import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { AppConstants } from '@apps/app.constants';
import { AuthenticationService } from '@apps/auth/api/authentication.service';
import { AuthConstants } from '@apps/auth/auth.constants';

import * as fromAuthLoginActions from './login.actions';
import * as fromAuthProfileActions from '../profile/profile.actions';
import { IAuroraState } from '@apps/aurora.state';
import { getCounterTwoFactorTries } from '../auth.reducer';
import { NotfGravity, NotificationService, NotifType } from '@common/services';

@Injectable()
export class AuthLoginEffects
{
    constructor (
        private actions: Actions,
        private router: Router,
        private authenticationService: AuthenticationService,
        private store: Store<IAuroraState>,
        private notificationService: NotificationService
    )
    {
    }

    authLoggedIn = createEffect(() => this.actions
        .pipe(
            ofType(fromAuthLoginActions.AuthLoggedIn),
            map(() =>
                fromAuthProfileActions.AuthLoginProfileLoading()
            )
        )
    );

    authLogout = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthLoginActions.AuthLogout),
                    switchMap(() => this.authenticationService.logout()
                        .pipe(
                            tap(() =>
                                this.router.navigate([AppConstants.LoginUrl])
                            )
                        )
                    )
                ),
        { dispatch: false }
    );

    authForgottenPassword = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthLoginActions.AuthPasswordForgotten),
                    tap(() =>
                        this.router.navigate(AuthConstants.ForgottenPasswordRoute(), { skipLocationChange: true })
                    )
                ),
        { dispatch: false }
    );

    authRenewPasswordSendingLink = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthLoginActions.AuthPasswordResetSendingLink),
                switchMap((action) =>
                    this.authenticationService.resetPasswordSendLink(action.email)
                        .pipe(
                            map(() =>
                                fromAuthLoginActions.AuthPasswordResetLinkSent()
                            ),
                            catchError((error) =>
                                of(fromAuthLoginActions.AuthProblemArisen({ error }))
                            )
                        )
                )
            )
    );

    authRenewPasswordLinkSent = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthLoginActions.AuthPasswordResetLinkSent),
                    tap(() =>
                        this.router.navigate(AuthConstants.LinkSentRoute(), { skipLocationChange: true })
                    )
                ),
        { dispatch: false }
    );

    authProblemArisen = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthLoginActions.AuthProblemArisen),
                    tap(() =>
                        this.router.navigate(AuthConstants.ProblemRoute(), { skipLocationChange: true })
                    )
                ),
        { dispatch: false }
    );

    authPasswordReset = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthLoginActions.AuthPasswordReset),
                switchMap((action) =>
                    this.authenticationService.resetPassword(action.passwordResetData)
                        .pipe(
                            map(() =>
                                fromAuthLoginActions.AuthPasswordResetSucceeded()
                            ),
                            catchError(() =>
                            {
                                return of(fromAuthLoginActions.AuthPasswordResetFailed());
                            })
                        )
                )
            )
    );

    authPasswordResetFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthLoginActions.AuthPasswordResetFailed),
                    map(() =>
                    {
                        this.router.navigate(
                            AuthConstants.ForgottenPasswordRoute({ result: false }),
                            { skipLocationChange: true }
                        );

                        return of(null);
                    })
                ),
        { dispatch: false }
    );

    authPasswordResetSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthLoginActions.AuthPasswordResetSucceeded),
                    map(() =>
                        this.toLoginUrl()
                    )
                ),
        { dispatch: false }
    );

    authTwoFactorRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthLoginActions.AuthTwoFactorRequested),
                    tap(({ idToken }) =>
                        this.router.navigate(AuthConstants.TwoFactorRoute(idToken), { skipLocationChange: true })
                    )
                ),
        { dispatch: false }
    );

    authTwoFactorLoginRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthLoginActions.AuthTwoFactorLoginRequested),
                switchMap(props =>
                    this.authenticationService.twoFactorLogin(props.idToken, props.code)
                        .pipe(
                            map(() =>
                                fromAuthLoginActions.AuthLoggedIn()
                            ),
                            catchError(() =>
                                of(fromAuthLoginActions.AuthTwoFactorLoginFailed())
                            )
                        )
                )
            )
    );

    authTwoFactorLoginFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthLoginActions.AuthTwoFactorLoginFailed),
                    withLatestFrom(this.store.select(getCounterTwoFactorTries)),
                    tap(([, tries]) =>
                    {
                        if (tries === 0)
                        {
                            this.router.navigate([AppConstants.LoginUrl]);
                            this.notificationService.notify(
                                [
                                    '',
                                    'AUTH.TWOFACTORAUTHENTICATION.ERRORS.LASTTRYFAILED'
                                ],
                                NotfGravity.danger,
                                NotifType.SNACKBAR
                            );
                        }
                    })
                ),
        { dispatch: false }
    );

    private toLoginUrl ()
    {
        this.router.navigate([AppConstants.LoginUrl]);

        return of(null);
    }
}
