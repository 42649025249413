import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, filter, tap } from 'rxjs/operators';

import { NotfGravity, NotificationService, NotifType } from '@common/services';
import { IAuroraState } from '@apps/aurora.state';

import * as fromNetworksNetwActions from './netw.actions';
import { NetworkMiscDomainService } from '../../../domain/network/network-misc-domain.service';
import { CreateNetwork, Network } from '../../../domain/models/network';
import { getNetworksNetwIsDataValid } from '../../ostk.reducer';
import { SelectOption } from '@ui/components/form/common';

@Injectable()
export class OstkNetworksNetwEffects
{
    constructor (
        private actions: Actions,
        private store: Store<IAuroraState>,
        private networkMiscDomainService: NetworkMiscDomainService,
        private notificationService: NotificationService
    )
    {
    }

    ostkNetworksNetwListRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromNetworksNetwActions.OstkNetworksNetwListRequested),
                withLatestFrom(this.store.select(getNetworksNetwIsDataValid)),
                filter(([, valid]) => !valid),
                switchMap(() =>
                    this.networkMiscDomainService.getNetworks()
                        .pipe(
                            map((networks: Network[]) =>
                                fromNetworksNetwActions.OstkNetworksNetwListSucceeded({ networks })
                            ),
                            catchError(err =>
                                of(fromNetworksNetwActions.OstkNetworksNetwListFailed({ err }))
                            )
                        )
                )
            )
    );

    ostkNetworksNetwCreateRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromNetworksNetwActions.OstkNetworksNetwCreateRequested),
                switchMap(({ network: createNetw }: { network: CreateNetwork; }) =>
                    this.networkMiscDomainService.createNetwork(createNetw)
                        .pipe(
                            map((network: Network) =>
                                fromNetworksNetwActions.OstkNetworksNetwCreateSucceeded({ network })
                            )
                            // catchError((failure: OstkFailure) =>
                            //     of(fromNetworksNetwActions.OstkNetworksNetwCreateFailed({ failure }))
                            // )
                        )
                )
            )
    );

    ostkNetworksNetwCreateSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromNetworksNetwActions.OstkNetworksNetwCreateSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.NETWORKS.CREATE.NOTIF.TITLE',
                                'OSTK.NETWORKS.CREATE.NOTIF.SUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    // ostkNetworksNetwCreateFailed: Observable<{ failure: OstkFailure; }> = createEffect(
    //     () =>
    //         this.actions
    //             .pipe(
    //                 ofType(fromNetworksNetwActions.OstkNetworksNetwCreateFailed),
    //                 tap(({ failure }: { failure: OstkFailure; }) =>
    //                     this.notificationFailureService.notify(failure)
    //                 )
    //             ),
    //     { dispatch: false }
    // );

    ostkNetworksNetwSelectRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromNetworksNetwActions.OstkNetworksNetwSelectRequested),
                switchMap(({ id }: { id: string; }) =>
                    this.networkMiscDomainService.getNetwork(id)
                        .pipe(
                            map((network: Network) =>
                                fromNetworksNetwActions.OstkNetworksNetwSelectSucceded({ network })
                            ),
                            catchError((failure: any) =>
                                of(fromNetworksNetwActions.OstkNetworksNetwSelectFailed({ failure }))
                            )
                        )
                )
            )
    );

    ostkNetworksNetwDeleteRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromNetworksNetwActions.OstkNetworksNetwDeleteRequested),
                switchMap(({ id }: { id: string; }) =>
                    this.networkMiscDomainService.deleteNetwork(id)
                        .pipe(
                            map((id: string) =>
                                fromNetworksNetwActions.OstkNetworksNetwDeleteSucceeded({ id })
                            ),
                            catchError((failure: any) =>
                                of(fromNetworksNetwActions.OstkNetworksNetwDeleteFailed({ failure }))
                            )
                        )
                )
            )
    );

    ostkNetworksNetwDeleteSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromNetworksNetwActions.OstkNetworksNetwDeleteSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.NETWORKS.NETW.DELETE.NOTIF.TITLE',
                                'OSTK.NETWORKS.NETW.DELETE.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkNetworksNetwSubnetDeleteRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromNetworksNetwActions.OstkNetworksNetwSubnetDeleteRequested),
                switchMap(({ id, subnetId }: { id: string; subnetId: string }) =>
                    this.networkMiscDomainService.deleteSubnet(id, subnetId)
                        .pipe(
                            map((subnetId: string) =>
                                fromNetworksNetwActions.OstkNetworksNetwSubnetDeleteSucceeded({ networkId: id, subnetId: subnetId })
                            ),
                            catchError((failure: any) =>
                                of(fromNetworksNetwActions.OstkNetworksNetwSubnetDeleteFailed({ failure }))
                            )
                        )
                )
            )
    );

    ostkNetworksNetwSubnetDeleteSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromNetworksNetwActions.OstkNetworksNetwSubnetDeleteSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.NETWORKS.NETW.DETAILS.SUBNET.DELETE.NOTIF.TITLE',
                                'OSTK.NETWORKS.NETW.DETAILS.SUBNET.DELETE.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkNetworksNetwPoolsRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromNetworksNetwActions.OstkNetworksNetwPoolsRequested),
                switchMap(() =>
                    this.networkMiscDomainService.getPools()
                        .pipe(
                            map((poolOptions: SelectOption[]) =>
                                fromNetworksNetwActions.OstkNetworksNetwPoolsSucceeded({ poolOptions: poolOptions })
                            ),
                            catchError(err =>
                                of(fromNetworksNetwActions.OstkNetworksNetwPoolsFailed({ err }))
                            )
                        )
                )
            )
    );
}
