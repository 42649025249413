import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import { FloatingIp } from '@apps/ostk/domain/models/network';
import * as fromOstkNetworksFipsActions from './fips.actions';
import * as fromOstkProjectsActions from '@apps/ostk/store/projects/projects.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { FloatingIpStatus, OstkConstants } from '@apps/ostk/ostk.constants';
import { CompareLowerString2 } from '@common/services/utils.service';

export const OstkNetwFipsFeatureKey = 'fips';
type IOstKNetwFipsCollectionState = EntityState<FloatingIp>;
export const OstkNetwFipsAdapter: EntityAdapter<FloatingIp> = createEntityAdapter({
    selectId: (floatingIp: FloatingIp) => floatingIp.id,
    sortComparer: CompareLowerString2<FloatingIp>((item: FloatingIp) => item.id)

});
const networksFipsInitialState: IOstKNetwFipsCollectionState = OstkNetwFipsAdapter.getInitialState({});
export interface IOstkNetworksFipsState
{
    floatingIps: IOstKNetwFipsCollectionState;
    isEmpty: boolean;
    isLoading: boolean;
    isOups: boolean;
    isLoaded: boolean;
    expirationDate?: DateTime;
    qsWord: string;
}

export const OstkNetworksFipsInitialState: IOstkNetworksFipsState = {
    floatingIps: networksFipsInitialState,
    isEmpty: false,
    isLoading: true,
    isOups: false,
    isLoaded: false,
    expirationDate: null,
    qsWord: ''
};

export const OstkNetwFipsReducer = createReducer(
    OstkNetworksFipsInitialState,
    on(
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        fromOstkProjectsActions.OstkProjectSelected,
        () => OstkNetworksFipsInitialState
    ),
    on(
        fromOstkNetworksFipsActions.OstkNetworksFipsListRequested,
        (state) => ({
            ...state,
            isLoading: !(state.expirationDate !== null && DateTime.utc() < state.expirationDate)
        })
    ),
    on(
        fromOstkNetworksFipsActions.OstkNetworksFipsListSucceeded,
        (state, { floatingIps }: { floatingIps: FloatingIp[]; }) => ({
            ...state,
            isLoading: false,
            isEmpty: floatingIps && floatingIps.length === 0,
            isOups: false,
            isLoaded: floatingIps && floatingIps.length !== 0,
            floatingIps: OstkNetwFipsAdapter.setAll(floatingIps, state.floatingIps),
            expirationDate: DateTime.utc().plus({ minutes: OstkConstants.DataLifeTime })
        })
    ),
    on(
        fromOstkNetworksFipsActions.OstkNetworksFipsListFailed,
        (state) => ({
            ...state,
            isLoading: false,
            isEmpty: false,
            isOups: true,
            isLoaded: false,
            floatingIps: OstkNetwFipsAdapter.removeAll(state.floatingIps),
            expirationDate: null
        })
    ),
    on(
        fromOstkNetworksFipsActions.OstkNetworksFipsQsWordSet,
        (state, { qsWord }) => ({
            ...state,
            qsWord: qsWord.toLowerCase()
        })
    ),
    on(
        fromOstkNetworksFipsActions.OstkNetworksFipsMapRequested,
        (state) => ({
            ...state,
            expirationDate: null
        })
    ),
    on(
        fromOstkNetworksFipsActions.OstkNetworksFipsMapSucceded,
        (state, { floatingIp }: { floatingIp: FloatingIp; }) =>
        {
            const update: Update<FloatingIp> = {
                id: floatingIp.id,
                changes: {
                    ...floatingIp,
                    status: FloatingIpStatus.ACTIVE
                }
            };

            return {
                ...state,
                floatingIps: OstkNetwFipsAdapter.updateOne(update, state.floatingIps)
            };
        }
    ),
    on(
        fromOstkNetworksFipsActions.OstkNetworksFipsUnmapSucceded,
        (state, { floatingIp }: { floatingIp: FloatingIp; }) =>
        {
            const update: Update<FloatingIp> = {
                id: floatingIp.id,
                changes: {
                    ...floatingIp,
                    status: FloatingIpStatus.DOWN
                }
            };

            return {
                ...state,
                floatingIps: OstkNetwFipsAdapter.updateOne(update, state.floatingIps)
            };
        }
    ),
    on(
        fromOstkNetworksFipsActions.OstkNetworksFipsDeleteSucceeded,
        (state, { id }: {id: string}) =>
            ({
                ...state,
                floatingIps: OstkNetwFipsAdapter.removeOne(id, state.floatingIps)
            })
    ),
    on(
        fromOstkNetworksFipsActions.OstkNetworksFipsAllocateSucceeded,
        (state, { floatingIp }: { floatingIp: FloatingIp; }) => ({
            ...state,
            floatingIps: OstkNetwFipsAdapter.addOne(floatingIp, state.floatingIps),
            expirationDate: null
        })
    )
);
