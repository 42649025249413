import { Injectable } from '@angular/core';

import { AppConstants } from 'src/app/_apps/app.constants';

class PersonalData
{
    readAlerts: string[] = [];
}

@Injectable({
    providedIn: 'root'
})
export class PersonalDataService
{
    private personalData: PersonalData;

    constructor()
    {
        this.loadPersonalData();
    }

    public getReadAlerts (): string[]
    {
        return this.personalData.readAlerts;
    }

    public setReadAlerts (alertId: string): void
    {
        if (!this.personalData.readAlerts.includes(alertId))
        {
            this.personalData.readAlerts.push(alertId);
            this.savePersonalData();
        }
    }

    public savePersonalData ()
    {
        localStorage.setItem(AppConstants.PersonalDataToken, JSON.stringify(this.personalData));
    }

    private loadPersonalData ()
    {
        const str = localStorage.getItem(AppConstants.PersonalDataToken);
        const obj = JSON.parse(str);

        if (obj !== null)
        {
            this.personalData = new PersonalData();
            Object.assign(this.personalData, obj);
        } else
        {
            this.personalData = new PersonalData();
        }
    }
}
