import { createSelector } from '@ngrx/store';

import { Bucket, S3Object } from '@apps/objs/domain/models';
import { CompareLowerString2 } from '@common/services';

import { getObjsState, IObjsState } from '../objs.state';
import {
    ObjsBucketBuckFeatureKey,
    IObjsBucketBuckState,
    bucketsAdapter
} from './buck/buck.state';
import {
    objectsAdapter,
    ObjsBucketObjeFeatureKey,
    IObjsBucketObjeState
} from './obje/obje.state';
import { IObjsBucketState, ObjsBucketFeatureKey } from './bucket.state';

const getObjsBucketState = createSelector(
    getObjsState,
    (state: IObjsState) =>
        state[ObjsBucketFeatureKey]
);

// #region Buckets
const getObjsBucketBuckState = createSelector(
    getObjsBucketState,
    (state: IObjsBucketState) =>
        state[ObjsBucketBuckFeatureKey]
);
const getBucketsCollection = createSelector(
    getObjsBucketBuckState,
    (state: IObjsBucketBuckState) =>
        state.buckets
);
export const {
    selectAll: getAllBuckets,
    selectTotal: getBucketsCount
} = bucketsAdapter.getSelectors(getBucketsCollection);
export const getBucketByName = createSelector(
    getAllBuckets,
    (buckets: Bucket[], props: {bucketName: string}) =>
        buckets.find((bucket: Bucket) => bucket.bucketName === props.bucketName)
);
export const getSelectedBucket = createSelector(
    getObjsBucketBuckState,
    (state: IObjsBucketBuckState) => state.selectedBucket
);
export const getPipelinedBuckets = createSelector(
    getAllBuckets,
    (buckets: Bucket[]): Bucket[] =>
        buckets.sort( CompareLowerString2<Bucket>((bucket: Bucket) => bucket.bucketName))
);
// export const getBucketsIsDataValid = createSelector(
//     getObjsBucketState,
//     (state: IObjsBucketsState) =>
//         false
//         // state.expirationDate !== null && DateTime.utc() < state.expirationDate
// );
export const getBucketsEmptyStatus = createSelector(
    getObjsBucketBuckState,
    (state: IObjsBucketBuckState) =>
        state.isEmpty
);
export const getBucketsOupsStatus = createSelector(
    getObjsBucketBuckState,
    (state: IObjsBucketBuckState) =>
        state.isOups
);
export const getBucketsLoadingStatus = createSelector(
    getObjsBucketBuckState,
    (state: IObjsBucketBuckState) =>
        state.isLoading
);
export const getBucketsLoadedStatus = createSelector(
    getObjsBucketBuckState,
    (state: IObjsBucketBuckState) =>
        state.isLoaded
);
export const getStorageUrl = createSelector(
    getObjsBucketBuckState,
    (state: IObjsBucketBuckState) =>
        state.storageUrl
);
export const getBucketsPathSegments = createSelector(
    getObjsBucketBuckState,
    (state: IObjsBucketBuckState) =>
        state.pathSegments
);
export const getBucketsCurrentPath = createSelector(
    getObjsBucketBuckState,
    (state: IObjsBucketBuckState) =>
        state.pathSegments?.join('/')
);
export const getBucketNameFromPath = createSelector(
    getBucketsPathSegments,
    (pathSegments: string[]) => pathSegments?.length > 0 ? pathSegments[0] : null
);
// #endregion


// #region Objects
const getObjsBucketObjeState = createSelector(
    getObjsBucketState,
    (state: IObjsBucketState) =>
        state[ObjsBucketObjeFeatureKey]
);
const getObjectsCollection = createSelector(
    getObjsBucketObjeState,
    (state: IObjsBucketObjeState) =>
        state.objects
);
const {
    selectAll: getAllObjects
} = objectsAdapter.getSelectors(getObjectsCollection);
export const getPipelinedObjects = createSelector(
    getAllObjects,
    (objects: S3Object[]): S3Object[] =>
        objects
);
export const getObjectsEmptyStatus = createSelector(
    getObjsBucketObjeState,
    (state) => state.isEmpty
);
export const getObjectsOupsStatus = createSelector(
    getObjsBucketObjeState,
    (state) => state.isOups
);
export const getObjectsLoadingStatus = createSelector(
    getObjsBucketObjeState,
    (state) => state.isLoading
);
export const getObjectsLoadedStatus = createSelector(
    getObjsBucketObjeState,
    (state) => state.isLoaded
);
export const getPipelinedFolders = createSelector(
    getPipelinedObjects,
    (objects: S3Object[]) => objects
        .filter((obj: S3Object) => obj.isFolder)
);
export const getObjectById = createSelector(
    getAllObjects,
    (objects: S3Object[], props: {id: string}): S3Object =>
        objects.find((object: S3Object) => object.id === props.id)
);
export const getObjectVersions = createSelector(
    getAllObjects,
    (objects: S3Object[], props: {key: string}): S3Object[] =>
        objects.filter((object) => object.key === props.key)
);
export const getSelectedObject = createSelector(
    getObjsBucketObjeState,
    (state: IObjsBucketObjeState) => state.selectedObject
);
export const getObjectExists = createSelector(
    getAllObjects,
    (objects: S3Object[], { objectName }: {objectName: string}) =>
        objects.find((obj: S3Object) => obj.name === objectName) !== undefined
);
// #endregion