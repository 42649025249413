import { Component, OnInit, OnDestroy, Injector, PLATFORM_ID, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { iif, of, Subject } from 'rxjs';
import { filter, map, switchMap, tap, take, takeUntil, concatAll } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';

import { AppConstants } from '@apps/app.constants';
import * as fromRootStore from '@apps/root/store';
import * as fromAuthStore from '@apps/auth/store';
import * as fromNotfStore from '@apps/notf/store';
import * as fromOstkStore from '@apps/ostk/store';
import { IAuroraState } from '@apps/aurora.state';
import { LanguageService, MatomoInjectorService, MatomoTrackerService, NotfGravity, NotificationService, NotifType, UtilsService, } from '@common/services';
import { Profile } from '@apps/auth/models/profile';
import { RocketChatInjectorService } from '@common/services/rocketchat-injector.service';
import { RocketChatWidgetApiService } from '@common/services/rocketchat-widget-api.service';
import { Impersonation } from '@apps/root/models';
import { Favicon } from '@common/services/favicon.service';
import { ImpersonateService, ImpersonationStorage } from '@apps/root/services/impersonate.service';
import { BackAuthUserSelectedSet } from '@apps/back/store/auth/users/global/users-global.actions';
import { OstkConstants } from '@apps/ostk/ostk.constants';
import { Platform, Project } from '@apps/ostk/domain/models';
import { DetailedUser } from '@apps/back/store/auth/users/users.common';

@Component({
    selector: 'aa-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy
{
    private intervalId: any;
    private destroyator = new Subject();
    private static regexCloudUrl = new RegExp(`${OstkConstants.BaseUrl}/(?<platform>.*)/(?<project>.*)/(${OstkConstants.OstkSubModules})`);
    private rocketChatInjectorService: RocketChatInjectorService;
    private rocketChatWidgetApiService: RocketChatWidgetApiService;

    constructor (
        @Inject(PLATFORM_ID) private platformId: any,
        languageService: LanguageService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private translateService: TranslateService,
        private store: Store<IAuroraState>,
        private routerStore: Store<any>,
        private favicon: Favicon,
        private impersonateService: ImpersonateService,
        private notificationService: NotificationService,
        private matomoInjectorService: MatomoInjectorService,
        private matomoTrackerService: MatomoTrackerService
    )
    {
        this.store.dispatch(fromRootStore.RootMiscStoreActions.RootGetDeployedVersion());

        this.setSentry();
        this.setMatomo(platformId);
        this.setRocketChat(languageService, translateService);

        this.store.dispatch(fromNotfStore.NotfAlertStoreActions.NotfAlertLoadRequested());
        this.intervalId = setInterval(() =>
            this.store.dispatch(fromNotfStore.NotfAlertStoreActions.NotfAlertLoadRequested()),
        600000
        );

        if (AppConstants.Configuration.sentryDsn)
        {
            const compositeVersion: string = `${AppConstants.ApplicationVersion}-${AppConstants.ApplicationCommitId}`;

            Sentry.init({
                dsn: AppConstants.Configuration.sentryDsn,
                release: compositeVersion,
                environment: AppConstants.Stage
            });
        }
    }

    ngOnInit ()
    {
        this.store.select(fromRootStore.getRootVersionDeployed)
            .pipe(
                filter((hash: string) =>
                    hash !== null
                ),
                switchMap((hash: string) =>
                    iif(
                        () => hash !== AppConstants.ApplicationCommitId,
                        of(true)
                            .pipe(
                                tap(() =>
                                {
                                    this.store.dispatch(fromRootStore.RootMiscStoreActions.RootResetDeployedVersion());
                                    const fields = document.querySelectorAll('div.cdk-overlay-container div.cdk-global-overlay-wrapper');


                                    if (fields.length === 0)
                                    {
                                        this.notificationService.notify(
                                            ['', 'NOTF.AUTOMATIC.RELOAD'],
                                            NotfGravity.danger,
                                            NotifType.SNACKBAR
                                        );
                                        setTimeout(() => window.location.reload(), 5000);
                                    }
                                })
                            ),
                        of(false)
                    )
                ),
                takeUntil(this.destroyator)
            )
            .subscribe();



        this.routerStore
            .pipe(
                select(fromRootStore.getRouteInfos),
                map(({ url }: { url: string, data: any }) =>
                    decodeURIComponent(url)
                ),
                filter((url: string) =>
                    url && 0 !== url.length && url.includes('cloud')
                ),
                take(1),
                tap((url: string) =>
                {
                    this.store
                        .pipe(
                            select(fromOstkStore.getSelectedProject),
                            filter((project: Project) =>
                                project === null
                            ),
                            take(1),
                            tap(() =>
                            {
                                const matches = AppComponent.regexCloudUrl.exec(url);

                                this.store.select(fromOstkStore.getAllPlatforms)
                                    .pipe(
                                        filter((platforms: Platform[]) =>
                                            platforms.length > 0
                                        ),
                                        concatAll(),
                                        filter((platform: Platform) =>
                                            platform.platformName === matches.groups.platform
                                        ),
                                        take(1),
                                        tap((platform: Platform) =>
                                        {
                                            this.store.dispatch(fromOstkStore.OstkProjectsActions.OstkPlatformSelected({ platform }));
                                            this.store.dispatch(fromOstkStore.OstkProjectsActions.OstkProjectsLoadingRequested({ platform }));
                                            this.store.select(fromOstkStore.getAllProjects)
                                                .pipe(
                                                    filter((projects: Project[]) =>
                                                        projects.length > 0
                                                    ),
                                                    concatAll(),
                                                    filter((project: Project) =>
                                                        project.projectName === matches.groups.project
                                                    ),
                                                    take(1),
                                                    tap((project: Project) =>
                                                    {
                                                        this.store.dispatch(fromOstkStore.OstkProjectsActions.OstkProjectSelected({ project }));
                                                    })
                                                )
                                                .subscribe();
                                        })
                                    )
                                    .subscribe();
                            })
                        )
                        .subscribe();
                })
            )
            .subscribe();

        this.router.events
            .pipe(
                filter((evt) => evt instanceof NavigationStart),
                map((x: NavigationStart) => x.url),
                filter((x) => x === '/'),
                take(1)
            )
            .subscribe(
                () => this.router.navigateByUrl(AppConstants.DefaultRedirectUrl)
            );

        this.router.events
            .pipe(
                filter((evt) => evt instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) =>
                {
                    while (route.firstChild)
                    {
                        route = route.firstChild;
                    }

                    return route;
                }),
                // filter((route) => route.outlet === 'primary' || route.outlet === 'secondary'), //
                switchMap((route) => route.data),
                tap((data) =>
                {
                    this.changeBrowserTabTitle(data);
                    this.adaptBodyClass(data);
                }),
                takeUntil(this.destroyator)
            )
            .subscribe();

        const is: ImpersonationStorage = this.impersonateService.getImpersonation();
        if (is !== null)
        {
            this.store.dispatch(
                BackAuthUserSelectedSet({ user: is.user as DetailedUser })
            );
            this.store.dispatch(
                fromAuthStore.AuthProfileStoreActions.AuthProfileApplicationRolesSet({ appRoles: is.user.appRoles })
            );
            this.store.dispatch(
                fromRootStore.RootImpeStoreActions.RootImpersonateSet({ impersonation: is.impe })
            );
        }

        this.store
            .pipe(
                select(fromRootStore.getRootImpersonation),
                map((impersonation: Impersonation) =>
                {
                    if (impersonation !== null)
                    {
                        this.favicon.activate('impersonate');
                    }
                    else
                    {
                        this.favicon.reset();
                    }
                }),
                takeUntil(this.destroyator)
            )
            .subscribe();
    }

    ngOnDestroy ()
    {
        clearTimeout(this.intervalId);
        this.store.dispatch(
            fromRootStore.RootImpeStoreActions.RootImpersonateUnset()
        );
        this.destroyator.next(true);
        this.destroyator.complete();
    }

    private setSentry (): void
    {
        if (!UtilsService.isNullOrEmpty(AppConstants.Configuration.sentryDsn))
        {
            this.store
                .pipe(
                    select(fromAuthStore.getProfile),
                    filter((profile: Profile) => profile !== null),
                    tap((profile: Profile) =>
                    {
                        Sentry.setUser({
                            id: profile.id,
                            username: profile.firstLastName,
                            email: profile.email
                        });

                        Sentry.setExtra(
                            'user-profile',
                            {
                                entityId: profile.entityId,
                                entityName: profile.entityName
                            }
                        );
                    }),
                    takeUntil(this.destroyator)
                )
                .subscribe();
        }
    }

    private setMatomo (platformId: any): void
    {
        if (!UtilsService.isNullOrEmpty(AppConstants.Configuration.trackingUrl))
        {
            this.matomoInjectorService.init(AppConstants.Configuration.trackingUrl, AppConstants.Configuration.trackingSiteId);

            this.routerStore
                .pipe(
                    select(fromRootStore.getRouteInfos),
                    filter((infos: { url: string, data: any }) =>
                        infos.url && 0 !== infos.url.length
                    ),
                    tap((infos: { url: string, data: any }) =>
                        this.matomoTrackerService.trackPageView(infos.url, infos?.data?.['title'] ?? null)
                    ),
                    takeUntil(this.destroyator)
                )
                .subscribe();


            this.store
                .pipe(
                    select(fromAuthStore.getProfile),
                    filter((profile: Profile) => profile !== null),
                    tap((profile: Profile) =>
                    {
                        this.matomoTrackerService.setUserId(profile.id);

                        this.matomoTrackerService.setCustomDimension(AppConstants.trackingDimensions.name, profile.firstLastName);
                        this.matomoTrackerService.setCustomDimension(AppConstants.trackingDimensions.email, profile.email);
                        this.matomoTrackerService.setCustomDimension(AppConstants.trackingDimensions.entityId, profile.entityId);
                        this.matomoTrackerService.setCustomDimension(AppConstants.trackingDimensions.entityName, profile.entityName);
                    }),
                    takeUntil(this.destroyator)
                )
                .subscribe();
        }
    }

    private setRocketChat (languageService: LanguageService, translateService: TranslateService): void
    {
        if (!UtilsService.isNullOrEmpty(AppConstants.Configuration.rocketChatUrl))
        {
            const injector = Injector.create({
                providers: [
                    { provide: RocketChatInjectorService, deps: [] },
                    {
                        provide: RocketChatWidgetApiService,
                        useFactory: (): RocketChatWidgetApiService =>
                            new RocketChatWidgetApiService(languageService, translateService)
                    }
                ]
            });

            this.rocketChatInjectorService = injector.get(RocketChatInjectorService);
            this.rocketChatWidgetApiService = injector.get(RocketChatWidgetApiService);

            this.rocketChatInjectorService.init(AppConstants.Configuration.rocketChatUrl, AppConstants.Configuration.rocketChatScriptUrl);
            this.rocketChatWidgetApiService.setDefaultTitle();

            this.store
                .pipe(
                    select(fromAuthStore.getProfileParameters),
                    filter((profile: Profile) =>
                        profile !== null
                    ),
                    tap((profile: Profile) =>
                    {
                        this.rocketChatWidgetApiService.setTheme(Profile.fromThemeToSkin(profile.skin), true);
                    }),
                    takeUntil(this.destroyator)
                )
                .subscribe();

            this.store
                .pipe(
                    select(fromAuthStore.getProfile),
                    filter((profile: Profile) => profile !== null),
                    tap((profile: Profile) =>
                    {
                        this.rocketChatWidgetApiService.setUserBasicInfo({
                            id: profile.id,
                            name: profile.firstLastName,
                            email: profile.email,
                            lang: profile.language
                        });
                        this.rocketChatWidgetApiService.setCustomField(AppConstants.rocketChatCustomFields.entityId, profile.entityId);
                        this.rocketChatWidgetApiService.setCustomField(AppConstants.rocketChatCustomFields.entityName, profile.entityName);
                        this.rocketChatWidgetApiService.setUserDepartment();
                    }),
                    takeUntil(this.destroyator)
                )
                .subscribe();
        }
    }

    private changeBrowserTabTitle (data: any)
    {
        if (data.title)
        {
            const browserTitle = this.translateService.instant(data.title);

            this.titleService.setTitle(`${AppConstants.ApplicationName} - ${browserTitle}`);
        }
        else
        {
            this.titleService.setTitle(`${AppConstants.ApplicationName}`);
        }
    }

    private adaptBodyClass (data: any)
    {
        let bodyClassList: DOMTokenList;
        if (data.bodyClass)
        {
            bodyClassList = document.querySelector('body').classList;
            Array.from(bodyClassList)
                .filter((cls: string) => !cls.startsWith('theme'))
                .forEach((cls: string) => bodyClassList.remove(cls));
            data.bodyClass.forEach((cls: string) => bodyClassList.add(cls));
        }
        else
        {
            bodyClassList = document.querySelector('body').classList;
            Array.from(bodyClassList)
                .filter((cls: string) => !cls.startsWith('theme'))
                .forEach((cls: string) => bodyClassList.remove(cls));
        }
    }
}
