import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, concatAll, toArray } from 'rxjs/operators';

import { BaseListDomainService } from '@common/domain/base-list-domain.service';
import { UtilsService } from '@common/services';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { ResultApi } from '@common/api/result-api';

import { ImageCopy } from './models';
import { CopyListApiService } from '../api/copy-list-api.service';
import { CopyApi, ImageCopyApi } from '../api/models';

@Injectable({
    providedIn: 'root'
})
export class CopyListDomainService extends BaseListDomainService
{
    constructor (
        private copyListApiService: CopyListApiService
    )
    {
        super();
    }

    getLatestCopies (qs: string): Observable<ImageCopy[]>
    {
        return this.copyListApiService.getLatestCopies(qs)
            .pipe(
                map((obj: ResultApi<ImageCopyApi>): ImageCopyApi[] =>
                    obj.value
                ),
                map(filterRemoteMaster),
                map((copies: ImageCopyApi[]) =>
                    UtilsService.groupBy<ImageCopyApi>(copies, (c) => c.server)
                ),
                map((groupedCopies: Map<string, ImageCopyApi[]>) =>
                    Array.from(groupedCopies.values())),
                concatAll(),
                map((cc: ImageCopyApi[]) =>
                    cc.reduce((prev: ImageCopyApi, curr: ImageCopyApi, index: number) =>
                    {
                        if (index === 0)
                        {
                            return curr;
                        }

                        prev.copy = [...prev.copy, ...curr.copy];

                        return prev;
                    }, {} as ImageCopyApi)
                ),
                map((imageCopyApi: ImageCopyApi, index) =>
                    ImageCopy.mapperFromApi(imageCopyApi, index)
                ),
                toArray()
            );
    }
}

const filterRemoteMaster = (copies: ImageCopyApi[]): ImageCopyApi[] =>
    copies.map((imageCopy: ImageCopyApi) =>
    {
        const filteredCopies: CopyApi[] = imageCopy.copy.filter(c =>
            c.residence === undefined || !c.residence.includes(BckpConstants.RemoteMaster)
        );

        return {
            ...imageCopy,
            copy: filteredCopies
        };
    });
