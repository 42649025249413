import { DateTime } from 'luxon';

import { Status } from '../../../domain/models/status';

export class LastSession
{
    public since: DateTime;
    public until: DateTime;
    public statuses: Status[];
    public total: number;
    public expirationDate?: DateTime;

    constructor ()
    {
        this.expirationDate = null;
    }
}

export class DashLastSession extends LastSession
{
    public isEmpty: boolean = false;
    public isLoading: boolean = true;
    public isLoaded: boolean = false;
    public isOups: boolean = false;

    constructor (since?: DateTime, until?: DateTime, statuses: Status[] = [])
    {
        super();

        this.since = since;
        this.until = until;
        this.statuses = statuses;
        this.total = statuses.length !== 0 ? statuses[0].counter + statuses[1].counter + statuses[2].counter : 0;
    }
}
