<form id="auth-form" [formGroup]="form" (keyup.enter)="onSubmit()">
    <div class="auth-title">{{'AUTH.PROFILE.ONBOARDING.STEP3.TITLE' | translate}}</div>
    <div class="auth-sub-title">
        {{'AUTH.PROFILE.ONBOARDING.STEP3.NEWPASSWORD' | translate}}
    </div>
    <div>
        <aa-input-email [required]="'required'" aaAutofocus name="email" label="{{ 'AUTH.FORM.EMAIL.LABEL' | translate }}"
                            formControlName="email">
        </aa-input-email>
        <aa-input-token-code [required]="'required'" name="code" label="{{ 'AUTH.VERIFYCODE.CODE' | translate }}"
                            formControlName="code">
        </aa-input-token-code>
        <aa-input-new-pswd [required]="'required'" name="password" label="{{ 'AUTH.PROFILE.ONBOARDING.STEP3.ENTERPASSWORD' | translate }}"
                            formControlName="password">
        </aa-input-new-pswd>
        <aa-input-password [required]="'required'" name="confirmPassword" label="{{ 'AUTH.PROFILE.ONBOARDING.STEP3.REENTER' | translate }}"
                            formControlName="confirmPassword">
        </aa-input-password>
        <div class="error" *ngIf="form.errors && form.errors.missmatch">
            {{'AUTH.FORM.ERROR.DIFFERENT' | translate}}
        </div>
        <div>
            <aa-btn-action-secondary [btnText]="'BUTTON.CONTINUE' | translate" [cta]="onSubmit" [btnWidth]="'100%'">
            </aa-btn-action-secondary>
        </div>
    </div>
</form>
