import { HttpEvent } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { MediaTypes } from '@common/services';
import { EnrichedAsset } from '@apps/cmdb/models';

import { CapacityGbytesPerServer, PolicyForFilter } from '../../domain/models';
import { CapacitiesFilter } from '../../models';

export const BckpCapacitiesODataRequested = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities OData Requested'
);

export const BckpCapacitiesPoliciesLoaded = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Policies Loaded',
    props<{ policyForFilters: PolicyForFilter[], fullFlags: number }>()
);

export const BckpCapacitiesCgpsLoaded = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Cgps Loaded',
    props<{ cgps: CapacityGbytesPerServer[] }>()
);

export const BckpCapacitiesFilterApplied = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Filter Applied',
    props<{ filter: CapacitiesFilter }>()
);

export const BckpCapacitiesLocalFilterApplied = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Local Filter Applied',
    props<{ filterFlags: number[], stateFilterFlags: number }>()
);

export const BckpCapacitiesCleared = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Cleared'
);

export const BckpCapacitiesExportRequested = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Export Requested',
    props<{ format: MediaTypes }>()
);

export const BckpCapacitiesExportSucceeded = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Export Succeeded',
    props<{ response: HttpEvent<Blob> }>()
);

export const BckpCapacitiesQsWordSet = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities QsWord Set',
    props<{ qsWord: string }>()
);

export const BckpCapacitiesTagAttachRequested = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Tag Attach Requested',
    props <{ id: string, name: string, identifier: string, tagId: string}>()
);
export const BckpCapacitiesTagAttachExecuted = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Tag Attach Executed',
    props<{ enrichedAsset: EnrichedAsset; }>()
);
export const BckpCapacitiesTagAttachSucceeded = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Tag Attach Succeeded',
    props<{ updatedCapacity: Update<CapacityGbytesPerServer> }>()
);
export const BckpCapacitiesTagDetachRequested = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Tag Detach Requested',
    props<{ id: string, identifier: string, tagId: string }>()
);
export const BckpCapacitiesTagDetachExecuted = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Tag Detach Executed',
    props<{ enrichedAsset: EnrichedAsset; }>()
);
export const BckpCapacitiesTagDetachSucceeded = createAction(
    '[BCKP-CAPACITIES] Bckp Capacities Tag Detach Succeeded',
    props<{ updatedCapacity: Update<CapacityGbytesPerServer> }>()
);
