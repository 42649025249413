import { createAction, props } from '@ngrx/store';

import { Profile } from '../../models/profile';

export const AuthAccountCreateStep1Enter = createAction(
    '[AUTH-ACCOUNT] Auth Account Step1 Enter'
);

export const AuthAccountCreateStep1Complete = createAction(
    '[AUTH-ACCOUNT] Auth Account Step1 Complete',
    props<{ email: string }>()
);

export const AuthConfirmEmailLinkSent = createAction(
    '[AUTH-LOGIN] Confirm Email Link Sent'
);

export const AuthConfirmEmailProblemArisen = createAction(
    '[AUTH-LOGIN] Confirm Email Problem Arisen',
    props<{ error: any }>()
);

export const AuthAccountCreateStep2Enter = createAction(
    '[AUTH-ACCOUNT] Auth Account Step2 Enter'
);

export const AuthAccountCreateStep2Complete = createAction(
    '[AUTH-ACCOUNT] Auth Account Step2 Complete',
    props<{ profile: Profile }>()
);

export const AuthAccountCreateStep3Enter = createAction(
    '[AUTH-ACCOUNT] Auth Account Step3 Enter',
    props<{ profile: Profile }>()
);

export const AuthAccountCreateStep3Complete = createAction(
    '[AUTH-ACCOUNT] Auth Account Step3 Complete'
);

export const AuthAccountCreateStep4Enter = createAction(
    '[AUTH-ACCOUNT] Auth Account Step4 Enter'
);

export const AuthAccountCreateStep4Complete = createAction(
    '[AUTH-ACCOUNT] Auth Account Step4 Complete'
);
