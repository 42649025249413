import { Component, forwardRef, Input, Optional, Inject, Injector, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { ElementBase, OptionValue } from '../../../common';
import { SelectOption2 } from '../../../common/select-option2';

@Component({
    selector: 'aa-checkbox-group2',
    templateUrl: './checkbox-group2.component.html',
    styleUrls: [
        './checkbox-group2.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxGroup2Component),
            multi: true
        }
    ]
})
export class CheckboxGroup2Component extends ElementBase<OptionValue[]> implements OnInit
{
    @Input() options: SelectOption2[];
    @Input() orientation?: 'horizontal' | 'vertical' = 'horizontal';
    @Input() toTranslate: boolean = false;
    @Input() lineSize: number = 0;
    @Input() marginTop: number = 20;
    public lines: number[];

    constructor (
        injector: Injector,
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>
    )
    {
        super(injector);
    }

    ngOnInit ()
    {
        if (this.lineSize !== 0)
        {
            const nbLines = Math.ceil(this.options.length / this.lineSize);

            this.lines = Array.from(Array(nbLines));
        }
    }

    public addOrRemove (v: OptionValue): void
    {
        if (this.contains(v))
        {
            this.remove(v);
        }
        else
        {
            this.add(v);
        }
    }

    public contains (v: OptionValue): boolean
    {
        if (Array.isArray(this.value))
        {
            return this.value.indexOf(v) > -1;
        }
        else if (this.value)
        {
            return this.value === v;
        }

        return false;
    }

    private add (v: OptionValue): void
    {
        if (Array.isArray(this.value))
        {
            this.value.push(v);
        }
        else
        {
            this.value = [v];
        }
    }

    private remove (value: OptionValue): void
    {
        const index = this.value.indexOf(value);

        if (!this.value || index < 0)
        {
            return;
        }

        this.value.splice(index, 1);
    }
}
