import { createAction, props } from '@ngrx/store';

import { Country, Protocol } from '../../domain/models';

export const SecuAttacksCountryAdded = createAction(
    '[SECU-ATTACKS] Secu Attacks Country Added',
    props<{ countries: Country[] }>()
);

export const SecuAttacksProtocolAdded = createAction(
    '[SECU-ATTACKS] Secu Attacks Protocol Added',
    props<{ protocols: Protocol[] }>()
);

export const SecuAttacksZoomLevelChanged = createAction(
    '[SECU-ATTACKS] Secu Attacks Zoom Level Changed',
    props<{ zoomLevel: number }>()
);

export const SecuAttacksZoomInClicked = createAction(
    '[SECU-ATTACKS] Secu Attacks Zoom In Clicked'
);

export const SecuAttacksZoomOutClicked = createAction(
    '[SECU-ATTACKS] Secu Attacks Zoom Out Clicked'
);

export const SecuAttacksZoomInitClicked = createAction(
    '[SECU-ATTACKS] Secu Attacks Zoom Init Clicked'
);

export const SecuAttacksSetZoomClicked = createAction(
    '[SECU-ATTACKS] Secu Attacks Set Zoom Clicked',
    props<{ zoomLevel: number }>()
);

export const SecuAttacksPauseClicked = createAction(
    '[SECU-ATTACKS] Secu Attacks Pause Clicked'
);

export const SecuAttacksStartClicked = createAction(
    '[SECU-ATTACKS] Secu Attacks Start Clicked'
);

export const SecuAttacksShowTraces = createAction(
    '[SECU-ATTACKS] Secu Attacks Show Traces'
);

export const SecuAttacksHideTraces = createAction(
    '[SECU-ATTACKS] Secu Attacks Hide Traces'
);

export const SecuAttacksCountryClicked = createAction(
    '[SECU-ATTACKS] Secu Attacks Flag Country Selected',
    props<{ codeIso2: string }>()
);
