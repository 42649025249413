import { OstkConstants } from '@apps/ostk/ostk.constants';
import { SubnetApi } from '../../../api/models/network';
import { AllocationPool } from './allocation-pool';
import { HostRoute } from './host-route';

export class Subnet
{
    allocationPools: AllocationPool[];
    cidr: string;
    dnsNameservers: string [];
    enableDhcp: boolean;
    gatewayIp: string;
    hostRoutes: HostRoute[];
    id: string;
    ipv6AddressMode: any;
    ipv6RaMode: any;
    ipVersion: number;
    name: string;
    networkId: string;
    subnetpoolId: any;
    tenantId: string;
    dhcpEnabledKey: string;

    public static mapperFromApi (source: SubnetApi): Subnet
    {
        const target = new Subnet();

        Object.assign(target, source);

        target.dhcpEnabledKey = OstkConstants.getDhcpEnabledKey(source.enableDhcp);

        return target;
    }
}
