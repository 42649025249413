import { Injectable } from '@angular/core';

import { DateTime } from 'luxon';
import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as fromBreakdownActions from './breakdown.actions';
import { RequestMiscDomainService } from '@apps/its2/domain';
import { RequestsCatalogCounter } from '@apps/its2/domain/models';
import { RequestTypeEnum } from '@apps/its2/its2.constants';
import { IAuroraState } from '@apps/aurora.state';
import { Its2DashSharedSelectors } from '../..';
import { Its2DashboardFilter } from '@apps/its2/services';

@Injectable({
    providedIn: 'root'
})
export class Its2BreakdownsEffects
{
    constructor (
        private actions: Actions,
        private requestMiscDomainService: RequestMiscDomainService,
        private store: Store<IAuroraState>
    )
    {}

    its2DashBreakdownCatalogIncidentsCountersRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromBreakdownActions.Its2DashBreakdownIncidentsCountersRequested),
                    withLatestFrom(
                        this.store.select(Its2DashSharedSelectors.getDashSinceUntil),
                        (_, [since, until]) => ([
                            since,
                            until
                        ])
                    ),
                    switchMap(([since, until]: [DateTime, DateTime]) =>
                        this.requestMiscDomainService.getSummaryCounters(
                            RequestTypeEnum.INCIDENTS,
                            Its2DashboardFilter.mapToQueryString(since, until).qs
                        )
                            .pipe(
                                map((counters: RequestsCatalogCounter[]) =>
                                    fromBreakdownActions.Its2DashBreakdownIncidentsCountersSucceeded({ counters })
                                ),
                                catchError((err) =>
                                    of(fromBreakdownActions.Its2DashBreakdownIncidentsCountersFailed({ err }))
                                )
                            )
                    )
                )
    );

    its2DashBreakdownCatalogServicesCountersRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromBreakdownActions.Its2DashBreakdownServicesCountersRequested),
                    withLatestFrom(
                        this.store.select(Its2DashSharedSelectors.getDashSinceUntil),
                        (_, [since, until]) => ([
                            since,
                            until
                        ])
                    ),
                    switchMap(([since, until]: [DateTime, DateTime]) =>
                        this.requestMiscDomainService.getSummaryCounters(
                            RequestTypeEnum.SERVICES,
                            Its2DashboardFilter.mapToQueryString(since, until).qs
                        )
                            .pipe(
                                map((counters: RequestsCatalogCounter[]) =>
                                    fromBreakdownActions.Its2DashBreakdownServicesCountersSucceeded({ counters })
                                ),
                                catchError((err) =>
                                    of(fromBreakdownActions.Its2DashBreakdownServicesCountersFailed({ err }))
                                )
                            )
                    )
                )
    );
}