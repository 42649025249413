import { createAction, props } from '@ngrx/store';

import { ResultDomain } from '@common/domain/result-domain';
import { CmdbAsset, CmdbAssetDetails, AssetPopinIds } from '@apps/cmdb/models';
import { AssetsFilter } from '@apps/cmdb/services/assets-filter';

export const CmdbAssetsODataFilterRequested = createAction(
    '[CMDB-ASSETS] Cmdb Assets OData Filter Requested',
    props<{ queryString: string; }>()
);
export const CmdbAssetsODataFilterSucceeded = createAction(
    '[CMDB-ASSETS] Cmdb Assets OData Filter Succeeded',
    props<{ result: ResultDomain<CmdbAsset>; }>()
);
export const CmdbAssetsODataFilterFailed = createAction(
    '[CMDB-ASSETS] Cmdb Assets OData Filter Failed',
    props<{ error: any; }>()
);
export const CmdbAssetsFilterChanged = createAction(
    '[CMDB-ASSETS] Cmdb Assets Filter Changed',
    props<{ assetsFilter: AssetsFilter }>()
);

export const CmdbAssetsODataSkipRequested = createAction(
    '[CMDB-ASSETS] Cmdb Assets OData Skip Requested',
    props<{ queryString: string; }>()
);
export const CmdbAssetsODataSkipSucceeded = createAction(
    '[CMDB-ASSETS] Cmdb Assets OData Skip Succeeded',
    props<{ result: ResultDomain<CmdbAsset>; }>()
);
export const CmdbAssetsODataSkipFailed = createAction(
    '[CMDB-ASSETS] Cmdb Assets OData Skip Failed',
    props<{ error: any; }>()
);
export const CmdbAssetsQsWordSet = createAction(
    '[CMDB-ASSETS] Cmdb Assets QsWord Set',
    props<{ qsWord: string; }>()
);

export const CmdbAssetDetailedRequested = createAction(
    '[CMDB-ASSETS] Cmdb Asset Detailed Requested',
    props<{ categoryGuid: string, assetId: string }>()
);
export const CmdbAssetDetailedSucceeded = createAction(
    '[CMDB-ASSETS] Cmdb Asset Detailed Succeeded',
    props<{ assetDetails: CmdbAssetDetails }>()
);
export const CmdbAssetDetailedFailed = createAction(
    '[CMDB-ASSETS] Cmdb Asset Detailed Failed'
);
export const CmdbAssetDetailedReset = createAction(
    '[CMDB-ASSETS] Cmdb Asset Detailed Reset'
);
export const CmdbAssetPopinIdsPush = createAction(
    '[CMDB-ASSETS] Cmdb Asset Popin Ids Push',
    props<{ assetPopinIds: AssetPopinIds; }>()
);
export const CmdbAssetPopinIdsPop = createAction(
    '[CMDB-ASSETS] Cmdb Asset Popin Ids Pop'
);
