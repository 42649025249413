<div id="{{message.id}}" class="message-item" [@showHide]="isShown ? 'show' : 'hide'" (@showHide.done)="showHideDone()">
    <div class="delete-message">
        <aa-action-circle class="blue duotone" [faIcon]="'fal fa-times'" (click)="deleteMessage()"></aa-action-circle>
    </div>
    <div class="icon" [ngSwitch]="message.iconClass">
        <aa-icon-circle class="info duotone" [faIcon]="message.icon" [size]="'fa-2x'" *ngSwitchCase="'info'">
        </aa-icon-circle>
        <aa-icon-circle class="success duotone" [faIcon]="message.icon" [size]="'fa-2x'" *ngSwitchCase="'success'">
        </aa-icon-circle>
        <aa-icon-circle class="warning1 duotone" [faIcon]="message.icon" [size]="'fa-2x'" *ngSwitchCase="'warning1'">
        </aa-icon-circle>
        <aa-icon-circle class="warning2 duotone" [faIcon]="message.icon" [size]="'fa-2x'" *ngSwitchCase="'warning2'">
        </aa-icon-circle>
        <aa-icon-circle class="danger duotone" [faIcon]="message.icon" [size]="'fa-2x'" *ngSwitchCase="'danger'">
        </aa-icon-circle>
    </div>
    <table>
        <tr>
            <td class="title">
                {{message.title}}
            </td>
        </tr>
        <tr>
            <td class="message">
                {{message.message}}
            </td>
        </tr>
    </table>
</div>
