import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseListApiService } from '@common/api/base-list-api.service';
import { ResultApi } from '@common/api/result-api';
import { AppConstants } from '@apps/app.constants';

import { JobDetailsApi } from './models';

@Injectable({
    providedIn: 'root'
})
export class JobDetailsListApiService extends BaseListApiService<JobDetailsApi>
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlHist;
        this.resourceUrl = '/hist/netbackup/';
    }

    getJobDetails (documentId: string, qs: string): Observable<ResultApi<JobDetailsApi>>
    {
        const endPoint = `jobs/${documentId}`;

        // if (AppConstants.IsDataLocal('bckp'))
        // {
        //     return this.http.get<ResultApi<JobDetailsApi>>('assets/data/bckp/jobs.json');
        // }

        // if (AppConstants.IsDebugStage)
        // {
        //     const headers = new HttpHeaders()
        //         .set('X-AurorA-EntityId', 'f9244225-04e3-e511-80cd-005056ae7252');

        //     return this.getAll(`${this.resourceUrl}${endPoint}${qs}`, headers);
        // }

        return this.getAll(`${this.resourceUrl}${endPoint}${qs}`);
    }
}
