import { createSelector } from '@ngrx/store';

import { FloatingIp, Instance, Network, SecurityGroup } from '@apps/ostk/domain/models';
import { Address } from '@apps/ostk/models/instance';
import { EnumIpType } from '@apps/ostk/ostk.constants';

import { getAllNetworksFips } from './ostk.reducer';
import { getAllInstances } from './ostk-instances.reducer';

export type SgrpName = Pick<SecurityGroup, 'name'>;
export type NetwName = Pick<Network, 'name'>;
export type FipsAddr = Pick<FloatingIp, 'fixedIpAddress' | 'floatingIpAddress'>;
export type ShortInst = Pick<Instance, 'id' | 'name'>;

export const getOstkInstCount = createSelector(
    getAllInstances,
    (instances: Instance[]) =>
        instances.length
);
export const getOstkInstNames = createSelector(
    getAllInstances,
    (instances: Instance[]) =>
        instances.map((instance: Instance) =>
            instance.name
        )
);


// #region INST <=> SGRP
/**
 * @summary this.store.getOstkInstBySgrp({ name: 'phantom' })
 * @param {SgrpName} props
 *
 * @return {ShortInst[]} un tableau d'identifiant et nom des instances utilisant ce SecurityGroup
 */
export const getOstkInstBySgrp = createSelector(
    getAllInstances,
    (instances: Instance[], props: SgrpName) =>
        instances
            .filter((instance: Instance) =>
                instance.securityGroups
            )
            .filter((instance: Instance) =>
                instance.securityGroups
                    .some((sgrp: SecurityGroup) =>
                        sgrp.name === props.name
                    )
            )
            .map((instance: Instance) =>
                ({
                    id: instance.id,
                    name: instance.name
                }) as ShortInst
            )
);

/**
 * @summary this.store.isOstkSgrpUsedByInst({ name: 'phantom' })
 * @param {SgrpName} props
 *
 * @return {boolean} le statut utilisé ou non par au moins une instance du SecurityGroup dont le nom est passé en paramètre.
 */
export const isOstkSgrpUsedByInst = createSelector(
    getAllInstances,
    (instances: Instance[], props: SgrpName) =>
        getOstkInstBySgrp.projector(instances, props).length > 0
);

/**
 * @summary this.store.isOstkSgrpUsedByInst({ name: 'phantom' })
 * @param {{sgrp: SecurityGroup}} props
 *
 * @return {boolean} le statut deletable ou non du SecurityGroup passé en paramètre : non utilisé et sans règles => deletable
 */
export const isOstkSgrpDeletable = createSelector(
    getAllInstances,
    (instances: Instance[], props: { sgrp: SecurityGroup; }) =>
    {
        const isUsed = isOstkSgrpUsedByInst.projector(instances, { name: props.sgrp.name });

        return !(isUsed || props.sgrp.rules.length !== 0);
    }
);
// #endregion

// #region INST <=> FIPS
/**
 * @summary this.store.getOstkInstByFixedFips({ fixedIpAddress: '10.11.12.7', floatingIpAddress: '' })
 * @param {FipsAddr} props
 *
 * @return {ShortInst[]} un tableau d'identifiant et nom des instances utilisant cette adresse fixe
 */
export const getOstkInstByFixedFips = createSelector(
    getAllInstances,
    (instances: Instance[], props: FipsAddr) =>
        instances
            .filter((instance: Instance) =>
                Object.keys(instance.addresses)
                    .some((addressKey: string) =>
                        instance.addresses[addressKey]
                            .some((address: Address) =>
                                address.ipType === EnumIpType.FIXED && address.addr === props.fixedIpAddress
                            )
                    )
            )
            .map((instance: Instance) => ({
                id: instance.id,
                name: instance.name
            }) as ShortInst)
);

export const getOstkInstWithoutFloatingFips = createSelector(
    getAllInstances,
    (instances: Instance[]) =>
        instances
            .filter((instance: Instance) =>
                !Object.keys(instance.addresses)
                    .some((addressKey: string) =>
                        instance.addresses[addressKey]
                            .some((address: Address) =>
                                address.ipType === EnumIpType.FLOATING
                            )
                    )
            )
            .map((instance: Instance) => ({
                id: instance.id,
                name: instance.name
            }) as ShortInst)
);

/**
 * @summary this.store.isOstkFixedFipsUsedByInst({ fixedIpAddress: '10.11.12.7', floatingIpAddress: '' })
 * @param {FipsAddr} props
 *
 * @return {boolean} le statut utilisée ou non de cette adresse fixe
 */
export const isOstkFixedFipsUsedByInst = createSelector(
    getAllInstances,
    (instances: Instance[], props: FipsAddr) =>
        instances
            .some((instance: Instance) =>
                Object.keys(instance.addresses)
                    .some((addressKey: string) =>
                        instance.addresses[addressKey]
                            .some((address: Address) =>
                                address.ipType === EnumIpType.FIXED && address.addr === props.fixedIpAddress
                            )
                    )
            )
);

/**
 * @summary this.store.getOstkInstByFloatingFips({ fixedIpAddress: '', floatingIpAddress: '157.97.138.143' })
 * @param {FipsAddr} props
 *
 * @return {ShortInst[]} un tableau d'identifiant et nom des instances utilisant cette adresse fixe
 */
export const getOstkInstByFloatingFips = createSelector(
    getAllInstances,
    (instances: Instance[], props: FipsAddr) =>
        instances
            .filter((instance: Instance) =>
                Object.keys(instance.addresses)
                    .some((addressKey: string) =>
                        instance.addresses[addressKey]
                            .some((address: Address) =>
                                address.ipType === EnumIpType.FLOATING && address.addr === props.floatingIpAddress
                            )
                    )
            )
            .map((instance: Instance) => ({
                id: instance.id,
                name: instance.name
            }) as ShortInst)
);

/**
 * @summary this.store.isOstkFloatingFipsUsedByInst({ fixedIpAddress: '', floatingIpAddress: '157.97.138.143' })
 * @param {FipsAddr} props
 *
 * @return {boolean} le statut utilisée ou non de cette adresse flottante
 */
export const isOstkFloatingFipsUsedByInst = createSelector(
    getAllInstances,
    (instances: Instance[], props: FipsAddr) =>
        instances
            .some((instance: Instance) =>
                Object.keys(instance.addresses)
                    .some((addressKey: string) =>
                        instance.addresses[addressKey]
                            .some((address: Address) =>
                                address.ipType === EnumIpType.FLOATING && address.addr === props.floatingIpAddress
                            )
                    )
            )
);

export const getOstkFloatingIpsByInst = createSelector(
    getAllNetworksFips,
    (floatings: FloatingIp[], props: { instance: Instance; }) =>
    {
        const addresses: string[] = [];

        for (const key in props.instance.addresses)
        {
            const ipFixed = props.instance.addresses[key].find((address: Address) =>
                address.ipType === EnumIpType.FIXED
            );
            const ipFloating = props.instance.addresses[key].find((address: Address) =>
                address.ipType === EnumIpType.FLOATING
            );

            if (ipFixed)
            {
                addresses.push(ipFixed.addr);
            }

            if (ipFloating)
            {
                addresses.push(ipFloating.addr);
            }
        }

        return addresses;
    }
);
// #endregion


// #region INST <=> NETW
/**
 * @summary this.store.isOstkNetwUsedByInst({ name: 'phantom' })
 * @param {NetwName} props
 *
 * @return {boolean} le statut utilisé ou non par au moins une instance du Network dont le nom est passé en paramètre.
 */
export const isOstkNetwUsedByInst = createSelector(
    getAllInstances,
    (instances: Instance[], props: NetwName) =>
        instances
            .some((instance: Instance) =>
            {
                let result: boolean = false;

                for (const key in instance.addresses)
                {
                    result = result || key === props.name;
                }

                return result;
            })
);
/**
 * @summary this.store.getOstkInstByNetw({ name: 'my-network-name' })
 * @param {NetwName} props
 *
 * @return {ShortInst[]} un tableau d'identifiant et nom des instances utilisant ce network
 */
export const getOstkInstByNetw = createSelector(
    getAllInstances,
    (instances: Instance[], props: NetwName) =>
        instances
            .filter((instance: Instance) =>
                Object.keys(instance.addresses)
                    .some((addressKey: string) =>
                        addressKey === props.name
                    )
            )
            .map((instance: Instance) => ({
                id: instance.id,
                name: instance.name
            }) as ShortInst)
);

export const isOstkNetwDeletable = createSelector(
    getAllInstances,
    (instances: Instance[], props: { network: Network; }) =>
    {
        const isUsed = isOstkNetwUsedByInst.projector(instances, { name: props.network.name });

        return !(isUsed || props.network.subnets.length !== 0);
    }
);
// #endregion
