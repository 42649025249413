import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotfGravity, NotificationFailureService, NotificationService, NotifType, UtilsService } from '@common/services';
import { OstkFailure } from '@common/models';
import { AppConstants } from '@apps/app.constants';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { CmdbConstants } from '@apps/cmdb/cmdb.constants';
import { CompConstants } from '@apps/comp/comp.constants';
import { DafaConstants } from '@apps/dafa/dafa.constants';
import { ObjsConstants } from '@apps/objs/objs.constants';
import { OstkConstants } from '@apps/ostk/ostk.constants';
import { PrtgConstants } from '@apps/prtg/prtg-constants';
import { ReptConstants } from '@apps/rept/rept.constants';
import { ApplyCredentialsService } from '@common/interceptors/apply-credentials';
import { Its2Constants } from '@apps/its2/its2.constants';

@Injectable()
export class FailureInterceptor implements HttpInterceptor
{
    private static regexApi = /^(?:.+\/)(?<version>v\d)\/(?<api>[a-z]{4})(?<segments>.*\/)(?<module>[a-z]+)(?:.*)$/i;
    private method: string;
    private api: string;
    private version: string;
    private segments: string;
    private module: string;
    private details: string;
    constructor (
        private notificationService: NotificationService,
        private notificationFailureService: NotificationFailureService
    )
    { }

    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        if (!req.url.includes(AppConstants.UrlApi))
        {
            return next.handle(req);
        }

        /***
         * @abstract Les erreurs sur AUTH sont gérées par un interceptor dédié
         *
         * @see src\app\_common\interceptors\auth.interceptor.ts
        */
        if (ApplyCredentialsService.isAuthUrl(req))
        {
            return next.handle(req);
        }

        const matches = FailureInterceptor.regexApi.exec(req.url);

        this.method = req.method;
        this.version = matches.groups.version;
        this.api = matches.groups.api;
        this.segments = matches.groups.segments;
        this.module = matches.groups.module;
        // console.debug({ method: this.method, api: this.api, segments: this.segments, module: this.module });

        switch (this.api)
        {
            case Its2Constants.BaseApi:

                return next.handle(req)
                    .pipe(
                        catchError((err: any): Observable<any> =>
                        {
                            if (err instanceof HttpErrorResponse)
                            {
                                if (AppConstants.IsDebugStage)
                                {
                                    console.log({ req });
                                }
                            }

                            throwError((): any => err);

                            return of(null);
                        })
                    );

            case OstkConstants.BaseApi:

                return next.handle(req)
                    .pipe(
                        catchError((err: any): Observable<any> =>
                        {
                            if (err instanceof HttpErrorResponse)
                            {
                                try
                                {
                                    const failure: OstkFailure = <OstkFailure>err;

                                    if (AppConstants.IsDebugStage)
                                    {
                                        console.log({ err, req, failure });
                                    }

                                    this.notificationFailureService.notify(failure);
                                }
                                catch (ex: any)
                                // eslint-disable-next-line no-empty
                                { }
                            }

                            throwError((): any => err);

                            return of(null);
                        })
                    );

            case BckpConstants.BaseApi:

                return next.handle(req)
                    .pipe(
                        catchError((err: any): Observable<any> =>
                        {
                            if (err instanceof HttpErrorResponse)
                            {
                                let msg: string = '';

                                if (AppConstants.IsDebugStage)
                                {
                                    console.log({ err, req });
                                }

                                switch (this.module.toUpperCase())
                                {
                                    case 'EXPORT':
                                        msg = 'BACKUP.NOTF.EXPORT.MSGFAILED';
                                        break;

                                    default:
                                    {
                                        const isExport: boolean = this.segments.includes('export');

                                        msg = `BACKUP.NOTF.${isExport ? 'EXPORT' : 'LOAD'}.MSGFAILED`;
                                    }
                                }

                                if (!UtilsService.isNullOrEmpty(msg))
                                {
                                    this.notificationService.notify(
                                        [
                                            '',
                                            msg
                                        ],
                                        NotfGravity.danger,
                                        NotifType.SNACKBAR
                                    );
                                }
                            }

                            throwError((): any => err);

                            return of(null);
                        })
                    );

            case CmdbConstants.BaseApi:

                return next.handle(req)
                    .pipe(
                        catchError((err: any): Observable<any> =>
                        {
                            if (err instanceof HttpErrorResponse)
                            {
                                let msg: string = '';

                                if (AppConstants.IsDebugStage)
                                {
                                    console.log({ err, req });
                                }

                                // http://192.168.1.23:65490/api/v2/cmdb/assets/SRVAAZ02MSDP01/HOST/E5047246-95C5-42D4-8CD1-7917EECE310D
                                const isAsset: boolean = this.segments.includes('assets');
                                switch (this.method.toUpperCase())
                                {
                                    case 'POST':
                                        if (isAsset)
                                        {
                                            msg = 'CMDB.ASSETS.ATTACH.MSGFAILED';
                                        }
                                        else
                                        {
                                            msg = 'CMDB.TAGS.CREATE.NOTF.MSGFAILED';
                                        }

                                        break;
                                    case 'DELETE':
                                        if (isAsset)
                                        {
                                            msg = 'CMDB.ASSETS.DETACH.MSGFAILED';
                                        }
                                        else
                                        {
                                            msg = 'CMDB.TAGS.DELETE.NOTF.MSGFAILED';
                                        }

                                        break;
                                    case 'UPDATE':
                                        msg = 'CMDB.TAGS.UPDATE.NOTF.MSGFAILED';

                                        break;
                                    default:
                                }

                                if (!UtilsService.isNullOrEmpty(msg))
                                {
                                    this.notificationService.notify(
                                        [
                                            '',
                                            msg
                                        ],
                                        NotfGravity.danger,
                                        NotifType.SNACKBAR
                                    );
                                }
                            }

                            throwError((): any => err);

                            return of(null);
                        })
                    );

            case ObjsConstants.BaseApi:
            case CompConstants.BaseApi:
            case DafaConstants.BaseApi:
            case PrtgConstants.BaseApi:
            case ReptConstants.BaseApi:
            default:
                //     if (AppConstants.IsDebugStage)
                //     {
                //         console.log({ api: this.api });
                //     }

                return next.handle(req);
        }
    }
}
