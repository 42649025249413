import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { ColorLevel, ColorName, ColorsService } from '@common/services';
import { OpenPopinOriginator } from '@common/enum/open-popin-originator.enum';
import { SelectOption } from '@ui/components/form/common';
import { TypePopin } from '@apps/app.constants';
import { BckpFeatureKey } from '@apps/bckp/bckp.constants';
import { UrgencyTradKey } from '@apps/its2/enum';
import { DashFeatureKey } from '@apps/dash/dash.constants';
import { Request } from '@apps/its2/domain/models';

export enum RequestTypeEnum
    {
    SERVICES = 'services',
    INCIDENTS = 'incidents'
}

export enum WaitingStatus
    {
    WaitingValidation = 'DC97DD1D-0F35-4153-B0E1-0F2E0155365D',
    WaitingComplement = '6A2086B8-83A3-4E5B-B4C5-8E3000BD6E9B'
}

export type RequestStatus = Pick<Request, 'statusId' | 'status'>;
export enum RequestStatusIdEnum
    {
    SOLVED = 2,
    CLOSED = 8
}

export enum ValidatedActionTypeIdEnum
    {
    Validation = 1,
    Satisfaction = 91
}

export enum UNCATEGORIZED
    {
    FR = 'Non catégorisé',
    EN = 'Uncategorized'
}

export enum SlaStatus
    {
    GOOD = 1,
    ACCEPTABLE = 0,
    BAD = -1
}

export const Its2FeatureKey = 'its2';
export const ItsmFeatureKey = 'itsm';

export class Its2Constants
{
    public static Application: string = 'ItsmApi';
    public static BaseApi: string = 'itsm';
    public static ItsmFeature = 'itsm';
    public static BaseUrl: string = 'service';
    public static Requests: string = 'requests';
    public static Dashboard: string = 'dashboard';
    public static Filter: string = 'filter';
    public static Reply: string = 'reply';
    public static Validate: string = 'validate';
    public static DefaultUrl: string = `/${Its2Constants.BaseUrl}/${Its2Constants.Dashboard}`;
    public static ClosedStatusId: number = 8;

    // Durée de vie en minutes des éléments dans le store
    public static DataLifeTime: number = 10;
    public static PollingInterval = 2 * 60 * 1000;

    public static BeginningOfTime = DateTime.utc(2021).startOf('year');
    public static QSRequestsTop: number = 50;

    public static SatisfactionMax: number = 5;
    public static MaximumSlaValue: number = 100;

    /**
     * @description GUID code independent of the execution platform
     */
    public static CatalogEntryGuid: any = {
        [DashFeatureKey] :
        {
            [RequestTypeEnum.INCIDENTS]: '932E417B-2798-4E45-9918-7FB6A9CB49D5',
            [RequestTypeEnum.SERVICES]: '2BD6E0E2-E21F-40D9-B10F-34CA9A28B239'
        }
    };

    /**
     * @description GUID code independent of the execution platform
     */
    public static CompletedMetaStatusGuid = 'FADD193C-E50F-4AB8-BE4B-928D6E2B2CD4';
    public static CatalogEntryMetaGuid: any = {
        [BckpFeatureKey]:
        {
            [RequestTypeEnum.INCIDENTS]: '4950637C-6539-4E2D-8D22-7BEA8FA3AEDA',
            [RequestTypeEnum.SERVICES]: '6395DB94-0A80-48D3-8DB9-8C1BB46C7A8B'
        },
        ['secu']:
        {
            [RequestTypeEnum.INCIDENTS]: 'fa8afb18-b905-4fcb-b0f3-ef057f228082',
            [RequestTypeEnum.SERVICES]: '6b884490-11a3-457c-9774-e6250ef0d8f3'
        }
    };
    public static getUrgencyTradKey = (s: number) =>
    {
        switch (s)
        {
            case 1:
                return UrgencyTradKey.High;
            case 2:
                return UrgencyTradKey.Standard;
            case 3:
                return UrgencyTradKey.Low;
        }
    };

    public static getUrgencyLabelKey = (u: number) =>
        Its2Constants.RequestUrgency.get(u);
    public static requestsQSFields: Array<keyof Request> = [ 'rfcNumber', 'title', 'shortComment',
        'recipientName', 'catalogPath', 'recipientEmail' ];

    public static RequestFavorite = new Map([
        [true, 'ITSM.POPUP.FILTER.FAVORITES.TRUE'],
        [false, 'ITSM.POPUP.FILTER.FAVORITES.FALSE']
    ]);

    public static RequestRead = new Map([
        [true, 'ITSM.POPUP.FILTER.READ.TRUE'],
        [false, 'ITSM.POPUP.FILTER.READ.FALSE']
    ]);

    public static RequestType = new Map([
        ['C', 'ITSM.POPUP.FILTER.REQUESTTYPES.CHANGE'],
        ['I', 'ITSM.POPUP.FILTER.REQUESTTYPES.INCIDENT'],
        ['S', 'ITSM.POPUP.FILTER.REQUESTTYPES.SERVICE']
    ]);

    // Urgence des tickets
    public static RequestUrgency = new Map([
        [1, 'DASH.ITSM.CONSTANTS.URGENCY.HIGH'],
        [2, 'DASH.ITSM.CONSTANTS.URGENCY.STANDARD'],
        [3, 'DASH.ITSM.CONSTANTS.URGENCY.LOW']
    ]);
    public static InvertRequestUrgencyEnUs = new Map<string, number>([
        ['HIGH', 1],
        ['MEDIUM', 2],
        ['LOW', 3]
    ]);
    public static InvertRequestUrgencyFrFr = new Map<string, number>([
        ['HAUTE', 1],
        ['MOYENNE', 2],
        ['FAIBLE', 3]
    ]);
    public static SlaForeColorMap: Map<SlaStatus, ColorName> = new Map([
        [SlaStatus.BAD, ColorName.Danger ],
        [SlaStatus.ACCEPTABLE, ColorName.Warning1 ],
        [SlaStatus.GOOD, ColorName.Success]
    ]);

    public static getUrgencyOptions (translateService: TranslateService): SelectOption[]
    {
        const options: SelectOption[] = [];

        Its2Constants.RequestUrgency.forEach((value, key) =>
            options.push(new SelectOption(key, translateService.instant(value), true))
        );

        return options;
    }

    public static getStatusOptions (statuses: RequestStatus[], language: string): SelectOption[]
    {
        const options: SelectOption[] = [];

        statuses.forEach((rs: RequestStatus) =>
            options.push(
                new SelectOption(
                    rs.statusId,
                    rs.status[language],
                    true
                )
            )
        );

        return options;
    }

    public static getTypeOptions (translateService: TranslateService): SelectOption[]
    {
        const options: SelectOption[] = [];

        Its2Constants.RequestType.forEach((value, key) =>
            options.push(new SelectOption(key, translateService.instant(value), true))
        );

        return options;
    }

    public static getFavoriteOptions (translateService: TranslateService): SelectOption[]
    {
        const options: SelectOption[] = [];

        Its2Constants.RequestFavorite.forEach((value, key) =>
            options.push(new SelectOption(key, translateService.instant(value), true))
        );

        return options;
    }

    public static getReadOptions (translateService: TranslateService): SelectOption[]
    {
        const options: SelectOption[] = [];

        Its2Constants.RequestRead.forEach((value, key) =>
            options.push(new SelectOption(key, translateService.instant(value), true))
        );

        return options;
    }

    public static RequestsRoute = () =>
        [
            Its2Constants.BaseUrl,
            Its2Constants.Requests
        ];
    public static RequestsPath = `/${Its2Constants.RequestsRoute().join('/')}`;
    public static DashboardRoute = () =>
        [
            Its2Constants.BaseUrl,
            Its2Constants.Dashboard
        ];
    public static DashFilterRoute = () =>
        [
            ...Its2Constants.DashboardRoute(),
            { outlets: { [TypePopin.FILTER]: [Its2Constants.Filter] } }
        ];
    public static RequestsDetailsRoute = (rfcNumber: string) =>
        [
            Its2Constants.BaseUrl,
            Its2Constants.Requests,
            { outlets: { [TypePopin.DETAILS]: [ OpenPopinOriginator.ITS2_REQUESTS, rfcNumber ] } }
        ];
    public static RequestsDetailsPath = (rfcNumber: string): string =>
        `/${Its2Constants.BaseUrl}/${Its2Constants.Requests}/(${TypePopin.DETAILS}:${OpenPopinOriginator.ITS2_REQUESTS}/${rfcNumber})`;

    public static RequestsReplyPath = (rfcNumber: string): string =>
        `/${Its2Constants.BaseUrl}/${Its2Constants.Requests}/(${TypePopin.REPLY}:${OpenPopinOriginator.ITS2_REQUESTS}/${rfcNumber})`;

    public static RequestsValidatePath = (rfcNumber: string): string =>
        `/${Its2Constants.BaseUrl}/${Its2Constants.Requests}/(${TypePopin.VALIDATE}:${OpenPopinOriginator.ITS2_REQUESTS}/${rfcNumber})`;

    public static CreateCaseRoute = (openPopinOriginator: OpenPopinOriginator) =>
        [
            Its2Constants.BaseUrl,
            Its2Constants.Requests,
            { outlets: { [TypePopin.ACTION]: [ 'createCase', openPopinOriginator ] } }
        ];

    public static CreateServiceRoute = (openPopinOriginator: OpenPopinOriginator) =>
        [
            Its2Constants.BaseUrl,
            Its2Constants.Requests,
            { outlets: { [TypePopin.ACTION]: [ 'createService', openPopinOriginator ] } }
        ];

    public static getSlaStatus (percentSla: number): SlaStatus
    {
        if (percentSla > 100)
        {
            return SlaStatus.BAD;
        }

        if (percentSla > 80)
        {
            return SlaStatus.ACCEPTABLE;
        }

        return SlaStatus.GOOD;
    }

    public static getSlaForeColor = (slaStatus: SlaStatus) =>
        ColorsService.getMessageColor(ColorLevel.Normal, Its2Constants.SlaForeColorMap.get(slaStatus));

    public static getSlaStatusIcon (slaStatus: SlaStatus): string
    {
        switch (slaStatus)
        {
            case SlaStatus.GOOD:
                return 'fas fa-fw fa-thumbs-up aa-color-msg-success-normal';
            case SlaStatus.ACCEPTABLE:
                return 'fas fa-fw fa-thumbs-up aa-color-msg-warning1-normal';
            case SlaStatus.BAD:
                return 'fas fa-fw fa-thumbs-down aa-color-msg-danger-normal';
            default:
                return 'fas fa-fw';
        }
    }

}
