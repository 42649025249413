import { DateTime } from 'luxon';

import { User } from '@apps/auth/domain/models/back';
import { AppliRole } from './appli-role';

export class PartialUser
{
    public id: string;
    public userName: string;
    public email: string;
    public firstName: string;
    public lastName: string;
    public title: string;
    public phoneNumber: string;
    public mobile: string;
    public createdAt: DateTime;
    public modifiedAt: DateTime;
    public lastLoginUtc: any;
    public applications: AppliRole[];
    public isBusinessHoursContact: boolean;
    public isOutOfBusinessHoursContact: boolean;

    public static mapperFromUser (user: User): PartialUser
    {
        const partialUser = new PartialUser();

        partialUser.id = user.id;
        partialUser.userName = user.userName;
        partialUser.email = user.email;
        partialUser.firstName = user.firstName;
        partialUser.lastName = user.lastName;
        partialUser.title = user.title;
        partialUser.phoneNumber = user.phoneNumber;
        partialUser.mobile = user.mobile;
        partialUser.createdAt = user.createdAt;
        partialUser.modifiedAt = user.modifiedAt;
        partialUser.lastLoginUtc = user.lastLoginUtc;
        partialUser.isBusinessHoursContact = user.isBusinessHoursContact;
        partialUser.isOutOfBusinessHoursContact = user.isOutOfBusinessHoursContact;

        partialUser.applications = (user.appRoles ?? []).map(a =>
            new AppliRole(a.application, AppliRole.LevelByRole(a.role))
        );

        return partialUser;
    }
}