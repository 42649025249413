
import { DateTime } from 'luxon';

import { UserApi } from '@apps/auth/api/models/back/user-api';
import { BackConstants } from '@apps/back/back.constants';
import { Profile } from '@apps/auth/models';
import { UtilsService } from '@common/services';

export class User extends Profile
{
    // #region Propriétés de Profile
    // firstName?: string;
    // lastName?: string;
    // firstLastName?: string;
    // appRoles?: ApplicationRole[];
    // subscriptions?: string[];
    // id?: string;
    // identiconDataUrl?: string;
    // userName?: string;
    // normalizedUserName?: string;
    // email?: string;
    // normalizedEmail?: string;
    // emailConfirmed?: boolean;
    // phoneNumber?: string;
    // phoneNumberConfirmed?: boolean;
    // entityId?: string;
    // entityName?: string;
    // title?: string;
    // marketingNewsletter?: boolean;
    // maintenanceNotification?: boolean;
    // language?: string;
    // skin?: Skin;
    // isEntityAdmin?: boolean;
    // workingCookies?: boolean;
    // statisticsCookies?: boolean;
    // socialNetworksCookies?: boolean;
    // nextProfileCompletionUtc?: DateTime;
    // twoFactorEnabled?: boolean;
    // entityShortId?: string;
    // #endregion

    active?: boolean;
    lastFirstName: string;
    mobile?: string;
    external: boolean;
    statusKey: string;
    statusClass: string;
    isExploit: boolean;
    exploitKey: string;
    exploitClass: string;
    firstLastNameAddress: string;
    lastLoginUtc: DateTime;
    formatedLastLoginUtc: string = '';
    createdAt: DateTime;
    formatedCreatedAt: string;
    modifiedAt: DateTime;
    formatedModifiedAt: string = '';
    isContact: boolean;
    isBusinessHoursContact: boolean;
    isOutOfBusinessHoursContact: boolean;
}

export class DomainUser extends User
{
    public static mapperFromApi (source: UserApi): User
    {
        const target = new User();

        Object.assign(target, Profile.mapperFromApi(source));

        target.firstName = source?.firstName ?? '';
        target.lastName = source?.lastName ?? '';
        let lastFirstNameSeparator: string = ' ';
        if (target.firstName === '' || target.lastName === '')
        {
            lastFirstNameSeparator = '';
        }

        target.title = target.title ?? ' ';

        target.firstLastName = `${target.firstName}${lastFirstNameSeparator}${target.lastName}`;
        target.firstLastNameAddress = !UtilsService.isNullOrEmpty(source.email) ? `${target.firstLastName} <${source.email}>` : source.id;

        if (source.active === null)
        {
            target.active = false;
        }

        target.statusKey = `STATUSES.${target.active ? 'ACTIVE' : 'INACTIVE'}`;
        target.statusClass = BackConstants.getCssStatus(target.active);

        target.isExploit = source.email.toUpperCase().includes(BackConstants.ExploitAccount);
        target.exploitKey = 'STATUSES.WITH_EXPLOIT';
        target.exploitClass = BackConstants.getCssExploit(target.isExploit);

        if ( source.lastLoginUtc )
        {
            target.lastLoginUtc = DateTime.fromISO(source.lastLoginUtc);
        }

        target.createdAt = DateTime.fromISO(source.createdAt);

        if (source.modifiedAt)
        {
            target.modifiedAt = DateTime.fromISO(source.modifiedAt);
        }

        delete target['roles'];

        return target;
    }
}
