import { createAction, props } from '@ngrx/store';

import { Flavor } from '../../domain/models';

export const OstkFlavorsListRequested = createAction(
    '[OSTK-Flavors] Ostk Flavors List Requested'
);
export const OstkFlavorsListSucceeded = createAction(
    '[OSTK-Flavors] Ostk Flavors List Succeeded',
    props<{ flavors: Flavor[] }>()
);
export const OstkFlavorsListFailed = createAction(
    '[OSTK-Flavors] Ostk Flavors List Failed',
    props<{ error: any }>()
);
