import { Component, forwardRef, Input, Optional, Inject, Injector, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { SelectOption, OptionValue } from '../../../common/select-option';
import { ElementBase } from '../../../common';

@Component({
    selector: 'aa-checkbox-group',
    templateUrl: './checkbox-group.component.html',
    styleUrls: [
        './checkbox-group.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxGroupComponent),
            multi: true
        }
    ]
})
export class CheckboxGroupComponent extends ElementBase<OptionValue[]> implements OnInit
{
    @Input() options: SelectOption[];
    @Input() orientation?: string = 'horizontal';
    @Input() toTranslate: boolean = false;
    @Input() lineSize: number = 0;
    public lines: number[];

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
        injector: Injector
    )
    {
        super(injector);
    }

    ngOnInit ()
    {
        if (this.lineSize !== 0)
        {
            const nbLines = Math.ceil(this.options.length / this.lineSize);

            this.lines = Array.from(Array(nbLines));
        }
    }

    public addOrRemove (v: OptionValue): void
    {
        if (this.contains(v))
        {
            this.remove(v);
        } else
        {
            this.add(v);
        }
    }

    public contains (v: OptionValue): boolean
    {
        if (Array.isArray(this.value))
        {
            return this.value.indexOf(v) > -1;
        } else if (!!this.value)
        {
            return this.value === v;
        }

        return false;
    }

    private add (v: OptionValue): void
    {
        if (Array.isArray(this.value))
        {
            this.value.push(v);
        } else
        {
            this.value = [v];
        }
    }

    private remove (value: OptionValue): void
    {
        const index = this.value.indexOf(value);

        if (!this.value || index < 0)
        {
            return;
        }

        this.value.splice(index, 1);
    }
}
