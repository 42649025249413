import { OnInit, Component, Input, Host, AfterContentInit } from '@angular/core';

import { CheckboxGroupComponent } from '../checkbox-group/checkbox-group.component';
import { SelectOption } from '../../../common/select-option';

@Component({
    selector: 'aa-checkbox',
    templateUrl: './checkbox.component.html'
})
export class CheckboxComponent implements OnInit, AfterContentInit
{
    @Input() option: SelectOption;
    @Input() toTranslate: boolean = false;
    public isChecked: boolean = false;
    public optionId: string;

    constructor(@Host() private checkboxGroup: CheckboxGroupComponent)
    {
    }

    ngOnInit ()
    {
        this.optionId = `id-${this.option.label}-${this.option.value}`;
    }

    ngAfterContentInit (): void
    {
        this.isChecked = this.checkboxGroup.contains(this.option.value);
    }

    toggleCheck ()
    {
        this.checkboxGroup.addOrRemove(this.option.value);
        this.isChecked = this.checkboxGroup.contains(this.option.value);
    }
}
