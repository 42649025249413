import { StatusApi } from '../../api/models/status-api';

export class Status
{
    public status: number;
    public counter: number = 0;
    public codeStatus?: number;

    /**
     *
     */
    constructor (status: number, counter: number, codeStatus?: number)
    {
        this.status = status;
        this.counter = counter;
        if (codeStatus !== null && codeStatus !== undefined)
        {
            this.codeStatus = codeStatus;
        }
        else
        {
            this.calcCodeStatus();
        }
    }

    public static mapperFromApi (source: StatusApi): Status
    {
        const status = parseInt(source.status, 10);
        const target = new Status(status, source.count);

        return target;
    }

    public calcCodeStatus (): void
    {
        this.codeStatus = this.status > 1 ? 2 : this.status;
    }
}
