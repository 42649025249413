import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';


import * as fromAttacksActions from './attacks.actions';
import { Country, Protocol } from '../../domain/models';
import { SecuConstants } from '../../secu-constants';
import { csvParseRows } from 'd3';
export const SecuAttacksFeatureKey = 'attacks';
interface ICountriesState extends EntityState<Country> { }
interface IProtocolsState extends EntityState<Protocol> { }
export interface ISecuAttacksState
{
    zoomLevel: number;
    countries: ICountriesState;
    protocols: IProtocolsState;
}
export const countriesAdapter: EntityAdapter<Country> = createEntityAdapter({
    selectId: (country) =>
        country.codeIso,
    sortComparer: (ca: Country, cb: Country): number =>
        cb.counter - ca.counter
});
const countriesInitialState: ICountriesState = countriesAdapter.getInitialState({});

export const protocolsAdapter: EntityAdapter<Protocol> = createEntityAdapter({
    selectId: (protocol) =>
        protocol.name,
    sortComparer: (pa: Protocol, pb: Protocol): number =>
        pb.counter - pa.counter
});
const protocolsInitialState: IProtocolsState = protocolsAdapter.getInitialState({});

export const SecuAttacksinitialState: ISecuAttacksState = {
    zoomLevel: SecuConstants.MinZoomLevel,
    countries: countriesInitialState,
    protocols: protocolsInitialState
};

export const SecuAttacksReducer = createReducer(
    SecuAttacksinitialState,
    on(
        fromAttacksActions.SecuAttacksCountryAdded,
        (state, { countries }) =>
        {
            const cs: Country[] = [];
            countries.forEach((country: Country) =>
            {
                let c = state.countries.entities[country.codeIso];

                if (c)
                {
                    c.counter += country.counter;
                } else
                {
                    c = country;
                }

                cs.push(c);
            });

            return {
                ...state,
                countries: countriesAdapter.upsertMany(cs, state.countries)
            };
        }
    ),
    on(
        fromAttacksActions.SecuAttacksProtocolAdded,
        (state, { protocols }) =>
        {
            const ps: Protocol[] = [];

            protocols.forEach((protocol: Protocol) =>
            {
                let p = state.protocols.entities[protocol.name];
                if (p)
                {
                    p.counter += protocol.counter;
                } else
                {
                    p = protocol;
                }

                ps.push(p);
            });

            return {
                ...state,
                protocols: protocolsAdapter.upsertMany(ps, state.protocols)
            };
        }
    ),
    on(
        fromAttacksActions.SecuAttacksZoomLevelChanged,
        (state, { zoomLevel }) => ({
            ...state,
            zoomLevel: zoomLevel
        })
    )
);
