import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DateTime } from 'luxon';

import * as fromOstkSecurityKeypActions from './keyp.actions';
import * as fromOstkProjectsActions from '../../projects/projects.actions';
import * as fromAuthLoginActions from 'src/app/_apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from 'src/app/_apps/auth/store/profile/profile.actions';
import { KeyPair } from '../../../domain/models/security/key-pair';
import { OstkConstants } from '../../../ostk.constants';

export const OstkSecurityKeypFeatureKey = 'keyp';

type ISecurityKeypState = EntityState<KeyPair>;
export const OstkSecurityKeypAdapter: EntityAdapter<KeyPair> = createEntityAdapter({
    selectId: (keyPair: KeyPair) => keyPair.name,
    sortComparer: (keypa: KeyPair, keypb: KeyPair) =>
        keypa.name.toLowerCase().localeCompare(keypb.name.toLowerCase())
});
const securityKeypInitialState: ISecurityKeypState = OstkSecurityKeypAdapter.getInitialState({});
export interface IOstkSecurityKeypState
{
    keyPairs: ISecurityKeypState;
    isEmpty: boolean;
    isLoading: boolean;
    isOups: boolean;
    isLoaded: boolean;
    expirationDate?: DateTime;
    qsWord: string;
}

export const OstkSecurityKeypInitialState: IOstkSecurityKeypState = {
    keyPairs: securityKeypInitialState,
    isEmpty: false,
    isLoading: true,
    isOups: false,
    isLoaded: false,
    expirationDate: null,
    qsWord: ''
};

export const OstkKeyPairsReducer = createReducer(
    OstkSecurityKeypInitialState,
    on(
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        fromOstkProjectsActions.OstkProjectSelected,
        () => OstkSecurityKeypInitialState
    ),
    on(
        fromOstkSecurityKeypActions.OstkSecurityKeypListRequested,
        (state) =>
            ({
                ...state,
                isLoading: !(state.expirationDate !== null && DateTime.utc() < state.expirationDate)
            }),
    ),
    on(
        fromOstkSecurityKeypActions.OstkSecurityKeypListSucceeded,
        (state, { keyPairs }) =>
            ({
                ...state,
                isEmpty: keyPairs && keyPairs.length === 0,
                isLoading: false,
                isOups: false,
                isLoaded: !(keyPairs && keyPairs.length === 0),
                keyPairs: OstkSecurityKeypAdapter.setAll(keyPairs, state.keyPairs),
                expirationDate: DateTime.utc().plus({ minutes: OstkConstants.DataLifeTime })
            }),
    ),
    on(
        fromOstkSecurityKeypActions.OstkSecurityKeypListFailed,
        (state) =>
            ({
                ...state,
                isLoading: false,
                isEmpty: false,
                isOups: true,
                isLoaded: false,
                keyPairs: OstkSecurityKeypAdapter.removeAll(state.keyPairs),
                expirationDate: null
            }),
    ),
    on(
        fromOstkSecurityKeypActions.OstkSecurityKeypCreationSucceeded,
        (state, data) =>
            ({
                ...state,
                keyPairs: OstkSecurityKeypAdapter.addOne(data.keyPair, state.keyPairs)
            })
    ),
    on(
        fromOstkSecurityKeypActions.OstkSecurityKeypDeletionSucceeded,
        (state, { keyPair }) =>
            ({
                ...state,
                keyPairs: OstkSecurityKeypAdapter.removeOne(keyPair.name, state.keyPairs)
            })
    ),
    on(
        fromOstkSecurityKeypActions.OstkKeyPairsQsWordSet,
        (state, { qsWord }) =>
            ({
                ...state,
                qsWord: qsWord.toLowerCase()
            })
    )
);
