import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lightPath'
})
export class LightPathPipe implements PipeTransform
{
    transform (fullPath: string): any
    {
        if (!fullPath)
        {
            return '';
        }

        const splittedPath = fullPath.split('/');
        const fileName = splittedPath[splittedPath.length - 1]
            ? splittedPath[splittedPath.length - 1]
            : splittedPath[splittedPath.length - 2];

        return fullPath.endsWith('/') ? fileName + '/' : fileName;
    }
}
