import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import * as fromOstkProjectsActions from '../projects/projects.actions';
import * as fromAuthLoginActions from 'src/app/_apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from 'src/app/_apps/auth/store/profile/profile.actions';
import * as FromOstkFlavorsActions from './flavors.actions';
import { OstkConstants } from '../../ostk.constants';
import { Flavor } from '../../domain/models';

export const OstkFlavorsFeatureKey = 'flavors';
interface IFlavorsState extends EntityState<Flavor> { }
export const flavorsAdapter: EntityAdapter<Flavor> = createEntityAdapter({
    selectId: (flavor: Flavor) => flavor.id,
    sortComparer: (flava: Flavor, flavb: Flavor) => flava.vcpu - flavb.vcpu
});
const flavorInitialState: IFlavorsState = flavorsAdapter.getInitialState({});
export interface IOstkFlavorsState
{
    flavors: IFlavorsState;
    isEmpty: boolean;
    isLoading: boolean;
    isOups: boolean;
    isLoaded: boolean;
    expirationDate?: DateTime;
}

const initialState: IOstkFlavorsState = {
    flavors: flavorInitialState,
    isEmpty: false,
    isLoading: false,
    isOups: false,
    isLoaded: false,
    expirationDate: null
};

export const OstkFlavorsReducers = createReducer(
    initialState,
    on(
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        fromOstkProjectsActions.OstkProjectSelected,
        () => initialState
    ),
    on(
        FromOstkFlavorsActions.OstkFlavorsListRequested,
        (state) =>
            ({
                ...state,
                isLoading: !(state.expirationDate !== null && DateTime.utc() < state.expirationDate)
            }),
    ),
    on(
        FromOstkFlavorsActions.OstkFlavorsListSucceeded,
        (state, { flavors }: { flavors: Flavor[] }) =>
            ({
                ...state,
                isEmpty: flavors && flavors.length === 0,
                isLoading: false,
                isLoaded: true,
                flavors: flavorsAdapter.setAll(flavors, state.flavors),
                expirationDate: DateTime.utc().plus({ minutes: OstkConstants.DataLifeTime })
            }),
    ),
    on(
        FromOstkFlavorsActions.OstkFlavorsListFailed,
        (state) =>
            ({
                ...state,
                isLoading: false,
                isEmpty: false,
                isOups: true,
                isLoaded: false,
                expirationDate: null
            }),
    )
);
