import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { RequestActionDetails } from '@apps/its2/domain/models';

export const Its2RequestsActionsRequested = createAction(
    '[ITS2-REQUEST] Its2 Requests Actions Requested',
    props<{ rfcNumber: string; }>()
);

export const Its2RequestsActionsSucceeded = createAction(
    '[ITS2-REQUEST] Its2 Requests Actions Succeeded',
    props<{ detailedRequestActions: RequestActionDetails[]; }>()
);

export const Its2RequestsActionsFailed = createAction(
    '[ITS2-REQUEST] Its2 Requests Actions Failed',
    props<{ error: any; }>()
);

export const Its2RequestsDetailedActionRequested = createAction(
    '[ITS2-REQUEST] Its2 Requests Detailed Action Requested',
    props<{ rfcNumber: string, requestActionDetails: RequestActionDetails }>()
);

export const Its2RequestsDetailedActionSucceeded = createAction(
    '[ITS2-REQUEST] Its2 Requests Detailed Action Succeeded',
    props<{ requestActionDetails: Update<RequestActionDetails> }>()
);

export const Its2RequestsDetailedActionFailed = createAction(
    '[ITS2-REQUEST] Its2 Requests Detailed Action Failed',
    props<{ error: any; }>()
);

export const Its2RequestsLastCommentRequested = createAction(
    '[ITS2-REQUEST] Its2 Requests Last Comment Requested',
    props<{ rfcNumber: string; detailedRequestActions: RequestActionDetails[] }>()
);

export const Its2RequestsLastCommentSucceeded = createAction(
    '[ITS2-REQUEST] Its2 Requests Last Comment Succeeded',
    props<{ lastComment: string; }>()
);

export const Its2RequestsLastCommentFailed = createAction(
    '[ITS2-REQUEST] Its2 Requests Last Comment Failed',
    props<{ error: any; }>()
);

export const Its2RequestsDetailedActionExpand = createAction(
    '[ITS2-REQUEST] Its2 Requests Detailed Action Expand',
    props<{ requestActionDetails: RequestActionDetails, value: boolean }>()
);

export const Its2RequestsDetailedActionExpandAllRequested = createAction(
    '[ITS2-REQUEST] Its2 Requests Detailed Action Expand All',
    props<{ rfcNumber: string, value: boolean }>()
);
