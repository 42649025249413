import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as fromRootImpeActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import * as fromMiscStoreActions from '@apps/its2/store/misc/misc.actions';
import { StoreCustomFile } from '@common/models/custom-file';
import { CreateService, Step1, Step2, Step3, Step4 } from '@apps/its2/models/create-service';

export const Its2MiscFeatureKey = 'misc';

export const adapterFile = createEntityAdapter<StoreCustomFile>(
    {
        selectId: (cf: StoreCustomFile) => cf.id
    }
);
type FileState = EntityState<StoreCustomFile>;
const fileInitialState: FileState = adapterFile.getInitialState();

export interface IIts2MiscState
{
    cs: CreateService;
    files: FileState;
}

const InitialState: IIts2MiscState = {
    cs: null,
    files: fileInitialState
};

export const Its2MiscReducer = createReducer(
    InitialState,
    on(
        fromRootImpeActions.RootImpersonateSet,
        fromRootImpeActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () =>
            InitialState
    ),
    on(
        fromMiscStoreActions.Its2RequestsServiceCreateStep1Entered,
        (state: IIts2MiscState, { currentLang }: { currentLang: string;}) => ({
            ...state,
            cs: new CreateService(currentLang)
        })
    ),
    on(
        fromMiscStoreActions.Its2RequestsServiceCreateStep1Exited,
        (state: IIts2MiscState, { step1 }: { step1: Step1; }) =>
            ({
                ...state,
                cs: {
                    ...state.cs,
                    step1
                }
            })
    ),
    on(
        fromMiscStoreActions.Its2RequestsServiceCreateStep2Exited,
        (state: IIts2MiscState, { step2 }: { step2: Step2; }) =>
            ({
                ...state,
                cs: {
                    ...state.cs,
                    step2
                }
            })
    ),
    on(
        fromMiscStoreActions.Its2RequestsServiceCreateStep3Exited,
        (state: IIts2MiscState, { step3 }: { step3: Step3; }) =>
            ({
                ...state,
                cs: {
                    ...state.cs,
                    step3
                }
            })
    ),
    on(
        fromMiscStoreActions.Its2RequestsServiceCreateStep4Exited,
        (state: IIts2MiscState, { step4 }: { step4: Step4; }) =>
            ({
                ...state,
                cs: {
                    ...state.cs,
                    step4
                }
            })
    ),
    on(
        fromMiscStoreActions.Its2RequestsServiceCreateSetSkipped,
        (state: IIts2MiscState, { idx, isSkipped }: { idx: number, isSkipped: boolean; }) =>
            ({
                ...state,
                cs: {
                    ...state.cs,
                    [`step${idx}`]: {
                        ...state.cs[`step${idx}`],
                        skipped: isSkipped
                    }
                }
            })
    ),
    on(
        fromMiscStoreActions.Its2RequestsFileAdded,
        (state: IIts2MiscState, { file }: {file: StoreCustomFile}) => ({
            ...state,
            files: adapterFile.addOne(file, state.files)
        })
    ),
    on(
        fromMiscStoreActions.Its2RequestsFileRemoved,
        (state: IIts2MiscState, { id }) => ({
            ...state,
            files: adapterFile.removeOne(id, state.files)
        })
    ),
    on(
        fromMiscStoreActions.Its2RequestsFileClearRequested,
        (state: IIts2MiscState) => ({
            ...state,
            files: adapterFile.removeAll(state.files)
        })
    ),
    on(
        fromMiscStoreActions.Its2RequestsFileStatusChanged,
        (state: IIts2MiscState, { file }: {file: Update<StoreCustomFile>}) => ({
            ...state,
            files: adapterFile.updateOne(file, state.files)
        })
    ),
    on(
        fromMiscStoreActions.Its2RequestsServiceCreatePopinCloseRequested,
        () =>
            InitialState
    )
);
