import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { SecurityMiscApiService } from '@apps/ostk/api/security/security-misc-api.service';
import { SecurityGroup, KeyPair, SecurityRule } from '@apps/ostk/domain/models';
import { KeyPairApi, SecurityGroupApi } from '@apps/ostk/api/models';

import { CreateSecurityRuleViewModel } from '../models/security/security-rule';
import { CreateSecurityGroupViewModel } from '../models/security/security-group';

@Injectable({
    providedIn: 'root'
})
export class SecurityMiscDomainService extends BaseMiscDomainService
{
    constructor (
        private securityMiscApiService: SecurityMiscApiService
    )
    {
        super();
    }

    getSecurityGroups (): Observable<SecurityGroup[]>
    {
        return this.securityMiscApiService.getSecurityGroups()
            .pipe(
                map((securityGroupApis: SecurityGroupApi[]) =>
                    securityGroupApis.map(SecurityGroup.mapperFromApi)
                )
            );
    }

    getKeyPairs (): Observable<KeyPair[]>
    {
        return this.securityMiscApiService.getKeyPairs()
            .pipe(
                map((keyPairApis: KeyPairApi[]) =>
                    keyPairApis.map(KeyPair.mapperFromApi)
                )
            );
    }

    getDetails (securityGroupId: string): Observable<SecurityGroup>
    {
        return this.securityMiscApiService.getDetails(securityGroupId)
            .pipe(
                map(SecurityGroup.mapperFromApi)
            );
    }

    createKeyPair (keyPair: KeyPair): Observable<KeyPair>
    {
        return this.securityMiscApiService.createKeyPair(keyPair)
            .pipe(
                map(KeyPair.mapperFromApi)
            );
    }

    createSecurityGroup (securityGroup: CreateSecurityGroupViewModel): Observable<SecurityGroup>
    {
        return this.securityMiscApiService.createSecurityGroup(securityGroup)
            .pipe(
                map(SecurityGroup.mapperFromApi)
            );
    }

    createSecurityRule (securityRule: CreateSecurityRuleViewModel): Observable<SecurityRule>
    {
        return this.securityMiscApiService.createSecurityRule(securityRule);
    }

    deleteKeyPair (keyPair: KeyPair): Observable<void>
    {
        return this.securityMiscApiService.deleteKeyPair(keyPair);
    }

    deleteSecurityRule (securityRule: SecurityRule): Observable<string>
    {
        return this.securityMiscApiService.deleteSecurityRule(securityRule.securityGroupId, securityRule.id);
    }

    deleteSecurityGroup (securityGroup: SecurityGroup): Observable<string>
    {
        return this.securityMiscApiService.deleteSecurityGroup(securityGroup.id);
    }
}
