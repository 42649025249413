import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsChartModule } from '../../../components/components-chart.module';
import { ColumnChartComponent } from './column-chart.component';

@NgModule({
    declarations: [
        ColumnChartComponent
    ],
    imports: [
        CommonModule,
        ComponentsChartModule
    ],
    exports: [
        ColumnChartComponent
    ],
})
export class ColumnChartModule
{ }
