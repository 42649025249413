import { createReducer, on } from '@ngrx/store';

import { IRootFilterTagState, filterTagAdapter, filterTagInitialState } from '@apps/root/store/filter-tag/filter-tag.state';
import * as fromRootImpeActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import { RootFilterTagReset, RootFilterTagSelect, RootFilterTagToggleEnabled, RootFilterTagToggleIncluded, RootFilterTagUnselect } from '@apps/root/store/filter-tag/filter-tag.actions';
import { CmdbConstants } from '@apps/cmdb/cmdb.constants';
import { Update } from '@ngrx/entity';
import { FilterTag } from '@apps/cmdb/models';

export const RootFilterTagReducer = createReducer(
    filterTagInitialState,
    on(
        fromRootImpeActions.RootImpersonateSet,
        fromRootImpeActions.RootImpersonateUnset,
        fromAuthLoginActions.AuthLogout,
        RootFilterTagReset,
        (): IRootFilterTagState =>
            filterTagInitialState
    ),
    on(
        RootFilterTagSelect,
        (state: IRootFilterTagState, { filterTag }: { filterTag: FilterTag; }) =>
        {
            if (filterTag.tagId === CmdbConstants.NoneTagId || filterTag.tagId === CmdbConstants.AnyTagId)
            {
                return {
                    ...state,
                    filterTags: filterTagAdapter.setAll([filterTag], state.filterTags)

                };
            }
            else
            {
                const tagIds: string[] = state.filterTags.ids as string[];

                if (tagIds.includes(CmdbConstants.NoneTagId) || tagIds.includes(CmdbConstants.AnyTagId))
                {
                    return {
                        ...state,
                        filterTags: filterTagAdapter.setAll([filterTag], state.filterTags)
                    };
                }

                return {
                    ...state,
                    filterTags: filterTagAdapter.addOne(filterTag, state.filterTags)
                };
            }
        }
    ),
    on(
        RootFilterTagUnselect,
        (state: IRootFilterTagState, { filterTag }: { filterTag: FilterTag; }) =>
            ({
                filterTags: filterTagAdapter.removeOne(filterTag.tagId, state.filterTags)
            })
    ),
    // on(
    //     RootFilterTagModify,
    //     (state: IRootFilterTagState, { filterTag }: { filterTag: FilterTag; }) =>
    //     {
    //         const changes: Partial<FilterTag> = {
    //             included: filterTag.included,
    //             enabled: filterTag.enabled
    //         };

    //         const updateFilterTag: Update<FilterTag> = {
    //             id: filterTag.tagId,
    //             changes
    //         };

    //         return {
    //             filterTags: filterTagAdapter.updateOne(updateFilterTag, state.filterTags)
    //         };
    //     }
    // ),
    on(
        RootFilterTagToggleIncluded,
        (state: IRootFilterTagState, { filterTag, toggle }: { filterTag: FilterTag; toggle: boolean; }) =>
        {
            const updateFilterTag: Update<FilterTag> = {
                id: filterTag.tagId,
                changes: {
                    included: toggle
                }
            };

            return {
                filterTags: filterTagAdapter.updateOne(updateFilterTag, state.filterTags)
            };
        }
    ),
    on(
        RootFilterTagToggleEnabled,
        (state: IRootFilterTagState, { filterTag, toggle }: { filterTag: FilterTag; toggle: boolean; }) =>
        {
            const updateFilterTag: Update<FilterTag> = {
                id: filterTag.tagId,
                changes: {
                    enabled: toggle
                }
            };

            return {
                filterTags: filterTagAdapter.updateOne(updateFilterTag, state.filterTags)
            };
        }
    )
);
