import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { AutocompleteModule } from '@ui/misc/autocomplete/autocomplete.module';

import { FilterTagComponent } from './filter-tag.component';
import { FilterTagItemComponent } from './filter-tag-item/filter-tag-item.component';
import { FilterTagInputComponent } from './filter-tag-input/filter-tag-input.component';

@NgModule({
    declarations: [
        FilterTagComponent,
        FilterTagItemComponent,
        FilterTagInputComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteModule,
        TranslateModule
    ],
    exports: [
        FilterTagComponent,
        FilterTagItemComponent,
        FilterTagInputComponent
    ],
})
export class FilterTagModule
{ }
