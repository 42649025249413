import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutocompleteOptionComponent } from './autocomplete-option/autocomplete-option.component';
import { AutocompleteComponent } from './autocomplete.component';
import { AutocompleteContentDirective } from './autocomplete-content.directive';
import { AutocompleteDirective } from './autocomplete.directive';
import { AutocompleteFilterPipe } from './autocomplete-filter.pipe';

@NgModule({
    declarations: [
        AutocompleteOptionComponent,
        AutocompleteComponent,
        AutocompleteContentDirective,
        AutocompleteDirective,
        AutocompleteFilterPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AutocompleteOptionComponent,
        AutocompleteComponent,
        AutocompleteContentDirective,
        AutocompleteDirective,
        AutocompleteFilterPipe
    ],
})
export class AutocompleteModule
{
}
