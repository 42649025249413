import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { GuardModalRef } from './guard-modal.ref';
import { GuardModalComponent } from './guard-modal/guard-modal.component';
import { GuardModalConfig } from './guard-modal.config';

@Injectable({
    providedIn: 'root'
})
export class GuardModalService
{
    private overlayRef: OverlayRef = null;
    private modalRef: GuardModalRef = null;

    constructor (private overlay: Overlay, private parentInjector: Injector)
    { }

    open (config?: GuardModalConfig): GuardModalRef
    {
        if (this.overlayRef === null || !this.overlayRef.hasAttached())
        {
            this.overlayRef = this.overlay.create(this.getOverlayConfig());
            this.modalRef = new GuardModalRef(this.overlayRef, config);
            const injector = this.createInjector(this.modalRef, this.parentInjector);

            this.overlayRef.attach(new ComponentPortal(GuardModalComponent, null, injector));
        }

        return this.modalRef;
    }

    createInjector (modalRef: GuardModalRef, injector: Injector)
    {
        const tokens = new WeakMap([[GuardModalRef, modalRef]]);

        return new PortalInjector(injector, tokens);
    }

    private getOverlayConfig (): OverlayConfig
    {
        return new OverlayConfig({
            width: '25vw',
            height: '25vh',
            minWidth: '25vw',
            minHeight: '25vh',
            hasBackdrop: true,
            backdropClass: 'guard-modal-backdrop',
            positionStrategy: this.getOverlayPosition(),
            scrollStrategy: this.overlay.scrollStrategies.reposition()
        });
    }

    private getOverlayPosition (): PositionStrategy
    {
        return this.overlay
            .position()
            .global()
            .centerHorizontally()
            .centerVertically();
    }
}
