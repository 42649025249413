import { TranslateService } from '@ngx-translate/core';

import { QdKeys } from '@apps/ostk/quotas.constants';
import { AppConstants } from '../app.constants';
import { RuleTemplate } from './domain/models/security/rule-template';
import { FloatingIp, Image, ImageSnapshot, Instance, KeyPair, Network, SecurityGroup, Snapshot, Volume } from './domain/models';

export type VersionIp = 'ipv4' | 'ipv6' ;
export enum EnumVersionIp
    {
    IPV4 = 'ipv4',
    IPV6 = 'ipv6'
}

export type IpType = 'fixed' | 'floating';
export enum EnumIpType
    {
    FIXED = 'fixed',
    FLOATING = 'floating'
}
export enum VolumesStatus
    {
    INUSE = 'in-use',
    AVAILABLE = 'available',
    ERROR = 'error'
}

export enum VolumesBootable
    {
    BOOTABLE = 'bootable',
    NOTBOOTABLE = 'notbootable'
}

export enum RemoteType
    {
    CIDR = 'CIDR',
    SECURITYGROUP = 'Security Group'
}

export enum FloatingIpStatus
    {
    DOWN = 'DOWN',
    ACTIVE = 'ACTIVE',
    ERROR = 'ERROR'
}

export type RuleDirection = 'egress' | 'ingress';

export class OstkConstants
{
    public static Application = 'OstkApi';
    public static BaseApi = 'ostk';
    public static BaseUrl = 'cloud';
    public static Projects = 'projects';
    public static DefaultUrl: string = `/${OstkConstants.BaseUrl}/${OstkConstants.Projects}`;
    public static Instances = 'instances';
    public static Volumes = 'volumes';
    public static Volu = 'volu';
    public static Snap = 'snap';
    public static Details = 'details';
    public static Create = 'create';
    public static Images = 'images';
    public static Imge = 'imge';
    public static Security = 'security';
    public static Keyp = 'keyp';
    public static Sgrp = 'sgrp';
    public static Networks = 'networks';
    public static Netw = 'netw';
    public static Fips = 'fips';
    public static Topology = 'topology';
    public static Quotas = 'quotas';
    public static Upload = 'upload';
    public static PlatformTypeAdc = 'ADC';
    public static Filter = 'filter';
    public static Attach = 'attach';
    public static Extend = 'extend';
    public static Rename = 'rename';
    public static SecurityRules = 'securityrules';
    public static SecurityGroups = 'securitygroups';
    public static OsIcons = new Map([
        ['win', 'windows'],
        ['rhel', 'redhat'],
        ['sles', 'suse'],
        ['vm', 'vmware'],
        ['windows', 'windows'],
        ['centos', 'centos'],
        ['debian', 'debian'],
        ['fedora', 'fedora'],
        ['freebsd', 'freebsd'],
        ['gnome', 'gnome'],
        ['hp', 'hp'],
        ['linux', 'linux'],
        ['redhat', 'redhat'],
        ['suse', 'suse'],
        ['ubuntu', 'ubuntu'],
        ['vmware', 'vmware'],
        ['windows', 'windows'],
        ['default', 'default']
    ]);
    public static instancesQsFields: Array<keyof Instance> = [ 'name', 'status', 'addressesLabel' ];
    public static imagesImgeQSFields: Array<keyof Image> = [ 'osLabel', 'name', 'diskFormat' ];
    public static imagesSnapQSFields: Array<keyof ImageSnapshot> = [ 'osLabel', 'name' ];
    public static securityKeypQSFields: Array<keyof KeyPair> = [ 'name', 'fingerprint', 'publicKey' ];
    public static securityGroupsQSFields: Array<keyof SecurityGroup> = [ 'name', 'description', 'protocols' ];
    public static volumesVoluQSFields: Array<keyof Volume> = [ 'status', 'bootableLabel', 'name', 'availabilityZone' ];
    public static volumesSnapQSFields: Array<keyof Snapshot> = [ 'osLabel', 'name' ];
    public static networksNetwQSFields: Array<keyof Network> = [ 'name' ];
    public static networksFipsQSFields: Array<keyof FloatingIp> = [ 'floatingIpAddress', 'fixedIpAddress' ];

    public static OstkSubModules: string =
    [
        OstkConstants.Instances,
        OstkConstants.Volumes,
        OstkConstants.Images,
        OstkConstants.Security,
        OstkConstants.Networks,
        OstkConstants.Quotas,
        OstkConstants.Topology
    ].join('|');

    public static RegexpSegment = '[-\\w]*';

    // Durée de vie en minutes des éléments dans le store
    public static DataLifeTime: number = 7;
    //#region  Projects
    public static ProjectsRoute: string[] =
    [
        OstkConstants.BaseUrl,
        OstkConstants.Projects
    ];
    public static ProjectsPath = `/${OstkConstants.ProjectsRoute.join('/')}`;
    //#endregion

    //#region Instances
    public static InstancesPath = [
        '',
        OstkConstants.BaseUrl,
        AppConstants.ParamPlaceholderUrl,
        OstkConstants.Instances
    ].join('/');

    public static InstancesPathRegexp = [
        '',
        OstkConstants.BaseUrl,
        OstkConstants.RegexpSegment,
        OstkConstants.RegexpSegment,
        OstkConstants.Instances
    ].join('/');
    //#endregion

    //#region Volumes
    public static VolumesRoute: string[] =
    [
        OstkConstants.BaseUrl,
        OstkConstants.Volumes
    ];
    public static VolumesPath = `/${OstkConstants.VolumesRoute.join('/')}`;
    public static VolumesPathRegexp = [
        '',
        OstkConstants.BaseUrl,
        OstkConstants.RegexpSegment,
        OstkConstants.RegexpSegment,
        OstkConstants.Volumes
    ].join('/');
    public static VolumesVoluRoute: string[] =
    [
        OstkConstants.BaseUrl,
        AppConstants.ParamPlaceholderUrl,
        OstkConstants.Volumes,
        OstkConstants.Volu
    ];
    public static VolumesVoluCreateRoute =
    [
        OstkConstants.BaseUrl,
        AppConstants.ParamPlaceholderUrl,
        OstkConstants.Volumes,
        { outlets: { [OstkConstants.Create]: [OstkConstants.Create] } }
    ];
    public static VolumesVoluPath = `/${OstkConstants.VolumesVoluRoute.join('/')}`;
    public static VolumesSnapRoute: string[] =
    [
        OstkConstants.BaseUrl,
        AppConstants.ParamPlaceholderUrl,
        OstkConstants.Volumes,
        OstkConstants.Snap
    ];
    public static VolumesSnapPath = `/${OstkConstants.VolumesVoluRoute.join('/')}`;
    //#endregion

    //#region Images
    public static getCssImageStatus (status: string): string
    {
        return `imge-${status}`;
    }
    public static ImagesRoute: string[] =
    [
        OstkConstants.BaseUrl,
        OstkConstants.Images
    ];
    public static ImagesPath = `/${OstkConstants.ImagesRoute.join('/')}`;
    public static ImagesPathRegexp = [
        '',
        OstkConstants.BaseUrl,
        OstkConstants.RegexpSegment,
        OstkConstants.RegexpSegment,
        OstkConstants.Images
    ].join('/');
    public static ImagesImgeRoute: string[] =
    [
        OstkConstants.BaseUrl,
        AppConstants.ParamPlaceholderUrl,
        OstkConstants.Images,
        OstkConstants.Imge
    ];
    public static ImagesImgePath = `/${OstkConstants.ImagesImgeRoute.join('/')}`;
    public static ImagesSnapRoute: string[] =
    [
        OstkConstants.BaseUrl,
        AppConstants.ParamPlaceholderUrl,
        OstkConstants.Images,
        OstkConstants.Snap
    ];
    public static ImagesSnapPath = `/${OstkConstants.ImagesSnapRoute.join('/')}`;
    public static imageFormats = [
        {
            value: 'iso',
            display: 'Image ISO 9660 (.iso)'
        },
        {
            value: 'qcow2',
            display: 'KVM - Emulateur QEMU (.qcow2)'
        },
        {
            value: 'raw',
            display: 'raw'
        }
    ];
    //#endregion

    //#region Security
    public static SecurityRoute: string[] =
    [
        OstkConstants.BaseUrl,
        OstkConstants.Security
    ];
    public static SecurityPath = `/${OstkConstants.SecurityRoute.join('/')}`;
    public static SecurityPathRegexp = [
        '',
        OstkConstants.BaseUrl,
        OstkConstants.RegexpSegment,
        OstkConstants.RegexpSegment,
        OstkConstants.Security
    ].join('/');
    public static SecurityKeypRoute: string[] =
    [
        OstkConstants.BaseUrl,
        AppConstants.ParamPlaceholderUrl,
        OstkConstants.Security,
        OstkConstants.Keyp
    ];
    public static SecurityKeypPath = `/${OstkConstants.SecurityKeypRoute.join('/')}`;
    public static SecuritySgrpRoute: string[] =
    [
        OstkConstants.BaseUrl,
        AppConstants.ParamPlaceholderUrl,
        OstkConstants.Security,
        OstkConstants.Sgrp
    ];
    public static SecuritySgrpPath = `/${OstkConstants.SecuritySgrpRoute.join('/')}`;

    public static ProtocolForm = 'protocol';
    public static PortForm = 'port';
    public static CodeForm = 'code';
    public static TypeForm = 'type';
    public static Direction = 'direction';
    public static PortSingleRange = 'portSingleRange';
    public static DefaultRule = 'tcp';
    public static DefaultRemoteIpPrefix = '0.0.0.0/0';
    public static RuleTemplates: RuleTemplate[] = [
        {
            value: 'icmp',
            label: 'Règle ICMP personnalisée',
            forms: [
                'direction',
                'type',
                'code'
            ],
            protocol: 'icmp',
            portRangeMin: null,
            portRangeMax: null,
            portSingleRange: 'range'
        } as RuleTemplate,
        {
            value: 'tcp',
            label: 'Règle TCP personnalisée',
            forms: [
                'direction',
                'port'
            ],
            protocol: 'tcp',
            portRangeMin: null,
            portRangeMax: null,
            portSingleRange: 'range'
        } as RuleTemplate,
        {
            value: 'udp',
            label: 'Règle UDP personnalisée',
            forms: [
                'direction',
                'port'
            ],
            protocol: 'udp',
            portRangeMin: null,
            portRangeMax: null,
            portSingleRange: 'range'
        } as RuleTemplate,
        {
            value: 'custom',
            label: 'Autre protocole',
            forms: [
                'direction',
                'protocol'
            ],
            portRangeMin: null,
            portRangeMax: null,
            portSingleRange: 'range'
        } as RuleTemplate,
        {
            value: 'all_icmp',
            label: 'All ICMP',
            forms: [
                'direction'
            ],
            protocol: 'icmp',
            portRangeMin: null,
            portRangeMax: null,
            portSingleRange: 'range'
        } as RuleTemplate,
        {
            value: 'all_tcp',
            label: 'All TCP',
            forms: [
                'direction'
            ],
            protocol: 'tcp',
            portRangeMin: 1,
            portRangeMax: 65535,
            portSingleRange: 'range'
        } as RuleTemplate,
        {
            value: 'all_udp',
            label: 'All UDP',
            forms: [
                'direction'
            ],
            protocol: 'udp',
            portRangeMin: 1,
            portRangeMax: 65535,
            portSingleRange: 'range'
        } as RuleTemplate,
        {
            value: 'dns',
            label: 'DNS',
            forms: [],
            direction: 'egress',
            protocol: 'udp',
            portRangeMin: 53,
            portRangeMax: 53,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'http',
            label: 'HTTP',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 80,
            portRangeMax: 80,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'https',
            label: 'HTTPS',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 443,
            portRangeMax: 443,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'imap',
            label: 'IMAP',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 143,
            portRangeMax: 143,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'imaps',
            label: 'IMAPS',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 993,
            portRangeMax: 993,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'ldap',
            label: 'LDAP',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 389,
            portRangeMax: 389,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'ms_sql',
            label: 'MS SQL',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 1433,
            portRangeMax: 1433,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'mysql',
            label: 'MySQL',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 3306,
            portRangeMax: 3306,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'pop3',
            label: 'POP3',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 110,
            portRangeMax: 110,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'pop3s',
            label: 'POP3S',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 995,
            portRangeMax: 995,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'rdp',
            label: 'RDP',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 3389,
            portRangeMax: 3389,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'smtp',
            label: 'SMTP',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 25,
            portRangeMax: 25,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'smtps',
            label: 'SMTPS',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 465,
            portRangeMax: 465,
            portSingleRange: 'single'
        } as RuleTemplate,
        {
            value: 'ssh',
            label: 'SSH',
            forms: [],
            direction: 'ingress',
            protocol: 'tcp',
            portRangeMin: 22,
            portRangeMax: 22,
            portSingleRange: 'single'
        } as RuleTemplate
    ];

    //#endregion

    //#region Networks
    public static NetworksRoute: string[] =
    [
        OstkConstants.BaseUrl,
        OstkConstants.Networks
    ];
    public static NetworksPath = `/${OstkConstants.NetworksRoute.join('/')}`;
    public static NetworksPathRegexp = [
        '',
        OstkConstants.BaseUrl,
        OstkConstants.RegexpSegment,
        OstkConstants.RegexpSegment,
        OstkConstants.Networks
    ].join('/');
    public static NetworksNetwRoute: string[] =
    [
        OstkConstants.BaseUrl,
        AppConstants.ParamPlaceholderUrl,
        OstkConstants.Networks,
        OstkConstants.Netw
    ];
    public static NetworksNetwPath = `/${OstkConstants.NetworksNetwRoute.join('/')}`;
    public static NetworksFipsRoute: string[] =
    [
        OstkConstants.BaseUrl,
        AppConstants.ParamPlaceholderUrl,
        OstkConstants.Networks,
        OstkConstants.Fips
    ];
    public static NetworksFipsPath = `/${OstkConstants.NetworksFipsRoute.join('/')}`;
    public static IpVersionOptions = [
        { label: 'IPv4', value: EnumVersionIp.IPV4, selected: true },
        { label: 'IPv6', value: EnumVersionIp.IPV6, selected: false },
    ];
    //#endregion

    //#region TOPO
    public static TopologyRoute: string[] =
    [
        OstkConstants.BaseUrl,
        AppConstants.ParamPlaceholderUrl,
        OstkConstants.Topology
    ];
    public static TopologyPath = `/${OstkConstants.TopologyRoute.join('/')}`;
    public static TopologyPathRegexp = [
        '',
        OstkConstants.BaseUrl,
        OstkConstants.RegexpSegment,
        OstkConstants.RegexpSegment,
        OstkConstants.Topology
    ].join('/');
    //#endregion

    //#region QUOTAS
    public static QuotasPath = [
        '',
        OstkConstants.BaseUrl,
        AppConstants.ParamPlaceholderUrl,
        OstkConstants.Quotas
    ].join('/');
    public static QuotasPathRegexp = [
        '',
        OstkConstants.BaseUrl,
        OstkConstants.RegexpSegment,
        OstkConstants.RegexpSegment,
        OstkConstants.Quotas
    ].join('/');

    // public static UploadImagesRoute = () =>
    //     [
    //         OstkConstants.BaseUrl,
    //         OstkConstants.Images,
    //         OstkConstants.Imge,
    //         { outlets: { images: [OstkConstants.Upload, OpenPopinOriginator.OSTK_IMAGES] } }
    //     ]
    // public static FilterImagesRoute = () =>
    //     [
    //         OstkConstants.BaseUrl,
    //         OstkConstants.Images,
    //         OstkConstants.Imge,
    //         { outlets: { images: [OstkConstants.Filter] } }
    //     ]

    // Quota
    public static getQuotaLegendByKey (key: string, translateService: TranslateService, used: number): string
    {
        const pluralOrSingular = used > 1 ? 'PLURAL' : 'SINGULAR';
        let firstKey = '';
        let secondKey = '';

        switch (key)
        {
            case QdKeys.VRAM:
            case QdKeys.DISK:
                firstKey = translateService.instant('NUMBERS.UNITS.BINARY.GIBI');
                secondKey = translateService.instant(`OSTK.INSTANCES.CHARTS.WORDUSED.MALE.${pluralOrSingular}`);
                break;
            case QdKeys.INST:
                firstKey = used > 1 ? 'instances' : 'instance';
                secondKey = translateService.instant(`OSTK.INSTANCES.CHARTS.WORDUSED.FEMALE.${pluralOrSingular}`);
                break;
            case QdKeys.VCPU:
                firstKey = 'CPU';
                secondKey = translateService.instant(`OSTK.INSTANCES.CHARTS.WORDUSED.MALE.${pluralOrSingular}`);
                break;
            default:
                secondKey = translateService.instant(`OSTK.INSTANCES.CHARTS.WORDUSED.MALE.${pluralOrSingular}`);
                break;
        }

        return `${firstKey} ${secondKey}`;
    }

    public static getQuotaUnit (key: string, translateService: TranslateService, plural = false): string
    {
        let unit = '';
        switch (key)
        {
            case QdKeys.VRAM:
                unit = translateService.instant('NUMBERS.UNITS.BINARY.GIBI');
                break;
            case QdKeys.INST:
                unit = plural ? 'instances' : 'instance';
                break;
            case QdKeys.VCPU:
                unit = 'CPU';
                break;
            case QdKeys.DISK:
                unit = translateService.instant('NUMBERS.UNITS.BINARY.GIBI');
                break;
            default:
                break;
        }

        return unit;
    }
    //#endregion

    //#region Volumes
    public static VolumeStatuses: Map<string, VolumesStatus> = new Map([
        [VolumesStatus.AVAILABLE.toString(), VolumesStatus.AVAILABLE],
        [VolumesStatus.INUSE.toString(), VolumesStatus.INUSE],
        [VolumesStatus.ERROR.toString(), VolumesStatus.ERROR],
    ]);
    public static getVolumeStatusClass (status: string): string
    {
        return OstkConstants.VolumeStatuses.get(status.toLocaleLowerCase());
    }

    public static getVolumeStatusLabelKey (status: string): string
    {
        return `OSTK.VOLUME.LIST.STATUSKEY.${status.toUpperCase()}`;
    }

    public static getVolumeBootableClass (bootable: boolean): string
    {
        return bootable ? VolumesBootable.BOOTABLE : VolumesBootable.NOTBOOTABLE;
    }

    public static getVolumeBootableLabelKey (bootableLabel: string): string
    {
        return `OSTK.VOLUME.LIST.${bootableLabel}`.toUpperCase();
    }

    public static getVolumeEncryptedKey (encrypted: boolean): string
    {
        return `OSTK.VOLUME.LIST.${encrypted ? 'ENCRYPTED' : 'NOTENCRYPTED'}`;
    }
    // #endregion

    public static getSubnetsClass (hasSubnets: boolean): string
    {
        return hasSubnets ? 'netw-subnets' : 'netw-no-subnets';
    }

    // public static getDirectionClass (direction: string): string
    // {
    //     return direction === 'ingress' ? 'fa-sign-in-alt' : 'fa-sign-out-alt';
    // }

    public static getNetworkStatusClass (adminStateUp: boolean): string
    {
        switch (adminStateUp)
        {
            case true:
                return 'netw-active';
            case false:
                return 'netw-down';
            default:
                return 'netw-unkwnon';
        }
    }

    public static getNetworkStatusKey (adminStateUp: boolean): string
    {
        return `STATUSES.${adminStateUp ? 'ACTIVE' : 'INACTIVE'}`;
    }

    public static getDhcpEnabledKey (enableDhcp: boolean): string
    {
        return `OSTK.NETWORKS.DETAILS.STATUSES.${enableDhcp ? 'ENABLED' : 'DISABLED'}`;
    }
    // Fips
    public static getFipStatusClass (status: string): string
    {
        switch (status)
        {
            case FloatingIpStatus.ACTIVE:
                return 'netw-fips-unavailable';
            case FloatingIpStatus.DOWN:
                return 'netw-fips-available';
            default:
                return 'netw-unkwnon';
        }
    }
}
