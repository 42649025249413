import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Subject, timer } from 'rxjs';
import { catchError, map, concatMap, tap, switchMap, exhaustMap, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { FieldOptionMiscDomainService, RequestMiscDomainService } from '@apps/its2/domain';
import { NotfGravity, NotificationService, NotifType } from '@common/services';
import * as fromIts2MiscActions from '@apps/its2/store/misc/misc.actions';
import * as fromIts2RequestsActions from '@apps/its2/store/requests/requests.actions';
import { FieldOptions } from '@common/models';
import { RequestValidationParameters } from '@apps/its2/models';
import { Request } from '@apps/its2/domain/models';
import { IAuroraState } from '@apps/aurora.state';
import { Update } from '@ngrx/entity';
import { Languages } from '@common/enum';
import { AppConstants } from '@apps/app.constants';

@Injectable()
export class Its2MiscEffects
{
    private counter: number = 0;
    private destroyator: Subject<unknown>;

    constructor (
        private store: Store<IAuroraState>,
        private actions: Actions,
        private requestMiscDomainService: RequestMiscDomainService,
        private fieldOptionMiscDomainService: FieldOptionMiscDomainService,
        private notificationService: NotificationService,
    )
    {
    }

    its2RequestsReplyRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2MiscActions.Its2RequestsReplyRequested),
                switchMap(({ rfcNumber, description }: { rfcNumber: string, description: string }) =>
                    this.requestMiscDomainService.createAction(rfcNumber, description)
                        .pipe(
                            map(() =>
                                fromIts2MiscActions.Its2RequestsReplySucceeded({ rfcNumber })
                            ),
                            catchError(() =>
                                of(fromIts2MiscActions.Its2RequestsReplySucceeded({ rfcNumber }))
                            )
                        )
                )
            )
    );

    its2RequestsReplySucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2MiscActions.Its2RequestsReplySucceeded),
                    tap(({ rfcNumber }: { rfcNumber: string }) =>
                        this.notificationService.notify(
                            [
                                '',
                                'ITSM.ADDACTION.DESCRIPTION.NOTIF.SUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR,
                            { rfcNumber }
                        )
                    )
                ),
        { dispatch: false }
    );

    its2RequestsReplyFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2MiscActions.Its2RequestsReplyFailed),
                    tap(({ rfcNumber }: { rfcNumber: string }) =>
                        this.notificationService.notify(
                            [
                                '',
                                'ITSM.ADDACTION.DESCRIPTION.NOTIF.FAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR,
                            { rfcNumber }
                        )
                    )
                ),
        { dispatch: false }
    );

    its2CreateServiceLoadOptionsRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2MiscActions.Its2CreateServiceLoadOptionsRequested),
                    concatMap(({ endpoint, fieldName }: any) =>
                        this.fieldOptionMiscDomainService.getFieldOptions(endpoint, fieldName)
                            .pipe(
                                map((fieldOptions: FieldOptions) =>
                                    fromIts2MiscActions.Its2CreateServiceLoadOptionsSucceeded({
                                        fieldOptions
                                    })
                                ),
                                catchError(() =>
                                    of(fromIts2MiscActions.Its2CreateServiceLoadOptionsFailed())
                                )
                            )
                    )
                )
    );

    its2RequestsValidateRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2MiscActions.Its2RequestsValidateRequested),
                switchMap(({ rfcNumber, oldStatusId, rvp }: { rfcNumber: string; oldStatusId: number; rvp: RequestValidationParameters; }) =>
                    this.requestMiscDomainService.validate(rfcNumber, rvp)
                        .pipe(
                            map(() =>
                                fromIts2MiscActions.Its2RequestsValidateSucceeded({ rfcNumber, oldStatusId })
                            ),
                            catchError(() =>
                                of(fromIts2MiscActions.Its2RequestsValidateFailed())
                            )
                        )
                )
            )
    );

    its2RequestsValidateSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2MiscActions.Its2RequestsValidateSucceeded),
                    tap(({ rfcNumber }: { rfcNumber: string; oldStatusId: number; }) =>
                    {
                        const update: Update<Request> = {
                            id: rfcNumber,
                            changes: {
                                statusId: -1,
                                status: {
                                    [Languages.enUs]: '',
                                    [Languages.frFr]: ''
                                }
                            }
                        };

                        this.store.dispatch(fromIts2RequestsActions.Its2RequestsUpdateOne({ request: update }));

                        this.counter = 0;
                        this.destroyator = new Subject();
                        this.notificationService.notify(
                            [
                                '',
                                'ITSM.VALIDATION.NOTIF.SUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );
                    }),
                    map(({ rfcNumber, oldStatusId }: { rfcNumber: string; oldStatusId: number; }) =>
                        fromIts2MiscActions.Its2RequestsPollingStatusRequested({
                            rfcNumber,
                            oldStatusId
                        })
                    )
                )
    );

    its2RequestsValidateFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2MiscActions.Its2RequestsValidateFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                '',
                                'ITSM.VALIDATION.NOTIF.FAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );
    its2RequestsPollingStatusRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2MiscActions.Its2RequestsPollingStatusRequested),
                switchMap(({ rfcNumber, oldStatusId }: { rfcNumber: string; oldStatusId: number; }) =>
                    timer(0, AppConstants.PollingStatusInterval)
                        .pipe(
                            exhaustMap(() =>
                                this.requestMiscDomainService.getDetails(rfcNumber)
                                    .pipe(
                                        tap((request: Request) =>
                                        {
                                            if (AppConstants.IsDebugStage && rfcNumber === 'S20210610_00004')
                                            {
                                                this.counter++;
                                                if (this.counter >= 7)
                                                {
                                                    request = {
                                                        ...request,
                                                        statusId: 8,
                                                        status: {
                                                            [Languages.enUs]: 'Clôturé',
                                                            [Languages.frFr]: 'Closed'
                                                        }
                                                    };
                                                }
                                            }

                                            if (oldStatusId !== request.statusId)
                                            {
                                                const update: Update<Request> = {
                                                    id: request.rfcNumber,
                                                    changes:request
                                                };

                                                this.store.dispatch(fromIts2RequestsActions.Its2RequestsUpdateOne({ request: update }));

                                                this.destroyator.next(null);
                                                this.destroyator.complete();
                                            }
                                        })
                                    )
                            ),
                            takeUntil(this.destroyator)
                        )
                )
            ),
    { dispatch: false }
    );

    its2RequestsPollingStatusSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2MiscActions.Its2RequestsPollingStatusSucceeded),
                    tap(({ rfcNumber }: { rfcNumber: string; }) =>
                        this.notificationService.notify(
                            [
                                '',
                                'ITSM.VALIDATION.POLLING.SUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR,
                            { rfcNumber }
                        )
                    )
                ),
        { dispatch: false }
    );

    its2RequestsPollingStatusFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2MiscActions.Its2RequestsPollingStatusFailed),
                    tap(({ rfcNumber }: { rfcNumber: string; }) =>
                        this.notificationService.notify(
                            [
                                '',
                                'ITSM.VALIDATION.POLLING.FAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR,
                            { rfcNumber }
                        )
                    )
                ),
        { dispatch: false }
    );
}
