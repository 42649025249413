<label [ngClass]="{'required':required}"
       *ngIf="label"
       [attr.for]="fieldId">{{label}}
</label>
<div class="input-component"
     [style.marginTop.px]="marginTop"
     [ngClass]="{'pristine': pristine, 'valid': (validStatus$ | async), 'invalid': (invalidStatus$ | async), 'disabled': disabled}">
    <div class="input-wrapper">
        <div class="radio-group"
             [ngClass]="orientation"
             *ngIf="lineSize === 0"
             [attr.disabled]="notEnabled ? '' : null">
            <div class="radio"
                 *ngFor="let so of options">
                <aa-radio3 [toTranslate]="toTranslate"
                           [option]="so"
                           name="{{ name }}">
                </aa-radio3>
            </div>
        </div>
        <div class="radio-group"
             *ngIf="lineSize !== 0"
             [attr.disabled]="notEnabled ? '' : null"
             [style.marginTop.px]="marginTop">
            <table>
                <tr *ngFor="let line of lines; index as i">
                    <td *ngFor="let so of options.slice(i * lineSize, (i + 1) * lineSize)">
                        <aa-radio3 [toTranslate]="toTranslate"
                                   [option]="so"
                                   name="{{ name }}">
                        </aa-radio3>
                    </td>
                </tr>
            </table>
        </div>
        <span class="input-validation fa-stack fa-1x"
              *ngIf="(dirtyStatus$ | async)">
            <i class="fas fa-stack-2x fa-circle"
               aria-hidden="true"></i>
            <i class="fal fa-stack-1x"
               [ngClass]="{'fa-check': (validStatus$ | async), 'fa-times': (invalidStatus$ | async)}"
               aria-hidden="true"></i>
        </span>
    </div>
    <aa-validation2 [dirty]="dirty"
                    [messages]="failures | async">
    </aa-validation2>
</div>
