import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseListApiService } from '@common/api/base-list-api.service';
import { ResultApi } from '@common/api/result-api';
import { AppConstants } from '@apps/app.constants';
import { Its2Constants } from '@apps/its2/its2.constants';

import { RequestApi } from './models';

@Injectable({
    providedIn: 'root'
})
export class RequestListcApiService extends BaseListApiService<RequestApi>
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlIts2;
        this.resourceUrl = `/${Its2Constants.BaseApi}/requests`;
    }

    getRequests (qs: string): Observable<ResultApi<RequestApi>>
    {
        if (AppConstants.IsDataLocal('its2'))
        {
            return this.http.get<ResultApi<RequestApi>>('assets/data/its2/requests.json');
        }

        return this.getAll(`${this.resourceUrl}${qs}`);
    }
}
