import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AppConstants } from '@apps/app.constants';
import { TokenService } from '@apps/auth/api/token.service';
import { AuthConstants } from '@apps/auth/auth.constants';

import { ApplyCredentialsService } from './apply-credentials';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    constructor (
        private tokenService: TokenService,
        private applyCredentialsService: ApplyCredentialsService,
        private router: Router
    )
    {
    }

    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const authReq = this.applyCredentialsService
            .ApplyBearer(req);

        return next.handle(authReq)
            .pipe(
                catchError((error: Error) =>
                {
                    if (error instanceof HttpErrorResponse)
                    {
                        switch (error.status)
                        {
                            case 401:
                                return this.handle401Error(authReq, next, error);

                            case 500:
                            default:
                                return throwError(error);
                        }
                    }
                    else
                    {
                        return throwError(error);
                    }
                })
            );
    }

    private handle401Error (request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse)
    {
        if (ApplyCredentialsService.isAuthUrl(request) || ApplyCredentialsService.isAuthRefreshUrl(request))
        {
            localStorage.removeItem(AuthConstants.TokenKey);
            this.router.navigate([AppConstants.LoginUrl]);

            return throwError(error);
        }

        const rt = this.tokenService.getRefreshToken();
        if (rt === null)
        {
            this.router.navigate([AppConstants.LoginUrl]);

            return throwError(error);
        }

        return this.tokenService.getNewToken()
            .pipe(
                switchMap((newToken: string) =>
                {
                    if (newToken)
                    {
                        const authRequest = this.applyCredentialsService
                            .ApplyBearer(request);

                        return next.handle(authRequest);
                    }
                }),
                catchError(() =>
                {
                    return throwError(error);
                })
            );
    }
}
