import { DateTime, Interval } from 'luxon';

import { CopyEvolutionApi, SampleApi } from '@apps/bckp/api/models/copy-evolution-api';
import { DatesService, groupByPeriod, GroupByPeriodEnum } from '@common/services';

export class GroupedPeriod
{
    public groupedPeriodMillis: number;
    public groupedPeriodISO: string;

    constructor (public groupedPeriod: DateTime, )
    {
        this.groupedPeriodMillis = groupedPeriod.toMillis();
        this.groupedPeriodISO = groupedPeriod.toISO();
    }
}

export interface IGroupByPeriod
{
    originalPeriod: DateTime;
    originalValues: {[prop: string]: number};
    groupedPeriod: DateTime;
    groupedPeriodISO: string;
    groupedPeriodMillis: number;
    groupedValue: {[prop: string]: number};
}

export class Sample implements IGroupByPeriod
{
    at?: string;
    numImages?: number;
    bytesTotal?: number;
    originalPeriod: DateTime;
    originalValues: { [prop: string]: number; };
    groupedPeriod: DateTime;
    groupedPeriodISO: string;
    groupedPeriodMillis: number;
    groupedValue: { [prop: string]: number; };
}

export class DomainSample extends Sample
{
    public static mapperFromApi (source: SampleApi): Sample
    {
        const target = new Sample();

        Object.assign(target, source);

        target.originalPeriod = DatesService.UtcStringToDateTimeHourStatic(source.at);
        target.originalValues = {
            numImages: source.numImages,
            bytesTotal: source.bytesTotal
        };

        return target;
    }
}

export class CopyEvolution
{
    residence: string;
    samples: Sample[];
    groupedSamples: Sample[];
    index: number;
}

export class DomainCopyEvolution extends CopyEvolution
{
    public static mapperFromApi (source: CopyEvolutionApi, index: number, since: DateTime, until: DateTime): CopyEvolution
    {
        const target = new CopyEvolution();

        Object.assign(target, source);

        target.index = index;
        target.samples = source.samples.map(DomainSample.mapperFromApi);
        target.groupedSamples = groupByPeriod<Sample>(
            target.samples,
            DomainCopyEvolution.optimizeGroupByPeriod(since, until)
        );

        return target;
    }

    private static optimizeGroupByPeriod (since: DateTime, until: DateTime):GroupByPeriodEnum
    {
        const interval: Interval = Interval.fromDateTimes(since, until);

        if (interval.length('hours') <= 12)
        {

            return GroupByPeriodEnum.HALFH;
        }

        if (interval.length('hours') <= 24)
        {

            return GroupByPeriodEnum.HOUR;
        }

        if (interval.length('days') <= 7)
        {

            return GroupByPeriodEnum.HALFD;
        }

        return GroupByPeriodEnum.DAY;
    }
}
