import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import * as fromRootImpersonateActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { Its2Constants } from '@apps/its2/its2.constants';
import { RequestActionDetails } from '@apps/its2/domain/models';

import * as fromIts2ActionsActions from './actions.actions';

export const Its2ActionsFeatureKey = 'actions';

type IActionsState = EntityState<RequestActionDetails>;
export const actionsAdapter: EntityAdapter<RequestActionDetails> = createEntityAdapter<RequestActionDetails>({
    selectId: (requestAction: RequestActionDetails) => requestAction.actionId
});
const actionsInitialState: IActionsState = actionsAdapter.getInitialState({
});

export interface IIts2ActionsState
{
    detailedRequestActions: IActionsState;
    isLoading: boolean;
    isLoaded: boolean;
    isEmpty: boolean;
    isOups: boolean;
    expirationDate?: DateTime;
    lastComment: string;
    lastCommentIsLoading: boolean;
}
export const Its2ActionsState: IIts2ActionsState = {
    detailedRequestActions: actionsInitialState,
    isLoading: false,
    isLoaded: false,
    isEmpty: false,
    isOups: false,
    expirationDate: null,
    lastComment: null,
    lastCommentIsLoading: false
};

export const Its2ActionsReducer = createReducer(
    Its2ActionsState,
    on(
        fromRootImpersonateActions.RootImpersonateSet,
        fromRootImpersonateActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => Its2ActionsState
    ),
    on(
        fromIts2ActionsActions.Its2RequestsActionsRequested,
        (state: IIts2ActionsState) => ({
            ...state,
            isLoading: true

        })
    ),
    on(
        fromIts2ActionsActions.Its2RequestsActionsSucceeded,
        (state: IIts2ActionsState, { detailedRequestActions }: { detailedRequestActions: RequestActionDetails[]; }) => ({
            ...state,
            isLoading: false,
            isLoaded: true,
            isEmpty: detailedRequestActions.length === 0,
            detailedRequestActions: actionsAdapter.setAll(detailedRequestActions, state.detailedRequestActions),
            expirationDate: DateTime.utc().plus({ minutes: Its2Constants.DataLifeTime }),
            lastComment: null
        })
    ),
    on(
        fromIts2ActionsActions.Its2RequestsDetailedActionRequested,
        (state: IIts2ActionsState, { requestActionDetails }: {requestActionDetails: RequestActionDetails}) =>
        {
            const update: Update<RequestActionDetails> = {
                id: requestActionDetails.actionId,
                changes: {
                    isLoading: true
                }
            };

            return {
                ...state,
                detailedRequestActions: actionsAdapter.updateOne(update, state.detailedRequestActions),
            };
        }
    ),
    on(
        fromIts2ActionsActions.Its2RequestsDetailedActionSucceeded,
        (state: IIts2ActionsState, { requestActionDetails }: {requestActionDetails: Update<RequestActionDetails>}) => ({
            ...state,
            detailedRequestActions: actionsAdapter.updateOne(requestActionDetails, state.detailedRequestActions),
        })
    ),
    on(
        fromIts2ActionsActions.Its2RequestsLastCommentRequested,
        (state: IIts2ActionsState) => ({
            ...state,
            lastCommentIsLoading: true
        })
    ),
    on(
        fromIts2ActionsActions.Its2RequestsLastCommentSucceeded,
        (state: IIts2ActionsState, { lastComment }) => ({
            ...state,
            lastComment: lastComment,
            lastCommentIsLoading: false
        })
    ),
    on(
        fromIts2ActionsActions.Its2RequestsDetailedActionExpand,
        (state: IIts2ActionsState, { requestActionDetails, value }: {requestActionDetails: RequestActionDetails, value: boolean}) =>
        {
            const update: Update<RequestActionDetails> = {
                id: requestActionDetails.actionId,
                changes: {
                    isExpanded: value
                }
            };

            return {
                ...state,
                detailedRequestActions: actionsAdapter.updateOne(update, state.detailedRequestActions)
            };
        }
    )
);
