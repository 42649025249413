import { LocalizedText } from '@common/models';

export class MetaField
{
    public id: string | number;
    public label: LocalizedText;
    public name: string;
    public type: string;
    public percentWidth: number;
    public required: boolean;
    public validators: any[];
    public endPoint: string;
    public fieldToHide: string;
    public hideConditionValues: string[];
    public hidden: boolean;
    public propertyName: string;
    public inputDisabled: boolean;

    constructor ()
    {
        this.validators = [];
    }
}
