import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ResultApi } from '@common/api/result-api';
import { AppConstants } from '@apps/app.constants';

import { BaseListApiService } from '../../../_common/api/base-list-api.service';
import { JobApi } from './models';

@Injectable({
    providedIn: 'root'
})
export class JobListApiService extends BaseListApiService<JobApi>
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlHist;
        this.resourceUrl = '/hist/netbackup';
    }

    getJobs (qs: string): Observable<ResultApi<JobApi>>
    {
        if (AppConstants.IsDataLocal('bckp'))
        {
            return this.http.get<ResultApi<JobApi>>('assets/data/bckp/jobs.json');
        }

        // if (AppConstants.IsDebugStage)
        // {
        //     const headers = new HttpHeaders()
        //         .set('X-AurorA-EntityId', 'f9244225-04e3-e511-80cd-005056ae7252');

        //     return this.getAll(`${this.resourceUrl}${endPoint}${qs}`, headers);
        // }

        return this.getAll(`${this.resourceUrl}/jobs${qs}`);
    }

    getByBackupId (backupId: string): Observable<ResultApi<JobApi>>
    {
        return this.getAll(`${this.resourceUrl}/jobs?$top=1&terms=backupId:${backupId}&$orderby=ended%20desc`);
    }
}
