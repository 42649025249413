import { CustomFile } from '@common/models/custom-file';

import { StepBase } from './step-base';

export class Step3 extends StepBase
{
    message: string = '';
    customFiles: CustomFile[] = [];

    constructor (currentLang: string, step3?: Step3)
    {
        super(currentLang);
        // this.subTitle = this.translateService.instant('ITSM.CREATESERVICEREQUEST.STEPS.STEP3.TAB');

        if (step3)
        {
            Object.assign(this, step3);
        }
    }

    toSummary ()
    {
        return `
            <h2>Message</h2>
            <div>${this.message}</div>
        `;
    }

    toDescription ()
    {
        return `
            ${this.subTitleDescription}
            <div>${this.message}</div>
        `;
    }
}
