import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor
{
    private nbRetries: number = 0;

    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return next.handle(req)
            .pipe(
                retry(this.nbRetries),
                catchError((err) =>
                {
                    throw err;
                })
            );
    }
}
