import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { NavbarComponent } from '../_layouts/navbar/navbar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { UIModule } from '../_ui/ui.module';
import { PipesModule } from '../_pipes/pipes.module';
import { NavbarItemComponent } from './navbar/navbar-item/navbar-item.component';
import { HeaderSearchComponent } from './header/header-search/header-search.component';
import { HeaderNotificationsComponent } from './header/header-notifications/header-notifications.component';
import { HeaderUserMenuComponent } from './header/header-user-menu/header-user-menu.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { HeaderSubscriptionsComponent } from './header/header-subscriptions/header-subscriptions.component';
import { ContentActionsComponent } from './content-actions/content-actions.component';
import { HeaderLogoComponent } from './header/header-logo/header-logo.component';
import { SearchResultComponent } from './header/header-search/search-result/search-result.component';
import { SearchResultItemComponent } from './header/header-search/search-result-item/search-result-item.component';
import { SearchToolbarComponent } from './header/header-search/search-toolbar/search-toolbar.component';
import { BckpJobComponent } from './header/header-search/search-result-item/specific/bckp-job/bckp-job.component';
import { Its2RequestComponent } from './header/header-search/search-result-item/specific/its2-request/its2-request.component';
import { BackAuthUserComponent } from './header/header-search/search-result-item/specific/back-auth-user/back-auth-user.component';
import { BackAuthEntityComponent } from './header/header-search/search-result-item/specific/back-auth-entity/back-auth-entity.component';
import { BckpServerComponent } from './header/header-search/search-result-item/specific/bckp-server/bckp-server.component';
import { Its2RequestSearchCategoryComponent } from './header/header-search/search-result/search-category/its2-request-category.component';
import { BckpJobSearchCategoryComponent } from './header/header-search/search-result/search-category/bckp-job-category.component';
import { BckpServerSearchCategoryComponent } from './header/header-search/search-result/search-category/bckp-server-category.component';
import { BackAuthUserCategoryComponent } from './header/header-search/search-result/search-category/back-auth-user-category.component';
import { BackAuthEntityCategoryComponent } from './header/header-search/search-result/search-category/back-auth-entity-category.component';
import { BckpPolicyCategoryComponent } from './header/header-search/search-result/search-category/bckp-policy-category.component';
import { CompInstanceCategoryComponent } from './header/header-search/search-result/search-category/comp-instance-category.component';
import { BckpPolicyComponent } from './header/header-search/search-result-item/specific/bckp-policy/bckp-policy.component';
import { CompInstanceComponent } from './header/header-search/search-result-item/specific/comp-instance/comp-instance.component';
import { ScrollMenuComponent } from './navbar/scroll-menu/scroll-menu.component';
import { SectionT0Component } from './content/sections/section-t0/section-t0.component';
import { SectionT1Component } from './content/sections/section-t1/section-t1.component';
import { PanelH1A1MA0MD0Component } from './content/panels/panel-h1-a1-ma0-md0/panel-h1-a1-ma0-md0.component';
import { PanelH1A1MA1MD0Component } from './content/panels/panel-h1-a1-ma1-md0/panel-h1-a1-ma1-md0.component';
import { PanelH1A1MA1MD1Component } from './content/panels/panel-h1-a1-ma1-md1/panel-h1-a1-ma1-md1.component';
import { PanelH1A0MD1Component } from './content/panels/panel-h1-a0-md1/panel-h1-a0-md1.component';
import { PanelH0A0MD1Component } from './content/panels/panel-h0-a0-md1/panel-h0-a0-md1.component';
import { ViewCh0Component } from './content/views/view-ch0/view-ch0.component';
import { ViewCh1Component } from './content/views/view-ch1/view-ch1.component';
import { ViewCh2Component } from './content/views/view-ch2/view-ch2.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        NavbarComponent,
        NavbarItemComponent,
        HeaderSearchComponent,
        HeaderNotificationsComponent,
        HeaderUserMenuComponent,
        ContentHeaderComponent,
        HeaderSubscriptionsComponent,
        ContentActionsComponent,
        HeaderLogoComponent,
        SearchResultComponent,
        SearchResultItemComponent,
        SearchToolbarComponent,
        BckpJobComponent,
        Its2RequestComponent,
        BackAuthUserComponent,
        BackAuthEntityComponent,
        BckpServerComponent,
        Its2RequestSearchCategoryComponent,
        BckpJobSearchCategoryComponent,
        BckpServerSearchCategoryComponent,
        BackAuthUserCategoryComponent,
        BackAuthEntityCategoryComponent,
        BckpPolicyCategoryComponent,
        CompInstanceCategoryComponent,
        BckpPolicyComponent,
        CompInstanceComponent,
        ScrollMenuComponent,
        SectionT0Component,
        SectionT1Component,
        PanelH1A1MA0MD0Component,
        PanelH1A1MA1MD0Component,
        PanelH1A1MA1MD1Component,
        PanelH1A0MD1Component,
        PanelH0A0MD1Component,
        ViewCh0Component,
        ViewCh1Component,
        ViewCh2Component,
    ],
    imports: [
        CommonModule,
        UIModule,
        PipesModule,
        RouterModule,
        TranslateModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        NavbarComponent,
        ContentHeaderComponent,
        ContentActionsComponent,
        SectionT0Component,
        SectionT1Component,
        PanelH1A1MA0MD0Component,
        PanelH1A1MA1MD0Component,
        PanelH1A1MA1MD1Component,
        PanelH1A0MD1Component,
        PanelH0A0MD1Component,
        ViewCh0Component,
        ViewCh1Component,
        ViewCh2Component
    ],
    providers: [
        TranslatePipe
    ]
})
export class LayoutModule
{
}
