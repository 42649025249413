import { createReducer, on } from '@ngrx/store';

import { CreateInstance } from '../../models/instance';
import * as fromAuthLoginActions from 'src/app/_apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from 'src/app/_apps/auth/store/profile/profile.actions';
import * as fromMiscStoreActions from './misc.actions';

export const OstkMiscFeatureKey = 'misc';

export interface IOstkMiscState
{
    ci: CreateInstance;
}

const InitialState: IOstkMiscState = {
    ci: new CreateInstance(null)
};
export const MiscReducer = createReducer(
    InitialState,
    on(
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => InitialState
    ),
    on(
        fromMiscStoreActions.OstkInstancesCreateStep1Exited,
        (state: IOstkMiscState, { step1 }) => ({
            ...state,
            ci: {
                ...state.ci,
                step1
            }
        })
    ),
    on(
        fromMiscStoreActions.OstkInstancesCreateStep2Exited,
        (state: IOstkMiscState, { step2 }) => ({
            ...state,
            ci: {
                ...state.ci,
                step2
            }
        })
    ),
    on(
        fromMiscStoreActions.OstkInstancesCreateReset,
        (state: IOstkMiscState) => ({
            ...state,
            ci: new CreateInstance(null)
        })
    )
);
