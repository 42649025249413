import { Field4Filter } from './field-4filter';

export type Predicate<T> = (x: T) => boolean;

export class InMemoryFilter<T>
{
    predicates: Predicate<T>[] = [];

    constructor(fields: Map<string, Field4Filter<T, any>>)
    {
        this.predicates = [];
        for (const f4f of fields.values())
        {
            this.predicates.push(f4f.process());
        }
    }
}
