import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppConstants } from '@apps/app.constants';
import { BaseListApiService } from '@common/api/base-list-api.service';
import { ResultApi } from '@common/api/result-api';
import { ImageCopyApi } from './models';
import { BckpConstants } from '../bckp.constants';

@Injectable({
    providedIn: 'root'
})
export class CopyListApiService extends BaseListApiService<ImageCopyApi>
{
    constructor (
        http: HttpClient
    )
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlHist;
        this.resourceUrl = `/${BckpConstants.BaseApi}/netbackup/copies`;
    }

    getAllCopies (qs: string): Observable<ResultApi<ImageCopyApi>>
    {
        if (AppConstants.IsDataLocal('bckp'))
        {
            return this.http.get<ResultApi<ImageCopyApi>>('assets/data/bckp/copies.json');
        }

        return this.getAll(`${this.resourceUrl}/all${qs}`);
    }

    getLatestCopies (qs: string): Observable<ResultApi<ImageCopyApi>>
    {
        if (AppConstants.IsDataLocal('bckp'))
        {
            return this.http.get<ResultApi<ImageCopyApi>>('assets/data/bckp/copies.json');
        }

        return this.getAll(`${this.resourceUrl}/latest${qs}`);
    }
}
