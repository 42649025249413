import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, first, take, concatAll } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { BaseListDomainService } from '@common/domain/base-list-domain.service';
import { DatesService } from '@common/services/dates.service';
import { BckpJobSearchResult } from '@common/models/search-result';
import { ResultDomain } from '@common/domain/result-domain';
import { ResultApi } from '@common/api/result-api';
import { LanguageService } from '@common/services';

import { JobListApiService } from '../api/job-list-api.service';
import { JobDetailsListApiService } from '../api/job-details-list-api.service';
import { Job, JobDetails } from './models';
import { JobApi, JobDetailsApi } from '../api/models';

@Injectable({
    providedIn: 'root'
})
export class JobListDomainService extends BaseListDomainService
{
    constructor (
        private jobListApiService: JobListApiService,
        private jobDetailsListApiService: JobDetailsListApiService,
        private datesService: DatesService,
        private translateService: TranslateService,
        private languageService: LanguageService
    )
    {
        super();
    }

    getAll (qs: string): Observable<ResultDomain<Job>>
    {
        return this.jobListApiService.getJobs(qs)
            .pipe(
                map((resultApi: ResultApi<JobApi>) =>
                {
                    const resultDomain = new ResultDomain<Job>();

                    resultDomain.count = resultApi['@odata.count'];
                    resultDomain.value = resultApi.value.map<Job>((jobApi: JobApi) =>
                        Job.mapperFromApi(jobApi, this.datesService, this.translateService, this.languageService.language)
                    );

                    return resultDomain;
                })
            );
    }

    search (qs: string): Observable<BckpJobSearchResult[]>
    {
        return this.jobListApiService.getJobs(qs)
            .pipe(
                map((obj: ResultApi<JobApi>): JobApi[] =>
                    obj.value
                ),
                map((result: JobApi[]) =>
                    result
                        .map<BckpJobSearchResult>(this.searchMapperFromApi, this)
                        .slice(0, 7)
                )
            );
    }

    private searchMapperFromApi (jobApi: JobApi): BckpJobSearchResult
    {
        return new BckpJobSearchResult(
            Job.mapperFromApi(jobApi,
                this.datesService,
                this.translateService,
                this.languageService.language
            )
        );
    }

    getJobDetails (documentId: string, qsSinceUntil: string): Observable<JobDetails>
    {
        return this.jobDetailsListApiService.getJobDetails(documentId, qsSinceUntil)
            .pipe(
                map((obj: ResultApi<JobDetailsApi>): JobDetailsApi[] =>
                    obj.value
                ),
                map((result: JobDetailsApi[]) =>
                    result.map<JobDetails>((jobDetailsApi: JobDetailsApi) =>
                        JobDetails.mapperFromApi(jobDetailsApi, this.datesService, this.translateService, this.languageService.language)
                    )
                ),
                first(),
                map((jobs: JobDetails[]) =>
                    jobs[0]
                )
            );
    }

    getByBackupId (backupId: string): Observable<string>
    {
        return this.jobListApiService.getByBackupId(backupId)
            .pipe(
                map((obj: ResultApi<JobApi>): JobApi[] =>
                    obj.value
                ),
                concatAll(),
                take(1),
                map((job: JobApi) =>
                    job.documentId
                )
            );
    }
}
