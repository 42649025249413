import { HttpEvent } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { MediaTypes } from '@common/services/files.service';
import { ServersFilter } from '@apps/bckp/models';
import { Server, Image } from '@apps/bckp/domain/models';
import { EnrichedAsset } from '@apps/cmdb/models';

export const BckpServersServODataInitialized = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv OData Initialized',
    props<{ scrollSize: number, filter: ServersFilter }>()
);

export const BckpServersServODataRequested = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv OData Requested'
);

export const BckpServersServODataLoaded = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv OData Loaded',
    props<{ servers: Server[] }>()
);

export const BckpServersServODataFailed = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv OData Failed'
);

export const BckpServersServODataIncremented = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv OData Incremented'
);

export const BckpServersServCleared = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Cleared'
);

export const BckpServersServFilterApplied = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Filter Applied',
    props<{ filter: ServersFilter }>()
);

export const BckpServersServFilterReset = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Filter Reset'
);

export const BckpServersServExportRequested = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Export Requested',
    props<{ format: MediaTypes }>()
);

export const BckpServersServExportSucceeded = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Export Succeeded',
    props<{ response: HttpEvent<Blob> }>()
);

export const BckpServersServQsWordSet = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv QsWord Set',
    props<{ qsWord: string }>()
);

export const BckpServersServSelectedImagesRequested = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Selected Images Requested',
    props<{ serverShort: string; }>()
);

export const BckpServersServSelectedImagesSucceeded = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Selected Images Succeeded',
    props<{ images: Image[] }>()
);
export const BckpServersServSelectedImagesFailed = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Selected Images Failed'
);

export const BckpServersServTagAttachRequested = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Tag Attach Requested',
    props <{ id: string, name: string, identifier: string, tagId: string}>()
);

export const BckpServersServTagAttachExecuted = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Tag Attach Executed',
    props<{ enrichedAsset: EnrichedAsset; }>()
);

export const BckpServersServTagAttachSucceeded = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Tag Attach Succeeded',
    props<{ updatedServer: Update<Server> }>()
);

export const BckpServersServTagDetachRequested = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Tag Detach Requested',
    props<{ id: string, identifier: string, tagId: string }>()
);

export const BckpServersServTagDetachExecuted = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Tag Detach Executed',
    props<{ enrichedAsset: EnrichedAsset; }>()
);


export const BckpServersServTagDetachSucceeded = createAction(
    '[BCKP-SERVERS] Bckp Servers Serv Tag Detach Succeeded',
    props<{ updatedServer: Update<Server> }>()
);
