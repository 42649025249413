import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';

@Component({
    selector: 'aa-infinite-loader',
    templateUrl: './infinite-loader.component.html',
    styleUrls: [
        './infinite-loader.component.scss'
    ]
})
export class InfiniteLoaderComponent implements OnInit
{
    @ViewChild('container', { static: true }) container: ElementRef;
    public width: string;
    public height: string;
    //
    private ww = 200;
    private w = 40;
    private duration: number = 2;
    private larg = 5;
    public offset = 10;

    public x1: string;
    public width1: string;
    public width2px: string;
    public wx = `${this.w}px`;
    public d = `${this.duration}s`;
    public ww1 = `${this.ww}px`;
    public begin1: string = '0s; second.end';
    public begin2: string = 'first.end';
    //

    constructor()
    {

        const dim: number = ((this.ww + this.w) / this.larg) + 1;
        const val: number[] = Array(dim).fill(0).map((x, i) => i);
        const x1 = val.map((v: number) => (this.larg * v - this.w) <= 0 ? 0 : this.larg * v - this.w);
        const idx = x1.findIndex((n: number) => n >= (this.ww - this.w));
        const period = this.duration / dim;
        const offset = (val.length - idx - 1) * period;

        this.begin1 = `0s;second.end - ${offset}s`;
        this.begin2 = `first.end - ${offset}s`;
        this.x1 = x1.join(';');
        this.width1 = x1
            .map((n: number, i) =>
                n <= 0
                    ? (this.larg * i)
                    : (n >= (this.ww - this.w))
                        ? this.ww - n
                        : this.w)
            .join('px;') + 'px';
    }

    ngOnInit ()
    {
        const { width, height } = this.container.nativeElement.getBoundingClientRect();

        this.width = `${width}px`;
        this.height = `${height}px`;
    }
}
