import { createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';

import { getDashboardState } from '../dashboard-selectors';
import { IIts2DashboardState } from '../dashboard.state';
import { IIts2DashSharedState, Its2DashSharedFeatureKey } from './shared.state';

const getSharedState = createSelector(
    getDashboardState,
    (state: IIts2DashboardState) => state[Its2DashSharedFeatureKey]
);

export const getDashSinceUntil = createSelector(
    getSharedState,
    (state: IIts2DashSharedState): [DateTime, DateTime] =>
        [state.since, state.until]
);
