export class AuthConstants
{
    public static AuthBaseUrl: string = 'auth';
    public static TokenKey = 'token';
    public static Profile = 'profile';
    public static Account = 'account';
    public static Step = 'step';
    public static Create = 'create';
    public static LinkSent = 'link-sent';
    public static ForgottenPassword = 'forgotten-password';
    public static Problem = 'problem';
    public static ProblemArisen = 'problem-arisen';
    public static MinPasswordLength: number = 10;
    public static SpecialCharacters = '!#$%&*<=>?@^_|+.-';
    public static PasswordRegex: RegExp = new RegExp(
        '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[' +
        AuthConstants.SpecialCharacters +
        ']).{4,}$'
    );
    public static TwoFactor: string = 'twofactor';

    public static ProfileStepRoute = (n: number) => [
        AuthConstants.AuthBaseUrl,
        AuthConstants.Profile,
        `${AuthConstants.Step}${n}`
    ];
    public static AccountCreateStepRoute = (n: number) => [
        AuthConstants.AuthBaseUrl,
        AuthConstants.Account,
        AuthConstants.Create,
        `${AuthConstants.Step}${n}`
    ];
    public static AccountCreateLinkSentRoute = () => [
        AuthConstants.AuthBaseUrl,
        AuthConstants.Account,
        AuthConstants.Create,
        AuthConstants.LinkSent
    ];
    public static AccountCreateProblemArisenRoute = () => [
        AuthConstants.AuthBaseUrl,
        AuthConstants.Account,
        AuthConstants.Create,
        AuthConstants.ProblemArisen
    ];
    public static ForgottenPasswordRoute = (params?: any): any[] =>
    {
        const route: any[] = [
            AuthConstants.AuthBaseUrl,
            AuthConstants.ForgottenPassword
        ];

        if (params)
        {
            route.push(params);
        }

        return route;
    };
    public static LinkSentRoute = () => [
        AuthConstants.AuthBaseUrl,
        AuthConstants.LinkSent
    ];
    public static ProblemRoute = () => [
        AuthConstants.AuthBaseUrl,
        AuthConstants.Problem
    ];
    public static TwoFactorRoute = (params?: any): any[] =>
    {
        const route: any[] = [
            AuthConstants.AuthBaseUrl,
            AuthConstants.TwoFactor
        ];

        if (params)
        {
            route.push(params);
        }

        return route;
    };
}
