import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import * as fromRootStore from '@apps/root/store';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { CompareLowerString2 } from '@common/services/utils.service';

import { Network } from '../../../domain/models/network';
import * as fromOstkProjectsActions from '../../projects/projects.actions';
import * as FromOstkNetworksNetwActions from './netw.actions';
import { OstkConstants } from '../../../ostk.constants';
import { SelectOption } from '@ui/components/form/common';

export const OstkNetworksNetwFeatureKey = 'netw';
type INetworksNetwState = EntityState<Network>;
export const networksNetwAdapter: EntityAdapter<Network> = createEntityAdapter({
    selectId: (network: Network) => network.id,
    sortComparer: CompareLowerString2<Network>((item: Network) => item.name)

});
const networksNetwInitialState: INetworksNetwState = networksNetwAdapter.getInitialState({});

type INetworksNetwPoolState = EntityState<SelectOption>;
export const networksNetwPoolAdapter: EntityAdapter<SelectOption> = createEntityAdapter({
    selectId: (option: SelectOption) => option.value.toString(),
    sortComparer: CompareLowerString2<SelectOption>((item: SelectOption) => item.label)

});
const networksNetwPoolInitialState: INetworksNetwPoolState = networksNetwPoolAdapter.getInitialState({});

export interface IOstkNetworksNetwState
{
    networks: INetworksNetwState;
    isEmpty: boolean;
    isLoading: boolean;
    isOups: boolean;
    isLoaded: boolean;
    expirationDate?: DateTime;
    selectedNetwork: Network;
    qsWord: string;
    poolOptions: INetworksNetwPoolState;
}

export const OstkNetworksNetwInitialState: IOstkNetworksNetwState = {
    networks: networksNetwInitialState,
    isEmpty: false,
    isLoading: false,
    isOups: false,
    isLoaded: false,
    expirationDate: null,
    selectedNetwork: null,
    qsWord: '',
    poolOptions: networksNetwPoolInitialState
};

export const OstkNetworksNetwReducer = createReducer(
    OstkNetworksNetwInitialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        fromOstkProjectsActions.OstkProjectSelected,
        () => OstkNetworksNetwInitialState
    ),
    on(
        FromOstkNetworksNetwActions.OstkNetworksNetwListRequested,
        (state) => ({
            ...state,
            isLoading: !(state.expirationDate !== null && DateTime.utc() < state.expirationDate)
        }),
    ),
    on(
        FromOstkNetworksNetwActions.OstkNetworksNetwListSucceeded,
        (state, { networks }: { networks: Network[]; }) => ({
            ...state,
            isEmpty: networks && networks.length === 0,
            isLoading: false,
            isOups: false,
            isLoaded: networks && networks.length !== 0,
            networks: networksNetwAdapter.setAll(networks, state.networks),
            expirationDate: DateTime.utc().plus({ minutes: OstkConstants.DataLifeTime })
        }),
    ),
    on(
        FromOstkNetworksNetwActions.OstkNetworksNetwListFailed,
        (state) => ({
            ...state,
            isLoading: false,
            isEmpty: false,
            isOups: true,
            isLoaded: false,
            networks: networksNetwAdapter.removeAll(state.networks),
            expirationDate: null
        }),
    ),
    on(
        FromOstkNetworksNetwActions.OstkNetworksNetwSelectSucceded,
        (state, { network }: { network: Network; }) => ({
            ...state,
            selectedNetwork: network
        })
    ),
    on(
        FromOstkNetworksNetwActions.OstkNetworksNetwQsWordSet,
        (state, { qsWord }) => ({
            ...state,
            qsWord: qsWord.toLowerCase()
        })
    ),
    on(
        FromOstkNetworksNetwActions.OstkNetworksNetwCreateSucceeded,
        (state, { network }) => ({
            ...state,
            networks: networksNetwAdapter.addOne(network, state.networks),
        })
    ),
    on(
        FromOstkNetworksNetwActions.OstkNetworksNetwDeleteSucceeded,
        (state, { id }) => ({
            ...state,
            networks: networksNetwAdapter.removeOne(id, state.networks),
        })
    ),
    on(
        FromOstkNetworksNetwActions.OstkNetworksNetwSubnetDeleteSucceeded,
        (state, { networkId, subnetId }) =>
        {
            const selectedNetwork: Network = state.selectedNetwork;
            selectedNetwork.subnets = selectedNetwork.subnets.filter(s => s.id !== subnetId);

            const network: Update<Network> = {
                id: networkId,
                changes: {
                    subnets: selectedNetwork.subnets
                }
            };

            return {
                ...state,
                networks: networksNetwAdapter.updateOne(network, state.networks),
                selectedNetwork: selectedNetwork
            };
        }
    ),
    on(
        FromOstkNetworksNetwActions.OstkNetworksNetwPoolsSucceeded,
        (state, { poolOptions }: { poolOptions: SelectOption[]; }) =>
        {
            return ({
                ...state,
                poolOptions: networksNetwPoolAdapter.setAll(poolOptions, state.poolOptions)
            });
        }
    )
);
