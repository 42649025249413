import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { Assetify } from '@common/domain/assetify';
import { UtilsService, DatesService } from '@common/services';
import { AppConstants } from '@apps/app.constants';
import { JobApi, JobDetailsApi, TryApi } from '../../api/models/job-api';
import { BckpConstants } from '../../bckp.constants';

export class Job extends Assetify
{
    assetName = (): string => this.serverShort;
    tagFullNames: string;
    tagIds: string[];
    public storeId: string;
    public documentId: string;
    public jobId: number;
    public parentJobId: number;
    public server: string;
    public serverShort: string;
    public policyName: string;
    public policyType: string;
    public state: string;
    public status: number;
    public statusCode: number;
    public metaStatus: string;
    public statusClass: string;
    public scheduleName: string;
    public scheduleType: string;
    public master: string;
    public elapsed: string;
    public started: DateTime;
    public ended: DateTime;
    public isRegulated: boolean;
    public statusDesc: string;
    public errorDesc: string;
    public static mapperFromApi (source: JobApi, datesService: DatesService, translateService: TranslateService, language: string): Job
    {
        const target = new Job();

        Object.assign(target, source);

        target.storeId = UtilsService.hash(source.documentId);
        if (source.started)
        {
            target.started = datesService.UtcStringToDateHour(source.started);
        }

        if (source.ended)
        {
            target.ended = datesService.UtcStringToDateHour(source.ended);
        }

        target.statusCode = target.status;
        target.status = BckpConstants.getCodeStatus(source.status);
        if (source.state.toLowerCase() === 'running')
        {
            target.status = 3;
        }

        target.metaStatus = translateService.instant(BckpConstants.JobsMetaStatus.get(target.status));
        target.statusClass = BckpConstants.getCssStatus[target.status];
        if (source.elapsed)
        {
            target.elapsed = datesService.NumberToDuration(source.elapsed, language);
        }

        target.errorDesc = `${target.statusCode} - ${target.statusDesc}`;

        return target;
    }
}

export class Try
{
    public started: DateTime;
    public ended: DateTime;
    public storageUnit: string;
    public mediaServer: string;
    public status: number;
    public statusCount: number;
    public statusLines: string[];
    public kbytes: number;
    public bytes: number;
    public numFiles: number;
    public elapsed: number;
    public statusDesc: string;

    public static mapperFromApi (source: TryApi, datesService: DatesService): Try
    {
        const target = new Try();

        Object.assign(target, source);
        target.bytes = AppConstants.MilleVingtQuatre * source.kbytes;
        if (source.started)
        {
            target.started = datesService.UtcStringToDateHour(source.started);
        }

        if (source.ended)
        {
            target.ended = datesService.UtcStringToDateHour(source.ended);
        }

        return target;
    }
}

export class JobDetails extends Job
{
    public kbytes: number;
    public bytes: number;
    public dedupRatio: number;
    public backupId: string;
    public jobType: string;
    public storageUnit: string;
    public mediaServer: string;
    public numFiles: number;
    public retentionPeriod: number;
    public tries: Try[];

    public static mapperFromApi (
        source: JobDetailsApi,
        datesService: DatesService,
        translateService: TranslateService,
        language: string
    ): JobDetails
    {
        let target = new JobDetails();

        Object.assign(target, source);
        target = Job.mapperFromApi(source, datesService, translateService, language) as JobDetails;

        target.bytes = source.kbytes ? AppConstants.MilleVingtQuatre * source.kbytes : null;
        target.dedupRatio = source.dedupRatio ? source.dedupRatio / 100 : null;
        target.tries = source.tries.map((tryApi: TryApi) =>
            Try.mapperFromApi(tryApi, datesService)
        );

        return target;
    }
}
