<div class="input-component"
     [ngClass]="{'pristine': formGroup.pristine, 'valid': formGroup.valid, 'invalid': formGroup.invalid, 'disabled': disabled}">
    <div class="input-wrapper">
        <input type="hidden"
               [(ngModel)]="value"
               [id]="fieldId"
               [name]="name"
               [attr.value]="value"
               [ngModelOptions]="updateOn" />
        <form [formGroup]="formGroup"
              #digicode>
            <input *ngFor="let field of digitsField; index as idx"
                   class="digit"
                   [disabled]="notEnabled"
                   [attr.required]="required"
                   type="tel"
                   name="{{field}}"
                   formControlName="{{field}}"
                   maxlength="1"
                   placeholder="·"
                   autocomplete="off"
                   (paste)="onPaste($event)"/>
        </form>
        <span class="input-validation fa-stack fa-1x"
              *ngIf="formGroup.dirty">
            <i class="fas fa-stack-2x fa-circle"
               aria-hidden="true"></i>
            <i class="fal fa-stack-1x"
               [ngClass]="formGroup.valid ? 'fa-check' : 'fa-times'"
               aria-hidden="true"></i>
        </span>
    </div>
    <div>
        <span (click)="reset()">
            <i class="fa-solid fa-eraser fa-2x digit-code-eraser"
               aria-hidden="true"></i>
        </span>
    </div>
    <aa-validation2 [dirty]="formGroup.dirty"
                    [messages]="failures | async">
    </aa-validation2>
</div>
