<div class="radio-group" [ngClass]="orientation" *ngIf="lineSize === 0" [attr.disabled]="notEnabled ? '' : null"
     [style.marginTop.px]="marginTop">
    <div class="radio" *ngFor="let so of options">
        <aa-radio2 [toTranslate]="toTranslate" [option]="so" name="{{ name }}"> </aa-radio2>
    </div>
</div>
<div class="radio-group" *ngIf="lineSize !== 0" [attr.disabled]="notEnabled ? '' : null"
     [style.marginTop.px]="marginTop">
    <table>
        <tr *ngFor="let line of lines; index as i">
            <td *ngFor="let so of options.slice(i * lineSize, (i + 1) * lineSize)">
                <aa-radio2 [toTranslate]="toTranslate" [option]="so" name="{{ name }}"> </aa-radio2>
            </td>
        </tr>
    </table>
</div>
