import { Component } from '@angular/core';

import { OstkFailure } from '@common/models';
import { Message } from '@apps/notf/models/message';

import { SnackbarRef } from './snackbar.ref';

@Component({
    selector: 'aa-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: [
        './snackbar.component.scss'
    ]
})
export class SnackbarComponent
{
    public message: string = '';
    public title: string = '';
    public gravity: string = 'standard';
    public isFailure: boolean = true;

    constructor (snackbarRef: SnackbarRef)
    {
        if (snackbarRef.gravity !== null)
        {
            this.gravity = Message.GetGravityClass(snackbarRef.gravity);
        }

        this.isFailure = typeof snackbarRef.failure !== 'string';
        if (this.isFailure)
        {
            const failure = (snackbarRef.failure as OstkFailure);

            try
            {
                this.message = failure.error.body.NeutronError?.message
                    || failure.error.body.badRequest?.message
                    || failure.error.body.conflictingRequest?.message
                    || failure.error.body.error?.message
                    || failure.message;
            }
            catch
            {
                this.message = failure.message;
            }
        }
        else
        {
            this.message = <string>snackbarRef.failure;
        }
    }
}
