import { createReducer, on } from '@ngrx/store';

import * as fromRootImpeActions from './impe.actions';
import { Impersonation } from '../../models';

export const RootImpeFeatureKey = 'impe';
export interface IRootImpeState
{
    impersonation: Impersonation;
}

const initialState: IRootImpeState = {
    impersonation: null
};

export const RootImpeReducer = createReducer(
    initialState,
    on(fromRootImpeActions.RootImpersonateSet,
        (state, { impersonation }) => ({
            ...state,
            impersonation: impersonation
        })
    ),
    on(fromRootImpeActions.RootImpersonateUnset,
        () =>
            initialState
    )
);
