import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VolumeApi } from '../../api/models';
import { Volume } from '../models';
import { VolumeListApiService } from '../../api';

@Injectable({
    providedIn: 'root'
})
export class VolumeListDomainService
{

    constructor (
        private volumeListApiService: VolumeListApiService
    )
    { }

    getVolumes (): Observable<Volume[]>
    {
        return this.volumeListApiService.getVolumes()
            .pipe(
                map((result: VolumeApi[]) =>
                    result.map<Volume>((volumeApi: VolumeApi) =>
                        Volume.mapperFromApi(volumeApi)
                    )
                )
            );
    }
}
