import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import { AppConstants } from '@apps/app.constants';

import * as fromAuthProfileStoreActions from './profile.actions';
import * as fromAuthLoginActions from '../login/login.actions';
import { Profile, ApplicationRole } from '../../models';

export const AuthProfileFeatureKey = 'profile';

export interface IAuthProfileState
{
    profile: Profile;
    appRoles: ApplicationRole[];
    expirationDate: DateTime;
}

const initialState: IAuthProfileState = {
    profile: null,
    appRoles: null,
    expirationDate: null
};

export const AuthProfileReducer = createReducer(
    initialState,
    on(
        fromAuthLoginActions.AuthLogout,
        () => (initialState)
    ),
    on(
        fromAuthProfileStoreActions.AuthLoginProfileLoaded,
        fromAuthProfileStoreActions.AuthProfileLoaded,
        (state, { profile }) =>
            ({
                ...state,
                profile,
                expirationDate: DateTime.utc().plus({ minutes: AppConstants.DataLifeTime })
            })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileApplicationRolesSet,
        (state, { appRoles }) =>
            ({
                ...state,
                appRoles: [...appRoles]
            })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileApplicationRolesReset,
        (state) =>
            ({
                ...state,
                appRoles: null
            })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileStep1Changed,
        fromAuthProfileStoreActions.AuthProfileStep2Changed,
        fromAuthProfileStoreActions.AuthProfileStep4Changed,
        (state, { profile }) => ({
            ...state,
            profile: {
                ...state.profile,
                ...profile
            }
        })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileLanguageChanged,
        (state, { profile }) => ({
            ...state,
            profile: {
                ...state.profile,
                language: profile.language
            }
        })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileSkinChanged,
        (state, { profile }) => ({
            ...state,
            profile: {
                ...state.profile,
                skin: profile.skin
            }
        })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileInformationsChanged,
        (state, { profile }) => ({
            ...state,
            profile: {
                ...state.profile,
                firstName: profile.firstName,
                lastName: profile.lastName,
                title: profile.title,
                phoneNumber: profile.phoneNumber,
                mobile: profile.mobile,
                firstLastName: `${profile.firstName} ${profile.lastName}`,
                lastFirstName: `${profile.lastName} ${profile.firstName}`
            }
        })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileSecurityTwofactorChanged,
        (state, { profile }) => ({
            ...state,
            profile: {
                ...state.profile,
                twoFactorEnabled: profile.twoFactorEnabled
            }
        })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileNewsletterMarketingChanged,
        (state, { profile }) => ({
            ...state,
            profile: {
                ...state.profile,
                marketingNewsletter: profile.marketingNewsletter
            }
        })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileNewsletterNotificationChanged,
        (state, { profile }) => ({
            ...state,
            profile: {
                ...state.profile,
                maintenanceNotification: profile.maintenanceNotification
            }
        })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileSocialNetworksCookiesChanged,
        (state, { profile }) => ({
            ...state,
            profile: {
                ...state.profile,
                socialNetworksCookies: profile.socialNetworksCookies
            }
        })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileStatisticsCookiesChanged,
        (state, { profile }) => ({
            ...state,
            profile: {
                ...state.profile,
                statisticsCookies: profile.statisticsCookies
            }
        })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileWorkingCookiesChanged,
        (state, { profile }) => ({
            ...state,
            profile: {
                ...state.profile,
                workingCookies: profile.workingCookies
            }
        })
    ),
    on(
        fromAuthProfileStoreActions.AuthProfileIsCatalogSecuAuthorizedSucceeded,
        (state, { isCatalogSecuAuthorized }: {isCatalogSecuAuthorized: boolean}) => ({
            ...state,
            profile: {
                ...state.profile,
                isCatalogSecuAuthorized
            }
        })
    )
);
