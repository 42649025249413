import { createReducer, on } from '@ngrx/store';

import * as fromRootImpeStoreActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { AuthUsersInitialState, IAuthUsersState, usersAdapter } from '@apps/auth/store/users/users.state';
import { AuthUsersSucceeded } from '@apps/auth/store/users/users.actions';
import { ShortProfile } from '@apps/auth/domain/models';

export const AuthUsersReducer = createReducer(
    AuthUsersInitialState,
    on(
        fromRootImpeStoreActions.RootImpersonateSet,
        fromRootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        (): IAuthUsersState =>
            AuthUsersInitialState
    ),
    on(
        AuthUsersSucceeded,
        (state: IAuthUsersState, { user }: { user: ShortProfile; }) =>
            ({
                ...state,
                users: usersAdapter.addOne(user, state.users)
            })
    )
);
