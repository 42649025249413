import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotfStoreModule } from '../_apps/notf/store/notf-store.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NotfStoreModule
    ]
})
export class NotfModule { }
