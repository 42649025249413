import { Component, Optional, Inject, Injector, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { gsap } from 'gsap';
import { ElementBase } from '../../../common';
@Component({
    selector: 'aa-input-switch',
    templateUrl: './input-switch.component.html',
    styleUrls: [
        '../input.component.scss',
        './input-switch.component.scss'
    ],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: InputSwitchComponent,
        multi: true,
    }]
})
export class InputSwitchComponent extends ElementBase<boolean> implements OnInit
{
    private static duration: number = 0.5;
    private static identifier = 0;
    private myCircle;
    private myPath;
    private colorOff: string = '#ededed';
    private colorOn: string = '#0057ff';
    private cxOff: number = 25;
    private cxOn: number = 75;
    public fieldId = `input-switch-${InputSwitchComponent.identifier++}-id`;
    public cx: number = 25;
    public fillPath: string = this.colorOn;
    @Input() posX: number = 0;
    @Input() posY: number = 0;
    @Input() public labelBefore: boolean = true;
    @Input() public checked: boolean;
    @Output() toggleSwitch: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
        injector: Injector,
        private elementRef: ElementRef
    )
    {
        super(injector);
    }
    ngOnInit ()
    {
        this.writeValue(!!this.checked);
        this.myCircle = this.elementRef.nativeElement.querySelector('circle');
        this.myPath = this.elementRef.nativeElement.querySelector('path');
        this.initVizu();
    }
    onToggleSwitch ()
    {
        this.value = !this.value;
        this.toggleVizu();
        this.toggleSwitch.emit(this.value);
    }
    toggleVizu ()
    {
        const tl = gsap.timeline();
        if (this.value)
        {
            tl
                .to(this.myCircle, { duration: InputSwitchComponent.duration, cx: this.cxOn, ease: 'sine.in' }, 0)
                .to(this.myPath, { duration: InputSwitchComponent.duration, fill: this.colorOn, ease: 'sine.in' }, 0);
        } else
        {
            tl
                .to(this.myCircle, { duration: InputSwitchComponent.duration, cx: this.cxOff, ease: 'sine.out' }, 0)
                .to(this.myPath, { duration: InputSwitchComponent.duration, fill: this.colorOff, ease: 'sine.out' }, 0);
        }
    }
    initVizu ()
    {
        Promise.resolve(null).then(() =>
        {
            this.cx = this.value ? this.cxOn : this.cxOff;
            this.fillPath = this.value ? this.colorOn : this.colorOff;
        });
    }
}
