<form id="auth-form" [formGroup]="form" (keyup.enter)="onSubmit()">
    <div class="auth-title">{{ 'AUTH.PROFILE.ONBOARDING.STEP2.TITLE' | translate }}</div>
    <div class="auth-sub-title">
        {{ 'AUTH.PROFILE.ONBOARDING.STEP2.SUBTITLE' | translate }}
    </div>
    <div class="names">
        <aa-input-phone
            name="phoneNumber"
            label="{{ 'AUTH.PROFILE.INFORMATIONS.PHONENUMBER' | translate }}"
            formControlName="phoneNumber"
            [tabindex]="2"
        >
        </aa-input-phone>
        <aa-input-phone
            name="mobile"
            label="{{ 'AUTH.PROFILE.INFORMATIONS.MOBILE' | translate }}"
            formControlName="mobile"
            [tabindex]="2"
        >
        </aa-input-phone>
    </div>
    <div class="buttons">
        <aa-btn-action-secondary
            [btnText]="'BUTTON.CANCEL' | translate"
            [cta]="onCancel"
            [btnWidth]="'100%'"
        >
        </aa-btn-action-secondary>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <aa-btn-action-secondary
            [btnText]="'BUTTON.CONTINUE' | translate"
            [cta]="onSubmit"
            [btnWidth]="'100%'"
        >
        </aa-btn-action-secondary>
    </div>
</form>
