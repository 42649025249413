import { Injectable } from '@angular/core';

import { Observable, map, of } from 'rxjs';
import { Store } from '@ngrx/store';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { IAuroraState } from '@apps/aurora.state';
import { SimpleEntity } from './models/back/simple-entity';
import { ItsmEntitiesMiscApiService } from '../api/department-misc-api.service';

@Injectable({
    providedIn: 'root'
})
export class ItsmEntitiesMiscDomainService extends BaseMiscDomainService
{
    constructor (
        private store: Store<IAuroraState>,
        private itsmEntitiesApiService: ItsmEntitiesMiscApiService
    )
    {
        super();
    }

    getEntities (): Observable<SimpleEntity[]>
    {
        return this.itsmEntitiesApiService.getEntities()
            .pipe(
                map(entities =>
                    entities.map(p => SimpleEntity.mapperFromApi(p))
                )
            );
    }

    getSubEntities (entityId: string): Observable<string[]>
    {
        return this.itsmEntitiesApiService.getSubEntities(entityId);
    }

    saveSubEntities (entityId: string, subEntities: string[]): Observable<boolean>
    {
        return this.itsmEntitiesApiService.saveSubEntities(entityId, subEntities);
    }
}
