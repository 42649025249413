export class FilterFieldValue
{
    name: string;
    value: any;

    constructor(n: string, v: any)
    {
        this.name = n;
        this.value = v;
    }
}
