import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { switchMap, concatAll, filter, map, toArray, tap } from 'rxjs/operators';

import { RequestStatusesDomainService } from '@apps/its2/domain';

import * as fromIts2SelectedActions from './selected.actions';
import * as fromIts2RequestsActions from '../requests/requests.actions';
import { Request } from '../../domain/models/request';
import { Store } from '@ngrx/store';
import { IAuroraState } from '@apps/aurora.state';

@Injectable()
export class Its2SelectedEffects
{
    constructor (
        private store: Store<IAuroraState>,
        private actions: Actions,
        private RequestStatusesDomainService: RequestStatusesDomainService
    )
    { }

    its2RequestsFavoritesSet = createEffect(
        () => this.actions
            .pipe(
                ofType(fromIts2SelectedActions.Its2RequestsFavoritesSet),
                switchMap((params: any) =>
                    this.RequestStatusesDomainService.addToFavorites(params.rfcNumbers)
                        .pipe(
                            tap((rfcNumbers: string[]) =>
                                this.store.dispatch(fromIts2RequestsActions.Its2RequestsFavoritesStatusUpdate({ rfcNumbers }))
                            ),
                            concatAll(),
                            filter((rfcNumber: string) =>
                                params.rfcNumbers.includes(rfcNumber)
                            ),
                            map((rfcNumber: string) => ({
                                id: rfcNumber,
                                changes: {
                                    isFavorite: true,
                                    isChecked: false
                                }
                            } as Update<Request>)
                            ),
                            toArray(),
                            switchMap((requests: Update<Request>[]) =>
                                [
                                    fromIts2RequestsActions.Its2RequestsUpdateMany({ requests }),
                                    fromIts2SelectedActions.Its2RequestsSelectedCleared()
                                ]
                            )
                        )
                )
            )
    );

    its2RequestsFavoritesUnset = createEffect(
        () => this.actions
            .pipe(
                ofType(fromIts2SelectedActions.Its2RequestsFavoritesUnset),
                switchMap((params: any) =>
                    this.RequestStatusesDomainService.removeFromFavorites(params.rfcNumbers)
                        .pipe(
                            tap((rfcNumbers: string[]) =>
                                this.store.dispatch(fromIts2RequestsActions.Its2RequestsFavoritesStatusUpdate({ rfcNumbers }))
                            ),
                            concatAll(),
                            filter((rfcNumber: string) =>
                                params.rfcNumbers.includes(rfcNumber)
                            ),
                            map((rfcNumber: string) => ({
                                id: rfcNumber,
                                changes: {
                                    isFavorite: false,
                                    isChecked: false
                                }
                            } as Update<Request>)
                            ),
                            toArray(),
                            switchMap((requests: Update<Request>[]) =>
                                [
                                    fromIts2RequestsActions.Its2RequestsUpdateMany({ requests }),
                                    fromIts2SelectedActions.Its2RequestsSelectedCleared()
                                ]
                            )
                        )
                )
            )
    );

    its2RequestsReadSet = createEffect(
        () => this.actions
            .pipe(
                ofType(fromIts2SelectedActions.Its2RequestsReadSet),
                switchMap((params: any) =>
                    this.RequestStatusesDomainService.addToRead(params.rfcNumbers)
                        .pipe(
                            tap((rfcNumbers: string[]) =>
                                this.store.dispatch(fromIts2RequestsActions.Its2RequestsReadStatusUpdate({ rfcNumbers }))
                            ),
                            concatAll(),
                            filter((rfcNumber: string) =>
                                params.rfcNumbers.includes(rfcNumber)
                            ),
                            map((rfcNumber: string) => ({
                                id: rfcNumber,
                                changes: {
                                    isRead: true,
                                    isChecked: false
                                }
                            } as Update<Request>)
                            ),
                            toArray(),
                            switchMap((requests: Update<Request>[]) =>
                                [
                                    fromIts2RequestsActions.Its2RequestsUpdateMany({ requests }),
                                    fromIts2SelectedActions.Its2RequestsSelectedCleared()
                                ]
                            )
                        )
                )
            )
    );

    its2RequestsReadUnset = createEffect(
        () => this.actions
            .pipe(
                ofType(fromIts2SelectedActions.Its2RequestsReadUnset),
                switchMap((params: any) =>
                    this.RequestStatusesDomainService.removeFromRead(params.rfcNumbers)
                        .pipe(
                            tap((rfcNumbers: string[]) =>
                                this.store.dispatch(fromIts2RequestsActions.Its2RequestsReadStatusUpdate({ rfcNumbers }))
                            ),
                            concatAll(),
                            filter((rfcNumber: string) =>
                                params.rfcNumbers.includes(rfcNumber)
                            ),
                            map((rfcNumber: string) => ({
                                id: rfcNumber,
                                changes: {
                                    isRead: false,
                                    isChecked: false
                                }
                            } as Update<Request>)
                            ),
                            toArray(),
                            switchMap((requests: Update<Request>[]) =>
                                [
                                    fromIts2RequestsActions.Its2RequestsUpdateMany({ requests }),
                                    fromIts2SelectedActions.Its2RequestsSelectedCleared()
                                ]
                            )
                        )
                )
            )
    );
}
