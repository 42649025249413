import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Volume } from '../../../domain/models';

import { CreateVolume } from '../../../models/volumes';
import { VolumeFields4Filter } from '../../../models/volumes/volume-fields-4filter';

export const OstkVolumesVoluListRequested = createAction(
    '[OSTK-VOLU] Ostk Volumes Volu List Requested'
);
export const OstkVolumesVoluListSucceeded = createAction(
    '[OSTK-VOLU] Ostk Volumes Volu List Loaded',
    props<{ volumes: Volume[] }>()
);
export const OstkVolumesVoluListFailed = createAction(
    '[OSTK-VOLU] Ostk Volumes Volu List Failed',
    props<{ err?: any}>()
);

export const OstkVolumesVoluCreationRequested = createAction(
    '[OSTK-VOLU] Ostk Volumes Volu Creation Requested',
    props<{ createVolume: CreateVolume }>()
);
export const OstkVolumesVoluCreationSucceeded = createAction(
    '[OSTK-VOLU] Ostk Volumes Volu Creation Succeeded',
    props<{ volume: Volume; oldStatus: string; }>()
);
export const OstkVolumesVoluCreationFailed = createAction(
    '[OSTK-VOLU] Ostk Volumes Volu Creation Failed',
    props<{ err?: any }>()
);
export const OstkVolumesVoluPollingStatusRequested = createAction(
    '[OSTK-VOLU] Ostk Volumes Volu Polling Status Requested',
    props<{ volumeId: string; oldStatus: string; }>()
);

export const OstkVolumesVoluSwitchFilterSet = createAction(
    '[OSTK-VOLU] Ostk Volumes Volu Switch Filter Set',
    props<{ qsf: boolean; }>()
);

export const OstkVolumesApplyFilter = createAction(
    '[OSTK-VOLU] Ostk Volumes Apply Filter',
    props<{ vf4f?: VolumeFields4Filter }>()
);
export const OstkVolumesFilterApplied = createAction(
    '[OSTK-VOLU] Ostk Volumes Filter Applied',
    props<{ volumes: Volume[] }>()
);
export const OstkVolumesVoluAttachRequested = createAction(
    '[OSTK-VOLU] Ostk Volumes Attach Requested',
    props<{ volume: Volume, serverId: string }>()
);
export const OstkVolumesVoluAttachSucceeded = createAction(
    '[OSTK-VOLU] Ostk Volumes Attach Succeeded',
    props<{ volume: Volume }>()
);
export const OstkVolumesVoluDetachRequested = createAction(
    '[OSTK-VOLU] Ostk Volumes Detach Requested',
    props<{ volume: Volume }>()
);
export const OstkVolumesVoluDetachSucceeded = createAction(
    '[OSTK-VOLU] Ostk Volumes Detach Succeeded',
    props<{ volume: Volume }>()
);
export const OstkVolumesVoluExtendRequested = createAction(
    '[OSTK-VOLU] Ostk Volumes Extend Requested',
    props<{ volume: Volume, newSize: number, serverId?: string }>()
);
export const OstkVolumesVoluExtendSucceeded = createAction(
    '[OSTK-VOLU] Ostk Volumes Extend Succeeded',
    props<{ volume: Volume, newSize: number }>()
);
export const OstkVolumesVoluExtendFailed = createAction(
    '[OSTK-VOLU] Ostk Volumes Extend Failed',
    props<{ error?: any }>()
);
export const OstkVolumesVoluDeleteRequested = createAction(
    '[OSTK-VOLU] Ostk Volumes Delete Requested',
    props<{ volume: Volume }>()
);
export const OstkVolumesVoluDeleteSucceeded = createAction(
    '[OSTK-VOLU] Ostk Volumes Delete Succeeded',
    props<{ volumeId: string }>()
);
export const OstkVolumesVoluDeleteFailed = createAction(
    '[OSTK-VOLU] Ostk Volumes Delete Failed',
    props<{ error?: any }>()
);
export const OstkVolumeSelected = createAction(
    '[OSTK-VOLU] Ostk Volume Selected',
    props<{ volume: Volume }>()
);
export const OstkVolumesVoluRefreshOneRequested = createAction(
    '[OSTK-VOLU] Ostk Volumes Relaod Requested',
    props<{ volumeId: string }>()
);
export const OstkSelectVolumeRequested = createAction(
    '[OSTK-VOLU] OstkSelectVolumeRequested',
    props<{ volumeId: string }>()
);
export const OstkVolumesVoluRenameRequested = createAction(
    '[OSTK-VOLU] Ostk Volumes Rename Requested',
    props<{ volume: Volume, newName: string }>()
);
export const OstkVolumesVoluRenameSucceeded = createAction(
    '[OSTK-VOLU] Ostk Volumes Rename Succeeded',
    props<{ volume: Volume, newName: string }>()
);
export const OstkVolumesVoluRenameFailed = createAction(
    '[OSTK-VOLU] Ostk Volumes Rename Failed',
    props<{ error?: any }>()
);
export const OstkVolumesVoluChanged = createAction(
    '[OSTK-VOLU] Ostk Volumes Volu Changed',
    props<{ volume: Update<Volume> }>()
);
export const OstkVolumesVoluQsWordSet = createAction(
    '[OSTK-VOLU] Ostk Volumes Volu QsWord Set',
    props<{ qsWord: string }>()
);
