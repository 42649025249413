import { Injectable, Predicate } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, concatAll, toArray, filter, tap } from 'rxjs/operators';

import * as fromImagesImgeActions from '@apps/ostk/store/images/imge/imge.actions';
import * as fromImagesSnapActions from '@apps/ostk/store/images/snap/snap.actions';
import { ImageListDomainService } from '../../../domain/image/image-list-domain.service';
import { Image } from '../../../domain/models/image/image';
import { ImageMiscDomainService } from '../../../domain/image/image-misc-domain.service';
import { IAuroraState } from '@apps/aurora.state';
import { NotifType } from '@common/enum';
import { InMemoryFilter } from '@common/filters/in-memory-filter';
import { multiFilter } from '@common/operators/multi-filter';
import { NotificationService, NotfGravity } from '@common/services';
import {
    getAllImages,
    getImageFieldsFilter,
    getImagesIsDataValid
} from '@apps/ostk/store/ostk-images.reducer';

@Injectable()
export class OstkImagesEffects
{
    constructor
    (
        private actions: Actions,
        private store: Store<IAuroraState>,
        private imageListDomainService: ImageListDomainService,
        private imageMiscDomainService: ImageMiscDomainService,
        private notificationService: NotificationService
    )
    { }

    ostkImagesRequested: Observable<Action> = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromImagesImgeActions.OstkImagesListRequested),
                withLatestFrom(this.store.select(getImagesIsDataValid)),
                filter(([, valid]) => !valid),
                switchMap(() =>
                    this.imageListDomainService.getAll()
                        .pipe(
                            switchMap((images: Image[]) =>
                                [
                                    fromImagesImgeActions.OstkImagesListSucceeded({ images }),
                                    fromImagesImgeActions.OstkImagesApplyFilter({ if4f: null })
                                ]
                            ),
                            catchError((err) =>
                                of(fromImagesImgeActions.OstkImagesListFailed(err))
                            )
                        )
                )
            )
    );

    ostkImageUploadRequested: Observable<Action> = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromImagesImgeActions.OstkImageUploadRequested),
                switchMap(({ image }) =>
                    this.imageMiscDomainService.uploadImage(image)
                        .pipe(
                            map(() =>
                                fromImagesImgeActions.OstkImageUploadSucceeded()
                            ),
                            catchError((err) =>
                                of(fromImagesImgeActions.OstkImageUploadFailed(err))
                            )
                        )
                )
            )
    );

    ostkImageUploadSucceeded = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromImagesImgeActions.OstkImageUploadSucceeded),
                tap(() =>
                {
                    this.notificationService.notify(
                        [
                            'OSTK.IMAGES.UPLOAD.NOTIF.TITLE',
                            'OSTK.IMAGES.UPLOAD.NOTIF.MSGSUCCESS'
                        ],
                        NotfGravity.success,
                        NotifType.SNACKBAR
                    );
                })
            )
    );

    ostkImageUploadFailed = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromImagesImgeActions.OstkImageUploadFailed),
                tap(() =>
                {
                    this.notificationService.notify(
                        [
                            'OSTK.IMAGES.UPLOAD.NOTIF.TITLE',
                            'OSTK.IMAGES.UPLOAD.NOTIF.MSGFAILED'
                        ],
                        NotfGravity.danger,
                        NotifType.SNACKBAR
                    );
                })
            )
    );

    ostkImagesApplyFilter = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromImagesImgeActions.OstkImagesApplyFilter),
                withLatestFrom(
                    this.store.select(getAllImages),
                    this.store.select(getImageFieldsFilter),
                    (_, images, imageFields4Filter) =>
                        ({
                            images,
                            imageFields4Filter
                        })
                ),
                filter(({ imageFields4Filter }) => !!imageFields4Filter),
                switchMap(({ images, imageFields4Filter }) =>
                {
                    const predicates: Predicate<Image>[] = new InMemoryFilter(imageFields4Filter.fields4Filter)
                        .predicates;

                    return of(images)
                        .pipe(
                            concatAll(),
                            multiFilter(predicates),
                            toArray(),
                            map((filteredImages: Image[]) =>
                                fromImagesImgeActions.OstkImagesFilterApplied({ images: filteredImages })
                            )
                        );
                })
            )
    );

    ostkImagesDeleteRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromImagesImgeActions.OstkImagesDeleteRequested),
                    switchMap(({ image }) =>
                        this.imageMiscDomainService.deleteImage(image)
                            .pipe(
                                map(() =>
                                    image.instanceId ?
                                        fromImagesSnapActions.OstkImagesSnapDeleteSucceeded({ imageId: image.id }) :
                                        fromImagesImgeActions.OstkImagesDeleteSucceeded({ imageId: image.id })
                                ),
                                catchError(error =>
                                    of(fromImagesImgeActions.OstkImagesDeleteFailed({ error }))
                                )
                            )
                    )
                )
    );

    ostkImagesDeleteSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromImagesImgeActions.OstkImagesDeleteSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.IMAGE.DELETE.NOTIF.TITLE',
                                'OSTK.IMAGE.DELETE.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );
}
