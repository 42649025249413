import { DateTime } from 'luxon';

import { LocalizedText } from '@common/models';
import { CmdbAssetApi } from '../api/models';
import { CmdbConstants } from '../cmdb.constants';

export class CmdbAsset
{
    public assetId: string;
    public statusId: number;
    public statusFR: string;
    public statusEN: string;
    public name: string;
    public categoryId: string;
    public categoryFR: string;
    public categoryEN: string;
    public myAntemetAGuid: string;
    public serialNumber: string;
    public model: string;
    public isEverProtect?: boolean;
    public lastUpdate: DateTime;
    public eLastUpdate?: DateTime;
    public score?: number;
    public status: LocalizedText;
    public statusClass: string;
    public category: LocalizedText;
    public everProtectScopeKey: string;

    public static mapperFromApi (source: CmdbAssetApi): CmdbAsset
    {
        const target = new CmdbAsset();

        Object.assign(target, source);

        target.lastUpdate = (source && source.lastUpdate) ? DateTime.fromISO(source.lastUpdate, { zone: 'UTC' }) : null;// DatesService.UtcStringToDateHourStatic(source.lastUpdate);
        target.eLastUpdate = (source && source.eLastUpdate) ? DateTime.fromISO(source.eLastUpdate, { zone: 'UTC' }) : null;//DatesService.UtcStringToDateTimeHourStatic(source.eLastUpdate);
        target.everProtectScopeKey = CmdbConstants.getYesNoKey(source.isEverProtect);
        target.status = new LocalizedText(source.statusEN, source.statusFR);
        target.statusClass = CmdbConstants.getCssActiveStatus(source.statusEN);
        target.category = new LocalizedText(source.categoryEN, source.categoryFR);

        return target;
    }
}
