import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { NumbersService } from './numbers.service';
import { Bytes } from '../enum';
import { AppConstants } from '@apps/app.constants';

@Injectable({
    providedIn: 'root'
})
export class BytesService
{
    // * See https://en.wikipedia.org/wiki/Kilobyte
    // * See also https://fr.wikipedia.org/wiki/Pr%C3%A9fixe_binaire
    private units = [
        'NUMBERS.UNITS.BINARY.BYTE',
        'NUMBERS.UNITS.BINARY.KIBI',
        'NUMBERS.UNITS.BINARY.MEBI',
        'NUMBERS.UNITS.BINARY.GIBI',
        'NUMBERS.UNITS.BINARY.TEBI',
        'NUMBERS.UNITS.BINARY.PEBI'
    ];

    constructor (
        private translateService: TranslateService,
        private numbersService: NumbersService
    )
    {

    }

    toKiloBytes (nb: number, decPart: number = 2): string
    {
        if (decPart > 3)
        {
            decPart = 2;
        }

        const y = this.numbersService.toFixed(nb, decPart);

        const unit = this.translateService.instant('NUMBERS.UNITS.BINARY.KIBI');

        return this.encodeToNbspThinSpace(`${y} ${unit}`);
    }

    static staticConvert (value: number, sourceUnit: Bytes, targetUnit: Bytes): number
    {
        if (targetUnit < sourceUnit)
        {
            throw RangeError;
        }

        return value / (Math.pow(AppConstants.MilleVingtQuatre, (targetUnit - sourceUnit)));
    }

    convert (value: number, sourceUnit: Bytes, targetUnit: Bytes): number
    {
        return BytesService.staticConvert(value, sourceUnit, targetUnit);
    }

    convertAndFormat (value: number, sourceUnit: Bytes, targetUnit: Bytes, decPart: number = 2): string
    {
        if (decPart > 3)
        {
            decPart = 2;
        }

        return this.formatWithUnit(this.convert(value, sourceUnit, targetUnit), targetUnit, decPart);
    }

    formatWithUnit (value: number, unit: number, decPart: number = 2): string
    {
        const f = this.numbersService.toFixed(value, decPart);
        const u = this.translateService.instant(this.units[unit]);

        return this.encodeToNbspThinSpace(`${f} ${u}`);
    }

    /**
     *
     * @param value Volume en bytes
     * @param decPart Nombre de chiffres après la virgule
     * @returns La chaîne formattée avec la meilleure unité possible
     */
    formatToBestUnit (value: number, sourceUnit: Bytes = Bytes.BYTE, decPart: number = 2): string
    {
        let tmp: number;
        let tmp1: number = value;
        let iter: number = 0;

        if (decPart > 3)
        {
            decPart = 2;
        }

        do
        {
            tmp = tmp1;
            iter++;
            tmp1 = tmp1 / AppConstants.MilleVingtQuatre;
        } while (tmp1 >= 1);

        iter--;

        const fixed = this.numbersService.toFixed(tmp, decPart);
        const unit = this.translateService.instant(this.units[iter + sourceUnit]);

        return this.encodeToNbspThinSpace(`${fixed} ${unit}`);
    }

    /**
     * Retourne le rang de la meilleure unité afin que la conversion donne un nombre dans l'intervalle [0, 10000[
     * @param {number} value: nombre dont on  veut la meilleure unité, valeur en bytes par défaut
     * @returns {number}
     */
    calcBestUnit (value: number): number
    {
        let tmp: number = value;
        let iter: number = 0;

        do
        {
            iter++;
            tmp = tmp / AppConstants.MilleVingtQuatre;
        } while (tmp >= 1);

        return iter - 1;
    }

    /**
     *
     * @param {number} min : Le nombre minimum de la série
     * @param {number} max : Le nombre maximum de la série
     */
    toIntelligentUnits (min: number, max: number): { min: number, max: number, unit: string, power: number, indexUnit: number }
    {
        let maxUnit = this.calcBestUnit(max);
        let p = Math.pow(AppConstants.MilleVingtQuatre, maxUnit);

        if (min / p < 1 && max / p > 1000)
        {
            maxUnit -= 1;
        }

        maxUnit = Math.max(0, maxUnit);
        p = Math.pow(AppConstants.MilleVingtQuatre, maxUnit);
        return {
            min: min / p,
            max: max / p,
            unit: this.translateService.instant(this.units[maxUnit]),
            power: p,
            indexUnit: maxUnit
        };
    }

    // toIntelligentUnits (min: number, max: number): { min: number, max: number, unit: string, power: number }
    // {
    //     let currMin: number;
    //     let currMax: number;
    //     let iter = 0;

    //     let currMin1 = min;
    //     let currMax1 = max;

    //     do
    //     {
    //         currMin = currMin1;
    //         currMax = currMax1;

    //         iter++;
    //         currMin1 = currMin / AppConstants.MilleVingtQuatre;
    //         currMax1 = currMax / AppConstants.MilleVingtQuatre;
    //     } while (currMin1 > 1 && currMax1 > 1);

    //     iter--;

    //     // console.log({
    //     //     min: currMin,
    //     //     max: currMax,
    //     //     unit: BytesService.units[iter],
    //     //     power: Math.pow(BytesService.cst1024, iter)
    //     // });

    //     return {
    //         min: currMin,
    //         max: currMax,
    //         unit: this.translateService.instant(this.units[iter]),
    //         power: Math.pow(AppConstants.MilleVingtQuatre, iter)
    //     };
    // }

    /**
     * ECMAScript renvoie le caractère '#&8239;' à la place de ce que l'on prend pour un espace
     * C'est une espace fine insécable, (En typographie le mot 'espace' est féminin)
     */
    private encodeToNbspThinSpace (str: string)
    {
        return str.replace(/ /g, String.fromCharCode(8239));
    }
}

