import { DateTime } from 'luxon';

export class PolicyTypeStatusRate
{
    public name: string;
    public successRate: number;
}

export class StatusPerPolicyType
{
    public since: DateTime;
    public until: DateTime;
    public validUntil?: DateTime;
    public policyTypeStatusRates: PolicyTypeStatusRate[];

    constructor ()
    {
        this.validUntil = null;
    }
}
