import { DateTime } from 'luxon';

export enum NotfGravity
{
    normal = 0,
    success = 1,
    warning1 = 2,
    warning2 = 3,
    danger = 4
}
export class Message
{
    private static counter: number = 0;
    private static icons: string[] = [
        'fal fa-info',
        'fal fa-thumbs-up',
        'fal fa-exclamation-triangle',
        'fal fa-exclamation-triangle',
        'fal fa-thumbs-down'
    ];
    private static iconClasses: string[] = [
        'info',
        'success',
        'warning1',
        'warning2',
        'danger'
    ];

    id: string;
    title: string;
    createdAt: DateTime;
    message: string;
    gravity: NotfGravity;
    icon: string;
    isRead: boolean = false;
    iconClass: string = '';

    constructor(title: string, message: string, gravity: NotfGravity)
    {
        this.title = title;
        this.message = message;
        this.gravity = gravity;

        this.id = (Message.counter++).toString();
        this.icon = Message.icons[this.gravity];
        this.iconClass = Message.iconClasses[this.gravity];
    }

    public static GetGravityClass (gravity: NotfGravity): string
    {
        return Message.iconClasses[gravity];
    }
}
