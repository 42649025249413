import { createReducer, on } from '@ngrx/store';

import { StatusesMetadata } from '@common/models';
import * as fromBreakdownActions from './breakdown.actions';
import { RequestsCatalogCounter } from '@apps/its2/domain/models';
import * as fromRootImpersonateActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';

import { IIts2DashBreakdownState, Its2DashBreakdownInitialState } from './breakdown.state';

export const Its2DashboardBreakdownReducer = createReducer(
    Its2DashBreakdownInitialState,
    on(
        fromRootImpersonateActions.RootImpersonateSet,
        fromRootImpersonateActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => Its2DashBreakdownInitialState
    ),
    on(
        fromBreakdownActions.Its2DashBreakdownIncidentsCountersRequested,
        (state: IIts2DashBreakdownState) =>
            ({
                ...state,
                incidents: {
                    collection: [],
                    ...StatusesMetadata.requested()
                }
            })
    ),
    on(
        fromBreakdownActions.Its2DashBreakdownIncidentsCountersSucceeded,
        (state: IIts2DashBreakdownState, { counters }: {counters: RequestsCatalogCounter[]}) => ({
            ...state,
            incidents: {
                collection: counters,
                ...StatusesMetadata.succeeded(counters?.length !== 0, counters?.length === 0)
            }
        })
    ),
    on(
        fromBreakdownActions.Its2DashBreakdownIncidentsCountersFailed,
        (state: IIts2DashBreakdownState) =>
            ({
                ...state,
                incidents: {
                    collection: [],
                    ...StatusesMetadata.failed()
                }
            })
    ),
    on(
        fromBreakdownActions.Its2DashBreakdownServicesCountersRequested,
        (state: IIts2DashBreakdownState) =>
            ({
                ...state,
                services: {
                    collection: [],
                    ...StatusesMetadata.requested()
                }
            })
    ),
    on(
        fromBreakdownActions.Its2DashBreakdownServicesCountersSucceeded,
        (state: IIts2DashBreakdownState, { counters }: {counters: RequestsCatalogCounter[]}) =>
            ({
                ...state,
                services: {
                    collection: counters,
                    ...StatusesMetadata.succeeded(counters?.length !== 0, counters?.length === 0)
                }
            })
    ),
    on(
        fromBreakdownActions.Its2DashBreakdownServicesCountersFailed,
        (state: IIts2DashBreakdownState) =>
            ({
                ...state,
                services: {
                    collection: [],
                    ...StatusesMetadata.failed()
                }
            })
    ),
);