import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DateTime } from 'luxon';

import { ActivityByServer } from '@apps/bckp/domain/models';

import { IJobsState, jobsInitialState } from '@apps/bckp/store/jobs/jobs.reducer';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { ServersFilter } from '@apps/bckp/models';
import { StatusesMetadata } from '@common/models';

import { ODataBckpServersHeat } from './odata-bckp-servers-heat';

export const BckpServersHeatFeatureKey = 'heat';

export type IServersHeatState = EntityState<ActivityByServer>;

export const serversHeatAdapter: EntityAdapter<ActivityByServer> = createEntityAdapter<ActivityByServer>({
    selectId: (activityByServer: ActivityByServer): string =>
        activityByServer.activityId
});

const serversInitialState: IServersHeatState = serversHeatAdapter.getInitialState({ });
export interface IBckpServersHeatState extends StatusesMetadata
{
    servers: IServersHeatState;
    headerDates: DateTime[],
    oData: ODataBckpServersHeat;
    qsWord: string;
    issuesOnly: boolean;
    jobsQs: string;
    jobs: IJobsState;
    nbJobs: number
}

export const bckpServersHeatInitialState: IBckpServersHeatState = {
    ...StatusesMetadata.init(),
    servers: serversInitialState,
    headerDates: [],
    oData: new ODataBckpServersHeat(BckpConstants.QSServersTop, new ServersFilter()),
    qsWord: '',
    issuesOnly: false,
    jobsQs: '',
    jobs: jobsInitialState,
    nbJobs: 0
};
