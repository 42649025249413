import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { DateTime } from 'luxon';

import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import * as fromRootStore from '@apps/root/store';
import { Server, Image } from '@apps/bckp/domain/models';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { ServersFilter } from '@apps/bckp/models';

import { ODataBckpServersServ } from './odata-bckp-servers-serv';
import * as fromServersServActions from './serv.actions';

export const BckpServersServFeatureKey = 'serv';

type IServersServState = EntityState<Server>;

export const serversServAdapter: EntityAdapter<Server> = createEntityAdapter<Server>({
    selectId: (server: Server) => server.documentId
});

const serversInitialState: IServersServState = serversServAdapter.getInitialState({
});
type ISelectedImagesState = EntityState<Image>;
export const selectedImagesAdapter: EntityAdapter<Image> = createEntityAdapter({
    selectId: (image: Image) => image.documentId
});
const selectedImagesInitialState: ISelectedImagesState = selectedImagesAdapter.getInitialState({});

export interface IBckpServersServState
{
    isLoading: boolean;
    isEmpty: boolean;
    isOups: boolean;
    selectedServer?: string;
    collection: IServersServState;
    oData: ODataBckpServersServ;
    expirationDate: DateTime;
    qsWord: string;
    isLoaded: boolean;
    selectedImages: ISelectedImagesState
}

const initialState: IBckpServersServState = {
    isLoading: true,
    isLoaded: false,
    isEmpty: false,
    isOups: false,
    selectedServer: null,
    collection: serversInitialState,
    oData: new ODataBckpServersServ(BckpConstants.QSServersTop, new ServersFilter()),
    expirationDate: null,
    qsWord: '',
    selectedImages: selectedImagesInitialState,
};

export const BckpServersServReducer = createReducer(
    initialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => initialState
    ),
    on(
        fromServersServActions.BckpServersServODataInitialized,
        (state, { scrollSize, filter }) => ({
            ...state,
            oData: new ODataBckpServersServ(scrollSize, filter)
        })
    ),
    on(
        fromServersServActions.BckpServersServODataLoaded,
        (state, { servers }) =>
            ({
                ...state,
                isLoading: false,
                isLoaded: servers.length !== 0,
                isEmpty: servers.length === 0,
                collection: serversServAdapter.addMany(servers, state.collection),
                expirationDate: DateTime.utc().plus({ minutes: BckpConstants.DataLifeTime })
            })
    ),
    on(
        fromServersServActions.BckpServersServODataFailed,
        (state) => ({
            ...state,
            isLoading: false,
            isLoaded: false,
            isOups: true,
            collection: serversServAdapter.removeAll(state.collection)
        })
    ),
    on(
        fromServersServActions.BckpServersServODataIncremented,
        (state) =>
        {
            const o = state.oData;

            o.incr();

            return {
                ...state,
                oData: o
            };
        }
    ),
    on(
        fromServersServActions.BckpServersServFilterApplied,
        (_, { filter }: { filter: ServersFilter }) => ({
            ...initialState,
            oData: new ODataBckpServersServ(BckpConstants.QSServersTop, filter)
        })
    ),
    /*on(
        fromServersServActions.BckpServersFilterReset,
        (state) =>
        {
            serversAdapter.removeAll(state.collection);

            return initialState;
        }
    ),*/
    on(
        fromServersServActions.BckpServersServCleared,
        (state) => ({
            ...state,
            collection: serversServAdapter.removeAll(state.collection)
        })
    ),
    on(
        fromServersServActions.BckpServersServQsWordSet,
        (state, { qsWord }) => ({
            ...state,
            qsWord: qsWord.toLowerCase()
        })
    ),
    on(
        fromServersServActions.BckpServersServSelectedImagesSucceeded,
        (state, { images }) => ({
            ...state,
            selectedImages: selectedImagesAdapter.setAll(images, state.selectedImages)
        })
    ),
    on(
        fromServersServActions.BckpServersServSelectedImagesFailed,
        (state) => ({
            ...state,
            selectedImages: selectedImagesAdapter.removeAll(state.selectedImages)
        })
    ),
    on(
        fromServersServActions.BckpServersServTagAttachSucceeded,
        fromServersServActions.BckpServersServTagDetachSucceeded,
        (state, { updatedServer }: { updatedServer: Update<Server>; }) =>
        {
            return {
                ...state,
                collection: serversServAdapter.updateOne(updatedServer, state.collection)
            };
        }
    )
);
