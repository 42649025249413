import { Pipe, PipeTransform } from '@angular/core';

import { NumbersService } from '@common/services';

@Pipe({
    name: 'formatPriceHT'
})
export class FormatPriceHT implements PipeTransform
{
    constructor (private numbersService: NumbersService)
    { }

    transform (value: number, decPart: number = 2): string
    {
        const price = this.numbersService.toCurrency(value ?? 0, decPart);

        return `${price}`;
    }
}
