import { OnInit, Component, Input, Host, AfterContentInit } from '@angular/core';

import { CheckboxGroup2Component } from '../checkbox-group2/checkbox-group2.component';
import { SelectOption2 } from '../../../common/select-option2';

@Component({
  selector: 'aa-checkbox2',
  templateUrl: './checkbox2.component.html'
})
export class Checkbox2Component  implements OnInit, AfterContentInit
{
    @Input() option: SelectOption2;
    @Input() toTranslate: boolean = false;
    public isChecked: boolean = false;
    public optionId: string;

    constructor(@Host() private checkboxGroup: CheckboxGroup2Component)
    {
    }

    ngOnInit ()
    {
        this.optionId = `id-${this.option.label}-${this.option.value}`;
    }

    ngAfterContentInit (): void
    {
        this.isChecked = this.checkboxGroup.contains(this.option.value);
    }

    toggleCheck ()
    {
        this.checkboxGroup.addOrRemove(this.option.value);
        this.isChecked = this.checkboxGroup.contains(this.option.value);
    }
}
