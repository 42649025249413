import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ChipsComponent } from './chips.component';
import { ChipsTagItemComponent } from './chips-tag-item/chips-tag-item.component';
import { ChipsTagsContainerComponent } from './chips-tags-container/chips-tags-container.component';
import { ChipsTagInputComponent } from './chips-tag-input/chips-tag-input.component';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { ChipsTagItemValueComponent } from './chips-tag-item-value/chips-tag-item-value.component';

@NgModule({
    declarations: [
        ChipsComponent,
        ChipsTagsContainerComponent,
        ChipsTagItemComponent,
        ChipsTagInputComponent,
        ChipsTagItemValueComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteModule,
        PipesModule,
        TranslateModule
    ],
    exports: [
        ChipsComponent,
        ChipsTagsContainerComponent,
        ChipsTagItemComponent,
        ChipsTagInputComponent,
        AutocompleteModule
    ]
})
export class ChipsModule
{
}
