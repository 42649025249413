import { Pipe, PipeTransform } from '@angular/core';

import { DateTime, DateTimeFormatOptions } from 'luxon';

import { FormatDateHourPipe } from './format-date.pipe';

@Pipe({
    name: 'formatGenericDate'
})
export class FormatGenericDatePipe implements PipeTransform
{
    constructor (
        private formatDateHourPipe: FormatDateHourPipe
    )
    { }

    transform (valueToConvert: any, format?: DateTimeFormatOptions): any
    {

        if (!valueToConvert)
        {
            return '';
        }

        const date: DateTime = DateTime.fromISO(valueToConvert);

        return this.formatDateHourPipe.transform(date);
    }
}
