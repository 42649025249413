import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { UtilsService } from '@common/services';
import { CustomErrorKey } from '@ui/components/form9/common/error-msg.service';

export function inputCheckbox9RequiredValidator (errorMsg: string = null): ValidatorFn
{
    return (formControl: FormControl): ValidationErrors | null =>
    {
        if (!UtilsService.isNonEmptyArray(formControl.value))
        {
            const validationErrors: ValidationErrors = { unchecked: true };

            if (!UtilsService.isNullOrEmpty(errorMsg))
            {
                validationErrors[CustomErrorKey] = errorMsg;
            }

            return validationErrors;
        }

        return null;
    };
}
