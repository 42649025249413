import { Tag } from '@apps/cmdb/models';

export class Assetify
{
    assetName: () => string;
    tagIds: string[];
    tagFullNames: string;

    public static BuildFullTagNames (tagIds: string[], tags: Tag[]): string
    {
        return tags
            .filter((tag: Tag): boolean =>
                tagIds.includes(tag.tagId)
            )
            .map((tag: Tag): string =>
                tag.fullTagName
            )
            .join('|');
    }
}
