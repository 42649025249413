import { Job } from '@apps/bckp/domain/models';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { TypePopin } from '@apps/app.constants';
import { OpenPopinOriginator } from '@common/enum/open-popin-originator.enum';

import { SearchResult } from './search-result';

export class BckpJobSearchResult extends SearchResult<Job>
{
    constructor (job: Job)
    {
        super('bckp-job', job.documentId);

        this.result = job;
        this.icon = 'fal fa-save';

        this.statusText = job.metaStatus;
        this.statusClass = BckpConstants.getCssStatus[job.status];

        // jobs/(details:srvabcbckmst01:srvabcbckmst01:1587664800:61:ABC_Z01_VM_BACKUP_FIL:2539574:2539574)
        this.link = {
            baseUrlFragment: BckpConstants.JobsListPath,
            outlet: TypePopin.DETAILS,
            params: [OpenPopinOriginator.ROOT_SEARCH, this.id]
        };
    }
}
