import { DateTime } from 'luxon';

import { DatesService } from '@common/services';

import { ProjectApi } from '../../../api/models/billing';

export class Project
{
    id: string;
    name: string;
    client: number;
    rebate: number;
    purchaseOrder: string;
    date: DateTime;

    public static mapperFromApi (source: ProjectApi): Project
    {
        const target = new Project();

        Object.assign(target, source);
        target.date = (source && source.date) ? DatesService.UtcStringToDateTimeHourStatic(source.date + 'Z') : null;

        return target;
    }
}
