import { ReferenceApi } from '../../../api/models/billing';

export class Reference
{
    article: string;
    commandLineNumber: number;
    price: number;
    referenceId: string;

    public static mapperFromApi (source: ReferenceApi): Reference
    {
        const target = new Reference();

        Object.assign(target, source);

        return target;
    }
}
