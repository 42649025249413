import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Image } from '../models';
import { ImageListApiService } from '../../api';
import { ImageApi } from '../../api/models';
import { IAuroraState } from '../../../aurora.state';
import * as fromOstkImagesActions from '@apps/ostk/store/images/imge/imge.actions';
import { SelectOption2 } from '@ui/components/form/common/select-option2';
import { SelectOption } from '@ui/components/form/common';
import { UtilsService } from '@common/services';
import { icon } from 'leaflet';

@Injectable({
    providedIn: 'root'
})
export class ImageListDomainService
{

    constructor (
        private store: Store<IAuroraState>,
        private imageListApiService: ImageListApiService
    )
    { }

    getAll (): Observable<Image[]>
    {
        return this.imageListApiService.getImages()
            .pipe(
                map((result: ImageApi[]) =>
                    result.map<Image>((imageApi: ImageApi) =>
                        Image.mapperFromApi(imageApi)
                    )
                ),
                // tap((images: Image[]) =>
                // {
                //     const xyz = images.map((image: Image) =>
                //     {
                //         const p: any = image.customProperties;

                //         return { name: image.name, osIcon: p['os-icon'], osDistro: p['os_distro'], distro: p.distro };
                //     });
                //     console.table(xyz);
                // }),
                tap((images: Image[]) =>
                {
                    const operatingSystems = [...new Set(images.map(i => i.osLabel))]
                        .map((label: string) =>
                            new SelectOption2(
                                label,
                                label,
                                false,
                                UtilsService.getFullOsPicture(label)
                            ));
                    const statuses = [...new Set(images.map(i => i.status))]
                        .map(s =>
                            new SelectOption(s, s)
                        );

                    this.store.dispatch(fromOstkImagesActions.OstkImagesOperatingSystemsLoaded({ operatingSystems }));
                    this.store.dispatch(fromOstkImagesActions.OstkImagesStatusesLoaded({ statuses }));
                })
            );
    }
}
