import { Component, Injector, forwardRef, ViewChild, ElementRef, AfterViewInit, OnDestroy, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';

import { tap, takeUntil } from 'rxjs/operators';

import { InputBase } from '../../../base/input-base';

@Component({
    selector: 'aa-input-digit-code',
    templateUrl: './input-digit-code.component.html',
    styleUrls: [
        '../input.component.scss',
        './input-digit-code.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputDigitCodeComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputDigitCodeComponent),
            multi: true,
        }
    ]
})
export class InputDigitCodeComponent extends InputBase<string> implements OnInit, AfterViewInit, OnDestroy
{
    private static identifier = 0;
    @Input() nbDigits: number = 6;
    @ViewChild('digicode', { static: true }) digiCode: ElementRef;
    public digitsField: string[];
    private digicodeRegexp: RegExp;
    public static ErrorMessageKey: string = 'digicode';
    public formGroup: UntypedFormGroup;
    private destroyator = new Subject();

    constructor (
        injector: Injector
    )
    {
        super(injector);

        this.fieldId = `input-digit-code-${InputDigitCodeComponent.identifier++}-id`;
    }

    ngOnInit (): void
    {
        this.digicodeRegexp = new RegExp(`[\\d]{${this.nbDigits}}`);
        this.digitsField = Array.from(Array(this.nbDigits))
            .map((_: any, idx: number): string =>
                `digit${idx}`);
        this.initFormGroup();
    }

    ngAfterViewInit (): void
    {
        this.focus(this.digitsField[0]);
    }

    ngOnDestroy (): void
    {
        this.destroyator.next(null);
        this.destroyator.complete();
    }

    public onPaste (event: ClipboardEvent): void
    {
        const data = event.clipboardData.getData('text/plain');

        if (!this.digicodeRegexp.test(data))
        {
            return;
        }

        const splittedData = data.split('');
        this.digitsField.forEach((field: string, idx: number): void =>
            this.formGroup.get(field).setValue(splittedData[idx])
        );
    }

    reset (): void
    {
        this.formGroup.reset();
        this.focus(this.digitsField[0]);
    }

    validate ({ value }: UntypedFormControl)
    {
        return this.digicodeRegexp.test(value) ? null : { [InputDigitCodeComponent.ErrorMessageKey]: true };
    }

    private initFormGroup (): void
    {
        this.formGroup = new UntypedFormGroup({});
        this.digitsField.forEach((name: string): void => this.formGroup.addControl(name, new UntypedFormControl('', [Validators.required])));

        this.digitsField.forEach((field: string, idx: number): Subscription =>
            this.formGroup.get(field).valueChanges
                .pipe(
                    tap((): void =>
                    {
                        const value = this.digitsField
                            .map((name: string): string =>
                                this.formGroup.get(name).value
                            ).join('');

                        if (value.length == this.digitsField.length)
                        {
                            this.value = value;
                        }

                        if (idx + 1 < this.digitsField.length)
                        {
                            this.focus(this.digitsField[idx + 1]);
                        }
                    }),
                    takeUntil(this.destroyator)
                )
                .subscribe()
        );
    }

    private focus (formControlName: string): void
    {
        this.digiCode.nativeElement.querySelector(`input[name=${formControlName}]`).focus();
    }
}
