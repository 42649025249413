import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { SnackbarRef, SnackbarService } from '@ui/misc/snackbar';
import { NotfGravity } from '@apps/notf/models/message';
import { Message } from '@apps/notf/models/message';
import { IAuroraState } from '@apps/aurora.state';
import * as fromNotfStore from '@apps/notf/store';

import { NotifType } from '../enum/notif-type.enum';

@Injectable({
    providedIn: 'root'
})
export class NotificationService
{
    private snackbarRef: SnackbarRef;

    constructor (
        private translateService: TranslateService,
        private snackbarService: SnackbarService,
        private store: Store<IAuroraState>
    )
    {
    }

    notify (tradKeys: string[], gravity: NotfGravity, type: NotifType, data: any = null)
    {
        const translations = this.translateService.instant(tradKeys, data);
        const title = translations[tradKeys[0]];
        const message = translations[tradKeys[1]];

        if (type === NotifType.BOTH || type === NotifType.SNACKBAR)
        {
            this.launchSnackbar(message, gravity);
        }


        if (type === NotifType.BOTH || type === NotifType.POPIN)
        {
            this.addWarning(title, message, gravity);
        }
    }

    private launchSnackbar (message: string, gravity?: NotfGravity)
    {
        if (this.snackbarRef)
        {
            this.snackbarRef.overlayRef.dispose();
        }

        this.snackbarRef = this.snackbarService.create(message, gravity);
    }

    private addWarning (title: string, text: string, gravity: NotfGravity)
    {
        const message = new Message(
            title,
            text,
            gravity
        );

        this.store.dispatch(fromNotfStore.NotfWarnStoreActions.AddMessage({ message }));
    }
}
