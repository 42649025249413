import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function matchingInputsValidator (firstKey: string, secondKey: string, errorName: string)
{
    return function (group: UntypedFormGroup): ValidationErrors | null
    {
        return group.controls[firstKey].value !== group.controls[secondKey].value
            ? { [errorName]: true }
            : null;
    };
}
