import { Configuration, TrackingDimensions } from '@common/models/configuration';
import * as AppGlobals from '@apps/globals';
import { SelectOption } from '@ui/components/form/common';
import { DashConstants } from '@apps/dash/dash.constants';

import { BackConstants } from './back/back.constants';

export type TypePopup = 'popmenu' | 'popover' | 'poptab';
export type UnitChart = undefined | 'counter' | 'duration' | 'volume' | 'percentage';
export type ValidationError = { [key: string]: boolean; } | null;

export enum ArrowDirection
{
    CLOSED = 'closed',
    OPEN = 'open'
}

export enum Orientation
{
    HORIZ = 'horizontal',
    VERTI = 'vertical'
}
export const enum TypePopin
{
    FILTER = 'filter',
    DETAILS = 'details',
    ACTION = 'actions',
    REPLY = 'reply',
    VALIDATE = 'validate',
    USER = 'user'
}

export const enum CompareDirection
{
    ASC = 1,
    NONE = 0,
    DESC = -1
}

export enum TopoNodeType
{
    RootNode = 'root-node',
    CompVcen = 'comp-vcen',
    CompDace = 'comp-dace',
    CompClus = 'comp-clus',
    CompHost = 'comp-host',
    CompInst = 'comp-inst',
    BckpPoli = 'bckp-poli'
}

export enum TopoMenuBelt
{
    Details = 'details',
    Links = 'links',
    ExpandAll = 'expandall',
    CollapseAll = 'collapseall',
    Expand = 'expand',
    Collapse = 'collapse'
}

export enum TopoSimulationType
{
    Static = 0,
    Animated = 1
}

export class AppConstants
{
    // * Constantes de présentation
    public static GoldenRatio = (Math.sqrt(5) + 1) / 2;

    // *  Config de développement
    private static DevConfig: any = {
        LocalData: {
            back: false,
            auth: false,
            itsm: false,
            its2: false,
            objs: false,
            bckp: false,
            rept: false,
            ostk: false,
            prtg: false,
            cmdb: false,
            dafa: false,
            pata: false,
            secu: false
        }
    };

    // * Paramètres généraux - ne pas modifier
    public static ApplicationName: string = 'MyAntemetA';
    public static ApplicationVersion: string = '2.31.1';
    public static ApplicationCommitId: string = '';
    public static ApplicationConfig = 'config';
    public static Configuration: Configuration = AppGlobals.config;
    public static PersonalDataToken: string = 'aurora';
    public static MilleVingtQuatre: number = 1024;
    public static DayInSeconds: number = 24 * 60 * 60;
    public static FormDebounceTime: number = 250;
    public static QSSearchTop: number = 7;
    public static NullGuid: string = '00000000-0000-0000-0000-000000000000';
    public static PollingStatusInterval = 1000;

    // * Theming
    public static ThemeLight: string = 'light';
    public static ThemeDark: string = 'dark';
    public static ThemePrune: string = 'prune';

    // public static ThemeMarked: string = 'marked';
    public static themes4Dev = [
        AppConstants.ThemeLight,
        AppConstants.ThemePrune,
        AppConstants.ThemeDark
        // AppConstants.ThemeMarked
    ];
    public static themes = [
        AppConstants.ThemeLight,
        AppConstants.ThemePrune,
        // AppConstants.ThemeDark
        // AppConstants.ThemeMarked
    ];
    public static frontThemes = AppConstants.themes.map((t) =>
    {
        const x = t.substring(0, 1).toUpperCase() + t.substring(1).toLowerCase();

        return new SelectOption(`theme-${t}`, x);
    });

    // * Languages
    public static Languages: string[] = ['en-US', 'fr-FR'];

    // Matomo tracking
    public static trackingDimensions: TrackingDimensions = {
        name: 1,
        email: 2,
        entityId: 3,
        entityName: 4
    };
    // RocketChat
    public static rocketChatCustomFields = {
        entityId: 'EntityId',
        entityName: 'EntityName'
    };

    public static UrlApi: string = 'api';
    public static UrlAssets: string = 'assets';
    public static UrlAuth: string = 'auth';
    public static LoginUrl: string = '/auth/login';
    public static DefaultCustomerUrl = DashConstants.DefaultUrl;
    public static DefaultAdminUrl = BackConstants.DefaultAdminUrl;
    public static DefaultRedirectUrl: string = AppConstants.DefaultCustomerUrl;
    public static ParamPlaceholderUrl: string = '_';
    public static FullParamPlaceholderUrl: string = '/_/';
    public static DataLifeTime = 7;

    // Font des icônes
    public static FontAwesomeFamily: string = 'Font Awesome 5 Pro';
    public static configEditor = {
        toolbar: [
            'heading'
            , '|'
            , 'fontsize'
            , 'fontfamily'
            , '|'
            , 'bold'
            , 'italic'
            , 'underline'
            , 'strikethrough'
            , 'highlight'
            , '|'
            , 'alignment'
            // , '|'
            // , 'numberedList'
            // , 'bulletedList'
            // , '|'
            // , 'indent'
            // , 'outdent'
            , '|'
            , 'link'
            , 'blockquote'
            // , 'imageUpload'
            , 'insertTable'
            // , 'mediaEmbed'
            , '|'
            , 'undo'
            , 'redo'
        ]
    };
    public static QuickSearchClass: string = 'highlight-search';
    // Topology
    public static MinZoomLevel = 0.25;
    public static MaxZoomLevel = 7;
    public static InitZoomLevel = 1;
    public static ZoomStep = 0.5;

    public static OpenPopinOriginator: string = 'openPopinOriginator';

    // * Environnement d'exécution
    public static get Stage (): string
    {
        return AppConstants.Configuration.stage.toLowerCase();
    }

    public static get IsDebugStage (): boolean
    {
        return AppConstants.Stage === 'debug';
    }

    public static get IsLocalStage (): boolean
    {
        return AppConstants.Stage === 'local';
    }

    public static get IsStagingStage (): boolean
    {
        return AppConstants.Stage === 'staging';
    }

    public static get IsProductionStage (): boolean
    {
        return AppConstants.Stage === 'production';
    }

    // * Accès à des données locales en DEV si besoin (absence de repository)
    // * Passer les valeurs correspondantes à true dans DevConfig
    public static IsDataLocal (appli: string): boolean
    {
        return AppConstants.IsDebugStage
            && AppConstants.DevConfig.LocalData[appli] !== null
            && AppConstants.DevConfig.LocalData[appli];
    }
}
