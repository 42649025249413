import { Observable, GroupedObservable } from 'rxjs';
import { concatAll, groupBy, mergeMap, toArray, map } from 'rxjs/operators';

import { SummaryCounter } from '@common/models/summary-counter';

/**
 *
 * @param fct
 * @returns
 */
export const groupedCounter = <T, U>(fct: (x: T) => U) =>
    (source: Observable<T[]>): Observable<SummaryCounter<U>[]> =>
        source
            .pipe(
                concatAll(),
                groupBy((item: T) =>
                    fct(item)
                ),
                mergeMap((group: GroupedObservable<U, T>) =>
                    group
                        .pipe(
                            toArray(),
                            map((items: T[]) =>
                                new SummaryCounter<U>(group.key, items.length)
                            )
                        )
                ),
                toArray()
            );
