import { Injectable } from '@angular/core';

import { combineLatest, iif, Observable, of } from 'rxjs';
import { concatAll, filter, map, switchMap, toArray, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { InstanceMiscApiService } from '@apps/ostk/api';
import { InstanceApi } from '@apps/ostk/api/models';
import { BytesService, UtilsService } from '@common/services';
import { IAuroraState } from '@apps/aurora.state';
import * as fromOstkActionsStore from '@apps/ostk/store/instances/instances.actions';
import { OstkConstants } from '@apps/ostk/ostk.constants';
import { SelectOption2 } from '@ui/components/form/common/select-option2';
import { SelectOption } from '@ui/components/form/common';
import { Bytes } from '@common/enum';
import { Addresses, CreateInstanceViewModel, ManageSecurityGroupsViewModel, } from '@apps/ostk/models/instance';
import
{
    getImagesLoadedStatus,
    getImageSnapshotsById
} from '@apps/ostk/store/ostk-images.reducer';
import
{
    getAllFlavors,
    getFlavorsLoadedStatus,
} from '@apps/ostk/store/ostk-flavor.reducer';
import
{
    getAllVolumesVolu,
    getVolumesVoluLoadedStatus
} from '@apps/ostk/store/ostk-volumes.reducer';

import { Flavor, ImageSnapshot, Instance, DomainInstance, InstanceTimelineGroup, Volume, Image } from '../models';
import { HasMajorProps } from '../models/common';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class InstanceMiscDomainService
{
    constructor (
        private instanceMiscApiService: InstanceMiscApiService,
        private store: Store<IAuroraState>,
        private bytesService: BytesService
    )
    {
    }

    getAllInstances (): Observable<Instance[]>
    {
        return combineLatest([
            this.store.select(getVolumesVoluLoadedStatus),
            this.store.select(getFlavorsLoadedStatus)
        ])
            .pipe(
                filter(([l1, l2]: [boolean, boolean]) =>
                    l1 && l2
                ),
                switchMap(() =>
                    this.instanceMiscApiService.getAllInstances()
                        .pipe(
                            withLatestFrom(
                                this.store.select(getAllVolumesVolu),
                                this.store.select(getAllFlavors),
                                (o1, o2, o3) => ({
                                    instancesApi: o1,
                                    volumes: o2,
                                    flavors: o3
                                })
                            ),
                            map((result: { instancesApi: InstanceApi[], volumes: Volume[], flavors: Flavor[]; }) =>
                            {
                                const mapVolu: Map<string, Volume> = this.buildMap<Volume>(result.volumes);
                                const mapFlav: Map<string, Flavor> = this.buildMap<Flavor>(result.flavors);
                                const os: Set<string> = new Set<string>();
                                const vcpus: Set<number> = new Set<number>();
                                const ram: Set<number> = new Set<number>();

                                const instances: Instance[] = result.instancesApi.map<Instance>((instanceApi: InstanceApi) =>
                                {
                                    const volumes = instanceApi.attachedVolumes
                                        .map((volumeId: string) =>
                                            mapVolu.get(volumeId)
                                        );

                                    const instance: Instance = DomainInstance.mapperFromApi(instanceApi, volumes, mapFlav);

                                    os.add(instance.osLabel);
                                    vcpus.add(instance.vcpu);
                                    ram.add(instance.vram);

                                    return instance;
                                });

                                this.store.dispatch(
                                    fromOstkActionsStore.OstkInstancesOperatingSystemValues({
                                        os: this.osToSelectOption2([...os])
                                    })
                                );

                                this.store.dispatch(
                                    fromOstkActionsStore.OstkInstancesVcpusValues({
                                        vcpus: this.vcpuToSelectOption([...vcpus].sort((x, y) => x - y))
                                    })
                                );

                                this.store.dispatch(
                                    fromOstkActionsStore.OstkInstancesRamValues({
                                        ram: this.ramToSelectOption([...ram].sort((x, y) => x - y))
                                    })
                                );

                                return instances;
                            })
                        )
                )
            );
    }

    getSingleByName (name: string): Observable<InstanceApi[]>
    {
        return this.instanceMiscApiService.getSingleByName(`?name=${name}&populated=false`);
    }

    getDetails (instanceId: string): Observable<Instance>
    {
        return combineLatest([
            this.store.select(getVolumesVoluLoadedStatus),
            this.store.select(getFlavorsLoadedStatus),
            this.store.select(getImagesLoadedStatus)
        ])
            .pipe(
                filter(([l1, l2, l3]: [boolean, boolean, boolean]) => l1 && l2 && l3),
                switchMap(() =>
                    this.instanceMiscApiService.getDetails(instanceId)
                        .pipe(
                            withLatestFrom(
                                this.store.select(getAllVolumesVolu),
                                this.store.select(getAllFlavors),
                                this.store.select(getImageSnapshotsById, { instanceId }),
                                (o1, o2, o3, o4) => ({
                                    instanceApi: o1,
                                    volumes: o2,
                                    flavors: o3,
                                    snapshots: o4
                                })
                            ),
                            map((result: { instanceApi: InstanceApi, volumes: Volume[], flavors: Flavor[], snapshots: ImageSnapshot[]; }) =>
                            {
                                const mapVolu: Map<string, Volume> = this.buildMap<Volume>(result.volumes);
                                const mapFlav: Map<string, Flavor> = this.buildMap<Flavor>(result.flavors);
                                const vols: Volume[] = result.instanceApi.attachedVolumes
                                    .map((volumeId: string) =>
                                        mapVolu.get(volumeId)
                                    );
                                const instance: Instance = DomainInstance.mapperFromApi(result.instanceApi, vols, mapFlav);

                                instance.snapshots = result.snapshots;
                                instance.flavor = mapFlav.get(result.instanceApi.idFlavor);

                                return instance;
                            })
                        )
                )
            );
    }

    getHistory (instanceId: string): Observable<Partial<InstanceTimelineGroup[]>>
    {
        return this.instanceMiscApiService.getHistory(instanceId)
            .pipe(
                concatAll(),
                map(InstanceTimelineGroup.mapperFromApi),
                toArray()
            );
    }

    deleteInstance (instance: Instance): Observable<void>
    {
        return this.instanceMiscApiService.deleteInstance(instance.id);
    }

    refreshInstanceAddresses (instanceId: string): Observable<Addresses>
    {
        return this.instanceMiscApiService.getDetails(instanceId)
            .pipe(
                map((instance: InstanceApi) => instance.addresses)
            );
    }

    private buildMap<T extends HasMajorProps> (collection: T[]): Map<string, T>
    {
        const temp: Map<string, T> = new Map<string, T>();

        collection.forEach((item: T) =>
            temp.set(item.id, item)
        );

        return temp;
    }

    private osToSelectOption2 = (operatingSystems: string[]): SelectOption2[] =>
    {
        const osOptions: { value: string[], label: string; }[] = [];

        OstkConstants.OsIcons.forEach((label, prefix) =>
        {
            const specificOperatingSystems = operatingSystems.filter((os: string) =>
                os && os.startsWith(prefix)
            );

            if (specificOperatingSystems && specificOperatingSystems.length > 0)
            {
                const current = osOptions.find(vl =>
                    vl.label === label
                );

                if (!current)
                {
                    osOptions.push({ label: label, value: specificOperatingSystems });
                }
                else
                {
                    current.value.concat(specificOperatingSystems);
                }
            }
        });

        return osOptions.map(vl =>
            new SelectOption2(
                vl.value.join(','),
                vl.label,
                false,
                UtilsService.getFullOsPicture(vl.label)
            )
        );
    };

    private vcpuToSelectOption (vcpus: number[]): SelectOption[]
    {
        return vcpus.map((vcpu: number) =>
            new SelectOption(
                vcpu,
                vcpu.toString(),
                false
            )
        );
    }

    private ramToSelectOption (ram: number[]): SelectOption[]
    {
        return ram.map((r: number) =>
            new SelectOption(
                r,
                this.bytesService.convertAndFormat(r, Bytes.MIBI, Bytes.GIBI, 0).toString(),
                false
            )
        );
    }

    public startInstance (instance: Instance): Observable<Instance>
    {
        return this.instanceMiscApiService.startInstance(instance.id)
            .pipe(
                map((instanceApi: InstanceApi) =>
                {
                    instance.updateStatus(instanceApi);

                    return instance;
                })
            );
    }

    public pauseInstance (instance: Instance): Observable<Instance>
    {
        return this.instanceMiscApiService.pauseInstance(instance.id)
            .pipe(
                map((instanceApi: InstanceApi) =>
                {
                    instance.updateStatus(instanceApi);

                    return instance;
                })
            );
    }

    public stopInstance (instance: Instance): Observable<Instance>
    {
        return this.instanceMiscApiService.stopInstance(instance.id)
            .pipe(
                map((instanceApi: InstanceApi) =>
                {
                    instance.updateStatus(instanceApi);

                    return instance;
                })
            );
    }

    public createInstance (civm: CreateInstanceViewModel): Observable<Instance | null>
    {
        return this.instanceMiscApiService.createInstance(civm)
            .pipe(
                switchMap((result: boolean) =>
                    iif(
                        () => result,
                        of(true)
                            .pipe(
                                map(() =>
                                    DomainInstance.MapperFromViewModel(civm)
                                )
                            ),
                        of(false)
                            .pipe(
                                map(() =>
                                    null
                                )
                            )
                    )
                )
            );
        // return combineLatest([
        //     this.store.select(getVolumesVoluLoadedStatus),
        //     this.store.select(getFlavorsLoadedStatus)
        // ])
        //     .pipe(
        //         filter(([l1, l2]: [boolean, boolean]) => l1 && l2),
        //         switchMap(() =>
        //             this.instanceMiscApiService.createInstance(civm)
        //                 .pipe(
        //                     withLatestFrom(
        //                         this.store.select(getAllVolumesVolu),
        //                         this.store.select(getAllFlavors),
        //                         (o1, o2, o3) => ({
        //                             instanceApi: o1,
        //                             volumes: o2,
        //                             flavors: o3
        //                         })
        //                     ),
        //                     map((result: { instanceApi: InstanceApi, volumes: Volume[], flavors: Flavor[]; }) =>
        //                     {
        //                         const mapVolu: Map<string, Volume> = this.buildMap<Volume>(result.volumes);
        //                         const mapFlav: Map<string, Flavor> = this.buildMap<Flavor>(result.flavors);
        //                         // const os: Set<string> = new Set<string>();
        //                         // const vcpus: Set<number> = new Set<number>();
        //                         // const ram: Set<number> = new Set<number>();
        //                         const volumes: Volume[] = (result.instanceApi.attachedVolumes ?? [])
        //                             .map((volumeId: string) =>
        //                                 mapVolu.get(volumeId)
        //                             );
        //                         const instance: Instance = Instance.mapperFromApi(result.instanceApi, volumes);
        //                         instance.flavor = mapFlav.get(result.instanceApi.idFlavor);


        //                         // os.add(instance.osLabel);
        //                         // vcpus.add(instance.vcpus);
        //                         // ram.add(instance.ram);

        //                         // this.store.dispatch(
        //                         //     fromOstkActionsStore.OstkInstancesOperatingSystemValues({
        //                         //         os: this.osToSelectOption2([...os])
        //                         //     })
        //                         // );

        //                         // this.store.dispatch(
        //                         //     fromOstkActionsStore.OstkInstancesVcpusValues({
        //                         //         vcpus: this.vcpuToSelectOption([...vcpus].sort((x, y) => x - y))
        //                         //     })
        //                         // );

        //                         // this.store.dispatch(
        //                         //     fromOstkActionsStore.OstkInstancesRamValues({
        //                         //         ram: this.ramToSelectOption([...ram].sort((x, y) => x - y))
        //                         //     })
        //                         // );

        //                         return instance;
        //                     })
        //                 )
        //         )
        //     );
    }

    public takeSnapshot (instance: Instance, name: string): Observable<ImageSnapshot>
    {
        return this.instanceMiscApiService.takeSnapshot(instance.id, name)
            .pipe(
                map(Image.mapperFromApi),
                map(ImageSnapshot.mapperFromImage)
            );
    }

    public manageSecurityGroups (instanceId: string, securityGroups: ManageSecurityGroupsViewModel): Observable<Instance>
    {
        return this.instanceMiscApiService.manageSecurityGroups(instanceId, securityGroups)
            .pipe(
                map((instanceApi: InstanceApi) =>
                    DomainInstance.mapperFromApi(instanceApi)
                )
            );
    }

    public renameInstance (instance: Instance, newName: string): Observable<void>
    {
        return this.instanceMiscApiService.renameInstance(instance.id, newName);
    }
}
