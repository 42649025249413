import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as fromRootStore from '@apps/root/store';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';

import { Platform, Project } from '../../domain/models';
import { OstkConstants } from '../../ostk.constants';
import * as fromProjectsActions from './projects.actions';

export const OstkProjectsFeatureKey = 'projects';
type IPlatformsState = EntityState<Platform>;
export const platformsAdapter: EntityAdapter<Platform> = createEntityAdapter({
    selectId: (platform: Platform) => platform.platformId,
    sortComparer: (pa, pb) =>
    {
        if (pa.platformType === OstkConstants.PlatformTypeAdc)
        {
            if (pb.platformType !== OstkConstants.PlatformTypeAdc)
            {
                return -1;
            }
        }
        else if (pb.platformType === OstkConstants.PlatformTypeAdc)
        {
            return 1;
        }

        return pa.platformName.toUpperCase().localeCompare(pb.platformName.toUpperCase());
    }
});
const platformInitialState: IPlatformsState = platformsAdapter.getInitialState({});
type IProjectsState = EntityState<Project>;
export const projectsAdapter: EntityAdapter<Project> = createEntityAdapter({
    selectId: (project: Project) => project.projectId
});
const projectInitialState: IProjectsState = projectsAdapter.getInitialState({});

export interface IOstkProjectsState
{
    platforms: IPlatformsState;
    projects: IProjectsState;
    isLoadingPlatform: boolean;
    isEmptyPlatform: boolean;
    isOupsPlatform: boolean;
    selectedPlatform: Platform;
    isLoadingProject: boolean;
    isEmptyProject: boolean;
    isOupsProject: boolean;
    selectedProject: Project;
}

const initialState: IOstkProjectsState = {
    platforms: platformInitialState,
    projects: projectInitialState,
    isLoadingPlatform: false,
    isEmptyPlatform: false,
    isOupsPlatform: false,
    selectedPlatform: null,
    isLoadingProject: false,
    isEmptyProject: false,
    isOupsProject: false,
    selectedProject: null
};

export const OstkProjectsReducer = createReducer(
    initialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => initialState
    ),
    on(
        fromProjectsActions.OstkPlatformsLoadingSucceeded,
        (state, { platforms }: { platforms: Platform[] }) =>
            ({
                ...state,
                platforms: platformsAdapter.setAll(platforms, state.platforms)
            })
    ),
    on(
        fromProjectsActions.OstkPlatformsLoadingFailed,
        (state) =>
            ({
                ...state,
                isOupsPlatform: true,
            })
    ),
    on(
        fromProjectsActions.OstkPlatformSelected,
        (state, { platform }: { platform: Platform }) =>
            ({
                ...state,
                selectedPlatform: platform,
                projects: projectsAdapter.removeAll(state.projects)
            })
    ),
    on(
        fromProjectsActions.OstkProjectsLoadingSucceeded,
        (state, { projects }: { projects: Project[] }) =>
            ({
                ...state,
                projects: projectsAdapter.setAll(projects, projectsAdapter.removeAll(state.projects))

            })
    ),
    on(
        fromProjectsActions.OstkProjectSelected,
        (state, { project }: { project: Project }) =>
            ({
                ...state,
                selectedProject: project
            })
    )
);
