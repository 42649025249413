import { createAction, props } from '@ngrx/store';
import { DateTime } from 'luxon';

import { CountServers, LastSession, StatusPerRange, TotalCapacity, Variations } from '../../../dashboard/models';
import { RequestsSummaryCounter } from '@common/models';

export const LoadBackupVbrDashboardStatusPerRangeRequested = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Status Per Range Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupVbrDashboardStatusPerRangeSucceeded = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Status Per Range Succeeded',
    props<{ statusPerRange: StatusPerRange }>()
);

export const LoadBackupVbrDashboardLastSessionRequested = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Last Session Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupVbrDashboardLastSessionSucceeded = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Last Session Succeeded',
    props<{ lastSession: LastSession }>()
);

export const LoadBackupVbrDashboardLastSessionFailed = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Last Session Failed'
);

export const LoadBackupVbrDashboardCountServersRequested = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Count Servers Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupVbrDashboardCountServersSucceeded = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Count Servers Succeeded',
    props<{ countServers: CountServers }>()
);

export const LoadBackupVbrDashboardTotalCapacityRequested = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Total Capacity Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupVbrDashboardTotalCapacitySucceeded = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Total Capacity Succeeded',
    props<{ totalCapacity: TotalCapacity }>()
);

export const LoadBackupVbrDashboardTicketingRequested = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Ticketing Requested'
);

export const LoadBackupVbrDashboardTicketingSucceeded = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Ticketing Succeeded',
    props<{ ticketing: RequestsSummaryCounter }>()
);

export const LoadBackupVbrDashboardVariationsRequested = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Variations Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupVbrDashboardVariationsSucceeded = createAction(
    '[BCKP-DASH] Load Backup Vbr Dashboard Variations Succeeded',
    props<{ variations: Variations }>()
);