<aa-popin [isShown]="isShown" [onClose]="closePopin" [width]="26">
    <span class="title">{{'NOTF.TITLE' | translate}}</span>
    <div>
        <div *ngIf="messages.length !== 0; else noNotif">
            <aa-popin-notif-item *ngFor="let message of messages" [message]="message"></aa-popin-notif-item>
        </div>
        <ng-template #noNotif>
            <div >
               {{'NOTF.MESSAGE.EMPTY' | translate}}
            </div>
        </ng-template>
    </div>
</aa-popin>
