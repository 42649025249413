import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
    selector: '[aaAutofocus]'
})
export class AutofocusDirective implements AfterViewInit
{
    constructor(private el: ElementRef)
    {
    }

    ngAfterViewInit ()
    {
        this.el.nativeElement.querySelector('input').focus();
    }
}
