import { ColorLevel, ColorName, ColorsService } from '@common/services';

import { TagApi } from '../api/models';
import { EnrichedTagApi } from '../api/models/tag-api';
import { CmdbConstants } from '../cmdb.constants';

export class Tag //implements TagFilterStrategy
{
    bgColor: string;
    fgColor: string;
    tagId: string;
    tagName: string;
    description?: string;
    categoryId: string;
    categoryName: string;
    fullTagName: string;

    public static mapperFromApi (source: TagApi): Tag
    {
        const target = new Tag();

        Object.assign(target, source);
        target.fullTagName = Tag.getFullTagName(target.categoryName, target.tagName);

        return target;
    }

    public static mapperFromObject (source: Tag): Tag
    {
        const target = new Tag();

        Object.assign(target, source);
        target.fullTagName = Tag.getFullTagName(source.categoryName, source.tagName);

        return target;
    }

    public static getFullTagName (categoryName: string, tagName: string)
    {
        return `${categoryName}::${tagName}`;
    }
}

export class EnrichedTag extends Tag
{
    nbUses: number;

    public static mapperFromApi (source: EnrichedTagApi): EnrichedTag
    {
        const target = new EnrichedTag();

        Object.assign(target, source);
        target.fullTagName = Tag.getFullTagName(target.categoryName, target.tagName);

        return target;
    }

    public static mapperFromNewTag (source: Tag): EnrichedTag
    {
        const target = new EnrichedTag();

        Object.assign(target, source);
        target.nbUses = 0;
        target.fullTagName = Tag.getFullTagName(target.categoryName, target.tagName);

        return target;
    }
}

export class UpsertTagViewModel
{
    label: string;
    bgColor: string;
    fgColor: string;
    description: string;
    categoryName: string;
}

export class FilterTag extends Tag
{
    public included: boolean;
    public enabled: boolean;
    public withToolbox: boolean;
    isTaggedBy: (tagIds: string[]) => boolean = null;

    constructor ()
    {
        super();

        this.included = true;
        this.enabled = true;
        this.withToolbox = true;
    }
}
export abstract class StaticFilterTag
{
    public static mapperFromTag = (source: Tag): FilterTag =>
    {
        const target = new FilterTag();

        Object.assign(target, source);

        return target;
    };
}

export class NoneTag extends FilterTag
{
    constructor ()
    {
        super();

        this.tagId = CmdbConstants.NoneTagId;
        this.tagName = CmdbConstants.NoneTagName;
        this.categoryName = CmdbConstants.ExtraTagCategoryName;
        this.fullTagName = Tag.getFullTagName(this.categoryName, this.tagName);
        this.fgColor = ColorsService.getBasicColor(ColorLevel.Normal, ColorName.Grey1);
        this.bgColor = ColorsService.getBasicColor(ColorLevel.Normal, ColorName.Grey2);
        this.description = 'CMDB.TAGS.FILTER.NONE';
        this.withToolbox = false;
    }
}

export class AnyTag extends FilterTag
{
    constructor ()
    {
        super();

        this.tagId = CmdbConstants.AnyTagId;
        this.tagName = CmdbConstants.AnyTagName;
        this.categoryName = CmdbConstants.ExtraTagCategoryName;
        this.fullTagName = Tag.getFullTagName(this.categoryName, this.tagName);
        this.fgColor = ColorsService.getBasicColor(ColorLevel.Normal, ColorName.Grey1);
        this.bgColor = ColorsService.getBasicColor(ColorLevel.Normal, ColorName.Grey2);
        this.description = 'CMDB.TAGS.FILTER.ANY';
        this.withToolbox = false;
    }
}
