import { BaseFields4Filter } from 'src/app/_common/filters/base-fields-4filter';
import { NumberArrayField4Filter, StringArrayField4Filter } from 'src/app/_common/filters/field-4filter';
import { Instance } from '../../domain/models/instance/instance';

export class InstanceFields4Filter extends BaseFields4Filter<Instance>
{
    // private _isRunning: boolean;
    // get isRunning ()
    // {
    //     return this._isRunning;
    // }
    // set isRunning (value: boolean)
    // {
    //     const f4f = new BooleanField4Filter<Instance>('isRunning', value);

    //     this.addField(f4f);
    //     this._isRunning = value;
    // }

    private _osLabel: string[];
    get osLabel ()
    {
        return this._osLabel;
    }
    set osLabel (value: string[])
    {
        if (value && value.length > 0)
        {
            const f4f = new StringArrayField4Filter<Instance>('osLabel', value);

            this.addField(f4f);
            this._osLabel = value;
        }
    }

    private _vcpus: string[];
    get vcpus ()
    {
        return this._vcpus;
    }
    set vcpus (value: string[])
    {
        if (value && value.length > 0)
        {
            const f4f = new StringArrayField4Filter<Instance>('vcpus', value);

            this.addField(f4f);
            this._vcpus = value;
        }
    }

    private _ram: number[];
    get ram ()
    {
        return this._ram;
    }
    set ram (value: number[])
    {
        if (value && value.length > 0)
        {
            const f4f = new NumberArrayField4Filter<Instance>('ram', value);

            this.addField(f4f);
            this._ram = value;
        }
    }

    constructor (filtre?: InstanceFields4Filter)
    {
        super();

        if (filtre)
        {
            this.osLabel = filtre.osLabel;
            this.ram = filtre.ram;
            this.vcpus = filtre.vcpus;
        }
    }
}
