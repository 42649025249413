import { RequestsUseCaseCounterApi } from '@apps/its2/api/models';
import { LocalizedText } from '@common/models';

export class RequestsUseCaseCounter
{
    useCaseTypeId: number;
    useCaseType: LocalizedText;
    count: number;
}

export class DomainRequestsUseCaseCounter
{
    public static mapperFromApi (source: RequestsUseCaseCounterApi): RequestsUseCaseCounter
    {
        const target = new RequestsUseCaseCounter();

        target.useCaseTypeId = source.useCaseTypeId;
        target.useCaseType = new LocalizedText(source.useCaseTypeEN, source.useCaseTypeFR);
        target.count = source.count;

        return target;
    }
}

