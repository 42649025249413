import { OpenPopinOriginator } from '@common/enum/open-popin-originator.enum';

import { TypePopin } from '../app.constants';
import { CmdbAsset, EnrichedTag } from './models';
import { Guid } from '@common/services/utils.service';

// * Classification des assets
export enum TypeAssetName
    {
    HOST = 'HOST',
    POLICY = 'POLICY'
}
export type TypeAsset = TypeAssetName.HOST | TypeAssetName.POLICY; // | 'BCKP-IMAGES';

export enum TagColor
    {
    BgColor = '#e5eeff',
    FgColor = '#0057ff'
}

export enum CmdbAssetStatus
    {
    ACTIVE = 'Active'
    // TODO: À enrichir pour la liste complète  !!
}

export enum CmdbAssetCategoryGuid
    {
    VIRTUAL_MACHINE = '03e386ca-7e87-4f83-8be6-cba392a6841b',
    PHYSICAL_SERVER = '25bb52f7-09e6-4401-b851-f0d32621de86',
    APPLICATION = '1cf53e54-2c0e-429f-b374-36ca100b9f02',
    ENTITY = '3cf5cba4-580b-4f01-afd0-4c332ed43145',
    OPERATING_SYSTEM = 'b25ff267-12ef-4c7a-b8ef-82fe97f8ee4e'
}

export class CmdbConstants
{
    public static Application: string = 'CmdbApi';
    public static BaseUrl: string = 'assets';
    public static BaseApi: string = 'cmdb';
    public static Tags: string = 'tags';
    public static Assets: string = 'assets';
    public static Details: string = 'details';
    public static Export: string = 'export';
    public static DataLifeTime = 7;
    public static PollingInterval = 10 * 60 * 1000;
    public static QSAssetsTop = 50;
    public static TagMaxLength = 255;
    public static TagForbiddenCharacters = ['/', ';', '\\', ',', '?'];
    public static ToTagsRoute = () =>
        [
            CmdbConstants.BaseUrl,
            CmdbConstants.Tags
        ];
    public static TagsRoutePath = `/${CmdbConstants.ToTagsRoute().join('/')}`;

    public static ToTagCreationRoute = () =>
        [
            CmdbConstants.BaseUrl,
            CmdbConstants.Tags,
            { outlets: { [TypePopin.ACTION]: ['create', OpenPopinOriginator.CMDB_TAGS] } }
        ];

    public static MiscToTagCreationRoute = () =>
        [
            CmdbConstants.BaseUrl,
            CmdbConstants.Tags,
            { outlets: { [TypePopin.ACTION]: ['policy', OpenPopinOriginator.COMP_TOPOLOGY] } }
        ];

    public static TagDetailsRoute = (tagId: string) =>
        [
            CmdbConstants.BaseUrl,
            CmdbConstants.Tags,
            { outlets: { [TypePopin.DETAILS]: [tagId] } }
        ];
    public static TagDetailsEditRoute = (tagId: string) =>
        [
            CmdbConstants.BaseUrl,
            CmdbConstants.Tags,
            { outlets: { [TypePopin.DETAILS]: [tagId, 'edit'] } }
        ];
    public static AssetsRoute = () =>
        [
            CmdbConstants.BaseUrl,
            CmdbConstants.Assets
        ];
    public static AssetsRoutePath = `/${CmdbConstants.AssetsRoute().join('/')}`;
    public static DefaultUrl: string = CmdbConstants.AssetsRoutePath;
    public static FallbackUrl: string = CmdbConstants.TagsRoutePath;
    public static AssetDetailsRoute = (categoryId: string, assetId: string) =>
        [
            ...CmdbConstants.AssetsRoute(),
            { outlets: { [TypePopin.DETAILS]: [categoryId, assetId] } }
        ];
    public static getCssActiveStatus = (status: string) => status === CmdbAssetStatus.ACTIVE ? 'active' : 'inactive';
    public static tagsQSFields: Array<keyof EnrichedTag> = [ 'tagName', 'categoryName', 'description', 'tagId' ];
    static assetsQSFields: Array<keyof CmdbAsset> = ['name', 'serialNumber', 'model'];
    public static ExtraTagCategoryName: string = '~';
    public static AnyTagName: string = 'ANY';
    public static AnyTagId: string = 'dd6848a1-9b99-47ca-ba7f-8621d03ca33e';
    public static NoneTagName: string = 'NONE';
    public static NoneTagId: string = Guid.empty;

    public static getYesNoKey (value: boolean): string
    {
        return value ? 'WORDS.YES' : 'WORDS.NO';
    }
}
