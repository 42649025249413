import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { DateTime } from 'luxon';

import * as fromOstkQuotasActions from './billing.actions';
import * as fromOstkProjectsActions from '../projects/projects.actions';
import * as fromAuthLoginActions from 'src/app/_apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from 'src/app/_apps/auth/store/profile/profile.actions';
import { OstkConstants } from '../../ostk.constants';
import { Project, Reference } from '../../domain/models/billing';

export const OstkBillingFeatureKey = 'billing';

type IReferencesState = EntityState<Reference>;
export const referencesAdapter: EntityAdapter<Reference> = createEntityAdapter({
    selectId: (reference: Reference) => reference.referenceId
});
const referencesInitialState: IReferencesState = referencesAdapter.getInitialState({});

export interface IOstkBillingState
{
    references: IReferencesState;
    isEmpty: boolean;
    isLoading: boolean;
    isOups: boolean;
    isLoaded: boolean;
    expirationDate?: DateTime;
    project: Project;
}

const initialState: IOstkBillingState = {
    references: referencesInitialState,
    isEmpty: false,
    isLoading: false,
    isOups: false,
    isLoaded: false,
    expirationDate: null,
    project: null
};

export const OstkBillingReducer = createReducer(
    initialState,
    on(
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthProfileActions.AuthProfileLanguageSet,
        fromAuthLoginActions.AuthLogout,
        fromOstkProjectsActions.OstkProjectSelected,
        () => initialState
    ),
    on(
        fromOstkQuotasActions.OstkBillingRequested,
        (state: IOstkBillingState) =>
            ({
                ...state,
                isLoading: !(state.expirationDate !== null && DateTime.utc() < state.expirationDate)
            })
    ),
    on(
        fromOstkQuotasActions.OstkBillingSucceeded,
        (state: IOstkBillingState, { references, project }: { references: Reference[], project: Project }) =>
            ({
                ...state,
                isLoading: false,
                isEmpty: references && references.length === 0,
                isOups: false,
                isLoaded: true,
                references: referencesAdapter.setAll(references, state.references),
                project: project,
                expirationDate: DateTime.utc().plus({ minutes: OstkConstants.DataLifeTime })
            })
    ),
    on(
        fromOstkQuotasActions.OstkBillingFailed,
        (state: IOstkBillingState) =>
            ({
                ...state,
                isLoading: false,
                isEmpty: false,
                isOups: true,
                isLoaded: false,
                references: referencesAdapter.removeAll(state.references),
                project: null,
                expirationDate: null
            })
    )
);
