<label for="{{optionId}}">
    <input id="{{optionId}}"
           (click)="toggleCheck()"
           type="checkbox"
           [checked]="isChecked"
           [value]="option.value" />
    <ng-container *ngIf="option.imgPath">
        <span title="{{ option.label }}">
            <img src="{{option.imgPath}}"
                 width="18px"
                 height="18px"
                 alt="{{ option.label }}" />
        </span>
    </ng-container>
    <ng-container *ngIf="!option.imgPath">
        <span >{{ option.label | uppercase | translate }}</span>
    </ng-container>
</label>
