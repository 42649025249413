import { createFeatureSelector } from '@ngrx/store';

import { IAuroraState } from '@apps/aurora.state';

import * as fromAttacksReducers from './attacks/attacks.reducer';
import { ISecuDashboardState, SecuDashboardInitialState, SecuDashboardFeatureKey } from './dashboard/dashboard.state';

export const SecuFeatureKey = 'secu';

export interface IState extends IAuroraState
{
    [SecuFeatureKey]: ISecuState;
}

export interface ISecuState
{
    [fromAttacksReducers.SecuAttacksFeatureKey]: fromAttacksReducers.ISecuAttacksState;
    [SecuDashboardFeatureKey]: ISecuDashboardState
}

export const getSecuState = createFeatureSelector<ISecuState>(
    SecuFeatureKey
);

export const SecuInitialState: ISecuState = {
    [fromAttacksReducers.SecuAttacksFeatureKey]: fromAttacksReducers.SecuAttacksinitialState,
    [SecuDashboardFeatureKey]: SecuDashboardInitialState
};
