import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { FilesService } from '@common/services';
import { MediaTypes } from '@common/services/files.service';
import { AppConstants } from '@apps/app.constants';

@Injectable({
    providedIn: 'root'
})
export class JobMiscApiService extends BaseMiscApiService
{
    constructor (http: HttpClient, private filesService: FilesService)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlHist;
        this.segmentApi = '/hist/netbackup';
    }

    export (qs: string, format: MediaTypes): Observable<HttpEvent<Blob>>
    {
        return this.download(`/export/jobs${qs}`, format);
    }

    restoresExport (qs: string, format: MediaTypes): Observable<HttpEvent<Blob>>
    {
        return this.download(`/export/restorejobs${qs}`, format);
    }
}
