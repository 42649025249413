import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { DateTime, Duration, FixedOffsetZone } from 'luxon';

import { CompareDirection } from '@apps/app.constants';
import { RangeKey } from '@common/enum';
import { DateRange } from '@ui/components/form/complex/range-picker/utils';
import { Period28To28 } from '@common/models';
import { UtilsService } from '@common/services/utils.service';

@Injectable({
    providedIn: 'root'
})
export class DatesService
{
    private static readonly RegExp8239: RegExp = new RegExp(String.fromCharCode(8239), 'g');
    private static readonly Char160 = String.fromCharCode(160);

    constructor (private translateService: TranslateService)
    {
    }

    public static compareDate = (datea: DateTime, dateb: DateTime, direction: CompareDirection = CompareDirection.ASC): number =>
    {
        const ecart: number = +direction * (datea < dateb ? -1 : (datea > dateb) ? 1 : 0);

        // Lors de l'égalité des deux dates avec une direction descending, ecart vaut -0
        if (Object.is(-0, ecart))
        {
            return 0;
        }

        return ecart;
    };

    // #region Transformation string => Date ou DateTime
    public static UtcStringToDateHourStatic (dateUtc: string): DateTime
    {
        if (dateUtc.length < 10)
        {
            return null;
        }

        try
        {
            return DateTime.fromISO(dateUtc);
        }
        catch (error)
        {
            return null;
        }
    }

    /**
     * @param {string} dateUtc - Format 2019-01-14T10:19:00Z or 2019-01-14
     *
     * @returns {DateTime}
     */
    public static UtcStringToDateTimeHourStatic (dateUtc: string): DateTime
    {
        const regexp1: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}((?:\.\d+){0,1}|(\+\d{2}:\d{2}){0,1})[zZ]$/i;
        const regexp2: RegExp = /((?:\.\d*){0,1}|(\+\d{2}:\d{2}){0,1})(?:[zZ]){0,1}$/i;
        const fmt: string = 'yyyy-MM-dd\'T\'hh:mm:ss\'Z\'';

        if (UtilsService.isNullOrEmpty(dateUtc))
        {
            return null;
        }

        dateUtc = dateUtc.trim();
        if (!regexp1.test(dateUtc))
        {
            return null;
        }

        if (regexp2.test(dateUtc))
        {
            dateUtc = dateUtc.replace(regexp2, 'Z');
        }

        return DateTime.fromFormat(dateUtc, fmt, { zone: FixedOffsetZone.parseSpecifier('UTC') });
    }

    public UtcStringToDateHour (dateUtc: string): DateTime
    {
        return DatesService.UtcStringToDateHourStatic(dateUtc);
    }

    public UtcStringToDateTimeHour (dateUtc?: string): DateTime
    {
        return dateUtc ? DatesService.UtcStringToDateTimeHourStatic(dateUtc) : null;
    }

    /**
     * Returns a Date JS from a string timestamp
     * @param {string} dateUtc - Format 2019-01-14T10:19:00Z or 2019-01-14
     *
     * @returns {Date}
     */
    public UtcStringToDate (dateUtc: string): Date
    {
        if (dateUtc.length < 10)
        {
            return null;
        }

        try
        {
            return new Date(DateTime.fromFormat(dateUtc.substring(0, 10), 'yyyy-MM-dd').toISO());
        }
        catch (error)
        {
            return null;
        }
    }

    public UtcStringToShortDate (dateUtc: string): string
    {
        if (dateUtc.length < 10)
        {
            return '';
        }

        try
        {
            const fmt = this.translateService.instant('NUMBERS.FORMATS.DATES.LUXON.DAY_MONTH_YEAR');
            const d: DateTime = DateTime.fromFormat(dateUtc.substring(0, 10), 'yyyy-MM-dd');

            return d.toFormat(fmt);
        }
        catch (error)
        {
            return '';
        }
    }

    public FromISOToMonthDay (dateISO: string): string
    {
        if (dateISO.length < 10)
        {
            return '';
        }
        try
        {
            const fmt = this.translateService.instant('NUMBERS.FORMATS.DATES.LUXON.DAY_MONTH');
            return DateTime.fromISO(dateISO).toFormat(fmt);
        }
        catch (error)
        {
            return '';
        }
    }

    public FromISOToYearMonthDay (dateISO: string): string
    {
        if (dateISO.length < 10)
        {
            return '';
        }

        try
        {
            const fmt = this.translateService.instant('NUMBERS.FORMATS.DATES.LUXON.DAY_MONTH_YEAR');

            return DateTime.fromISO(dateISO).toFormat(fmt);
        }
        catch (error)
        {
            return '';
        }
    }
    // #endregion

    /**
     * @deprecated Ne plus utiliser et migrer vers luxon/DateTime
     */
    public DateToShortDate (date: DateTime): string
    {
        // ! Ne pas effectuer la traduction à chaque fois
        const fmt = this.translateService.instant('NUMBERS.FORMATS.DATES.DATEFNS.DAY');

        return date.toFormat(fmt);
    }

    public DateTimeToShorDate (date: DateTime): string
    {
        const fmt = this.translateService.instant('NUMBERS.FORMATS.DATES.LUXON.DAY_MONTH_YEAR');

        return date.toFormat(fmt);
    }

    /**
     *
     * @param date La date à présenter
     * @returns Une chaîne de 10 caractères présentant la date au format de la langue de l'usager
     */
    public DateTimeToHumanReadable (date: DateTime): string
    {
        return date.toFormat(this.translateService.instant('NUMBERS.FORMATS.DATES.LUXON.DAY_MONTH_YEAR'));
    }

    /**
     *
     * @param date La date à présenter
     * @returns Une chaîne de 10 caractères présentant la date au format de la langue de l'usager
     */
    public DateTimeToShortHumanReadable (date: DateTime): string
    {
        return date.toFormat(this.translateService.instant('NUMBERS.FORMATS.DATES.LUXON.DAY_MONTH'));
    }

    /**
     *
     * @param date La date à présenter
     * @returns Une chaîne présentant la date au format de la langue de l'usager (en fr-FR : JJ/MM/YYYY HH:MM:SS)
     */
    public DateTimeToFull (date: DateTime): string
    {
        if (date && date.isValid)
        {
            return date.toFormat(this.translateService.instant('NUMBERS.FORMATS.DATES.LUXON.FULL'));
        }
        else
        {
            return '';
        }
    }

    public DateToDayAndMonth (date: DateTime): string
    {
        // ! Ne pas effectuer la traduction à chaque fois
        const fmt = this.translateService.instant('NUMBERS.FORMATS.DATES.DATEFNS.DAYMONTH');

        return date.toFormat(fmt);
    }

    /**
     * @deprecated
     * @param date
     */
    public DateToDateHour (date: DateTime): string
    {
        const fmt = this.translateService.instant('NUMBERS.FORMATS.DATES.DATEFNS.DAYHOUR');

        return date.toFormat(fmt);
    }

    public DateTimeToDateHour (date: DateTime): string
    {
        const fmt = this.translateService.instant('NUMBERS.FORMATS.DATES.LUXON.DAY_HOUR');

        return date.toFormat(fmt);
    }

    public DateTimeToDayMonthHour (date: DateTime): string
    {
        const fmt = this.translateService.instant('NUMBERS.FORMATS.DATES.LUXON.DAY_MONTH_HOUR');

        return date.toFormat(fmt);
    }

    public Last30DaysDate (): string
    {
        return DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd');
    }

    public LastDaysDate (nbDays: number): string
    {
        return DateTime.now().minus({ days: nbDays }).toFormat('yyyy-MM-dd');
    }

    // #region Duration Calculus
    public NumberToDuration (seconds: number, language: string): string
    {
        if (!seconds)
        {
            return '';
        }

        return DatesService.ToHumanReadableDuration(Duration.fromMillis(seconds * 1000), language);
    }

    /**
     *
     * @param {DateTime} dt Birth date
     *
     * @returns l'âge au format human readable
     */
    public AgeDuration (dt: DateTime, lang: string): string
    {
        const locale = lang === 'fr-FR' ? 'fr' : 'en';

        return dt.toRelative({ base: DateTime.utc(), locale });
        // return dt.toRelative({ base: DateTime.utc(), locale, round: false });
    }
    // #endregion

    /**
     * Returns a string from a date
     * @param {DateTime} date
     *
     * @returns {string} - Format 2020-02-27
     */
    public DateToShortString (date: DateTime): string
    {
        return date.toFormat('yyyy-MM-dd');
    }

    // * Fabrication de range pour les "query string"
    /**
     *
     * @param {Date} since
     * @param {Date} until
     *
     * @returns  {string} - since=2020-01-01T00:00:00Z&until=2020-01-31T23:59:29Z
     */
    public static RawUtcDateRangeStatic (since: DateTime, until: DateTime): string
    {
        // eslint-disable-next-line quotes
        const s = since.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
        // eslint-disable-next-line quotes
        const u = until.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");

        return `since=${s}&until=${u}`;
    }

    public RawUtcDateRange (since: DateTime, until: DateTime): string
    {
        return DatesService.RawUtcDateRangeStatic(since, until);
    }

    // * Date au format de "query string"
    /**
     *
     * @param {Date} date
     *
     * @returns  {string} - 2020-01-01T00:00:00Z
     */
    public static DateToQueryStringStatic (date: DateTime): string
    {
        if (!date)
        {
            return null;
        }

        // eslint-disable-next-line quotes
        return date.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
    }

    /**
     *
     * @param {Date} date
     *
     * @returns  {string} - 2020-01-01T00:00:00Z
     */
    public DateToQueryString (date: DateTime): string
    {
        return DatesService.DateToQueryStringStatic(date);
    }

    /**
     *
     * @param {DateTime} since
     * @param {DateTime}until
     * @returns {DateTime, DateTime} intervalle de la même durée que l'intervalle initial se terminant la veille de l'intervalle initial
     */
    public getPreviousRange (since: DateTime, until: DateTime): { prevSince: DateTime, prevUntil: DateTime; }
    {
        if (since > until)
        {
            throw new RangeError;
        }

        const gap: Duration = until
            .startOf('day')
            .diff(since.startOf('day'), 'days');
        const prevUntil = since
            .minus({ days: 1 })
            .endOf('day');
        const prevSince = prevUntil
            .minus({ days: gap.days })
            .startOf('day');

        return { prevSince, prevUntil };
    }

    /**
     *
     * @param {RangeKey} key Un mot-clé qui définit une période, c'est aussi une clé de traduction
     * @returns Un objet de deux dates (UTC) de début et de fin de la période
     */
    public preRangeDates (key: RangeKey): DateRange
    {
        return DatesService.preRangeDatesStatic(key);

        // let since: DateTime,
        //     until: DateTime,
        //     today: DateTime,
        //     yesterday: DateTime,
        //     firstDay: DateTime;

        // switch (key)
        // {
        //     case RangeKey.YESTERDAY:
        //         yesterday = DateTime.utc().plus({ days: -1 });

        //         since = yesterday.startOf('day');
        //         until = since.endOf('day');

        //         break;
        //     case RangeKey.LAST24HOURS:
        //         until = DateTime.utc();
        //         since = until.minus({ hours: 24 });

        //         break;

        //     case RangeKey.LAST7DAYS:
        //         yesterday = DateTime.utc().plus({ days: -1 });
        //         firstDay = yesterday.plus({ days: -6 });

        //         since = firstDay.startOf('day');
        //         until = yesterday.endOf('day');

        //         break;

        //     case RangeKey.CURRENT7DAYS:
        //         today = DateTime.utc();
        //         firstDay = today.minus({ days: 6 });

        //         since = firstDay.startOf('day');
        //         until = today.endOf('day');

        //         break;

        //     case RangeKey.LAST28DAYS:
        //         yesterday = DateTime.utc().plus({ days: -1 });
        //         firstDay = yesterday.plus({ days: -27 });

        //         since = firstDay.startOf('day');
        //         until = yesterday.endOf('day');

        //         break;

        //     case RangeKey.CURRENTMONTH:
        //         {
        //             const temp = DateTime.utc();

        //             since = temp
        //                 .startOf('month')
        //                 .startOf('day');
        //             until = temp
        //                 .endOf('month')
        //                 .endOf('day');
        //         }

        //         break;

        //     case RangeKey.LASTMONTH:
        //         since = DateTime.utc()
        //             .startOf('month')
        //             .minus({ months: 1 })
        //             .startOf('day');
        //         until = since
        //             .endOf('month');

        //         break;

        //     case RangeKey.LAST6MONTH:
        //         until = DateTime.utc();
        //         since = until
        //             .minus({ months: 6 })
        //             .startOf('day');

        //         break;

        //     case RangeKey.TODAY:
        //     default:
        //         today = DateTime.utc();

        //         since = today.startOf('day');
        //         until = today;

        //         break;
        // }

        // return { since, until };
    }

    public displayRangePeriod (since?: DateTime, until?: DateTime): string
    {
        return since === null || until === null
            ? ''
            : this.translateService
                .instant('NUMBERS.FORMATS.RANGES.PERIOD',
                    {
                        since: this.DateTimeToHumanReadable(since),
                        until: this.DateTimeToHumanReadable(until)
                    }
                );
    }

    public displayRangeViewerPeriod (since?: DateTime, until?: DateTime): string[]
    {
        if (since === null || until === null)
        {
            return ['', ''];
        }

        // let viewer: string;
        // const lang = this.translateService.currentLang;

        // since = since.setLocale(lang);
        // until = until.setLocale(lang);

        // if (since.month === until.month)
        // {
        //     viewer = this.translateService
        //         .instant('NUMBERS.FORMATS.RANGES.VIEWER.SAME_MONTH',
        //             {
        //                 dayfrom: since.day,
        //                 dayto: until.day,
        //                 month: since.monthLong,
        //                 year: since.year
        //             }
        //         );
        // }
        // else if (since.year === until.year)
        // {
        //     viewer = this.translateService
        //         .instant('NUMBERS.FORMATS.RANGES.VIEWER.SAME_YEAR',
        //             {
        //                 dayfrom: since.day,
        //                 dayto: until.day,
        //                 monthfrom: since.monthLong,
        //                 monthto: until.monthLong,
        //                 year: since.year
        //             }
        //         );
        // }
        // else
        // {
        //     viewer = this.translateService
        //         .instant('NUMBERS.FORMATS.RANGES.VIEWER.OVER_YEAR',
        //             {
        //                 dayfrom: since.day,
        //                 dayto: until.day,
        //                 monthfrom: since.monthLong,
        //                 monthto: until.monthLong,
        //                 yearfrom: since.year,
        //                 yearto: until.year
        //             }
        //         );
        // }

        const hover = this.translateService
            .instant('NUMBERS.FORMATS.RANGES.PERIOD',
                {
                    since: this.DateTimeToDateHour(since),
                    until: this.DateTimeToDateHour(until)
                }
            );

        return [hover, hover];
    }

    public calendar28To28 (nbPeriodes: number): Period28To28[]
    {
        const lang = this.translateService.currentLang;
        const calendar: Period28To28[] = [];
        const now: DateTime = DateTime.utc();

        let until: DateTime;
        let since: DateTime;
        if (now.day >= 28)
        {
            until = now
                .set({ day: 27 })
                .endOf('day')
                .setLocale(lang);
        }
        else
        {
            until = now
                .minus({ months: 1 })
                .set({ day: 27 })
                .endOf('day')
                .setLocale(lang);
        }

        since = until
            .minus({ months: 1 })
            .set({ day: 28 })
            .startOf('day')
            .setLocale(lang);
        for (let i = 1; i <= nbPeriodes; i++)
        {
            calendar.push(new Period28To28(since, until));

            since = since.minus({ months: 1 });
            until = until.minus({ months: 1 });
        }

        return calendar;
    }

    public static preRangeDatesStatic (key: RangeKey): DateRange
    {
        let since: DateTime,
            until: DateTime,
            today: DateTime,
            yesterday: DateTime,
            firstDay: DateTime;

        switch (key)
        {
            case RangeKey.YESTERDAY:
                yesterday = DateTime.utc().plus({ days: -1 });

                since = yesterday.startOf('day');
                until = since.endOf('day');

                break;
            case RangeKey.LAST24HOURS:
                until = DateTime.utc();
                since = until.minus({ hours: 24 });

                break;

            case RangeKey.LAST7DAYS:
                yesterday = DateTime.utc().plus({ days: -1 });
                firstDay = yesterday.plus({ days: -6 });

                since = firstDay.startOf('day');
                until = yesterday.endOf('day');

                break;

            case RangeKey.LAST28DAYS:
                yesterday = DateTime.utc().plus({ days: -1 });
                firstDay = yesterday.plus({ days: -27 });

                since = firstDay.startOf('day');
                until = yesterday.endOf('day');

                break;

            case RangeKey.LAST30DAYS:
                until = DateTime.utc();
                since = until
                    .minus({ day: 29 })
                    .startOf('day');

                break;

            case RangeKey.CURRENTMONTH:
                {
                    const temp = DateTime.utc();

                    since = temp
                        .startOf('month')
                        .startOf('day');
                    until = temp
                        .endOf('month')
                        .endOf('day');
                }

                break;

            case RangeKey.LASTMONTH:
                since = DateTime.utc()
                    .startOf('month')
                    .minus({ months: 1 })
                    .startOf('day');
                until = since
                    .endOf('month');

                break;

            case RangeKey.LAST6MONTH:
                until = DateTime.utc();
                since = until
                    .minus({ months: 6 })
                    .startOf('day');

                break;

            case RangeKey.TODAY:
                today = DateTime.utc();

                since = today.startOf('day');
                until = today;

                break;

            case RangeKey.CURRENT7DAYS:
            default:
                today = DateTime.utc();
                firstDay = today.minus({ days: 6 });

                since = firstDay.startOf('day');
                until = today.endOf('day');

                break;
        }

        return { since, until };
    }

    public static isDataValid (expirationDate: DateTime): boolean
    {
        return expirationDate !== null && DateTime.utc() < expirationDate;
    }

    private static ToHumanReadableDuration (duration: Duration, language: string): string
    {
        const locale: string = language === 'fr-FR' ? 'fr' : 'en';
        const objDuration = duration.shiftTo('years', 'months', 'days', 'hours', 'minutes', 'seconds')
            .normalize()
            .toObject();
        const entries = Object
            .entries(objDuration)
            .filter(([, amount]): boolean => amount > 0);
        const formatedDuration: any = Duration.fromObject(
            entries.length === 0 ? { seconds: 0 } : Object.fromEntries(entries),
            { locale }
        );

        // Pour contourner le problème de restitution de Luxon, on remplace tous les espaces par des &nbsp;
        return formatedDuration.toHuman({ unitDisplay: 'short', listStyle: 'long' })
            .replace(/ /g, DatesService.Char160)
            .replace(DatesService.RegExp8239, DatesService.Char160);
    }

    public static monthEquals (datea: DateTime, dateb: DateTime): boolean
    {
        return datea.year === dateb.year && datea.month === dateb.month;
    }
}
