import { FragmentApi } from '@apps/bckp/api/models';

export class Fragment
{
    fragmentSize: number;
    fragmentState: number;
    fragmentNumber: number;
    mediaDesc: string;

    public static mapperFromApi (source: FragmentApi): Fragment
    {
        const target = new Fragment();

        Object.assign(target, source);

        return target;
    }
}
