import { FilterTag } from '@apps/cmdb/models';
import { createAction, props } from '@ngrx/store';


export const RootFilterTagSelect = createAction(
    '[FILTER-TAG] Root Filter Tag Select',
    props<{ filterTag: FilterTag }>()
);
export const RootFilterTagUnselect = createAction(
    '[FILTER-TAG] Root Filter Tag Unselect',
    props<{ filterTag: FilterTag }>()
);
export const RootFilterTagToggleEnabled = createAction(
    '[FILTER-TAG] Root Filter Tag Toggle Enabled',
    props<{ filterTag: FilterTag, toggle: boolean }>()
);
export const RootFilterTagToggleIncluded = createAction(
    '[FILTER-TAG] Root Filter Tag Toggle Included',
    props<{ filterTag: FilterTag, toggle: boolean }>()
);
export const RootFilterTagReset = createAction(
    '[FILTER-TAG] Root Filter Tag Reset'
);
