import { Type } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

import { Subject } from 'rxjs';

import { PopinModalEvent } from './popin-modal.event';
import { PopinModalConfig } from './popin-modal.config';
import { PopinComponentBase } from './popin-component-base';

export class PopinModalRef
{
    public afterClosed = new Subject<PopinModalEvent>();

    constructor (
        public readonly overlayRef: OverlayRef,
        public content: Type<PopinComponentBase>,
        public config?: PopinModalConfig,
        public data?: any
    )
    { }

    destroy (dialogResult: boolean)
    {
        this._destroy({ type: 'close', dialogResult });
    }

    private _destroy (event: PopinModalEvent)
    {
        this.overlayRef.dispose();
        this.afterClosed.next(event);
        this.afterClosed.complete();
    }
}
