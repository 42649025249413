import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';

import { FieldOptions } from '@common/models';

import { FieldOptionMiscApiService } from '../api';

@Injectable({
    providedIn: 'root'
})
export class FieldOptionMiscDomainService extends BaseMiscDomainService
{

    constructor (private fieldOptionMiscApiService: FieldOptionMiscApiService)
    {
        super();
    }

    getFieldOptions (endPoint: string, fieldName: string): Observable<FieldOptions>
    {
        return this.fieldOptionMiscApiService.getFieldOptions(endPoint)
            .pipe(
                map((response: string[]) =>
                    new FieldOptions(fieldName, response)
                )
            );
    }
}
