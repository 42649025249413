import { Injectable } from '@angular/core';
import { Resolve, } from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ColorsService, JsonStyle, Skin } from 'src/app/_common/services';
import { JsonStyleService } from '../domain';
import { getSkin } from '../../auth/store';
import { IAuroraState } from '../../aurora.state';

@Injectable()
export class SkinResolver implements Resolve<boolean>
{
    constructor (
        private jsonStyleService: JsonStyleService,
        private store: Store<IAuroraState>
    )
    {
    }

    resolve (): Observable<boolean>
    {
        return this.store.select(getSkin)
            .pipe(
                take(1),
                switchMap((skin: Skin) =>
                    this.jsonStyleService.getSkinJson(skin)
                        .pipe(
                            take(1),
                            switchMap((styles: JsonStyle) =>
                                ColorsService.setSkinColors(styles)
                                    .pipe(
                                        take(1),
                                        catchError(() => of(false))
                                    )
                            ),
                            catchError(() => of(false))
                        )
                ),
                catchError(() => of(false))
            );
    }
}
