import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CmdbConstants } from '@apps/cmdb/cmdb.constants';
import { FilterTag } from '@apps/cmdb/models';
import { Assetify } from '@common/domain/assetify';

import { DateTime } from 'luxon';

export class Guid
{
    public static newGuid (): Guid
    {
        return new Guid('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c =>
        {
            const r = Math.random() * 16 | 0;
            const v = (c == 'x') ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        }));
    }
    public static get empty (): string
    {
        return '00000000-0000-0000-0000-000000000000';
    }
    public get empty (): string
    {
        return Guid.empty;
    }
    public static isValid (str: string): boolean
    {
        const validRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return validRegex.test(str);
    }
    private value: string = this.empty;
    constructor (value?: string)
    {
        if (value)
        {
            if (Guid.isValid(value))
            {
                this.value = value;
            }
        }
    }
    public toString ()
    {
        return this.value;
    }

    public toJSON (): string
    {
        return this.value;
    }
}
// #region Constantes de gestion des images et icônes d'OS
// * OS Icons path
export const OSIconPath: string = './assets/images/osIcons';
export class OsIcon
{
    iconClass: string;
    iconCode: string;

    constructor (iconClass: string, iconCode: string)
    {
        this.iconClass = iconClass;
        this.iconCode = iconCode;
    }
}
export const OsFaIcons = new Map<string, OsIcon>([
    ['windows', new OsIcon('fab', '\uf17a')],
    ['win2k12', new OsIcon('fab', '\uf17a')],
    ['win2k16', new OsIcon('fab', '\uf17a')],
    ['win2k19', new OsIcon('fab', '\uf17a')],
    ['redhat', new OsIcon('fab', '\uf7bc')],
    ['suse', new OsIcon('fab', '\uf7d6')],
    ['ubuntu', new OsIcon('fab', '\uf7df')],
    ['centos', new OsIcon('fab', '\uf789')],
    ['debian', new OsIcon('icons8', '\uf126')],
    ['fedora', new OsIcon('fab', '\uf398')],
    ['freebsd', new OsIcon('fab', '\uf3a4')],
    ['linux', new OsIcon('fab', '\uf17c')]
]);
export const OsPictures = new Map([
    ['windows', 'windows'],
    ['win2k12', 'win2k12'],
    ['win2k16', 'win2k16'],
    ['win2k19', 'win2k19'],
    ['rhel', 'redhat'],
    ['sles', 'suse'],
    ['vm', 'vmware'],
    ['centos', 'centos'],
    ['debian', 'debian'],
    ['fedora', 'fedora'],
    ['freebsd', 'freebsd'],
    ['gnome', 'gnome'],
    ['hp', 'hp'],
    ['linux', 'linux'],
    ['redhat', 'redhat'],
    ['suse', 'suse'],
    ['ubuntu', 'ubuntu'],
    ['vmware', 'vmware'],
    ['cirros', 'cirros'],
    ['coreos', 'coreos'],
    ['fortios', 'fortios'],
    ['rancheros', 'rancheros'],
    ['flatcar', 'flatcar'],
    ['junos', 'junos']
]);
// #endregion
export const cumulativeSum = (init: number) => ((sum: number) =>
    (value: number) =>
        sum += value)(init);
// * Dans tous les cas null < à qqchose de non null
export const isWellDefined = (x: any): boolean =>
    x !== null && x !== undefined;
export const comparator = <T> (fa: T, fb: T, fn: (xa: T, xb: T) => number) =>
{
    if (isWellDefined(fa))
    {
        if (isWellDefined(fb))
        {
            return fn(fa, fb);
        }
        else
        {
            return 1;
        }
    }
    else if (isWellDefined(fb))
    {
        return -1;
    }
    else
    {
        return 0;
    }
};
export type Comparer<T> = (a: T, b: T) => number;

// #region Old Fashion
/**
 *
 * @deprecated use CompareBoolean2
 */
export const CompareBoolean = <T> (field: string): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<boolean>(itema[field], itemb[field], (xa, xb) => xa ? (xb ? 0 : 1) : (xb ? -1 : 0));
/**
 *
 * @deprecated use CompareLowerString2
 */
export const CompareLowerString = <T> (field: string): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<string>(itema[field], itemb[field], (xa, xb) => xa.toLowerCase().localeCompare(xb.toLowerCase()));
/**
 *
 * @deprecated use CompareUpperString2
 */
export const CompareUpperString = <T> (field: string): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<string>(itema[field], itemb[field], (xa, xb) => xa.toUpperCase().localeCompare(xb.toUpperCase()));
/**
 *
 * @deprecated use CompareNumber2
 */
export const CompareNumber = <T> (field: string): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<number>(itema[field], itemb[field], (xa, xb) => xa - xb);
/**
 *
 * @deprecated use CompareDateTime2
 */
export const CompareDateTime = <T> (field: string): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<DateTime>(itema[field], itemb[field], (xa, xb) => xa < xb ? -1 : (xa > xb ? 1 : 0));
// #endregion

// #region New Methods
export const CompareBoolean2 = <T> (accessor: (item: T) => boolean): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<boolean>(accessor(itema), accessor(itemb), (xa, xb) => xa ? (xb ? 0 : 1) : (xb ? -1 : 0));
export const CompareLowerString2 = <T> (accessor: (item: T) => string): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<string>(accessor(itema), accessor(itemb), (xa, xb) => xa.toLowerCase().localeCompare(xb.toLowerCase()));
export const CompareUpperString2 = <T> (accessor: (item: T) => string): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<string>(accessor(itema), accessor(itemb), (xa, xb) => xa.toUpperCase().localeCompare(xb.toUpperCase()));
export const CompareNumber2 = <T> (accessor: (item: T) => number): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<number>(accessor(itema), accessor(itemb), (xa, xb) => xa??0 - xb??0);
export const CompareDateTime2 = <T> (accessor: (item: T) => DateTime): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<DateTime>(accessor(itema), accessor(itemb), (xa, xb) => xa < xb ? -1 : (xa > xb ? 1 : 0));
export const CompareAddressIp2 = <T> (accessor: (item: T) => string): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<string>(accessor(itema), accessor(itemb), (xa, xb) =>
        {
            const ta: number[] = xa.split('.')
                .map((t: string)=> parseInt(t, 10));
            const tb: number[] = xb.split('.')
                .map((t: string) => parseInt(t, 10));

            if (ta.length === 0)
            {
                if (tb.length !== 0)
                {
                    return -1;
                }
                else
                {
                    return 0;
                }
            }

            if (tb.length === 0)
            {
                if (ta.length !== 0)
                {
                    return 1;
                }
            }

            let idx: number = 0;
            if (ta[idx] < tb[idx])
            {
                return -1;
            }
            if (ta[idx] > tb[idx])
            {
                return 1;
            }

            idx = 1;
            if (ta[idx] < tb[idx])
            {
                return -1;
            }
            if (ta[idx] > tb[idx])
            {
                return 1;
            }

            idx = 2;
            if (ta[idx] < tb[idx])
            {
                return -1;
            }
            if (ta[idx] > tb[idx])
            {
                return 1;
            }

            idx = 3;
            if (ta[idx] < tb[idx])
            {
                return -1;
            }
            if (ta[idx] > tb[idx])
            {
                return 1;
            }

            return 0;
        });
// #endregion

// #region Tri dans un tableau
/**
 *
 * @deprecated use CompareBoolean2
 */
export const CompareBooleanInArray = <T> (field: string, index: number): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<boolean>(itema[field][index], itemb[field][index], (xa, xb) => xa ? (xb ? 0 : 1) : (xb ? -1 : 0));
/**
 *
 * @deprecated use CompareLowerString2
 */
export const CompareLowerStringInArray = <T> (field: string, index: number): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<string>(itema[field][index], itemb[field][index], (xa, xb) => xa.toLowerCase().localeCompare(xb.toLowerCase()));
/**
 *
 * @deprecated use CompareUpperString2
 */
export const CompareUpperStringInArray = <T> (field: string, index: number): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<string>(itema[field][index], itemb[field][index], (xa, xb) => xa.toUpperCase().localeCompare(xb.toUpperCase()));
/**
 *
 * @deprecated use CompareNumber2
 */
export const CompareNumberInArray = <T> (field: string, index: number): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<number>(itema[field][index], itemb[field][index], (xa, xb) => xa - xb);
/**
 *
 * @deprecated use CompareDateTime2
 */
export const CompareDateTimeInArray = <T> (field: string, index: number): Comparer<T> =>
    (itema: T, itemb: T): number =>
        comparator<DateTime>(itema[field][index], itemb[field][index], (xa, xb) => xa < xb ? -1 : (xa > xb ? 1 : 0));
// #endregion

// #region Sets manipulation
export const setDifference = <T> (a: Set<T>, b: Set<T>): Set<T> =>
    new Set([...a].filter(x => !b.has(x)));
export const setIntersection = <T> (a: Set<T>, b: Set<T>): Set<T> =>
    new Set([...a].filter(x => b.has(x)));
export const setUnion = <T> (a: Set<T>, b: Set<T>): Set<T> =>
    new Set([...a, ...b]);
export const setDeltaDiff = <T> (a: Set<T>, b: Set<T>): Set<T> =>
    setUnion(setDifference(a, b), setDifference(b, a));
// #endregion

export const flat = <T>(arr: T[][]): T[] => [].concat(...arr);
@Injectable({
    providedIn: 'root'
})
export class UtilsService
{
    public static toFirstUpperCase = (str: string) => str.substring(0, 1).toUpperCase() + str.substring(1);
    public static isNullOrEmpty = (str: string): boolean => str === '' || str === undefined || str === null;
    public static isNullOrUndefined = <T> (object: T | undefined | null): boolean =>
        <T>object === undefined || <T>object === null;
    public static isNonEmptyArray = <T>(object: T[]): boolean =>
        Array.isArray(object) && object.length !== 0;
    public static areArraysEqual = <T>(array1: T[], array2: T[]): boolean =>
        array1.length === array2.length
        && array1.every((elt: T): boolean => array2.includes(elt));

    public static hash (name: string)
    {
        let hash = 0;
        for (let i: number = 0; i < name.length; i++)
        {
            // tslint:disable-next-line: no-bitwise
            hash = ~~(((hash << 5) - hash) + name.charCodeAt(i));
        }

        return Math.abs(hash).toString();
    }
    // const groupBy = <T, K extends keyof any> (list: T[], getKey: (item: T) => K) =>
    //     list.reduce((previous: Record<K, T[]>, currentItem: T) =>
    //     {
    //         const group = getKey(currentItem);

    //         previous[group] = previous[group] || [];
    //         previous[group].push(currentItem);

    //         return previous;
    //     }, {} as Record<K, T[]>);

    public static groupBy = <T> (list: T[], getKey: (item: T) => string | number) =>
        list.reduce(
            (previous: Map<string | number, T[]>, currentItem: T) =>
            {
                const group = getKey(currentItem);

                if (previous.has(group))
                {
                    previous.get(group).push(currentItem);
                }
                else
                {
                    previous.set(group, [currentItem]);
                }

                return previous;
            }, new Map<string | number, T[]>()
        );

    public static groupByString = <T> (list: T[], getKey: (item: T) => string) =>
        list.reduce(
            (previous: Map<string, T[]>, currentItem: T) =>
            {
                const group = getKey(currentItem);

                if (previous.has(group))
                {
                    previous.get(group).push(currentItem);
                }
                else
                {
                    previous.set(group, [currentItem]);
                }

                return previous;
            }, new Map<string, T[]>()
        );

    public static groupByTyped = <U, T> (list: T[], getKey: (item: T) => U): Map<U, T[]> =>
        list.reduce(
            (previous: Map<U, T[]>, currentItem: T): Map<U, T[]> =>
            {
                const group = getKey(currentItem);

                if (previous.has(group))
                {
                    previous.get(group).push(currentItem);
                }
                else
                {
                    previous.set(group, [currentItem]);
                }

                return previous;
            }, new Map<U, T[]>()
        );

    // #region Méthodes de gestion des images et icônes d'OS
    public static getOsLabel = (os: string, defaultLabel: string = ''): string =>
    {
        if (os)
        {
            const extractedOs = os.match(/(win\w{4})|([a-z]+)/g)[0];

            if (OsPictures.has(extractedOs))
            {
                return OsPictures.get(extractedOs);
            }

            return defaultLabel;
        }

        return defaultLabel;
    };

    public static isKeyPairRequired = (osLabel: string): boolean =>
    {
        return ['windows', 'win2k12', 'win2k16', 'win2k19'].indexOf(osLabel) === -1;
    };

    public static getFullOsPicture = (osLabel: string, size: number = 48): string =>
        `${OSIconPath}/${size}/${osLabel ? osLabel : 'default'}.png`;

    public static getOsFaIcon = (os: string): OsIcon =>
    {
        const osKey = UtilsService.getOsLabel(os, 'linux');

        if (OsFaIcons.has(osKey))
        {
            return OsFaIcons.get(osKey);
        }

        return OsFaIcons.get('linux');
    };

    public static serialize = <T> (obj: T) => JSON.stringify(obj);
    public static deserialize = <T> (str: string) => <T>JSON.parse(str);


    public static mapperFromObject = <T> (source: any): T =>
    {
        const target = {} as T;

        Object.assign(target, source);

        return target;
    };
    public static generateGuid = (): string =>
        'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c)
            {
                const r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });

    public static actionIdentifier: (moduleKey: string, key: string) => string = (moduleKey: string, key: string): string =>
        `${moduleKey} | ${key}`;
    // #endregion
}

/**
 * Force Component to be remonted after params route change
 */
export const overrideRouterReuseStrategy = (router: Router): Router =>
{
    // override the route reuse strategy
    router.routeReuseStrategy.shouldReuseRoute = () => false;

    return router;
};

export const filterQuickSearch = <T>(
    collection: T[],
    lowerCasedQsWord: string,
    fields: Array<keyof T>,
    customFilterFn?: (item: T) => boolean
): T[] =>
{
    const filteredItems = collection
        .filter((obj: T): boolean =>
            fields.some((field: keyof T): boolean =>
                `${obj[field]}`.toLowerCase().includes(lowerCasedQsWord)
            )
        ) as T[];

    return customFilterFn ?
        Array.from(new Set(
            [
                ...filteredItems,
                ...collection.filter((o: T): boolean => customFilterFn(o))
            ])) :
        filteredItems;
};

export const filterQuickSearchWithTags = <T extends Assetify>(
    collection: T[],
    lowerCasedQsWord: string,
    fields: Array<keyof T>,
    filterTags: FilterTag[],
    customFilterFn?: (item: T) => boolean
): T[] =>
{
    filterTags.forEach((filterTag: FilterTag) =>
    {
        switch (filterTag.tagId)
        {
            case CmdbConstants.NoneTagId:
                filterTag.isTaggedBy = (tagIds: string[]): boolean =>
                    tagIds.length === 0;
                break;

            case CmdbConstants.AnyTagId:
                filterTag.isTaggedBy = (tagIds: string[]): boolean =>
                    tagIds.length > 0;
                break;

            default:
                filterTag.isTaggedBy = (tagIds: string[]): boolean =>
                    filterTag.enabled
                        ? filterTag.included ? tagIds.includes(filterTag.tagId) : !tagIds.includes(filterTag.tagId)
                        : true;
                break;
        }
    });

    let filteredItems = collection
        .filter((item: T): boolean =>
            fields.some((field: keyof T): boolean =>
                `${item[field]}`.toLowerCase().includes(lowerCasedQsWord)
            )
        )
        .filter((item: T): boolean =>
            filterTags
                .every((filterTag: FilterTag): boolean =>
                    filterTag.isTaggedBy(item.tagIds)
                )
        ) as T[];

    if (!UtilsService.isNullOrUndefined(customFilterFn))
    {
        filteredItems = filteredItems.filter((item: T): boolean => customFilterFn(item));
    }

    return filteredItems;
};
