import { Component, Input } from '@angular/core';

@Component({
    selector: 'aa-icon-circle',
    templateUrl: './icon-circle.component.html',
    styleUrls: [
        './icon-circle.component.scss'
    ]
})
export class IconCircleComponent
{
    @Input() faIcon: string;
    @Input() size: string = 'fa-1x';
}
