import { CustomPropertiesApi } from '../../../api/models';

export class CustomProperties
{
    'source_file': string;
    builddate: string;
    'os-icon': string;
    version: string;
    os: string;

    public static mapperFromApi (source: CustomPropertiesApi): CustomProperties
    {
        const target = new CustomProperties();
        Object.assign(target, source);

        return target;
    }
}
