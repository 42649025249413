import { SearchResult } from './search-result';
import { Server } from '@apps/bckp/domain/models';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { TypePopin } from '@apps/app.constants';
import { OpenPopinOriginator } from '@common/enum/open-popin-originator.enum';

export class BckpServerSearchResult extends SearchResult<Server>
{
    constructor (server: Server)
    {
        super('bckp-server', server.documentId);

        this.result = server;
        this.icon = 'fal fa-server';

        this.statusText = server.scheduleType.toUpperCase();
        this.statusClass = this.statusText === 'INCR' ? 'incr' : 'full';

        this.link = {
            baseUrlFragment: BckpConstants.ServersListPath,
            outlet: TypePopin.DETAILS,
            params: [OpenPopinOriginator.ROOT_SEARCH, server.serverShort]
        };
    }
}
