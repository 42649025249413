import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { LanguageService } from '.';

declare const window: {
    [key: string]: any;
    prototype: Window;
    new(): Window;
};

interface RCUserInfo
{
    id: string;
    name: string;
    email: string;
    lang?: string;
}

interface RCTheme
{
    color: string;
    fontColor: string;
    iconColor: string;
}

@Injectable({
    providedIn: 'root'
})
export class RocketChatWidgetApiService
{
    constructor (
        private languageService: LanguageService,
        private translateService: TranslateService
    )
    {
        try
        {
            if (typeof window.RocketChat === 'undefined')
            {
                console.warn('RocketChat has not yet been initialized! (Did you forget to inject it?)');
            }
        }
        catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    setCustomField (fieldName: string, value: string): void
    {
        try
        {
            window.RocketChat(function ()
            {
                this.setCustomField(fieldName, value);
            });
        }
        catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    setUserDepartment (department = 'R&D')
    {
        try
        {
            window.RocketChat(function ()
            {
                this.setDepartment(department);
            });
        }
        catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    setUserBasicInfo (userInfo: RCUserInfo): void
    {
        try
        {
            const lang = userInfo.lang ? userInfo.lang.substring(0, 2) : this.languageService.language.substring(0, 2);
            window.RocketChat(function ()
            {
                this.setLanguage(lang);
                this.setGuestToken(userInfo.id);
                this.setGuestName(userInfo.name);
                this.setGuestEmail(userInfo.email);
            });
        }
        catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    setDefaultTitle (langWasLoaded = false): void
    {
        try
        {
            const { defaultTitle, defaultOfflineTitle } = this.getRCWidgetTitle(langWasLoaded);

            window.RocketChat(function ()
            {
                this.setTheme({
                    title: defaultTitle, // default widget title when the status of service is online
                    offlineTitle: defaultOfflineTitle, // default widget title when the status of service is online
                });
            });
        }
        catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    setTheme (theme = 'light', langWasLoaded = false)
    {
        const rcTheme: RCTheme = {} as RCTheme;
        const { defaultTitle, defaultOfflineTitle } = this.getRCWidgetTitle(langWasLoaded);

        switch (theme)
        {
            case 'light':
                rcTheme.color = '#0057ff';
                rcTheme.iconColor = '#FFFFFF';
                break;
            case 'prune':
                rcTheme.color = '#ffb906';
                rcTheme.iconColor = '#FFFFFF';
                break;
            default:
                break;
        }

        try
        {
            window.RocketChat(function ()
            {
                this.setTheme({
                    color: rcTheme.color, // widget title background color
                    fontColor: '#FFFFFF', // widget title font color
                    iconColor: rcTheme.iconColor, // widget icon color
                    title: defaultTitle, // default widget title when the status of service is online
                    offlineTitle: defaultOfflineTitle, // default widget title when the status of service is online
                });
            });
        }
        catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    private getRCWidgetTitle (langWasLoaded: boolean)
    {
        const defaultTitle = langWasLoaded ?
            this.translateService.instant('ROCKETCHAT.DEFAULTTITLE') :
            'Do you have questions ?';
        const defaultOfflineTitle = langWasLoaded ?
            this.translateService.instant('ROCKETCHAT.DEFAULTOFFLINETITLE') :
            'AntemetA';

        return { defaultTitle, defaultOfflineTitle };
    }
}
