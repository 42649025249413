import { DateTime } from 'luxon';

import { RequestsSummaryCounterApi, RequestsUrgencyCounterApi } from '@apps/its2/api/models';
import { Its2Constants } from '@apps/its2/its2.constants';

export class RequestsUrgencyCounter
{
    urgencyId: number;
    urgencyTradKey: string;
    count: number;
    percent: number;

    public static mapperFromApi = (source: RequestsUrgencyCounterApi): RequestsUrgencyCounter =>
    {
        return {
            urgencyId: source.urgencyId,
            urgencyTradKey: Its2Constants.getUrgencyTradKey(source.urgencyId),
            count: source.count,
            percent: source.percent
        } as RequestsUrgencyCounter;
    };
}

export class RequestsSummaryCounter
{
    incidents: RequestsUrgencyCounter[];
    services: RequestsUrgencyCounter[];

    constructor (incidentsSummaryCounter?: RequestsSummaryCounter, servicesSummaryCounter?: RequestsSummaryCounter)
    {
        this.incidents = incidentsSummaryCounter?.incidents;
        this.services = servicesSummaryCounter?.services;
    }

    public static mapperFromApi = (source: RequestsSummaryCounterApi): RequestsSummaryCounter =>
    {
        const target = new RequestsSummaryCounter();

        target.incidents = source.incidents.map(RequestsUrgencyCounter.mapperFromApi);
        target.services = source.requests.map(RequestsUrgencyCounter.mapperFromApi);

        return target;
    };
}

export class DashRequestsSummaryCounter extends RequestsSummaryCounter
{
    public incidentsIsEmpty: boolean = false;
    public servicesIsEmpty: boolean = false;
    public isLoading: boolean = true;
    public incidentsIsLoaded: boolean = false;
    public servicesIsLoaded: boolean = false;
    public isOups: boolean = false;
    public expirationDate?: DateTime;
}
