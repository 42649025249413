export class QueryString
{
    private values: { key: string, value: any }[] = [];
    get qs ()
    {
        return this.getQueryString();
    }

    public pushValue (key: string, value: any)
    {
        this.values.push({ key: key, value: value });
    }

    private getQueryString ()
    {
        const queries: string[] = [];

        let qs: string = '?';

        this.values
            .forEach((kv: { key: string, value: any }) =>
            {
                queries.push(`${kv.key}=${kv.value}`);
            });

        qs += queries.join('&');

        return qs;
    }
}
