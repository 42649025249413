import { Injectable } from '@angular/core';
import { HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { arrayMapper } from '@common/operators';
import { AppliRole } from '@apps/back/models';

import { EntitiesMiscApiService } from '../api/entities-misc-api.service';
import { Counters, DashCounters } from './models/back/dash-counters';
import { DashCountersApi } from '../api/models/back/dash-counters.api';
import { ApplicationDefaultRole, Entity } from './models/back/entity';
import { EntityApi } from '../api/models/back/entity-api';
import { User, DomainUser } from './models/back/user';
import { Site } from '../../priv/models/site';
import { UploadFileState } from '@common/domain/models/upload-file-state';
import { MediaTypes } from '@common/services';

@Injectable({
    providedIn: 'root'
})
export class EntitiesService extends BaseMiscDomainService
{
    constructor (
        private entitiesMiscServiceApi: EntitiesMiscApiService
    )
    {
        super();
    }

    getCounters (): Observable<DashCounters>
    {
        return this.entitiesMiscServiceApi.getCounters()
            .pipe(
                map((dca: DashCountersApi) =>
                    new DashCounters(
                        new Counters(dca.external.total, dca.external.active),
                        new Counters(dca.internal.total, dca.internal.active)
                    )
                )
            );
    }

    get (entityId: string): Observable<Entity>
    {
        return this.entitiesMiscServiceApi.retrieve(entityId)
            .pipe(
                map((entity: EntityApi) =>
                    Entity.mapperFromApi(entity)
                )
            );
    }

    getUsers (entityId: string): Observable<User[]>
    {
        return this.entitiesMiscServiceApi.getUsers(entityId)
            .pipe(
                arrayMapper(DomainUser.mapperFromApi)
            );
    }

    updateIdentity (entity: Entity): Observable<Entity>
    {
        return this.entitiesMiscServiceApi.updateIdentity(entity)
            .pipe(
                map(Entity.mapperFromApi)
            );
    }

    getReferents (entityId: string): Observable<User[]>
    {
        return this.entitiesMiscServiceApi.getReferents(entityId)
            .pipe(
                arrayMapper(DomainUser.mapperFromApi)
            );
    }

    getSites (entityId: string): Observable<Site[]>
    {
        return this.entitiesMiscServiceApi.getSites(entityId)
            .pipe(
                arrayMapper(Site.mapperFromApi)
            );
    }

    // getSdmAlias (entityId: string): Observable<string>
    // {
    //     //Tenter avec le nom de l'entité si l'alias n'a pas été saisie
    //     return this.entitiesMiscServiceApi.getSdmAlias(entityId)
    //         .pipe(
    //             switchMap(alias =>
    //                 iif(
    //                     () => !!alias,
    //                     of(alias),
    //                     this.store.select(getProfile)
    //                         .pipe(
    //                             withLatestFrom(this.store.select(getRootImpersonation)),
    //                             map(([profile, impersonation]) =>
    //                                 impersonation !== null ? impersonation.entityName : profile.entityName
    //                             )
    //                         )
    //                 )
    //             )
    //         );
    // }

    // getEntityErpShortId (entityId: string): Observable<string>
    // {
    //     return this.entitiesMiscServiceApi.getEntityErpShortId(entityId);
    // }

    replaceAllHabilitations (entityId: string, appRoles: AppliRole[]): Observable<Entity>
    {
        return this.entitiesMiscServiceApi
            .updateHabs(entityId, appRoles.map(ar => new ApplicationDefaultRole(ar.application, AppliRole.RoleByLevel(ar.role))))
            .pipe(
                map(Entity.mapperFromApi)
            );
    }

    addHabilitations (entityId: string, appRoles: AppliRole[]): Observable<Entity>
    {
        return this.entitiesMiscServiceApi
            .addHabilitations(entityId, appRoles.map(ar => new ApplicationDefaultRole(ar.application, AppliRole.RoleByLevel(ar.role))))
            .pipe(
                map(Entity.mapperFromApi)
            );
    }

    removeHabilitations (entityId: string, appRoles: AppliRole[]): Observable<Entity>
    {
        return this.entitiesMiscServiceApi
            .removeHabilitations(entityId, appRoles.map(ar => new ApplicationDefaultRole(ar.application, AppliRole.RoleByLevel(ar.role))))
            .pipe(
                map(Entity.mapperFromApi)
            );
    }

    uploadSdmAlias (file: File): Observable<UploadFileState>
    {
        return this.entitiesMiscServiceApi.uploadSdmAlias(file);
    }

    export (qs: string, format: MediaTypes): Observable<HttpEvent<Blob>>
    {
        return this.entitiesMiscServiceApi.export(qs, format);
    }

    exportEntityUsers (entityId: string, format: MediaTypes): Observable<HttpEvent<Blob>>
    {
        return this.entitiesMiscServiceApi.exportEntityUsers(entityId, format);
    }
}
