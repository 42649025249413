import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { AppConstants } from '@apps/app.constants';
import { RequestActionApi, RequestActionDetailsApi, RequestApi, RequestDocumentApi, RequestCountersApi, RequestsSummaryCounterApi, RequestsCatalogCounterApi, RequestUrgencyCounterByDayApi } from './models';
import { Its2Constants, RequestTypeEnum } from '@apps/its2/its2.constants';
import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { MediaTypes } from '@common/services';
import { UploadFileState } from '@common/domain/models/upload-file-state';
import { RequestValidationParameters } from '@apps/its2/models';

import { CreateRequestViewModel } from '../models/create-request-view-model';

@Injectable({
    providedIn: 'root'
})
export class RequestMiscApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlIts2;
        this.segmentApi = `/${Its2Constants.BaseApi}/requests`;
    }

    getWarnings (): Observable<number>
    {
        return this.get<number>('/warnings');
    }

    getCounters (): Observable<RequestCountersApi>
    {
        return this.get<RequestCountersApi>('/counters');
    }

    getDetails (rfcNumber: string): Observable<RequestApi>
    {
        // if (AppConstants.IsDebugStage && rfcNumber === 'S20210610_00004')
        // {
        //     const requestApi = {
        //         'requestId': 2582,
        //         'rfcNumber': 'S20210610_00004',
        //         'type': 'S',
        //         'submitDateUtc': '2021-06-10T15:21:59',
        //         'endDateUtc': null,
        //         'maxResolutionDateUtc': '2021-06-15T14:51:59',
        //         'title': '\uD83D\uDC7E\uD83D\uDC64 Buddha',
        //         'catalogPathFR': 'Backup #BCS/Stratégie de sauvegarde/Création',
        //         'catalogPathEN': 'Backup #BCS/Backup strategy/Creation',
        //         'comment': '<i>Friendship is an arrangement by which we undertake to exchange small favors for big ones.</i>',
        //         'description': null,
        //         'shortComment': 'Friendship is an arrangement by which we undertake to exchange small favors for big ones.',
        //         'urgencyId': 2,
        //         'statusId': RequestStatusIdEnum.SOLVED,
        //         'catalogId': null,
        //         'recipientName': 'Daniel LUCAZEAU',
        //         'recipientEmail': 'Daniel.LUCAZEAU@antemeta.fr',
        //         'recipientIsMe': true,
        //         'requestorName': 'Frederic CHAVEROT',
        //         'requestorEmail': 'Frederic.CHAVEROT@antemeta.fr',
        //         'statusFR': 'Résolu',
        //         'statusEN': 'Solved',
        //         'statusMyAntemetAGuid': WaitingStatus.WaitingValidation,
        //         'urgencyFR': 'Moyenne',
        //         'urgencyEN': 'Medium',
        //         'resolution': null,
        //         'satisfaction': null
        //     } as RequestApi;

        //     return of(requestApi);
        // }

        return this.get<RequestApi>(`/${rfcNumber}`);
    }

    getActions (rfcNumber: string): Observable<RequestActionApi[]>
    {
        return this.get<RequestActionApi[]>(`/${rfcNumber}/actions`);
    }

    getActionDetails (rfcNumber: string, actionId: number): Observable<RequestActionDetailsApi>
    {
        return this.get<RequestActionDetailsApi>(`/${rfcNumber}/actions/${actionId}`);
    }

    createAction (rfcNumber: string, description: string)
    {
        return this.post<string>(`/${rfcNumber}/actions`, { description: description });
    }

    getDocuments (rfcNumber: string): Observable<RequestDocumentApi[]>
    {
        return this.get<RequestDocumentApi[]>(`/${rfcNumber}/documents`);
    }

    getDocument (rfcNumber: string, physicalName: string): Observable<any>
    {
        return this.getFromBlob(`/${rfcNumber}/documents/${physicalName}`);
    }

    export (qs: string, format: MediaTypes): Observable<HttpEvent<Blob>>
    {
        return this.download(`/export${qs}`, format);
    }

    createRequest (createCase: CreateRequestViewModel): Observable<RequestApi>
    {
        return this.post<RequestApi>('', createCase);
    }

    postQuestion (rfcNumber: string, questionId: string | number, result: string): Observable<unknown>
    {
        return this.post(`/${rfcNumber}/questions/${questionId}`, { result });
    }

    uploadDocument (rfcNumber: string, document: File): Observable<UploadFileState>
    {
        return this.upload(`/${rfcNumber}/documents`, document);
    }

    validate (rfcNumber: string, rvp: RequestValidationParameters): Observable<unknown>
    {
        if (AppConstants.IsDebugStage && rfcNumber === 'S20210610_00004')
        {
            return of(true);
        }

        return this.post(`/${rfcNumber}/validate`, rvp);
    }

    getSummary (catalogGuid: string, openOnly?: boolean): Observable<RequestsSummaryCounterApi>
    {
        if (openOnly === true)
        {
            return this.get<RequestsSummaryCounterApi>(`/summary?catalogGuid=${catalogGuid}&openOnly=true`);
        }

        return this.get<RequestsSummaryCounterApi>(`/summary?catalogGuid=${catalogGuid}`);
    }

    getSLAIncidentCounters (qs: string): Observable<number>
    {
        if (AppConstants.IsDataLocal('its2'))
        {
            return of(98.0);
        }

        return this.get<number>(`/incident/sla${qs}`);
    }

    getSLAServiceCounters (qs: string): Observable<number>
    {
        if (AppConstants.IsDataLocal('its2'))
        {
            return of(66.0);
        }

        return this.get<number>(`/service/sla${qs}`);
    }

    getSummaryCounters (type: RequestTypeEnum, qs: string): Observable<RequestsCatalogCounterApi[]>
    {
        if (AppConstants.IsDataLocal('its2'))
        {
            return this.http.get<RequestsCatalogCounterApi[]>(`assets/data/its2/summary.${type}.json${qs}`);
        }

        const segment = type === RequestTypeEnum.SERVICES ? 'service' : 'incident';

        return this.get<RequestsCatalogCounterApi[]>(`/${segment}/summary${qs}`);
    }

    getEvolutionCounters (type: RequestTypeEnum, qs: string): Observable<RequestUrgencyCounterByDayApi[]>
    {
        if (AppConstants.IsDataLocal('its2'))
        {
            return this.http.get<RequestUrgencyCounterByDayApi[]>(`assets/data/its2/evolution.${type}.json${qs}`);
        }

        const segment = type === RequestTypeEnum.SERVICES ? 'service' : 'incident';

        return this.get<RequestUrgencyCounterByDayApi[]>(`/${segment}/history${qs}`);
    }
}
