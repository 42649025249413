import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

// * Debug des routes en DEV uniquement
// * Passer la valeur suivante à true pour cela
const devEnableTracing = false;

const appRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'admin',
                loadChildren: (): Promise<any> => import('./admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: '',
                loadChildren: (): Promise<any> => import('./customer/customer.module').then(m => m.CustomerModule),
            }
        ]
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        data: {
            title: 'Not Found'
        }
    }
];

export const AppRoutingModule = RouterModule.forRoot(
    appRoutes,
    {
        paramsInheritanceStrategy: 'always',
        // ! Ne pas modifier le code ci-dessous
        enableTracing: devEnableTracing
        // ! Fin de l'alerte
        ,
        relativeLinkResolution: 'legacy',
        onSameUrlNavigation: 'reload'
    }
);
