import { createAction, props } from '@ngrx/store';

import { CatalogEntry } from '@apps/its2/models';
import { CatalogEntryQuestion } from '@apps/its2/domain/models';

// #region Catalogue des incidents
export const Its2CatalogsIncidentRequested = createAction(
    '[ITS2-CATALOGS] Its2 Catalogs Incident Requested'
);
export const Its2CatalogsIncidentSucceeded = createAction(
    '[ITS2-CATALOGS] Its2 Catalogs Incident Succeeded',
    props<{ catalogEntries: CatalogEntry[] }>()
);
export const Its2CatalogsIncidentFailed = createAction(
    '[ITS2-CATALOGS] Its2 Catalogs Incident Failed',
    props<{ error: Error }>()
);
// #endregion

// #region Catalogue des services
export const Its2CatalogsServiceRequested = createAction(
    '[ITS2-CATALOGS] Its2 Catalogs Service Requested'
);
export const Its2CatalogsServiceSucceeded = createAction(
    '[ITS2-CATALOGS] Its2 Catalogs Service Succeeded',
    props<{ catalogEntries: CatalogEntry[] }>()
);
export const Its2CatalogsServiceFailed = createAction(
    '[ITS2-CATALOGS] Its2 Catalogs Service Failed',
    props<{ error: Error }>()
);
export const Its2CatalogsServiceQuestionsRequested = createAction(
    '[ITS2-CATALOGS] Its2 Catalogs Service Questions Requested',
    props<{ catalogGuid: string }>()
);
export const Its2CatalogsServiceQuestionsSucceeded = createAction(
    '[ITS2-CATALOGS] Its2 Catalogs Service Questions Succeeded',
    props<{ catalogGuid: string, questions: CatalogEntryQuestion[] }>()
);
export const Its2CatalogsServiceQuestionsFailed = createAction(
    '[ITS2-CATALOGS] Its2 Catalogs Service Questions Failed',
    props<{ error: Error }>()
);
// #endregion
