import { Injectable } from '@angular/core';
import { HttpEvent } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, Observable, of, iif } from 'rxjs';
import { switchMap, map, catchError, tap, withLatestFrom } from 'rxjs/operators';

import { RequestListDomainService } from '@apps/its2/domain/request-list-domain.service';
import { RequestMiscDomainService } from '@apps/its2/domain/request-misc-domain.service';
import { RequestStatusesDomainService } from '@apps/its2/domain/request-statuses-domain.service';
import { IAuroraState } from '@apps/aurora.state';
import * as fromIts2RequestsActions from '@apps/its2/store/requests/requests.actions';
import * as fromAuthStore from '@apps/auth/store';
import { CreateRequestViewModel } from '@apps/its2/models/create-request-view-model';
import { NotifType } from '@common/enum';
import { NotificationService, NotfGravity, MediaTypes, FilesService } from '@common/services';
import { ResultDomain } from '@common/domain/result-domain';
import { Profile } from '@apps/auth/models';
import { Answer, CreateService } from '@apps/its2/models/create-service';

import { Request, RequestCounters, RequestStatuses } from '../../domain/models';

@Injectable()
export class Its2RequestsEffects
{
    constructor (
        private actions: Actions,
        private requestListDomainService: RequestListDomainService,
        private requestMiscDomainService: RequestMiscDomainService,
        private requestStatusesDomainService: RequestStatusesDomainService,
        private notificationService: NotificationService,
        private filesService: FilesService,
        private store: Store<IAuroraState>
    )
    {
    }

    its2RequestsODataFilterRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsODataFilterRequested),
                    switchMap((params: any) =>
                        this.requestListDomainService.getRequests(params.queryString)
                            .pipe(
                                switchMap((result: ResultDomain<Request>) =>
                                    [
                                        fromIts2RequestsActions.Its2RequestsODataFilterSucceeded({ result }),
                                        fromIts2RequestsActions.Its2RequestsStatusesRequested()
                                    ]
                                ),
                                catchError((error: Error) =>
                                    of(fromIts2RequestsActions.Its2RequestsODataFilterFailed({ error }))
                                )
                            )
                    )
                )
    );

    // its2RequestsODataFilterSucceeded = createEffect(
    //     () =>
    //         this.actions
    //             .pipe(
    //                 ofType(fromIts2RequestsActions.Its2RequestsODataFilterSucceeded),
    //                 tap(() =>
    //                 {
    //                     this.notificationService.notify(
    //                         [
    //                             'ITSM.LIST.ODATA.FILTER.TITLE',
    //                             'ITSM.LIST.ODATA.FILTER.SUCCEEDED'
    //                         ],
    //                         NotfGravity.success,
    //                         NotifType.SNACKBAR
    //                     );
    //                 })
    //             ),
    //     { dispatch: false }
    // );

    its2RequestsODataFilterFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsODataFilterFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'ITSM.LIST.ODATA.FILTER.TITLE',
                                'ITSM.LIST.ODATA.FILTER.FAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    its2RequestsODataSkipRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsODataSkipRequested),
                    switchMap((params: any) =>
                        this.requestListDomainService.getRequests(params.queryString)
                            .pipe(
                                switchMap((result: ResultDomain<Request>) =>
                                    [
                                        fromIts2RequestsActions.Its2RequestsODataSkipSucceeded({ result })
                                    ]
                                ),
                                catchError((error: Error) =>
                                    of(fromIts2RequestsActions.Its2RequestsODataSkipFailed({ error }))
                                )
                            )
                    )
                )
    );

    // its2RequestsODataSkipSucceeded = createEffect(
    //     () =>
    //         this.actions
    //             .pipe(
    //                 ofType(fromIts2RequestsActions.Its2RequestsODataSkipSucceeded),
    //                 tap(() =>
    //                 {
    //                     this.notificationService.notify(
    //                         [
    //                             'ITSM.LIST.ODATA.SKIP.TITLE',
    //                             'ITSM.LIST.ODATA.SKIP.SUCCEEDED'
    //                         ],
    //                         NotfGravity.success,
    //                         NotifType.SNACKBAR
    //                     );
    //                 })
    //             ),
    //     { dispatch: false }
    // );

    its2RequestsODataSkipFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsODataSkipFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'ITSM.LIST.ODATA.SKIP.TITLE',
                                'ITSM.LIST.ODATA.SKIP.FAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    its2RequestsCountersRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsCountersRequested),
                    switchMap(() =>
                        this.requestMiscDomainService.getCounters()
                            .pipe(
                                map((requestCounters: RequestCounters) =>
                                    fromIts2RequestsActions.Its2RequestsCountersSucceeded({ requestCounters })
                                ),
                                catchError((error: Error) =>
                                    of(fromIts2RequestsActions.Its2RequestsODataFilterFailed({ error }))
                                )
                            )
                    )
                )
    );

    its2RequestsSelectedRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsSelectedRequested),
                    switchMap(({ rfcNumber }) =>
                        this.requestMiscDomainService.getDetails(rfcNumber)
                            .pipe(
                                map((request: Request) =>
                                    fromIts2RequestsActions.Its2RequestsSelectedSucceeded({ selected: request })
                                ),
                                catchError((error: Error) =>
                                    of(fromIts2RequestsActions.Its2RequestsSelectedFailed({ error }))
                                )
                            )
                    )
                )
    );

    its2RequestsStatusesRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsStatusesRequested),
                    switchMap(() =>
                        this.requestStatusesDomainService.retrieveStatuses()
                            .pipe(
                                switchMap((result: RequestStatuses) =>
                                    [
                                        fromIts2RequestsActions.Its2RequestsFavoritesStatusUpdate({
                                            rfcNumbers: result && result.favorites ? result.favorites : []
                                        }),
                                        fromIts2RequestsActions.Its2RequestsReadStatusUpdate({
                                            rfcNumbers: result && result.read ? result.read : []
                                        })
                                    ]
                                ),
                                catchError((error: Error) =>
                                    of(fromIts2RequestsActions.Its2RequestsODataFilterFailed({ error }))
                                )
                            )
                    )
                )
    );

    its2RequestsCreateCaseRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsCreateCaseRequested),
                    switchMap(({ createCase }: { createCase: CreateRequestViewModel}) =>
                        this.requestMiscDomainService.createRequest(createCase)
                            .pipe(
                                map((request: Request) =>
                                    fromIts2RequestsActions.Its2RequestsCreateCaseSucceeded({ request: request })
                                ),
                                catchError((error: Error) =>
                                    of(fromIts2RequestsActions.Its2RequestsCreateCaseFailed({ error }))
                                )
                            )
                    )
                )
    );

    its2RequestsCreateCaseSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsCreateCaseSucceeded),
                    map(({ request }: { request: Request }) =>
                    {
                        this.notificationService.notify(
                            [
                                'ITSM.CREATECASEPOPIN.NOTIF.TITLE',
                                'ITSM.CREATECASEPOPIN.NOTIF.SUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );

                        return fromIts2RequestsActions.Its2RequestsFilesUploadRequested({ rfcNumber: request.rfcNumber });
                    })
                )
    );

    its2RequestsCreateCaseFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsCreateCaseFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'ITSM.CREATECASEPOPIN.NOTIF.TITLE',
                                'ITSM.CREATECASEPOPIN.NOTIF.ERROR'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    its2RequestsCreateServiceRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsCreateServiceRequested),
                    switchMap(({ createService }: { createService: CreateService;}) =>
                    {
                        const crvm: CreateRequestViewModel = {
                            catalogGuid: createService.step1.catalogEntry.catalogGuid,
                            urgencyId: createService.step1.urgency,
                            title: createService.step1.title,
                            description: createService.step3.message
                        };

                        return this.requestMiscDomainService.createRequest(crvm)
                            .pipe(
                                map((request: Request) =>
                                    fromIts2RequestsActions.Its2RequestsCreateServiceCreated({ request, createService })
                                ),
                                catchError((error: Error) =>
                                    of(fromIts2RequestsActions.Its2RequestsCreateServiceFailed({ error }))
                                )
                            );
                    })
                )
    );

    its2RequestsCreateServiceCreated = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsCreateServiceCreated),
                    tap(({ request }: { request: Request; createService: CreateService; }) =>
                        this.store.dispatch(fromIts2RequestsActions.Its2RequestsFilesUploadRequested({ rfcNumber: request.rfcNumber }))
                    ),
                    switchMap(({ request, createService }: { request: Request; createService: CreateService; }) =>
                        iif(
                            () => createService.step2.answers.length !== 0,
                            of(true)
                                .pipe(
                                    switchMap(() =>
                                    {
                                        const results: Observable<unknown>[] = [];

                                        createService.step2.answers.forEach((answer: Answer) =>
                                        {
                                            results.push(this.requestMiscDomainService.postQuestion(request.rfcNumber, answer));
                                        });

                                        return forkJoin(results)
                                            .pipe(
                                                map(() =>
                                                    fromIts2RequestsActions.Its2RequestsCreateServiceSucceeded({ request })
                                                )
                                            );
                                    })
                                ),
                            of(false)
                                .pipe(
                                    map(() =>
                                        fromIts2RequestsActions.Its2RequestsCreateServiceSucceeded({ request })
                                    )
                                )
                        )
                    )
                )
    );

    its2RequestsCreateServiceSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsCreateServiceSucceeded),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                'ITSM.CREATECASEPOPIN.NOTIF.TITLE',
                                'ITSM.CREATECASEPOPIN.NOTIF.SUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );

                        // return fromIts2RequestsActions.Its2RequestsFilesUploadRequested({ rfcNumber: request.rfcNumber });
                    })
                ),
        { dispatch: false }
    );

    its2RequestsCreateServiceFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsCreateServiceFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'ITSM.CREATECASEPOPIN.NOTIF.TITLE',
                                'ITSM.CREATECASEPOPIN.NOTIF.ERROR'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    // its2RequestsFilterChanged = createEffect(
    //     () =>
    //         this.actions
    //             .pipe(
    //                 ofType(fromIts2RequestsActions.Its2RequestsFilterChanged),
    //                 tap(() =>
    //                     this.requestFilterService.applyFilter()
    //                 )
    //             ),
    //     { dispatch: false }
    // );

    its2RequestsExportRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsExportRequested),
                    switchMap(({ qs, format }: {qs: string, format: MediaTypes}) =>
                        this.requestMiscDomainService.export(qs, format)
                            .pipe(
                                map((response) =>
                                    fromIts2RequestsActions.Its2RequestsExportSucceeded({ response })
                                ),
                                catchError((failure: any) =>
                                    of(fromIts2RequestsActions.Its2RequestsExportFailed(failure))
                                ))
                    )
                )
    );

    itsmRequestExportSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2RequestsActions.Its2RequestsExportSucceeded),
                    withLatestFrom(this.store.select(fromAuthStore.getProfile)),
                    tap(([params, profile]: [{ response: HttpEvent<Blob>; }, Profile]) =>
                    {
                        this.filesService.saveFileFromApi(params.response, profile);
                    })
                ),
        { dispatch: false }
    );
}
