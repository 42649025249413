export interface ISearchCategory
{
    key: string;
    icon: string;
    application: string;
    module: string;
}

class SearchConstants
{
    categories: Map<string, ISearchCategory>;
}

export class RootConstants
{
    public static ImpersonateKey: string = 'impersonate';
    public static PollingVersionDelay = 60 * 1000; // 1 minute
    public static PollingVersionInterval = 30 * 60 * 1000; // 30 minutes
    public static SearchConstants: SearchConstants = {
        categories: new Map<string, ISearchCategory>([
            [
                'itsm-request',
                {
                    key: 'itsm-request',
                    application: 'LAYOUT.MENU.ITSM',
                    module: 'LAYOUT.TABTITLE.ITSM.HISTORIC',
                    icon: 'fal fa-concierge-bell'
                }
            ],
            [
                'its2-request',
                {
                    key: 'its2-request',
                    application: 'LAYOUT.MENU.ITSM',
                    module: 'LAYOUT.TABTITLE.ITSM.REQUEST',
                    icon: 'fal fa-concierge-bell'
                }
            ],
            [
                'bckp-job',
                {
                    key: 'bckp-job',
                    application: 'LAYOUT.MENU.BCKP',
                    module: 'LAYOUT.TABTITLE.BCKP.JOBS',
                    icon: 'fal fa-save'
                }
            ],
            [
                'bckp-server',
                {
                    key: 'bckp-server',
                    application: 'LAYOUT.MENU.BCKP',
                    module: 'LAYOUT.TABTITLE.BCKP.SERVERS',
                    icon: 'fal fa-server'
                }
            ],
            [
                'bckp-policy',
                {
                    key: 'bckp-policy',
                    application: 'LAYOUT.MENU.BCKP',
                    module: 'LAYOUT.TABTITLE.BCKP.POLICIES',
                    icon: 'fal fa-shield-alt'
                }
            ],
            [
                'comp-instance',
                {
                    key: 'comp-instance',
                    application: 'LAYOUT.MENU.COMP',
                    module: 'LAYOUT.TABTITLE.COMP.INSTANCE',
                    icon: 'fal fa-cloud'
                }
            ],
            [
                'back-auth-entity',
                {
                    key: 'back-auth-entity',
                    application: 'LAYOUT.MENU.BACK.ADMIN',
                    module: 'LAYOUT.MENU.BACK.ENTITIES',
                    icon: 'fal fa-industry-alt'
                }
            ],
            [
                'back-auth-user',
                {
                    key: 'back-auth-user',
                    application: 'LAYOUT.MENU.BACK.ADMIN',
                    module: 'LAYOUT.MENU.BACK.USERS',
                    icon: 'fal fa-users'
                }
            ]
        ])
    };
}
