// Its2DashReportingIncidentsCountersRequested
import { Injectable } from '@angular/core';

import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as fromReportingActions from './reporting.actions';
import { RequestMiscDomainService } from '@apps/its2/domain';
import { RequestUrgencyCounterByDay } from '@apps/its2/domain/models';
import { RequestTypeEnum } from '@apps/its2/its2.constants';
import { IAuroraState } from '@apps/aurora.state';
import { Its2DashSharedSelectors } from '../..';
import { Its2DashboardFilter } from '@apps/its2/services';

@Injectable({
    providedIn: 'root'
})
export class Its2ReportingsEffects
{
    constructor (
        private actions: Actions,
        private requestMiscDomainService: RequestMiscDomainService,
        private store: Store<IAuroraState>
    )
    {}

    its2DashReportingEvolutionIncidentsCountersRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromReportingActions.Its2DashReportingIncidentsSummariesRequested),
                    withLatestFrom(
                        this.store.select(Its2DashSharedSelectors.getDashSinceUntil),
                        (_, [since, until]) => ([
                            since,
                            until
                        ])
                    ),
                    switchMap(([since, until]: [DateTime, DateTime]) =>
                        this.requestMiscDomainService.getEvolutionCounters(RequestTypeEnum.INCIDENTS, since, until)
                            .pipe(
                                map((summaries: RequestUrgencyCounterByDay[]) =>
                                    fromReportingActions.Its2DashReportingIncidentsSummariesSucceeded({ summaries })
                                ),
                                catchError((err) =>
                                    of(fromReportingActions.Its2DashReportingIncidentsSummariesFailed({ err: { ...err } }))
                                )
                            )
                    )
                )
    );

    its2DashReportingCatalogServicesCountersRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromReportingActions.Its2DashReportingServicesSummariesRequested),
                    withLatestFrom(
                        this.store.select(Its2DashSharedSelectors.getDashSinceUntil),
                        (_, [since, until]) => ([
                            since,
                            until
                        ])
                    ),
                    switchMap(([since, until]: [DateTime, DateTime]) =>
                        this.requestMiscDomainService.getEvolutionCounters(RequestTypeEnum.SERVICES, since, until)
                            .pipe(
                                map((summaries: RequestUrgencyCounterByDay[]) =>
                                    fromReportingActions.Its2DashReportingServicesSummariesSucceeded({ summaries })
                                ),
                                catchError((err: any) =>
                                    of(fromReportingActions.Its2DashReportingServicesSummariesFailed({ err: { ...err } }))
                                )
                            )
                    )
                )
    );
}
