import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, filter, tap, debounceTime } from 'rxjs/operators';

import { FloatingIp } from '@apps/ostk/domain/models/network';
import { getNetworksFipsIsDataValid } from '@apps/ostk/store/ostk.reducer';
import { IAuroraState } from '@apps/aurora.state';
import { FloatingIpMiscDomainService } from '@apps/ostk/domain/network/floating-ip-misc-domain.service';
import { NotfGravity, NotificationService, NotifType } from '@common/services';

import * as fromNetworksFipsActions from './fips.actions';
import * as fromOstkInstancesActions from '../../instances/instances.actions';

@Injectable()
export class OstkNetworksFipsEffects
{
    constructor (
        private actions: Actions,
        private store: Store<IAuroraState>,
        private floatingIpMiscDomainService: FloatingIpMiscDomainService,
        private notificationService: NotificationService
    )
    {
    }

    ostkNetworksFipsListRequested: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(fromNetworksFipsActions.OstkNetworksFipsListRequested),
            withLatestFrom(this.store.select(getNetworksFipsIsDataValid)),
            filter(([, valid]) => !valid),
            switchMap(() =>
                this.floatingIpMiscDomainService.getFloatingIps()
                    .pipe(
                        map((floatingIps: FloatingIp[]) =>
                            fromNetworksFipsActions.OstkNetworksFipsListSucceeded({ floatingIps })
                        ),
                        catchError(err =>
                            of(fromNetworksFipsActions.OstkNetworksFipsListFailed({ err }))
                        )
                    )
            )
        )
    );

    ostkNetworksFipsMapRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromNetworksFipsActions.OstkNetworksFipsMapRequested),
                switchMap(({ id, serverId }: { id: string, serverId: string; }) =>
                    this.floatingIpMiscDomainService.map(id, serverId)
                        .pipe(
                            switchMap((floatingIp: FloatingIp) =>
                                [
                                    fromNetworksFipsActions.OstkNetworksFipsMapSucceded({ floatingIp }),
                                    fromOstkInstancesActions.OstkInstancesExpireDate()
                                ]
                            ),
                            catchError((failure: any) =>
                                of(fromNetworksFipsActions.OstkNetworksFipsMapFailed(failure))
                            )
                        )
                )
            )
    );

    ostkNetworksFipsMapSucceded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromNetworksFipsActions.OstkNetworksFipsMapSucceded),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.NETWORKS.FIPS.MAPPING.NOTIF.TITLE',
                                'OSTK.NETWORKS.FIPS.MAPPING.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );
                    })
                ),
        { dispatch: false }
    );

    // ostkNetworksFipsMapFailed: Observable<Action> = createEffect(
    //     () =>
    //         this.actions
    //             .pipe(
    //                 ofType(fromNetworksFipsActions.OstkNetworksFipsMapFailed),
    //                 tap(() =>
    //                     this.notificationService.notify(
    //                         [
    //                             'OSTK.NETWORKS.FIPS.MAPPING.NOTIF.TITLE',
    //                             'OSTK.NETWORKS.FIPS.MAPPING.NOTIF.MSGFAILED'
    //                         ],
    //                         NotfGravity.danger,
    //                         NotifType.SNACKBAR
    //                     )
    //                 )
    //             ),
    //     { dispatch: false }
    // );

    ostkNetworksFipsUnmapRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromNetworksFipsActions.OstkNetworksFipsUnmapRequested),
                switchMap(({ id }: { id: string; }) =>
                    this.floatingIpMiscDomainService.unmap(id)
                        .pipe(
                            switchMap((floatingIp: FloatingIp) =>
                                [
                                    fromNetworksFipsActions.OstkNetworksFipsUnmapSucceded({ floatingIp }),
                                    fromOstkInstancesActions.OstkInstancesExpireDate()
                                ]
                            ),
                            catchError((failure: any) =>
                                of(fromNetworksFipsActions.OstkNetworksFipsUnmapFailed(failure))
                            )
                        )
                )
            )
    );

    ostkNetworksFipsUnmapSucceded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromNetworksFipsActions.OstkNetworksFipsUnmapSucceded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.NETWORKS.FIPS.UNMAPPING.NOTIF.TITLE',
                                'OSTK.NETWORKS.FIPS.UNMAPPING.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    // ostkNetworksFipsUnmapFailed: Observable<Action> = createEffect(
    //     () =>
    //         this.actions
    //             .pipe(
    //                 ofType(fromNetworksFipsActions.OstkNetworksFipsUnmapFailed),
    //                 tap(() =>
    //                     this.notificationService.notify(
    //                         [
    //                             'OSTK.NETWORKS.FIPS.UNMAPPING.NOTIF.TITLE',
    //                             'OSTK.NETWORKS.FIPS.UNMAPPING.NOTIF.MSGFAILED'
    //                         ],
    //                         NotfGravity.danger,
    //                         NotifType.SNACKBAR
    //                     )
    //                 )
    //             ),
    //     { dispatch: false }
    // );

    ostkNetworkFipsDeleteRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromNetworksFipsActions.OstkNetworksFipsDeleteRequested),
                switchMap(({ id }: { id: string; }) =>
                    this.floatingIpMiscDomainService.deleteFloatingIp(id)
                        .pipe(
                            map(() =>
                                fromNetworksFipsActions.OstkNetworksFipsDeleteSucceeded({ id })
                            ),
                            catchError((failure: any) =>
                                of(fromNetworksFipsActions.OstkNetworksFipsDeleteFailed({ failure }))
                            )
                        )
                )
            )
    );

    ostkNetworksFipsDeleteSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromNetworksFipsActions.OstkNetworksFipsDeleteSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.NETWORKS.FIPS.DELETE.NOTIF.TITLE',
                                'OSTK.NETWORKS.FIPS.DELETE.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkNetworksFipsAllocateRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromNetworksFipsActions.OstkNetworksFipsAllocateRequested),
                switchMap(({ poolId: poolId }: { poolId: string; }) =>
                    this.floatingIpMiscDomainService.allocate(poolId)
                        .pipe(
                            map((floatingIp: FloatingIp) =>
                                fromNetworksFipsActions.OstkNetworksFipsAllocateSucceeded({ floatingIp })
                            ),
                            catchError((failure: any) =>
                                of(fromNetworksFipsActions.OstkNetworksFipsAllocateFailed(failure))
                            )
                        )
                )
            )
    );

    ostkNetworksFipsAllocateSucceded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromNetworksFipsActions.OstkNetworksFipsAllocateSucceeded),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.NETWORKS.FIPS.ALLOCATE.NOTIF.TITLE',
                                'OSTK.NETWORKS.FIPS.ALLOCATE.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );
                    }),
                    debounceTime(5000),
                    map(() => fromNetworksFipsActions.OstkNetworksFipsListRequested())
                )
    );
}
