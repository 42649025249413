<legend [ngClass]="{'required':required}"
       *ngIf="label"
       [attr.for]="fieldId">{{label| translate}}
</legend>
<div class="input-component"
     [formGroup]="formGroup"
     *ngIf="controlStatus | async as status"
     [style.height.px]="componentHeight"
     [style.marginTop.px]="marginTop"
     [class.pristine]="!status.dirty"
     [class.valid]="!status.invalid"
     [class.invalid]="status.invalid"
     [class.required]="required">
    <div class="input-wrapper"
         [style.height.px]="wrapperHeight">
        <div class="checkbox-group"
             [ngClass]="orientation"
             *ngIf="lineSize === 0"
             [attr.disabled]="isDisabled ? '' : null">
            <div class="checkbox"
                 *ngFor="let so of options">
                <aa-checkbox9 [option]="so"
                              [name]="ctrlName">
                </aa-checkbox9>
            </div>
        </div>
        <div class="checkbox-group"
             *ngIf="lineSize !== 0"
             [attr.disabled]="isDisabled ? '' : null"
             [style.marginTop.px]="marginTop">
            <table>
                <tr *ngFor="let line of lines; index as i">
                    <td *ngFor="let so of options.slice(i * lineSize, (i + 1) * lineSize)">
                        <aa-checkbox9 [option]="so"
                                      [name]="ctrlName">
                        </aa-checkbox9>
                    </td>
                </tr>
            </table>
        </div>
        <aa-check-mark9 [status]="status">
        </aa-check-mark9>
    </div>
    <aa-field-msg-error [status]="status">
    </aa-field-msg-error>
</div>
