import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { UpsertTagViewModel, EnrichedTag, Tag } from '../../models';
import { DetailedTag } from '../../models';

export const CmdbTagsListRequested = createAction(
    '[CMDB-TAG] Cmdb Tags List Requested'
);
export const CmdbTagsListSucceeded = createAction(
    '[CMDB-TAG] Cmdb Tags List Succeeded',
    props<{ tags: EnrichedTag[] }>()
);
export const CmdbTagsListFailed = createAction(
    '[CMDB-TAG] Cmdb Tags List Failed'
);
export const CmdbTagsListStopRequested = createAction(
    '[CMDB-TAG] Cmdb Tags List Failed'
);

export const CmdbTagDetailedRequested = createAction(
    '[CMDB-TAG] Cmdb Tag Detailed Requested',
    props<{ tagId: string }>()
);
export const CmdbTagDetailedSucceeded = createAction(
    '[CMDB-TAG] Cmdb Tag Detailed Succeeded',
    props<{ detailedTag: DetailedTag }>()
);
export const CmdbTagDetailedFailed = createAction(
    '[CMDB-TAG] Cmdb Detailed Tag Failed'
);

export const CmdbTagCreateRequested = createAction(
    '[CMDB-TAG] Cmdb Tag Create Requested',
    props<{ tag: UpsertTagViewModel }>()
);
export const CmdbTagCreateSucceeded = createAction(
    '[CMDB-TAG] Cmdb Tag Create Succeeded',
    props<{ tag: EnrichedTag }>()
);

export const CmdbTagDeleteRequested = createAction(
    '[CMDB-TAG] Cmdb Tag Delete Requested',
    props<{ tagId: string }>()
);
export const CmdbTagDeleteSucceeded = createAction(
    '[CMDB-TAG] Cmdb Tag Delete Succeeded',
    props<{ tagId: string }>()
);

export const CmdbTagUpdateRequested = createAction(
    '[CMDB-TAG] Cmdb Tag Update Requested',
    props<{ tagId: string, tag: UpsertTagViewModel }>()
);
export const CmdbTagUpdateSucceeded = createAction(
    '[CMDB-TAG] Cmdb Tag Update Succeeded',
    props<{ tag: Update<Tag> }>()
);
export const CmdbTagUpdateFailed = createAction(
    '[CMDB-TAG] Cmdb Tag Update Failed'
);
export const CmdbTagsQsWordSet = createAction(
    '[CMDB-TAG] Cmdb tags QsWord Set',
    props<{ qsWord: string }>()
);
