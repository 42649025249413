export class TreeviewNode<T>
{
    /**
     * @constructor
     * @param {string} id - identifiant du nœud  courant
     * @param {string} parentId - identifiant du noueud parent
     * @param {string} label - texte à présenter le cas échéant
     * @param {T} entry - donnée associé au nœud  courant
     */
    constructor (public id: string, public parentId: string, public label: string, public entry: T)
    {
    }
}

export const AnonymousRoot = <T>(): TreeviewNode<T> =>
    new TreeviewNode<T>('root', '', '', null);
