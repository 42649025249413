import { Action, combineReducers } from '@ngrx/store';

import { IIts2DashboardState } from './dashboard.state';
import { Its2DashboardSLAReducer } from './sla/sla.reducer';
import { Its2DashSLAFeatureKey } from './sla/sla.state';
import { Its2DashboardBreakdownReducer } from './breakdown/breakdown.reducer';
import { Its2DashBreakdownFeatureKey } from './breakdown/breakdown.state';
import { Its2DashReportingFeatureKey } from './reporting/reporting.state';
import { Its2DashboardReportingReducer } from './reporting/reporting.reducer';
import { Its2DashSharedFeatureKey } from './shared/shared.state';
import { Its2DashboardSharedReducer } from './shared/shared.reducer';

export function Its2DashboardReducers (state: IIts2DashboardState | undefined, action: Action)
{
    return combineReducers({
        [Its2DashSLAFeatureKey]: Its2DashboardSLAReducer,
        [Its2DashBreakdownFeatureKey]: Its2DashboardBreakdownReducer,
        [Its2DashReportingFeatureKey]: Its2DashboardReportingReducer,
        [Its2DashSharedFeatureKey]: Its2DashboardSharedReducer
    })(state, action);
}
