import { createAction, props } from '@ngrx/store';

import { SelectedRequest } from '@apps/its2/domain/models/request';

export const Its2RequestsSelectedAddMany = createAction(
    '[ITS2-REQUESTS] Its2 Add Requests Selected Add Many',
    props<{ requests: SelectedRequest[] }>()
);

export const Its2RequestsSelectedAddOne = createAction(
    '[ITS2-REQUESTS] Its2 Requests Selected Add One',
    props<{ request: SelectedRequest }>()
);

export const Its2RequestsSelectedRemoveMany = createAction(
    '[ITS2-REQUESTS] Its2 Requests Selected Remove Many',
    props<{ requests: SelectedRequest[] }>()
);

export const Its2RequestsSelectedRemoveOne = createAction(
    '[ITS2-REQUESTS] Its2 Requests Selected Remove One',
    props<{ request: SelectedRequest }>()
);

export const Its2RequestsFavoritesSet = createAction(
    '[ITS2-REQUESTS] Its2 Requests Favorites Set',
    props<{ rfcNumbers: string[] }>()
);

export const Its2RequestsFavoritesUnset = createAction(
    '[ITS2-REQUESTS] Its2 Requests Favorites Unset',
    props<{ rfcNumbers: string[] }>()
);

export const Its2RequestsReadSet = createAction(
    '[ITS2-REQUESTS] Its2 Requests Read Set',
    props<{ rfcNumbers: string[] }>()
);

export const Its2RequestsReadUnset = createAction(
    '[ITS2-REQUESTS] Its2 Requests Read Unset',
    props<{ rfcNumbers: string[] }>()
);

export const Its2RequestsSelectedCleared = createAction(
    '[ITS2-REQUESTS] Its2 Requests Selected Cleared'
);
