import { DateTime } from 'luxon';

import { AppConstants } from '@apps/app.constants';
import { DatesService } from '@common/services';

export class StatusesMetadata
{
    constructor (public isLoading: boolean = false, public isLoaded: boolean = false, public isEmpty: boolean = false, public isOups: boolean = false, public expirationDate: DateTime = null)
    {
    }

    public static init = (): StatusesMetadata =>
        new StatusesMetadata(false, false, false, false, null);

    public static requested = (): StatusesMetadata =>
        new StatusesMetadata(true, false, false, false, null);

    public static succeeded = (isLoaded: boolean, isEmpty: boolean): StatusesMetadata =>
        new StatusesMetadata(false, isLoaded, isEmpty, false, DateTime.utc().plus({ minutes: AppConstants.DataLifeTime }));

    public static failed = (): StatusesMetadata =>
        new StatusesMetadata(false, false, false, true, null);
    public static isDataValid = (expirationDate: DateTime): boolean =>
        DatesService.isDataValid(expirationDate);
}

export class FlagsMetadata
{
    constructor (public isLoading: boolean , public isLoaded: boolean , public isEmpty: boolean , public isOups: boolean)
    {
    }
}
