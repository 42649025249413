import { TypePopin } from '@apps/app.constants';
import { Entity, User } from '@apps/auth/domain/models/back';
import { Copil } from '@apps/pata/domain/models/copil';

export const ExternalStatus: boolean = true;
export const InternalStatus: boolean = false;

export enum EntitiesStatus
    {
    Active = 0,
    Inactive = 1
}

export class BackConstants
{
    public static Application: string = 'BackApi';
    public static BaseUrl: string = 'admin';
    public static Dashboard: string = 'dashboard';
    public static Impersonate: string = 'impersonate';
    public static Entities: string = 'entities';
    public static Users: string = 'users';
    public static Pata: string = 'pata';
    public static Copils: string = 'copils';
    public static Docs: string = 'docs';
    public static External: string = 'external';
    public static Internal: string = 'internal';
    public static Probes = 'probes';
    public static DefaultAdminUrl = `/${BackConstants.BaseUrl}/${BackConstants.Dashboard}`;
    public static ExternalEntitiesUrl = `/${BackConstants.BaseUrl}/${BackConstants.Entities}/${BackConstants.External}`;
    public static InternalEntitiesUrl = `/${BackConstants.BaseUrl}/${BackConstants.Entities}/${BackConstants.Internal}`;

    public static QSEntitiesTop: number = 5000;
    public static QSUsersTop: number = 10000;
    public static ExploitAccount: string = '.EXPLOIT';
    public static DataLifeTime = 7;
    public static entitiesQSFields: (keyof Entity)[] = [ 'name', 'entityShortId', 'extractedApplicationNames' ];
    public static usersQSFields: (keyof User)[] = [ 'firstLastName', 'email', 'entityName', 'title', 'mobile', 'phoneNumber' ];
    public static copilsQSFields: (keyof Copil)[] = ['entity', 'entityErpId', 'governorFirstLastName', 'projectNumber', 'orderNumber', 'description', 'unit', 'projectName', 'billCustomer', 'manager', 'managerSup'];

    public static getCssStatus = (status: boolean) =>
    {
        return status ? 'active' : 'inactive';
    };

    public static getStatusKey = (status: boolean) =>
    {
        return `STATUSES.${status ? 'ACTIVE' : 'INACTIVE'}`;
    };

    public static getCssExploit = (status: boolean) =>
    {
        return status ? 'exploit-yes' : 'exploit-no';
    };

    public static EntityDetailsRoute = (external: boolean, entityId: string) =>
    {
        const baseFragments = [BackConstants.BaseUrl, BackConstants.Entities];

        baseFragments.push( external ? BackConstants.External : BackConstants.Internal );

        return [
            ...baseFragments,
            { outlets: { [TypePopin.DETAILS]: [ entityId ] } }
        ];
    };

    public static UserDetailsRoute = (external: boolean, userId: string) =>
    {
        const baseFragments = [BackConstants.BaseUrl, BackConstants.Users];

        baseFragments.push( external ? BackConstants.External : BackConstants.Internal );

        return [
            ...baseFragments,
            { outlets: { [TypePopin.DETAILS]: [ userId ] } }
        ];
    };

    public static FromEntityUserDetailsRoute = (external: boolean, userId: string) =>
    {
        const baseFragments = [BackConstants.BaseUrl, BackConstants.Entities];

        baseFragments.push(external ? BackConstants.External : BackConstants.Internal);

        return [
            ...baseFragments,
            { outlets: { [TypePopin.DETAILS]: [ 'user', userId ] } }
        ];
    };

    public static EntityProbesRoute = (entityName: string, entityId: string, external: boolean) =>
        [
            BackConstants.BaseUrl,
            BackConstants.Entities,
            { outlets:
                {
                    [ TypePopin.ACTION ]:
                        [
                            entityName,
                            entityId,
                            external,
                            BackConstants.Probes
                        ]
                }
            }
        ];

    public static BackDetailsCopilRoute = (entityName: string, jobNumber: string) =>
    {
        const baseFragments = [BackConstants.BaseUrl, BackConstants.Copils];

        return [
            ...baseFragments,
            { outlets: { [TypePopin.DETAILS]: [ entityName, jobNumber ] } }
        ];
    };
}
