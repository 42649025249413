import { Directive, Injector, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';

import { Orientation } from '@apps/app.constants';

import { SelectOption9 } from './select-option9';
import { InputBase } from '../base/input-base';

@Directive()
export abstract class RbCbBase<T> extends InputBase<T>
{
    @Input() options: SelectOption9[];
    @Input() orientation: Orientation = Orientation.HORIZ;
    @Input() lineSize: number = 0;
    @Input() nbLines: number = 0;
    @Input() marginTop: number = 20;
    public lines: number[];
    public componentHeight: number;
    public wrapperHeight: number;

    constructor (
        injector: Injector,
        controlContainer: ControlContainer
    )
    {
        super(injector, controlContainer);
    }

    protected initRbCb (): void
    {
        if (this.lineSize !== 0)
        {
            const nbLines = Math.max(this.nbLines, Math.ceil(this.options.length / this.lineSize));

            this.lines = Array.from(Array(nbLines));
            this.wrapperHeight = 40 * this.lines.length;
        }
        else
        {
            this.wrapperHeight = 40;
        }

        this.componentHeight = this.wrapperHeight + 32;
    }
}
