import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ResultApi } from './result-api';

export abstract class BaseListApiService<T>
{
    protected segmentRoot: string = '';
    protected resourceUrl: string;

    constructor (
        protected http: HttpClient
    )
    {
    }

    getAll (resource: string, headers?: HttpHeaders): Observable<ResultApi<T>>
    {
        const urlFull = `${this.segmentRoot}${resource}`;

        if (headers !== null)
        {
            return this.http.get<ResultApi<T>>(urlFull, { headers: headers });
        }

        return this.http.get<ResultApi<T>>(urlFull);
    }

    getOne (resource: string): Observable<T>
    {
        const urlFull = `${this.segmentRoot}${resource}`;

        return this.http.get<T>(urlFull);
    }
}
