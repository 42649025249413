import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseListApiService } from '../../../_common/api/base-list-api.service';
import { ResultApi } from 'src/app/_common/api/result-api';
import { AppConstants } from '../../app.constants';
import { CapacityApi } from './models/capacity-api';

@Injectable({
    providedIn: 'root'
})
export class CapacityListApiService extends BaseListApiService<CapacityApi>
{
    private endPoint = '/capacities';

    constructor(http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlHist;
        this.resourceUrl = '/hist/netbackup';
    }

    getCapacities (range: string): Observable<ResultApi<CapacityApi>>
    {
        if (AppConstants.IsDataLocal('bckp'))
        {
            return this.http.get<ResultApi<CapacityApi>>('assets/data/bckp/capacities.json');
        }

        return this.getAll(`${this.resourceUrl}${this.endPoint}?${range}`);
    }
}
