import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ImageApi } from './models';
import { AppConstants } from '../../app.constants';
import { BaseMiscApiService } from 'src/app/_common/api/base-misc-api.service';

@Injectable({
    providedIn: 'root'
})
export class ImageListApiService extends BaseMiscApiService
{
    private resourceUrl: string;

    constructor(http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlOstk;
        this.segmentApi = '/ostk/images';
    }

    getImages (): Observable<ImageApi[]>
    {
        if (AppConstants.IsDataLocal('ostk'))
        {
            return this.http.get<ImageApi[]>('assets/data/ostk/images.json');
        }

        return this.get<ImageApi[]>('');
    }
}
