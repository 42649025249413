import { InstCreationSource } from '../../instances.constants';
import { CreateInstance } from '.';

export class CreateInstanceViewModel
{
    nbInstances: number = 1;
    name: string;
    diskSize?: number;
    flavorId: string;
    imageId: string = null;
    volumeId: string = null;
    keyPairName: string;
    securityRefs: string[];
    networkIds: string[];
    osIcon: string;

    mapperToApi (source: CreateInstance): CreateInstanceViewModel
    {
        const target = new CreateInstanceViewModel();

        target.name = source.step1.instanceName;
        target.diskSize = source.step1.createVolume ? source.step1.voluCapacity : null;
        target.flavorId = source.step1.flavorId;

        switch (source.step1.source)
        {
            case InstCreationSource.IMAGE:
                target.imageId = source.step1.imageSource;
                break;
            case InstCreationSource.VOLUME:
                target.volumeId = source.step1.volumeSource;
                break;
            case InstCreationSource.SNAPSHOT:
                target.volumeId = source.step1.snapshotSource;
                break;
        }

        target.keyPairName = source.step2.keyPairName;
        target.securityRefs = [...source.step2.securityGroupIds];
        target.networkIds = [...source.step2.networkIds];

        return target;
    }
}
