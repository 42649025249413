import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as fromRootImpersonateActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';

import * as fromIts2ValuesActions from './values.actions';
import { PropertyValues } from '../models/property-values';

export const Its2ValuesFeatureKey = 'values';

type IValuesState = EntityState<PropertyValues>;
export const valuesAdapter: EntityAdapter<PropertyValues> = createEntityAdapter<PropertyValues>({
    selectId: (propertyValues: PropertyValues) => propertyValues.propertyName
});
const valuesInitialState: IValuesState = valuesAdapter.getInitialState({
});

export interface IIts2ValuesState
{
    values: IValuesState;
    isLoading: boolean;
}
export const Its2ValuesState: IIts2ValuesState = {
    values: valuesInitialState,
    isLoading: false
};

export const Its2ValuesReducer = createReducer(
    Its2ValuesState,
    on(
        fromRootImpersonateActions.RootImpersonateSet,
        fromRootImpersonateActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => Its2ValuesState
    ),
    on(
        fromIts2ValuesActions.Its2RequestsValuesRequested,
        (state: IIts2ValuesState) => ({
            ...state,
            isLoading: true
        })
    ),
    on(
        fromIts2ValuesActions.Its2RequestsValuesSucceeded,
        (state: IIts2ValuesState, { propertyValues }: { propertyValues: PropertyValues; }) => ({
            ...state,
            isLoading: false,
            values: valuesAdapter.setOne(propertyValues, state.values)
        })
    )
);
