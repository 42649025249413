import { Action, combineReducers } from '@ngrx/store';

import { IAuroraState } from 'src/app/_apps/aurora.state';
import * as fromSecurityGroupsReducer from 'src/app/_apps/ostk/store/security/sgrp/sgrp.reducer';
import * as fromKeyPairsReducer from 'src/app/_apps/ostk/store/security/keyp/keyp.reducer';

export * from './sgrp/sgrp.reducer';
export * from './keyp/keyp.reducer';

export const OstkSecurityFeatureKey = 'security';

export interface IState extends IAuroraState
{
    [OstkSecurityFeatureKey]: IOstkSecurityState;
}

export function OstkSecurityReducers (state: IOstkSecurityState | undefined, action: Action)
{
    return combineReducers({
        [fromSecurityGroupsReducer.OstkSecuritySgrpFeatureKey]: fromSecurityGroupsReducer.OstkSecuritySgrpReducer,
        [fromKeyPairsReducer.OstkSecurityKeypFeatureKey]: fromKeyPairsReducer.OstkKeyPairsReducer
    })(state, action);
}

export interface IOstkSecurityState
{
    [fromSecurityGroupsReducer.OstkSecuritySgrpFeatureKey]: fromSecurityGroupsReducer.IOstkSecuritySgrpState;
    [fromKeyPairsReducer.OstkSecurityKeypFeatureKey]: fromKeyPairsReducer.IOstkSecurityKeypState;
}
