import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { LanguageService } from '@common/services';

@Injectable()
export class AuthResolver implements Resolve<boolean>
{
    constructor (private languageService: LanguageService)
    { }

    resolve (): Observable<boolean>
    {
        return this.languageService.loadLanguage(['auth', 'priv']);
    }
}
