import { createSelector } from '@ngrx/store';

import { FlagsMetadata } from '@common/models';
import { getDashboardState } from '../dashboard-selectors';
import { IIts2DashboardState } from '../dashboard.state';
import { IIts2DashSLACounters, IIts2DashSLAState, Its2DashSLAFeatureKey } from './sla.state';

const getSLAState = createSelector(
    getDashboardState,
    (state: IIts2DashboardState) => state[Its2DashSLAFeatureKey]
);

const getSLAIncidents = createSelector(
    getSLAState,
    (slaState: IIts2DashSLAState) => slaState.incidents
);
export const getSLAIncidentsCounters = createSelector(
    getSLAIncidents,
    (iState: IIts2DashSLACounters) => iState.sla
);
export const getIts2DashSLAIncidentsFlags = createSelector(
    getSLAIncidents,
    (iState: IIts2DashSLACounters): FlagsMetadata =>
        new FlagsMetadata(iState.isLoading, iState.isLoaded, iState.isEmpty, iState.isOups)
);

const getSLAServices = createSelector(
    getSLAState,
    (slaState: IIts2DashSLAState) => slaState.services
);
export const getSLAServicesCounters = createSelector(
    getSLAServices,
    (sState: IIts2DashSLACounters) => sState.sla
);
export const getIts2DashSLAServicesFlags = createSelector(
    getSLAServices,
    (sState: IIts2DashSLACounters): FlagsMetadata =>
        new FlagsMetadata(sState.isLoading, sState.isLoaded, sState.isEmpty, sState.isOups)
);
