import { props, createAction } from '@ngrx/store';

export const RootQuickSearchValueChanged = createAction(
    '[QUICK-SEARCH] Root Quick Search Value Changed',
    props<{ key: string, searchValue: string }>()
);

export const RootQuickSearchResetRequested = createAction(
    '[QUICK-SEARCH] Root Quick Search Reset Requested'
);
