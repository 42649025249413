import { Injectable } from '@angular/core';

import { SnackbarRef, SnackbarService } from '@ui/misc/snackbar';
import { NotfGravity } from '@apps/notf/models/message';
import { Failure } from '@common/models';

@Injectable({
    providedIn: 'root'
})
export class NotificationFailureService
{
    private snackbarRef: SnackbarRef;

    constructor (
        private snackbarService: SnackbarService
    )
    {
    }

    notify (failure: Failure, gravity: NotfGravity = NotfGravity.danger)
    {
        if (this.snackbarRef)
        {
            this.snackbarRef.overlayRef.dispose();
        }

        this.snackbarRef = this.snackbarService.create(failure, gravity);
    }
}
