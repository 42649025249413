import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseListApiService } from '../../../_common/api/base-list-api.service';
import { ResultApi } from 'src/app/_common/api/result-api';
import { AppConstants } from '../../app.constants';
import { ImageApi } from './models';

@Injectable({
    providedIn: 'root'
})
export class ServerListApiService extends BaseListApiService<ImageApi>
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlHist;
        this.resourceUrl = '/hist/netbackup';
    }

    getImages (qs: string): Observable<ResultApi<ImageApi>>
    {
        // if (AppConstants.IsDataLocal('bckp'))
        // {
        //     return this.http.get<ResultApi<ImageApi>>('assets/data/bckp/servers.json');
        // }

        return this.getAll(`${this.resourceUrl}/images${qs}`);
    }

    getLatestImages (qs: string): Observable<ResultApi<ImageApi>>
    {
        // if (AppConstants.IsDataLocal('bckp'))
        // {
        //     return this.http.get<ResultApi<ImageApi>>('assets/data/bckp/servers.json');
        // }

        return this.getAll(`${this.resourceUrl}/latestimages${qs}`);
    }
}
