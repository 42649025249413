import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, tap, withLatestFrom, concatMap } from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';

import { IAuroraState } from '@apps/aurora.state';
import { RequestMiscDomainService } from '@apps/its2/domain';
import { RequestActionDetails } from '@apps/its2/domain/models';
import * as fromIts2ActionssActions from './actions.actions';
import { getAllActions } from '../its2.reducer';

@Injectable()
export class Its2ActionsEffects
{
    constructor (
        private actions: Actions,
        private store: Store<IAuroraState>,
        private requestMiscDomainService: RequestMiscDomainService
    )
    { }

    its2RequestsActionsRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2ActionssActions.Its2RequestsActionsRequested),
                switchMap(({ rfcNumber: rfcNumber }: { rfcNumber: string; }) =>
                    this.requestMiscDomainService.getActions(rfcNumber)
                        .pipe(
                            switchMap((detailedRequestActions: RequestActionDetails[]) =>
                                [
                                    fromIts2ActionssActions.Its2RequestsActionsSucceeded({ detailedRequestActions }),
                                    fromIts2ActionssActions.Its2RequestsLastCommentRequested({ rfcNumber, detailedRequestActions })
                                ]
                            ),
                            catchError((error: Error) =>
                                of(fromIts2ActionssActions.Its2RequestsActionsFailed({ error }))
                            )
                        )
                )
            )
    );

    its2RequestsDetailedActionRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2ActionssActions.Its2RequestsDetailedActionRequested),
                concatMap(({ rfcNumber, requestActionDetails }: { rfcNumber: string; requestActionDetails: RequestActionDetails; }) =>
                    this.requestMiscDomainService.getActionDetails(rfcNumber, requestActionDetails)
                        .pipe(
                            map((rad: RequestActionDetails) =>
                            {
                                const update: Update<RequestActionDetails> = {
                                    id: rad.actionId,
                                    changes: {
                                        comment: rad.comment,
                                        description: rad.description,
                                        displayText: rad.displayText,
                                        isLoading: false
                                    }
                                };

                                return fromIts2ActionssActions.Its2RequestsDetailedActionSucceeded({ requestActionDetails: update });
                            }),
                            catchError((error: Error) =>
                                of(fromIts2ActionssActions.Its2RequestsDetailedActionFailed({ error }))
                            )
                        )
                )
            )
    );

    its2RequestsDetailedActionExpandAllRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromIts2ActionssActions.Its2RequestsDetailedActionExpandAllRequested),
                    withLatestFrom(
                        this.store.select(getAllActions)
                    ),
                    tap(([actionProps, detailedRequestActions]) =>
                    {
                        detailedRequestActions.forEach((requestActionDetails) =>
                        {
                            this.store.dispatch(
                                fromIts2ActionssActions.Its2RequestsDetailedActionExpand({ requestActionDetails, value: actionProps.value })
                            );

                            if (actionProps.value === true)
                            {
                                this.store.dispatch(
                                    fromIts2ActionssActions.Its2RequestsDetailedActionRequested({ rfcNumber: actionProps.rfcNumber, requestActionDetails })
                                );
                            }
                        });
                    })
                ),
        { dispatch: false }
    );

    its2RequestsLastCommentRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2ActionssActions.Its2RequestsLastCommentRequested),
                switchMap(({ rfcNumber, detailedRequestActions }: { rfcNumber: string; detailedRequestActions: RequestActionDetails[] }) =>
                    this.requestMiscDomainService.getActionDetails(rfcNumber, detailedRequestActions[0])
                        .pipe(
                            map((action: RequestActionDetails) =>
                                fromIts2ActionssActions.Its2RequestsLastCommentSucceeded({ lastComment: action.displayText })
                            ),
                            catchError((error: Error) =>
                                of(fromIts2ActionssActions.Its2RequestsLastCommentFailed({ error }))
                            )
                        ))
            )
    );
}
