<span class="input-validation fa-stack fa-1x"
      *ngIf="status.dirty"
      [ngClass]="status.invalid ? 'invalid': 'valid'">
    <i class="fas fa-stack-2x fa-circle"
       aria-hidden="true">
    </i>
    <i class="fal fa-stack-1x"
       [ngClass]="status.invalid ? 'fa-times' : 'fa-check'"
       aria-hidden="true">
    </i>
</span>
