import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CompareDateTime2 } from '@common/services/utils.service';
import { TeamConso, UserConso } from '@apps/objs/domain/models';
import { Platform } from '@apps/objs/domain/models/platform';


export const ObjsStorageFeatureKey = 'storage';

type IUserConsoState = EntityState<UserConso>;
export const userConsoAdapter: EntityAdapter<UserConso> = createEntityAdapter<UserConso>({
    selectId: (userConso: UserConso) => userConso.ts.toISODate(),
    sortComparer: CompareDateTime2<UserConso>((userConso: UserConso) => userConso.ts)
});

export const userConsoInitialState: IUserConsoState = userConsoAdapter.getInitialState({});

type ITeamConsoState = EntityState<TeamConso>;
export const teamConsoAdapter: EntityAdapter<TeamConso> = createEntityAdapter<TeamConso>({
    selectId: (teamConso: TeamConso) => teamConso.ts.toISODate(),
    sortComparer: CompareDateTime2<TeamConso>((teamConso: TeamConso) => teamConso.ts)
});

export const teamConsoInitialState: ITeamConsoState = teamConsoAdapter.getInitialState({});

export interface IObjsStorageState
{
    userConso: IUserConsoState;
    teamConso: ITeamConsoState;
    isLoading: boolean;
    isLoaded: boolean;
    isEmpty: boolean;
    isOups: boolean;
    platforms: Platform[];
    platformId: string;
}

export const ObjsStorageInitialState: IObjsStorageState = {
    userConso: userConsoInitialState,
    teamConso: teamConsoInitialState,
    isLoading: true,
    isLoaded: false,
    isEmpty: true,
    isOups: false,
    platforms: [],
    platformId: ''
};