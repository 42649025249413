import { combineReducers, Action, createSelector, createFeatureSelector } from '@ngrx/store';
import { DateTime } from 'luxon';

import { Project, Network, FloatingIp, KeyPair, SecurityGroup, SecurityRule, Quota } from '@apps/ostk/domain/models';
import { Comparer, filterQuickSearch } from '@common/services/utils.service';

import { IAuroraState } from '../../aurora.state';
import * as fromProjectsReducer from './projects/projects.reducer';
import * as fromInstancesReducer from './instances/instances.reducer';
import * as fromMiscReducer from './misc/misc.reducer';
import * as fromQuotaReducer from './quotas/quotas.reducer';
import * as fromBillingReducer from './billing/billing.reducer';
import * as fromImagesReducer from './images/images.reducer';
import * as fromSecurityReducer from './security/security.reducer';
import * as fromNetworksReducer from './networks/networks.reducer';
import * as fromVolumesReducer from './volumes/volumes.reducer';
import * as fromFlavorsReducer from './flavors/flavors.reducer';
import { OstkConstants } from '../ostk.constants';
import { IOstkNetworksNetwState } from './networks/networks.reducer';

export const OstkFeatureKey = 'ostk';

export function OstkReducers (state: IOstkState | undefined, action: Action)
{
    return combineReducers({
        [fromProjectsReducer.OstkProjectsFeatureKey]: fromProjectsReducer.OstkProjectsReducer,
        [fromInstancesReducer.OstkInstFeatureKey]: fromInstancesReducer.OstkInstancesReducer,
        [fromMiscReducer.OstkMiscFeatureKey]: fromMiscReducer.MiscReducer,
        [fromQuotaReducer.OstkQuotasFeatureKey]: fromQuotaReducer.OstkQuotaReducer,
        [fromBillingReducer.OstkBillingFeatureKey]: fromBillingReducer.OstkBillingReducer,
        [fromImagesReducer.OstkImagesFeatureKey]: fromImagesReducer.OstkImagesReducers,
        [fromVolumesReducer.OstkVolumesFeatureKey]: fromVolumesReducer.OstkVolumesReducers,
        [fromSecurityReducer.OstkSecurityFeatureKey]: fromSecurityReducer.OstkSecurityReducers,
        [fromNetworksReducer.OstkNetworksFeatureKey]: fromNetworksReducer.OstkNetworksReducers,
        [fromFlavorsReducer.OstkFlavorsFeatureKey]: fromFlavorsReducer.OstkFlavorsReducers
    })(state, action);
}

export interface IOstkState {
    [fromProjectsReducer.OstkProjectsFeatureKey]: fromProjectsReducer.IOstkProjectsState;
    [fromInstancesReducer.OstkInstFeatureKey]: fromInstancesReducer.IOstkInstState;
    [fromMiscReducer.OstkMiscFeatureKey]: fromMiscReducer.IOstkMiscState;
    [fromQuotaReducer.OstkQuotasFeatureKey]: fromQuotaReducer.IOstkQuotasState;
    [fromBillingReducer.OstkBillingFeatureKey]: fromBillingReducer.IOstkBillingState;
    [fromImagesReducer.OstkImagesFeatureKey]: fromImagesReducer.IOstkImagesState;
    [fromVolumesReducer.OstkVolumesFeatureKey]: fromVolumesReducer.IOstkVolumesState;
    [fromSecurityReducer.OstkSecurityFeatureKey]: fromSecurityReducer.IOstkSecurityState;
    [fromNetworksReducer.OstkNetworksFeatureKey]: fromNetworksReducer.IOstkNetworksState;
    [fromFlavorsReducer.OstkFlavorsFeatureKey]: fromFlavorsReducer.IOstkFlavorsState;
}
export const OstkInitialState: IOstkState =
{
    [fromProjectsReducer.OstkProjectsFeatureKey]: null,
    [fromInstancesReducer.OstkInstFeatureKey]: fromInstancesReducer.OstkInstInitialState,
    [fromMiscReducer.OstkMiscFeatureKey]: null,
    [fromQuotaReducer.OstkQuotasFeatureKey]: null,
    [fromBillingReducer.OstkBillingFeatureKey]: null,
    [fromImagesReducer.OstkImagesFeatureKey]: null,
    [fromVolumesReducer.OstkVolumesFeatureKey]: null,
    [fromSecurityReducer.OstkSecurityFeatureKey]: null,
    [fromNetworksReducer.OstkNetworksFeatureKey]: null,
    [fromFlavorsReducer.OstkFlavorsFeatureKey]: null
};

export const getOstkState = createFeatureSelector<IOstkState>(
    OstkFeatureKey
);

//#region Projects
const getOstkProjectsState = createSelector(
    getOstkState,
    (state: IOstkState) =>
        state[fromProjectsReducer.OstkProjectsFeatureKey]
);
const getPlatformsState = createSelector(
    getOstkProjectsState,
    (state) => state.platforms
);
export const {
    selectEntities: getDictPlatforms,
    selectAll: getAllPlatforms,
    selectTotal: getNbPlatforms
} = fromProjectsReducer.platformsAdapter.getSelectors(getPlatformsState);
export const getPlatformsLoadingStatus = createSelector(
    getOstkProjectsState,
    (state) => state.isLoadingPlatform
);
export const getPlatformsEmptyStatus = createSelector(
    getOstkProjectsState,
    (state) => state.isEmptyPlatform
);
export const getPlatformsOupsStatus = createSelector(
    getOstkProjectsState,
    (state) => state.isOupsPlatform
);
export const getSelectedPlatform = createSelector(
    getOstkProjectsState,
    (state) => state.selectedPlatform
);

const getProjectsState = createSelector(
    getOstkProjectsState,
    (state) => state.projects
);
export const {
    selectEntities: getDictProjects,
    selectAll: getAllProjects,
    selectTotal: getNbProjects
} = fromProjectsReducer.projectsAdapter.getSelectors(getProjectsState);
export const getProjectsLoadingStatus = createSelector(
    getOstkProjectsState,
    (state) => state.isLoadingProject
);
export const getProjectsEmptyStatus = createSelector(
    getOstkProjectsState,
    (state) => state.isEmptyProject
);
export const getProjectsOupsStatus = createSelector(
    getOstkProjectsState,
    (state) => state.isOupsProject
);
export const getSelectedProject = createSelector(
    getOstkProjectsState,
    (state) => state.selectedProject
);
export const isProjectLoaded = createSelector(
    getAllProjects,
    (projects: Project[], props: { id: string }) =>
        -1 !== projects.findIndex((project: Project) => project.projectId === props.id)
);
//#endregion

//#region Misc
const getOstkMiscState = createSelector(
    getOstkState,
    (state: IOstkState) =>
        state[fromMiscReducer.OstkMiscFeatureKey]
);
export const getCreateInstance = createSelector(
    getOstkMiscState,
    (state: fromMiscReducer.IOstkMiscState) =>
        state.ci
);
// #endregion

//#region Quota
const getQuotasState = createSelector(
    getOstkState,
    (state: IOstkState) =>
        state[fromQuotaReducer.OstkQuotasFeatureKey]
);
const getQuotasCollectionState = createSelector(
    getQuotasState,
    (state) => state.quotas
);
export const {
    selectAll: getAllQuotas,
} = fromQuotaReducer.quotasAdapter.getSelectors(getQuotasCollectionState);

export const getQuotaByKey = createSelector(
    getAllQuotas,
    (quotas: Quota[], { key }: { key: string }) =>
    {
        const result = quotas.filter((quota: Quota) =>
            key === quota.key
        );

        return result.length === 1 ? result[0] : null;
    }
);

export const getQuotasLoadingStatus = createSelector(
    getQuotasState,
    (state) => state.isLoading
);
export const getQuotasEmptyStatus = createSelector(
    getQuotasState,
    (state) => state.isEmpty
);
export const getQuotasOupsStatus = createSelector(
    getQuotasState,
    (state) => state.isOups
);
export const getQuotasLoadedStatus = createSelector(
    getQuotasState,
    (state) => state.isLoaded
);
export const getQuotasIsDataValid = createSelector(
    getQuotasState,
    (state) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);
// #endregion

//#region SecurityGroups
const getSecurityState = createSelector(
    getOstkState,
    (state: IOstkState) =>
        state[fromSecurityReducer.OstkSecurityFeatureKey]
);
const getSecuritySgrpState = createSelector(
    getSecurityState,
    (state) =>
        state[fromSecurityReducer.OstkSecuritySgrpFeatureKey]
);

const getSecuritySgrpCollection = createSelector(
    getSecuritySgrpState,
    (state) =>
        state.securityGroups
);
export const {
    selectAll: getAllSecurityGroups,
} = fromSecurityReducer.OstkSecuritySgrpAdapter.getSelectors(getSecuritySgrpCollection);
export const getSecuritySgrpLoadingStatus = createSelector(
    getSecuritySgrpState,
    (state) =>
        state.isLoading
);
export const getSecuritySgrpEmptyStatus = createSelector(
    getSecuritySgrpState,
    (state) =>
        state.isEmpty
);
export const getSecuritySgrpOupsStatus = createSelector(
    getSecuritySgrpState,
    (state) =>
        state.isOups
);
export const getSecuritySgrpLoadedStatus = createSelector(
    getSecuritySgrpState,
    (state) =>
        state.isLoaded
);
export const getSecuritySgrpIsDataValid = createSelector(
    getSecuritySgrpState,
    (state) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);
const getQuickSearchedSecurityGroups = createSelector(
    getSecuritySgrpState,
    getAllSecurityGroups,
    (state: fromSecurityReducer.IOstkSecuritySgrpState, securityGroups: SecurityGroup[]) =>
        filterQuickSearch(securityGroups, state.qsWord, OstkConstants.securityGroupsQSFields)
);
export const getPipelinedSecurityGroups = createSelector(
    getQuickSearchedSecurityGroups,
    (securityGroups: SecurityGroup[], params: { comparer: Comparer<SecurityGroup> }) =>
        [...securityGroups].sort(params.comparer)
);
export const getSecurityGroupById = createSelector(
    getSecuritySgrpState,
    (state: fromSecurityReducer.IOstkSecuritySgrpState, securityGroupId: string) =>
        state.securityGroups.entities[securityGroupId]
);
export const getSecurityRuleById = createSelector(
    getSecuritySgrpState,
    (state: fromSecurityReducer.IOstkSecuritySgrpState, securityRuleId: string) =>
        state.selectedSecurityGroup.rules.find(r => r.id === securityRuleId)
);
export const getSelectedSecurityGroup = createSelector(
    getSecuritySgrpState,
    (state: fromSecurityReducer.IOstkSecuritySgrpState) =>
        state.selectedSecurityGroup
);
export const getSelectedSecurityRules = createSelector(
    getSelectedSecurityGroup,
    (securityGroup: SecurityGroup, params: { comparer: Comparer<SecurityRule> }) =>
        [...securityGroup.rules].sort(params.comparer)
);
export const getSecurityRulesLoadedStatus = createSelector(
    getSecuritySgrpState,
    (state: fromSecurityReducer.IOstkSecuritySgrpState) => state.securityRulesIsLoaded
);
export const getMapSecurityGroups = createSelector(
    getAllSecurityGroups,
    getSelectedSecurityGroup,
    (allSecurityGroups: SecurityGroup[], securityGroup: SecurityGroup) =>
    {
        const remoteGroupIds = [...new Set(securityGroup.rules.map(r => r.remoteGroupId))];
        const mapSecurityGroups = new Map<string, string>();

        remoteGroupIds.forEach(id =>
        {
            const sgrp = allSecurityGroups.find(s => s.id === id);
            mapSecurityGroups.set(id, sgrp ? sgrp.name : '');
        });

        return mapSecurityGroups;
    }
);
// #endregion

//#region KeyPairs
const getSecurityKeypState = createSelector(
    getSecurityState,
    (state) =>
        state[fromSecurityReducer.OstkSecurityKeypFeatureKey]
);
const getSecurityKeypCollection = createSelector(
    getSecurityKeypState,
    (state) =>
        state.keyPairs
);
export const {
    selectAll: getAllKeyPairs,
} = fromSecurityReducer.OstkSecurityKeypAdapter.getSelectors(getSecurityKeypCollection);
export const getSecurityKeypLoadingStatus = createSelector(
    getSecurityKeypState,
    (state) =>
        state.isLoading
);
export const getSecurityKeypEmptyStatus = createSelector(
    getSecurityKeypState,
    (state) =>
        state.isEmpty
);
export const getSecurityKeypOupsStatus = createSelector(
    getSecurityKeypState,
    (state) =>
        state.isOups
);
export const getSecurityKeypLoadedStatus = createSelector(
    getSecurityKeypState,
    (state) =>
        state.isLoaded
);
export const getSecurityKeypIsDataValid = createSelector(
    getSecurityKeypState,
    (state) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);
const getQuickSearchedKeyPairs = createSelector(
    getSecurityKeypState,
    getAllKeyPairs,
    (state: fromSecurityReducer.IOstkSecurityKeypState, keyPairs: KeyPair[]) =>
        filterQuickSearch(keyPairs, state.qsWord, OstkConstants.securityKeypQSFields)
);
export const getPipelinedKeyPairs = createSelector(
    getQuickSearchedKeyPairs,
    (keyPairs: KeyPair[], params: { comparer: Comparer<KeyPair> }) =>
        [...keyPairs].sort(params.comparer)
);
// #endregion

//#region Networks/Netw
const getNetworksState = createSelector(
    getOstkState,
    (state: IOstkState) =>
        state[fromNetworksReducer.OstkNetworksFeatureKey]
);
const getNetworksNetwState = createSelector(
    getNetworksState,
    (state) =>
        state[fromNetworksReducer.OstkNetworksNetwFeatureKey]
);

const getNetworksNetwList = createSelector(
    getNetworksNetwState,
    (state) =>
        state.networks
);
export const {
    selectAll: getAllNetworksNetw,
} = fromNetworksReducer.networksNetwAdapter.getSelectors(getNetworksNetwList);
export const getNetworkById = createSelector(
    getAllNetworksNetw,
    (networks: Network[], props: { networkId: string }) =>
        networks.find(n => n.id === props.networkId)
);
export const getNetworksNetwLoadingStatus = createSelector(
    getNetworksNetwState,
    (state) =>
        state.isLoading
);
export const getNetworksNetwLoadedStatus = createSelector(
    getNetworksNetwState,
    (state) =>
        state.isLoaded
);
export const getNetworksNetwEmptyStatus = createSelector(
    getNetworksNetwState,
    (state) =>
        state.isEmpty
);
export const getNetworksNetwIsDataValid = createSelector(
    getNetworksNetwState,
    (state) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);
export const getNetworksNetwOupsStatus = createSelector(
    getNetworksNetwState,
    (state) =>
        state.isOups
);
export const getSelectedNetwork = createSelector(
    getNetworksNetwState,
    (state) =>
        state.selectedNetwork
);
export const getQuickSearchedNetworks = createSelector(
    getNetworksNetwState,
    getAllNetworksNetw,
    (state: IOstkNetworksNetwState, networks: Network[]) =>
        filterQuickSearch(networks, state.qsWord, OstkConstants.networksNetwQSFields, (netw: Network) =>
        {
            const subToSearched = (netw.subnets ?? []).map(s => [s.name, s.cidr]).reduce((x, y) => x.concat(y)).join('|');

            return subToSearched.includes(state.qsWord);
        })
);
export const getPipelinedNetworks = createSelector(
    getQuickSearchedNetworks,
    (networks: Network[], params: { comparer: Comparer<Network> }) =>
        [...networks].sort(params.comparer)
);
export const getNetworksNetwPoolState = createSelector(
    getNetworksNetwState,
    (state) =>
        state.poolOptions
);
export const {
    selectAll: getPoolOptions,
} = fromNetworksReducer.networksNetwPoolAdapter.getSelectors(getNetworksNetwPoolState);
//#endregion

//#region Networks/Fips
const getNetworksFipsState = createSelector(
    getNetworksState,
    (state) =>
        state[fromNetworksReducer.OstkNetwFipsFeatureKey]
);

const getNetworksFipsList = createSelector(
    getNetworksFipsState,
    (state) =>
        state.floatingIps
);
export const {
    selectAll: getAllNetworksFips,
} = fromNetworksReducer.OstkNetwFipsAdapter.getSelectors(getNetworksFipsList);
export const getNetworksFipsLoadingStatus = createSelector(
    getNetworksFipsState,
    (state) =>
        state.isLoading
);
export const getNetworksFipsLoadedStatus = createSelector(
    getNetworksFipsState,
    (state) =>
        state.isLoaded
);
export const getNetworksFipsEmptyStatus = createSelector(
    getNetworksFipsState,
    (state) =>
        state.isEmpty
);
export const getNetworksFipsIsDataValid = createSelector(
    getNetworksFipsState,
    (state) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);
export const getNetworksFipsOupsStatus = createSelector(
    getNetworksFipsState,
    (state) =>
        state.isOups
);
export const getQuickSearchedNetworksFips = createSelector(
    getNetworksFipsState,
    getAllNetworksFips,
    (state, floatingIps: FloatingIp[]) =>
        filterQuickSearch(floatingIps, state.qsWord, OstkConstants.networksFipsQSFields)
);
export const getPipelinedNetworksFips = createSelector(
    getQuickSearchedNetworksFips,
    (floatingips: FloatingIp[], params: { comparer: Comparer<FloatingIp> }) =>
        [...floatingips].sort(params.comparer)
);
export const getFloatingIpById = createSelector(
    getAllNetworksFips,
    (floatingIps: FloatingIp[], props: { fipId: string }) =>
        floatingIps.find((fip: FloatingIp) => fip.id === props.fipId)
);
//#endregion
