import { Capacity } from './capacity';

export class VolumetricVariation
{
    public client: string;
    public prevVolumetry: number;
    public currVolumetry: number;
    public evolution: number = 0;
    public symbol: string = '';
    public status: '' | 'entering' | 'outgoing' = '';
    public low: boolean = false;

    constructor(client: string, prevVolumetry: number, currVolumetry: number)
    {
        this.client = client;
        this.prevVolumetry = prevVolumetry;
        this.currVolumetry = currVolumetry;

        this.calcEvolution();
    }

    public calcEvolution (): void
    {
        if (this.prevVolumetry === undefined)
        {
            this.evolution = Number.POSITIVE_INFINITY;
            this.symbol = 'fa-arrow-up';
            this.status = 'entering';
        } else if (this.prevVolumetry === 0)
        {
            if (this.currVolumetry === 0)
            {
                this.evolution = 0;
                this.symbol = 'fa-equals';
                this.status = '';
            } else
            {
                this.evolution = Number.POSITIVE_INFINITY;
                this.symbol = 'fa-arrow-up';
                this.status = 'entering';
            }

        } else
        {
            if (this.currVolumetry === undefined)
            {
                this.evolution = Number.NEGATIVE_INFINITY;
                this.symbol = 'fa-arrow-down';
                this.status = 'outgoing';
            } else
                if (this.currVolumetry === 0)
                {
                    this.evolution = -1;
                    this.symbol = 'fa-arrow-down';
                } else
                {
                    const diff: number = this.currVolumetry - this.prevVolumetry;

                    if (diff !== 0)
                    {
                        this.evolution = diff / this.prevVolumetry;
                        this.symbol = this.evolution > 0 ? 'fa-arrow-up' : 'fa-arrow-down';
                    } else
                    {
                        this.evolution = 0;
                        this.symbol = 'fa-equals';
                    }
                }
        }

        this.low = Math.abs(this.evolution) < 0.001;
    }
}
