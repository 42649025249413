import { EnumVersionIp } from '@apps/ostk/ostk.constants';
import { AllocationPool, HostRoute } from '.';

export class CreateNetwork
{
    adminStateUp: boolean;
    name: string;
    subnet: CreateSubnet;

    public static mapperFromObject(source: any): CreateNetwork
    {
        const target = new CreateNetwork();

        target.name = source.name;
        // target.adminStateUp = ?
        target.subnet = CreateSubnet.mapperFromObject(source);

        return target;
    }
}
export class CreateSubnet
{
    subnetName: string;
    enableDhcp: boolean;
    cidr: string;
    ipVersion: number;
    gatewayIp: string;
    enableGateway: boolean;
    defaultGateway: boolean;
    allocationPools: AllocationPool[];
    dnsNameServers: string[];
    hostRoutes: HostRoute[];

    public static mapperFromObject(source: any): CreateSubnet
    {
        const target = new CreateSubnet();

        target.subnetName = source.subnetName;
        target.ipVersion = source.ipVersion === EnumVersionIp.IPV4 ? 4 : 6;

        const ipVersionGroup = target.ipVersion === 4 ? 'ipv4Group' : 'ipv6Group';
        target.cidr = source[ipVersionGroup].cidr;
        target.enableGateway = source.enableGateway;
        target.gatewayIp = source[ipVersionGroup].gatewayIp;

        target.enableDhcp = source.enableDhcp;
        if (source.enableDhcp)
        {
            target.allocationPools = [...source[ipVersionGroup].allocationPools];
            target.dnsNameServers = [...source[ipVersionGroup].dnsServers
                .map((obj: {dnsServer: string}) => obj.dnsServer)];
            target.hostRoutes = [...source[ipVersionGroup].hostRoutes];
        }

        return target;
    }
}
