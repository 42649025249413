import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';

import { catchError, map, Observable, of } from 'rxjs';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { AppConstants } from '@apps/app.constants';

import { DashCountersApi } from './models/back/dash-counters.api';
import { EntityApi } from './models/back/entity-api';
import { UserApi } from './models/back/user-api';
import { ApplicationDefaultRole, Entity } from '../domain/models/back/entity';
import { SiteApi } from './models/back/site-api';
import { UploadFileState } from '@common/domain/models/upload-file-state';
import { MediaTypes } from '@common/services';
import { AppliRole } from '@apps/back/models';

@Injectable({
    providedIn: 'root'
})
export class EntitiesMiscApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlAuth;
        this.segmentApi = '/auth/entities';
    }

    getCounters (): Observable<DashCountersApi>
    {
        return this.get<DashCountersApi>('/count');
    }

    retrieve (id: string): Observable<EntityApi>
    {
        return this.get<EntityApi>(`/${id}`);
    }

    getUsers (id: string): Observable<UserApi[]>
    {
        return this.get<UserApi[]>(`/${id}/users`);
    }

    updateIdentity (entity: Entity): Observable<EntityApi>
    {
        return this.put<EntityApi>('', entity);
    }

    getReferents (entityId: string): Observable<UserApi[]>
    {
        return this.get<UserApi[]>(`/${entityId}/referents`);
    }

    getSites (entityId: string): Observable<SiteApi[]>
    {
        return this.get<SiteApi[]>(`/${entityId}/sites`);
    }

    getReptUsers (entityId: string): Observable<UserApi[]>
    {
        return this.get<UserApi[]>(`/${entityId}/recipients`);
    }

    updateHabs (entityId: string, habs: ApplicationDefaultRole[]): Observable<EntityApi>
    {
        return this.put<EntityApi>(`/${entityId}/habs`, habs);
    }

    addHabilitations (entityId: string, appRoles: ApplicationDefaultRole[]): Observable<EntityApi>
    {
        return this.patch<EntityApi>(`/${entityId}/habs`, appRoles);
    }

    removeHabilitations (entityId: string, appRoles: ApplicationDefaultRole[]): Observable<Entity>
    {
        return this.patch(`/${entityId}/habs/remove`, appRoles);
    }

    uploadSdmAlias (file: File): Observable<UploadFileState>
    {
        return this.upload('/sdmalias', file);
    }

    export (qs: string, format: MediaTypes): Observable<HttpEvent<Blob>>
    {
        return this.download(`/export${qs}`, format);
    }

    exportEntityUsers (entityId: string, format: MediaTypes): Observable<HttpEvent<Blob>>
    {
        return this.download(`/${entityId}/users/export`, format);
    }
}
