import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import * as fromRootImpersonateActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';

import { IIts2DashSharedState, Its2DashSharedInitialState } from './shared.state';
import * as fromSharedActions from './shared.actions';

export const Its2DashboardSharedReducer = createReducer(
    Its2DashSharedInitialState,
    on(
        fromRootImpersonateActions.RootImpersonateSet,
        fromRootImpersonateActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => Its2DashSharedInitialState
    ),
    on(
        fromSharedActions.Its2DashRangeSet,
        (state: IIts2DashSharedState, { since, until }: {since: DateTime, until: DateTime}) =>
            ({
                ...state,
                since,
                until
            })
    )
);