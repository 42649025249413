import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, filter, withLatestFrom, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';

import * as fromSnapshotsActions from './snap.actions';
import { SnapshotListDomainService, VolumeMiscDomainService } from '../../../domain';
import { Snapshot } from '../../../models/volumes';
import { IAuroraState } from '@apps/aurora.state';
import { getVolumesSnapIsDataValid } from '../../ostk-volumes.reducer';
import { NotfGravity, NotificationService, NotifType } from '@common/services';

@Injectable()
export class OstkVolumesSnapEffects
{
    constructor (
        private actions: Actions,
        private store: Store<IAuroraState>,
        private snapshotListDomainService: SnapshotListDomainService,
        private volumeMiscDomainService: VolumeMiscDomainService,
        private notificationService: NotificationService
    )
    {}

    ostkVolumesSnapRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromSnapshotsActions.OstkVolumesSnapListRequested),
                withLatestFrom(this.store.select(getVolumesSnapIsDataValid)),
                filter(([, valid]) => !valid),
                switchMap(() =>
                    this.snapshotListDomainService.getSnapshots()
                        .pipe(
                            map((snapshots: Snapshot[]) =>
                                fromSnapshotsActions.OstkVolumesSnapListSucceeded({ snapshots: snapshots })
                            ),
                            catchError((failure: any) =>
                                of(fromSnapshotsActions.OstkVolumesSnapListFailed({ failure }))
                            )
                        )
                )
            )
    );

    ostkVolumesSnapDeleteRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromSnapshotsActions.OstkVolumesSnapDeleteRequested),
                    switchMap(({ snapshot }) =>
                        this.volumeMiscDomainService.deleteSnapshot(snapshot.id)
                            .pipe(
                                map((snapshotId: string) =>
                                    fromSnapshotsActions.OstkVolumesSnapDeleteSucceeded({ snapshotId: snapshotId })
                                ),
                                catchError((failure: any) =>
                                    of(fromSnapshotsActions.OstkVolumesSnapDeleteFailed({ failure }))
                                )
                            )
                    )
                )
    );

    ostkVolumesVoluDeleteSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromSnapshotsActions.OstkVolumesSnapDeleteSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.SNAPSHOT.DELETE.NOTIF.TITLE',
                                'OSTK.SNAPSHOT.DELETE.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );
}
