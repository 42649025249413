import { DateTime } from 'luxon';

import { RequestsEvolutionSummaryApi } from '@apps/its2/api/models';
import { Its2Constants } from '@apps/its2/its2.constants';

export class RequestsEvolutionSummary
{
    public urgencyTradKey: string;

    constructor (public dateUtc: DateTime, public urgencyId: number, public countCreated: number, public countClosed: number)
    {
        this.urgencyTradKey = Its2Constants.getUrgencyTradKey(urgencyId);
    }
}

export class DomainRequestsEvolutionSummary extends RequestsEvolutionSummary
{
    public static mapperFromApi (source: RequestsEvolutionSummaryApi): RequestsEvolutionSummary
    {
        const target = new RequestsEvolutionSummary(DateTime.fromISO(source.dateUtc), source.urgencyId, source.countCreated, source.countClosed);

        return target;
    }
}
