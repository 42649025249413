export class Counters
{
    constructor (public total: number = null, public active: number = null)
    {
    }
}

export class DashCounters
{
    constructor (public external: Counters, public internal: Counters)
    {
    }
}
