export enum RangeKey
{
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY',
    LAST24HOURS = 'LAST24HOURS',
    LAST7DAYS = 'LAST7DAYS',
    CURRENT7DAYS = 'CURRENT7DAYS',
    LAST28DAYS = 'LAST28DAYS',
    LAST30DAYS = 'LAST30DAYS',
    LASTMONTH = 'LASTMONTH',
    CURRENTMONTH = 'CURRENTMONTH',
    LAST6MONTH = 'LAST6MONTH'
}
