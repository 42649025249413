import { createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';

import { Comparer, filterQuickSearch } from '@common/services/utils.service';
import { Image, ImageSnapshot } from '@apps/ostk/domain/models';
import * as fromImagesReducer from './images/images.reducer';
import { getOstkState, IOstkState } from './ostk.reducer';
import { OstkConstants } from '../ostk.constants';

//#region Images
const getImagesState = createSelector(
    getOstkState,
    (state: IOstkState) =>
        state[fromImagesReducer.OstkImagesFeatureKey]
);
const getImagesImgeState = createSelector(
    getImagesState,
    (state: fromImagesReducer.IOstkImagesState) =>
        state[fromImagesReducer.OstkImagesImgeFeatureKey]
);
const getImagesCollectionState = createSelector(
    getImagesImgeState,
    (state) => state.images
);
export const {
    selectAll: getAllImages,
} = fromImagesReducer.imagesAdapter.getSelectors(getImagesCollectionState);
export const getImagesLoadingStatus = createSelector(
    getImagesImgeState,
    (state) => state.isLoading
);
export const getImagesLoadedStatus = createSelector(
    getImagesImgeState,
    (state) => state.isLoaded
);
export const getImagesEmptyStatus = createSelector(
    getImagesImgeState,
    (state) => state.isEmpty
);

export const getImagesOupsStatus = createSelector(
    getImagesImgeState,
    (state) => state.isOups
);
export const getImagesIsDataValid = createSelector(
    getImagesImgeState,
    (state) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);
export const getAllActiveImages = createSelector(
    getAllImages,
    (fullImages) =>
        fullImages.filter((image: Image) =>
            image.statusLabelKey)
);
export const getDetailedImage = createSelector(
    getImagesImgeState,
    (state) => state.detailedImage
);

export const countImagesPublic = createSelector(
    getAllImages,
    (fullImages) =>
        fullImages.filter((r) => r.isPublic).length
);
export const countImagesNotPublic = createSelector(
    getAllImages,
    (fullImages) =>
        fullImages.filter((r) => !r.isPublic).length
);
export const getImageFieldsFilter = createSelector(
    getImagesImgeState,
    (state) =>
        state.filter
);
export const getImagesAllStatuses = createSelector(
    getImagesImgeState,
    (state) =>
        state.statuses
);
export const getImagesAllOperatingSystems = createSelector(
    getImagesImgeState,
    (state) =>
        state.operatingSystems
);
const getFilteredImages = createSelector(
    getImagesImgeState,
    (state) =>
        state.filteredImages
);
export const getQuickSearchedImages = createSelector(
    getImagesImgeState,
    getFilteredImages,
    (state: fromImagesReducer.IOstkImagesImgeState, images: Image[]) =>
        filterQuickSearch(images, state.qsWord, OstkConstants.imagesImgeQSFields)
);
export const getPipelinedImages = createSelector(
    getQuickSearchedImages,
    (filteredImages: Image[], params: { comparer: Comparer<Image> }) =>
        [...filteredImages].sort(params.comparer)
);
//#endregion

//#region Snapshots
const getImagesSnapState = createSelector(
    getImagesState,
    (state: fromImagesReducer.IOstkImagesState) =>
        state[fromImagesReducer.OstkImagesSnapFeatureKey]
);
export const getAllImageSnapshots = createSelector(
    getImagesSnapState,
    (state) => state.snapshots
);
export const getSnapshotsEmptyStatus = createSelector(
    getImagesSnapState,
    (state) => state.isEmpty
);
export const getImageSnapshotsById = createSelector(
    getImagesSnapState,
    (state, props: { instanceId: string }) => state.snapshots
        .filter((snap: ImageSnapshot) =>
            snap.instanceId === props.instanceId
        )
);
export const getQuickSearchedSnapshots = createSelector(
    getImagesSnapState,
    getAllImageSnapshots,
    (state: fromImagesReducer.IOstkImagesSnapState, snaps: ImageSnapshot[]) =>
        filterQuickSearch(snaps, state.qsWord, OstkConstants.imagesSnapQSFields)
);
export const getPipelinedSnapshots = createSelector(
    getQuickSearchedSnapshots,
    (snapshots: ImageSnapshot[], params: { comparer: Comparer<ImageSnapshot> }) =>
        [...snapshots].sort(params.comparer)
);
//#endregion
