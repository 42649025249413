import { createAction, props } from '@ngrx/store';

import { FloatingIp } from '@apps/ostk/domain/models/network';

export const OstkNetworksFipsListRequested = createAction(
    '[OSTK-FIPS] Ostk Networks Fips List Requested'
);
export const OstkNetworksFipsListSucceeded = createAction(
    '[OSTK-FIPS] Ostk Networks Fips List Succeeded',
    props<{ floatingIps: FloatingIp[]; }>()
);
export const OstkNetworksFipsListFailed = createAction(
    '[OSTK-FIPS] Ostk Networks Fips List Failed',
    props<{ err?: any; }>()
);
export const OstkNetworksFipsQsWordSet = createAction(
    '[OSTK-FIPS] Ostk Networks Fips QsWord Set',
    props<{ qsWord: string; }>()
);
export const OstkNetworksFipsMapRequested = createAction(
    '[OSTK-FIPS] Ostk Networks Fips Map Requested',
    props<{ id: string, serverId: string; }>()
);
export const OstkNetworksFipsMapSucceded = createAction(
    '[OSTK-FIPS] Ostk Networks Fips Map Succeded',
    props<{ floatingIp: FloatingIp; }>()
);
export const OstkNetworksFipsMapFailed = createAction(
    '[OSTK-FIPS] Ostk Networks Fips Map Failed',
    props<{ failure: any; }>()
);
export const OstkNetworksFipsUnmapRequested = createAction(
    '[OSTK-FIPS] Ostk Networks Fips Unmap Requested',
    props<{ id: string; }>()
);
export const OstkNetworksFipsUnmapSucceded = createAction(
    '[OSTK-FIPS] Ostk Networks Fips Unmap Succeded',
    props<{ floatingIp: FloatingIp; }>()
);
export const OstkNetworksFipsUnmapFailed = createAction(
    '[OSTK-FIPS] Ostk Networks Fips Unmap Failed',
    props<{ failure: any; }>()
);
export const OstkNetworksFipsDeleteRequested = createAction(
    '[OSTK-FIPS] Ostk Networks Fips Delete Requested',
    props<{ id: string }>()
);
export const OstkNetworksFipsDeleteSucceeded = createAction(
    '[OSTK-FIPS] Ostk Networks Fips Delete Succeeded',
    props<{ id: string }>()
);
export const OstkNetworksFipsDeleteFailed = createAction(
    '[OSTK-FIPS] Ostk Networks Fips Delete Failed',
    props<{ failure?: any}>()
);
export const OstkNetworksFipsAllocateRequested = createAction(
    '[OSTK-FIPS] Ostk Networks Fips Allocate Requested',
    props<{ poolId: string }>()
);
export const OstkNetworksFipsAllocateSucceeded = createAction(
    '[OSTK-FIPS] Ostk Networks Fips Allocate Succeeded',
    props<{ floatingIp: FloatingIp; }>()
);
export const OstkNetworksFipsAllocateFailed = createAction(
    '[OSTK-FIPS] Ostk Networks Fips Allocate Failed',
    props<{ failure: any; }>()
);
