import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { Alert } from '../../domain/models';
import * as fromNotfAlertActions from './alert.actions';

export const NotfAlertFeatureKey = 'alert';

export interface INotfAlertState extends EntityState<Alert>
{
}

export const adapter: EntityAdapter<Alert> = createEntityAdapter<Alert>({
    selectId: (alert: Alert) => alert.id
});

export const initialState: INotfAlertState = adapter.getInitialState({
});

export const NotfAlertReducer = createReducer(
    initialState,
    on(
        fromNotfAlertActions.NotfAlertLoadSucceeded,
        (state: INotfAlertState, { alerts }: { alerts: Alert[] }) =>
            adapter.addMany(alerts, adapter.removeAll(state))
    )
);
