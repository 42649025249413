import { Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { IAuroraState } from '@apps/aurora.state';
import * as fromNotfStore from '@apps/notf/store';
import { Alert } from '@apps/notf/domain/models';
import { PersonalDataService, LanguageService } from '@common/services';

@Component({
    selector: 'aa-alert',
    templateUrl: './alert.component.html',
    styleUrls: [
        './alert.component.scss'
    ],
    animations: [
        trigger('showHide', [
            state('true', style({
                opacity: 1,
                bottom: '25px'
            })),
            state('false', style({
                opacity: 0,
                bottom: '-25px'
            })),
            transition('true <=> false', [
                animate('1s cubic-bezier(0.35, 0, 0.25, 1)')
            ])
        ])
    ]
})
export class AlertComponent
{
 public isShown: Observable<boolean> = of(false);
    public alert: Observable<Alert> = of();

    constructor (
        private store: Store<IAuroraState>,
        private personalDataService: PersonalDataService,
        public languageService: LanguageService
    )
    {
        this.isShown = this.store
            .pipe(
                select(fromNotfStore.selectTotalAlert),
                map((nb: number): boolean =>
                    nb > 0
                )
            );
        this.alert = this.store
            .pipe(
                select(fromNotfStore.getFirstAlert),
                filter((alert: Alert): boolean =>
                    alert !== null
                )
            );
    }

    onClose (id: string): void
    {
        this.personalDataService.setReadAlerts(id);
        this.store.dispatch(fromNotfStore.NotfAlertStoreActions.NotfAlertLoadRequested());
    }
}
