export class GuardModalConfig
{
    static titleKeyDef: string = 'GUARD.MODAL.TITLE';
    static msgOkKeyDef: string = 'GUARD.MSG.YES';
    static msgNoKeyDef: string = 'GUARD.MSG.NO';
    static btnOkKeyDef: string = 'BUTTON.YES';
    static btnNoKeyDef: string = 'BUTTON.NO';

    private _titleKey: string;
    private _msgKey?: string;
    private _msgOkKey?: string;
    private _msgNoKey?: string;
    private _btnOkKey: string;
    private _btnNoKey: string;
    public data?: any;

    get titleKey (): string
    {
        return this._titleKey || GuardModalConfig.titleKeyDef;
    }
    set titleKey (value: string)
    {
        this._titleKey = value;
    }

    get msgKey ()
    {
        return this._msgKey;
    }
    set msgKey (value)
    {
        this._msgKey = value;
    }

    get msgOkKey ()
    {
        return this._msgOkKey || GuardModalConfig.msgOkKeyDef;
    }
    set msgOkKey (value)
    {
        this._msgOkKey = value;
    }

    get msgNoKey ()
    {
        return this._msgNoKey || GuardModalConfig.msgNoKeyDef;
    }
    set msgNoKey (value)
    {
        this._msgNoKey = value;
    }

    get btnOkKey ()
    {
        return this._btnOkKey || GuardModalConfig.btnOkKeyDef;
    }
    set btnOkKey (value)
    {
        this._btnOkKey = value;
    }

    get btnNoKey ()
    {
        return this._btnNoKey || GuardModalConfig.btnNoKeyDef;
    }
    set btnNoKey (value)
    {
        this._btnNoKey = value;
    }
}
