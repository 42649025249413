import { Injectable, ErrorHandler } from '@angular/core';

import * as Sentry from '@sentry/browser';

import { AppConstants } from '@apps/app.constants';

@Injectable({
    providedIn: 'root'
})
export class SentryErrorHandler implements ErrorHandler
{
    handleError (error: any)
    {
        if (AppConstants.Configuration.sentryDsn)
        {
            Sentry.captureException(error.originalError || error.error || error);
        }

        // if (error.message && error.message.startsWith('ExpressionChangedAfterItHasBeenCheckedError'))
        // {
        //     console.error(error.message);
        // }
        // else
        // {
        //     console.error(error);
        // }
    }
}
