import { createAction, props } from '@ngrx/store';

import { ImageCopy } from '@apps/bckp/domain/models';
import { CopiesCurrFilter } from '@apps/bckp/models';

export const BckpCopiesCurrODataRequested = createAction(
    '[BCKP-COPIES] Bckp Copies Curr OData Requested'
);
export const BckpCopiesCurrODataSucceeded = createAction(
    '[BCKP-COPIES] Bckp Copies Curr OData Succeeded',
    props<{ copies: ImageCopy[] }>()
);
export const BckpCopiesCurrODataFailed = createAction(
    '[BCKP-COPIES] Bckp Copies Curr OData Failed'
);
export const BckpCopiesCurrQsWordSet = createAction(
    '[BCKP-COPIES] Bckp Copies Curr QsWord Set',
    props<{ qsWord: string }>()
);
export const BckpCopiesCurrFilterApplied = createAction(
    '[BCKP-COPIES] Bckp Copies Curr Filter Applied',
    props<{ filter: CopiesCurrFilter }>()
);
export const BckpCopiesCurrCleared = createAction(
    '[BCKP-COPIERS] Bckp Copies Curr Cleared'
);
export const BckpCopiesCurrODataInitialized = createAction(
    '[BCKP-COPIES] Bckp Copies Curr OData Initialized',
    props<{ scrollSize: number, filter: CopiesCurrFilter }>()
);

