import { createAction, props } from '@ngrx/store';

import { RequestsCatalogCounter } from '@apps/its2/domain/models';

export const Its2DashBreakdownIncidentsCountersRequested = createAction(
    '[ITS2-DASH] Its2 Dash Breakdown Incidents Counters Requested'
);
export const Its2DashBreakdownIncidentsCountersSucceeded = createAction(
    '[ITS2-DASH] Its2 Dash Breakdown Incidents Counters Succeeded',
    props<{ counters: RequestsCatalogCounter[] }>()
);

export const Its2DashBreakdownIncidentsCountersFailed = createAction(
    '[ITS2-DASH] Its2 Dash Breakdown Incidents Counters Failed',
    props<{ err: any }>()
);

export const Its2DashBreakdownServicesCountersRequested = createAction(
    '[ITS2-DASH] Its2 Dash Breakdown Services Counters Requested'
);
export const Its2DashBreakdownServicesCountersSucceeded = createAction(
    '[ITS2-DASH] Its2 Dash Breakdown Services Counters Succeeded',
    props<{ counters: RequestsCatalogCounter[] }>()
);

export const Its2DashBreakdownServicesCountersFailed = createAction(
    '[ITS2-DASH] Its2 Dash Breakdown Services Counters Failed',
    props<{ err: any }>()
);
