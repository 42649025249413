import { Injector, Input, Directive } from '@angular/core';
import { AsyncValidatorFn, ControlContainer, FormGroup, ValidatorFn } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { UtilsService } from '@common/services';
import { CustomErrorKey } from '@ui/components/form9/common/error-msg.service';

import { ControlValueAccessorBase } from './control-value-accessor-base';
import { ValidationResult } from '../common/types';
import { ControlStatus } from '../common/control-status';

@Directive()
export abstract class InputBase<T> extends ControlValueAccessorBase<T>
{
    @Input() ctrlName: string;
    @Input() initialValue: T;
    @Input() label: string;
    @Input() required: boolean;
    @Input() isDisabled: boolean = false;
    @Input() customMsg: string = null;
    public formGroup: FormGroup;
    public fieldId: string;

    constructor (
        injector: Injector,
        protected controlContainer: ControlContainer
    )
    {
        super(injector);
    }

    public get controlStatus (): Observable<ControlStatus>
    {
        return this._invalid()
            .pipe(
                distinctUntilChanged((prev, curr): boolean => prev === curr),
                map(([invalid, error]: [boolean, string]): ControlStatus =>
                    new ControlStatus(
                        this.control.dirty,
                        this.control.dirty && invalid,
                        error
                    )
                )
            );
    }

    public get pristine (): boolean
    {
        return this.control.pristine;
    }

    protected initControl (validators: ValidatorFn | ValidatorFn[] = [], asyncValidators: AsyncValidatorFn | AsyncValidatorFn[] = []): void
    {
        this.formGroup = this.controlContainer.control as FormGroup;
        this.control = this.formGroup.get(this.ctrlName);
        this.control.setValidators(validators);
        this.control.setAsyncValidators(asyncValidators);
        this.control.updateValueAndValidity({ emitEvent: false });
    }

    private _invalid (): Observable<[boolean, string]>
    {
        const errors: ValidationResult = this.control?.errors || {};
        const keys: string[] = Object.keys(errors);
        let errorMessage: string = null;

        if (UtilsService.isNonEmptyArray(keys))
        {
            if (keys.includes(CustomErrorKey))
            {
                errorMessage = this.errorMsgService.getCustomMessage(errors);
            }
            else
            {
                errorMessage = Object.keys(errors)
                    .filter((errorKey: string): boolean =>
                        errorKey !== CustomErrorKey
                    )
                    .map((errorKey: string): string =>
                        this.errorMsgService.getStandardMessage(errors, errorKey)
                    )[0];
            }

            return of([Object.keys(errors).length > 0, errorMessage]);
        }
        else
        {
            return of([false, null]);
        }
    }
}
