import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiBtnModule } from '@ui/components/buttons/ui.btn.module';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { PopupCalendarComponent } from './components/popup-calendar/popup-calendar.component';
import { PopupRangeComponent } from './popup-range.component';
import { PopupNavigationComponent } from './components/popup-navigation/popup-navigation.component';
import { HourSelectorComponent } from './components/hour-selector/hour-selector.component';
import { PopupButtonsComponent } from './components/popup-buttons/popup-buttons.component';
import { PopupButtonComponent } from './components/popup-buttons/popup-button/popup-button.component';

@NgModule({
    declarations: [
        PopupCalendarComponent,
        PopupRangeComponent,
        PopupNavigationComponent,
        HourSelectorComponent,
        PopupButtonsComponent,
        PopupButtonComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UiBtnModule,
        TranslateModule
    ],
    exports: [
        PopupCalendarComponent
    ],
    providers: [
        TranslatePipe
    ]
})
export class PopupRangeModule
{ }
