import { ODataSearch } from '@common/api/qs';

import { AppConstants } from '../../app.constants';

export class ODataIts2RequestsSearch extends ODataSearch
{
    constructor (searchValue: string, incrSize: number = AppConstants.QSSearchTop)
    {
        super(searchValue, incrSize);

        if (this.searchValue !== null)
        {
            this.values.push({ key: '$count', value: true });
            this.values.push({ key: '$search', value: this.searchValue });
        }
        else
        {
            const idx = this.values.findIndex((item: { key: string, value: any }) => item.key === '$search');

            this.values = this.values.splice(idx, 1);
        }
    }
}
