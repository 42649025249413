import { DateTime } from 'luxon';

import { DatesService } from 'src/app/_common/services';
import { ITimelineItem } from 'src/app/_ui/misc/timeline/models/timeline-item';
import { InstanceLogApi } from '../../../api/models/instance';

export class InstanceTimelineItem implements ITimelineItem
{
    author: string;
    message: string;
    eventDateUtc: DateTime;
    eventType: string;
    side: string;

    public static mapperFromApi (source: InstanceLogApi): InstanceTimelineItem
    {
        const target = new InstanceTimelineItem();

        Object.assign(target, source);

        target.eventDateUtc = DatesService.UtcStringToDateTimeHourStatic(source.eventDateUtc);

        return target;
    }
}
