import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { switchMap, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { IAuroraState } from '@apps/aurora.state';

import * as fromFlavorsActions from './flavors.actions';
import { Flavor } from '../../domain/models';
import { FlavorMiscDomainService } from '../../domain/flavor-misc-domain.service';
import { getFlavorsIsDataValid } from '../ostk-flavor.reducer';

@Injectable()
export class OstkFlavorsEffects
{
    constructor (
        private actions: Actions,
        private store: Store<IAuroraState>,
        private flavorMiscDomainService: FlavorMiscDomainService )
    { }

    ostkFlavorsListRequested: Observable<Action> = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromFlavorsActions.OstkFlavorsListRequested),
                withLatestFrom(this.store.select(getFlavorsIsDataValid)),
                filter(([, valid]) => !valid),
                switchMap(() =>
                    this.flavorMiscDomainService.getFlavors()
                        .pipe(
                            switchMap((flavors: Flavor[]) =>
                                [
                                    fromFlavorsActions.OstkFlavorsListSucceeded({ flavors })
                                ]
                            ),
                            catchError((err) =>
                                of(fromFlavorsActions.OstkFlavorsListFailed(err))
                            )
                        )
                )
            )
    );
}
