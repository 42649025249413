import { createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';

import { getOstkState, IOstkState } from './ostk.reducer';
import * as fromFlavorsReducer from './flavors/flavors.reducer';

//#region Flavor
const getFlavorsState = createSelector(
    getOstkState,
    (state: IOstkState) =>
        state[fromFlavorsReducer.OstkFlavorsFeatureKey]
);
const getFlavorsCollectionState = createSelector(
    getFlavorsState,
    (state) =>
        state.flavors
);
export const {
    selectAll: getAllFlavors,
} = fromFlavorsReducer.flavorsAdapter.getSelectors(getFlavorsCollectionState);
export const getFlavorsLoadingStatus = createSelector(
    getFlavorsState,
    (state) =>
        state.isLoading
);
export const getFlavorsEmptyStatus = createSelector(
    getFlavorsState,
    (state) =>
        state.isEmpty
);
export const getFlavorsOupsStatus = createSelector(
    getFlavorsState,
    (state) =>
        state.isOups
);
export const getFlavorsLoadedStatus = createSelector(
    getFlavorsState,
    (state) =>
        state.isLoaded
);
export const getFlavorsIsDataValid = createSelector(
    getFlavorsState,
    (state) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);
export const getFlavorById = createSelector (
    getFlavorsState,
    (state: fromFlavorsReducer.IOstkFlavorsState, flavorId: string) =>
        state.flavors.entities[flavorId]
);
// #endregion
