import { props, createAction } from '@ngrx/store';

import { Impersonation } from '../../models';

export const RootImpersonateSet = createAction(
    '[ROOT-IMPE] Root Impersonate Set',
    props<{ impersonation: Impersonation }>()
);

export const RootImpersonateUnset = createAction(
    '[ROOT-IMPE] Root Impersonate Unset'
);
