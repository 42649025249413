import { Action, combineReducers } from '@ngrx/store';

import { IAuroraState } from '@apps/aurora.state';

import * as fromServReducer from './serv/serv.reducer';
import * as fromHeatState from './heat/heat.state';
import * as fromHeatReducer from './heat/heat.reducer';

export * from './serv/serv.reducer';

export const BckpServersFeatureKey = 'servers';

export interface IState extends IAuroraState
{
    [BckpServersFeatureKey]: IBckpServersState;
}

export interface IBckpServersState
{
    [fromServReducer.BckpServersServFeatureKey]: fromServReducer.IBckpServersServState;
    [fromHeatState.BckpServersHeatFeatureKey]: fromHeatState.IBckpServersHeatState;
}

export function BckpServersReducers (state: IBckpServersState | undefined, action: Action)
{
    return combineReducers({
        [fromServReducer.BckpServersServFeatureKey]: fromServReducer.BckpServersServReducer,
        [fromHeatState.BckpServersHeatFeatureKey]: fromHeatReducer.BckpServersHeatReducer
    })(state, action);
}
