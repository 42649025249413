import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRootStore from '@apps/root/store';
import * as fromTagsStore from '@apps/cmdb/store/tags';
import * as fromIts2Store from '@apps/its2/store';
import { DatesService, LanguageService } from '@common/services';
import { IAuroraState } from '@apps/aurora.state';
import { AuthProfileIsCatalogSecuAuthorizedRequested } from '@apps/auth/store/profile/profile.actions';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class RootResolver implements Resolve<boolean>
{
    constructor (
        private store: Store<IAuroraState>,
        private languageService: LanguageService,
        protected datesService: DatesService
    )
    {
    }

    resolve (): Observable<boolean>
    {
        this.store.dispatch(AuthProfileIsCatalogSecuAuthorizedRequested());
        this.store.dispatch(fromTagsStore.CmdbTagsActions.CmdbTagsListRequested());
        this.store.dispatch(fromIts2Store.Its2CatalogsActions.Its2CatalogsIncidentRequested());
        this.store.dispatch(fromIts2Store.Its2CatalogsActions.Its2CatalogsServiceRequested());
        this.store.dispatch(fromRootStore.RootMiscStoreActions.RootMiscWarningsRequested());

        //TODO supprimer à l'installation du nouveau module de supervision
        this.store.dispatch(fromRootStore.RootMiscStoreActions.RootMiscWarningSet({
            id: 'prtg',
            nb: 1
        }));

        return this.languageService.loadLanguage('dash');
    }
}
