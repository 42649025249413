import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { AppConstants } from '@apps/app.constants';

import { VolumeApi } from '../models';

@Injectable({
    providedIn: 'root'
})
export class VolumeMiscApiService extends BaseMiscApiService
{
    constructor (
        httpClient: HttpClient
    )
    {
        super(httpClient);

        this.segmentRoot = AppConstants.Configuration.apiUrlOstk;
        this.segmentApi = '/ostk/volumes';

    }

    createVolume (name: string, size: number): Observable<VolumeApi>
    {
        return this.post<VolumeApi>('', { name, size });
    }

    getVolume (volumeId: string): Observable<VolumeApi>
    {
        return this.get<VolumeApi>(`/${volumeId}`);
    }

    attachVolume (volumeId: string, serverId: string): Observable<boolean>
    {
        return this.post<boolean>('/attach', { volumeId, serverId });
    }

    detachVolume (volumeId: string, serverId: string): Observable<void>
    {
        return this.post(`/${volumeId}/detach`, { serverId });
    }

    extendVolume (volumeId: string, newSize: number, serverId: string): Observable<void>
    {
        return this.put(`/${volumeId}/extend`, { newSize, serverId });
    }

    deleteVolume (volumeId: string): Observable<void>
    {
        return this.delete(`/${volumeId}`);
    }

    renameVolume (volumeId: string, newName: string): Observable<void>
    {
        return this.put(`/${volumeId}/rename`, { newName });
    }

    deleteSnapshot (snapshotId: string): Observable<string>
    {
        return this.delete(`/snap/${snapshotId}`)
            .pipe(
                map(() =>
                    snapshotId
                )
            );
    }
}
