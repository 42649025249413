import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { UiFormModule } from '../components/form/ui.form.module';
import { UiMiscModule } from '../misc/ui.misc.module';
import { UiBtnModule } from '../components/buttons/ui.btn.module';
import { IncidentComponent } from './incident/incident.component';
import { ServiceComponent } from './service/service.component';

@NgModule({
    declarations: [
        IncidentComponent,
        ServiceComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        UiFormModule,
        UiMiscModule,
        UiBtnModule
    ],
    exports: [
        IncidentComponent,
        ServiceComponent
    ]
})
export class BusinessModule
{ }
