import { DateTime } from 'luxon';

import { ColorName } from '@common/services/colors.service';
import { OpenPopinOriginator } from '@common/enum/open-popin-originator.enum';

import { FlatNode } from './models';

export enum SensorStatus
    {
    Down = 1,
    DownPartial = SensorStatus.Down << 1,
    DownAcknowledged = SensorStatus.DownPartial << 1,
    Warning = SensorStatus.DownAcknowledged << 1,
    Unusual = SensorStatus.Warning << 1,
    Paused = SensorStatus.Unusual << 1,
    Up = SensorStatus.Paused << 1,
    Unknown = SensorStatus.Up << 1
}

export enum SensorNodeType
    {
    Root = 1,
    Core = 2,
    Node = 4,
    Leaf = 8,
}


export class PrtgConstants
{
    public static Application: string = 'PrtgApi';
    public static BaseUrl = 'monitoring';
    public static BaseApi = 'prtg';
    public static Graphic = 'graphic';
    public static Sensors = 'sensors';
    public static Details = 'details';
    public static DefaultUrl: string = `/${PrtgConstants.BaseUrl}/${PrtgConstants.Graphic}`;
    public static AverageSecs: number = 60 * 60;
    public static Filter: string = 'filter';
    public static BeginningOfTime = DateTime.utc(2018).startOf('year');
    public static DataLifeTime = 7;
    // Description des données reçues
    public static RootNodeName = 'RootNode';
    public static DowntimeChannelId: number = -4;

    public static GraphicColors = new Map<string, ColorName>(
        [
            ['Down', ColorName.Danger],
            ['DownPartial', ColorName.Danger],
            ['DownAcknowledged', ColorName.Warning2],
            ['Warning', ColorName.Warning1],
            ['Unusual', ColorName.Info],
            ['Paused', ColorName.Blue],
            ['Up', ColorName.Success],
            ['Unknown', ColorName.Grey]
        ]
    );
    //     public static GraphicColors = new Map<string, string>(
    //     [
    //         ['DOWN', ColorName.Danger)],
    //         ['DOWNPARTIAL', ColorName.Danger)],
    //         ['DOWNACKNOWLEDGED', ColorName.Warning2)],
    //         ['WARNING', ColorName.Warning1)],
    //         ['UNUSUAL', ColorName.Info)],
    //         ['PAUSED', ColorName.Blue)],
    //         ['UP', ColorName.Success)],
    //         ['UNKNOWN', ColorName.Grey)]
    //     ]
    // );

    public static UpFlagStatus: number =
    SensorStatus.Unusual
        | SensorStatus.Paused
        | SensorStatus.Up
        | SensorStatus.Unknown;
    public static DownFlagStatus: number =
    SensorStatus.Down
        | SensorStatus.DownAcknowledged
        | SensorStatus.DownPartial
        | SensorStatus.Warning;

    public static UpFlagStatusArray = [SensorStatus.Unusual, SensorStatus.Paused, SensorStatus.Up, SensorStatus.Unknown];
    public static DownFlagStatusArray = [SensorStatus.Down, SensorStatus.DownAcknowledged, SensorStatus.DownPartial, SensorStatus.Warning];
    public static FullFlagStatus = Object.keys(SensorStatus)
        .map((key: any) =>
            parseInt(SensorStatus[key], 10)
        )
        .filter((id: number) =>
            !isNaN(id)
        )
        .reduce((acc: number, cur: number) =>
            // tslint:disable-next-line: no-bitwise
            acc | cur, 0
        );
    public static FullFlagStatusArray = Object.keys(SensorStatus)
        .map((key: any) =>
            parseInt(SensorStatus[key], 10)
        )
        .filter((id: number) =>
            !isNaN(id)
        )
        .reduce((acc: number[], cur: number) =>
            [...acc, cur], []
        );
    public static MinZoomLevel = 0.25;
    public static MaxZoomLevel = 7;
    public static InitZoomLevel = 1;
    public static ZoomStep = 0.5;
    public static MaxMonitoringCores = 7;
    public static ToGraphicRoute = () =>
        [
            PrtgConstants.BaseUrl,
            PrtgConstants.Graphic
        ];
    public static nodesQSFields: Array<keyof FlatNode> = [ 'status', 'monitoringKey', 'sensorId',
        'displayName', 'quickBreadcrumb', 'typeSensor' ];

    public static getFullFlagStatusArray (): number[]
    {
        return Object.keys(SensorStatus)
            .map((key: any) =>
                parseInt(SensorStatus[key], 10)
            )
            .filter((id: number) =>
                !isNaN(id)
            )
            .reduce((acc: number[], cur: number) =>
                [...acc, cur], []
            );
    }

    public static ToSensorsRoute = () =>
        [
            PrtgConstants.BaseUrl,
            PrtgConstants.Sensors
        ];

    public static ToSensorsDetailsRoute = (monitoringKey: string, sensorId: number) =>
        [
            PrtgConstants.BaseUrl,
            PrtgConstants.Sensors,
            { outlets: { [PrtgConstants.Details]: [PrtgConstants.Details, OpenPopinOriginator.PRTG_SENSORS, monitoringKey, sensorId] } }
        ];

    public static ToGraphicDetailsRoute = (monitoringKey: string, sensorId: number) =>
        [
            PrtgConstants.BaseUrl,
            PrtgConstants.Graphic,
            { outlets: { [PrtgConstants.Details]: [PrtgConstants.Details, OpenPopinOriginator.PRTG_GRAPHIC, monitoringKey, sensorId] } }
        ];
}
