import { AppConstants } from '@apps/app.constants';
import { QdKeys } from '@apps/ostk/quotas.constants';

import { QuotaApi } from '../../../api/models';

export class Quota
{
    public max: number;
    public key: string;
    public used: number;
    private _needed: number = 0;
    get needed ()
    {
        return this._needed;
    }
    set needed (value)
    {
        this._needed = value;
        this._enabled = this.used + this._needed <= this.max;
    }
    private _enabled: boolean;
    get enabled ()
    {
        return this._enabled;
    }

    public static mapperFromObject (source: any): Quota
    {
        const target = new Quota();

        Object.assign(target, source);

        return target;
    }

    public static mapperFromApi (source: QuotaApi): Quota
    {
        const target = new Quota();

        Object.assign(target, source);

        target.needed = 0;
        if (source.key === QdKeys.VRAM)
        {
            target.max = source.max / AppConstants.MilleVingtQuatre;
            target.used = source.used / AppConstants.MilleVingtQuatre;
        }

        return target;
    }
}
