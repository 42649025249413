import { on, createReducer } from '@ngrx/store';

import * as fromAuthLoginActions from 'src/app/_apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from 'src/app/_apps/auth/store/profile/profile.actions';
import * as fromRootStore from 'src/app/_apps/root/store';
import { LoadBackupVboDashboardCountServersSucceeded, LoadBackupVboDashboardLastSessionSucceeded, LoadBackupVboDashboardStatusPerRangeSucceeded, LoadBackupVboDashboardTicketingSucceeded, LoadBackupVboDashboardTotalCapacitySucceeded, LoadBackupVboDashboardVariationsSucceeded } from './vbo-dashboard.actions';
import { CountServers, LastSession, StatusPerRange, TotalCapacity, Variations } from '../../../dashboard/models';
import { RequestsSummaryCounter } from '@common/models';

export const BckpVboDashboardFeatureKey = 'vbo-dashboard';

export interface IBckpVboDashboardState
{
    lastSession: LastSession;
    statusPerRange: StatusPerRange;
    countServers: CountServers;
    totalCapacity: TotalCapacity;
    ticketing: RequestsSummaryCounter;
    variations: Variations;
}

const InitialState: IBckpVboDashboardState = {
    statusPerRange: new StatusPerRange(),
    lastSession: new LastSession(),
    countServers: new CountServers(),
    totalCapacity: new TotalCapacity(),
    ticketing: null,
    variations: new Variations()
};

export const VboDashboardReducer = createReducer(
    InitialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => InitialState
    ),
    on(
        LoadBackupVboDashboardStatusPerRangeSucceeded,
        (state: IBckpVboDashboardState, { statusPerRange }) => ({
            ...state,
            statusPerRange: statusPerRange
        })
    ),
    on(
        LoadBackupVboDashboardLastSessionSucceeded,
        (state: IBckpVboDashboardState, { lastSession }) =>
        {
            return ({
                ...state,
                lastSession: lastSession
            });
        }
    ),
    on(
        LoadBackupVboDashboardCountServersSucceeded,
        (state: IBckpVboDashboardState, { countServers }) => ({
            ...state,
            countServers: countServers
        })
    ),
    on(
        LoadBackupVboDashboardTotalCapacitySucceeded,
        (state: IBckpVboDashboardState, { totalCapacity }) => ({
            ...state,
            totalCapacity: totalCapacity
        })
    ),
    on(
        LoadBackupVboDashboardTicketingSucceeded,
        (state: IBckpVboDashboardState, { ticketing }) => ({
            ...state,
            ticketing: ticketing
        })
    ),
    on(
        LoadBackupVboDashboardVariationsSucceeded,
        (state: IBckpVboDashboardState, { variations }) => ({
            ...state,
            variations: variations
        })
    )
);
