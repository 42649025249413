import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PercentsService
{
    constructor(private translateService: TranslateService)
    {
    }

    percentage (nb: number, decPart: number): string
    {
        if (decPart < 1 || decPart > 2)
        {
            decPart = 2;
        }

        return new Intl.NumberFormat(
            this.translateService.currentLang,
            {
                style: 'percent',
                minimumIntegerDigits: 1,
                minimumFractionDigits: decPart,
                maximumFractionDigits: decPart
            })
            .format(nb);
    }
}
