import { createAction, props } from '@ngrx/store';

import { OstkFailure } from '@common/models';

import { CreateNetwork, Network } from '../../../domain/models/network';
import { SelectOption } from '@ui/components/form/common';

export const OstkNetworksNetwListRequested = createAction(
    '[OSTK-NETW] Ostk Networks Netw List Requested'
);
export const OstkNetworksNetwListSucceeded = createAction(
    '[OSTK-NETW] Ostk Networks Netw List Succeeded',
    props<{ networks: Network[] }>()
);
export const OstkNetworksNetwListFailed = createAction(
    '[OSTK-NETW] Ostk Networks Netw List Failed',
    props<{ err?: any }>()
);
export const OstkNetworksNetwSelectRequested = createAction(
    '[OSTK-NETW] Ostk Networks Netw Select Requested',
    props<{ id: string }>()
);

export const OstkNetworksNetwSelectSucceded = createAction(
    '[OSTK-NETW] Ostk Network Selected',
    props<{ network: Network }>()
);
export const OstkNetworksNetwSelectFailed = createAction(
    '[OSTK-NETW] Ostk Network Select Failed',
    props<{ failure: any }>()
);
export const OstkNetworksNetwQsWordSet = createAction(
    '[OSTK-NETW] Ostk Networks Netw QsWord Set',
    props<{ qsWord: string }>()
);
export const OstkNetworksNetwCreateRequested = createAction(
    '[OSTK-NETW] Ostk Networks Netw Create Requested',
    props<{ network: CreateNetwork }>()
);
export const OstkNetworksNetwCreateSucceeded = createAction(
    '[OSTK-NETW] Ostk Networks Netw Create Succeeded',
    props<{ network: Network }>()
);
export const OstkNetworksNetwCreateFailed = createAction(
    '[OSTK-NETW] Ostk Create Network Failed',
    props<{ failure: OstkFailure; }>()
);
export const OstkNetworksNetwDeleteRequested = createAction(
    '[OSTK-NETW] Ostk Networks Netw Delete Requested',
    props<{ id: string }>()
);
export const OstkNetworksNetwDeleteSucceeded = createAction(
    '[OSTK-NETW] Ostk Networks Netw Delete Succeeded',
    props<{ id: string }>()
);
export const OstkNetworksNetwDeleteFailed = createAction(
    '[OSTK-NETW] Ostk Networks Netw Delete Failed',
    props<{ failure?: any }>()
);
export const OstkNetworksNetwSubnetDeleteRequested = createAction(
    '[OSTK-NETW] Ostk Networks Netw Subnet Delete Requested',
    props<{ id: string, subnetId: string }>()
);
export const OstkNetworksNetwSubnetDeleteSucceeded = createAction(
    '[OSTK-NETW] Ostk Networks Netw Subnet Delete Succeeded',
    props<{ networkId: string, subnetId: string }>()
);
export const OstkNetworksNetwSubnetDeleteFailed = createAction(
    '[OSTK-NETW] Ostk Networks Netw Subnet Delete Failed',
    props<{ failure?: any }>()
);
export const OstkNetworksNetwPoolsRequested = createAction(
    '[OSTK-NETW] Ostk Networks Netw Pools Requested'
);
export const OstkNetworksNetwPoolsSucceeded = createAction(
    '[OSTK-NETW] Ostk Networks Netw Pools Succeeded',
    props<{ poolOptions: SelectOption[] }>()
);
export const OstkNetworksNetwPoolsFailed = createAction(
    '[OSTK-NETW] Ostk Networks Netw Pools Failed',
    props<{ err?: any }>()
);
