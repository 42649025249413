import { FlagsMetadata, StatusesMetadata } from '@common/models';

export const Its2DashSLAFeatureKey = 'sla';

export interface IIts2DashSLACounters extends FlagsMetadata
{
    sla: number;
}

const Its2DashSLACountersInitialState: IIts2DashSLACounters = {
    sla: null,
    ...StatusesMetadata.init()
};

export interface IIts2DashSLAState
{
    incidents: IIts2DashSLACounters;
    services: IIts2DashSLACounters;
}

export const Its2DashSLAInitialState: IIts2DashSLAState = {
    incidents: Its2DashSLACountersInitialState,
    services: Its2DashSLACountersInitialState
};