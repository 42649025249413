import { on, createReducer } from '@ngrx/store';

import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import * as fromRootStore from '@apps/root/store';

import * as fromBackupSystemsActions from './backup-systems.actions';
import { BackupSystem } from '@apps/bckp/models/backup-system';
export const BckpBackupSystemsFeatureKey = 'backupSystems';

export interface IBckpBackupSystemsState
{
    backupSystems: BackupSystem[];
    selectedBackupSystem: BackupSystem
}

const initialState: IBckpBackupSystemsState = {
    backupSystems: null,
    selectedBackupSystem: null
};

export const BckpBackupSystemsReducer = createReducer(
    initialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => initialState
    ),
    on(
        fromBackupSystemsActions.BckpBackupSystemSelected,
        (state: IBckpBackupSystemsState, { backupSystem }) =>
            ({
                ...state,
                selectedBackupSystem: backupSystem
            })
    )
);
