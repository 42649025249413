import { Injectable } from '@angular/core';

import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map } from 'rxjs/operators';

import * as fromNotfAlertStoreActions from './alert.actions';
import { AlertMiscDomainService } from '../../domain/alert-misc-domain.service';
import { Alert } from '../../domain/models';

@Injectable()
export class NotfAlertEffects
{
    constructor (
        private actions: Actions,
        private alertMiscDomainService: AlertMiscDomainService
    )
    { }

    notfAlertLoadRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromNotfAlertStoreActions.NotfAlertLoadRequested),
                switchMap(() =>
                    this.alertMiscDomainService.getAll()
                        .pipe(
                            map((alerts: Alert[]) =>
                                fromNotfAlertStoreActions.NotfAlertLoadSucceeded({ alerts })
                            )
                        )
                )
            )
    );
}
