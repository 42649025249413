import { createReducer, on } from '@ngrx/store';

import { StatusesMetadata } from '@common/models';
import * as fromRootImpersonateActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';

import { IIts2DashSLAState, Its2DashSLAInitialState } from './sla.state';
import * as fromSLAActions from './sla.actions';

export const Its2DashboardSLAReducer = createReducer(
    Its2DashSLAInitialState,
    on(
        fromRootImpersonateActions.RootImpersonateSet,
        fromRootImpersonateActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => Its2DashSLAInitialState
    ),
    on(
        fromSLAActions.Its2DashSLAIncidentsRequested,
        (state: IIts2DashSLAState) =>
            ({
                ...state,
                incidents: {
                    sla: null,
                    ...StatusesMetadata.requested()
                }
            })
    ),
    on(
        fromSLAActions.Its2DashSLAIncidentsSucceeded,
        (state: IIts2DashSLAState, { sla }: {sla: number}) =>
            ({
                ...state,
                incidents: {
                    sla: sla,
                    ...StatusesMetadata.succeeded(true, false)
                }
            })
    ),
    on(
        fromSLAActions.Its2DashSLAIncidentsFailed,
        (state: IIts2DashSLAState) =>
            ({
                ...state,
                incidents: {
                    sla: null,
                    ...StatusesMetadata.failed()
                }
            })
    ),
    on(
        fromSLAActions.Its2DashSLAServicesRequested,
        (state: IIts2DashSLAState) =>
            ({
                ...state,
                services: {
                    sla: null,
                    ...StatusesMetadata.requested()
                }
            })
    ),
    on(
        fromSLAActions.Its2DashSLAServicesSucceeded,
        (state: IIts2DashSLAState, { sla }: {sla: number}) =>
            ({
                ...state,
                services: {
                    sla: sla,
                    ...StatusesMetadata.succeeded(true, false)
                }
            })
    ),
    on(
        fromSLAActions.Its2DashSLAServicesFailed,
        (state: IIts2DashSLAState) =>
            ({
                ...state,
                services: {
                    sla: null,
                    ...StatusesMetadata.failed()
                }
            })
    )
);