import { AppConstants } from '../../app.constants';
import { ODataSearch } from 'src/app/_common/api/qs/odata';

export class ODataBackAuthEntitiesSearch extends ODataSearch
{
    constructor(searchValue: string, incrSize: number = AppConstants.QSSearchTop)
    {
        super(searchValue, incrSize);

        if (this.searchValue !== null)
        {
            this.pushValue('$count', true);
            this.pushValue('$search', this.searchValue);
        }
    }
}
