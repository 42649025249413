import { UntypedFormGroup } from '@angular/forms';

export class CustomFormGroup extends UntypedFormGroup
{
    public setValueMarkDirty (fieldName: string, value: any): void
    {
        this.get(fieldName).setValue(value);
        this.get(fieldName).markAsDirty();
    }
}
