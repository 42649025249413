import { Md5Service } from '@common/services';

export class QuickSearchValue
{
    key: string;
    searchValue: string;

    constructor(key: string, searchValue: string)
    {
        this.key = Md5Service.HexMd5(key.toUpperCase());
        this.searchValue = searchValue;
    }
}
