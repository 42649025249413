import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { BaseListDomainService } from '@common/domain/base-list-domain.service';
import { DatesService, LanguageService, UtilsService } from '@common/services';
import { SearchResult, CompInstanceSearchResult } from '@common/models/search-result';
import { SummaryCounter } from '@common/models/summary-counter';
import { groupedCounter } from '@common/operators';
import { SimplifiedInstance } from '@apps/comp/domain/models/instance';
import { ResultApi } from '@common/api/result-api';

import { IAuroraState } from '../../aurora.state';
import { Impersonation } from '../../root/models';
import { getRootImpersonation } from '../../root/store/root.reducer';
import { Instance } from './models';
import { InstanceApi } from '../api/models/instance-api';
import { InstanceListApiService } from '../api/instance-list-api.service';

@Injectable({
    providedIn: 'root'
})
export class InstanceListDomainService extends BaseListDomainService
{
    constructor (
        private instanceListApiService: InstanceListApiService,
        private datesService: DatesService,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private store: Store<IAuroraState>
    )
    {
        super();
    }

    getAll (qs: string): Observable<Instance[]>
    {
        return this.getInstances(qs)
            .pipe(
                map((result: InstanceApi[]) =>
                    result.map<Instance>((instanceApi: InstanceApi) =>
                        Instance.mapperFromApi(instanceApi, this.datesService, this.translateService, this.languageService.language)
                    )
                )
            );
    }

    public getAllAlone (qs: string): Observable<Instance[]>
    {
        return this.getInstancesAlone(qs)
            .pipe(
                map((result: InstanceApi[]) =>
                    result.map<Instance>((instanceApi: InstanceApi) =>
                        Instance.mapperFromApi(instanceApi, this.datesService, this.translateService, this.languageService.language)
                    )
                )
            );
    }

    search (qs: string): Observable<SearchResult<Instance>[]>
    {
        return this.getInstancesAlone(qs)
            .pipe(
                map((instancesApi: InstanceApi[]) =>
                    instancesApi
                        .map(this.searchMapperFromApi, this)
                        // ! TODO remplacer la valeur hard-codée par une constante
                        .slice(0, 7)
                )
            );
    }

    public getSummaryByPowerState (qs: string): Observable<SummaryCounter<string>[]>
    {
        return this.getSimplifiedInstances(qs)
            .pipe(
                map((allInstances: SimplifiedInstance[]) =>
                    allInstances
                        .filter((instance: SimplifiedInstance) =>
                            instance.billable !== false
                        )
                ),
                groupedCounter<SimplifiedInstance, string>((instance: Instance) => instance.powerState)
            );
    }

    public getSummaryByOperatingSystem (qs: string): Observable<SummaryCounter<string>[]>
    {
        return this.getSimplifiedInstances(qs)
            .pipe(
                groupedCounter<SimplifiedInstance, string>((instance: SimplifiedInstance) =>
                    UtilsService.toFirstUpperCase(UtilsService.getOsLabel(instance.os, 'default'))
                ),
                map((sc: SummaryCounter<string>[]) =>
                    SummaryCounter.sortSummaryCounter<string>(sc)
                )
            );
    }

    private getSimplifiedInstances (qs: string): Observable<SimplifiedInstance[]>
    {
        return this.instanceListApiService.getSimplifiedInstances(`${qs}&$select=os,billable,powerState`)
            .pipe(
                map((obj: ResultApi<SimplifiedInstance>): SimplifiedInstance[] =>
                    obj.value
                ),
                map((allInstances: SimplifiedInstance[]) =>
                    allInstances
                        .filter((instance: SimplifiedInstance) =>
                            instance.billable !== false
                        )
                )
            );
    }

    private searchMapperFromApi (instanceApi: InstanceApi): CompInstanceSearchResult
    {
        return new CompInstanceSearchResult(
            Instance.mapperFromApi(instanceApi, this.datesService, this.translateService, this.languageService.language)
        );
    }

    private getInstances (qs: string): Observable<InstanceApi[]>
    {
        return this.store
            .pipe(
                select(getRootImpersonation),
                map((impersonation: Impersonation) =>
                    impersonation !== null
                ),
                switchMap((isImpersonated: boolean) =>
                    this.instanceListApiService.getInstances(qs)
                        .pipe(
                            map((obj: ResultApi<InstanceApi>): InstanceApi[] =>
                                obj.value
                            ),
                            map((allInstances: InstanceApi[]) =>
                                isImpersonated
                                    ? allInstances
                                    : allInstances
                                        .filter((instanceApi: InstanceApi) =>
                                            instanceApi.billable !== false
                                        )
                            )
                        )
                )
            );
    }

    private getInstancesAlone (qs: string): Observable<InstanceApi[]>
    {
        return this.store
            .pipe(
                select(getRootImpersonation),
                map((impersonation: Impersonation) =>
                    impersonation !== null
                ),
                switchMap((isImpersonated: boolean) =>
                    this.instanceListApiService.getInstancesAlone(qs)
                        .pipe(
                            map((obj: ResultApi<InstanceApi>): InstanceApi[] =>
                                obj.value
                            ),
                            map((allInstances: InstanceApi[]) =>
                                isImpersonated
                                    ? allInstances
                                    : allInstances
                                        .filter((instance: Instance) =>
                                            instance.billable !== false
                                        )
                            )
                        )
                )
            );
    }
}
