import { Injectable } from '@angular/core';
import { HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseMiscDomainService } from 'src/app/_common/domain/base-misc-domain.service';
import { JobMiscApiService } from '../api/job-misc-api.service';
import { MediaTypes } from 'src/app/_common/services/files.service';

@Injectable({
    providedIn: 'root'
})
export class JobMiscDomainService extends BaseMiscDomainService {

    constructor (private jobMiscApiService: JobMiscApiService)
    {
        super();
    }

    export (qs: string, format: MediaTypes): Observable<HttpEvent<Blob>>
    {
        return this.jobMiscApiService.export(qs, format);
    }

    restoresExport (qs: string, format: MediaTypes): Observable<HttpEvent<Blob>>
    {
        return this.jobMiscApiService.restoresExport(qs, format);
    }
}
