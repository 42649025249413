import { createSelector } from '@ngrx/store';

import { getIts2State, IIts2State } from '../its2.state';
import { Its2DashboardFeatureKey } from './dashboard.state';


export const getDashboardState = createSelector(
    getIts2State,
    (state: IIts2State) => state[Its2DashboardFeatureKey]
);
