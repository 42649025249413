import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { AppConstants } from '@apps/app.constants';

import { VolumeApi } from '../models';

@Injectable({
    providedIn: 'root'
})
export class VolumeListApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlOstk;
        this.segmentApi = '/ostk/volumes';
    }

    getVolumes (): Observable<VolumeApi[]>
    {
        if (AppConstants.IsDataLocal('ostk'))
        {
            return this.http.get<VolumeApi[]>('assets/data/ostk/volumes.json');
        }

        return this.get<VolumeApi[]>();
    }
}
