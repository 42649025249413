import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Project, Reference } from '../models/billing';
import { BaseMiscDomainService } from 'src/app/_common/domain/base-misc-domain.service';
import { BillingMiscApiService } from '../../api/billing/billing-misc-api.service';
import { concatAll, map, toArray } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BillingMiscDomainService extends BaseMiscDomainService
{

    constructor (
        private billingMiscApiService: BillingMiscApiService
    )
    {
        super();
    }

    getReferences (): Observable<Reference[]>
    {
        return this.billingMiscApiService.getReferences()
            .pipe(
                concatAll(),
                map(Reference.mapperFromApi),
                toArray()
            );
    }

    getProject (): Observable<Project>
    {
        return this.billingMiscApiService.getProject()
            .pipe(
                map(Project.mapperFromApi)
            );
    }
}
