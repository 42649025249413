import { on, createReducer } from '@ngrx/store';

import * as fromAuthLoginActions from 'src/app/_apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from 'src/app/_apps/auth/store/profile/profile.actions';
import * as fromRootStore from 'src/app/_apps/root/store';
import { LoadBackupVbrDashboardCountServersSucceeded, LoadBackupVbrDashboardLastSessionSucceeded, LoadBackupVbrDashboardStatusPerRangeSucceeded, LoadBackupVbrDashboardTicketingSucceeded, LoadBackupVbrDashboardTotalCapacitySucceeded, LoadBackupVbrDashboardVariationsSucceeded } from './vbr-dashboard.actions';
import { CountServers, LastSession, StatusPerRange, TotalCapacity, Variations } from '../../../dashboard/models';
import { RequestsSummaryCounter } from '@common/models';

export const BckpVbrDashboardFeatureKey = 'vbr-dashboard';

export interface IBckpVbrDashboardState
{
    lastSession: LastSession;
    statusPerRange: StatusPerRange;
    countServers: CountServers;
    totalCapacity: TotalCapacity;
    ticketing: RequestsSummaryCounter;
    variations: Variations;
}

const InitialState: IBckpVbrDashboardState = {
    statusPerRange: new StatusPerRange(),
    lastSession: new LastSession(),
    countServers: new CountServers(),
    totalCapacity: new TotalCapacity(),
    ticketing: null,
    variations: new Variations()
};

export const VbrDashboardReducer = createReducer(
    InitialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => InitialState
    ),
    on(
        LoadBackupVbrDashboardStatusPerRangeSucceeded,
        (state: IBckpVbrDashboardState, { statusPerRange }) => ({
            ...state,
            statusPerRange: statusPerRange
        })
    ),
    on(
        LoadBackupVbrDashboardLastSessionSucceeded,
        (state: IBckpVbrDashboardState, { lastSession }) =>
        {
            return ({
                ...state,
                lastSession: lastSession
            });
        }
    ),
    on(
        LoadBackupVbrDashboardCountServersSucceeded,
        (state: IBckpVbrDashboardState, { countServers }) => ({
            ...state,
            countServers: countServers
        })
    ),
    on(
        LoadBackupVbrDashboardTotalCapacitySucceeded,
        (state: IBckpVbrDashboardState, { totalCapacity }) => ({
            ...state,
            totalCapacity: totalCapacity
        })
    ),
    on(
        LoadBackupVbrDashboardTicketingSucceeded,
        (state: IBckpVbrDashboardState, { ticketing }) => ({
            ...state,
            ticketing: ticketing
        })
    ),
    on(
        LoadBackupVbrDashboardVariationsSucceeded,
        (state: IBckpVbrDashboardState, { variations }) => ({
            ...state,
            variations: variations
        })
    )
);
