import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import * as fromRootStore from '@apps/root/store';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { JobsFilter, ServersFilter } from '@apps/bckp/models';
import { ActivityByServer, ActivityByServerDomain } from '@apps/bckp/domain/models/activity/activity-by-server';
import { ActivityByDate, Job } from '@apps/bckp/domain/models';
import { ODataBckpJobs } from '@apps/bckp/store/jobs/odata-bckp-jobs';
import { jobsAdapter } from '@apps/bckp/store/jobs/jobs.reducer';
import { bckpServersHeatInitialState, IBckpServersHeatState, serversHeatAdapter } from '@apps/bckp/store/servers/heat/heat.state';
import { ODataBckpServersHeat } from '@apps/bckp/store/servers/heat/odata-bckp-servers-heat';
import { StatusesMetadata } from '@common/models';
import { IDomainized } from '@common/domain';

import * as fromHeatActions from './heat.actions';

export const initToView = <T extends IDomainized>(collection: T[], mapper: (item: T) => T): T[] =>
    [...collection].map(mapper);

export const BckpServersHeatReducer = createReducer(
    bckpServersHeatInitialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => bckpServersHeatInitialState
    ),
    on(
        fromHeatActions.BckpServersHeatODataInitialized,
        (state: IBckpServersHeatState, { scrollSize, filter }) => ({
            ...state,
            ...StatusesMetadata.requested(),
            oData: new ODataBckpServersHeat(scrollSize, filter)
        })
    ),
    on(
        fromHeatActions.BckpServersHeatODataLoaded,
        (state: IBckpServersHeatState, { servers }: { servers: ActivityByServer[]; }) =>
        {
            servers = initToView(servers, ActivityByServerDomain.mapperToView);

            return {
                ...state,
                ...StatusesMetadata.succeeded(servers.length !== 0, servers.length === 0),
                servers: serversHeatAdapter.setAll(servers, state.servers)
            };
        }
    ),
    on(
        fromHeatActions.BckpServersHeatInsert,
        (state: IBckpServersHeatState, { servers }: { servers: ActivityByServer[]; }) =>
            ({
                ...state,
                servers: serversHeatAdapter.addMany(servers, state.servers)
            })
    ),
    on(
        fromHeatActions.BckpServersHeatODataFailed,
        () =>
            ({
                ...bckpServersHeatInitialState,
                ...StatusesMetadata.failed()
            })
    ),
    on(
        fromHeatActions.BckpServersHeatCleared,
        () =>
            ({
                ...bckpServersHeatInitialState,
                ...StatusesMetadata.init()
            })
    ),
    on(
        fromHeatActions.BckpServersHeatQsWordSet,
        (state: IBckpServersHeatState, { qsWord }) =>
            ({
                ...state,
                qsWord: qsWord.toLowerCase()
            })
    ),
    on(
        fromHeatActions.BckpServersHeatIssuesOnlySet,
        (state: IBckpServersHeatState, { issuesOnly }: { issuesOnly: boolean }) =>
            ({
                ...state,
                issuesOnly
            })
    ),
    on(
        fromHeatActions.BckpServersHeatFilterApplied,
        (state: IBckpServersHeatState, { filter }: { filter: ServersFilter; }) =>
            ({
                ...bckpServersHeatInitialState,
                qsWord: state.qsWord,
                issuesOnly: state.issuesOnly,
                oData: new ODataBckpServersHeat(BckpConstants.QSServersTop, filter),
                ...StatusesMetadata.init()
            })
    ),
    on(
        fromHeatActions.BckpServersHeatJobsRequested,
        (state: IBckpServersHeatState, { master, server, abd }: { master: string, server: string, abd: ActivityByDate }) =>
        {
            const filter = new JobsFilter();
            filter.jobStatus = [];
            filter.rangeSince = abd.dateJobs.startOf('day');
            filter.rangeUntil = abd.dateJobs.endOf('day');
            filter.master = master;
            filter.serverShort = server;
            const odataBckpJobs = new ODataBckpJobs(BckpConstants.QSJobsTop, filter);

            return {
                ...state,
                jobsQs: odataBckpJobs.qs,
                jobs: jobsAdapter.removeAll(state.jobs)
            };
        }
    ),
    on(
        fromHeatActions.BckpServersHeatJobsSucceeded,
        (state: IBckpServersHeatState, { jobs, nbJobs }: { jobs: Job[]; nbJobs: number }) =>
            ({
                ...state,
                jobs: jobsAdapter.addMany(jobs, state.jobs),
                nbJobs
            })
    ),
    on(
        fromHeatActions.BckpServersHeatTagAttachSucceeded,
        fromHeatActions.BckpServersHeatTagDetachSucceeded,
        (state, { updatedActivity }: { updatedActivity: Update<ActivityByServer>; }) =>
        {
            return {
                ...state,
                servers: serversHeatAdapter.updateOne(updatedActivity, state.servers)
            };
        }
    )
);
