import { OverlayRef } from '@angular/cdk/overlay';

import { Subject } from 'rxjs';

import { GuardModalEvent } from './guard-modal.event';
import { GuardModalConfig } from './guard-modal.config';

export class GuardModalRef
{
    public onModalClosed = new Subject<GuardModalEvent>();

    constructor(public readonly overlayRef: OverlayRef, public config?: GuardModalConfig)
    {
        this.overlayRef
            .backdropClick()
            .subscribe((event: MouseEvent) =>
            {
                event.stopPropagation();
                event.stopImmediatePropagation();
                this._close({ type: 'backdropClick', dialogResult: false });
            });
    }

    close (dialogResult: boolean)
    {
        this._close({type: 'close', dialogResult});
    }

    isVisible ()
    {
        return this.overlayRef && this.overlayRef.overlayElement;
    }

    private _close (event: GuardModalEvent)
    {
        this.overlayRef.dispose();
        this.onModalClosed.next(event);
        this.onModalClosed.complete();
    }
}
