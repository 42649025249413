import { NgModule } from '@angular/core';

import { LegoChartModule } from './charts/lego/lego-chart.module';
import { RawD3ChartModule } from './charts/rawd3/raw-d3-chart.module';
import { UiChartModule } from './ui/ui-chart.module';
import { MixedChartModule } from './charts/mixed/mixed-chart.module';
import { CompositeChartModule } from './composite/composite-chart.module';

@NgModule({
    exports: [
        LegoChartModule,
        RawD3ChartModule,
        MixedChartModule,
        CompositeChartModule,
        UiChartModule
    ]
})
export class ChartingModule
{ }
