import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DateTime } from 'luxon';

import * as fromRootImpersonateActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { ResultDomain } from '@common/domain/result-domain';
import { CmdbAsset, AssetPopinIds, CmdbAssetDetails } from '@apps/cmdb/models';
import { PotoAssetsFilter } from '@apps/cmdb/services/assets-filter';
import { CmdbConstants } from '@apps/cmdb/cmdb.constants';
import * as fromCmdbAssetsActions from './assets.actions';

export const CmdbAssetsFeatureKey = 'assets';

type IAssetsState = EntityState<CmdbAsset>;
export const assetsAdapter: EntityAdapter<CmdbAsset> = createEntityAdapter({
    selectId: (asset: CmdbAsset) => asset.assetId
});
const assetsInitialState: IAssetsState = assetsAdapter.getInitialState({});

export interface ICmdbAssetsState
{
    assets: IAssetsState;
    isLoading: boolean;
    isLoaded: boolean;
    isEmpty: boolean;
    isOups: boolean;
    assetsFilter: PotoAssetsFilter;
    expirationDate?: DateTime;
    qsWord: string;
    assetDetails: CmdbAssetDetails,
    assetPopinIds: AssetPopinIds[]
}

const CmdbAssetsInitialState: ICmdbAssetsState = {
    assets: assetsInitialState,
    isLoading: false,
    isLoaded: false,
    isEmpty: false,
    isOups: false,
    assetsFilter: new PotoAssetsFilter(),
    expirationDate: null,
    qsWord: '',
    assetDetails: null,
    assetPopinIds: []
};

export const CmdbAssetsReducer = createReducer(
    CmdbAssetsInitialState,
    on(
        fromRootImpersonateActions.RootImpersonateSet,
        fromRootImpersonateActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => CmdbAssetsInitialState
    ),
    on(
        fromCmdbAssetsActions.CmdbAssetsODataFilterRequested,
        (state: ICmdbAssetsState) => ({
            ...state,
            isLoading: true,
            isLoaded: false
        })
    ),
    on(
        fromCmdbAssetsActions.CmdbAssetsODataFilterSucceeded,
        (state: ICmdbAssetsState, { result }: { result: ResultDomain<CmdbAsset>; }) => ({
            ...state,
            isLoading: false,
            isLoaded: true,
            isEmpty: result.value.length === 0,
            isOups: false,
            assets: assetsAdapter.setAll(result.value, state.assets),
            expirationDate: DateTime.utc().plus({ minutes: CmdbConstants.DataLifeTime })
        }),
    ),
    on(
        fromCmdbAssetsActions.CmdbAssetsODataFilterFailed,
        (state: ICmdbAssetsState) => ({
            ...state,
            isLoading: false,
            isLoaded: false,
            isEmpty: false,
            isOups: true,
            assets: assetsAdapter.removeAll(state.assets),
            expirationDate: null
        })
    ),
    on(
        fromCmdbAssetsActions.CmdbAssetsODataSkipRequested,
        (state: ICmdbAssetsState) => ({
            ...state,
            isLoading: true
        })
    ),
    on(
        fromCmdbAssetsActions.CmdbAssetsODataSkipSucceeded,
        (state: ICmdbAssetsState, { result }: { result: ResultDomain<CmdbAsset>; }) => ({
            ...state,
            isLoading: false,
            isLoaded: true,
            assets: assetsAdapter.addMany(result.value, state.assets)
        })
    ),
    on(
        fromCmdbAssetsActions.CmdbAssetsFilterChanged,
        (state: ICmdbAssetsState, { assetsFilter }: { assetsFilter: PotoAssetsFilter; }) => ({
            ...state,
            assetsFilter
        })
    ),
    on(
        fromCmdbAssetsActions.CmdbAssetsQsWordSet,
        (state: ICmdbAssetsState, { qsWord }) =>
            ({
                ...state,
                qsWord: qsWord.replace(/[-/\\^$*+?.()|[\]{}]/g, '')
            })
    ),
    on(
        fromCmdbAssetsActions.CmdbAssetDetailedSucceeded,
        (state: ICmdbAssetsState, { assetDetails }) =>
            ({
                ...state,
                assetDetails
            })
    ),
    on(
        fromCmdbAssetsActions.CmdbAssetPopinIdsPush,
        (state: ICmdbAssetsState, { assetPopinIds }) =>
        {
            state.assetPopinIds.push(assetPopinIds);

            return {
                ...state
            };
        }
    ),
    on(
        fromCmdbAssetsActions.CmdbAssetPopinIdsPop,
        (state: ICmdbAssetsState) =>
        {
            state.assetPopinIds.pop();

            return {
                ...state
            };
        }
    )
);
