import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, map } from 'rxjs/operators';

import * as fromMiscActions from './misc.actions';

@Injectable()
export class OstkMiscEffects {

    constructor(
        private actions: Actions
    ) {}
}
