import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { AppConstants } from '@apps/app.constants';
import { Its2Constants } from '@apps/its2/its2.constants';
import { CmdbAssetDetailsApi } from './models';

@Injectable({
    providedIn: 'root'
})
export class CmdbAssetMiscApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlIts2;
        this.segmentApi = `/${Its2Constants.BaseApi}/assets`;
    }

    getOne (assetId: string): Observable<CmdbAssetDetailsApi>
    {
        return this.get<CmdbAssetDetailsApi>(`/${assetId}`);
    }
}
