import { FloatingIpApi } from '../../../api/models/network';
import { FloatingIpStatus, OstkConstants } from '../../../ostk.constants';
import { Network } from './network';

export class FloatingIp
{
    fixedIpAddress: string;
    floatingIpAddress: string;
    floatingNetworkId: string;
    network: Network;
    id: string;
    portId: string;
    routerId: string;
    serverId: string;
    status: string;
    tenantId: string;
    statusClass: string;
    statusText: string;

    public static mapperFromApi (source: FloatingIpApi): FloatingIp
    {
        const target = new FloatingIp();

        Object.assign(target, source);

        target.statusClass = OstkConstants.getFipStatusClass(source.status);
        target.statusText = `OSTK.NETWORKS.FIPS.LIST.STATUS.${source.status}`;

        return target;
    }

    public static mapperFromObject (source: Partial<FloatingIp>): Partial<FloatingIp>
    {
        const target = {} as Partial<FloatingIp>;

        Object.assign(target, source);

        if (source.status)
        {
            target.statusClass = OstkConstants.getFipStatusClass(source.status);
        }

        target.statusText = `OSTK.NETWORKS.FIPS.LIST.STATUS.${source.status}`;

        return target;
    }

    public static getFipPopupAction (status: string): { key: string, icon: string, klass: string; }
    {
        switch (status)
        {
            case FloatingIpStatus.ACTIVE:
                return {
                    key: 'OSTK.NETWORKS.FIPS.ACTIONS.DISASSOCIATE',
                    icon: 'fal fa-unlink',
                    klass: 'danger'
                };
            case FloatingIpStatus.DOWN:
                return {
                    key: 'OSTK.NETWORKS.FIPS.ACTIONS.ASSOCIATE',
                    icon: 'fal fa-link',
                    klass: 'info'
                };
            default:
                return null;
        }
    }
}
