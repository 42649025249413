import { DateTime } from 'luxon';

import { DatesService } from '@common/services';
import { ITimelineGroup } from '@ui/misc/timeline/models/timeline-group';
import { InstanceTimelineApi } from '@apps/ostk/api/models/instance';

import { InstanceTimelineItem } from './instance-timeline-item';

export class InstanceTimelineGroup implements ITimelineGroup
{
    eventDateUtc: DateTime;
    timelineItems: InstanceTimelineItem[];
    id: string;

    public static mapperFromApi (source: InstanceTimelineApi): InstanceTimelineGroup
    {
        const target = new InstanceTimelineGroup();

        target.eventDateUtc = DatesService.UtcStringToDateTimeHourStatic(source.eventDateUtc);
        target.id = source.id;
        target.timelineItems = source.instanceLogs.map(InstanceTimelineItem.mapperFromApi);

        return target;
    }
}
