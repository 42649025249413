import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { AuthenticationService } from '../api/authentication.service';
import { AppConstants } from '../../app.constants';

@Injectable({
    providedIn: 'root'
})
export class RenewPasswordGuard implements CanActivate
{
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate (
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const token = next.paramMap.get('token');

        return this.authenticationService.resetPasswordVerifyToken(token)
            .pipe(
                tap(() => true),
                catchError(() =>
                {
                    return this.router.navigate([AppConstants.LoginUrl]);
                })
            );
    }
}
