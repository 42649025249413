import { animate, state, style, transition, trigger, } from '@angular/animations';

export const animations: Array<any> = [
    trigger('showHide', [
        state('show', style({
            opacity: 1
        })),
        state('hide', style({
            opacity: 0
        })),
        transition('show => hide', [
            animate('0.5s cubic-bezier(0.35, 0, 0.25, 1)')
        ]),
        transition('hide => show', [
            animate('0.5s cubic-bezier(0.35, 0, 0.25, 1)')
        ])
    ])
];
