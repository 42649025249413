import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState, EntityAdapter, Update } from '@ngrx/entity';

import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import * as fromRootStore from '@apps/root/store';

import * as fromCapacitiesActions from './capacities.actions';
import { CapacityGbytesPerServer, PolicyForFilter } from '../../domain/models';
import { ODataBckpCapacities } from './odata-bckp-capacities';
import { CapacitiesFilter } from '../../models';
export const BckpCapacitiesFeatureKey = 'capacities';

type IChargedCapacitiesState = EntityState<CapacityGbytesPerServer>;
export const chargedCapacitiesAdapter: EntityAdapter<CapacityGbytesPerServer> = createEntityAdapter<CapacityGbytesPerServer>({
    selectId: (cgps: CapacityGbytesPerServer): string =>
        cgps.serverShort,
    sortComparer: (cgpsa, cgpsb): number =>
        cgpsa.serverShort.localeCompare(cgpsb.serverShort)
});
const chargedCapacitiesInitialState: IChargedCapacitiesState = chargedCapacitiesAdapter.getInitialState({
});

export interface IBckpCapacitiesState
{
    policyForFilters: PolicyForFilter[];
    collection: IChargedCapacitiesState;
    isLoading: boolean;
    isLoaded: boolean;
    isEmpty: boolean;
    isOups: boolean;
    oData: ODataBckpCapacities;
    stateFilterFlags: number;
    fullFlags: number;
    qsWord: string;
}

const initialState: IBckpCapacitiesState = {
    policyForFilters: [],
    collection: chargedCapacitiesInitialState,
    isLoading: true,
    isLoaded: false,
    isEmpty: false,
    isOups: false,
    oData: new ODataBckpCapacities(50, new CapacitiesFilter()),
    stateFilterFlags: 0,
    fullFlags: 0,
    qsWord: ''
};

export const CapacitiesReducer = createReducer(
    initialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => initialState
    ),
    on(
        fromCapacitiesActions.BckpCapacitiesPoliciesLoaded,
        (state, { policyForFilters, fullFlags }) =>
        {
            state.policyForFilters = [
                ...policyForFilters
            ];
            state.isLoading = false;
            state.isLoaded = true;
            state.fullFlags = fullFlags;
            state.stateFilterFlags = fullFlags;

            return state;
        }
    ),
    on(
        fromCapacitiesActions.BckpCapacitiesCgpsLoaded,
        (state, { cgps }) => ({
            ...state,
            isLoading: false,
            isLoaded: cgps && cgps.length !== 0,
            isEmpty: cgps && cgps.length === 0,
            collection: chargedCapacitiesAdapter.addMany(cgps, state.collection)
        })
    ),
    on(
        fromCapacitiesActions.BckpCapacitiesFilterApplied,
        (_, { filter }) => ({
            ...initialState,
            oData: new ODataBckpCapacities(50, filter),
            isLoading: true
        })
    ),
    on(
        fromCapacitiesActions.BckpCapacitiesLocalFilterApplied,
        (state, { filterFlags, stateFilterFlags }) =>
        {
            state.oData.filter.filterFlags = filterFlags;

            return {
                ...state,
                stateFilterFlags: stateFilterFlags !== 0 ? stateFilterFlags : state.fullFlags
            };
        }
    ),
    on(
        fromCapacitiesActions.BckpCapacitiesCleared,
        (state) => ({
            ...state,
            collection: chargedCapacitiesAdapter.removeAll(state.collection),
        })
    ),
    on(
        fromCapacitiesActions.BckpCapacitiesQsWordSet,
        (state, { qsWord }) => ({
            ...state,
            qsWord: qsWord.toLowerCase()
        })
    ),
    on(
        fromCapacitiesActions.BckpCapacitiesTagAttachSucceeded,
        fromCapacitiesActions.BckpCapacitiesTagDetachSucceeded,
        (state, { updatedCapacity }: { updatedCapacity: Update<CapacityGbytesPerServer>; }) =>
        {
            return {
                ...state,
                collection: chargedCapacitiesAdapter.updateOne(updatedCapacity, state.collection)
            };
        }
    )
);
