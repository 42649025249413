import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { mergeMap, take } from 'rxjs/operators';

import { IAuroraState } from '@apps/aurora.state';
import * as fromRootStore from '@apps/root/store';
import { Impersonation } from '@apps/root/models';

@Injectable()
export class ImpersonateInterceptor implements HttpInterceptor
{
    constructor (
        private store: Store<IAuroraState>
    )
    { }

    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // TBD
        // .set('X-AurorA-UserId', impersonation.userId)

        if (req.url.includes('auth'))
        {
            return next.handle(req);
        }

        return this.store
            .pipe(
                select(fromRootStore.getRootImpersonation),
                take(1),
                mergeMap((impersonation: Impersonation) =>
                {
                    if (impersonation === null)
                    {
                        return next.handle(req);
                    }

                    const language: string = impersonation.language ? impersonation.language : 'fr-FR';
                    const clonedRequest: HttpRequest<any> = req.clone({
                        headers: req.headers
                            // HIST
                            .set('X-AurorA-EntityId', impersonation.entityId)
                            // OBJS
                            .set('X-AurorA-EntityShortId', impersonation.entityShortId)
                            .set('X-AurorA-Username', impersonation.username)
                            // ITSM
                            .set('X-AurorA-UserEmail', impersonation.email)
                            .set('X-AurorA-UserLanguage', language)
                            // PATA
                            .set('X-AurorA-EntityErpId', impersonation.entityErpId)
                    });

                    return next.handle(clonedRequest);
                })
            );
    }
}
