export abstract class StepBase
{
    active: boolean = false;
    valid: boolean = false;
    skipped: boolean = false;
    subTitle: string = '';
    get subTitleDescription ()
    {
        return `
            <div style="color: #0057ff;font-weight: bold;">
                ${this.subTitle}
            </div>
        `;
    }

    constructor (public currentLang: string)
    {
    }
}
