import { TranslateService } from '@ngx-translate/core';

import { DateTime } from 'luxon';

import { SelectOption } from 'src/app/_ui/components/form/common';
import { OpenPopinOriginator } from 'src/app/_common/enum/open-popin-originator.enum';
import { CmdbConstants } from '../cmdb/cmdb.constants';
import { TypePopin } from '../app.constants';
import { Instance } from './domain/models';

export class CompConstants
{
    public static Application: string = 'CompApi';
    public static BaseApi: string = 'hist';
    public static BaseUrl: string = 'compute';
    // public static BaseApi: string = 'prtg';
    public static Instances: string = 'instances';
    public static Topology: string = 'topology';
    public static Details: string = 'details';
    public static Filter: string = 'filter';
    public static DefaultUrl: string = `/${CompConstants.BaseUrl}/${CompConstants.Instances}`;
    public static QSInstancesTop: number = 0;
    public static OsIcons = new Map([
        ['win', 'windows'],
        ['rhel', 'redhat'],
        ['sles', 'suse'],
        ['vm', 'vmware'],
        ['windows', 'windows'],
        ['centos', 'centos'],
        ['debian', 'debian'],
        ['fedora', 'fedora'],
        ['freebsd', 'freebsd'],
        ['gnome', 'gnome'],
        ['hp', 'hp'],
        ['linux', 'linux'],
        ['redhat', 'redhat'],
        ['suse', 'suse'],
        ['ubuntu', 'ubuntu'],
        ['vmware', 'vmware'],
        ['windows', 'windows']
    ]);
    public static InstanceStatuses = new Map([
        ['poweredOn', 'COMP.INSTANCE.FILTER.POWEREDON'],
        ['poweredOff', 'COMP.INSTANCE.FILTER.POWEREDOFF']
    ]);
    // Classes
    public static Platinum: string = 'PLATINIUM';
    public static Gold: string = 'GOLD';
    public static Silver: string = 'SILVER';
    public static Bronze: string = 'BRONZE';
    public static InstanceClasses = [
        CompConstants.Platinum,
        CompConstants.Gold,
        CompConstants.Silver,
        CompConstants.Bronze
    ];
    public static PowerStateField: string = 'powerState';
    public static OsField: string = 'os';
    public static DatacenterField: string = 'datacenter';
    public static ClassField: string = 'class';
    public static NumberCpuField: string = 'numberCpu';
    public static BeginningOfTime = DateTime.utc(2018).startOf('year');
    public static DataLifeTime = 7;
    static instancesQSFields: Array<keyof Instance> = [ 'nameShort', 'datacenterLabel', 'class', 'tagFullNames' ];

    public static GetPowerStatus (powerState: string): string
    {
        return powerState === 'poweredOn' ? 'power-on' : 'power-off';
    }


    public static getCssStatus (powerState: string): string
    {
        return powerState === 'poweredOn' ? 'power-on' : 'power-off';
    }

    public static getStatusLabel (powerState: string, translateService: TranslateService): string
    {
        return translateService.instant(`STATUSES.${powerState.toUpperCase()}`);
    }

    public static getBillableLabel (billable: boolean, translateService: TranslateService): string
    {
        return translateService.instant(`BUTTON.${billable ? 'YES' : 'NO'}`);
    }

    public static getBillableOptions (translateService: TranslateService): SelectOption[]
    {
        return [
            new SelectOption(true, translateService.instant('BUTTON.YES')),
            new SelectOption(false, translateService.instant('BUTTON.NO'))
        ];
    }

    public static getReplicationTier (replications: string[], tiers: string[]): string
    {
        const replTypes: string[] = ['R', 'NR', 'SR', 'SSR'];
        let replication: string;
        let tier: string;

        if (!replications || replications.find((r: string) => !replTypes.includes(r)))
        {
            replication = '';
        }
        else
        {
            replication = replications.join(' - ');
        }

        if (!tiers || tiers.find(t => !/T[0-3]/.test(t)))
        {
            tier = '';
        }
        else
        {
            tier = tiers.join(' - ');
        }

        if (!replication && !tier)
        {
            return '';
        }

        return `${replication} | ${tier}`;
    }

    public static InstancesRoute = () =>
        [
            CompConstants.BaseUrl,
            CompConstants.Instances
        ];

    public static InstancesToDetailsRoute = (documentId: string) =>
        [
            CompConstants.BaseUrl,
            CompConstants.Instances,
            { outlets: { details: [OpenPopinOriginator.COMP_INSTANCES, documentId] } }
        ];

    public static ToTagCreationRoute = () =>
        [
            CmdbConstants.BaseUrl,
            CmdbConstants.Tags,
            { outlets: { [TypePopin.ACTION]: ['create', OpenPopinOriginator.COMP_INSTANCES] } }
        ];

    public static ToTopologyRoute = () =>
        [
            CompConstants.BaseUrl,
            CompConstants.Topology
        ];

    public static TopologyToCompInstDetailsRoute = (documentId: string) =>
        [
            CompConstants.BaseUrl,
            CompConstants.Topology,
            { outlets: { details: ['instance', OpenPopinOriginator.COMP_TOPOLOGY, documentId] } }
        ];

    public static TopologyToBckpPoliDetailsRoute = (documentId: string) =>
        [
            CompConstants.BaseUrl,
            CompConstants.Topology,
            { outlets: { details: ['policy', OpenPopinOriginator.COMP_TOPOLOGY, documentId] } }
        ];
}
