import { Injectable } from '@angular/core';

import { combineLatest, of } from 'rxjs';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, switchMap, withLatestFrom, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { IAuroraState } from '@apps/aurora.state';

import * as fromOstkBillingActions from './billing.actions';
import { getBillingIsDataValid } from '../ostk-billing.reducer';
import { BillingMiscDomainService } from '../../domain/billing/billing-misc-domain.service';
import { Project, Reference } from '../../domain/models/billing';

@Injectable()
export class OstkBillingEffects
{
    constructor (
        private actions: Actions,
        private store: Store<IAuroraState>,
        private billingMiscDomainService: BillingMiscDomainService
    )
    { }

    ostkBillingRequested = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromOstkBillingActions.OstkBillingRequested),
                withLatestFrom(this.store.select(getBillingIsDataValid)),
                filter(([, valid]) => !valid),
                switchMap(() =>
                    combineLatest([this.billingMiscDomainService.getReferences(), this.billingMiscDomainService.getProject()])
                        .pipe(
                            map((result: [Reference[], Project]) =>
                                fromOstkBillingActions.OstkBillingSucceeded({ references: result[0], project: result[1] })
                            )
                        )
                )
            )
    );
}
