import { Component, Optional, Inject, Injector, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { InputBase } from '../../../base/input-base';

@Component({
    selector: 'aa-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: [
        '../input.component.scss'
    ],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: InputTextComponent,
        multi: true,
    }]
})
export class InputTextComponent extends InputBase<string> implements OnInit, AfterViewInit, OnDestroy
{
    private static identifier = 0;

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
        injector: Injector
    )
    {
        super(injector);
        this.fieldId = `input-text-${InputTextComponent.identifier++}-id`;
    }

    ngOnInit ()
    {
    }

    ngAfterViewInit (): void
    {
    }

    ngOnDestroy ()
    {
    }
}
