import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { timer } from 'rxjs';
import { tap, map, switchMap, concatMap } from 'rxjs/operators';

import * as fromRootMiscActions from '@apps/root/store/misc/misc.actions';
import { SelectOption } from '@ui/components/form/common';
import { Profile } from '@apps/auth/models';
import { ColorsService, Skin, JsonStyle } from '@common/services';
import { VersionService } from '@apps/root/services/version.service';
import { Version } from '@apps/root/models';
import { RootConstants } from '@apps/root/root.constants';
import { Its2Constants } from '@apps/its2/its2.constants';
import { RequestMiscDomainService } from '@apps/its2/domain';

import { AppConstants } from '../../../app.constants';
import { JsonStyleService } from '../../domain/json-style.service';

@Injectable()
export class RootMiscEffects
{
    constructor (
        private actions: Actions,
        private requestMiscDomainService: RequestMiscDomainService,
        private jsonStyleService: JsonStyleService,
        private versionService: VersionService
    )
    {
    }

    rootGetDeployedVersion = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromRootMiscActions.RootGetDeployedVersion),
                    switchMap(() =>
                        timer(RootConstants.PollingVersionDelay, RootConstants.PollingVersionInterval)
                            .pipe(
                                switchMap(() =>
                                    this.versionService.getDeployedVersion()
                                        .pipe(
                                            map((version: Version) =>
                                                fromRootMiscActions.RootSetDeployedVersion({ hash: version.hash })
                                            )
                                        )
                                )
                            )
                    )
                )
    );

    rootMiscSkinSwitched = createEffect(
        () => this.actions
            .pipe(
                ofType(fromRootMiscActions.RootMiscSkinSwitched),
                map((action: any) =>
                    action.skin
                ),
                tap((skin: Skin) =>
                {
                    const theme = Profile.fromSkinToTheme(skin);
                    const classList = document.querySelector('body').classList;

                    AppConstants.frontThemes.forEach((soTheme: SelectOption) =>
                        classList.remove(soTheme.value.toString())
                    );
                    classList.add(theme);
                }),
                switchMap((skin: Skin) =>
                    this.jsonStyleService.getSkinJson(skin)
                        .pipe(
                            switchMap((styles: JsonStyle) =>
                                ColorsService.setSkinColors(styles)
                            )
                        )
                )
            ),
        { dispatch: false }
    );

    itsmMiscWarningsRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromRootMiscActions.RootMiscWarningsRequested),
                    switchMap(() =>
                        timer(0, Its2Constants.PollingInterval)
                            .pipe(
                                concatMap(() =>
                                    this.requestMiscDomainService.getWarnings()
                                        .pipe(
                                            map((nb: number) =>
                                                fromRootMiscActions.RootMiscWarningSet({
                                                    id: Its2Constants.ItsmFeature,
                                                    nb
                                                })
                                            )
                                        )
                                )
                            )
                    )
                )
    );
}
