import { StatusesMetadata } from '@common/models';
import { RequestsEvolutionSummary } from '@apps/its2/domain/models/requests-evolution-summary';

export const SecuDashReportingFeatureKey = 'reporting';


export interface ISecuDashReportingEvolutionState extends StatusesMetadata
{
    summaries: RequestsEvolutionSummary[]
}

export const SecuDashReportingEvolutionInitialState: ISecuDashReportingEvolutionState = {
    summaries: [],
    ...StatusesMetadata.init()
};

export interface ISecuDashReportingState
{
    evolution: ISecuDashReportingEvolutionState;
}

export const SecuDashReportingInitialState: ISecuDashReportingState = {
    evolution: SecuDashReportingEvolutionInitialState
};
