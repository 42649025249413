import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { Message } from '../../models/message';
import * as fromNotfMesgActions from './message.actions';

export const NotfMesgFeatureKey = 'mesg';

export interface INotfMesgState extends EntityState<Message>
{
}

export const adapter: EntityAdapter<Message> = createEntityAdapter<Message>({
});

export const initialState: INotfMesgState = adapter.getInitialState({
});

export const NotfMesgReducer = createReducer(
    initialState,
    on(
        fromNotfMesgActions.AddMessage,
        (state: INotfMesgState, { message }: { message: Message }) =>
            adapter.addOne(message, state)
    ),
    on(
        fromNotfMesgActions.DeleteMessage,
        (state: INotfMesgState, { id }: { id: string }) =>
            adapter.removeOne(id, state)
    ),
    on(
        fromNotfMesgActions.ClearMessages,
        (state: INotfMesgState) =>
            adapter.removeAll(state)
    )
);

