import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { concatAll } from 'rxjs/operators';

import { BaseMiscApiService } from 'src/app/_common/api/base-misc-api.service';
import { AppConstants } from '../../app.constants';
import { PlatformApi, ProjectApi } from './models';

@Injectable({
    providedIn: 'root'
})
export class ProjectMiscApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlOstk;
        this.segmentApi = '/ostk/platforms';
    }

    getPlatforms (): Observable<PlatformApi>
    {
        return this.get<PlatformApi[]>('')
            .pipe(
                concatAll()
            );

        // return of([
        //     {
        //         'id': '43C728EB-B8B0-462F-BA97-A2A10CA99EB3',
        //         'name': 'ADC',
        //         'type': 'ADC',
        //         'uri': 'https://api.compute.pod2.adc.antemeta.net:5000',
        //         'domain': ''
        //     } as PlatformApi,
        //     {
        //         'id': '43C728EB-B8B0-462F-BA97-A2A10CA99EB3',
        //         'name': 'ADC1',
        //         'type': 'ADC1',
        //         'uri': 'https://api.compute.pod2.adc.antemeta.net:5000',
        //         'domain': 'Domain'
        //     } as PlatformApi
        // ])
        //     .pipe(
        //         concatAll()
        //     );
    }

    getProjects (platformId: string): Observable<ProjectApi>
    {
        // return throwError(() => ({
        //     'request': 'POST https://api.compute.pod2.adc.antemeta.net:5000/v3/auth/tokens',
        //     'response': 'StatusCode: 401, ReasonPhrase: \'Unauthorized\', Version: 1.1, Content: System.Net.Http.HttpConnectionResponseContent, Headers:\r',
        //     'message': '{\r\n  WWW-Authenticate: Keystone uri="https://api.compute.pod2.adc.antemeta.net:5000/"\r\n  Vary: X-Auth-Token\r\n  X-Distribution: Ubuntu\r\n  X-Openstack-Request-Id: req-89bc9b26-8223-42e4-8077-61923ca1fb49\r\n  Date: Thu, 17 Jun 2021 13:01:15 GMT\r\n  Connection: close\r\n  Content-Type: application/json\r\n  Content-Length: 114\r\n}\n{"error": {"message": "The request you have made requires authentication.", "code": 401, "title": "Unauthorized"}}',
        //     'body': {
        //         'error': {
        //             'message': 'The request you have made requires authentication.',
        //             'code': 401,
        //             'title': 'Unauthorized'
        //         }
        //     }
        // }));
        return this.get<ProjectApi[]>(`/${platformId}/projects`)
            .pipe(
                concatAll()
            );
    }
}
