<form id="auth-form" [formGroup]="form">
    <div class="auth-title">{{ 'AUTH.FORM.FORGET' | translate }}</div>
    <div class="auth-sub-title">{{ 'AUTH.FORM.REINITIALISATION' | translate }}</div>
    <!-- TODO: Autofocus sur ce champ -->
    <aa-input-email [required]="'required'" autofocus name="email" label="{{ 'AUTH.FORM.EMAIL.LABEL' | translate }}"
                    formControlName="email">
    </aa-input-email>
    <div>
        <!-- TODO: Respond to ENTER -->
        <aa-btn-action-secondary [btnText]="'BUTTON.SUBMIT' | translate" [cta]="onSubmit" [btnWidth]="'100%'">
        </aa-btn-action-secondary>
    </div>
</form>
