import { FaultApi } from '@apps/ostk/api/models/instance';
import { DatesService } from '@common/services';
import { DateTime } from 'luxon';

export class Fault
{
    created: DateTime | null;
    code: number | null;
    message: string;
}
export class DomainFault extends Fault
{
    public static mapperFromApi (source: FaultApi): Fault
    {
        const target = new Fault();

        Object.assign(target, source);

        target.created = DatesService.UtcStringToDateTimeHourStatic(source?.created);

        return target;
    }
}
