
import { createSelector } from '@ngrx/store';

import { getOstkState, IOstkState } from '@apps/ostk/store/ostk.reducer';

import * as fromBillingReducer from './billing/billing.reducer';
import { Reference } from '../domain/models/billing';
import { DateTime } from 'luxon';

//#region Billing
const getBillingState = createSelector(
    getOstkState,
    (state: IOstkState) =>
        state[fromBillingReducer.OstkBillingFeatureKey]
);
const getBillingReferencesState = createSelector(
    getBillingState,
    (state: fromBillingReducer.IOstkBillingState) => state.references
);
export const {
    selectAll: getAllBillingReferences,
} = fromBillingReducer.referencesAdapter.getSelectors(getBillingReferencesState);
export const getBillingProject = createSelector(
    getBillingState,
    (state: fromBillingReducer.IOstkBillingState) => state.project
);
export const getBillingLoadingStatus = createSelector(
    getBillingState,
    (state: fromBillingReducer.IOstkBillingState) => state.isLoading
);
export const getBillingEmptyStatus = createSelector(
    getBillingState,
    (state: fromBillingReducer.IOstkBillingState) => state.isEmpty
);
export const getBillingOupsStatus = createSelector(
    getBillingState,
    (state: fromBillingReducer.IOstkBillingState) => state.isOups
);
export const getBillingLoadedStatus = createSelector(
    getBillingState,
    (state: fromBillingReducer.IOstkBillingState) => state.isLoaded
);
export const getBillingIsDataValid = createSelector(
    getBillingState,
    (state: fromBillingReducer.IOstkBillingState) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);

export const getBilingReferenceFlavor = createSelector(
    getAllBillingReferences,
    (references: Reference[], flavor: string) =>
        references.find((ref: Reference) =>
            ref.article === flavor
        )
);

export const getBilingReferenceOs = createSelector(
    getAllBillingReferences,
    (references: Reference[], os: string) =>
        references.find((ref: Reference) =>
            ref.article === os
        )
);

export const getBilingReferenceFips = createSelector(
    getAllBillingReferences,
    (references: Reference[]) =>
        references.filter((ref: Reference) =>
            ref.article === 'floatingip'
        )
);

export const getBilingReferenceDisk = createSelector(
    getAllBillingReferences,
    (references: Reference[]) =>
        references.find((ref: Reference) =>
            ref.article === 'storage'
        )
);

export const getBilingReferenceSnap = createSelector(
    getAllBillingReferences,
    (references: Reference[]) =>
        references.filter((ref: Reference) =>
            ref.article === 'snapshot'
        )
);
// #endregion
