import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { AppConstants } from '@apps/app.constants';
import { Its2Constants } from '../its2.constants';

@Injectable({
    providedIn: 'root'
})
export class RequestValuesApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlIts2;
        this.segmentApi = `/${Its2Constants.BaseApi}/values`;
    }

    getValues (propertyName: string): Observable<{ key: string, value: string }[]>
    {
        return this.get<{ key: string, value: string }[]>(`/requests/${propertyName}`);
    }
}
