<div class="input-component"
     [ngClass]="{'pristine': pristine, 'valid': (validStatus$ | async), 'invalid': (invalidStatus$ | async), 'disabled': disabled}">
     <aa-validation [dirty]="dirty" [messages]="failures$ | async">
     </aa-validation>
    <div class="input-wrapper">
        <input [disabled]="notEnabled" type="text" class="input-field" [attr.required]="required" [(ngModel)]="value" [id]="fieldId"
               [name]="name" [attr.value]="value" [ngModelOptions]="updateOn" />
        <label [attr.for]="fieldId">{{label}}</label>
        <span class="input-validation fa-stack fa-1x" *ngIf="dirty">
            <i class="fas fa-stack-2x fa-circle" aria-hidden="true"></i>
            <i class="fal fa-stack-1x" [ngClass]="(validStatus$ | async) ? 'fa-check' : 'fa-times'"
               aria-hidden="true"></i>
        </span>
    </div>
</div>
