import { LocalizedText } from '@common/models';

export class CatalogEntry
{
    catalogId: number;
    parentId: string;
    catalogGuid: string;
    left: number;
    right: number;
    level: number;
    titleFr: string;
    titleEn: string;
    title: LocalizedText;
    descriptionFr: string;
    descriptionEn: string;
    description: LocalizedText;
    path: LocalizedText;
    myAntemetAGuid: string;
}
export type CatalogEntryApi = Omit<CatalogEntry, 'parentId'>;

export class CatalogEntryConverter
{
    public static mapperFromApi (source: CatalogEntryApi): CatalogEntry
    {
        const target: CatalogEntry = new CatalogEntry();

        Object.assign(target, source);

        target.title = new LocalizedText(source.titleEn, source.titleFr);
        target.description = new LocalizedText(source.descriptionEn, source.descriptionFr);
        target.path = new LocalizedText('', '');

        return target;
    }
}
