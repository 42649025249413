import { Component, OnInit, HostListener, Input, OnDestroy } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

import * as fromNotfStoreActions from 'src/app/_apps/notf/store';
import { IAuroraState } from 'src/app/_apps/aurora.state';
import { Message } from 'src/app/_apps/notf/models';
import { popin_notifications_animations } from './popin-notifications-animations';

@Component({
    selector: 'aa-popin-notifications',
    templateUrl: './popin-notifications.component.html',
    styleUrls: [
        './popin-notifications.component.scss'
    ],
    animations: popin_notifications_animations
})
export class PopinNotificationsComponent implements OnInit, OnDestroy
{
    @Input() isShown: boolean = false;
    private destroyator = new Subject();
    messages: Message[] = [];

    constructor(
        private actions: Actions,
        private store: Store<IAuroraState>
    )
    {
    }

    ngOnInit ()
    {
        this.actions
            .pipe(
                ofType(fromNotfStoreActions.NotfLayoutStoreActions.NotfLayoutMesgShowHide),
                tap((param: { status: boolean }) =>
                {
                    this.isShown = param.status;
                }),
                takeUntil(this.destroyator)
            )
            .subscribe();

        this.listenToNotifs();
    }

    ngOnDestroy ()
    {
        this.destroyator.next(null);
        this.destroyator.complete();
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown (event: KeyboardEvent)
    {
        if (this.isShown && event.key === 'Escape')
        {
            this.closePopin();
        }
    }

    closePopin (): void
    {
        this.store.dispatch(fromNotfStoreActions.NotfLayoutStoreActions.NotfLayoutMesgShowHide({ status: false }));
    }

    private listenToNotifs (): void
    {
        this.store
            .pipe(
                select(fromNotfStoreActions.selectAllMessages),
                tap((result) => this.messages = result),
                takeUntil(this.destroyator)
            )
            .subscribe();
    }
}
