import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseMiscApiService } from 'src/app/_common/api/base-misc-api.service';
import { AppConstants } from '../../app.constants';
import { EnrichedTagApi, TagApi } from './models';
import { UpsertTagViewModel } from '../models';
import { DetailedTagApi } from './models/detailed-tag';

@Injectable({
    providedIn: 'root'
})
export class TagMiscApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlCmdb;
        this.segmentApi = '/cmdb/tags';
    }

    getAll (): Observable<EnrichedTagApi[]>
    {
        if (AppConstants.IsDataLocal('cmdb'))
        {
            return this.http.get<EnrichedTagApi[]>('assets/data/cmdb/tags.json');
        }

        return this.get<EnrichedTagApi[]>('');
    }

    getOne (tagId: string): Observable<DetailedTagApi>
    {
        return this.get<DetailedTagApi>(`/${tagId}`);
    }

    createTag (tag: UpsertTagViewModel): Observable<TagApi>
    {
        return this.post<TagApi>('', tag);
    }

    updateTag (tagId: string, tag: UpsertTagViewModel): Observable<TagApi>
    {
        return this.put<TagApi>(`/${tagId}`, tag);
    }

    deleteTag (tagId: string): Observable<number>
    {
        return this.delete(`/${tagId}`);
    }
}
