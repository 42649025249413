import { NgModule } from '@angular/core';

import
{
    FormatShortDatePipe,
    FormatDateHourPipe,
    FormatDateHour9Pipe,
    FormatShortDate9Pipe,
    FormatToShortDatePipe,
    FormatDateISOPipe,
    FormatShortDateHour9Pipe,
    FormatDayMonthHour9Pipe,
    DatesToListPipe
} from './pipes/format-date.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FormatGenericDatePipe } from './pipes/format-generic-date.pipe';
import { LightPathPipe } from './pipes/light-path.pipe';
import { ConvertBToKBPipe } from './pipes/convert-b-to-kb.pipe';
import { Percent2Pipe } from './pipes/percent2.pipe';
import { ConvertAndFormatUnitPipe, FormatToBestUnitPipe } from './pipes/format-to-best-unit.pipe';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import {
    FormatNullableValuePipe,
    ZeroToTextPipe
} from './pipes/format-nullable-value.pipe';
import { ToNbspPipe } from './pipes/to-nbsp.pipe';
import { FormatPriceHT } from './pipes/format-price';
import { MailtoPipe } from './pipes/mailto.pipe';
import { TeltoPipe } from './pipes/telto.pipe';
import { HighlightSearchPipe, TagHighlightSearchPipe } from './pipes/highlight-search.pipe';
import { WrapWithPipe } from './pipes/wrap-with.pipe';

@NgModule({
    declarations: [
        FormatShortDatePipe,
        FormatDateHourPipe,
        FormatDateHour9Pipe,
        SafeHtmlPipe,
        FormatGenericDatePipe,
        LightPathPipe,
        ConvertBToKBPipe,
        Percent2Pipe,
        FormatToBestUnitPipe,
        FormatNumberPipe,
        FormatNullableValuePipe,
        FormatShortDate9Pipe,
        FormatShortDateHour9Pipe,
        FormatDayMonthHour9Pipe,
        ConvertAndFormatUnitPipe,
        FormatToShortDatePipe,
        ZeroToTextPipe,
        FormatDateISOPipe,
        ToNbspPipe,
        FormatPriceHT,
        MailtoPipe,
        TeltoPipe,
        HighlightSearchPipe,
        TagHighlightSearchPipe,
        WrapWithPipe,
        DatesToListPipe
    ],
    exports: [
        FormatShortDatePipe,
        FormatDateHourPipe,
        FormatDateHour9Pipe,
        SafeHtmlPipe,
        FormatGenericDatePipe,
        LightPathPipe,
        ConvertBToKBPipe,
        Percent2Pipe,
        FormatToBestUnitPipe,
        FormatNumberPipe,
        FormatNullableValuePipe,
        FormatShortDate9Pipe,
        FormatShortDateHour9Pipe,
        FormatDayMonthHour9Pipe,
        ConvertAndFormatUnitPipe,
        FormatToShortDatePipe,
        ZeroToTextPipe,
        FormatDateISOPipe,
        ToNbspPipe,
        FormatPriceHT,
        MailtoPipe,
        TeltoPipe,
        HighlightSearchPipe,
        TagHighlightSearchPipe,
        WrapWithPipe,
        DatesToListPipe
    ],
    providers: [
        FormatShortDatePipe,
        FormatToShortDatePipe,
        FormatDateHourPipe,
        FormatDateHour9Pipe,
        SafeHtmlPipe,
        FormatGenericDatePipe,
        LightPathPipe,
        ConvertBToKBPipe,
        Percent2Pipe,
        FormatToBestUnitPipe,
        FormatNumberPipe,
        FormatNullableValuePipe,
        FormatShortDate9Pipe,
        FormatShortDateHour9Pipe,
        FormatDayMonthHour9Pipe,
        ConvertAndFormatUnitPipe,
        FormatDateISOPipe,
        FormatPriceHT,
        MailtoPipe,
        TeltoPipe,
        HighlightSearchPipe,
        TagHighlightSearchPipe,
        DatesToListPipe
    ]
})
export class PipesModule
{
}
