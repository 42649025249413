import { RangeKey } from 'src/app/_common/enum';
import { RangeFilter } from '../../../_common/filters/range-filter';

export class CapacitiesFilter extends RangeFilter
{
    public filterFlags: number[];

    constructor()
    {
        super();

        this.initFilter();
    }

    public static mapperFromObject (source: {}): CapacitiesFilter
    {
        const target = new CapacitiesFilter();

        Object.assign(target, source);

        return target;
    }

    public initFilter ()
    {
        const { since, until } = this.datesService.preRangeDates(RangeKey.CURRENT7DAYS);

        this.rangeSince = since;
        this.rangeUntil = until;

        this.filterFlags = [];
    }
}
