import { createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';


import * as fromVolumesReducer from './volumes/volumes.reducer';
import { getOstkState, IOstkState } from '@apps/ostk/store/ostk.reducer';
import { Snapshot, Volume } from '@apps/ostk/domain/models';
import { Comparer, filterQuickSearch } from '@common/services/utils.service';
import { OstkConstants } from '../ostk.constants';

//#region Volumes
const getVolumesState = createSelector(
    getOstkState,
    (state: IOstkState) =>
        state[fromVolumesReducer.OstkVolumesFeatureKey]
);
const getVolumesVoluState = createSelector(
    getVolumesState,
    (state: fromVolumesReducer.IOstkVolumesState) =>
        state[fromVolumesReducer.OstkVolumesVoluFeatureKey]
);

const getVolumesVoluCollection = createSelector(
    getVolumesVoluState,
    (state: fromVolumesReducer.IOstkVolumesVoluState) =>
        state.volumes
);
export const {
    selectAll: getAllVolumesVolu,
} = fromVolumesReducer.volumesVoluAdapter.getSelectors(getVolumesVoluCollection);
export const getVolumesVoluAvailable = createSelector(
    getAllVolumesVolu,
    (volumes) =>
        volumes.filter((volume: Volume) =>
            volume.isAvailable
        )
);
export const getVolumesVoluFieldsFilter = createSelector(
    getVolumesVoluState,
    (state: fromVolumesReducer.IOstkVolumesVoluState) =>
        state.filter
);
const getFilteredVolumesVolu = createSelector(
    getVolumesVoluState,
    (state: fromVolumesReducer.IOstkVolumesVoluState) =>
        state.filteredVolumes
);

const getSwitchFilterVolumesVolu = createSelector(
    getVolumesVoluState,
    getFilteredVolumesVolu,
    (state: fromVolumesReducer.IOstkVolumesVoluState, volumes: Volume[]) =>
        volumes.filter((volume: Volume) =>
            volume.isAvailable === state.switchFilter
        )
);

export const getQuickSearchedVolumesVolu = createSelector(
    getVolumesVoluState,
    getSwitchFilterVolumesVolu,
    (state: fromVolumesReducer.IOstkVolumesVoluState, volumes: Volume[]) =>
        filterQuickSearch(volumes, state.qsWord, OstkConstants.volumesVoluQSFields)
);
export const getPipelinedVolumesVolu = createSelector(
    getQuickSearchedVolumesVolu,
    (volumes: Volume[], params: { comparer: Comparer<Volume> }) =>
        [...volumes].sort(params.comparer)
);
export const countVolumesVoluQuickFilterTrue = createSelector(
    getAllVolumesVolu,
    (volumes: Volume[]) =>
    {
        return volumes
            ? volumes
                .filter((volume: Volume) =>
                    volume.isAvailable
                )
                .length
            : 0;
    }
);
export const countVolumesVoluQuickFilterFalse = createSelector(
    getAllVolumesVolu,
    (volumes: Volume[]) =>
    {
        return volumes
            ? volumes
                .filter((volume: Volume) =>
                    !volume.isAvailable
                )
                .length
            : 0;
    }
);
export const getVolumesVoluLoadingStatus = createSelector(
    getVolumesVoluState,
    (state: fromVolumesReducer.IOstkVolumesVoluState) =>
        state.isLoading
);
export const getVolumesVoluLoadedStatus = createSelector(
    getVolumesVoluState,
    (state: fromVolumesReducer.IOstkVolumesVoluState) =>
        state.isLoaded
);
export const getVolumesVoluEmptyStatus = createSelector(
    getVolumesVoluState,
    (state: fromVolumesReducer.IOstkVolumesVoluState) =>
        state.isEmpty
);
export const getVolumesVoluOupsStatus = createSelector(
    getVolumesVoluState,
    (state: fromVolumesReducer.IOstkVolumesVoluState) =>
        state.isOups
);
export const getVolumesVoluIsDataValid = createSelector(
    getVolumesVoluState,
    (state: fromVolumesReducer.IOstkVolumesVoluState) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);
export const getSelectedVolume = createSelector(
    getVolumesVoluState,
    (state: fromVolumesReducer.IOstkVolumesVoluState) => state.selectedVolume
);
// #endregion

//#region VolumeSnapshots
const getVolumesSnapState = createSelector(
    getVolumesState,
    (state) =>
        state[fromVolumesReducer.OstkVolumesSnapFeatureKey]
);
const getVolumesSnapCollection = createSelector(
    getVolumesSnapState,
    (state) =>
        state.snapshots
);
export const {
    selectAll: getAllVolumesSnap,
} = fromVolumesReducer.volumesSnapAdapter.getSelectors(getVolumesSnapCollection);
export const getQuickSearchedVolumesSnap = createSelector(
    getVolumesSnapState,
    getAllVolumesSnap,
    (state: fromVolumesReducer.IOstkVolumesSnapState, snaps: Snapshot[]) =>
        filterQuickSearch(
            snaps,
            state.qsWord,
            OstkConstants.volumesSnapQSFields,
            (item: Snapshot) => item.volume?.name?.toLowerCase().includes(state.qsWord))
);
export const getPipelinedVolumesSnap = createSelector(
    getQuickSearchedVolumesSnap,
    (snapshots: Snapshot[], params: { comparer: Comparer<Snapshot> }) =>
        [...snapshots].sort(params.comparer)
);

export const getVolumesSnapLoadingStatus = createSelector(
    getVolumesSnapState,
    (state) =>
        state.isLoading
);
export const getVolumesSnapLoadedStatus = createSelector(
    getVolumesSnapState,
    (state) =>
        state.isLoaded
);
export const getVolumesSnapEmptyStatus = createSelector(
    getVolumesSnapState,
    (state) =>
        state.isEmpty
);
export const getVolumesSnapOupsStatus = createSelector(
    getVolumesSnapState,
    (state) =>
        state.isOups
);
export const getVolumesSnapIsDataValid = createSelector(
    getVolumesSnapState,
    (state) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);
// #endregion
