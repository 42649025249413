import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Skin, JsonStyle } from 'src/app/_common/services';

@Injectable({
    providedIn: 'root'
})
export class JsonStyleService
{
    constructor(private http: HttpClient) { }

    getSkinJson (skin: Skin): Observable<JsonStyle>
    {
        return this.http.get<any>(`assets/skins/${skin.toLowerCase()}.json`);
    }
}
