import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, concatAll, toArray } from 'rxjs/operators';

import { BaseMiscDomainService } from 'src/app/_common/domain/base-misc-domain.service';
import { TagMiscApiService } from '../api/tag-misc-api.service';
import { Tag, DetailedTag, EnrichedTag, UpsertTagViewModel } from '../models';

@Injectable({
    providedIn: 'root'
})
export class TagMiscDomainService extends BaseMiscDomainService
{
    constructor (
        private tagMiscApiService: TagMiscApiService
    )
    {
        super();
    }

    getAll (): Observable<Tag[]>
    {
        return this.tagMiscApiService.getAll()
            .pipe(
                concatAll(),
                map(Tag.mapperFromApi),
                toArray()
            );
    }

    getOne (tagId: string): Observable<DetailedTag>
    {
        return this.tagMiscApiService.getOne(tagId)
            .pipe(
                map(DetailedTag.mapperFromApi)
            );
    }

    createTag (tag: UpsertTagViewModel): Observable<EnrichedTag>
    {
        return this.tagMiscApiService.createTag(tag)
            .pipe(
                map(Tag.mapperFromApi),
                map(EnrichedTag.mapperFromNewTag)
            );
    }

    deleteTag (tagId: string): Observable<number>
    {
        return this.tagMiscApiService.deleteTag(tagId);
    }

    updateTag (tagId: string, tag: UpsertTagViewModel): Observable<Tag>
    {
        return this.tagMiscApiService.updateTag(tagId, tag)
            .pipe(
                map(Tag.mapperFromApi)
            );
    }
}
