export class RequestCountersApi
{
    public teamTickets: number;
    public userTickets: number;

    constructor (u: number = 0, t: number = 0)
    {
        this.userTickets = u;
        this.teamTickets = t;
    }
}
