<div class="input-component"
     [ngClass]="{'pristine': (pristine$ | async), 'valid': (validStatus$ | async), 'invalid': (invalidStatus$ | async), 'disabled': notEnabled}">
     <aa-validation [dirty]="dirty" [messages]="failures$ | async">
    </aa-validation>
    <div class="input-wrapper">
        <input [disabled]="notEnabled" type="text" class="input-field" [attr.required]="required" [(ngModel)]="value" [id]="fieldId"
               [name]="name" [attr.value]="value" [ngModelOptions]="updateOn" />
        <label [attr.for]="fieldId">{{label}}</label>
        <span class="input-validation fa-stack fa-1x" *ngIf="(dirtyStatus$ | async)">
            <i class="fas fa-stack-2x fa-circle" aria-hidden="true"></i>
            <i class="fal fa-stack-1x" [ngClass]="{'fa-check': (validStatus$ | async), 'fa-times': (invalidStatus$ | async)}"
               aria-hidden="true"></i>
        </span>
    </div>
</div>
