import { createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';

import { getBckpState, IBckpState } from './bckp.reducer';
import * as fromOptionsReducer from './common/options/options.reducer';

// #region Options
const getBckpOptionsState = createSelector(
    getBckpState,
    (state: IBckpState) =>
        state[fromOptionsReducer.BckpOptionsFeatureKey]
);
// #endregion

// #region Options/Masters
export const getBckpOptionsMastersIsDataValid = createSelector(
    getBckpOptionsState,
    (state: fromOptionsReducer.IBckpOptionsState) =>
        state.masters.expirationDate !== null && DateTime.utc() < state.masters.expirationDate
);
export const getBckpOptionsMastersState = createSelector(
    getBckpOptionsState,
    (state) => state.masters.collection
);
export const {
    selectAll: getMastersOptions,
} = fromOptionsReducer.mastersAdapter.getSelectors(getBckpOptionsMastersState);
// #endregion

// #region Options/JobsServers
export const getBckpOptionsServersIsDataValid = createSelector(
    getBckpOptionsState,
    (state: fromOptionsReducer.IBckpOptionsState) =>
        state.servers.expirationDate !== null && DateTime.utc() < state.servers.expirationDate
);
export const getBckpOptionsServersState = createSelector(
    getBckpOptionsState,
    (state) => state.servers.collection
);
export const {
    selectAll: getServersOptions,
} = fromOptionsReducer.serversAdapter.getSelectors(getBckpOptionsServersState);
// #endregion

// #region Options/ServerShorts
export const getBckpOptionsServerShortsIsDataValid = createSelector(
    getBckpOptionsState,
    (state: fromOptionsReducer.IBckpOptionsState) =>
        state.serverShorts.expirationDate !== null && DateTime.utc() < state.serverShorts.expirationDate
);
export const getBckpOptionsServerShortsState = createSelector(
    getBckpOptionsState,
    (state) => state.serverShorts.collection
);
export const {
    selectAll: getServerShortsOptions,
} = fromOptionsReducer.serverShortsAdapter.getSelectors(getBckpOptionsServerShortsState);
// #endregion

// #region Options/JobsPolicies
export const getBckpOptionsPoliciesIsDataValid = createSelector(
    getBckpOptionsState,
    (state: fromOptionsReducer.IBckpOptionsState) =>
        state.policies.expirationDate !== null && DateTime.utc() < state.policies.expirationDate
);
export const getBckpOptionsPoliciesState = createSelector(
    getBckpOptionsState,
    (state) => state.policies.collection
);
export const {
    selectAll: getPoliciesOptions,
} = fromOptionsReducer.policiesAdapter.getSelectors(getBckpOptionsPoliciesState);
// #endregion

// #region Options/PolicyTypes
export const getBckpOptionsPolicyTypesIsDataValid = createSelector(
    getBckpOptionsState,
    (state: fromOptionsReducer.IBckpOptionsState) =>
        state.policyTypes.expirationDate !== null && DateTime.utc() < state.policyTypes.expirationDate
);
export const getBckpOptionsPolicyTypesState = createSelector(
    getBckpOptionsState,
    (state) => state.policyTypes.collection
);
export const {
    selectAll: getPolicyTypesOptions,
} = fromOptionsReducer.policyTypesAdapter.getSelectors(getBckpOptionsPolicyTypesState);
// #endregion

//#region Options/CopiesServers
export const getBckpOptionsCopiesServersIsDataValid = createSelector(
    getBckpOptionsState,
    (state) => state.copiesServers.expirationDate !== null &&
        DateTime.utc() < state.copiesServers.expirationDate
);
const getBckpOptionsCopiesServersState = createSelector(
    getBckpOptionsState,
    (state) => state.copiesServers.collection
);
export const {
    selectAll: getCopiesServersOptions,
} = fromOptionsReducer.copiesServersAdapter.getSelectors(getBckpOptionsCopiesServersState);
//#endregion

//#region Options/CopiesPolicies
export const getBckpOptionsCopiesPoliciesIsDataValid = createSelector(
    getBckpOptionsState,
    (state) => state.copiesPolicies.expirationDate !== null &&
        DateTime.utc() < state.copiesPolicies.expirationDate
);
const getBckpOptionsCopiesPoliciesState = createSelector(
    getBckpOptionsState,
    (state) => state.copiesPolicies.collection
);
export const {
    selectAll: getCopiesPoliciesOptions,
} = fromOptionsReducer.copiesPoliciesAdapter.getSelectors(getBckpOptionsCopiesPoliciesState);
//#endregion

//#region Options/CopiesResidences
export const getBckpOptionsCopiesResidencesIsDataValid = createSelector(
    getBckpOptionsState,
    (state) => state.copiesResidences.expirationDate !== null &&
        DateTime.utc() < state.copiesResidences.expirationDate
);
const getBckpOptionsCopiesResidencesState = createSelector(
    getBckpOptionsState,
    (state) => state.copiesResidences.collection
);
export const {
    selectAll: getCopiesResidencesOptions,
} = fromOptionsReducer.copiesResidencesAdapter.getSelectors(getBckpOptionsCopiesResidencesState);
//#endregion

//#region Options/CopiesSLP
export const getBckpOptionsCopiesSLPIsDataValid = createSelector(
    getBckpOptionsState,
    (state) => state.copiesSLP.expirationDate !== null &&
        DateTime.utc() < state.copiesSLP.expirationDate
);
const getBckpOptionsCopiesSLPState = createSelector(
    getBckpOptionsState,
    (state) => state.copiesSLP.collection
);
export const {
    selectAll: getCopiesSLPOptions,
} = fromOptionsReducer.copiesSLPAdapter.getSelectors(getBckpOptionsCopiesSLPState);
//#endregion
