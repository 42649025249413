import { PrtgConstants } from '@apps/prtg/prtg-constants';
import { CmdbConstants } from '@apps/cmdb/cmdb.constants';
import { CompConstants } from '@apps/comp/comp.constants';
import { OstkConstants } from '@apps/ostk/ostk.constants';
import { ObjsConstants } from '@apps/objs/objs.constants';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { ReptConstants } from '@apps/rept/rept.constants';
import { SecuConstants } from '@apps/secu/secu-constants';
import { BackConstants } from '@apps/back/back.constants';
import { DashConstants } from '@apps/dash/dash.constants';
import { Its2Constants } from '@apps/its2/its2.constants';
import { PataConstants } from '@apps/pata/pata.constants';

import { NavbarItem } from '../../_common/models/navbar-item';
import { DateTime } from 'luxon';

const dash = {
    enabled: true,
    icon: 'fal fa-chart-line',
    id: 'DASH',
    production: DateTime.utc(2021, 10, 1),
    staging: DateTime.utc(2021, 10, 1),
    nuovo: false,
    soon: false,
    isDeployed: true,
    link: DashConstants.DefaultUrl,
    activeWhen: `/${DashConstants.BaseUrl}`,
    habilitation: false
};

const show = {
    enabled: false,
    icon: 'fal fa-shopping-cart',
    id: 'SHOW',
    production: DateTime.utc(2019, 1, 1),
    staging: DateTime.utc(2019, 1, 1),
    nuovo: false,
    soon: false,
    isDeployed: false,
    link: '',
    activeWhen: '',
    habilitation: false
};

const itsm = {
    enabled: true,
    icon: 'fal fa-concierge-bell',
    id: 'ITSM',
    production: DateTime.utc(2018, 12, 19),
    staging: DateTime.utc(2018, 12, 19),
    nuovo: false,
    soon: false,
    isDeployed: true,
    link: Its2Constants.DefaultUrl,
    activeWhen: `/${Its2Constants.BaseUrl}`,
    habilitation: false,
    onDashboard: true
};

const prtg = {
    enabled: true,
    icon: 'fal fa-tachometer-alt',
    id: 'PRTG',
    production: DateTime.utc(2020, 8, 1),
    staging: DateTime.utc(2020, 7, 20),
    nuovo: false,
    soon: false,
    isDeployed: true,
    link: PrtgConstants.DefaultUrl,
    activeWhen: `/${PrtgConstants.BaseUrl}`,
    habilitation: true,
    api: PrtgConstants.Application,
    onDashboard: true
};

const cmdb = {
    enabled: true,
    icon: 'fas fa-chart-network',
    id: 'CMDB',
    production: DateTime.utc(2020, 9, 1),
    staging: DateTime.utc(2020, 8, 20),
    nuovo: false,
    soon: false,
    isDeployed: true,
    link: CmdbConstants.DefaultUrl,
    activeWhen: `/${CmdbConstants.BaseUrl}`,
    habilitation: false,
    api: CmdbConstants.Application
};

const objs = {
    enabled: true,
    icon: 'fal fa-server',
    id: 'OBJS',
    production: DateTime.utc(2020, 1, 15),
    staging: DateTime.utc(2019, 12, 19),
    nuovo: false,
    soon: false,
    isDeployed: true,
    link: '/object/dashboard',
    activeWhen: '/object',
    habilitation: true,
    api: ObjsConstants.Application,
    onDashboard: true
};

const bckp = {
    enabled: true,
    icon: 'fal fa-save',
    id: 'BCKP',
    production: DateTime.utc(2020, 3, 1),
    staging: DateTime.utc(2020, 3, 1),
    nuovo: false,
    soon: false,
    isDeployed: true,
    link: BckpConstants.DefaultUrl,
    activeWhen: `/${BckpConstants.Bckp}`,
    habilitation: true,
    api: BckpConstants.Application,
    onDashboard: true
};

const comp = {
    enabled: true,
    icon: 'fal fa-cloud',
    id: 'COMP',
    production: DateTime.utc(2020, 5, 28),
    staging: DateTime.utc(2018, 6, 25),
    nuovo: false,
    soon: false,
    isDeployed: true,
    link: CompConstants.DefaultUrl,
    activeWhen: `/${CompConstants.BaseUrl}`,
    habilitation: true,
    api: CompConstants.Application,
    onDashboard: true
};

const rept = {
    enabled: true,
    icon: 'fal fa-folder-open',
    id: 'REPT',
    production: DateTime.utc(2020, 6, 29),
    staging: DateTime.utc(2020, 6, 22),
    nuovo: false,
    soon: false,
    isDeployed: true,
    link: '/report/reports',
    activeWhen: '/report',
    habilitation: true,
    api: ReptConstants.Application
};

const ostk = {
    enabled: true,
    icon: 'fal fa-clouds',
    id: 'OSTK',
    production: DateTime.utc(2021, 6, 10),
    staging: DateTime.utc(2021, 2, 1),
    nuovo: false,
    soon: false,
    isDeployed: true,
    link: OstkConstants.DefaultUrl,
    activeWhen: `/${OstkConstants.BaseUrl}`,
    habilitation: true,
    api: OstkConstants.Application
};

const secu = {
    enabled: true,
    icon: 'fal fa-shield',
    id: 'SECU',
    production: DateTime.utc(2020, 6, 29),
    staging: DateTime.utc(2020, 6, 22),
    nuovo: true,
    soon: false,
    isDeployed: true,
    link: SecuConstants.DefaultUrl,
    activeWhen: `/${SecuConstants.BaseUrl}`,
    habilitation: true,
    api: SecuConstants.Application
};

const pata = {
    enabled: true,
    icon: 'fal fa-tasks-alt',
    id: 'PATA',
    production: DateTime.utc(2022, 2, 1),
    staging: DateTime.utc(2022, 2, 1),
    nuovo: true,
    soon: false,
    isDeployed: true,
    link: PataConstants.DefaultUrl,
    activeWhen: `/${PataConstants.BaseUrl}`,
    habilitation: true,
    api: PataConstants.Application,
};

const backDash = {
    enabled: true,
    icon: 'fal fa-chart-line',
    id: 'BACK.DASH',
    production: DateTime.utc(2018, 12, 19),
    staging: DateTime.utc(2018, 12, 19),
    nuovo: false,
    link: '/admin/dashboard',
    activeWhen: '/admin/dashboard',
    habilitation: true,
    api: BackConstants.Application
};

const backEnti = {
    enabled: true,
    icon: 'fal fa-industry-alt',
    id: 'BACK.ENTITIES',
    production: DateTime.utc(2018, 12, 19),
    staging: DateTime.utc(2018, 12, 19),
    nuovo: false,
    soon: false,
    link: '/admin/entities/external',
    activeWhen: '/admin/entities',
    habilitation: true,
    api: BackConstants.Application
};

const backUser = {
    enabled: true,
    icon: 'fal fa-users',
    id: 'BACK.USERS',
    production: DateTime.utc(2018, 12, 19),
    staging: DateTime.utc(2018, 12, 19),
    nuovo: false,
    soon: false,
    link: '/admin/users/external',
    activeWhen: '/admin/users',
    habilitation: true,
    api: BackConstants.Application
};

const backPata = {
    enabled: true,
    icon: 'fal fa-tasks-alt',
    id: 'BACK.PATA',
    production: DateTime.utc(2018, 12, 19),
    staging: DateTime.utc(2018, 12, 19),
    nuovo: true,
    soon: false,
    link: '/admin/pata/copils',
    activeWhen: '/admin/pata',
    habilitation: true,
    api: BackConstants.Application
};

const items: any[] = [
    dash,
    pata,
    // show,
    itsm,
    prtg,
    cmdb,
    objs,
    bckp,
    comp,
    ostk,
    rept,
    secu,

    backDash,
    backEnti,
    backUser
];

const temp = [];
export const NavbarItems: NavbarItem[] = items.map((item: NavbarItem, idx: number): NavbarItem =>
{
    item.order = idx;
    const ni = NavbarItem.mapperFromObject(item);
    temp.push([ni.id.toLowerCase(), ni]);

    return ni;
});


export const MapNavbarItems: Map<string, NavbarItem> = new Map<string, NavbarItem>(temp);
