import { Component, Input } from '@angular/core';

import { ControlStatus } from "../../common/control-status";

@Component({
    selector: 'aa-check-mark9',
    templateUrl: './check-mark9.component.html',
    styleUrls: [
        './check-mark9.component.scss'
    ]
})
export class CheckMark9Component
{
    @Input() status: ControlStatus;
}
