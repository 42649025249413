import { DateTime } from 'luxon';

export class Variations
{
    public since: DateTime;
    public until: DateTime;
    public validUntil?: DateTime;
    public volumetricVariations: import('../../../domain/models').VolumetricVariation[];

    constructor ()
    {
        this.validUntil = null;
    }
}
