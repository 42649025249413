import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError, tap, switchMap, take, filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromAuthStore from '@apps/auth/store';
import * as fromRootStore from '@apps/root/store';
import { LanguageService } from '@common/services';
import { AppConstants } from '@apps/app.constants';

import { Profile } from '../models/profile';
import { TokenService } from '../api/token.service';
import { AuthenticationService } from '../api/authentication.service';
import { IAuroraState } from '../../aurora.state';

@Injectable({
    providedIn: 'root'
})
export class AppTokenCanActivateGuard implements CanActivate
{
    constructor (
        private router: Router,
        private routerStore: Store<any>,
        private store: Store<IAuroraState>,
        private tokenService: TokenService,
        private authenticationService: AuthenticationService,
        private languageService: LanguageService
    )
    { }

    canActivate (): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this.process();
    }

    process (): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const accessToken: string = this.tokenService.getAccessToken();

        if (accessToken !== null)
        {
            if (this.tokenService.isAccessTokenValid())
            {
                return this.loadProfile();
            }
            else
            {
                this.storeUrl();

                return this.tokenService.getNewToken()
                    .pipe(
                        switchMap(() =>
                            this.loadProfile()
                        ),
                        catchError(() =>
                            this.toLoginUrl()
                        )
                    );
            }
        }
        else
        {
            this.storeUrl();

            return this.toLoginUrl();
        }
    }

    private loadProfile ()
    {
        return this.authenticationService.profile()
            .pipe(
                take(1),
                tap((profile: Profile) =>
                {
                    this.store.dispatch(fromAuthStore.AuthProfileStoreActions.AuthProfileLoaded({ profile }));
                    this.store.dispatch(fromRootStore.RootMiscStoreActions.RootMiscSkinSwitched({
                        skin: Profile.fromThemeToSkin(profile.skin)
                    }));
                }),
                switchMap((profile: Profile) =>
                    this.languageService.setlanguage(profile.language)
                        .pipe(
                            take(1),
                            map((result: boolean) => result)
                        )
                ),
                catchError(() =>
                    this.toLoginUrl()
                )
            );
    }

    private storeUrl ()
    {
        this.routerStore
            .pipe(
                take(1),
                select(fromRootStore.selectUrl),
                filter((url: string) =>
                    url !== undefined
                ),
                filter((url: string) =>
                    !url.startsWith('/auth')
                ),
                tap((returnUrl: string) =>
                    this.store.dispatch(fromAuthStore.AuthLoginStoreActions.AuthReturnUrlSaved({ returnUrl }))
                )
            )
            .subscribe();
    }

    private toLoginUrl ()
    {
        this.authenticationService.logout();
        this.router.navigate([AppConstants.LoginUrl]);

        return of(null);
    }
}
