import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { RootConstants } from '@apps/root/root.constants';
import { Impersonation } from '@apps/root/models';
import { User } from '@apps/auth/domain/models/back';
import { IAuroraState } from '@apps/aurora.state';
import { RootImpersonateSet, RootImpersonateUnset } from '@apps/root/store/impersonate/impe.actions';
import { NotfGravity, NotificationService, NotifType } from '@common/services';

export class ImpersonationStorage
{
    impe: Impersonation;
    user: User;
}

@Injectable({
    providedIn: 'root'
})
export class ImpersonateService
{
    /**
     *
     */
    constructor (
        private store: Store<IAuroraState>,
        private notificationService: NotificationService,
    )
    {
    }

    public setImpersonation (user: User): void
    {
        const impersonation = new Impersonation(user);
        const impersonationStorage = {
            impe: impersonation,
            user: user
        } as ImpersonationStorage;

        localStorage.setItem(RootConstants.ImpersonateKey, JSON.stringify(impersonationStorage));
        this.store.dispatch(
            RootImpersonateSet({ impersonation })
        );

        this.notificationService.notify(
            ['', 'ADMIN.IMPERSONATE.NOTF.ENTRY'],
            NotfGravity.success,
            NotifType.SNACKBAR,
            {
                firstLastName: user.firstLastName,
                entityName: user.entityName
            }
        );
    }

    public debugImpersonation (): void
    {
        const str = '';
        const is: ImpersonationStorage = JSON.parse(str);

        localStorage.setItem(RootConstants.ImpersonateKey, JSON.stringify(is));
        this.store.dispatch(
            RootImpersonateSet({ impersonation: is.impe })
        );
    }

    public unsetImpersonation (): void
    {
        if (this.getImpersonation())
        {
            localStorage.removeItem(RootConstants.ImpersonateKey);
            this.store.dispatch(
                RootImpersonateUnset()
            );
            this.notificationService.notify(
                ['', 'ADMIN.IMPERSONATE.NOTF.QUIT'],
                NotfGravity.success,
                NotifType.SNACKBAR
            );
        }
    }

    public getImpersonation (): ImpersonationStorage
    {
        const str: string = localStorage.getItem(RootConstants.ImpersonateKey);
        if (str === null || str === '')
        {
            return null;
        }

        return JSON.parse(str);
    }
}
