import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsChartModule } from '../../components/components-chart.module';
import { StackedColumnComponent } from './stacked-column/stacked-column.component';
import { StackedColumn2Component } from './stacked-column2/stacked-column2.component';

@NgModule({
    declarations: [
        StackedColumnComponent,
        StackedColumn2Component
    ],
    imports: [
        CommonModule,
        ComponentsChartModule
    ],
    exports: [
        StackedColumnComponent,
        StackedColumn2Component
    ]
})
export class StackedColumnModule
{ }
