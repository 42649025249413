import { TypePopin } from '@apps/app.constants';
import { Entity } from '@apps/auth/domain/models/back/entity';
import { BackConstants } from '@apps/back/back.constants';
import { SearchResult } from './search-result';

export class BackAuthEntitySearchResult extends SearchResult<Entity>
{
    constructor (entity: Entity)
    {
        super('back-entity', entity.id);

        this.result = entity;
        this.icon = 'fal fa-industry-alt';

        this.statusKey = entity.statusKey;
        this.statusClass = entity.statusClass;

        this.link = {
            baseUrlFragment: entity.external
                ? `/${BackConstants.BaseUrl}/${BackConstants.Entities}/${BackConstants.External}`
                : `/${BackConstants.BaseUrl}/${BackConstants.Entities}/${BackConstants.Internal}`,
            outlet: TypePopin.DETAILS,
            params: [this.id]
        };
    }
}
