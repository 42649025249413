import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppConstants } from '@apps/app.constants';
import { Its2Constants } from '@apps/its2/its2.constants';
import { BaseMiscApiService } from '@common/api/base-misc-api.service';

import { RequestsSummaryCounterApi, RequestsUseCaseCounterApi, RequestsEvolutionSummaryApi, SimplifiedRequestApi } from './models';

@Injectable({
    providedIn: 'root'
})
export class RequestSecuApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlIts2;
        this.segmentApi = `/${Its2Constants.BaseApi}/requests/secu`;
    }

    getCounters (catalogGuid: string, openOnly?: boolean): Observable<RequestsSummaryCounterApi>
    {
        if (openOnly === true)
        {
            return this.get<RequestsSummaryCounterApi>(`/counters?catalogGuid=${catalogGuid}&openOnly=true`);
        }

        return this.get<RequestsSummaryCounterApi>(`/counters?catalogGuid=${catalogGuid}`);
    }

    getSummaryCounters (): Observable<RequestsUseCaseCounterApi[]>
    {
        if (AppConstants.IsDataLocal('secu'))
        {
            return this.http.get<RequestsUseCaseCounterApi[]>('assets/data/secu/TRV/summary.json');
        }

        return this.get<RequestsUseCaseCounterApi[]>('/summary');
    }

    getRequests (): Observable<SimplifiedRequestApi[]>
    {
        if (AppConstants.IsDataLocal('secu'))
        {
            return this.http.get<SimplifiedRequestApi[]>('assets/data/secu/TRV/requests.json');
        }

        return this.get<SimplifiedRequestApi[]>('/requests');
    }

    getEvolutionSummary (): Observable<RequestsEvolutionSummaryApi[]>
    {
        if (AppConstants.IsDataLocal('secu'))
        {
            return this.http.get<RequestsEvolutionSummaryApi[]>('assets/data/secu/TRV/evolution.json');
        }

        // return this.get<RequestsEvolutionSummaryApi[]>('/evolution?since=2022-07-01T00:00:00Z&until=2022-07-31T23:59:29Z');
        return this.get<RequestsEvolutionSummaryApi[]>('/evolution');
    }
}
