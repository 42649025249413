import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { concatAll, map, toArray } from 'rxjs/operators';

import { FlavorMiscApiService } from '../api';
import { Flavor } from './models';
import { FlavorApi } from '../api/models';

@Injectable({
    providedIn: 'root'
})
export class FlavorMiscDomainService
{
    constructor (
        private flavorMiscApiService: FlavorMiscApiService
    )
    {
    }

    public getFlavors (): Observable<Flavor[]>
    {
        return this.flavorMiscApiService.getFlavors()
            .pipe(
                concatAll(),
                map((flavorApi: FlavorApi) => Flavor.mapperFromApi(flavorApi)),
                toArray()
            );
    }
}
