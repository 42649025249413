import { DateTime } from 'luxon';

import { QueryString } from '@common/api/qs/query-string';
import { DatesService } from '@common/services';

export class Its2DashboardFilter
{
    public static mapToQueryString (since: DateTime, until: DateTime): QueryString
    {
        const qs = new QueryString();

        qs.pushValue('since', DatesService.DateToQueryStringStatic(since));
        qs.pushValue('until', DatesService.DateToQueryStringStatic(until));

        return qs;
    }

}