import { TypePopin } from '@apps/app.constants';
import { Project, Document } from '@apps/pata/domain/models';
import { OpenPopinOriginator } from '@common/enum/open-popin-originator.enum';
import { Guid } from '@common/services/utils.service';
import { Copil } from './domain/models/copil';

export enum DocumentType
    {
    Root = 1,
    Directory = 2,
    Document = 4
}
export class PataConstants
{
    public static Application: string = 'ProjApi';
    public static BaseApi: string = 'pata';
    public static BaseUrl = 'project';
    public static Projects = 'projects';
    public static Planning = 'planning';
    public static Sdm = 'sdm';
    public static Copils = 'copils';
    public static Documents = 'documents';
    public static DefaultUrl: string = `/${PataConstants.BaseUrl}/${PataConstants.Projects}`;
    public static RootNodeName = 'RootNode';
    public static RootNodeGuid = Guid.empty;
    public static BeginningOfDate: string = '1970-01-01T00:00:00';

    // Durée de vie en minutes des éléments dans le store
    public static DataLifeTime: number = 7;
    public static ToProjectsRoute = (): string[] => [
        PataConstants.BaseUrl,
        PataConstants.Projects
    ];
    public static ProjectsPath = `/${PataConstants.ToProjectsRoute().join('/')}`;

    public static FromProjectsToDetailsRoute (projectNumber: string): any[]
    {
        return [
            ...PataConstants.ToProjectsRoute(),
            { outlets: { [TypePopin.DETAILS]: [ OpenPopinOriginator.PATA_PROJECTS, projectNumber ] } }
        ];
    }
    public static ToPlanningRoute = (): string[] => [
        PataConstants.BaseUrl,
        PataConstants.Planning
    ];
    public static PlanningPath = `/${PataConstants.ToPlanningRoute().join('/')}`;

    public static FromPlanningToDetailsRoute (projectNumber: string): any[]
    {
        return [
            ...PataConstants.ToPlanningRoute(),
            { outlets: { [TypePopin.DETAILS]: [ OpenPopinOriginator.PATA_PLANNING, projectNumber ] } }
        ];
    }

    public static FromCopilsToDetailsRoute (copilId: string): any[]
    {
        return [
            ...PataConstants.ToCopilsRoute(),
            { outlets: { [TypePopin.DETAILS]: [ copilId ] } }
        ];
    }

    public static ToDocumentsRoute = (): string[] => [
        PataConstants.Sdm,
        PataConstants.Documents
    ];
    public static DocumentsPath = `/${PataConstants.ToDocumentsRoute().join('/')}`;

    public static ToCopilsRoute = (): string[] => [
        PataConstants.Sdm,
        PataConstants.Copils
    ];
    public static CopilsPath = `/${PataConstants.ToCopilsRoute().join('/')}`;

    public static ProjectQSFields: Array<keyof Project> = ['projectNumber', 'orderNumber', 'description'];
    public static DocumentQSFields: Array<keyof Document> = ['name'];
    public static CopilsQSFields: (keyof Copil)[] = ['entity', 'entityErpId', 'governorFirstLastName', 'projectNumber', 'orderNumber', 'description', 'unit', 'projectName', 'billCustomer', 'manager', 'managerSup'];
}
