import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { BrowserModule, Title, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Hammer from 'hammerjs';

import { AuthenticationService } from '@apps/auth/api/authentication.service';
import { TokenService } from '@apps/auth/api/token.service';
import { SharedModule } from '@common/shared.module';
import { RootStoreModule } from '@apps/root/store/root-store.module';
import { PopinNotificationsComponent } from '@layouts/header/popin-notifications/popin-notifications.component';
import { PopinNotifItemComponent } from '@layouts/header/popin-notifications/popin-notif-item/popin-notif-item.component';
import { DomService } from '@ui/misc/modal/dom.service';
import { ModalService } from '@ui/misc/modal/modal.service';
import { SnackbarService } from '@ui/misc/snackbar/snackbar.service';
import { GuardModalService } from '@ui/misc/guard-modal/guard-modal.service';
import { PopinModalService } from '@ui/misc/popin-modal/popin-modal.service';
import { AuthTokenCanActivateGuard } from '@apps/auth/guards/auth-token.can-activate.guard';
import { AppTokenCanActivateGuard } from '@apps/auth/guards/app-token.can-activate.guard';

import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app.routing';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NotfModule } from './notf/notf.module';

import
{
    LanguageService,
    BytesService,
    DatesService,
    NumbersService,
    TyposService,
    NotificationService,
    ApplicationsService,
    LoaderService,
    PersonalDataService,
    PercentsService
} from '@common/services';
import
{
    ApplyCredentialsService,
    AuthInterceptor,
    RequestInterceptor,
    ObjsBucketInterceptor,
    LoaderInterceptor,
    ImpersonateInterceptor,
    FailureInterceptor,
    OstkInterceptor,
    AdminCustomerInterceptor
} from '@common/interceptors';
import { SentryErrorHandler } from '@common/errorHandlers/sentry-error-handler';
import { RangeService } from '@common/services/range.service';
import { SkinResolver } from '@apps/root/resolvers/skin.resolver';
import { Favicon, FAVICONS_CONFIG, FaviconService } from '@common/services/favicon.service';
import { RootResolver } from '@apps/root/resolvers/root.resolver';
import { CmdbStoreModule } from '@apps/cmdb/store';
import { Its2StoreModule } from '@apps/its2/store';

// AoT requires an exported function for factories
export function createTranslateLoader (handler: HttpBackend)
{
    const http = new HttpClient(handler);

    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable()
export class AuroraHammerConfig extends HammerGestureConfig
{
    overrides = <any>{
        swipe: {
            direction: Hammer.DIRECTION_ALL
        },
        // I will only use the swap gesture so
        // I will deactivate the others to avoid overlaps
        // 'pinch': { enable: false },
        // 'rotate': { enable: false }
    };
}

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        PopinNotificationsComponent,
        PopinNotifItemComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        OverlayModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpBackend]
            }
        }),
        AuthModule,
        NotfModule,
        AppRoutingModule,
        RootStoreModule,
        CmdbStoreModule,
        Its2StoreModule,
        DragDropModule
    ],
    exports: [
        TranslatePipe
    ],
    providers: [
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: AuroraHammerConfig
        },
        SkinResolver,
        RootResolver,
        AuthTokenCanActivateGuard,
        AppTokenCanActivateGuard,
        LanguageService,
        BytesService,
        DatesService,
        RangeService,
        NumbersService,
        TyposService,
        NotificationService,
        ApplicationsService,
        LoaderService,
        Title,
        AuthenticationService,
        ApplyCredentialsService,
        TokenService,
        DomService,
        ModalService,
        GuardModalService,
        PopinModalService,
        SnackbarService,
        PersonalDataService,
        PercentsService,
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ObjsBucketInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OstkInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ImpersonateInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: FailureInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AdminCustomerInterceptor,
            multi: true
        },
        // ! Bien laisser cet interceptor en dernier
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },
        {
            provide: Favicon,
            useClass: FaviconService
        },
        {
            provide: FAVICONS_CONFIG,
            useValue: {
                icons: {
                    'impersonate': {
                        type: 'image/png',
                        href: './icons/impersonate.png'
                    },
                    'antemeta': {
                        type: 'image/x-icon',
                        href: './icons/favicon.ico',
                        isDefault: true
                    }
                },
                cacheBusting: false
            }
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule
{
}
