import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { concatAll, filter, toArray } from 'rxjs/operators';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { mapSelectOption } from '@common/operators';
import { SelectOption } from '@ui/components/form/common';
import { NamesListApiService } from '../api';
import { BckpConstants } from '../bckp.constants';

@Injectable({
    providedIn: 'root'
})
export class OptionListDomainService extends BaseMiscDomainService
{

    constructor (
        private namesListApiService: NamesListApiService
    )
    {
        super();
    }

    getJobsServers (): Observable<SelectOption[]>
    {
        return this.namesListApiService.getJobsServers()
            .pipe(
                mapSelectOption()
            );
    }

    getJobsServerShorts (): Observable<SelectOption[]>
    {
        return this.namesListApiService.getJobsServerShorts()
            .pipe(
                mapSelectOption()
            );
    }

    getJobsMasters (): Observable<SelectOption[]>
    {
        return this.namesListApiService.getJobsMasters()
            .pipe(
                mapSelectOption()
            );
    }

    getJobsPolicies (): Observable<SelectOption[]>
    {
        return this.namesListApiService.getJobsPolicies()
            .pipe(
                mapSelectOption()
            );
    }

    getJobsPolicyTypes (): Observable<SelectOption[]>
    {
        return this.namesListApiService.getJobsPolicyTypes()
            .pipe(
                mapSelectOption()
            );
    }

    getCopiesServers (): Observable<SelectOption[]>
    {
        return this.namesListApiService.getCopiesServers()
            .pipe(
                mapSelectOption()
            );
    }

    getCopiesPolicyNames (): Observable<SelectOption[]>
    {
        return this.namesListApiService.getCopiesPolicyNames()
            .pipe(
                mapSelectOption()
            );
    }

    getcopiesResidences (): Observable<SelectOption[]>
    {
        return this.namesListApiService.getcopiesResidences()
            .pipe(
                concatAll(),
                filter((residence: string) => !residence.includes(BckpConstants.RemoteMaster)),
                toArray(),
                mapSelectOption()
            );
    }

    getCopiesStorageLifecyclePolicy (): Observable<SelectOption[]>
    {
        return this.namesListApiService.getCopiesStorageLifecyclePolicy()
            .pipe(
                mapSelectOption()
            );
    }

}
