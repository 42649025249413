import { DateTime } from 'luxon';

import { DatesService } from '@common/services';

import { CmdbAsset } from './cmdb-asset';
import { CmdbRelatedAsset } from './cmdb-related-asset';
import { CmdbAssetDetailsApi } from '../api/models';
import { CmdbConstants } from '../cmdb.constants';

export type CmdbAssetDetails = VirtualMachine | PhysicalServer | Application | Entity;

class BaseCmdbAssetDetails extends CmdbAsset
{
    public ciNumber: string;
    public lastReview?: DateTime;
    public classification: string;
    public isAutomatedRequests?: boolean;
    public offer: string;
    public contractNumber: string;
    public isRacktables?: boolean;
    public parents: CmdbRelatedAsset[];
    public children: CmdbRelatedAsset[];
    public automatedRequestsScopeKey?: string;
    public racktablesScopeKey?: string;
    public perimeter: string;
    public eLastMinorUpdate: string;

    public static mapperFromApi (source: CmdbAssetDetailsApi): BaseCmdbAssetDetails
    {
        const target = Object.assign(new BaseCmdbAssetDetails(), CmdbAsset.mapperFromApi(source));
        target.parents = source.parents.map(CmdbRelatedAsset.mapperFromApi);
        target.children = source.children.map(CmdbRelatedAsset.mapperFromApi);
        target.ciNumber = source.ciNumber === null ? '-' : source.ciNumber;

        if (source.lastReview)
        {
            if (!source.lastReview.endsWith('Z'))
            {
                source.lastReview += 'Z';
            }

            target.lastReview = DatesService.UtcStringToDateTimeHourStatic(source.lastReview);
        }

        if (source.isAutomatedRequests !== null)
        {
            target.automatedRequestsScopeKey = CmdbConstants.getYesNoKey(source.isAutomatedRequests);
        }

        if (source.isRacktables !== null)
        {
            target.racktablesScopeKey = CmdbConstants.getYesNoKey(source.isRacktables);
        }

        return target;
    }
}


export class VirtualMachine extends BaseCmdbAssetDetails
{
    os: string;
    lastSecurityUpdate?: DateTime;

    public static mapperFromApi (source: CmdbAssetDetailsApi): VirtualMachine
    {
        const target = Object.assign(new VirtualMachine(), BaseCmdbAssetDetails.mapperFromApi(source));

        if (source.lastSecurityUpdate !== null)
        {
            target.lastSecurityUpdate = DatesService.UtcStringToDateTimeHourStatic(source.lastSecurityUpdate);
        }

        return target;
    }
}

export class PhysicalServer extends BaseCmdbAssetDetails
{
    os: string;
    spProliantVersion: string;
    lastSecurityUpdate?: string;

    public static mapperFromApi (source: CmdbAssetDetailsApi): PhysicalServer
    {
        const target = Object.assign(new PhysicalServer(), BaseCmdbAssetDetails.mapperFromApi(source));

        return target;
    }
}

export class Application extends BaseCmdbAssetDetails
{
    role: string;
    applicationVersion: string;

    public static mapperFromApi (source: CmdbAssetDetailsApi): Application
    {
        const target = Object.assign(new Application(), BaseCmdbAssetDetails.mapperFromApi(source));

        return target;
    }
}

export class OperatingSystem extends BaseCmdbAssetDetails
{
    os: string;
    lastSecurityUpdate?: DateTime;

    public static mapperFromApi (source: CmdbAssetDetailsApi): OperatingSystem
    {
        const target = Object.assign(new OperatingSystem(), BaseCmdbAssetDetails.mapperFromApi(source));

        if (source.lastSecurityUpdate !== null)
        {
            target.lastSecurityUpdate = DatesService.UtcStringToDateTimeHourStatic(source.lastSecurityUpdate);
        }

        return target;
    }
}

export class Entity extends BaseCmdbAssetDetails
{
    public static mapperFromApi (source: CmdbAssetDetailsApi): Entity
    {
        const target = Object.assign(new Entity(), BaseCmdbAssetDetails.mapperFromApi(source));

        return target;
    }
}
