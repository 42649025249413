import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ResultApi } from '@common/api/result-api';
import { QueryString } from '@common/api/qs/query-string';
import { BaseListApiService } from '@common/api/base-list-api.service';

import { PolicyDetailsApi } from './models/policy-api';
import { AppConstants } from '../../app.constants';

@Injectable({
    providedIn: 'root'
})
export class PolicyDetailsApiService extends BaseListApiService<PolicyDetailsApi>
{
    private endPoint = '/latestpolicies';

    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlHist;
        this.resourceUrl = '/hist/netbackup';
    }
    getPolicyDetails (documentId: string): Observable<ResultApi<PolicyDetailsApi>>
    {
        const queryString = new QueryString();

        queryString.pushValue('terms', `documentId:${documentId}`);
        if (AppConstants.IsDataLocal('bckp'))
        {
            return this.http.get<ResultApi<PolicyDetailsApi>>('assets/data/bckp/onePolicy.json');
        }

        return this.getAll(`${this.resourceUrl}${this.endPoint}${queryString.qs}`);
    }
}
