import { DateTime } from 'luxon';

import { Skin } from '@common/services';

import { ApplicationRole } from './application-role';
import { ProfileApi } from '../api/models/profile-api';
import { AppConstants } from '../../app.constants';

export class Profile
{
    firstName?: string;
    lastName?: string;
    firstLastName?: string;
    appRoles?: ApplicationRole[];
    subscriptions?: string[];
    id?: string;
    identiconDataUrl?: string;
    userName?: string;
    normalizedUserName?: string;
    email?: string;
    normalizedEmail?: string;
    emailConfirmed?: boolean;
    phoneNumber?: string;
    phoneNumberConfirmed?: boolean;
    mobile?: string;
    twoFactorEnabled?: boolean;
    entityId?: string;
    entityName?: string;
    title?: string;
    marketingNewsletter?: boolean;
    maintenanceNotification?: boolean;
    language?: string;
    skin?: Skin;
    isEntityAdmin?: boolean;
    workingCookies?: boolean;
    statisticsCookies?: boolean;
    socialNetworksCookies?: boolean;
    nextProfileCompletionUtc?: DateTime;
    entityShortId?: string;
    entityErpId?: string;
    isCatalogSecuAuthorized?: boolean = false;
    entityDocFolder?: string;
    entityErpShortId?: string;

    public static mapperFromApi (source: ProfileApi): Profile
    {
        const target = new Profile();

        Object.assign(target, source);

        target.appRoles = source.roles ? [...source.roles] : null;

        // tslint:disable-next-line: no-string-literal
        delete target['roles'];

        target.nextProfileCompletionUtc = DateTime.fromISO(source.nextProfileCompletionUtc);
        if (!target.nextProfileCompletionUtc.isValid)
        {
            target.nextProfileCompletionUtc = null;
        }

        return target;
    }

    public static fromThemeToSkin (theme: string): Skin
    {
        if (theme && theme.startsWith('theme-'))
        {
            const skin = theme.substring(6) as Skin;

            if (AppConstants.themes4Dev.includes(skin))
            {
                return skin;
            }
        }

        return 'light';
    }

    public static fromSkinToTheme (skin: Skin): string
    {
        return `theme-${skin}`;
    }
}
