import { Action, combineReducers } from '@ngrx/store';

import { IAuroraState } from '@apps/aurora.state';
import * as fromCopiesCurrReducer from './curr/curr.reducer';
import * as fromCopiesEvolReducer from './evol/evol.reducer';

export * from './curr/curr.reducer';
export * from './evol/evol.reducer';

export const BckpCopiesFeatureKey = 'copies';

export interface IState extends IAuroraState
{
    [BckpCopiesFeatureKey]: IBckpCopiesState;
}

export interface IBckpCopiesState
{
    [fromCopiesCurrReducer.BckpCopiesCurrFeatureKey]: fromCopiesCurrReducer.IBckpCopiesCurrState;
    [fromCopiesEvolReducer.BckpCopiesEvolFeatureKey]: fromCopiesEvolReducer.IBckpCopiesEvolState;
}

export function BckpCopiesReducers (state: IBckpCopiesState | undefined, action: Action)
{
    return combineReducers({
        [fromCopiesCurrReducer.BckpCopiesCurrFeatureKey]: fromCopiesCurrReducer.BckpCopiesCurrReducer,
        [fromCopiesEvolReducer.BckpCopiesEvolFeatureKey]: fromCopiesEvolReducer.BckpCopiesEvolReducer
    })(state, action);
}
