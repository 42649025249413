import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParameterCodec } from '@angular/common/http';

import { Observable, firstValueFrom, from } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { mergeMap, switchMap, take } from 'rxjs/operators';

import { IAuroraState } from '@apps/aurora.state';
import { getSelectedBucket } from '@apps/objs/store/bucket/bucket-selectors';
import { Bucket } from '@apps/objs/domain/models';
import { getAllObjsPlatforms, getHeaderPlatform } from '@apps/objs/store/storage/storage-selectors';
import { ObjsConstants } from '@apps/objs/objs.constants';

// @see https://stackoverflow.com/a/52458069
class CustomEncoder implements HttpParameterCodec
{
    encodeKey (key: string): string
    {
        return encodeURIComponent(key);
    }

    encodeValue (value: string): string
    {
        return encodeURIComponent(value);
    }

    decodeKey (key: string): string
    {
        return decodeURIComponent(key);
    }

    decodeValue (value: string): string
    {
        return decodeURIComponent(value);
    }
}

@Injectable()
export class ObjsBucketInterceptor implements HttpInterceptor
{
    constructor (
        private store: Store<IAuroraState>
    )
    { }

    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // TODO ObjsConstants.HeaderPlatform = undefined au sein de cette classe ?
        if (!req.url.includes('objs/s3') || req.headers.has('X-AurorA-ObjsPlatformId'))
        {
            return next.handle(req);
        }

        return this.store
            .pipe(
                select(getSelectedBucket),
                take(1),
                switchMap((bucket: Bucket) =>
                {
                    return from(this.determinePlatformId(req, bucket))
                        .pipe(
                            mergeMap(platformId =>
                            {
                                if (!platformId)
                                {
                                    return next.handle(req);
                                }

                                const clonedRequest: HttpRequest<any> = req.clone({
                                    headers: req.headers
                                        .set(ObjsConstants.HeaderPlatform, platformId)
                                });

                                return next.handle(clonedRequest);
                            })
                        );
                })
            );
    }

    private async determinePlatformId (req: HttpRequest<any>, bucket: Bucket | null): Promise<string>
    {
        let platformId = '';

        if (!bucket)
        {
            if (req.method === 'POST' && !req.headers.has(ObjsConstants.HeaderPlatform))
            {
                const platforms = await firstValueFrom(this.store.pipe(select(getAllObjsPlatforms), take(1)));
                platformId = platforms?.find(p => !p.disabled && !p.readOnly)?.id || '';
            }
        }
        else
        {
            platformId = bucket.platformId;
        }

        return platformId;
    }
}
