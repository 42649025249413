import { DateTime } from 'luxon';

export class Period28To28
{
    since: DateTime;
    until: DateTime;
    name: string;
    year: number;
    details: string;

    constructor (since: DateTime, until: DateTime)
    {
        this.since = since;
        this.until = until;
        this.name = `${until.monthLong} ${until.year}`;
        this.details = `${this.detailed(since)} - ${this.detailed(until)}`;
    }

    private detailed (dt: DateTime): string
    {
        return `${dt.toLocaleString(DateTime.DATE_SHORT)} ${dt.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}`;
    }
}
