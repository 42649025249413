import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { AppConstants } from '@apps/app.constants';
import { CreateInstanceViewModel } from '@apps/ostk/models/instance';
import { ImageApi, InstanceApi } from '../models';
import { InstanceTimelineApi } from '../models/instance';
import { ManageSecurityGroupsViewModel } from '@apps/ostk/models/instance/manage-security-groups-view-model';

@Injectable({
    providedIn: 'root'
})
export class InstanceMiscApiService extends BaseMiscApiService
{
    constructor (
        http: HttpClient,
    )
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlOstk;
        this.segmentApi = '/ostk/instances';
    }

    getAllInstances (): Observable<InstanceApi[]>
    {
        if (AppConstants.IsDataLocal('ostk'))
        {
            return this.http.get<InstanceApi[]>('assets/data/ostk/instances.json');
        }

        return this.get<InstanceApi[]>('?populated=false');
    }

    getSingleByName (name: string): Observable<InstanceApi[]>
    {
        return this.get<InstanceApi[]>(`?name=${name}&populated=false`);
    }

    getDetails (instanceId: string): Observable<InstanceApi>
    {
        return this.get<InstanceApi>(`/${instanceId}/details`);
    }

    getInstancesBySecurityGroup (): Observable<any>
    {
        return this.get('/securitygroups');
    }

    getHistory (instanceId: string): Observable<Partial<InstanceTimelineApi[]>>
    {
        return this.get<InstanceTimelineApi[]>(`/${instanceId}/history`);
    }

    public refreshInstance (instanceId: string): Observable<InstanceApi>
    {
        return this.post<InstanceApi>(`/${instanceId}/refresh`, {});
    }

    public isNotUnique (instanceName: string): Observable<boolean>
    {
        return this.get<boolean>(`/not-unique/${instanceName}`);
    }

    // public createInstance (instance: Partial<InstanceApi>): Observable<Instance>
    // {
    //     // return this.post<InstanceApi>('', instance)
    //     const myInstance: InstanceApi = Object.assign(instance, mockedInstanceApiResponse(instance));
    //     return of(myInstance)
    //         .pipe(
    //             map((inst: InstanceApi) => Instance.mapperFromApi(inst, this.datesService)),
    //             // map(_ => {
    //             //     throw new Error('Erreur de création');
    //             // })
    //         );
    // }

    public startInstance (instanceId: string): Observable<InstanceApi>
    {
        return this.post<InstanceApi>(`/${instanceId}/start`, {});
    }

    public pauseInstance (instanceId: string): Observable<InstanceApi>
    {
        return this.post<InstanceApi>(`/${instanceId}/pause`, {});
    }

    public stopInstance (instanceId: string): Observable<InstanceApi>
    {
        return this.post<InstanceApi>(`/${instanceId}/stop`, {});
    }

    public createInstance (instance: CreateInstanceViewModel): Observable<boolean>
    {
        return this.post<boolean>('', instance);
    }

    public takeSnapshot (instanceId: string, name: string): Observable<ImageApi>
    {
        return this.post(`/${instanceId}/takesnapshot`, { name });
    }

    public manageSecurityGroups (instanceId: string, securityGroups: ManageSecurityGroupsViewModel): Observable<InstanceApi>
    {
        return this.post<InstanceApi>(`/${instanceId}/securitygroups`, securityGroups);
    }

    public deleteInstance (instanceId: string): Observable<void>
    {
        return this.delete(`/${instanceId}`);
    }

    public renameInstance (instanceId: string, newName: string): Observable<void>
    {
        return this.post(`/${instanceId}/rename?newName=${newName}`, { newName });
    }
}
