import { Component, OnDestroy } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

import { GuardModalRef } from '../guard-modal.ref';
import { GuardModalConfig } from '../guard-modal.config';

@Component({
    selector: 'aa-guard-modal',
    templateUrl: './guard-modal.component.html',
    styleUrls: [
        './guard-modal.component.scss'
    ]
})
export class GuardModalComponent implements OnDestroy
{
    private destroyator = new Subject();
    config: GuardModalConfig = new GuardModalConfig();
    _msg: string;
    title: string;
    msgOk: string;
    msgNo: string;
    btnOk: string;
    btnNo: string;

    get msg (): string
    {
        return this._msg ? this.translateService.instant(this.config.msgKey, this.config.data) : '';
    }
    set msg (value: string)
    {
        this._msg = value;
    }

    constructor (
        private guardModalRef: GuardModalRef,
        private translateService: TranslateService
    )
    {
        Object.assign(this.config, this.guardModalRef.config);
        this.title = this.translateService.instant(this.config.titleKey, this.config.data);
        this.msg = this.guardModalRef.config ? this.guardModalRef.config.msgKey : '';
        this.msgOk = this.translateService.instant(this.config.msgOkKey, this.config.data);
        this.msgNo = this.translateService.instant(this.config.msgNoKey, this.config.data);
        this.btnOk = this.translateService.instant(this.config.btnOkKey, this.config.data);
        this.btnNo = this.translateService.instant(this.config.btnNoKey, this.config.data);

        this.guardModalRef.overlayRef
            .keydownEvents()
            .pipe(
                tap((event: KeyboardEvent) =>
                {
                    if (event.key === 'Escape')
                    {
                        this.close(false);
                    }
                }),
                takeUntil(this.destroyator)
            )
            .subscribe();
    }

    ngOnDestroy ()
    {
        this.destroyator.next(null);
        this.destroyator.complete();
    }

    // // ! TODO Étudier l'intérêt de ce code - fut sans doute un test
    // @HostListener('click', ['$event'])
    // handleClick ($event: MouseEvent)
    // {
    //     $event.stopPropagation();
    //     $event.stopImmediatePropagation();
    // }

    public close (dialogResult: boolean)
    {
        this.guardModalRef.close(dialogResult);
    }
}
