import { createAction, props } from '@ngrx/store';

import { PropertyValues } from '../models/property-values';

export const Its2RequestsValuesRequested = createAction(
    '[ITS2-REQUEST] Its2 Requests Values Requested',
    props<{ propertyName: string; }>()
);

export const Its2RequestsValuesSucceeded = createAction(
    '[ITS2-REQUEST] Its2 Requests Values Succeeded',
    props<{ propertyValues: PropertyValues; }>()
);

export const Its2RequestsValuesFailed = createAction(
    '[ITS2-REQUEST] Its2 Requests Values Failed',
    props<{ error: any; }>()
);
