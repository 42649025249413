import { RequestsUseCaseCounter, SimplifiedRequest } from '@apps/its2/domain/models';
import { StatusesMetadata } from '@common/models';

export const SecuDashBreakdownFeatureKey = 'breakdown';


export interface ISecuDashBreakdownCountersState extends StatusesMetadata
{
    collection: RequestsUseCaseCounter[]
}
export const SecuDashBreakdownCountersInitialState: ISecuDashBreakdownCountersState = {
    collection: [],
    ...StatusesMetadata.init()
};

export interface ISecuDashBreakdownIncidentsState extends StatusesMetadata
{
    collection: SimplifiedRequest[]
}
export const SecuDashBreakdownIncidentsInitialState: ISecuDashBreakdownIncidentsState = {
    collection: [],
    ...StatusesMetadata.init()
};


export interface ISecuDashBreakdownState
{
    counters: ISecuDashBreakdownCountersState;
    incidents: ISecuDashBreakdownIncidentsState;
    ucTypeId?: number;
    hoveredUcTypeId?: number;
}

export const SecuDashBreakdownInitialState: ISecuDashBreakdownState = {
    counters: SecuDashBreakdownCountersInitialState,
    incidents: SecuDashBreakdownIncidentsInitialState,
    ucTypeId: null,
    hoveredUcTypeId: null
};
