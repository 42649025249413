import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BtnActionPrimaryComponent } from './btn-action-primary/btn-action-primary.component';
import { BtnActionSecondaryComponent } from './btn-action-secondary/btn-action-secondary.component';
import { BtnAction2SecondaryComponent } from './btn-action2-secondary/btn-action2-secondary.component';
import { BtnActionTertiaryComponent } from './btn-action-tertiary/btn-action-tertiary.component';
import { BtnActionTransparentComponent } from './btn-action-transparent/btn-action-transparent.component';
import { BtnActionIconComponent } from './btn-action-icon/btn-action-icon.component';
import { BtnActionQuaternaryComponent } from './btn-action-quaternary/btn-action-quaternary.component';
import { BtnAction2TertiaryComponent } from '@ui/components/buttons/btn-action2-tertiary/btn-action2-tertiary.component';

@NgModule({
    declarations: [
        BtnActionPrimaryComponent,
        BtnActionSecondaryComponent,
        BtnAction2SecondaryComponent,
        BtnActionTertiaryComponent,
        BtnAction2TertiaryComponent,
        BtnActionQuaternaryComponent,
        BtnActionTransparentComponent,
        BtnActionIconComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        BtnActionPrimaryComponent,
        BtnActionSecondaryComponent,
        BtnAction2SecondaryComponent,
        BtnActionTertiaryComponent,
        BtnAction2TertiaryComponent,
        BtnActionQuaternaryComponent,
        BtnActionTransparentComponent,
        BtnActionIconComponent
    ]
})
export class UiBtnModule
{ }
