import { DateTime } from 'luxon';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ShortProfile } from '@apps/auth/domain/models';
import { CompareLowerString2 } from '@common/services';

export const AuthUsersFeatureKey = 'users';
export type IUsersState = EntityState<ShortProfile>;
export const usersAdapter: EntityAdapter<ShortProfile> = createEntityAdapter<ShortProfile>({
    selectId: (user: ShortProfile) =>
        user.userName,
    sortComparer: CompareLowerString2<ShortProfile>((user: ShortProfile): string =>
        user.userName
    )
});
export const usersInitialState: IUsersState = usersAdapter.getInitialState();

export interface IAuthUsersState
{
    users: IUsersState;
    expirationDate: DateTime;
}

export const AuthUsersInitialState: IAuthUsersState = {
    users: usersInitialState,
    expirationDate: null
};
