import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompareDateTime2 } from '@common/services/utils.service';
import { Credential } from '@apps/objs/domain/models/credential';

export const ObjsCredentialFeatureKey = 'credentials';

export type IObjsCredentialState = EntityState<Credential>;

export const credentialAdapter: EntityAdapter<Credential> = createEntityAdapter<Credential>({
    selectId: (c: Credential) => c.accessKey,
    sortComparer: CompareDateTime2<Credential>((item: Credential) => item.createDate)
});

export const objsCredentialInitialState: IObjsCredentialState = credentialAdapter.getInitialState({
});

