import { TypePopin } from '@apps/app.constants';
import { CompareLowerString2, UtilsService } from '@common/services/utils.service';
import { S3File, S3Object } from './domain/models';

export enum CounterType
{
    Bytes = 'totalBytes',
    Objects = 'totalObjects'
}

export enum S3ObjectFlags {
    Folder = 1,
    Versioned = 4,
    Latest = 8,
    DeleteMarker = 16
}

export class ObjsConstants
{
    static getObjectStatusLabel (file: S3File): string
    {
        if (file.isDeleted)
        {
            return 'Deleted';
        }

        if (file.isLatest)
        {
            return 'Latest';
        }

        return '';
    }
    static getObjectStatusClass (file: S3File): string
    {
        if (file.isDeleted)
        {
            return 'objs-is-deleted';
        }

        if (file.isLatest)
        {
            return 'objs-is-latest';
        }

        return '';
    }
    public static Application: string = 'ObjsApi';
    public static BaseApi: string = 'objs';
    public static NbCredentialsMax: number = 5;
    public static Objs = 'object';
    public static Dashboard = 'dashboard';
    public static Buckets = 'buckets';
    public static Credentials = 'credentials';
    public static DataLifeTime = 7;
    public static HeaderPlatform = 'X-AurorA-ObjsPlatformId';
    public static DashboardRoute = () =>
        [
            ObjsConstants.Objs,
            ObjsConstants.Dashboard
        ];
    public static CredentialsRoute = () =>
        [
            ObjsConstants.Objs,
            ObjsConstants.Credentials
        ];

    public static CredentialsRoutePath = `/${ObjsConstants.CredentialsRoute().join('/')}`;
    public static BucketsRoute = () =>
        [
            ObjsConstants.Objs,
            ObjsConstants.Buckets
        ];
    public static BucketsRoutePath = `/${ObjsConstants.BucketsRoute().join('/')}`;

    public static ObjectDetailsRoute = (objectId: string) =>
        [
            ...ObjsConstants.BucketsRoute(),
            { outlets: { [TypePopin.DETAILS]: [ objectId ] } }
        ];

    public static getPublicIcon = (isPublic: boolean): {
        publicIconClass: string,
        publicColorClass: string,
        publicLabelKey: string
    } =>
    {
        return isPublic
            ? {
                publicIconClass: 'fa-user-unlock',
                publicColorClass: 'danger' ,
                publicLabelKey: 'public'
            }
            : { publicIconClass:  'fa-user-lock',
                publicColorClass:  'success',
                publicLabelKey: 'private'
            };
    };

    public static endingWithSlash = (objectName: string): boolean =>
    {
        if (UtilsService.isNullOrEmpty(objectName))
        {
            return false;
        }

        return objectName.split('').reverse()[0] === '/';
    };

    public static removeEndingSlash = (objectName: string): string =>
    {
        if (UtilsService.isNullOrEmpty(objectName))
        {
            return objectName;
        }

        return ObjsConstants.endingWithSlash(objectName) ?
            objectName.substring(0, objectName.length - 1) :
            objectName;
    };

    public static extractObjectName = (key: string): string =>
    {
        if (key.endsWith('/'))
        {
            return `${key.split('/').reverse()[1]}/`;
        }

        if (key.split('/').length > 0)
        {
            return key.split('/').reverse()[0];
        }

        return key;
    };

    public static isCurrentFolder = (object: S3Object, path: string) =>
    {
        return object.isFolder || ! (`${ObjsConstants.extractObjectName(path)}/` === object.name);
    };

    public static getRelativePath = (path: string): string =>
    {
        const splitted = path.split('/');

        if ( splitted.length === 1)
        {
            return '';
        }

        return path.substr( splitted[0].length + 1 );
    };

    public static compareS3Object = ((xa: S3Object, xb: S3Object): number =>
    {
        if (xa.isFolder && xb.isFolder)
        {
            return CompareLowerString2<S3Object>((folder: S3Object) => folder.name)(xa, xb);
        }

        if (!xa.isFolder && !xb.isFolder)
        {
            return CompareLowerString2<S3Object>((file: S3Object) => file.name)(xa, xb);
        }

        return xa.isFolder ? -1 : 1;
    });
}
