import { BckpConstants } from '@apps/bckp/bckp.constants';
import { Assetify } from '@common/domain/assetify';

import { ChargedCapacityApi } from '../../api/models/charged-capacity-api';

export class CapacityGbytesPerServer extends Assetify
{
    public tagIds: string[];
    public tagFullNames: string;
    public serverShort: string;
    public gbytes: number[];
    public policyFlags: number;
    public assetName = (): string => this.serverShort;

    constructor (serverShort: string, gbytes: number[])
    {
        super();

        this.serverShort = serverShort;
        this.gbytes = gbytes;
    }
}

export class FlatCapacityPerServer
{
    public serverShort: string;
    public policy: string;
    public gbytes: number;

    constructor (serverShort: string, policy: string, gbytes: number)
    {
        this.serverShort = serverShort;
        this.policy = policy;
        this.gbytes = gbytes;
    }
}

export class CapacityPerPolicy
{
    public type: string;
    public gbytes: number;

    constructor (type: string, gbytes: number = 0)
    {
        this.type = type;
        this.gbytes = gbytes;
    }
}

export class ChargedCapacity
{
    public serverShort: string;
    public gbytesTotal: number;
    public perPolicy: CapacityPerPolicy[];

    public static mapperFromApi (source: ChargedCapacityApi): ChargedCapacity
    {
        const target = new ChargedCapacity();

        Object.assign(target, source);

        return target;
    }

    public static flattenToPerServer (capacities: ChargedCapacity): FlatCapacityPerServer[]
    {
        const arr: FlatCapacityPerServer[] = capacities.perPolicy.map((item: CapacityPerPolicy) =>
            new FlatCapacityPerServer(capacities.serverShort, item.type, item.gbytes)
        );
        arr.push(
            new FlatCapacityPerServer(capacities.serverShort, BckpConstants.TotalColumnName, capacities.gbytesTotal)
        );

        return arr;
    }
}
