import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { of } from 'rxjs';

import { SelectOption } from '@ui/components/form/common';
import { IAuroraState } from '@apps/aurora.state';

import { OptionListDomainService } from '../../../domain/option-list-domain.service';
import * as fromBckpOptionsActions from './options.actions';
import {
    getBckpOptionsMastersIsDataValid,
    getBckpOptionsServersIsDataValid,
    getBckpOptionsPoliciesIsDataValid,
    getBckpOptionsPolicyTypesIsDataValid,
    getBckpOptionsServerShortsIsDataValid,
    getBckpOptionsCopiesServersIsDataValid,
    getBckpOptionsCopiesPoliciesIsDataValid,
    getBckpOptionsCopiesResidencesIsDataValid,
    getBckpOptionsCopiesSLPIsDataValid
} from '../../bckp-options.reducer';

@Injectable()
export class BckpOptionsEffects
{
    constructor (
        private actions: Actions,
        private optionListDomainService: OptionListDomainService,
        private store: Store<IAuroraState>
    )
    { }

    bckpLoadMastersRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromBckpOptionsActions.BckpLoadMastersRequested),
                    withLatestFrom(this.store.select(getBckpOptionsMastersIsDataValid)),
                    filter(([, valid]) => !valid),
                    switchMap(() =>
                        this.optionListDomainService.getJobsMasters()
                            .pipe(
                                map((masters: SelectOption[]) =>
                                    fromBckpOptionsActions.BckpLoadMastersSucceeded({ masters })
                                ),
                                catchError(() =>
                                    of(fromBckpOptionsActions.BckpLoadMastersFailed())
                                )
                            )
                    )
                )
    );

    bckpLoadServersRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromBckpOptionsActions.BckpLoadServersRequested),
                    withLatestFrom(this.store.select(getBckpOptionsServersIsDataValid)),
                    filter(([, valid]) => !valid),
                    switchMap(() => this.optionListDomainService.getJobsServers()
                        .pipe(
                            map((servers: SelectOption[]) =>
                                fromBckpOptionsActions.BckpLoadServersSucceeded({ servers })
                            ),
                            catchError(() =>
                                of(fromBckpOptionsActions.BckpLoadServerShortsFailed())
                            )
                        )
                    )
                )
    );

    bckpLoadServerShortsRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromBckpOptionsActions.BckpLoadServerShortsRequested),
                    withLatestFrom(this.store.select(getBckpOptionsServerShortsIsDataValid)),
                    filter(([, valid]) => !valid),
                    switchMap(() => this.optionListDomainService.getJobsServerShorts()
                        .pipe(
                            map((serverShorts: SelectOption[]) =>
                                fromBckpOptionsActions.BckpLoadServerShortsSucceeded({ serverShorts })
                            ),
                            catchError(() =>
                                of(fromBckpOptionsActions.BckpLoadServerShortsFailed())
                            )
                        )
                    )
                )
    );

    bckpLoadPoliciesRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromBckpOptionsActions.BckpLoadPoliciesRequested),
                    withLatestFrom(this.store.select(getBckpOptionsPoliciesIsDataValid)),
                    filter(([, valid]) => !valid),
                    switchMap(() => this.optionListDomainService.getJobsPolicies()
                        .pipe(
                            map((policies: SelectOption[]) =>
                                fromBckpOptionsActions.BckpLoadPoliciesSucceeded({ policies })
                            ),
                            catchError(() =>
                                of(fromBckpOptionsActions.BckpLoadPoliciesFailed())
                            )
                        )
                    )
                )
    );

    bckpLoadPolicyTypesRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromBckpOptionsActions.BckpLoadPolicyTypesRequested),
                    withLatestFrom(this.store.select(getBckpOptionsPolicyTypesIsDataValid)),
                    filter(([, valid]) => !valid),
                    switchMap(() => this.optionListDomainService.getJobsPolicyTypes()
                        .pipe(
                            map((policyTypes: SelectOption[]) =>
                                fromBckpOptionsActions.BckpLoadPolicyTypesSucceeded({ policyTypes })
                            ),
                            catchError(() =>
                                of(fromBckpOptionsActions.BckpLoadPolicyTypesFailed())
                            )
                        )
                    )
                )
    );

    bckpLoadCopiesServersRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromBckpOptionsActions.BckpLoadCopiesServersRequested),
                    withLatestFrom(this.store.select(getBckpOptionsCopiesServersIsDataValid)),
                    filter(([, valid]) => !valid),
                    switchMap(() =>
                        this.optionListDomainService.getCopiesServers()
                            .pipe(
                                map((copiesServers: SelectOption[]) =>
                                    fromBckpOptionsActions.BckpLoadCopiesServersSucceeded({ copiesServers })
                                ),
                                catchError(() =>
                                    of(fromBckpOptionsActions.BckpLoadCopiesServersFailed())
                                )
                            )
                    )
                )
    );

    bckpLoadCopiesPoliciesRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromBckpOptionsActions.BckpLoadCopiesPoliciesRequested),
                    withLatestFrom(this.store.select(getBckpOptionsCopiesPoliciesIsDataValid)),
                    filter(([, valid]) => !valid),
                    switchMap(() =>
                        this.optionListDomainService.getCopiesPolicyNames()
                            .pipe(
                                map((copiesPolicies: SelectOption[]) =>
                                    fromBckpOptionsActions.BckpLoadCopiesPoliciesSucceeded({ copiesPolicies })
                                ),
                                catchError(() =>
                                    of(fromBckpOptionsActions.BckpLoadCopiesPoliciesFailed())
                                )
                            )
                    )
                )
    );

    bckpLoadCopiesResidencesRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromBckpOptionsActions.BckpLoadCopiesResidencesRequested),
                    withLatestFrom(this.store.select(getBckpOptionsCopiesResidencesIsDataValid)),
                    filter(([, valid]) => !valid),
                    switchMap(() =>
                        this.optionListDomainService.getcopiesResidences()
                            .pipe(
                                map((copiesResidences: SelectOption[]) =>
                                    fromBckpOptionsActions.BckpLoadCopiesResidencesSucceeded({ copiesResidences })
                                ),
                                catchError(() =>
                                    of(fromBckpOptionsActions.BckpLoadCopiesResidencesFailed())
                                )
                            )
                    )
                )
    );

    bckpLoadCopiesSLPRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromBckpOptionsActions.BckpLoadCopiesSLPRequested),
                    withLatestFrom(this.store.select(getBckpOptionsCopiesSLPIsDataValid)),
                    filter(([, valid]) => !valid),
                    switchMap(() =>
                        this.optionListDomainService.getCopiesStorageLifecyclePolicy()
                            .pipe(
                                map((copiesSLP: SelectOption[]) =>
                                    fromBckpOptionsActions.BckpLoadCopiesSLPSucceeded({ copiesSLP })
                                ),
                                catchError(() =>
                                    of(fromBckpOptionsActions.BckpLoadCopiesSLPFailed())
                                )
                            )
                    )
                )
    );
}
