import { Component, forwardRef, Injector, OnInit } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilsService } from '@common/services';

import { OptionValue9 } from '@ui/components/form9/common';
import { RbCbBase } from '@ui/components/form9/common/rb-cb-base';

import { inputCheckbox9RequiredValidator } from './input-checkbox9.validators';

@Component({
    selector: 'aa-input-checkbox9',
    templateUrl: './input-checkbox9.component.html',
    styleUrls: [
        '../input9.component.scss',
        './input-checkbox9.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof InputCheckbox9Component => InputCheckbox9Component),
            multi: true
        }
    ]
})
export class InputCheckbox9Component extends RbCbBase<OptionValue9[]> implements OnInit
{
    private static identifier: number = 0;
    private stateValue: OptionValue9[] = [];

    constructor (
        injector: Injector,
        controlContainer: ControlContainer
    )
    {
        super(injector, controlContainer);

        this.fieldId = `idCheckboxGroup9-${InputCheckbox9Component.identifier++}`;
    }

    ngOnInit (): void
    {
        this.initRbCb();
        this.initControl([inputCheckbox9RequiredValidator((this.customMsg))]);
        if (UtilsService.isNonEmptyArray(this.initialValue))
        {
            this.initialValue.forEach((optionValue: OptionValue9): void =>
                this.addOrRemove(optionValue)
            );
        }
    }

    public addOrRemove (optionValue: OptionValue9): void
    {
        if (this.contains(optionValue))
        {
            this.remove(optionValue);
        }
        else
        {
            this.add(optionValue);
        }

        this.ctrlValue = [...this.stateValue];
        this.control.markAsDirty();
    }

    public contains (optionValue: OptionValue9): boolean
    {
        return this.stateValue.indexOf(optionValue) > -1;
    }

    private add (optionValue: OptionValue9): void
    {
        this.stateValue.push(optionValue);
    }

    private remove (optionValue: OptionValue9): void
    {
        const index = this.stateValue.indexOf(optionValue);

        if (index < 0)
        {
            return;
        }

        this.stateValue.splice(index, 1);
    }
}
