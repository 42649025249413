import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as fromRootImpeActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { QuickSearchValue } from '@apps/root/models';

import * as fromRootQuickSearchActions from './quick-search.actions';
import { Md5Service } from '@common/services';

export const RootQuickSearchFeatureKey = 'quicksearch';
type IQuickSearchesState = EntityState<QuickSearchValue>;

export const quickSearchesAdapter: EntityAdapter<QuickSearchValue> = createEntityAdapter({
    selectId: (qsValue: QuickSearchValue) => qsValue.key
});
const quickSearchesInitialState: IQuickSearchesState = quickSearchesAdapter.getInitialState({});

export interface IRootQuickSearchState
{
    quickSearches: IQuickSearchesState;
}

const initialState: IRootQuickSearchState = {

    quickSearches: quickSearchesInitialState
};

export const RootQuickSearchReducer = createReducer(
    initialState,
    on(
        fromRootImpeActions.RootImpersonateSet,
        fromRootImpeActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        fromRootQuickSearchActions.RootQuickSearchResetRequested,
        () => initialState
    ),
    on(
        fromRootQuickSearchActions.RootQuickSearchValueChanged,
        (state, { key, searchValue }) => ({
            ...state,
            quickSearches: searchValue !== ''
                ? quickSearchesAdapter.upsertOne(new QuickSearchValue(key, searchValue), state.quickSearches)
                : quickSearchesAdapter.removeOne(Md5Service.HexMd5(key.toUpperCase()), state.quickSearches)
        })
    )
    // on(
    //     fromRootQuickSearchActions.RootQuickSearchResetRequested,
    //     (state, { key, searchValue }) => ({
    //         ...state,
    //         quickSearches: quickSearchesAdapter.upsertOne(
    //             new QuickSearchValue(key, searchValue),
    //             state.quickSearches
    //         )
    //     })
    // )
);
