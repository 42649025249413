import { createAction, props } from '@ngrx/store';

import { SecurityGroup, SecurityRule } from '@apps/ostk/domain/models';
import { CreateSecurityRuleViewModel } from '@apps/ostk/domain/models/security/security-rule';
import { CreateSecurityGroupViewModel } from '@apps/ostk/domain/models/security/security-group';

export const OstkSecuritySgrpListRequested = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp List Requested'
);
export const OstkSecuritySgrpListSucceeded = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp List Loaded',
    props<{ securityGroups: SecurityGroup[] }>()
);
export const OstkSecuritySgrpListFailed = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp List Failed',
    props<{ failure?: any}>()
);
export const OstkSecuritySgrpQsWordSet = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp QsWord Set',
    props<{ qsWord: string }>()
);
export const OstkSecuritySgrpDetailsRequested = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Details Requested',
    props<{ securityGroupId: string }>()
);
export const OstkSecuritySgrpDetailsSucceeded = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Details Succeeded',
    props<{ securityGroup: SecurityGroup }>()
);
export const OstkSecuritySgrpRuleSelected = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Rule Selected',
    props<{ securityRule: SecurityRule }>()
);
export const OstkSecuritySgrpRuleDeleteRequested = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Rule Delete Requested',
    props<{ securityRule: SecurityRule }>()
);
export const OstkSecuritySgrpRuleDeleteSucceeded = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Rule Delete Succeeded',
    props<{ securityRuleId: string }>()
);
export const OstkSecuritySgrpRuleDeleteFailed = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Rule Delete Failed',
    props<{ failure?: any}>()
);
export const OstkSecuritySgrpRuleCreateRequested = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Rule Create Requested',
    props<{ securityRule: CreateSecurityRuleViewModel }>()
);
export const OstkSecuritySgrpRuleCreateSucceeded = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Rule Create Succeeded',
    props<{ securityRule: SecurityRule }>()
);
export const OstkSecuritySgrpRuleCreateFailed = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Rule Create Failed',
    props<{ failure?: any }>()
);
export const OstkSecuritySgrpCreateRequested = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Create Requested',
    props<{ securityGroup: CreateSecurityGroupViewModel }>()
);
export const OstkSecuritySgrpCreateSucceeded = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Create Succeeded',
    props<{ securityGroup: SecurityGroup }>()
);
export const OstkSecuritySgrpCreateFailed = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Create Failed',
    props<{ failure?: any}>()
);
export const OstkSecuritySgrpDeleteRequested = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Delete Requested',
    props<{ securityGroup: SecurityGroup }>()
);
export const OstkSecuritySgrpDeleteSucceeded = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Delete Succeeded',
    props<{ securityGroupId: string }>()
);
export const OstkSecuritySgrpDeleteFailed = createAction(
    '[OSTK-SGRP] Ostk Security Sgrp Delete Failed',
    props<{ failure?: any}>()
);
