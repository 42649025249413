import { SimpleEntityApi } from '../../../api/models/back/simple-entity-api';

export class SimpleEntity
{
    public name: string;
    public entityShortId: string;

    public static mapperFromApi (mapperFromApi: SimpleEntityApi): SimpleEntity
    {
        return Object.assign(new SimpleEntity(), mapperFromApi);
    }
}