import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';

import { AppConstants } from '../../app.constants';

@Injectable({
    providedIn: 'root'
})
export class FieldOptionMiscApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlIts2;
        this.segmentApi = '';
    }

    getFieldOptions (endPoint: string): Observable<string[]>
    {
        return this.get(endPoint)
            .pipe(
                map((response: any) =>
                    response.value ? response.value : response
                )
            );
    }
}
