import { AppConstants } from '@apps/app.constants';
import { RequestActionApi, RequestActionDetailsApi } from '@apps/its2/api/models/request-action-api';
import { ValidatedActionTypeIdEnum } from '@apps/its2/its2.constants';

export abstract class RequestAction
{
    actionId: number;
    actionTypeId: number | null;
    creationDateUtc: string | null;
    startDateUtc: string | null;
    endDateUtc: string | null;
    maxResolutionDateUtc: string | null;
    labelFR: string;
    labelEN: string;
    contactId: number | null;
    doneById: number | null;
    contactName: string;
    contactEmail: string;
    doneByName: string;
    doneByEmail: string;
    eMyAntemetAGuid: string;
    initial: string;
    commentType: {
        'en-US': string;
        'fr-FR': string;
    };
    validated: boolean;
    isExpanded = false;
    isLoading = false;
    identiconSrc: string;
}

export class RequestActionDetails extends RequestAction
{
    comment: string;
    description: string;
    displayText: string;
}

export class DomainRequestActionDetails extends RequestActionDetails
{
    public static mapperFromDetailsApi (source: RequestActionDetailsApi, label: string): RequestActionDetails
    {
        const target = new RequestActionDetails();

        Object.assign(target, source);

        DomainRequestActionDetails.setDisplayText(target, label);

        // if (source.comment)
        // {
        //     target.displayText = source.comment;
        // }
        // else
        // {
        //     target.displayText = source.description ? source.description : label ?? '';
        // }

        return target;
    }

    public static setDisplayText (actionDetails: RequestActionDetails, label: string)
    {
        if (actionDetails.comment)
        {
            actionDetails.displayText = actionDetails.comment;
        }
        else
        {
            actionDetails.displayText = actionDetails.description ? actionDetails.description : label ?? '';
        }
    }

    public static mapperFromApi (source: RequestActionApi): RequestActionDetails
    {
        const target = new RequestActionDetails();

        Object.assign(target, source);

        target.doneByEmail = target.doneByEmail ?? 'helpdesk@antemeta.fr';
        target.initial = encodeURI(target.doneByEmail);
        target.identiconSrc = `${AppConstants.Configuration.apiUrlAuth}/auth/identicons/${target.initial}`;
        target.commentType = {
            'en-US': source.labelEN,
            'fr-FR': source.labelFR
        };
        target.validated = Object.values(ValidatedActionTypeIdEnum).includes(source.actionTypeId);

        return target;
    }
}
