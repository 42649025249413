import { CreateInstanceViewModel } from '@apps/ostk/models/instance/create-instance-view-model';

import { InstCreationSource } from '../../instances.constants';

export class Step1
{
    instanceName: string;
    source: InstCreationSource;
    flavorId: string;
    flavorName: string;
    createVolume: boolean;
    imageSource: string;
    volumeSource: string;
    snapshotSource: string;
    voluCapacity?: number;
    os: string;
    isKeyPairRequired: boolean;

    constructor (step1?: Step1)
    {
        if (step1)
        {
            Object.assign(this, step1);
        }
    }
}

export class Step2
{
    networkIds: string[];
    networkNames: string[];
    securityGroupIds: string[];
    securityGroupNames: string[];
    keyPairName: string;

    constructor (step2?: Step2)
    {
        if (step2)
        {
            Object.assign(this, step2);
        }
    }
}

export class CreateInstance
{
    step1: Step1;
    step2: Step2;

    constructor (createInstance: CreateInstance)
    {
        if (createInstance != null)
        {
            Object.assign(this, createInstance);
        }
        else
        {
            this.step1 = null;
            this.step2 = null;
        }
    }

    public static toInstanceCreateViewModel (createInstance: CreateInstance): CreateInstanceViewModel
    {
        const target = new CreateInstanceViewModel();

        target.name = createInstance.step1.instanceName;
        target.diskSize = createInstance.step1.voluCapacity;
        target.flavorId = createInstance.step1.flavorId;
        target.keyPairName = createInstance.step2.keyPairName;
        target.securityRefs = createInstance.step2.securityGroupIds;
        target.networkIds = createInstance.step2.networkIds;
        target.osIcon = createInstance.step1.os;


        switch (createInstance.step1.source)
        {
            case InstCreationSource.VOLUME:
                target.volumeId = createInstance.step1.volumeSource;
                break;
            case InstCreationSource.IMAGE:
                target.imageId = createInstance.step1.imageSource;
                break;
            case InstCreationSource.SNAPSHOT:
                target.imageId = createInstance.step1.snapshotSource;
                break;
            default:
                break;
        }

        return target;
    }
}
