import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { DateTime } from 'luxon';

import * as fromOstkQuotasActions from './quotas.actions';
import * as fromOstkProjectsActions from '../projects/projects.actions';
import * as fromAuthLoginActions from 'src/app/_apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from 'src/app/_apps/auth/store/profile/profile.actions';
import { OstkConstants } from '../../ostk.constants';
import { Quota } from '../../domain/models';

export const OstkQuotasFeatureKey = 'quotas';

interface IQuotasState extends EntityState<Quota> { }
export const quotasAdapter: EntityAdapter<Quota> = createEntityAdapter({
    selectId: (quota: Quota) => quota.key
});
const quotasInitialState: IQuotasState = quotasAdapter.getInitialState({});

export interface IOstkQuotasState
{
    quotas: IQuotasState;
    isEmpty: boolean;
    isLoading: boolean;
    isOups: boolean;
    isLoaded: boolean;
    expirationDate?: DateTime;
}

const initialState: IOstkQuotasState = {
    quotas: quotasInitialState,
    isEmpty: false,
    isLoading: false,
    isOups: false,
    isLoaded: false,
    expirationDate: null
};

export const OstkQuotaReducer = createReducer(
    initialState,
    on(
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthProfileActions.AuthProfileLanguageSet,
        fromAuthLoginActions.AuthLogout,
        fromOstkProjectsActions.OstkProjectSelected,
        () => initialState
    ),
    on(
        fromOstkQuotasActions.OstkQuotasListRequested,
        (state) =>
        ({
            ...state,
            isLoading: !(state.expirationDate !== null && DateTime.utc() < state.expirationDate)
        })
    ),
    on(
        fromOstkQuotasActions.OstkQuotasListSucceeded,
        (state: IOstkQuotasState, { quotas }) =>
        ({
            ...state,
            isLoading: false,
            isEmpty: quotas && quotas.length === 0,
            isOups: false,
            isLoaded: true,
            quotas: quotasAdapter.setAll(quotas, state.quotas),
            expirationDate: DateTime.utc().plus({ minutes: OstkConstants.DataLifeTime })
        })
    ),
    on(
        fromOstkQuotasActions.OstkQuotasListFailed,
        (state) =>
        ({
            ...state,
            isLoading: false,
            isEmpty: false,
            isOups: true,
            isLoaded: false,
            quotas: quotasAdapter.removeAll(state.quotas),
            expirationDate: null
        })
    ),
    on(
        fromOstkQuotasActions.OstkQuotasListReset,
        () => initialState
    )
);
