import { BaseFields4Filter } from 'src/app/_common/filters/base-fields-4filter';
import { BooleanField4Filter, StringArrayField4Filter } from 'src/app/_common/filters/field-4filter';
import { Image } from 'src/app/_apps/ostk/domain/models/image/image';

export class ImageFields4Filter extends BaseFields4Filter<Image>
{
    private _isPublic: boolean;
    get isPublic ()
    {
        return this._isPublic;
    }
    set isPublic (value: boolean)
    {
        const f4f = new BooleanField4Filter<Image>('isPublic', value);

        this.addField(f4f);
        this._isPublic = value;
    }

    private _osLabel: string[];
    get osLabel ()
    {
        return this._osLabel;
    }
    set osLabel (value: string[])
    {
        if (value)
        {
            const osFilter = new StringArrayField4Filter<Image>('osLabel', value);

            this.addField(osFilter);
            this._osLabel = value;
        }
    }

    private _status: string[];
    get status ()
    {
        return this._status;
    }
    set status (value: string[])
    {
        if (value)
        {
            const osFilter = new StringArrayField4Filter<Image>('status', value);

            this.addField(osFilter);
            this._status = value;
        }
    }

    constructor(filtre?: ImageFields4Filter)
    {
        super();

        this.isPublic = true;

        if (filtre)
        {
            this.isPublic = filtre.isPublic;
            this.osLabel = filtre.osLabel;
            this.status = filtre.status;
        }
    }
}
