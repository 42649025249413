import { Component, Input, Host, OnInit } from '@angular/core';

import { SelectOption } from '../../../common';
import { RadioGroup3Component } from '../radio-group3/radio-group3.component';

@Component({
    selector: 'aa-radio3',
    templateUrl: './radio3.component.html'
})
export class Radio3Component implements OnInit
{
    @Input() name: string;
    @Input() option: SelectOption;
    @Input() toTranslate: boolean = false;
    public optionId: string;

    constructor (@Host() private radioGroup: RadioGroup3Component)
    { }

    ngOnInit ()
    {
        this.optionId = `id-${this.option.label}-${this.option.value}`;
    }

    toggle ()
    {
        this.radioGroup.toggle(this.option.value);
    }

    isChecked ()
    {
        return this.radioGroup.value === this.option.value;
    }
}
