import { DateTime } from 'luxon';

import { Status } from './status';
import { JobsStatus } from '@apps/bckp/bckp.constants';
import { DatesService } from '@common/services';
import { VbrRates } from './vbr-rates';
import { VboRates } from './vbo-rates';

export class StatusPerDay
{
    day: DateTime;
    jobCount: number;
    groupByCodeStatuses: Status[];

    public static mapperFromVbrRates (vbrRates: VbrRates): StatusPerDay
    {
        const statusPerDay = {
            day: DatesService.UtcStringToDateTimeHourStatic(vbrRates.timeSince),
            jobCount: vbrRates.numVms,
            groupByCodeStatuses: [
                new Status(JobsStatus.Success, vbrRates.countedSuccess),
                new Status(JobsStatus.Partial, 0),
                new Status(JobsStatus.Failed, vbrRates.countedFailed)
            ]
        } as StatusPerDay;

        return statusPerDay;
    }

    public static mapperFromVboRates (vboRates: VboRates): StatusPerDay
    {
        const statusPerDay = {
            day: DatesService.UtcStringToDateTimeHourStatic(vboRates.timeSince),
            jobCount: vboRates.numVms,
            groupByCodeStatuses: [
                new Status(JobsStatus.Success, vboRates.countedSuccess),
                new Status(JobsStatus.Partial, 0),
                new Status(JobsStatus.Failed, vboRates.countedFailed)
            ]
        } as StatusPerDay;

        return statusPerDay;
    }
}
