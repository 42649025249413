import { Injectable } from '@angular/core';

import { Observable, of, Subject, timer } from 'rxjs';
import { map, switchMap, catchError, concatMap, takeUntil, tap } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { NotfGravity, NotificationService, NotifType } from '@common/services';
import { CmdbConstants } from '@apps/cmdb/cmdb.constants';
import { AuthLogout } from '@apps/auth/store/login/login.actions';

import * as fromTagsActions from './tags.actions';
import { TagMiscDomainService } from '../../domain';
import { Tag, DetailedTag, EnrichedTag, UpsertTagViewModel } from '../../models';

@Injectable()
export class TagsEffects
{
    private destroyator = new Subject();

    constructor (
        private actions: Actions,
        private tagMiscDomainService: TagMiscDomainService,
        private notificationService: NotificationService
    )
    {
        this.actions
            .pipe(
                ofType(AuthLogout, fromTagsActions.CmdbTagsListStopRequested),
                tap(() =>
                {
                    this.destroyator.next(null);
                })
            )
            .subscribe();
    }

    // cmdbTagsListRequested: Observable<Action> = createEffect(() =>
    //     this.actions.pipe(
    //         ofType(fromTagsActions.CmdbTagsListRequested),
    //         withLatestFrom(
    //             this.store.select(getTagsIsDataValid)
    //         ),
    //         filter(([, valid]) =>
    //             !valid
    //         ),
    //         switchMap(() =>
    //             this.tagMiscDomainService.getAll()
    //                 .pipe(
    //                     map((tags: EnrichedTag[]) =>
    //                         fromTagsActions.CmdbTagsListSucceeded({ tags })
    //                     ),
    //                     catchError(() =>
    //                     {
    //                         return of(fromTagsActions.CmdbTagsListFailed());
    //                     })
    //                 )
    //         )
    //     )
    // );
    cmdbTagsListRequested: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(fromTagsActions.CmdbTagsListRequested),
            switchMap(() =>
                timer(0, CmdbConstants.PollingInterval)
                    .pipe(
                        takeUntil(this.destroyator),
                        concatMap(() =>
                            this.tagMiscDomainService.getAll()
                                .pipe(
                                    map((tags: EnrichedTag[]) =>
                                        fromTagsActions.CmdbTagsListSucceeded({ tags })
                                    ),
                                    catchError(() =>
                                    {
                                        return of(fromTagsActions.CmdbTagsListFailed());
                                    })
                                )
                        )
                    )
            )
        )
    );

    cmdbTagDetailedRequested: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(fromTagsActions.CmdbTagDetailedRequested),
            switchMap(({ tagId }) =>
                this.tagMiscDomainService.getOne(tagId)
                    .pipe(
                        map((detailedTag: DetailedTag) =>
                            fromTagsActions.CmdbTagDetailedSucceeded({ detailedTag })
                        ),
                        catchError(() =>
                            of(fromTagsActions.CmdbTagDetailedFailed())
                        )
                    )
            )
        )
    );

    cmdbTagCreateRequested: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(fromTagsActions.CmdbTagCreateRequested),
            switchMap(({ tag }: { tag: UpsertTagViewModel }) =>
                this.tagMiscDomainService.createTag(tag).pipe(
                    map((newTag: EnrichedTag) =>
                    {
                        this.notificationService.notify(
                            [
                                '',
                                'CMDB.TAGS.CREATE.NOTF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );

                        return fromTagsActions.CmdbTagCreateSucceeded({ tag: newTag });
                    })
                )
            )
        )
    );

    cmdbTagDeleteRequested = createEffect(() => this.actions
        .pipe(
            ofType(fromTagsActions.CmdbTagDeleteRequested),
            switchMap(({ tagId }) =>
                this.tagMiscDomainService.deleteTag(tagId)
                    .pipe(
                        map(() =>
                        {
                            this.notificationService.notify(
                                [
                                    '',
                                    'CMDB.TAGS.DELETE.NOTF.MSGSUCCESS'
                                ],
                                NotfGravity.success,
                                NotifType.SNACKBAR
                            );

                            return fromTagsActions.CmdbTagDeleteSucceeded({ tagId });
                        })
                    )
            )
        )
    );

    cmdbTagUpdateRequested = createEffect(() => this.actions
        .pipe(
            ofType(fromTagsActions.CmdbTagUpdateRequested),
            switchMap(({ tagId, tag }: { tagId: string, tag: UpsertTagViewModel }) =>
                this.tagMiscDomainService.updateTag(tagId, tag)
                    .pipe(
                        map((result: Tag) =>
                        {
                            const updatedTag: Update<Tag> = {
                                id: result.tagId,
                                changes: { ...result }
                            };
                            this.notificationService.notify(
                                [
                                    '',
                                    'CMDB.TAGS.UPDATE.NOTF.MSGSUCCESS'
                                ],
                                NotfGravity.success,
                                NotifType.SNACKBAR
                            );

                            return fromTagsActions.CmdbTagUpdateSucceeded({ tag: updatedTag });
                        })
                    )
            )
        )
    );
}
