import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { RequestStatusesApiService } from '@apps/its2/api';
import { RequestStatuses } from '@apps/its2/domain/models';
import { RequestStatusesApi } from '@apps/its2/api/models';

@Injectable({
    providedIn: 'root'
})
export class RequestStatusesDomainService extends BaseMiscDomainService
{
    constructor (
        private requestStatusesApiService: RequestStatusesApiService
    )
    {
        super();
    }

    retrieveStatuses (): Observable<RequestStatuses>
    {
        return this.requestStatusesApiService.retrieveStatuses()
            .pipe(
                map((statuses: RequestStatusesApi) =>
                    statuses as RequestStatuses
                )
            );
    }

    addToFavorites (requests: string[]): Observable<string[]>
    {
        return this.requestStatusesApiService.addToFavorites(requests)
            .pipe(
                map(() => requests)
            );
    }

    removeFromFavorites (requests: string[]): Observable<string[]>
    {
        return this.requestStatusesApiService.removeFromFavorites(requests)
            .pipe(
                map(() => requests)
            );
    }

    addToRead (requests: string[]): Observable<string[]>
    {
        return this.requestStatusesApiService.addToRead(requests)
            .pipe(
                map(() => requests)
            );
    }

    removeFromRead (requests: string[]): Observable<string[]>
    {
        return this.requestStatusesApiService.removeFromRead(requests)
            .pipe(
                map(() => requests)
            );
    }
}
