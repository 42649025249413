import { DateTime } from 'luxon';

import { RangeKey } from '@common/enum';
import { DatesService } from '@common/services';

export const Its2DashSharedFeatureKey = 'shared';

export interface IIts2DashSharedState {
    since: DateTime;
    until: DateTime;
}

const {
    since,
    until
} = DatesService.preRangeDatesStatic(RangeKey.CURRENT7DAYS);

export const Its2DashSharedInitialState: IIts2DashSharedState =
{
    since,
    until
};