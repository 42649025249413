<form id="auth-form" [formGroup]="form" (keyup.enter)="onSubmit()">
    <div class="auth-title">{{ 'AUTH.PROFILE.ONBOARDING.STEP1.TITLE' | translate }}</div>
    <div class="auth-sub-title">
        {{ 'AUTH.PROFILE.ONBOARDING.STEP1.SUBTITLE' | translate }}
    </div>
    <div class="names">
        <aa-input-text
            [required]="'required'"
            name="firstName"
            label="{{ 'AUTH.PROFILE.INFORMATIONS.FIRSTNAME' | translate }}"
            formControlName="firstName"
            [tabindex]="0"
        >
        </aa-input-text>
        <aa-input-text
            [required]="'required'"
            name="lastName"
            label="{{ 'AUTH.PROFILE.INFORMATIONS.LASTNAME' | translate }}"
            formControlName="lastName"
            [tabindex]="1"
        >
        </aa-input-text>
    </div>
    <div class="names">
        <aa-input-text
            name="title"
            label="{{ 'AUTH.PROFILE.INFORMATIONS.TITLE' | translate }}"
            formControlName="title"
            [tabindex]="3"
        >
        </aa-input-text>
    </div>
    <div class="buttons">
        <aa-btn-action-secondary
            [btnText]="'BUTTON.CANCEL' | translate"
            [cta]="onCancel"
            [btnWidth]="'100%'"
        >
        </aa-btn-action-secondary>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <aa-btn-action-secondary
            [btnText]="'BUTTON.CONTINUE' | translate"
            [cta]="onSubmit"
            [btnWidth]="'100%'"
        >
        </aa-btn-action-secondary>
    </div>
</form>
