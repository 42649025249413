import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthReducers, AuthFeatureKey } from './auth.reducer';
import { AuthLoginEffects } from './login/login.effects';
import { AuthProfileEffects } from './profile/profile.effects';
import { AuthAccountEffects } from './account/account.effects';
import { AuthUsersEffects } from './users/users.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(
            AuthFeatureKey,
            AuthReducers
        ),
        EffectsModule.forFeature([
            AuthLoginEffects,
            AuthProfileEffects,
            AuthAccountEffects,
            AuthUsersEffects
        ])
    ]
})
export class AuthStoreModule
{ }
