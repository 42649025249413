import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, pairwise, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { IAuroraState } from '@apps/aurora.state';
import * as fromRootStore from '@apps/root/store';

@Injectable()
export class AdminCustomerInterceptor implements HttpInterceptor
{
    constructor (
        private store: Store<IAuroraState>,
        private router: Router
    )
    { }

    intercept (req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
    {
        this.router.events
            .pipe(
                filter((evt) => evt instanceof NavigationEnd),
                pairwise(),
                take(1),
                filter((urls: any[]) =>
                    urls[0] && urls[0].url
                ),
                tap((urls: any[]) =>
                {
                    const prevUrl: string = urls[0].url;
                    const nextUrl: string = urls[1].url;

                    // from customer to admin
                    if (this.isCustomerUrl(prevUrl) && this.isAdminUrl(nextUrl))
                    {
                        this.store.dispatch(fromRootStore.RootMiscStoreActions.RootMiscLastCustomerUrlSet({ url:prevUrl }));
                    }

                    // from admin to customer
                    if (this.isAdminUrl(prevUrl) && this.isCustomerUrl(nextUrl))
                    {
                        this.store.dispatch(fromRootStore.RootMiscStoreActions.RootMiscLastAdminUrlSet({ url: prevUrl }));
                    }
                })
            )
            .subscribe();

        return next.handle(req);
    }

    private isAdminUrl (url: string): boolean
    {
        const isAdmin: boolean = url.includes('/admin');

        return isAdmin;
    }

    private isCustomerUrl (url: string): boolean
    {
        const isCustomer: boolean = !url.includes('/admin') && !url.includes('/auth');

        return isCustomer;
    }
}
