import { HttpEvent } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import { Policy } from '../../domain/models';
import { PolicyDetails } from '../../domain/models/policy';
import { PoliciesFilter } from '../../models/policies-filter';
import { MediaTypes } from 'src/app/_common/services';
import { Update } from '@ngrx/entity';
import { EnrichedAsset } from '@apps/cmdb/models';

export const BckpPoliciesODataInitialized = createAction(
    '[BCKP-POLICIES] Bckp Policies OData Initialized',
    props<{ filter: PoliciesFilter }>()
);
export const BckpPoliciesCleared = createAction(
    '[BCKP-POLICIES] Bckp Policies Cleared'
);
export const BckpPoliciesODataRequested = createAction(
    '[BCKP-POLICIES] Bckp Policies OData Requested'
);

export const BckpPoliciesODataLoaded = createAction(
    '[BCKP-POLICIES] Bckp Policies OData Loaded',
    props<{ policies: Policy[] }>()
);

export const BckpPoliciesODataFailed = createAction(
    '[BCKP-POLICIES] Bckp Policies OData Failed'
);

export const BckpPoliciesDetailsRequested = createAction(
    '[BCKP-POLICIES] Bckp Policies Details Requested',
    props<{ documentId: string }>()
);

export const BckpPoliciesDetailsLoaded = createAction(
    '[BCKP-POLICIES] Bckp Policies Details Loaded',
    props<{ policyDetails: PolicyDetails }>()
);

export const BckpPoliciesDetailsSelected = createAction(
    '[BCKP-POLICIES] Bckp Policies Details Selected',
    props<{ policyDetails: PolicyDetails }>()
);

export const BckpPoliciesDetailsFailed = createAction(
    '[BCKP-POLICIES] BckpPoliciesDetailsFailed'
);

export const BckpPoliciesFilterApplied = createAction(
    '[BCKP-POLICIES] Bckp Policies Filter Applied',
    props<{ filter: PoliciesFilter }>()
);

export const BckpPoliciesExportRequested = createAction(
    '[BCKP-POLICIES] Bckp Policies Export Requested',
    props<{ format: MediaTypes }>()
);

export const BckpPoliciesExportSucceeded = createAction(
    '[BCKP-POLICIES] Bckp Policies Export Succeeded',
    props<{ response: HttpEvent<Blob> }>()
);

export const BckpPoliciesQsWordSet = createAction(
    '[BCKP-POLICIES] Bckp Policies QsWord Set',
    props<{ qsWord: string }>()
);

export const BckpPoliciesTagAttachRequested = createAction(
    '[BCKP-POLICIES] Bckp Policies Tag Attach Requested',
    props<{
        id: string,
        name: string,
        identifier: string,
        tagId: string
    }>()
);
export const BckpPoliciesTagAttachExecuted = createAction(
    '[BCKP-POLICIES] Bckp Policies Tag Attach Executed',
    props<{ enrichedAsset: EnrichedAsset }>()
);
export const BckpPoliciesTagAttachSucceeded = createAction(
    '[BCKP-POLICIES] Bckp Policies Tag Attach Succeeded',
    props<{ updatedPolicy: Update<Policy> }>()
);
export const BckpPoliciesTagDetachRequested = createAction(
    '[BCKP-POLICIES] Bckp Policies Tag Detach Requested',
    props<{
        id: string,
        identifier: string,
        tagId: string
    }>()
);
export const BckpPoliciesTagDetachExecuted = createAction(
    '[BCKP-POLICIES] Bckp Policies Tag Detach Executed',
    props<{ enrichedAsset: EnrichedAsset }>()
);
export const BckpPoliciesTagDetachSucceeded = createAction(
    '[BCKP-POLICIES] Bckp Policies Tag Detach Succeeded',
    props<{ updatedPolicy: Update<Policy> }>()
);
