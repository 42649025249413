import { DateTime } from 'luxon';

import { Volume } from './volume';
import { SnapshotApi } from '../../../api/models';
import { OstkConstants } from '../../../ostk.constants';
import { DatesService, UtilsService } from 'src/app/_common/services';

export class Snapshot
{
    name: string;
    id: string;
    status: string;
    createdAt: DateTime;
    size: number;
    volume: Volume;
    volumeId: string;
    metadata: any;
    osLabel: string;
    osIconLocation: string;
    osLabelFirstUpperCase: string;
    statusClass: string;
    statusLabelKey: string;
    isKeyPairRequired: boolean;

    public static mapperFromApi (source: SnapshotApi): Snapshot
    {
        const target = new Snapshot();

        Object.assign(target, source);

        if (!source.createdAt.endsWith('z'))
        {
            source.createdAt += 'Z';
        }

        const osIcon = source.volume?.imageMetadata?.osIcon;

        target.osLabel = UtilsService.getOsLabel(osIcon, 'default');
        target.osIconLocation = UtilsService.getFullOsPicture(target.osLabel);
        target.osLabelFirstUpperCase = UtilsService.toFirstUpperCase(target.osLabel);
        target.isKeyPairRequired = UtilsService.isKeyPairRequired(target.osLabel);

        target.createdAt = DatesService.UtcStringToDateTimeHourStatic(source.createdAt);
        target.statusClass = OstkConstants.getVolumeStatusClass(source.status);
        target.statusLabelKey = OstkConstants.getVolumeStatusLabelKey(source.status);

        return target;
    }
}
