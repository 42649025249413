export enum QdKeys
{
    INST = 'inst',
    VCPU = 'vcpu',
    VRAM = 'vram',
    SGRP = 'sgrp',
    RULE = 'rule',
    KEYP = 'keyp',
    DISK = 'disk',
    VOLU = 'volu',
    SNAP = 'snap',
    FIPS = 'fips'
}

export enum QdGender
{
    FEMALE = 'FEMALE',
    MALE = 'MALE'
}

export enum QdQty
{
    SINGULAR = 'SINGULAR',
    PLURAL = 'PLURAL'
}

export class QuotasConstants
{
    public static KeypNeeded4Creation: number = 1;
    public static InstNeeded4Creation: number = 1;
    public static VoluNeeded4Creation: number = 1;
    public static RuleNeeded4Creation: number = 1;
    public static SgrpNeeded4Creation: number = 1;
    public static SnapNeeded4Creation: number = 1;
    public static FipsNeeded4Creation: number = 1;
}
