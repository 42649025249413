import { TypePopin } from '@apps/app.constants';
import { Instance } from '@apps/comp/domain/models';
import { CompConstants } from '@apps/comp/comp.constants';
import { OpenPopinOriginator } from '@common/enum/open-popin-originator.enum';
import { SearchResult } from './search-result';

export class CompInstanceSearchResult extends SearchResult<Instance>
{
    constructor (instance: Instance)
    {
        super('comp-instance', instance.documentId);

        this.result = instance;
        this.icon = 'fal fa-server';

        this.statusText = instance.statusLabel;
        this.statusClass = instance.statusClass;

        this.link = {
            baseUrlFragment: `/${CompConstants.BaseUrl}/${CompConstants.Instances}`,
            outlet: TypePopin.DETAILS,
            extraUrlFragment: OpenPopinOriginator.COMP_INSTANCES,
            params: [instance.documentId]
        };
    }
}
