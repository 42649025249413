import { DateTime } from 'luxon';

import { OstkConstants, VolumesBootable, VolumesStatus } from '../../../ostk.constants';
import { VolumeApi } from '../../../api/models';
import { AttachmentApi } from '../../../api/models/common';
import { DatesService, UtilsService } from 'src/app/_common/services';
import { Attachment, HasMajorProps } from '../common';
import { ImageMetadata } from './image-metadata';

export class Volume implements HasMajorProps
{
    attachments?: Attachment[] | null;
    availabilityZone: string;
    bootable: boolean;
    createdAt: DateTime;
    encrypted: boolean;
    id: string;
    imageMetadata: ImageMetadata;
    multiAttach: boolean;
    name: string;
    osVolTenantAttrTenantId: string;
    replicationStatus: string;
    size: number;
    status: string;
    userId: string;
    volumeType: string;
    isRootDevice: boolean;
    description?: string | null;
    snapshotId?: string;
    attachedOn: string;
    attachedTo: string;
    osLabel: string;
    osIconLocation: string;
    osLabelFirstUpperCase: string;
    statusClass: string;
    statusLabelKey: string;
    bootableClass: string;
    bootableLabel: string;
    bootableLabelKey: string;
    encryptedKey: string;
    isAvailable: boolean;
    isKeyPairRequired: boolean;

    public static mapperFromApi (source: VolumeApi): Volume
    {
        const target = new Volume();

        Object.assign(target, source);

        if (!source.createdAt.endsWith('z'))
        {
            source.createdAt += 'Z';
        }

        target.createdAt = DatesService.UtcStringToDateTimeHourStatic(source.createdAt);
        if (source.attachments && source.attachments.length > 0)
        {
            target.attachedTo = source.attachments
                .filter((att: AttachmentApi) => att.instance)
                .map((att: AttachmentApi) => att.instance.name)
                .join(',');
            target.attachedOn = source.attachments
                .map((att: AttachmentApi) => att.device)
                .join(',');
        }

        target.encryptedKey = OstkConstants.getVolumeEncryptedKey(source.encrypted);

        const osIcon = source.imageMetadata &&
            source.imageMetadata.osIcon ? source.imageMetadata.osIcon : 'default';
        target.osLabel = UtilsService.getOsLabel(osIcon);
        target.osIconLocation = UtilsService.getFullOsPicture(target.osLabel);
        target.osLabelFirstUpperCase = UtilsService.toFirstUpperCase(target.osLabel);
        target.isKeyPairRequired = UtilsService.isKeyPairRequired(target.osLabel);

        target.statusClass = OstkConstants.getVolumeStatusClass(source.status);
        target.statusLabelKey = OstkConstants.getVolumeStatusLabelKey(source.status);
        target.isAvailable = !(source.status.toLowerCase() === VolumesStatus.INUSE);
        target.bootableClass = OstkConstants.getVolumeBootableClass(source.bootable);
        target.bootableLabel = source.bootable ? VolumesBootable.BOOTABLE : VolumesBootable.NOTBOOTABLE;
        target.bootableLabelKey = OstkConstants.getVolumeBootableLabelKey(target.bootableLabel);

        if (!target.name)
        {
            target.name = target.id;
        }

        return target;
    }

    get isRenamable ()
    {
        return true;
    }

    get isDeletable (): boolean
    {
        return this.status === VolumesStatus.AVAILABLE;
    }

    get isAttachable (): boolean
    {
        return this.status === VolumesStatus.AVAILABLE;
    }

    get isDetachable (): boolean
    {
        return !this.bootable && this.status === VolumesStatus.INUSE;
    }

    get isExtendable (): boolean
    {
        return !this.bootable && this.status === VolumesStatus.AVAILABLE;
    }

    get canLaunchInstance (): boolean
    {
        return this.bootable && this.status === VolumesStatus.AVAILABLE;
    }
}
