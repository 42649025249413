import { DateTime } from 'luxon';

import { ImageApi } from '../../api/models';
import { DatesService } from '@common/services';
import { Assetify } from '@common/domain/assetify';

const scheduleTypeMatching: Map<string, string> = new Map<string, string>([
    ['FULL', 'FULL'],
    ['INCR', 'INCR'],
    ['USERBACKUP', 'FULL']
]);

export class Image extends Assetify
{
    public tagIds: string[];
    public tagFullNames: string;
    public server: string;
    public serverShort: string;
    public master: string;
    public backupString: string;
    public scheduleType: string;
    public internalScheduleType: string;
    public documentId: string;
    public backupId: string;
    public jobId: number;
    public policyName: string;
    public policyType: string;
    public scheduleName: string;
    public backupTime: DateTime;
    public expirationTime: DateTime;
    public deletedBefore: DateTime;
    public ageBackup: string;
    public futureExpiration: string;
    public expired: boolean;
    public deleted: boolean;
    public numFiles: number;
    public numFilesTrend?: number = null;
    public kbytes: number;
    public kbytesTrend?: number = null;
    public assetName = (): string => this.serverShort;

    public static mapperFromApi (
        source: ImageApi,
        datesService: DatesService,
        language: string = 'en-US'
    ): Image
    {
        const target = new Image();

        Object.assign(target, source);

        target.backupString = source.backupTime;
        if (source.backupTime)
        {
            target.backupTime = datesService.UtcStringToDateTimeHour(source.backupTime);
        }

        if (source.expiration)
        {
            target.expirationTime = datesService.UtcStringToDateTimeHour(source.expiration);
        }

        if (source.deletedBefore)
        {
            target.deletedBefore = datesService.UtcStringToDateTimeHour(source.deletedBefore);
        }

        target.deleted = target.deletedBefore < DateTime.utc();
        target.expired = target.expirationTime < DateTime.utc() || target.deleted;

        target.ageBackup = datesService.AgeDuration(target.backupTime, language);
        target.futureExpiration = datesService.AgeDuration(target.expirationTime, language);
        target.scheduleType = source.scheduleType.toUpperCase();
        target.internalScheduleType = scheduleTypeMatching.get(target.scheduleType);

        return target;
    }
}
