import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';

import FileSaver from 'file-saver';

import { Profile } from 'src/app/_apps/auth/models';

export enum MediaTypes
{
    Csv = 'text/csv',
    Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    JSON = 'application/json'
}

@Injectable({
    providedIn: 'root'
})
export class FilesService
{
    saveFileFromApi (response: HttpEvent<Blob>, profile: Profile, filename: string = '')
    {
        switch (response.type)
        {
            case HttpEventType.Sent:
                break;

            case HttpEventType.UploadProgress:
                break;

            case HttpEventType.ResponseHeader:
                break;

            case HttpEventType.DownloadProgress:
                break;

            case HttpEventType.Response:
                if (!filename)
                {
                    const contentDispositionElements: string[] = response.headers.get('content-disposition').split(';');
                    filename = contentDispositionElements[1].split('=')[1];

                    if (profile)
                    {
                    // Remplacer le cas échéant l'id de l'utilisateur par le nom de l'entité
                        const userId = profile.id;
                        const entityName = profile.entityName.replace(' ', '_');
                        filename = filename.replace(userId, entityName);
                    }
                }

                FileSaver.saveAs(
                    new Blob([response.body],
                        {
                            type: response.headers.get('content-type')
                        }),
                    filename);
                break;
        }
    }

    /**
     *
     * @param {{string}} fileName Nom du ficheira vec ous ans répertoire
     * @returns {{string}} Classe du fichier de font-awesome
     */
    public static getFileIconFromName (fileName: string): string
    {
        const pos = fileName.lastIndexOf('.') + 1;
        const extension = fileName.substring(pos);

        return FilesService.getFileIconFromExtension(extension);
    }

    /**
     * @param {{string}} extension du fichier
     * @returns {{string}} Classe du fichier de font-awesome
     */
    public static getFileIconFromExtension (extension: string): string
    {
        switch (extension.toLowerCase())
        {
            case 'xls':
            case 'xlsx':
                return 'fa-file-excel';

            case 'doc':
            case 'docx':
                return 'fa-file-word';

            case 'ppt':
            case 'pptx':
                return 'fa-file-powerpoint';

            case 'png':
            case 'bmp':
            case 'jpg':
            case 'jpeg':
                return 'fa-file-image';

            case 'zip':
            case 'gz':
                return 'fa-file-archive';

            case 'txt':
            case 'json':
                return 'fa-file-alt';

            case 'htm':
            case 'html':
                return 'fa-file-code';

            case 'pdf':
                return 'fa-file-pdf';

            default:
                return 'fa-file';
        }
    }
}
