import { Pipe, PipeTransform } from '@angular/core';

import { DateTime } from 'luxon';

import { DatesService } from '@common/services';

/**
@deprecated Ne plus utiliser et migrer vers luxon/DateTime
*/
@Pipe({
    name: 'formatShortDate'
})
export class FormatShortDatePipe implements PipeTransform
{
    constructor (private datesService: DatesService)
    { }

    transform (val?: DateTime): any
    {
        if (!val)
        {
            return '';
        }

        return this.datesService.DateToShortDate(val);
    }
}

@Pipe({
    name: 'formatToShortDate'
})
export class FormatToShortDatePipe implements PipeTransform
{
    constructor (private datesService: DatesService)
    { }

    transform (val?: DateTime): any
    {
        if (!val)
        {
            return '';
        }

        return this.datesService.DateTimeToShorDate(val);
    }
}

/**
 * @deprecated Use formatDateHour9
 */
@Pipe({
    name: 'formatDateHour'
})
export class FormatDateHourPipe implements PipeTransform
{
    constructor (private datesService: DatesService)
    { }

    transform (val?: DateTime): any
    {
        if (!val)
        {
            return '';
        }

        return this.datesService.DateToDateHour(val);
    }
}

@Pipe({
    name: 'formatDateHour9'
})
export class FormatDateHour9Pipe implements PipeTransform
{
    constructor (private datesService: DatesService)
    { }

    transform (val?: DateTime): any
    {
        if (!val || !val.isValid)
        {
            return '';
        }

        return this.datesService.DateTimeToFull(val);
    }
}

@Pipe({
    name: 'formatShortDate9'
})
export class FormatShortDate9Pipe implements PipeTransform
{
    constructor (private datesService: DatesService)
    { }

    transform (val?: DateTime): any
    {
        if (!val)
        {
            return '';
        }

        return this.datesService.DateTimeToHumanReadable(val);
    }
}

@Pipe({
    name: 'formatShortDateHour9'
})
export class FormatShortDateHour9Pipe implements PipeTransform
{
    constructor (private datesService: DatesService)
    { }

    transform (val?: DateTime): any
    {
        if (!val)
        {
            return '';
        }

        return this.datesService.DateTimeToDateHour(val);
    }
}

@Pipe({
    name: 'formatDayMonthHour9'
})
export class FormatDayMonthHour9Pipe implements PipeTransform
{
    constructor (private datesService: DatesService)
    { }

    transform (val?: DateTime): any
    {
        if (!val)
        {
            return '';
        }

        return this.datesService.DateTimeToDayMonthHour(val);
    }
}

@Pipe({
    name: 'formatDateISO'
})
export class FormatDateISOPipe implements PipeTransform
{
    constructor (private datesService: DatesService)
    { }

    transform (val?: string): any
    {
        const date = DateTime.fromISO(val);

        if (!date.isValid)
        {
            return '';
        }

        return this.datesService.DateTimeToFull(date);
    }
}

@Pipe({
    name: 'datesToList'
})
export class DatesToListPipe implements PipeTransform
{
    constructor (private datesService: DatesService)
    { }

    transform (dates: DateTime[], startLineChar: string = '*'): unknown
    {
        if (!dates || dates.length === 0)
        {
            return `${startLineChar} --`;
        }

        return dates
            .map(d =>
                `${startLineChar} ${this.datesService.DateTimeToShorDate(d)}`
            )
            .join('\n');
    }

}
