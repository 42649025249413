import { Entity } from '@apps/auth/domain/models/back';
import { ApplicationsService } from '@common/services';
import { AppliRole, LevelRole } from './appli-role';

export class PartialEntity
{
    public id: string;
    public name: string;
    public entityShortId: string;
    public entityErpId: string;
    public createdAt: string;
    public modifiedAt: string;
    public external: boolean;
    public lastUserLoginUtc: any;
    public applications: AppliRole[];
    public active: boolean;
    public dpoId: string;
    public cisoId: string;
    public entityDocFolder: string;

    public static mapperFromEntity (entity: Entity): PartialEntity
    {
        const partialEntity = new PartialEntity();

        partialEntity.id = entity.id;
        partialEntity.name = entity.name;
        partialEntity.entityShortId = entity.entityShortId;
        partialEntity.entityErpId = entity.entityErpId;
        partialEntity.createdAt = entity.createdAt;
        partialEntity.modifiedAt = entity.modifiedAt;
        partialEntity.external = entity.external;
        partialEntity.lastUserLoginUtc = entity.lastUserLoginUtc;
        partialEntity.active = entity.active;
        partialEntity.dpoId = entity.dpoId;
        partialEntity.cisoId = entity.cisoId;
        partialEntity.entityDocFolder = entity.entityDocFolder;

        if (entity.applications)
        {
            partialEntity.applications = entity.applications.map(a =>
                new AppliRole(a.application, a.defaultRole ? AppliRole.LevelByRole(a.defaultRole) : LevelRole.NOROLE)
            );
        }
        else
        {
            const applicationsService = new ApplicationsService();
            partialEntity.applications = applicationsService.habilitationNavbarItems().map(ni =>
                new AppliRole(ni.api, LevelRole.NOROLE)
            );
        }

        return partialEntity;
    }
}