import { DateTime } from 'luxon';

import { DatesService } from '@common/services/dates.service';

import { SuccessRateApi } from '../../api/models/successrate-api';
import { JobPerPolicy } from './job-per-policy';
import { JobPerPolicyApi } from '../../api/models/job-per-policy-api';
import { Status } from './status';

export class SuccessRate
{
    public day: DateTime;
    public weekDay: string;
    public jobCount: number;
    public jobPerPolicy: JobPerPolicy[];
    public groupByCodeStatuses: Status[];


    public static mapperFromApi (source: SuccessRateApi): SuccessRate
    {
        const target = new SuccessRate();

        Object.assign(target, source);
        target.day = DatesService.UtcStringToDateTimeHourStatic(source.day);

        target.jobPerPolicy = source.perPolicy.map((jobPerPolicyApi: JobPerPolicyApi) =>
            JobPerPolicy.mapperFromApi(jobPerPolicyApi)
        );
        target.groupByCodeStatus();

        return target;
    }

    public groupByCodeStatus (): void
    {
        const init: Status[] = [0, 1, 2].map((idx: number) => new Status(idx, 0, idx));

        this.jobPerPolicy.forEach((policy: JobPerPolicy) =>
            policy.groupByCodeStatus()
        );
        this.groupByCodeStatuses = this.jobPerPolicy.reduce(
            (prev: Status[], curr: JobPerPolicy) =>
            {
                for (let idx = 0; idx < 3; idx++)
                {
                    prev[idx].counter += curr.groupByCodeStatuses[idx].counter;
                }

                return prev;
            },
            init
        );
    }

    public groupByPolicyType (): void
    {
        this.jobPerPolicy.forEach((policy: JobPerPolicy) =>
            policy.groupByCodeStatus()
        );
    }
}
