import { on, createReducer } from '@ngrx/store';

import { RequestsSummaryCounter } from '@common/models';

import * as fromDashboardStoreActions from './dashboard.actions';
import { LastSession, StatusPerRange, StatusPerPolicyType, Variations, TotalCapacity, CountServers } from './models';
import * as fromAuthLoginActions from 'src/app/_apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from 'src/app/_apps/auth/store/profile/profile.actions';
import * as fromRootStore from 'src/app/_apps/root/store';

export const BckpDashboardFeatureKey = 'dashboard';

export interface IBckpDashboardState
{
    lastSession: LastSession;
    statusPerRange: StatusPerRange;
    ticketing: RequestsSummaryCounter;
    statusPerPolicyType: StatusPerPolicyType;
    variations: Variations;
    totalCapacity: TotalCapacity;
    countServers: CountServers;
    statusPerRange2: StatusPerRange;
}

const InitialState: IBckpDashboardState = {
    lastSession: new LastSession(),
    statusPerRange: new StatusPerRange(),
    ticketing: null,
    statusPerPolicyType: new StatusPerPolicyType(),
    variations: new Variations(),
    totalCapacity: new TotalCapacity(),
    countServers: new CountServers(),
    statusPerRange2: new StatusPerRange()
};

export const DashboardReducer = createReducer(
    InitialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => InitialState
    ),
    on(
        fromDashboardStoreActions.LoadBackupDashboardLastSessionSucceeded,
        (state: IBckpDashboardState, { lastSession }) => ({
            ...state,
            lastSession: lastSession
        })
    ),
    on(
        fromDashboardStoreActions.LoadBackupDashboardStatusPerRangeSucceeded,
        (state: IBckpDashboardState, { statusPerRange }) => ({
            ...state,
            statusPerRange: statusPerRange
        })
    ),
    on(
        fromDashboardStoreActions.LoadBackupDashboardTicketingSucceeded,
        (state: IBckpDashboardState, { ticketing }) => ({
            ...state,
            ticketing: ticketing
        })
    ),
    on(
        fromDashboardStoreActions.LoadBackupDashboardStatusPerPolicyTypeSucceeded,
        (state: IBckpDashboardState, { statusPerPolicyType }) => ({
            ...state,
            statusPerPolicyType: statusPerPolicyType
        })
    ),
    on(
        fromDashboardStoreActions.LoadBackupDashboardVariationsSucceeded,
        (state: IBckpDashboardState, { variations }) => ({
            ...state,
            variations: variations
        })
    ),
    on(
        fromDashboardStoreActions.LoadBackupDashboardTotalCapacitySucceeded,
        (state: IBckpDashboardState, { totalCapacity }) => ({
            ...state,
            totalCapacity: totalCapacity
        })
    ),
    on(
        fromDashboardStoreActions.LoadBackupDashboardCountServersSucceeded,
        (state: IBckpDashboardState, { countServers }) => ({
            ...state,
            countServers: countServers
        })
    )
);
