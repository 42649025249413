import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import { ImageCopy } from '@apps/bckp/domain/models';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import * as fromRootStore from '@apps/root/store';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { CopiesCurrFilter } from '@apps/bckp/models';

import * as fromCurrActions from './curr.actions';
import { ODataBckpCopiesCurr } from './odata-bckp-copies-curr';
export const BckpCopiesCurrFeatureKey = 'current';

type ICopiesCurrState = EntityState<ImageCopy>;
export const copiesCurrAdapter: EntityAdapter<ImageCopy> = createEntityAdapter<ImageCopy>({
    selectId: (copy: ImageCopy) => copy.index
});
const copiesCurrInitialState = copiesCurrAdapter.getInitialState({});
export interface IBckpCopiesCurrState
{
    copies: ICopiesCurrState;
    isEmpty: boolean;
    isOups: boolean;
    isLoaded: boolean;
    isLoading: boolean;
    odata: ODataBckpCopiesCurr;
    expirationDate: DateTime;
    qsWord: string;
}

const initialState: IBckpCopiesCurrState =
{
    copies: copiesCurrInitialState,
    isEmpty: false,
    isOups: false,
    isLoaded: false,
    isLoading: true,
    odata: new ODataBckpCopiesCurr(BckpConstants.QSCopiesTop, new CopiesCurrFilter()),
    expirationDate: null,
    qsWord: ''
};


export const BckpCopiesCurrReducer = createReducer(
    initialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => initialState
    ),
    on(
        fromCurrActions.BckpCopiesCurrODataSucceeded,
        (state, { copies }) => ({
            ...state,
            copies: copiesCurrAdapter.setAll(copies, state.copies),
            isEmpty: copies && copies.length === 0,
            isLoaded: true,
            isLoading: false,
            isOups: false,
            expirationDate: DateTime.utc().plus({ minutes: BckpConstants.DataLifeTime })
        }),
    ),
    on(
        fromCurrActions.BckpCopiesCurrODataFailed,
        (state) => ({
            ...state,
            copies: copiesCurrAdapter.removeAll(state.copies),
            isLoading: false,
            isLoaded: false,
            isOups: true
        }),
    ),
    on(
        fromCurrActions.BckpCopiesCurrODataInitialized,
        (state, { scrollSize, filter }) => ({
            ...state,
            odata: new ODataBckpCopiesCurr(scrollSize, filter)
        })
    ),
    on(
        fromCurrActions.BckpCopiesCurrFilterApplied,
        (_, { filter }) => ({
            ...initialState,
            odata: new ODataBckpCopiesCurr(BckpConstants.QSCopiesTop, filter)
        })
    ),
    on(
        fromCurrActions.BckpCopiesCurrCleared,
        (state) => ({
            ...state,
            collection: copiesCurrAdapter.removeAll(state.copies)
        })
    ),
    on(
        fromCurrActions.BckpCopiesCurrQsWordSet,
        (state, { qsWord }) =>
            ({
                ...state,
                qsWord: qsWord.toLowerCase()
            })
    )

);
