import { createAction, props } from '@ngrx/store';
import { DateTime } from 'luxon';

import { RequestsSummaryCounter } from '@common/models';

import { LastSession, StatusPerRange, StatusPerPolicyType, Variations, TotalCapacity, CountServers } from './models';

// #region Last backup session
export const LoadBackupDashboardLastSessionRequested = createAction(
    '[BCKP-DASH] Load Backup Dashboard Last Session Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupDashboardLastSessionSucceeded = createAction(
    '[BCKP-DASH] Load Backup Dashboard Last Session Succeeded',
    props<{ lastSession: LastSession }>()
);

export const LoadBackupDashboardLastSessionFailed = createAction(
    '[BCKP-DASH] Load Backup Dashboard Last Session Failed'
);
// #endregion

// #region Status Per Range
export const LoadBackupDashboardStatusPerRangeRequested = createAction(
    '[BCKP-DASH] Load Backup Dashboard Status Per Range Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupDashboardStatusPerRangeSucceeded = createAction(
    '[BCKP-DASH] Load Backup Dashboard Status Per Range Succeeded',
    props<{ statusPerRange: StatusPerRange }>()
);

export const LoadBackupDashboardStatusPerRangeFailed = createAction(
    '[BCKP-DASH] Load Backup Dashboard Status Per Range Failed'
);
// #endregion

// #region Ticketing
export const LoadBackupDashboardTicketingRequested = createAction(
    '[BCKP-DASH] Load Backup Dashboard Ticketing Requested'
);

export const LoadBackupDashboardTicketingSucceeded = createAction(
    '[BCKP-DASH] Load Backup Dashboard Ticketing Succeeded',
    props<{ ticketing: RequestsSummaryCounter }>()
);

export const LoadBackupDashboardTicketingFailed = createAction(
    '[BCKP-DASH] Load Backup Dashboard Ticketing Failed'
);
// #endregion

// #region Status Per Policy Type
export const LoadBackupDashboardStatusPerPolicyTypeRequested = createAction(
    '[BCKP-DASH] Load Backup Dashboard Status Per Policy Type Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupDashboardStatusPerPolicyTypeSucceeded = createAction(
    '[BCKP-DASH] Load Backup Dashboard Status Per Policy Type Succeeded',
    props<{ statusPerPolicyType: StatusPerPolicyType }>()
);

export const LoadBackupDashboardStatusPerPolicyTypeFailed = createAction(
    '[BCKP-DASH] Load Backup Dashboard Status Per Policy Type Failed'
);
// #endregion

// #region Variations
export const LoadBackupDashboardVariationsRequested = createAction(
    '[BCKP-DASH] Load Backup Dashboard Variations Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupDashboardVariationsSucceeded = createAction(
    '[BCKP-DASH] Load Backup Dashboard Variations Succeeded',
    props<{ variations: Variations }>()
);

export const LoadBackupDashboardVariationsFailed = createAction(
    '[BCKP-DASH] Load Backup Dashboard Variations Failed'
);
// #endregion

// #region TotalCapacity
export const LoadBackupDashboardTotalCapacityRequested = createAction(
    '[BCKP-DASH] Load Backup Dashboard Total Capacity Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupDashboardTotalCapacitySucceeded = createAction(
    '[BCKP-DASH] Load Backup Dashboard Total Capacity Succeeded',
    props<{ totalCapacity: TotalCapacity }>()
);

export const LoadBackupDashboardTotalCapacityFailed = createAction(
    '[BCKP-DASH] Load Backup Dashboard Total Capacity Failed'
);
// #endregion

// #region CountServers
export const LoadBackupDashboardCountServersRequested = createAction(
    '[BCKP-DASH] Load Backup Dashboard Count Servers Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupDashboardCountServersSucceeded = createAction(
    '[BCKP-DASH] Load Backup Dashboard Count Servers Succeeded',
    props<{ countServers: CountServers }>()
);

export const LoadBackupDashboardCountServersFailed = createAction(
    '[BCKP-DASH] Load Backup Dashboard Count Servers Failed'
);
// #endregion
