import { createAction, props } from '@ngrx/store';

import { PasswordResetData } from '../../api/models/reset-pswd';

export const AuthLoginEntered = createAction(
    '[AUTH-LOGIN] Auth Login Entered',
    props<{ email: string, firstName: string, lastName: string }>()
);

export const AuthLoginProfile = createAction(
    '[AUTH-LOGIN] Auth Login Profile'
);

export const AuthLogging = createAction(
    '[AUTH-LOGIN] Logging...',
    props<{ isLogging: boolean }>()
);

export const AuthReturnUrlSaved = createAction(
    '[AUTH-LOGIN] Auth Return Url Saved',
    props<{ returnUrl: string }>()
);

export const AuthLoggedIn = createAction(
    '[AUTH-LOGIN] Logged in'
);

export const AuthLogout = createAction(
    '[AUTH-LOGIN] Logout'
);

export const AuthPasswordForgotten = createAction(
    '[AUTH-LOGIN] Forgotten password'
);

export const AuthPasswordResetSendingLink = createAction(
    '[AUTH-LOGIN] Reset Password Sending link',
    props<{ email: string }>()
);

export const AuthPasswordResetLinkSent = createAction(
    '[AUTH-LOGIN] Reset Password Link Sent'
);

// export const AuthRenewPasswordVerify = createAction(
//     '[AUTH-LOGIN] Renew Password Verify',
//     props<{ token: string }>()
// );

export const AuthPasswordReset = createAction(
    '[AUTH-LOGIN] Reset Password',
    props<{ passwordResetData: PasswordResetData }>()
);

export const AuthPasswordResetSucceeded = createAction(
    '[AUTH-LOGIN] Reset Password Succeeded'
);

export const AuthPasswordResetFailed = createAction(
    '[AUTH-LOGIN] Reset Password Failed'
);

export const AuthProblemArisen = createAction(
    '[AUTH-LOGIN] Problem Arisen',
    props<{ error: any }>()
);

export const AuthTwoFactorRequested = createAction(
    '[AUTH-LOGIN] Auth Two Factor Requested',
    props<{ idToken: string }>()
);

export const AuthTwoFactorLoginRequested = createAction(
    '[AUTH-LOGIN] Auth Two Factor Login Requested',
    props<{ idToken: string, code: string }>()
);

export const AuthTwoFactorLoginFailed = createAction(
    '[AUTH-LOGIN] Auth Two Factor Login Failed'
);
