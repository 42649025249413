import { createAction, props } from '@ngrx/store';

import { Snapshot } from '../../../models/volumes';

export const OstkVolumesSnapListRequested = createAction(
    '[OSTK-SNAP] Ostk Volumes Snap List Requested'
);
export const OstkVolumesSnapListSucceeded = createAction(
    '[OSTK-SNAP] Ostk Volumes Snap List Loaded',
    props<{ snapshots: Snapshot[] }>()
);
export const OstkVolumesSnapListFailed = createAction(
    '[OSTK-SNAP] Ostk Volumes Snap List Failed',
    props<{ failure?: any }>()
);
export const OstkVolumesSnapDeleteRequested = createAction(
    '[OSTK-SNAP] Ostk Volumes Snap Delete Requested',
    props<{ snapshot: Snapshot }>()
);
export const OstkVolumesSnapDeleteSucceeded = createAction(
    '[OSTK-SNAP] Ostk Volumes Snap Delete Succeeded',
    props<{ snapshotId: string }>()
);
export const OstkVolumesSnapDeleteFailed = createAction(
    '[OSTK-SNAP] Ostk Volumes Snap Delete Failed',
    props<{ failure?: any }>()
);
export const OstkVolumesSnapQsWordSet = createAction(
    '[OSTK-SNAP] OSTK Volumes Snap QsWord Set',
    props<{ qsWord: string }>()
);