import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NumbersService
{
    constructor (private translateService: TranslateService)
    {
    }

    toFixed (nb: number, decPart: number = 2): string
    {
        return new Intl.NumberFormat(
            this.translateService.currentLang,
            {
                minimumIntegerDigits: 1,
                minimumFractionDigits: decPart,
                maximumFractionDigits: decPart
            }
        ).format(nb);
    }

    toCurrency (nb: number, decPart: number = 2, currency: string = 'EUR'): string
    {
        return new Intl.NumberFormat(
            this.translateService.currentLang,
            {
                style: 'currency',
                currency,
                minimumIntegerDigits: 1,
                minimumFractionDigits: decPart,
                maximumFractionDigits: decPart
            }
        ).format(nb);
    }
}
