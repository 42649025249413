import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { NgSelectModule } from '@ng-select/ng-select';

import { UiFormModule } from './components/form/ui.form.module';
import { UiForm9Module } from './components/form9/ui.form9.module';
import { UiMiscModule } from './misc/ui.misc.module';
import { ChipsModule } from './misc/chips/chips.module';
import { UiBtnModule } from './components/buttons/ui.btn.module';
import { BusinessModule } from './business/business.module';
import { FilterTagModule } from '@ui/misc/filter-tag/filter-tag.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UiFormModule,
        UiForm9Module,
        UiBtnModule,
        UiMiscModule,
        ChipsModule,
        BusinessModule,
        ScrollingModule,
        NgSelectModule,
        FilterTagModule
    ],
    exports: [
        NgSelectModule,
        UiFormModule,
        UiForm9Module,
        UiBtnModule,
        UiMiscModule,
        ChipsModule,
        BusinessModule,
        ScrollingModule,
        FilterTagModule
    ]
})
export class UIModule
{
}
