import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppConstants } from 'src/app/_apps/app.constants';
import { BaseMiscApiService } from 'src/app/_common/api/base-misc-api.service';
import { FlavorApi } from './models';

@Injectable({
    providedIn: 'root'
})
export class FlavorMiscApiService extends BaseMiscApiService
{
    constructor(http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlOstk;
        this.segmentApi = '/ostk/flavors';
    }

    public getFlavors (): Observable<FlavorApi[]>
    {
        return this.get<FlavorApi[]>();
    }
}
