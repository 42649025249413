import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VolumeMiscApiService } from '../../api';
import { VolumeApi } from '../../api/models';
import { CreateVolume } from '../../models/volumes';
import { Volume } from '../models';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class VolumeMiscDomainService
{
    constructor (
        private volumeMiscApiService: VolumeMiscApiService
    )
    {
    }

    createVolume (volume: CreateVolume): Observable<Volume>
    {
        return this.volumeMiscApiService.createVolume(volume.name, volume.size)
            .pipe(
                map((volumeApi: VolumeApi) =>
                    Volume.mapperFromApi(volumeApi)
                )
            );
    }

    getVolume (volumeId: string): Observable<Volume>
    {
        return this.volumeMiscApiService.getVolume(volumeId)
            .pipe(
                map(volumeApi =>
                    Volume.mapperFromApi(volumeApi)
                )
            );
    }

    attachVolume (volume: Volume, serverId: string): Observable<boolean>
    {
        return this.volumeMiscApiService.attachVolume(volume.id, serverId);
    }

    detachVolume (volume: Volume, serverId: string): Observable<void>
    {
        return this.volumeMiscApiService.detachVolume(volume.id, serverId);
    }

    extendVolume (volume: Volume, newSize: number, serverId: string = ''): Observable<void>
    {
        return this.volumeMiscApiService.extendVolume(volume.id, newSize, serverId);
    }

    deleteVolume (volume: Volume): Observable<void>
    {
        return this.volumeMiscApiService.deleteVolume(volume.id);
    }

    renameVolume (volume: Volume, newName: string): Observable<void>
    {
        return this.volumeMiscApiService.renameVolume(volume.id, newName);
    }

    deleteSnapshot (snapshotId: string): Observable<string>
    {
        return this.volumeMiscApiService.deleteSnapshot(snapshotId);
    }
}
