import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { LeadCreationComponent } from './lead-creation/lead-creation.component';
import { LeadDemoComponent } from './lead-demo/lead-demo.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { AuthRouting } from './auth.routing';
import { AuthStoreModule } from '../_apps/auth/store/auth-store.module';
import { RenewPasswordLinkSentComponent } from './renew-password-link-sent/renew-password-link-sent.component';
import { ProblemArisenComponent } from './problem-arisen/problem-arisen.component';
import { RenewPasswordComponent } from './renew-password/renew-password.component';
import { RenewPasswordGuard } from '../_apps/auth/guards/renew-password.guard';
import { AuthLoadingComponent } from './login/auth-loading/auth-loading.component';
import { AuthResolver } from 'src/app/_apps/auth/resolvers/auth.resolver';
import { SharedModule } from '../_common/shared.module';
import { ProfileStep1Component } from './login/profile/profile-step1/profile-step1.component';
import { ProfileStep2Component } from './login/profile/profile-step2/profile-step2.component';
import { ProfileStep3Component } from './login/profile/profile-step3/profile-step3.component';
import { ProfileStep4Component } from './login/profile/profile-step4/profile-step4.component';
import { TwoFactorLoginComponent } from './two-factor-login/two-factor-login.component';

@NgModule({
    declarations: [
        AuthComponent,
        LoginComponent,
        LeadCreationComponent,
        LeadDemoComponent,
        ForgottenPasswordComponent,
        RenewPasswordLinkSentComponent,
        ProblemArisenComponent,
        RenewPasswordComponent,
        AuthLoadingComponent,
        ProfileStep1Component,
        ProfileStep2Component,
        ProfileStep3Component,
        ProfileStep4Component,
        TwoFactorLoginComponent
    ],
    imports: [
        SharedModule,
        AuthStoreModule,
        AuthRouting
    ],
    providers: [
        AuthResolver,
        RenewPasswordGuard
    ],
    exports: [
        RouterModule,
    ]
})
export class AuthModule
{ }
