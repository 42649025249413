import { TypePopin } from '../app.constants';
import { Contract, Order } from './domain/models';

enum SendStatus {
    ORDER_OK = 'ORDER_OK',
    WITH_THE_CLIENT = 'WITH_THE_CLIENT',
    RESPONSE_OK = 'RESPONSE_OK',
    WAITING = 'WAITING',
    QUOTE_SENT = 'QUOTE_SENT'
}

export enum DafaContractCategoryID
{
    MAINTENANCE = '1',
    ARCANA = '2'
}

export class DafaConstants
{
    public static Application: string = 'DafaApi';
    public static BaseApi: string = 'dafa';
    public static QSOrdersTop = 120;
    public static DataLifeTime = 7;
    public static PrivBaseUrl = 'personal';
    public static Orders = 'orders';
    public static Contracts = 'contracts';
    public static QSContractsTop = 120;
    public static ContractSendStatus = new Map([
        [0, ''],
        [1, SendStatus.ORDER_OK],
        [2, SendStatus.WITH_THE_CLIENT],
        [3, SendStatus.RESPONSE_OK],
        [5, SendStatus.WAITING],
        [6, SendStatus.QUOTE_SENT]
    ]);
    public static OrdersRoute = () => [
        DafaConstants.PrivBaseUrl,
        DafaConstants.Orders
    ];
    public static OrderDetailsRoute = (avcNumber: string) => [
        DafaConstants.PrivBaseUrl,
        DafaConstants.Orders,
        { outlets: { [TypePopin.DETAILS]: [avcNumber] } }
    ];
    public static ContractsRoute = () => [
        DafaConstants.PrivBaseUrl,
        DafaConstants.Contracts
    ];
    public static ContractDetailsRoute = (categoryId: string, contractNumber: string) => [
        DafaConstants.PrivBaseUrl,
        DafaConstants.Contracts,
        { outlets: { [TypePopin.DETAILS]: [categoryId, contractNumber] } }
    ];
    public static contractsQSFields: Array<keyof Contract> = [ 'contractNumber', 'rootContract', 'modelNumber' ];
    static ordersQSFields: Array<keyof Order> = [ 'avcNumber', 'description', 'projectCode' ];

    public static getContractsStatusLabelKey (sendStatus: string): string
    {
        if (sendStatus === '0')
        {
            return '';
        }

        const key = DafaConstants.ContractSendStatus.get(parseInt(sendStatus, 10));

        return key !== undefined ? `DAFA.CONTRACT.STATUSES.${key}` : '';
    }

    public static getContractsCssStatus (sendStatus: string): string
    {
        let klass = '';

        switch (parseInt(sendStatus, 10))
        {
            case 1:
                klass = 'order-ok';
                break;
            case 2:
                klass = 'with-the-client';
                break;
            case 3:
                klass = 'response-ok';
                break;
            case 5:
                klass = 'waiting';
                break;
            case 6:
                klass = 'quote-sent';
                break;
            default:
                break;
        }

        return klass;
    }
}
