import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import * as fromRootImpersonateActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { Its2Constants } from '@apps/its2/its2.constants';
import { CatalogEntry } from '@apps/its2/models';

import * as fromIts2CatalogsActions from './catalogs.actions';
import { CatalogEntryQuestion } from '@apps/its2/domain/models';

export const Its2CatalogsFeatureKey = 'catalogs';

type ICatalogsState = EntityState<CatalogEntry>;
export const catalogsAdapter: EntityAdapter<CatalogEntry> = createEntityAdapter<CatalogEntry>({
    selectId: (catalogEntry: CatalogEntry) => catalogEntry.catalogId
});
const catalogsInitialState: ICatalogsState = catalogsAdapter.getInitialState({
});

interface catalog
{
    collection: ICatalogsState;
    isLoading: boolean;
    isLoaded: boolean;
    isEmpty: boolean;
    isOups: boolean;
    expirationDate?: DateTime;
}
export interface IIts2CatalogsState
{
    incident: catalog;
    service: catalog;
    questions: any;
}

export const Its2CatalogsState: IIts2CatalogsState = {
    incident:
    {
        collection: catalogsInitialState,

        isLoading: false,
        isLoaded: false,
        isEmpty: false,
        isOups: false,
        expirationDate: null
    },
    service:
    {
        collection: catalogsInitialState,
        isLoading: false,
        isLoaded: false,
        isEmpty: false,
        isOups: false,
        expirationDate: null
    },
    questions: {
    }
};

export const Its2CatalogsReducer = createReducer(
    Its2CatalogsState,
    on(
        fromRootImpersonateActions.RootImpersonateSet,
        fromRootImpersonateActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => Its2CatalogsState
    ),
    on(
        fromIts2CatalogsActions.Its2CatalogsIncidentRequested,
        (state: IIts2CatalogsState) =>
            ({
                ...state,
                incident: {
                    ...state.incident,
                    isLoading: true
                }
            })
    ),
    on(
        fromIts2CatalogsActions.Its2CatalogsIncidentSucceeded,
        (state: IIts2CatalogsState, { catalogEntries }: { catalogEntries: CatalogEntry[]; }) =>
            ({
                ...state,
                incident: {
                    collection: catalogsAdapter.setAll(catalogEntries, state.incident.collection),
                    isLoading: false,
                    isLoaded: true,
                    isEmpty: catalogEntries.length === 0,
                    isOups: false,
                    expirationDate: DateTime.utc().plus({ minutes: Its2Constants.DataLifeTime })
                }
            })
    ),
    on(
        fromIts2CatalogsActions.Its2CatalogsIncidentFailed,
        (state: IIts2CatalogsState) =>
            ({
                ...state,
                incident: {
                    collection: catalogsAdapter.removeAll(state.incident.collection),
                    isLoading: false,
                    isLoaded: true,
                    isEmpty: true,
                    isOups: true,
                    expirationDate: null
                }
            })
    ),
    on(
        fromIts2CatalogsActions.Its2CatalogsServiceRequested,
        (state: IIts2CatalogsState) =>
            ({
                ...state,
                service: {
                    ...state.service,
                    isLoading: true
                }
            })
    ),
    on(
        fromIts2CatalogsActions.Its2CatalogsServiceSucceeded,
        (state: IIts2CatalogsState, { catalogEntries }: { catalogEntries: CatalogEntry[]; }) =>
            ({
                ...state,
                service: {
                    collection: catalogsAdapter.setAll(catalogEntries, state.service.collection),
                    isLoading: false,
                    isLoaded: true,
                    isEmpty: catalogEntries.length === 0,
                    isOups: false,
                    expirationDate: DateTime.utc().plus({ minutes: Its2Constants.DataLifeTime })
                }
            })
    ),
    on(
        fromIts2CatalogsActions.Its2CatalogsServiceFailed,
        (state: IIts2CatalogsState) =>
            ({
                ...state,
                service: {
                    collection: catalogsAdapter.removeAll(state.service.collection),
                    isLoading: false,
                    isLoaded: true,
                    isEmpty: true,
                    isOups: true,
                    expirationDate: null
                }
            })
    ),
    on(
        fromIts2CatalogsActions.Its2CatalogsServiceQuestionsSucceeded,
        (state: IIts2CatalogsState, { catalogGuid, questions }: { catalogGuid: string, questions: CatalogEntryQuestion[]; }) =>
            ({
                ...state,
                questions: {
                    ...state.questions,
                    [catalogGuid]: questions
                }
            })
    ),
);
