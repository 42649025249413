import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { tap, map, switchMap, concatMap, filter, last } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { IAuroraState } from '@apps/aurora.state';
import * as fromAuthProfileStoreActions from '@apps/auth/store/profile/profile.actions';
import { NotfWarnStoreActions } from '@apps/notf/store';
import { AppConstants } from '@apps/app.constants';

type AppName = 'auth' | 'dash' | 'bckp' | 'comp' | 'itsm' | 'cmdb' |
'dafa' | 'objs' | 'priv' | 'prtg' | 'rept' | 'secu' | 'pata' |
'ostk' | 'ostk/project' | 'ostk/instance' | 'ostk/image' |
'ostk/volume' | 'ostk/network' | 'ostk/security' | 'ostk/quota' |
'main@admin' | 'prtg@admin' | 'pata@admin';
type LoadedTranslations = { [K in AppName]: boolean } & { main: boolean };

@Injectable({
    providedIn: 'root'
})
export class LanguageService
{
    private loadedTranslations: LoadedTranslations;
    private readonly defaultLang = AppConstants.Languages[0];
    private readonly regexpLang: RegExp = /en-US|fr-FR/;

    private _lang: string = this.defaultLang;
    private oldLang: string;

    constructor (
        private store: Store<IAuroraState>,
        private actions: Actions,
        private translate: TranslateService
    )
    {
        this.loadedTranslations = {} as LoadedTranslations;
        translate.addLangs(AppConstants.Languages);
        this._lang = this.formatLang(this.translate.getBrowserCultureLang());
        this.language = this._lang;

        this.actions
            .pipe(
                ofType(fromAuthProfileStoreActions.AuthProfileLanguageSet),
                map((param: { language: string }) => this.formatLang(param.language)),
                filter((l: string) => l !== this.language),
                tap((l: string) =>
                {
                    this.store.dispatch(NotfWarnStoreActions.ClearMessages());
                    this.language = l;
                    this.resetLoadedLanguages();
                }),
                switchMap(() =>
                    this.loadLanguage(['auth', 'priv', 'dafa'])
                )
            )
            .subscribe();

        this.translate.onLangChange
            .pipe(
                tap(() =>
                    this.store.dispatch(fromAuthProfileStoreActions.AuthProfileLanguageLoaded())
                )
            )
            .subscribe();
    }

    public get language (): string
    {
        return this.translate.currentLang || this._lang;
    }

    public set language (lang: string)
    {
        this.oldLang = this._lang;
        this._lang = lang;

        this.translate.resetLang(this.oldLang);
        this.translate.use(this._lang);
    }

    public getShortItsmLanguage (): string
    {
        return this.language === 'fr-FR' ? 'Fr' : 'En';
    }

    public getLangs (): string[]
    {
        return this.translate.getLangs();
    }

    /**
     * @param {string | string[]} apps a single or multiple appNames.
     * When not provided, will load main translation file.
     * @example: loadLanguage(['itsm', 'bckp']) will load both "itsm" and "bckp" translation files
     *
     */
    loadLanguage (apps?: AppName | AppName[]): Observable<boolean>
    {
        if (!apps || (Array.isArray(apps) && apps.length === 0))
        {
            return this.loadMainLanguage();
        }

        if (typeof apps === 'string')
        {
            return this.loadAppLanguage(apps);
        }

        if (Array.isArray(apps))
        {
            return from(apps)
                .pipe(
                    concatMap((app: AppName) =>
                        this.loadAppLanguage(app)
                    ),
                    last()
                );
        }

        return of(true);
    }

    public setlanguage (language: string): Observable<boolean>
    {
        this.resetLoadedLanguages();

        if (language === this.language)
        {
            return of(true);
        }

        this.store.dispatch(NotfWarnStoreActions.ClearMessages());
        this.language = language;

        return this.loadLanguage();
    }

    // public toggleLanguage(profile: Profile): void
    // {
    //     const idx: number = AppConstants.Languages.findIndex((lg) => lg === this.language);
    //     const next: number = (idx + 1) % (AppConstants.Languages.length);

    //     profile.language = AppConstants.Languages[next];
    //     this.store.dispatch(fromAuthProfileStoreActions.AuthProfileLanguageChanged({ profile: profile }));
    // }

    // #endregion Public Methods

    // #region Private Methods
    private loadMainLanguage (): Observable<boolean>
    {
        if (this.loadedTranslations.main)
        {
            return of(true);
        }

        return this.loadTranslation(`${this._lang}`)
            .pipe(
                tap(() =>
                    this.loadedTranslations.main = true
                ),
                // catchError(() => of(false))
            );
    }

    private formatLang (l: string): string
    {
        if (l === 'fr')
        {
            return 'fr-FR';
        }

        return l.match(this.regexpLang) ? l : this.defaultLang;
    }

    private loadAppLanguage (appName: AppName): Observable<boolean>
    {
        if (this.loadedTranslations[appName])
        {
            return of(true);
        }

        const path = this.getPath(appName);

        return this.loadTranslation(path)
            .pipe(
                tap(() => this.loadedTranslations[appName] = true)
            );
    }

    private loadTranslation (path: string): Observable<boolean>
    {
        return this.translate.getTranslation(path)
            .pipe(
                tap((x: any) =>
                {
                    this.translate.setTranslation(this._lang, x, true);
                }),
                map(() => true),
                // catchError(() => of(false))
            );
    }

    private getPath (appName: AppName): string
    {
        if (appName.indexOf('@') !== -1)
        {
            const [myAppName, area] = appName.split('@');

            return `${area}/${myAppName}/${this._lang}`;
        }

        return `customer/${appName}/${this._lang}`;
    }

    private resetLoadedLanguages ()
    {
        for (const key in this.loadedTranslations)
        {
            this.loadedTranslations[key] = false;
        }
    }
}
