import { createAction, props } from '@ngrx/store';

import { CopyEvolution } from '@apps/bckp/domain/models/copy-evolution';
import { CopiesEvolFilter } from '@apps/bckp/models';

export const BckpCopiesEvolODataRequested = createAction(
    '[BCKP-COPIES] Bckp Copies Evol OData Requested'
);
export const BckpCopiesEvolODataSucceeded = createAction(
    '[BCKP-COPIES] Bckp Copies Evol OData Succeeded',
    props<{ copies: CopyEvolution[] }>()
);
export const BckpCopiesEvolODataInitialized = createAction(
    '[BCKP-COPIES] Bckp Copies Evol OData Initialized',
    props<{ scrollSize: number, filter: CopiesEvolFilter }>()
);
export const BckpCopiesEvolODataFailed = createAction(
    '[BCKP-COPIES] Bckp Copies Evol OData Failed'
);
export const BckpCopiesEvolCleared = createAction(
    '[BCKP-COPIES] Bckp Copies Evol Cleared'
);
export const BckpCopiesEvolFilterApplied = createAction(
    '[BCKP-COPIES] Bckp Copies Evol Filter Applied',
    props<{ filter: CopiesEvolFilter }>()
);
