import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import { IAuroraState } from '@apps/aurora.state';

import * as fromLoginReducer from './login/login.reducer';
import * as fromProfileReducer from './profile/profile.reducer';
import * as fromAccountReducer from './account/account.reducer';
import * as fromUsersReducer from './users/users.reducer';
import * as fromUsersState from './users/users.state';
import { Profile } from '../models/profile';
import { ApplicationRole } from '../models';
import { BackConstants } from '../../back/back.constants';

export const AuthFeatureKey = 'auth';
export interface IAuthState
{
    [fromLoginReducer.AuthLoginFeatureKey]: fromLoginReducer.IAuthLoginState;
    [fromProfileReducer.AuthProfileFeatureKey]: fromProfileReducer.IAuthProfileState;
    [fromAccountReducer.AuthAccountFeatureKey]: fromAccountReducer.IAuthAccountState;
    [fromUsersState.AuthUsersFeatureKey]: fromUsersState.IAuthUsersState;
}

export interface IState extends IAuroraState
{
    [AuthFeatureKey]: IAuthState;
}

export function AuthReducers (state: IAuthState | undefined, action: Action)
{
    return combineReducers({
        [fromLoginReducer.AuthLoginFeatureKey]: fromLoginReducer.AuthLoginReducer,
        [fromProfileReducer.AuthProfileFeatureKey]: fromProfileReducer.AuthProfileReducer,
        [fromAccountReducer.AuthAccountFeatureKey]: fromAccountReducer.AuthAccountReducer,
        [fromUsersState.AuthUsersFeatureKey]: fromUsersReducer.AuthUsersReducer
    })(state, action);
}

export const getAuthState = createFeatureSelector<IAuthState>(
    AuthFeatureKey
);

const getAuthLoginState = createSelector(
    getAuthState,
    (state: IAuthState) =>
        state[fromLoginReducer.AuthLoginFeatureKey]
);

const getAuthProfileState = createSelector(
    getAuthState,
    (state: IAuthState) =>
        state[fromProfileReducer.AuthProfileFeatureKey]
);
export const isLoggedIn = createSelector(
    getAuthLoginState,
    (state: fromLoginReducer.IAuthLoginState) =>
        state.loggedIn
);

export const isLoggedOut = createSelector(
    getAuthLoginState,
    (state: fromLoginReducer.IAuthLoginState) =>
        !state.loggedIn
);
export const getReturnUrl = createSelector(
    getAuthLoginState,
    (state: fromLoginReducer.IAuthLoginState) =>
        state.returnUrl
);

export const getWelcomeInfos = createSelector(
    getAuthLoginState,
    (state: fromLoginReducer.IAuthLoginState) =>
    {
        return {
            email: state.email,
            firstName: state.firstName,
            lastName: state.lastName
        };
    }
);


export const getCounterTwoFactorTries = createSelector(
    getAuthLoginState,
    (state: fromLoginReducer.IAuthLoginState) =>
        state.counterTwoFactorTries
);

export const getProfile = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState) =>
        state.profile
);
export const getBackAuthorization = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState) =>
    {
        if (state.profile && state.profile.appRoles)
        {
            return state.profile.appRoles.find((appRole: ApplicationRole) =>
                appRole.application.toUpperCase() === BackConstants.Application.toUpperCase()
            ) !== undefined;
        }

        return false;
    }
);
export const getApiAuthorization = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState, params: ApplicationRole): boolean =>
    {
        if (state.appRoles !== null && state.appRoles.length !== 0)
        {
            return state.appRoles.find((appRole: ApplicationRole): boolean =>
                appRole.application.toUpperCase() === params.application.toUpperCase()
            ) !== undefined;
        }
        else if (state.profile && state.profile.appRoles)
        {
            return state.profile.appRoles.find((appRole: ApplicationRole): boolean =>
                appRole.application.toUpperCase() === params.application.toUpperCase()
            ) !== undefined;
        }

        return false;
    }
);

export const getApplications = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState): string[] =>
    {
        const appRoles = (state.appRoles !== null)
            ? state.appRoles
            : state.profile.appRoles;

        const applications = appRoles
            .map((appRole: ApplicationRole): string =>
                appRole.application
            );

        return applications;
    }
);

export const getAuthProfile = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState) =>
        state.profile
);

export const getWelcomeInfos2 = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState) =>
    {
        if (state.profile === null)
        {
            return null;
        }

        return {
            email: state.profile.email,
            firstName: state.profile.firstName,
            lastName: state.profile.lastName
        };
    }
);

export const getProfileCard = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState) =>
    {
        if (state.profile === null)
        {
            return null;
        }

        return {
            identiconDataUrl: state.profile.identiconDataUrl,
            firstLastName: state.profile.firstLastName,
            title: state.profile.title,
            entityName: state.profile.entityName,
            phoneNumber: state.profile.phoneNumber,
            email: state.profile.email,
            mobile: state.profile.mobile
        } as Profile;
    }
);

export const getProfileInformations = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState) =>
    {
        if (state.profile === null)
        {
            return null;
        }

        return state.profile;
        // console.debug({ p: state.profile });
        // return {
        //     firstName: state.profile.firstName,
        //     lastName: state.profile.lastName,
        //     title: state.profile.title,
        //     entityName: state.profile.entityName,
        //     phoneNumber: state.profile.phoneNumber,
        //     email: state.profile.email,
        //     mobile: state.profile.mobile
        // } as Profile;
    }
);

export const getProfileSecurity = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState) =>
    {
        if (state.profile === null)
        {
            return null;
        }

        return {
            twoFactorEnabled: state.profile.twoFactorEnabled
        } as Profile;
    }
);

export const getProfileNewsletters = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState) =>
    {
        if (state.profile === null)
        {
            return null;
        }

        return {
            marketingNewsletter: state.profile.marketingNewsletter,
            maintenanceNotification: state.profile.maintenanceNotification
        } as Profile;
    }
);

export const getProfileCookies = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState) =>
    {
        if (state.profile === null)
        {
            return null;
        }

        return {
            socialNetworksCookies: state.profile.socialNetworksCookies,
            statisticsCookies: state.profile.statisticsCookies,
            workingCookies: state.profile.workingCookies
        } as Profile;
    }
);

export const getProfileParameters = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState) =>
    {
        if (state.profile === null)
        {
            return null;
        }

        return {
            skin: state.profile.skin,
            language: state.profile.language
        } as Profile;
    }
);

export const getLanguage = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState) =>
    {
        if (state.profile === null)
        {
            return null;
        }

        return state.profile.language;
    }
);

export const getSkin = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState) =>
    {
        if (state.profile === null)
        {
            return 'light';
        }

        return Profile.fromThemeToSkin(state.profile.skin);
    }
);

export const isCatalogSecuAuthorized = createSelector(
    getAuthProfileState,
    (state: fromProfileReducer.IAuthProfileState): boolean =>
        state.profile?.isCatalogSecuAuthorized
);
