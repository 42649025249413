import { Status } from './status';

export class StatusPerPolicyType
{
    public policyType: string;
    public statuses: Status[];
    public total: number;
    public successRate: number;

    constructor(policyType: string, statuses: Status[], total: number) {
        this.policyType = policyType;
        this.statuses = statuses;
        this.total = total;
    }

    public calcSuccessRate (): number
    {
        if (this.statuses[0])
        {
            return 100 * this.statuses[0].counter / this.total;
        }

        return 0;
    }
}
