import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { S3Object } from '@apps/objs/domain/models';
import { ObjsConstants } from '@apps/objs/objs.constants';

export const ObjsBucketObjeFeatureKey = 'objects';

type IObjsObjectState = EntityState<S3Object>;
export const objectsAdapter: EntityAdapter<S3Object> = createEntityAdapter({
    selectId: (object: S3Object) => object.id,
    sortComparer: ObjsConstants.compareS3Object

});
const objectsInitialState: IObjsObjectState = objectsAdapter.getInitialState({});
export interface IObjsBucketObjeState {
    objects: IObjsObjectState;
    isLoading: boolean;
    isLoaded: boolean;
    isEmpty: boolean;
    isOups: boolean;
    selectedObject: S3Object;
}

export const ObjsBucketObjeInitialState: IObjsBucketObjeState = {
    objects: objectsInitialState,
    isLoading: true,
    isLoaded: false,
    isEmpty: false,
    isOups: false,
    selectedObject: null
};