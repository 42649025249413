<form id="auth-form" [formGroup]="form" (keyup.enter)="onSubmit()">
    <div class="auth-title">{{ 'AUTH.PROFILE.ONBOARDING.STEP3.TITLE' | translate }}</div>
    <div class="auth-sub-title">
        {{ 'AUTH.PROFILE.ONBOARDING.STEP3.SUBTITLE' | translate }}
    </div>
    <aa-input-password
        name="oldPassword"
        [required]="'required'"
        required
        label="{{
            'PRIV.PROFILE.SECURITY.UPDATEPASSWORD.MODAL.OLDPASSWORD' | translate
        }}"
        formControlName="oldPassword"
    ></aa-input-password>
    <aa-input-new-pswd
        [required]="'required'"
        required
        name="newPassword"
        label="{{
            'AUTH.PROFILE.ONBOARDING.STEP3.ENTERPASSWORD'
                | translate
        }}"
        formControlName="newPassword"
    >
    </aa-input-new-pswd>
    <aa-input-password
        [required]="'required'"
        required
        name="verifyPassword"
        label="{{
            'PRIV.PROFILE.SECURITY.UPDATEPASSWORD.MODAL.CONFIRMPASSWORD'
                | translate
        }}"
        formControlName="verifyPassword"
    >
    </aa-input-password>
    <div class="buttons">
        <aa-btn-action-secondary
            [btnText]="'BUTTON.CANCEL' | translate"
            [cta]="onCancel"
            [btnWidth]="'100%'"
        >
        </aa-btn-action-secondary>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <aa-btn-action-secondary
            [btnText]="'BUTTON.CONTINUE' | translate"
            [cta]="onSubmit"
            [btnWidth]="'100%'"
        >
        </aa-btn-action-secondary>
    </div>
</form>
