import { Observable } from 'rxjs';

import { Predicate } from '../filters/in-memory-filter';

// ? @see https://netbasal.com/creating-custom-operators-in-rxjs-32f052d69457
// ? @see https://rxjs.dev/guide/operators (vers la fin de la doc)
export const multiFilter = <T> (fns: Predicate<T>[]) =>
{
    return function (source: Observable<T>): Observable<T>
    {
        return new Observable(subscriber =>
        {
            return source.subscribe({
                next (value: T)
                {
                    const result = fns.reduce(
                        (prev: boolean, curr: Predicate<T>) => prev && curr(value),
                        true
                    );
                    if (result)
                    {
                        subscriber.next(value);
                    }
                },
                error (error)
                {
                    subscriber.error(error);
                },
                complete ()
                {
                    subscriber.complete();
                }
            });
        });
    };
};
