import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { concatAll, map, tap, toArray } from 'rxjs/operators';
import { BaseMiscDomainService } from 'src/app/_common/domain/base-misc-domain.service';

import { QuotaMiscApiService } from '../api';
import { Quota } from './models';

@Injectable({
    providedIn: 'root'
})
export class QuotaMiscDomainService extends BaseMiscDomainService
{
    constructor(private quotaMiscApiService: QuotaMiscApiService)
    {
        super();
    }

    public getQuotas (): Observable<Quota[]>
    {
        return this.quotaMiscApiService.getQuotas()
            .pipe(
                concatAll(),
                map(Quota.mapperFromApi),
                toArray()
            );
    }
}
