import { HttpEvent } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ActivityByServer } from '@apps/bckp/domain/models/activity/activity-by-server';
import { ServersFilter } from '@apps/bckp/models';
import { ActivityByDate, Job } from '@apps/bckp/domain/models';
import { MediaTypes } from '@common/services';
import { EnrichedAsset } from '@apps/cmdb/models';

const moduleIdentifier: string = '[BCKP-SERVERS-HEAT]';
const actionIdentifier = (key: string): string =>
    `${moduleIdentifier} | ${key}`;

export const BckpServersHeatODataInitialized = createAction(
    actionIdentifier('OData Initialized'),
    props<{ scrollSize: number, filter: ServersFilter }>()
);
export const BckpServersHeatODataRequested = createAction(
    actionIdentifier('OData Requested')
);

export const BckpServersHeatODataLoaded = createAction(
    actionIdentifier('OData Loaded'),
    props<{ servers: ActivityByServer[] }>()
);

export const BckpServersHeatODataFailed = createAction(
    actionIdentifier('OData Failed')
);

export const BckpServersHeatCleared = createAction(
    actionIdentifier('Cleared')
);

export const BckpServersHeatInsert = createAction(
    actionIdentifier('Upsert'),
    props<{ servers: ActivityByServer[] }>()
);

export const BckpServersHeatQsWordSet = createAction(
    actionIdentifier('QsWord Set'),
    props<{ qsWord: string }>()
);

export const BckpServersHeatIssuesOnlySet = createAction(
    actionIdentifier('Issues Only Set'),
    props<{ issuesOnly: boolean }>()
);

export const BckpServersHeatFilterApplied = createAction(
    actionIdentifier('Filter Applied'),
    props<{ filter: ServersFilter }>()
);

export const BckpServersHeatFilterReset = createAction(
    actionIdentifier('Filter Reset')
);

export const BckpServersHeatJobsRequested = createAction(
    actionIdentifier('Jobs Requested'),
    props<{ master: string, server: string, abd: ActivityByDate }>()
);

export const BckpServersHeatJobsSucceeded = createAction(
    actionIdentifier('Jobs Succeeded'),
    props<{ jobs: Job[], nbJobs: number }>()
);

export const BckpServersHeatJobsFailed = createAction(
    actionIdentifier('Jobs Failed')
);

export const BckpServersHeatExportRequested = createAction(
    actionIdentifier('Export Requested'),
    props<{ format: MediaTypes }>()
);

export const BckpServersHeatExportSucceeded = createAction(
    actionIdentifier('Export Succeeded'),
    props<{ response: HttpEvent<Blob> }>()
);

export const BckpServersHeatTagAttachRequested = createAction(
    actionIdentifier('Tag Attach Requested'),
    props <{ id: string, name: string, identifier: string, tagId: string}>()
);
export const BckpServersHeatTagAttachExecuted = createAction(
    actionIdentifier('Tag Attach Executed'),
    props<{ enrichedAsset: EnrichedAsset; }>()
);
export const BckpServersHeatTagAttachSucceeded = createAction(
    actionIdentifier('Tag Attach Succeeded'),
    props<{ updatedActivity: Update<ActivityByServer> }>()
);
export const BckpServersHeatTagDetachRequested = createAction(
    actionIdentifier('Tag Detach Requested'),
    props<{ id: string, identifier: string, tagId: string }>()
);
export const BckpServersHeatTagDetachExecuted = createAction(
    actionIdentifier('Tag Detach Executed'),
    props<{ enrichedAsset: EnrichedAsset; }>()
);
export const BckpServersHeatTagDetachSucceeded = createAction(
    actionIdentifier('Tag Detach Succeeded'),
    props<{ updatedActivity: Update<ActivityByServer> }>()
);
