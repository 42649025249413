<div class="checkbox-group" [ngClass]="orientation" *ngIf="lineSize === 0" [attr.disabled]="notEnabled?'':null"
     [style.marginTop.px]="marginTop">
    <div class="checkbox" *ngFor="let so of options">
        <aa-checkbox2 [toTranslate]="toTranslate" [option]="so"> </aa-checkbox2>
    </div>
</div>
<div class="checkbox-group" *ngIf="lineSize !== 0" [attr.disabled]="notEnabled?'':null"
     [style.marginTop.px]="marginTop">
    <table>
        <tr *ngFor="let line of lines; index as i">
            <td *ngFor="let so of options.slice(i * lineSize, (i + 1) * lineSize)">
                <aa-checkbox2 [toTranslate]="toTranslate" [option]="so"> </aa-checkbox2>
            </td>
        </tr>
    </table>
</div>
