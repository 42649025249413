import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import { RootMiscEffects } from './misc/misc.effects';
import { RootSearchEffects } from './search/search.effects';
import { RootFeatureKey, RootReducers } from './root.reducer';

// * Réglage dynamique des imports de DEV
const noDevTools = {
    imports: []
};
const storeDevTools = noDevTools;

const storeConfig = {
    runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false
    }
};

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forRoot(
            {
                router: routerReducer
            },
            storeConfig
        ),
        StoreModule.forFeature(
            RootFeatureKey,
            RootReducers
        ),
        EffectsModule.forRoot([]),
        EffectsModule.forFeature([
            RootMiscEffects,
            RootSearchEffects
        ]),
        StoreRouterConnectingModule.forRoot(),
        storeDevTools.imports
    ]
})
export class RootStoreModule
{
}
