import { Route, RouterModule } from '@angular/router';

import { AuthComponent } from './auth.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { LeadDemoComponent } from './lead-demo/lead-demo.component';
import { LeadCreationComponent } from './lead-creation/lead-creation.component';
import { LoginComponent } from './login/login.component';
import { RenewPasswordLinkSentComponent } from './renew-password-link-sent/renew-password-link-sent.component';
import { ProblemArisenComponent } from './problem-arisen/problem-arisen.component';
import { RenewPasswordComponent } from './renew-password/renew-password.component';
import { RenewPasswordGuard } from '@apps/auth/guards/renew-password.guard';
import { AuthResolver } from '@apps/auth/resolvers/auth.resolver';
import { ProfileStep1Component } from './login/profile/profile-step1/profile-step1.component';
import { ProfileStep2Component } from './login/profile/profile-step2/profile-step2.component';
import { ProfileStep3Component } from './login/profile/profile-step3/profile-step3.component';
import { ProfileStep4Component } from './login/profile/profile-step4/profile-step4.component';
import { AuthTokenCanActivateGuard } from '@apps/auth/guards/auth-token.can-activate.guard';
import { SkinResolver } from '@apps/root/resolvers/skin.resolver';
import { TwoFactorLoginComponent } from './two-factor-login/two-factor-login.component';

const authLoginRoute0: Route = {
    path: 'login/:email/:firstName/:lastName',
    component: LoginComponent,
    data: {
        title: 'Login'
    },
    canActivate: [
        AuthTokenCanActivateGuard
    ],
    resolve: {
        skin: SkinResolver
    }
};
const authLoginRoute1: Route = {
    path: 'login/:email',
    component: LoginComponent,
    data: {
        title: 'Login'
    },
    canActivate: [
        AuthTokenCanActivateGuard
    ],
    resolve: {
        skin: SkinResolver
    }
};

const authLoginRoute2: Route = {
    path: 'login',
    component: LoginComponent,
    data: {
        title: 'Login'
    },
    canActivate: [
        AuthTokenCanActivateGuard
    ],
    resolve: {
        skin: SkinResolver
    }
};

const authProfileStep1Route: Route = {
    path: 'profile/step1',
    component: ProfileStep1Component,
    data: {
        title: 'Login'
    }
};

const authProfileStep2Route: Route = {
    path: 'profile/step2',
    component: ProfileStep2Component,
    data: {
        title: 'Login'
    }
};

const authProfileStep3Route: Route = {
    path: 'profile/step3',
    component: ProfileStep3Component,
    data: {
        title: 'Login'
    }
};

const authProfileStep4Route: Route = {
    path: 'profile/step4',
    component: ProfileStep4Component,
    data: {
        title: 'Login'
    }
};

const authForgottenPasswordRoute: Route = {
    path: 'forgotten-password',
    component: ForgottenPasswordComponent,
    data: {
        title: 'Login'
    }
};

const authRenewPasswordLinkSentRoute: Route = {
    path: 'link-sent',
    component: RenewPasswordLinkSentComponent,
    data: {
        title: 'Login'
    }
};

const authRenewPasswordRoute: Route = {
    path: 'renew-password/:token',
    component: RenewPasswordComponent,
    data: {
        title: 'Login'
    },
    canActivate: [
        RenewPasswordGuard
    ]
};

const leadDemoRoute: Route = {
    path: 'demo',
    component: LeadDemoComponent,
    data: {
        title: 'Demo'
    }
};

const leadCreationRoute: Route = {
    path: 'creation',
    component: LeadCreationComponent,
    data: {
        title: 'Demo'
    }
};

const authProblemArisen: Route = {
    path: 'problem',
    component: ProblemArisenComponent
};

const authAccount: Route = {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
};

const authTwoFactorLoginRoute: Route = {
    path: 'twofactor/:idtoken',
    component: TwoFactorLoginComponent,
    data: {
        title: 'Login'
    }
};

const authRoutes: Route = {
    path: 'auth',
    component: AuthComponent,
    children: [
        authLoginRoute0,
        authLoginRoute1,
        authLoginRoute2,
        authProfileStep1Route,
        authProfileStep2Route,
        authProfileStep3Route,
        authProfileStep4Route,
        authForgottenPasswordRoute,
        authRenewPasswordLinkSentRoute,
        authRenewPasswordRoute,
        leadDemoRoute,
        leadCreationRoute,
        authTwoFactorLoginRoute,
        authProblemArisen,
        authAccount
    ],
    resolve: {
        language: AuthResolver
    }
};

export const AuthRouting = RouterModule.forChild([
    authRoutes
]);
