import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aa-problem-arisen',
  templateUrl: './problem-arisen.component.html',
  styleUrls: ['./problem-arisen.component.scss']
})
export class ProblemArisenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
