import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';

import { RequestValuesDomainService } from '@apps/its2/domain';
import { SelectOption } from '@ui/components/form/common';

import * as fromIts2ValuesActions from './values.actions';
import { PropertyValues } from '../models/property-values';

@Injectable()
export class Its2ValuesEffects
{
    constructor (
        private actions: Actions,
        private requestValuesDomainService: RequestValuesDomainService
    )
    { }

    its2RequestsValuesRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2ValuesActions.Its2RequestsValuesRequested),
                concatMap(({ propertyName: propertyName }: { propertyName: string; }) =>
                    this.requestValuesDomainService.getOptions(propertyName)
                        .pipe(
                            map((options: SelectOption[]) =>
                                fromIts2ValuesActions.Its2RequestsValuesSucceeded({
                                    propertyValues: {
                                        propertyName: propertyName,
                                        options: options
                                    } as PropertyValues
                                })
                            ),
                            catchError((error: Error) =>
                                of(fromIts2ValuesActions.Its2RequestsValuesFailed({ error }))
                            )
                        )
                )
            )
    );
}
