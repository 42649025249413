import { animate, state, style, transition, trigger, AnimationTriggerMetadata, } from '@angular/animations';

export const popin_notif_item_animations: Array<AnimationTriggerMetadata> = [
    trigger('showHide', [
        state('show', style({
            height: 'calc(100vh / 10)',
            opacity: '1'
        })),
        state('hide', style({
            height: '0',
            opacity: '0'
        })),
        transition('show => hide', [
            animate('0.5s')
        ]),
        transition('hide => show', [
            animate('0.5s')
        ])
    ])
];
