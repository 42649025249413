import { DateTime } from 'luxon';

import { Its2Constants, RequestStatusIdEnum, SlaStatus, UNCATEGORIZED, WaitingStatus } from '@apps/its2/its2.constants';
import { LocalizedText } from '@common/models';

import { RequestApi, SimplifiedRequestApi } from '../../api/models/request-api';
import { UtilsService } from '@common/services';

export class SelectedRequest
{
    rfcNumber: string;
    isFavorite: boolean;
    isRead: boolean;
}

export class Request
{
    public requestId: number;
    public rfcNumber: string;
    public type: string;
    public submitDateUtc: DateTime;
    public endDateUtc?: DateTime;
    public maxResolutionDateUtc: DateTime;
    public title: string;
    public shortComment: string;
    public urgencyId: number;
    public statusId: number;
    public metaStatusGuid: string;
    public recipientName: string;
    public recipientEmail: string;
    public recipientIsMe: boolean;
    public requestorName: string;
    public requestorEmail: string;
    public statusFr: string;
    public statusEn: string;
    public urgencyFr: string;
    public urgencyEn: string;
    public catalogPathFR: string;
    public catalogPathEN: string;
    public isFavorite: boolean = false;
    public isRead: boolean = false;
    public isChecked: boolean = false;
    public urgencyClass: string;
    public typeClass: string;
    public status: LocalizedText;
    public urgency: LocalizedText;
    public catalogPath: string;
    public statusMyAntemetAGuid: string;
    public waitingValidation: boolean;
    public waitingComplement: boolean;
    public waitingValidationUI: boolean;
    public waitingComplementUI: boolean;
    public forceUnreadUI: boolean;
    public isClosed: boolean;
    public statisfaction: number;
    public isValidating: boolean = false;
    public resolution: string;
    public comment: string;
    public useCaseTypeId!: number;
    public useCaseType!: LocalizedText;
    public useCaseCode!: string;
    public useCase!: LocalizedText;
    public availableField3: string;
    public percentSla: number;
    public percentSlaMaximum: number;
    public slaStatus: SlaStatus;
    public slaForeColor: string;
    public slaStatusIconClassNames: string;
    public entityName: string;
}

export type SimplifiedRequest1 = Pick<Request,
'rfcNumber'
| 'type'
| 'submitDateUtc'
| 'title'
| 'urgencyId'
| 'statusId'
| 'metaStatusGuid'
| 'statusMyAntemetAGuid'
| 'urgencyClass'
| 'typeClass'
| 'useCaseTypeId'
| 'useCaseType'
| 'useCaseCode'
| 'useCase'
| 'waitingValidation'
| 'waitingComplement'
| 'waitingValidationUI'
| 'waitingComplementUI'
| 'availableField3'
| 'entityName'
>;

export class SimplifiedRequest implements SimplifiedRequest1
{
    public rfcNumber: string;
    public type: string;
    public typeClass: string;
    public submitDateUtc: DateTime;
    public title: string;
    public urgencyId: number;
    public urgency: LocalizedText;
    public urgencyClass: string;
    public statusId: number;
    public metaStatusGuid: string;
    public status: LocalizedText;
    public statusMyAntemetAGuid: string;
    public catalogPath: LocalizedText;
    public useCaseTypeId!: number;
    public useCaseType!: LocalizedText;
    public useCaseCode!: string;
    public useCase!: LocalizedText;
    public color: string;
    public waitingValidation: boolean;
    public waitingComplement: boolean;
    public waitingValidationUI: boolean;
    public waitingComplementUI: boolean;
    public availableField3: string;
    public entityName: string;
}

export class DomainRequest extends Request
{
    public static mapperFromApi (source: RequestApi, language: string): Request
    {
        const target = new Request();

        Object.assign(target, source);

        target.statusEn = source.statusEN;
        target.statusFr = source.statusFR;
        target.status = {
            'en-US': source.statusEN,
            'fr-FR': source.statusFR
        };

        target.metaStatusGuid = source.metaStatusGuid?.toUpperCase();
        target.urgencyEn = source.urgencyEN;
        target.urgencyFr = source.urgencyFR;
        target.urgency = {
            'en-US': source.urgencyEN,
            'fr-FR': source.urgencyFR
        };

        target.urgencyClass = `its2-urgency-${target.urgencyId}`;

        target.typeClass = DomainRequest.calcTypeClass(target.type);

        target.submitDateUtc = DateTime.fromISO(source.submitDateUtc);
        target.maxResolutionDateUtc = DateTime.fromISO(source.maxResolutionDateUtc);

        target.catalogPath = language === 'fr-FR' ? target.catalogPathFR : target.catalogPathEN;

        target.statusMyAntemetAGuid = target.statusMyAntemetAGuid?.toUpperCase();
        // Waiting status
        target.waitingValidationUI = target.statusMyAntemetAGuid === WaitingStatus.WaitingValidation;
        target.waitingComplementUI = target.statusMyAntemetAGuid === WaitingStatus.WaitingComplement;
        target.waitingValidation = target.recipientIsMe && target.waitingValidationUI;
        target.waitingComplement = target.recipientIsMe && target.waitingComplementUI;
        target.forceUnreadUI = target.waitingValidationUI || target.waitingComplementUI;
        target.isClosed = target.statusId === RequestStatusIdEnum.CLOSED;

        target.useCaseType = {
            'en-US': source.useCaseTypeEN,
            'fr-FR': source.useCaseTypeFR
        };
        target.useCase = {
            'en-US': source.useCaseEN,
            'fr-FR': source.useCaseFR
        };

        if (!UtilsService.isNullOrUndefined(source.percentSla))
        {
            target.percentSlaMaximum = source.percentSla > Its2Constants.MaximumSlaValue ? Its2Constants.MaximumSlaValue : source.percentSla;
            target.slaStatus = Its2Constants.getSlaStatus(source.percentSla);
            target.slaStatusIconClassNames = Its2Constants.getSlaStatusIcon(target.slaStatus);
            target.slaForeColor = Its2Constants.getSlaForeColor(target.slaStatus);
        }

        return target;
    }

    public static calcTypeClass (rfcNumber: string): string
    {
        switch (rfcNumber.toUpperCase())
        {
            case 'C':
                return 'fal fa-stack-1x fa-random';
            case 'I':
                return 'fal fa-stack-1x fa-bolt';
            case 'S':
                return 'fal fa-stack-1x fa-wrench';
            case 'P':
                return 'fal fa-stack-1x fa-exclamation-triangle';
            default:
                break;
        }
    }
}


export class DomainSimplifiedRequest extends SimplifiedRequest
{
    public static mapperFromApi (source: SimplifiedRequestApi): SimplifiedRequest
    {
        const target = new SimplifiedRequest();

        target.rfcNumber = source.rfcNumber;

        target.type = source.type;
        target.typeClass = DomainRequest.calcTypeClass(target.type);

        target.submitDateUtc = DateTime.fromISO(source.submitDateUtc);
        target.title = source.title;

        target.urgencyId = source.urgencyId;
        target.urgency = new LocalizedText(source.urgencyEN, source.urgencyFR);
        target.urgencyClass = `its2-urgency-${target.urgencyId}`;

        target.statusId = source.statusId;
        target.metaStatusGuid = source.metaStatusGuid?.toUpperCase();
        target.status = new LocalizedText(source.statusEN, source.statusFR);
        target.statusMyAntemetAGuid = source.statusMyAntemetAGuid?.toUpperCase();

        target.catalogPath = new LocalizedText(source.catalogPathEN, source.catalogPathFR);

        target.availableField3 = source.availableField3;

        if (UtilsService.isNullOrUndefined(source.useCaseCode))
        {
            target.useCaseCode = source.availableField3;
            target.useCaseTypeId = 0;
            target.useCase = {
                'en-US': UNCATEGORIZED.EN,
                'fr-FR': UNCATEGORIZED.FR
            };
            target.useCaseType = {
                'en-US': UNCATEGORIZED.EN,
                'fr-FR': UNCATEGORIZED.FR
            };
        }
        else
        {
            target.useCaseTypeId = source.useCaseTypeId;
            target.useCaseType = {
                'en-US': source.useCaseTypeEN,
                'fr-FR': source.useCaseTypeFR
            };
            target.useCaseCode = source.useCaseCode;
            target.useCase = {
                'en-US': source.useCaseEN,
                'fr-FR': source.useCaseFR
            };
        }
        // Waiting status
        target.waitingValidationUI = target.statusMyAntemetAGuid === WaitingStatus.WaitingValidation;
        target.waitingComplementUI = target.statusMyAntemetAGuid === WaitingStatus.WaitingComplement;

        return target;
    }
}


