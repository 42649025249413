import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseListApiService } from '@common/api/base-list-api.service';
import { QueryString } from '@common/api/qs/query-string';

import { AppConstants } from '../../app.constants';

@Injectable({
    providedIn: 'root'
})
export class NamesListApiService extends BaseListApiService<string>
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlHist;
        this.resourceUrl = '/hist/netbackup/names';
    }

    /**
     * @param range Chaîne au format since=YYY-mm-ddThh:mm:ss&until=YYY-mm-ddThh:mm:ss
     * @returns {string[]} Liste des types de politiques sans NBU_Catalog
     */
    getImagesPoliciesWithoutNbu (since: string, until: string): Observable<string[]>
    {
        const queryString = new QueryString();

        queryString.pushValue('since', since);
        queryString.pushValue('until', until);
        queryString.pushValue('$top', 9000);
        queryString.pushValue('$orderBy', 'policytype');
        queryString.pushValue('terms', 'policyType:!NBU_Catalog');
        const endpoint = `/images/policyType?${queryString.qs}`;

        return this.getNames(endpoint);
    }

    /**
     * @param range Chaîne au format since=YYY-mm-ddThh:mm:ss&until=YYY-mm-ddThh:mm:ss
     * @returns {string[]} Liste des serveurs
     */
    getImagesServers (since: string, until: string): Observable<string[]>
    {
        if (AppConstants.IsDataLocal('bckp'))
        {
            return this.http.get<string[]>('assets/data/bckp/serverShort.json')
                .pipe(
                    map((obj: any): string[] =>
                        obj.value
                    )
                );
        }
        const queryString = new QueryString();

        queryString.pushValue('since', since);
        queryString.pushValue('until', until);
        queryString.pushValue('$top', 9000);
        queryString.pushValue('$orderBy', 'serverShort');

        const endpoint = `/images/serverShort${queryString.qs}`;

        return this.getNames(endpoint);
    }

    getJobsServers (): Observable<string[]>
    {
        const endpoint = '/jobs/server?$top=9000&$orderby=server';

        return this.getNames(endpoint);
    }

    getJobsServerShorts (): Observable<string[]>
    {
        const endpoint = '/images/serverShort?$top=9000&$orderby=serverShort';

        return this.getNames(endpoint);
    }

    getJobsMasters (): Observable<string[]>
    {
        const endpoint = '/jobs/master?$top=9000&$orderby=master';

        return this.getNames(endpoint);
    }

    getJobsPolicies (): Observable<string[]>
    {
        const endpoint = '/jobs/policy?$top=9000&$orderby=policy';

        return this.getNames(endpoint);
    }

    getJobsPolicyTypes (): Observable<string[]>
    {
        const endpoint = '/jobs/policytype?$top=9000&$orderby=policytype';
        return this.getNames(endpoint);
    }

    getCopiesServers (): Observable<string[]>
    {
        const endpoint = '/copies/server?$top=9000&$orderby=server';

        return this.getNames(endpoint);
    }


    getCopiesPolicyNames (): Observable<string[]>
    {
        const endpoint = '/copies/policyName?$top=9000&$orderby=policyName';

        return this.getNames(endpoint);
    }

    getcopiesResidences (): Observable<string[]>
    {
        const endpoint = '/copies/residence?$top=9000&$orderby=residence';

        return this.getNames(endpoint);
    }

    getCopiesStorageLifecyclePolicy (): Observable<string[]>
    {
        const endpoint = '/copies/StorageLifecyclePolicy?$top=9000&$orderby=StorageLifecyclePolicy';

        return this.getNames(endpoint);
    }

    private getNames (endpoint: string): Observable<string[]>
    {
        return this.getAll(`${this.resourceUrl}${endpoint}`)
            .pipe(
                map((obj: any): string[] =>
                    obj.value
                )
            );
    }
}
