import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppConstants } from '@apps/app.constants';
import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { ResultApi } from '@common/api/result-api';
import { BckpConstants } from '../bckp.constants';
import { CopyEvolutionApi } from './models/copy-evolution-api';

@Injectable({
    providedIn: 'root'
})
export class CopyMiscApiService extends BaseMiscApiService
{
    constructor (
        http: HttpClient
    )
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlHist;
        this.segmentApi = `/${BckpConstants.BaseApi}/netbackup/copies`;
    }

    getCopiesEvolutionPerMSDP (qs: string): Observable<ResultApi<CopyEvolutionApi>>
    {
        if (AppConstants.IsDataLocal('bckp'))
        {
            return this.http.get<ResultApi<CopyEvolutionApi>>('assets/data/bckp/copiesEvolPerMsdp2.json');
        }

        // https://api-staging.antemeta.fr/v2/hist/netbackup/copies/evolutionpermsdp?since=2021-05-05T00:00:00Z&until=2021-05-12T23:59:59Z
        return this.get(`/evolutionpermsdp${qs}`);
    }
}
