import { ShortProfileApi } from '@apps/auth/api/models';

export class ShortProfile
{
    public email: string;
    public firstLastName: string;
    public firstName: string;
    public id: string;
    public identiconDataUrl: string;
    public language: string;
    public lastName: string;
    public lastFirstName: string;
    public userName: string;
    public phoneNumber: string;
    public mobile: string;

    /**
     * Computed properties
     */
    public displayName: string;
    public mailto: string;
    public telto: string;
    public displayPhoneNumber: string;

    public static mapperFromApi (source: ShortProfileApi): ShortProfile
    {
        const target = new ShortProfile();

        Object.assign(target, source);

        target.userName = source.userName.toUpperCase();
        target.mailto = `mailto:${source.email}`;
        target.displayPhoneNumber = source.phoneNumber ?? source.mobile;
        target.telto = `tel:${target.displayPhoneNumber}`;
        target.displayName = source.firstLastName ?? source.userName;

        return target;
    }
}
