import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { AppConstants } from '@apps/app.constants';

import { RequestStatusesApi } from './models';

@Injectable({
    providedIn: 'root'
})
export class RequestStatusesApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlIts2;
        this.segmentApi = '/itsm/users';
    }

    retrieveStatuses (): Observable<RequestStatusesApi>
    {
        return this.get<RequestStatusesApi>();
    }

    addToFavorites (rfcNumbers: string[]): Observable<string[]>
    {
        return this.post<any>('/favorites/add', { rfcNumbers: rfcNumbers })
            .pipe(
                map((result: RequestStatusesApi) =>
                    result.favorites
                )
            );
    }

    removeFromFavorites (rfcNumbers: string[]): Observable<string[]>
    {
        return this.post<any>('/favorites/remove', { rfcNumbers: rfcNumbers })
            .pipe(
                map((result: RequestStatusesApi) =>
                    result.favorites
                )
            );
    }

    addToRead (rfcNumbers: string[]): Observable<string[]>
    {
        return this.post<any>('/read/add', { rfcNumbers: rfcNumbers })
            .pipe(
                map((result: RequestStatusesApi) =>
                    result.read
                )
            );
    }

    removeFromRead (rfcNumbers: string[]): Observable<string[]>
    {
        return this.post<any>('/read/remove', { rfcNumbers: rfcNumbers })
            .pipe(
                map((result: RequestStatusesApi) =>
                    result.read
                )
            );
    }
}
