import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

import { DomService } from './dom.service';
import { IModalConfig } from './modal-config';

@Injectable({
    providedIn: 'root'
})
export class ModalService
{
    private modalElementId = 'modal-container';
    private overlayElementId = 'modal-overlay';
    public config: IModalConfig;
    private subject: BehaviorSubject<boolean>;
    private observer: Observable<boolean>;

    constructor(private domService: DomService)
    {
    }

    init (component: any, config: IModalConfig): Observable<boolean>
    {
        this.subject = new BehaviorSubject<boolean>(null);
        this.observer = this.subject.asObservable();
        this.config = config;
        this.domService.appendComponentTo(this.modalElementId, component, config);
        document.getElementById(this.modalElementId).className = 'show';
        document.getElementById(this.overlayElementId).className = 'show';

        return this.observer;
    }

    destroy (dialogResult: boolean): void
    {
        this.domService.removeComponent();
        document.getElementById(this.modalElementId).className = 'hidden';
        document.getElementById(this.overlayElementId).className = 'hidden';

        this.subject.next(dialogResult);
    }
}
