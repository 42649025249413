import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { StoreCustomFile } from '@common/models/custom-file';

export const ObjsMiscFeatureKey = 'misc';
type FileState = EntityState<StoreCustomFile>;
export interface IObjsMiscState {
    files: FileState;
}
export const adapterFile = createEntityAdapter<StoreCustomFile>(
    {
        selectId: (cf: StoreCustomFile) => cf.id
    }
);
export const fileInitialState: FileState = adapterFile.getInitialState();

export const ObjsMiscInitialState: IObjsMiscState = {
    files: fileInitialState
};