import { DateTime } from 'luxon';

import { StatusPerDay } from '../../../domain/models/status-per-day';

export class StatusPerRange
{
    public since: DateTime;
    public until: DateTime;
    public expirationDate?: DateTime;
    public statusPerDay: StatusPerDay[];

    constructor ()
    {
        this.expirationDate = null;
    }
}

export class DashStatusPerRange extends StatusPerRange
{
    public isEmpty: boolean = false;
    public isLoading: boolean = true;
    public isLoaded: boolean = false;
    public isOups: boolean = false;

    constructor (since?: DateTime, until?: DateTime, statusPerDay?: StatusPerDay[])
    {
        super();

        this.since = since;
        this.until = until;
        this.statusPerDay = statusPerDay;
    }
}
