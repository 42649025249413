import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DateTime } from 'luxon';

import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';

import * as fromOstkVolumesSnapActions from './snap.actions';
import * as fromOstkProjectsActions from '../../projects/projects.actions';
import { Snapshot } from '../../../domain/models/volumes/snapshot';
import { OstkConstants } from '../../../ostk.constants';
export const OstkVolumesSnapFeatureKey = 'snap';

type IVolumesSnapState = EntityState<Snapshot>;
export const volumesSnapAdapter: EntityAdapter<Snapshot> = createEntityAdapter({
    selectId: (snapshot: Snapshot) => snapshot.id,
    sortComparer: (snapa: Snapshot, snapb: Snapshot) =>
        snapa.name.toLowerCase().localeCompare(snapb.name.toLowerCase())
});
const volumesSnapInitialState: IVolumesSnapState = volumesSnapAdapter.getInitialState({});
export interface IOstkVolumesSnapState {
    snapshots: IVolumesSnapState;
    isEmpty: boolean;
    isLoading: boolean;
    isOups: boolean;
    isLoaded: boolean;
    expirationDate?: DateTime;
    qsWord: string;
}

const initialState: IOstkVolumesSnapState = {
    snapshots: volumesSnapInitialState,
    isEmpty: false,
    isLoading: true,
    isOups: false,
    isLoaded: false,
    expirationDate: null,
    qsWord: ''
};

export const OstkVolumesSnapReducer = createReducer(
    initialState,
    on(
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        fromOstkProjectsActions.OstkProjectSelected,
        () => initialState
    ),
    on(
        fromOstkVolumesSnapActions.OstkVolumesSnapListRequested,
        (state) =>
            ({
                ...state,
                isLoading: !(state.expirationDate !== null && DateTime.utc() < state.expirationDate)
            }),
    ),
    on(
        fromOstkVolumesSnapActions.OstkVolumesSnapListSucceeded,
        (state, { snapshots }) =>
            ({
                ...state,
                isEmpty: snapshots && snapshots.length === 0,
                isLoading: false,
                isOups: false,
                isLoaded: snapshots && snapshots.length !== 0,
                snapshots: volumesSnapAdapter.setAll(snapshots, state.snapshots),
                expirationDate: DateTime.utc().plus({ minutes: OstkConstants.DataLifeTime })
            }),
    ),
    on(
        fromOstkVolumesSnapActions.OstkVolumesSnapListFailed,
        (state) =>
            ({
                ...state,
                isLoading: false,
                isEmpty: false,
                isOups: true,
                isLoaded: false,
                snapshots: volumesSnapAdapter.removeAll(state.snapshots),
                expirationDate: null
            }),
    ),
    on(
        fromOstkVolumesSnapActions.OstkVolumesSnapQsWordSet,
        (state, { qsWord }) =>
            ({
                ...state,
                qsWord: qsWord.toLowerCase()
            })
    ),
    on(
        fromOstkVolumesSnapActions.OstkVolumesSnapDeleteSucceeded,
        (state, { snapshotId }) =>
            (
                {
                    ...state,
                    snapshots: volumesSnapAdapter.removeOne(snapshotId, state.snapshots)
                }
            )
    )
);
