import { createReducer, on } from '@ngrx/store';

import { ImageSnapshot } from '@apps/ostk/domain/models';
import { Image } from '@apps/ostk/domain/models';
import * as fromImagesImgeActions from '../imge/imge.actions';
import * as fromImagesSnapActions from '../snap/snap.actions';


export const OstkImagesSnapFeatureKey = 'snap';

export interface IOstkImagesSnapState
{
    isEmpty: boolean;
    snapshots: ImageSnapshot[];
    qsWord: string;
}

const initialState: IOstkImagesSnapState =
{
    isEmpty: false,
    snapshots: [],
    qsWord: ''
};

export const OstkImagesSnapReducer = createReducer(
    initialState,
    on(
        fromImagesImgeActions.OstkImagesListSucceeded,
        (state: IOstkImagesSnapState, { images }: { images: Image[] }) =>
        {
            const snapshots: ImageSnapshot[] = images
                .filter(i =>
                    i.baseImageRef !== null
                )
                .map(i =>
                    ImageSnapshot.mapperFromImage(i)
                );

            return {
                ...state,
                isEmpty: snapshots.length === 0,
                snapshots: snapshots
            };
        }
    ),
    on(
        fromImagesImgeActions.OstkImagesListFailed,
        (state: IOstkImagesSnapState) => ({
            ...state,
            snapshots: []
        })
    ),
    on(
        fromImagesSnapActions.OstkImagesSnapQsWordSet,
        (state, { qsWord }) =>
            ({
                ...state,
                qsWord: qsWord.toLowerCase()
            })
    ),
    on(
        fromImagesSnapActions.OstkImagesSnapDeleteSucceeded,
        (state, { imageId }) =>
            ({
                ...state,
                snapshots: state.snapshots.filter(s => s.id !== imageId)
            })
    )
);
