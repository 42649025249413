import { DateTime } from 'luxon';

import { DatesService, UtilsService } from '@common/services';

export class PotoRequestsFilter
{
    protected _recipientIsMe?: boolean;
    protected _rangeSince: DateTime;
    protected _rangeUntil: DateTime;
    public urgencyId: number[];
    public type: string | string[];
    public statusId: number[];
    public isFavorite: boolean[];
    public isRead: boolean[];
    public isChecked: boolean;
    public metaStatusGuid: string[];
    public onlySecuRequests: boolean = false;
}
/**
 * Cette classe gère l'état du filtre utilisé par la queryString vers le backend
 */
export class RequestsFilter extends PotoRequestsFilter
{
    get recipientIsMe ()
    {
        return this._recipientIsMe;
    }
    set recipientIsMe (value: boolean | null)
    {
        this._recipientIsMe = value;
    }
    private _since: string;
    get since (): string
    {
        return this._since;
    }
    private _until: string;
    get until (): string
    {
        return this._until;
    }
    get rangeSince (): DateTime
    {
        return this._rangeSince;
    }
    set rangeSince (value: DateTime)
    {
        this._rangeSince = value;
        this._since = DatesService.DateToQueryStringStatic(this._rangeSince);
    }
    get rangeUntil (): DateTime
    {
        return this._rangeUntil;
    }
    set rangeUntil (value: DateTime)
    {
        this._rangeUntil = value;
        this._until = DatesService.DateToQueryStringStatic(this._rangeUntil);
    }

    constructor ()
    {
        super();

        this.initFilter();
    }

    public initFilter ()
    {
        this.recipientIsMe = true;
        this.rangeSince = null;
        this.rangeUntil = null;
        this.urgencyId = [];
        this.type = [];
        this.statusId = [];
        this.isFavorite = [];
        this.isRead = [];
        this.metaStatusGuid = [];
        this.onlySecuRequests = false;
    }

    public static convertFromPoto (source: PotoRequestsFilter, destination?: RequestsFilter): RequestsFilter
    {
        if (UtilsService.isNullOrUndefined(destination))
        {
            destination = new RequestsFilter();
        }

        Object.assign(destination, source);

        return destination;
    }
}
