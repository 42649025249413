import { NgModule } from '@angular/core';

import { ColumnChartModule } from './column-chart/column-chart.module';
import { TimelineChartModule } from './timeline-chart/timeline-chart.module';

@NgModule({
    exports: [
        ColumnChartModule,
        TimelineChartModule
    ]
})
export class LegoChartModule
{ }
