import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { TokenService } from 'src/app/_apps/auth/api/token.service';
import { AppConstants } from 'src/app/_apps/app.constants';

@Injectable()
export class ApplyCredentialsService
{
    private static authSegmentApi: string[] = [
        'login',
        'email/confirmation'
    ];

    constructor(private tokenService: TokenService)
    {
    }

    public static isAuthUrl (req: HttpRequest<any>): boolean
    {
        if (!req.url.includes(AppConstants.UrlApi))
        {
            return true;
        }

        if (req.url.includes('auth') && this.containsAuthSegment(req.url))
        {
            return true;
        }

        return false;
    }

    public static isAuthRefreshUrl (req: HttpRequest<any>): boolean
    {
        if (!req.url.includes(AppConstants.UrlApi))
        {
            return true;
        }

        if (req.url.includes('auth') && req.url.includes('refresh'))
        {
            return true;
        }

        return false;
    }

    private static containsAuthSegment (url: string): boolean
    {
        return ApplyCredentialsService.authSegmentApi
            .reduce(
                (prev, curr) => prev || url.includes(curr),
                false
            );
    }

    public ApplyBearer (req: HttpRequest<any>): HttpRequest<any>
    {
        if (!ApplyCredentialsService.isAuthUrl(req))
        {
            return this.setBearerToken(req, this.tokenService.getAccessToken());
        }

        return req;
    }

    private setBearerToken = (req: HttpRequest<any>, token: string): HttpRequest<any> =>
    {
        return req.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    };
}
