import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import * as fromRootStore from '@apps/root/store';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { CopiesEvolFilter } from '@apps/bckp/models';
import { CopyEvolution } from '@apps/bckp/domain/models';

import * as fromEvolActions from './evol.actions';
import { ODataBckpCopiesEvol } from './odata-bckp-copies-evol';
export const BckpCopiesEvolFeatureKey = 'evolution';

type IEvolCopiesState = EntityState<CopyEvolution>;
export const evolCopiesAdapter: EntityAdapter<CopyEvolution> = createEntityAdapter<CopyEvolution>({
    selectId: (copy: CopyEvolution) => copy.index
});
const evolCopiesInitialState = evolCopiesAdapter.getInitialState({});
export interface IBckpCopiesEvolState
{
    copies: IEvolCopiesState;
    isOups: boolean;
    isLoading: boolean;
    odata: ODataBckpCopiesEvol;
    expirationDate: DateTime;
    isLoaded: boolean;
    isEmpty: boolean;
}

const initialState: IBckpCopiesEvolState =
{
    copies: evolCopiesInitialState,
    isOups: false,
    isLoading: true,
    odata: new ODataBckpCopiesEvol(BckpConstants.QSCopiesTop, new CopiesEvolFilter()),
    expirationDate: null,
    isLoaded: false,
    isEmpty: false
};


export const BckpCopiesEvolReducer = createReducer(
    initialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => initialState
    ),
    on(
        fromEvolActions.BckpCopiesEvolODataRequested,
        (state: IBckpCopiesEvolState) => ({
            ...state,
            isLoading: true,
            isLoaded: false,
            isOups: false,
            isEmpty: false
        }),
    ),
    on(
        fromEvolActions.BckpCopiesEvolODataSucceeded,
        (state: IBckpCopiesEvolState, { copies }: {copies: CopyEvolution[] }) => ({
            ...state,
            copies: evolCopiesAdapter.setAll(copies, state.copies),
            isLoading: false,
            isLoaded: true,
            isOups: false,
            isEmpty: copies && copies.length === 0
        }),
    ),
    on(
        fromEvolActions.BckpCopiesEvolODataFailed,
        (state: IBckpCopiesEvolState) => ({
            ...state,
            copies: evolCopiesAdapter.removeAll(state.copies),
            isLoading: false,
            isLoaded: false,
            isOups: true,
            isEmpty: false
        }),
    ),
    on(
        fromEvolActions.BckpCopiesEvolODataInitialized,
        (state: IBckpCopiesEvolState, { scrollSize, filter }) => ({
            ...state,
            odata: new ODataBckpCopiesEvol(scrollSize, filter)
        })
    ),
    on(
        fromEvolActions.BckpCopiesEvolCleared,
        (state: IBckpCopiesEvolState) => ({
            ...state,
            copies: evolCopiesAdapter.removeAll(state.copies)
        })
    )
);
