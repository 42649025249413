import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseListApiService } from '../../../_common/api/base-list-api.service';
import { ResultApi } from 'src/app/_common/api/result-api';
import { SuccessRateApi } from './models/successrate-api';
import { AppConstants } from '../../app.constants';

@Injectable({
    providedIn: 'root'
})
export class SuccessRateListApiService extends BaseListApiService<SuccessRateApi>
{
    private endPoint = 'successrates';

    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlHist;
        this.resourceUrl = '/hist/netbackup/';
    }

    getSuccessRates (range: string): Observable<ResultApi<SuccessRateApi>>
    {
        if (AppConstants.IsDataLocal('bckp'))
        {
            return this.http.get<ResultApi<SuccessRateApi>>('assets/data/bckp/successrates.json');
        }

        return this.getAll(`${this.resourceUrl}${this.endPoint}?${range}`);
    }
}
