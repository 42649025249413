import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { DateTime } from 'luxon';

import * as fromOstkSecuritySgrpActions from './sgrp.actions';
import * as fromOstkProjectsActions from '../../projects/projects.actions';
import * as fromAuthLoginActions from 'src/app/_apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from 'src/app/_apps/auth/store/profile/profile.actions';
import { SecurityGroup } from '../../../domain/models';
import { OstkConstants } from '../../../ostk.constants';

export const OstkSecuritySgrpFeatureKey = 'sgrp';

type ISecuritySgrpState = EntityState<SecurityGroup>;
export const OstkSecuritySgrpAdapter: EntityAdapter<SecurityGroup> = createEntityAdapter({
    selectId: (securityGroup: SecurityGroup) => securityGroup.id,
    sortComparer: (sgrpa: SecurityGroup, sgrpb: SecurityGroup) =>
        sgrpa.name.toLowerCase().localeCompare(sgrpb.name.toLowerCase())
});
const securitySgrpInitialState: ISecuritySgrpState = OstkSecuritySgrpAdapter.getInitialState({});
export interface IOstkSecuritySgrpState
{
    securityGroups: ISecuritySgrpState;
    isEmpty: boolean;
    isLoading: boolean;
    isOups: boolean;
    isLoaded: boolean;
    expirationDate?: DateTime;
    qsWord: string;
    selectedSecurityGroup: SecurityGroup;
    securityRulesIsLoaded: boolean;
}

export const OstkSecuritySgrpInitialState: IOstkSecuritySgrpState = {
    securityGroups: securitySgrpInitialState,
    isEmpty: false,
    isLoading: true,
    isOups: false,
    isLoaded: false,
    expirationDate: null,
    qsWord: '',
    selectedSecurityGroup: null,
    securityRulesIsLoaded: false
};

export const OstkSecuritySgrpReducer = createReducer(
    OstkSecuritySgrpInitialState,
    on(
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        fromOstkProjectsActions.OstkProjectSelected,
        () => OstkSecuritySgrpInitialState
    ),
    on(
        fromOstkSecuritySgrpActions.OstkSecuritySgrpListRequested,
        (state) => ({
            ...state,
            isLoading: !(state.expirationDate !== null && DateTime.utc() < state.expirationDate)
        }),
    ),
    on(
        fromOstkSecuritySgrpActions.OstkSecuritySgrpListSucceeded,
        (state, {securityGroups}) => ({
            ...state,
            isEmpty: securityGroups && securityGroups.length === 0,
            isLoading: false,
            isOups: false,
            isLoaded: true,
            securityGroups: OstkSecuritySgrpAdapter.setAll(securityGroups, state.securityGroups),
            expirationDate: DateTime.utc().plus({ minutes: OstkConstants.DataLifeTime })
        })
    ),
    on(
        fromOstkSecuritySgrpActions.OstkSecuritySgrpListFailed,
        (state) => ({
            ...state,
            isLoading: false,
            isEmpty: false,
            isOups: true,
            isLoaded: false,
            securityGroups: OstkSecuritySgrpAdapter.removeAll(state.securityGroups),
            expirationDate: null
        }),
    ),
    on(
        fromOstkSecuritySgrpActions.OstkSecuritySgrpQsWordSet,
        (state, { qsWord }) => ({
            ...state,
            qsWord: qsWord.toLowerCase()
        })
    ),
    on(
        fromOstkSecuritySgrpActions.OstkSecuritySgrpDetailsSucceeded,
        (state, { securityGroup: selectedSecurityGroup }) => ({
            ...state,
            selectedSecurityGroup: selectedSecurityGroup,
            securityRulesIsLoaded: true
        })
    ),
    on(
        fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleSelected,
        (state, { securityRule: selectedSecurityRule }) => ({
            ...state,
            selectedSecurityRule: selectedSecurityRule
        })
    ),
    on(
        fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleDeleteSucceeded,
        (state, { securityRuleId }) =>
        {
            const securityGroup = state.securityGroups.entities[state.selectedSecurityGroup.id];
            securityGroup.rules = securityGroup.rules.filter(r => r.id !== securityRuleId);

            return {
                ...state,
                securityGroups: OstkSecuritySgrpAdapter.updateOne(
                    {
                        id: securityGroup.id,
                        changes: { ...securityGroup }
                    } as Update<SecurityGroup>,
                    state.securityGroups
                ),
                selectedSecurityGroup: securityGroup
            };
        }
    ),
    on(
        fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleCreateSucceeded,
        (state, { securityRule }) =>
        {
            const securityGroup: SecurityGroup = state.securityGroups.entities[state.selectedSecurityGroup.id];
            securityGroup.rules.push(securityRule);

            return {
                ...state,
                securityGroups: OstkSecuritySgrpAdapter.updateOne(
                    {
                        id: securityGroup.id,
                        changes: { ...securityGroup }
                    } as Update<SecurityGroup>,
                    state.securityGroups
                ),
                selectedSecurityGroup: securityGroup
            };
        }
    ),
    on(
        fromOstkSecuritySgrpActions.OstkSecuritySgrpCreateSucceeded,
        (state, { securityGroup }) => ({
            ...state,
            securityGroups: OstkSecuritySgrpAdapter.addOne(
                securityGroup,
                state.securityGroups
            )
        })
    ),
    on(
        fromOstkSecuritySgrpActions.OstkSecuritySgrpDeleteSucceeded,
        (state, { securityGroupId }) => ({
            ...state,
            securityGroups: OstkSecuritySgrpAdapter.removeOne(securityGroupId, state.securityGroups)
        })
    )
);
