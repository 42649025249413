import { createAction, props } from '@ngrx/store';

import { Site } from '../../../priv/models/site';
import { Profile } from '../../models/profile';
import { ApplicationRole } from '../../models';
import { ChangePassword } from '../../api/models';

export const AuthLoginProfileLoading = createAction(
    '[AUTH-PROFILE] Auth Login Profile Loading'
);

export const AuthLoginProfileLoaded = createAction(
    '[AUTH-PROFILE] Auth Login Profile Loaded',
    props<{ profile: Profile }>()
);

export const AuthProfileLoaded = createAction(
    '[AUTH-PROFILE] Auth Profile Loaded',
    props<{ profile: Profile }>()
);

export const AuthProfileStatus = createAction(
    '[AUTH-LOGIN] Auth Profile Status',
    props<{ completed: boolean }>()
);

export const AuthProfileAvatarChange = createAction(
    '[AUTH-PROFILE] Avatar change',
    props<{ avatarDataUrl: string }>()
);

export const AuthProfileAvatarChangeCanceled = createAction(
    '[AUTH-PROFILE] Avatar change canceled'
);

export const AuthProfileInformationsChanged = createAction(
    '[AUTH-PROFILE] Auth Profile Informations Changed',
    props<{ profile: Profile }>()
);

export const AuthProfileStep1Changed = createAction(
    '[AUTH-PROFILE] Auth Profile Step1 Changed',
    props<{ profile: Profile }>()
);

// export const AuthProfileStep1Continued = createAction(
//     '[AUTH-PROFILE] Auth Profile Step1 Continued'
// );

export const AuthProfileStep2Changed = createAction(
    '[AUTH-PROFILE] Auth Profile Step2 Changed',
    props<{ profile: Profile }>()
);

// export const AuthProfileStep2Continued = createAction(
//     '[AUTH-PROFILE] Auth Profile Step2 Continued'
// );

export const AuthProfileStep3Changed = createAction(
    '[AUTH-PROFILE] Auth Profile Step3 Changed',
    props<{ oldPswd: string, newPswd: string, verifyPswd: string }>()
);

// export const AuthProfileStep3Continued = createAction(
//     '[AUTH-PROFILE] Auth Profile Step3 Continued'
// );

export const AuthProfileStep4Changed = createAction(
    '[AUTH-PROFILE] Auth Profile Step4 Changed',
    props<{ profile: Profile }>()
);

// export const AuthProfileStep4Continued = createAction(
//     '[AUTH-PROFILE] Auth Profile Step4 Continued'
// );

export const AuthProfileStepSucceeded = createAction(
    '[AUTH-PROFILE] Auth Profile Step Succeeded'
);

export const AuthProfileStepFailed = createAction(
    '[AUTH-PROFILE] Auth Profile Step Failed'
);

export const AuthProfileSecurityTwofactorChanged = createAction(
    '[AUTH-PROFILE] Auth Profile Security TwoFactor Changed',
    props<{ profile: Profile }>()
);

export const AuthProfileLanguageChanged = createAction(
    '[AUTH-PROFILE] Auth Profile Language Changed',
    props < { profile: Profile }>()
);

export const AuthProfileLanguageSet = createAction(
    '[AUTH-PROFILE] Auth Profile Language Set',
    props<{ language: string }>()
);

export const AuthProfileLanguageLoaded = createAction(
    '[AUTH-PROFILE] Auth Profile Language Loaded'
);

export const AuthProfileSkinChanged = createAction(
    '[AUTH-PROFILE] Auth Profile Skin Changed',
    props<{ profile: Profile }>()
);

export const AuthProfileNewsletterMarketingChanged = createAction(
    '[AUTH-PROFILE] Auth Profile Newsletter Marketing Changed',
    props<{ profile: Profile }>()
);

export const AuthProfileNewsletterNotificationChanged = createAction(
    '[AUTH-PROFILE] Auth Profile Newsletter Notification Changed',
    props<{ profile: Profile }>()
);

export const AuthProfileSocialNetworksCookiesChanged = createAction(
    '[AUTH-PROFILE] Auth Profile Social Networks Cookies Changed',
    props<{ profile: Profile }>()
);

export const AuthProfileStatisticsCookiesChanged = createAction(
    '[AUTH-PROFILE] Auth Profile Statistics Cookies Changed',
    props<{ profile: Profile }>()
);

export const AuthProfileWorkingCookiesChanged = createAction(
    '[AUTH-PROFILE] Auth Profile Working Cookies Changed',
    props<{ profile: Profile }>()
);

export const AuthProfileUpdateSucceeded = createAction(
    '[AUTH-PROFILE] Auth Profile Update Succeeded'
);

export const AuthProfileUpdateFailed = createAction(
    '[AUTH-PROFILE] Auth Profile Update Failed'
);

export const AuthProfileUpdated = createAction(
    '[AUTH-PROFILE] Auth Profile Updated',
    props<{ status: boolean }>()
);

export const AuthProfileStepCanceled = createAction(
    '[AUTH-PROFILE] Auth Profile Step Canceled'
);

export const AuthEntityProfileSiteDeleted = createAction(
    '[AUTH-ENTITY-PROFILE] Site Deleted',
    props<{ site: Site }>()
);

export const AuthProfileApplicationRolesSet = createAction(
    '[AUTH-PROFILE] Auth Profile Application Roles Set',
    props<{ appRoles: ApplicationRole[] }>()
);

export const AuthProfileApplicationRolesReset = createAction(
    '[AUTH-PROFILE] Auth Profile Application Roles Reset'
);

export const AuthProfileChangePasswordRequested = createAction(
    '[AUTH-PROFILE] Auth Profile Change Password Requested',
    props<{ changePassword: ChangePassword }>()
);

export const AuthProfileChangePasswordSucceeded = createAction(
    '[AUTH-PROFILE] Auth Profile Change Password Succeeded'
);

export const AuthProfileChangePasswordFailed = createAction(
    '[AUTH-PROFILE] Auth Profile Change Password Failed'
);

export const AuthProfileIsCatalogSecuAuthorizedRequested = createAction(
    '[AUTH-PROFILE] Auth Profile Is Catalog Secu Authorized Requested'
);
export const AuthProfileIsCatalogSecuAuthorizedSucceeded = createAction(
    '[AUTH-PROFILE] Auth Profile Is Catalog Secu Authorized Succeeded',
    props<{ isCatalogSecuAuthorized: boolean }>()
);
export const AuthProfileIsCatalogSecuAuthorizedFailed = createAction(
    '[AUTH-PROFILE] Auth Profile Is Catalog Secu Authorized Failed'
);
