import { Component, Type } from '@angular/core';

import { PopinModalRef } from '../popin-modal.ref';
import { PopinComponentBase } from '../popin-component-base';

@Component({
    templateUrl: './popin-modal.component.html',
    styleUrls: [
        './popin-modal.component.scss'
    ]
})
export class PopinModalComponent
{
    public content: Type<PopinComponentBase>;

    constructor (private popinModalRef: PopinModalRef)
    {
        this.content = this.popinModalRef.content;
    }
}
