import { Contact } from './contact';
import { StepBase } from './step-base';

export class Step4 extends StepBase
{
    main: Contact = null;
    contacts: Contact[] = [];

    constructor (currentLang: string, step4?: Step4)
    {
        super(currentLang);
        // this.subTitle = this.translateService.instant('ITSM.CREATESERVICEREQUEST.STEPS.STEP4.TAB');

        if (step4)
        {
            Object.assign(this, step4);

            this.main = new Contact(step4.main);
            this.contacts = step4.contacts.map((c: Contact) => new Contact(c));
        }
    }

    toSummary ()
    {
        const summary = [this.main, ...this.contacts]
            .map((contact: Contact) =>
                contact.toSummary()
            )
            .join('');

        return `<ul>${summary}</ul>`;
    }

    toDescription ()
    {
        const description = [this.main, ...this.contacts]
            .map((contact: Contact) =>
                contact.toDescription()
            )
            .join('');

        return `
            ${this.subTitleDescription}
            <div style="color: #0057ff;font-weight: bold;">
                ${description}
            </div>
        `;
    }
}
