import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SelectOption } from 'src/app/_ui/components/form/common';
import { Value } from 'src/app/_apps/rept/domain/models';

export const mapSelectOption = () =>
    (source: Observable<string[]>) =>
        source.pipe(
            map((names: string[]) =>
                names.map((name: string) => new SelectOption(name, name))
            )
        );

export const mapSelectOptionBis = () =>
    (source: Observable<Value[]>) =>
        source.pipe(
            map((names: Value[]) =>
                names.map((v: Value) => new SelectOption(v.value, v.key))
            )
        );
