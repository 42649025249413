export class FieldOptions
{
    constructor (public fieldName: string, public options: string[])
    {
        this.options = options
            .sort((stra: string, strb: string) =>
                stra.toUpperCase().localeCompare(strb.toUpperCase())
            );
    }
}
