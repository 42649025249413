import { NgModule } from '@angular/core';

import { DonutModule } from './donut/donut.module';
import { Donut2Module } from './donut2/donut2.module';
import { GaugeModule } from './gauge/gauge.module';
import { GaugeBarModule } from './gauge-bar/gauge-bar.module';
import { TimelineRawModule } from './timeline-raw/timeline-raw.module';

@NgModule({
    exports: [
        DonutModule,
        Donut2Module,
        GaugeModule,
        GaugeBarModule,
        TimelineRawModule
    ]
})
export class RawD3ChartModule
{ }
