import { RangeKey } from 'src/app/_common/enum';
import { RangeFilter } from '../../../_common/filters/range-filter';

export class PoliciesFilter extends RangeFilter
{
    public master: string;
    public serverShort: string;
    public policyType: string;
    public policyStatus: number[];
    constructor()
    {
        super();

        this.initFilter();
    }

    public static mapperFromObject (source: {}): PoliciesFilter
    {
        const target = new PoliciesFilter();

        Object.assign(target, source);

        return target;
    }

    public initFilter ()
    {
        const { since, until } = this.datesService.preRangeDates(RangeKey.CURRENT7DAYS);

        this.rangeSince = since;
        this.rangeUntil = until;
    }
}
