import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform
{
    constructor (private domSanitizer: DomSanitizer)
    {
    }

    transform (value: string)
    {
        const sanitizedContent: string = this.domSanitizer.sanitize(SecurityContext.HTML, value);

        return this.domSanitizer.bypassSecurityTrustHtml(sanitizedContent);
    }
}
