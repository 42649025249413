import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ImageMiscApiService } from '../../api';
import { UploadImageViewModel } from '@apps/ostk/models/image/upload-image-view-model';
import { Image } from '../models';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class ImageMiscDomainService
{
    constructor (
        private imageMiscApiService: ImageMiscApiService
    )
    {
    }

    uploadImage (image: UploadImageViewModel): Observable<boolean>
    {
        return this.imageMiscApiService.uploadImage(image);
    }

    deleteImage (image: Image): Observable<string>
    {
        return this.imageMiscApiService.deleteImage(image.id);
    }
}
