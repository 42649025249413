<div class="container" [@showHide]="(isShown | async)" *ngIf="alert | async as alert">
    <div class="content" [innerHTML]="alert.text[languageService.language]">
    </div>
    <div class="h4 alert-close">
        <span class="fa-stack fa-sm" (click)="onClose(alert.id)">
            <i class="fas fa-stack-2x fa-circle" aria-hidden="true"></i>
            <i class="fal fa-stack-1x fa-times" aria-hidden="true"></i>
        </span>
    </div>
</div>
