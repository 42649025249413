export class UpsertAssetViewModel
{
    typeName: string;
    assetName: string;
    tagIds: string[];

    constructor(typeName: string, name: string, tagId: string)
    {
        this.typeName = typeName;
        this.assetName = name;
        this.tagIds = [tagId];
    }
}
