export enum OpenPopinOriginator {
    NO_ROUTE = -1,
    ROOT_SEARCH,
    PRTG_GRAPHIC,
    PRTG_SENSORS,
    COMP_INSTANCES,
    COMP_TOPOLOGY,
    CMDB_TAGS,
    BCKP_POLICIES,
    OSTK_INSTANCES,
    OSTK_VOLUMES,
    OSTK_IMAGES,
    OSTK_IMAGES_SNAP,
    ITS2_REQUESTS,
    BCKP_DASHBOARD,
    DASH_REQUESTS,
    BCKP_SERVERS,
    BCKP_CAPACITIES,
    BCKP_HEATS,
    DASH_PATA,
    PATA_PROJECTS,
    PATA_PLANNING,
    SECU_DASHBOARD
}
