import { createAction, props } from '@ngrx/store';
import { KeyPair } from '../../../domain/models/security/key-pair';

export const OstkSecurityKeypListRequested = createAction(
    '[OSTK-KEYP] Ostk Security Keyp List Requested'
);
export const OstkSecurityKeypListSucceeded = createAction(
    '[OSTK-KEYP] Ostk Security Keyp List Loaded',
    props<{ keyPairs: KeyPair[] }>()
);
export const OstkSecurityKeypListFailed = createAction(
    '[OSTK-KEYP] Ostk Security Keyp List Failed',
    props<{ failure?: any }>()
);
export const OstkSecurityKeypCreationRequested = createAction(
    '[OSTK-KEYP] Ostk Security Keyp Creation Requested',
    props<{ keyPair: KeyPair }>()
);
export const OstkSecurityKeypCreationSucceeded = createAction(
    '[OSTK-KEYP] Ostk Security Keyp Creation Succeeded',
    props<{ keyPair: KeyPair }>()
);
export const OstkSecurityKeypCreationFailed = createAction(
    '[OSTK-KEYP] Ostk Security Keyp Creation Failed',
    props<{ failure?: any }>()
);
export const OstkSecurityKeypDeletionRequested = createAction(
    '[OSTK-KEYP] Ostk Security Keyp Deletion Requested',
    props<{ keyPair: KeyPair }>()
);
export const OstkSecurityKeypDeletionSucceeded = createAction(
    '[OSTK-KEYP] Ostk Security Keyp Deletion Succeeded',
    props<{ keyPair: KeyPair }>()
);
export const OstkSecurityKeypDeletionFailed = createAction(
    '[OSTK-KEYP] Ostk Security Keyp Deletion Failed',
    props<{ failure?: any }>()
);
export const OstkKeyPairsQsWordSet = createAction(
    '[OSTK-KEYP] Ostk Key Pairs QsWord Set',
    props<{ qsWord: string }>()
);
