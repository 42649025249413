import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseListDomainService } from '@common/domain/base-list-domain.service';
import { SearchResult, BackAuthUserSearchResult } from '@common/models/search-result';
import { arrayMapper } from '@common/operators';

import { User, DomainUser } from './models/back/user';
import { UsersListApiService } from '../api/users-list-api.service';
import { UserApi } from '../api/models/back/user-api';

@Injectable({
    providedIn: 'root'
})
export class UsersListService extends BaseListDomainService
{
    constructor (
        private usersListApiService: UsersListApiService
    )
    {
        super();
    }

    getUsers (qs: string): Observable<User[]>
    {
        return this.usersListApiService.getUsers(qs)
            .pipe(
                map((obj: any): UserApi[] =>
                    obj.value
                ),
                arrayMapper(DomainUser.mapperFromApi)
            );
    }

    searchUsers (qs: string): Observable<SearchResult<User>[]>
    {
        return this.usersListApiService.searchUsers(qs)
            .pipe(
                map((obj: any): UserApi[] =>
                    obj.value
                ),
                map((users: UserApi[]) =>
                    users
                        .map(this.searchMapperFromApi, this)
                        .slice(0, 7)
                )
            );
    }

    private searchMapperFromApi (userApi: UserApi): BackAuthUserSearchResult
    {
        return new BackAuthUserSearchResult(DomainUser.mapperFromApi(userApi));
    }
}
