import { createAction, props } from '@ngrx/store';

import { Message } from '../../models/message';
export const AddMessage = createAction(
    '[NOTF-MESG] Notf Message Add',
    props<{ message: Message }>()
);

export const DeleteMessage = createAction(
    '[NOTF-MESG] Notf Message Delete',
    props<{ id: string }>()
);

export const ClearMessages = createAction(
    '[NOTF-MESG] Notf Messages Clear'
);
