import { Injectable } from '@angular/core';

import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, tap, filter, withLatestFrom } from 'rxjs/operators';

import * as fromKeyPairsActions from '@apps/ostk/store/security/keyp/keyp.actions';
import { NotfGravity, NotifType, NotificationService } from '@common/services';
import { IAuroraState } from '@apps/aurora.state';
import { SecurityMiscDomainService } from '@apps/ostk/domain/security/security-misc-domain.service';
import { getSecurityKeypIsDataValid } from '@apps/ostk/store/ostk.reducer';
import { KeyPair } from '@apps/ostk/domain/models';

@Injectable()
export class OstkKeyPairsEffects
{

    constructor(
        private actions: Actions,
        private store: Store<IAuroraState>,
        private securityMiscDomainSevice: SecurityMiscDomainService,
        private notificationService: NotificationService
    )
    { }

    ostkKeyPairsListRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromKeyPairsActions.OstkSecurityKeypListRequested),
                    withLatestFrom(this.store.select(getSecurityKeypIsDataValid)),
                    filter(([, valid]) => !valid),
                    switchMap(() =>
                        this.securityMiscDomainSevice.getKeyPairs()
                            .pipe(
                                map((keyPairs: KeyPair[]) =>
                                    fromKeyPairsActions.OstkSecurityKeypListSucceeded({ keyPairs })
                                ),
                                catchError((failure: any) =>
                                    of(fromKeyPairsActions.OstkSecurityKeypListFailed({ failure: failure }))
                                )
                            )
                    )
                )
    );

    ostkKeyPairsCreationRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromKeyPairsActions.OstkSecurityKeypCreationRequested),
                    switchMap(({ keyPair }) =>
                        this.securityMiscDomainSevice.createKeyPair(keyPair)
                            .pipe(
                                map((kp: KeyPair) =>
                                    fromKeyPairsActions.OstkSecurityKeypCreationSucceeded({ keyPair: kp })
                                ),
                                catchError((failure: any) =>
                                    of(fromKeyPairsActions.OstkSecurityKeypCreationFailed({ failure: failure }))
                                )
                            )
                    )
                )
    );

    ostkSecurityKeypCreationSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromKeyPairsActions.OstkSecurityKeypCreationSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.SECURITY.KEYPAIR.NOTF.ADD.TITLE',
                                'OSTK.SECURITY.KEYPAIR.NOTF.ADD.MSGSUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkKeyPairsCreationFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromKeyPairsActions.OstkSecurityKeypCreationFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.SECURITY.KEYPAIR.NOTF.ADD.TITLE',
                                'OSTK.SECURITY.KEYPAIR.NOTF.ADD.MSGFAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkKeyPairsDeletionRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromKeyPairsActions.OstkSecurityKeypDeletionRequested),
                    switchMap(({ keyPair }) =>
                        this.securityMiscDomainSevice.deleteKeyPair(keyPair)
                            .pipe(
                                map(() =>
                                    fromKeyPairsActions.OstkSecurityKeypDeletionSucceeded({ keyPair })
                                ),
                                catchError((failure: any) =>
                                    of(fromKeyPairsActions.OstkSecurityKeypDeletionFailed({ failure: failure }))
                                )
                            )
                    )
                )
    );

    ostkSecurityKeypDeletionSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromKeyPairsActions.OstkSecurityKeypDeletionSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.SECURITY.KEYPAIR.NOTF.DELETE.TITLE',
                                'OSTK.SECURITY.KEYPAIR.NOTF.DELETE.MSGSUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkKeyPairsDeletionFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromKeyPairsActions.OstkSecurityKeypDeletionFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.SECURITY.KEYPAIR.NOTF.DELETE.TITLE',
                                'OSTK.SECURITY.KEYPAIR.NOTF.DELETE.MSGFAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );
}
