import { VbrRatesApi } from '@apps/bckp/api/models/vbr-rates-api';
import { CapacityApi } from '../../api/models';
import { VboRatesApi } from '@apps/bckp/api/models/vbo-rates-api';
import { VboRepoApi } from '@apps/bckp/api/models/vbo-repo-api';

export class Capacity
{
    public serverShort: string;
    public master: string = '';
    public kbytesTotal: number = 0;
    public kbytesSent: number = 0;
    public numJobs: number = 0;

    public static mapperFromApi (source: CapacityApi): Capacity
    {
        const target = new Capacity();

        Object.assign(target, source);

        return target;
    }

    public static mapperFromVbrRatesApi (vbr: VbrRatesApi): Capacity
    {
        const capacity = new Capacity();

        capacity.serverShort = vbr.vmName;
        capacity.master = '';
        capacity.kbytesTotal = vbr.totalSize;
        capacity.kbytesSent = vbr.maxChargedSize;
        capacity.numJobs = vbr.numVms;

        return capacity;
    }

    public static mapperFromVboRepoApi (vbo: VboRepoApi): Capacity
    {
        const capacity = new Capacity();

        capacity.serverShort = vbo.name;
        //capacity.master = '';
        //capacity.kbytesTotal = vbo.totalSize;
        capacity.kbytesSent = vbo.freeSpaceBytes;
        //capacity.numJobs = vbo.numVms;

        return capacity;
    }
}
