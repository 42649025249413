import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import { IAuroraState } from 'src/app/_apps/aurora.state';
import * as fromAuthStore from 'src/app/_apps/auth/store';

@Component({
    selector: 'aa-profile-step3',
    templateUrl: './profile-step3.component.html',
    styleUrls: [
        '../profile.component.scss',
        './profile-step3.component.scss'
    ]
})
export class ProfileStep3Component implements OnInit, OnDestroy
{
    private destroyator = new Subject();
    public form: UntypedFormGroup;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private store: Store<IAuroraState>
    ) { }

    ngOnInit ()
    {
        this.createForm();
    }

    ngOnDestroy (): void
    {
        this.destroyator.next(null);
        this.destroyator.complete();
    }

    onSubmit = () =>
    {
        if (this.form.invalid)
        {
            for (const control in this.form.controls)
            {
                this.form.controls[control].markAsDirty();
            }

            return;
        }

        const oldPswd: string = this.form.get('oldPassword').value;
        const newPswd: string = this.form.get('newPassword').value;
        const verifyPswd = this.form.get('verifyPassword').value;

        this.store.dispatch(fromAuthStore.AuthProfileStoreActions.AuthProfileStep3Changed({
            oldPswd, newPswd, verifyPswd
        }));
    }

    // onContinue = () =>
    // {
    //     this.store.dispatch(fromAuthStore.AuthProfileStoreActions.AuthProfileStep3Continued());
    // }

    onCancel = () =>
    {
        this.store.dispatch(fromAuthStore.AuthProfileStoreActions.AuthProfileStepCanceled());
    }

    private createForm ()
    {
        this.form = this.formBuilder.group({
            oldPassword: ['', Validators.required],
            newPassword: ['', [Validators.required, Validators.minLength(4)]],
            verifyPassword: ['', [Validators.required]]
            // , [
            // Validators.required,
            // this.verifyPasswordValidator(this.renewPasswordForm)
            // ]
        });
    }
}
