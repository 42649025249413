import { createSelector, createFeatureSelector, Action, combineReducers } from '@ngrx/store';

import { IAuroraState } from '@apps/aurora.state';

import * as fromMessageReducer from './message/message.reducer';
import * as fromAlertReducer from './alert/alert.reducer';
import { Alert } from '../domain/models';

export const NotfFeatureKey = 'notf';

export interface INotfState
{
    [fromMessageReducer.NotfMesgFeatureKey]: fromMessageReducer.INotfMesgState;
    [fromAlertReducer.NotfAlertFeatureKey]: fromAlertReducer.INotfAlertState;
}

export interface IState extends IAuroraState
{
    [NotfFeatureKey]: INotfState;
}

export function NotfReducers (state: INotfState | undefined, action: Action)
{
    return combineReducers({
        [fromMessageReducer.NotfMesgFeatureKey]: fromMessageReducer.NotfMesgReducer,
        [fromAlertReducer.NotfAlertFeatureKey]: fromAlertReducer.NotfAlertReducer
    })(state, action);
}

const getNotfState = createFeatureSelector<INotfState>(
    NotfFeatureKey
);

const getNotfWarnState = createSelector(
    getNotfState,
    (state: INotfState) => state[fromMessageReducer.NotfMesgFeatureKey]
);

const getNotfAlertState = createSelector(
    getNotfState,
    (state: INotfState) => state[fromAlertReducer.NotfAlertFeatureKey]
);

export const {
    selectAll: selectAllMessages
} = fromMessageReducer.adapter.getSelectors(getNotfWarnState);

export const notReadWarning = () => createSelector(
    selectAllMessages,
    (warnings) =>
        warnings.filter(w => !w.isRead)
);

export const counterNotReadWarning = () => createSelector(
    getNotfWarnState,
    (state: fromMessageReducer.INotfMesgState) =>
        state.entities
            .length
);

export const {
    selectAll: selectAllAlert,
    selectTotal: selectTotalAlert
} = fromAlertReducer.adapter.getSelectors(getNotfAlertState);

export const getFirstAlert = createSelector(
    selectAllAlert,
    (alerts: Alert[]): Alert =>
        alerts.length > 0 ? alerts[0] : null
);

// export const getEntitiesExternalIsEndReached = createSelector(
//     getBackAuthState,
//     getTotalExternalEntities,
//     (state: fromAuthReducer.IBackAuthState, nbTotal: number) =>
//         nbTotal >= state.entities.external.counters.total
// );
