import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatNullableValue'
})
export class FormatNullableValuePipe implements PipeTransform
{
    transform (value: any, text = 'N/A'): string
    {
        return value ? value : text;
    }
}

@Pipe({
    name: 'zeroToText'
})
export class ZeroToTextPipe implements PipeTransform
{
    transform (val?: string, text: string = ''): any
    {
        if (parseInt(val) !== 0)
        {
            return val;
        }

        return text;
    }
}