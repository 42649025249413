import { HttpEvent } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { MediaTypes } from '@common/services/files.service';
import { ResultDomain } from '@common/domain/result-domain';
import { EnrichedAsset, FilterTag } from '@apps/cmdb/models';
import { Job, JobDetails } from '../../domain/models';
import { JobsFilter } from '../../models';

export const BckpJobsODataInitialized = createAction(
    '[BCKP-JOBS] Bckp Jobs OData Initialized',
    props<{ scrollSize: number, filter: JobsFilter }>()
);

export const BckpJobsODataRequested = createAction(
    '[BCKP-JOBS] Bckp Jobs OData Requested'
);

export const BckpJobsODataLoaded = createAction(
    '[BCKP-JOBS] Bckp Jobs OData Loaded',
    props<{ dataLoaded: ResultDomain<Job> }>()
);

export const BckpJobsODataFailed = createAction(
    '[BCKP-JOBS] Bckp Jobs OData Failed'
);

export const BckpJobsODataIncremented = createAction(
    '[BCKP-JOBS] Bckp Jobs OData Incremented'
);

export const BckpJobsCleared = createAction(
    '[BCKP-JOBS] Backup Jobs Cleared'
);

export const BckpJobsFilterApplied = createAction(
    '[BCKP-JOBS] Bckp Jobs Filter Applied',
    props<{ filter: JobsFilter }>()
);

export const BckpJobsExportRequested = createAction(
    '[BCKP-JOBS] Bckp Jobs Export Requested',
    props<{ format: MediaTypes }>()
);

export const BckpJobsExportSucceeded = createAction(
    '[BCKP-JOBS] Bckp Jobs Export Succeeded',
    props<{ response: HttpEvent<Blob> }>()
);

export const BckpJobsRestoreExportRequested = createAction(
    '[BCKP-JOBS] Bckp Jobs Restore Export Requested',
    props<{ format: MediaTypes }>()
);

export const BckpJobsRestoreExportSucceeded = createAction(
    '[BCKP-JOBS] Bckp Jobs Restore Export Succeeded',
    props<{ response: HttpEvent<Blob> }>()
);

export const BckpJobsDetailsRequested = createAction(
    '[BCKP-JOBS] Bckp Job Details Requested',
    props<{ documentId: string }>()
);

export const BckpJobsDetailsSucceeded = createAction(
    '[BCKP-JOBS] Bckp Job Details Succeeded',
    props<{ jobDetails: JobDetails }>()
);

export const BckpJobsDetailsSelected = createAction(
    '[BCKP-JOBS] Bckp Job Details Selected',
    props<{ jobDetails: JobDetails }>()
);

export const BckpJobsQsWordSet = createAction(
    '[BCKP-JOBS] Bckp Jobs QsWord Set',
    props<{ qsWord: string }>()
);

export const BckpJobsTagAttachRequested = createAction(
    '[BCKP-JOBS] Bckp Jobs Tag Attach Requested',
    props<{ id: string, name: string, identifier: string, tagId: string }>()
);
export const BckpJobsTagAttachExecuted = createAction(
    '[BCKP-JOBS] Bckp Jobs Tag Attach Executed',
    props<{ enrichedAsset: EnrichedAsset }>()
);
export const BckpJobsTagAttachSucceeded = createAction(
    '[BCKP-JOBS] Bckp Jobs Tag Attach Succeeded',
    props<{ updatedJob: Update<Job> }>()
);

export const BckpJobsTagDetachRequested = createAction(
    '[BCKP-JOBS] Bckp Jobs Tag Detach Requested',
    props<{ id: string, identifier: string, tagId: string }>()
);
export const BckpJobsTagDetachExecuted = createAction(
    '[BCKP-JOBS] Bckp Jobs Tag Detach Executed',
    props<{ enrichedAsset: EnrichedAsset }>()
);
export const BckpJobsTagDetachSucceeded = createAction(
    '[BCKP-JOBS] Bckp Jobs Tag Detach Succeeded',
    props<{ updatedJob: Update<Job> }>()
);
