import { createAction, props } from '@ngrx/store';

export const OstkImagesSnapQsWordSet = createAction(
    '[OSTK-IMGE] OSTK Images Snap QsWord Set',
    props<{ qsWord: string }>()
);

export const OstkImagesSnapDeleteSucceeded = createAction(
    '[OSTK-IMGE] Ostk Images Snap Delete Succeeded',
    props<{ imageId: string }>()
);
