import { Injector } from '@angular/core';

import { CapacitiesFilter } from '../../models/capacities-filter';
import { DatesService } from 'src/app/_common/services';
import { OData } from 'src/app/_common/api/qs';

export class ODataBckpCapacities extends OData
{
    public datesService: DatesService;
    public filter: CapacitiesFilter; get since ()
    {
        return this.filter.since;
    }
    get until ()
    {
        return this.filter.until;
    }
    public qsExport: string;

    constructor (incrSize: number, filter: CapacitiesFilter, nbItems?: number)
    {
        super(incrSize, nbItems);

        const injector = Injector.create({ providers: [{ provide: DatesService, deps: [] }] });
        this.datesService = injector.get(DatesService);

        this.exportValues = [];
        this.filter = filter;

        this.pushValue('since', filter.since, true);
        this.pushValue('until', filter.until, true);
        this.pushValue('tags', true, true);

        this.qs = this.getQueryString();

        this.qsExport = this.getExportQueryString();
    }

    private getExportQueryString (): string
    {
        const queries: string[] = [];
        let qs: string = '?';

        this.exportValues
            .forEach((kv: { key: string, value: any }) =>
            {
                queries.push(`${kv.key}=${kv.value}`);
            });
        qs += queries.join('&');

        return qs;
    }
}
