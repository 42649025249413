import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, filter, switchMap, map } from 'rxjs/operators';

import { IAuroraState } from '@apps/aurora.state';
import * as fromRootSearchStoreActions from './search.actions';
import * as fromAuthStore from '@apps/auth/store';
import { JobListDomainService, ServerListDomainService } from '@apps/bckp/domain';
import { EntitiesListService, UsersListService } from '@apps/auth/domain/';
import { PolicyListDomainService } from '@apps/bckp/domain/policy-list-domain.service';
import { RequestListDomainService as its2RequestListDomainService } from '@apps/its2/domain/request-list-domain.service';
import { InstanceListDomainService } from '@apps/comp/domain/instance-list-domain.service';
import {
    BckpJobSearchResult,
    BckpServerSearchResult,
    BckpPolicySearchResult,
    CompInstanceSearchResult,
    BackAuthUserSearchResult,
    BackAuthEntitySearchResult,
    Its2RequestSearchResult
} from '@common/models/search-result';
import { CompConstants } from '@apps/comp/comp.constants';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { Its2Constants } from '@apps/its2/its2.constants';

import {
    getBckpJobsSearchQueryString,
    getBckpServersSearchQueryString,
    getBackAuthEntitiesSearchQueryString,
    getBackAuthUsersSearchQueryString,
    getBckpPoliciesSearchQueryString,
    getCompInstancesSearchQueryString,
    getIts2RequestsSearchQueryString
} from '../root.reducer';

@Injectable()
export class RootSearchEffects
{
    constructor (
        private store: Store<IAuroraState>,
        private actions: Actions,
        private jobListDomainService: JobListDomainService,
        private serverListDomainService: ServerListDomainService,
        private its2RequestListDomainService: its2RequestListDomainService,
        private entitiesListService: EntitiesListService,
        private usersListService: UsersListService,
        private policyListDomainService: PolicyListDomainService,
        private instanceListDomainService: InstanceListDomainService
    )
    { }


    its2RequestsResultSearchRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromRootSearchStoreActions.RootSearchRequested),
                withLatestFrom(
                    this.store.select(fromAuthStore.getApiAuthorization, { application: Its2Constants.Application, role: null }),
                    this.store.select(getIts2RequestsSearchQueryString)
                ),
                filter(([, authorized, qsSearch]: [any, boolean, string]) =>
                    authorized && qsSearch.includes('$search')
                ),
                map(([, , qsSearch]: [any, boolean, string]) =>
                    qsSearch
                ),
                switchMap((qsSearch: string) =>
                    this.its2RequestListDomainService.search(qsSearch)
                        .pipe(
                            map((requests: Its2RequestSearchResult[]) =>
                                fromRootSearchStoreActions.RootSearchResultsIts2RequestsLoaded({ result: requests })
                            )
                        )
                )
            )
    );

    bckpJobsResultSearchRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromRootSearchStoreActions.RootSearchRequested),
                withLatestFrom(
                    this.store.select(fromAuthStore.getApiAuthorization, { application: BckpConstants.Application, role: null }),
                    this.store.select(getBckpJobsSearchQueryString)
                ),
                filter(([, authorized, qsSearch]: [any, boolean, string]) =>
                    authorized && qsSearch.includes('$search')
                ),
                map(([, , qsSearch]: [any, boolean, string]) =>
                    qsSearch
                ),
                switchMap((qsSearch: string) =>
                    this.jobListDomainService.search(qsSearch)
                        .pipe(
                            map((jobs: BckpJobSearchResult[]) =>
                                fromRootSearchStoreActions.RootSearchResultsBckpJobsLoaded({ result: jobs })
                            )
                        )
                )
            )
    );

    bckpServersResultSearchRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromRootSearchStoreActions.RootSearchRequested),
                withLatestFrom(
                    this.store.select(fromAuthStore.getApiAuthorization, { application: BckpConstants.Application, role: null }),
                    this.store.select(getBckpServersSearchQueryString)
                ),
                filter(([, authorized, qsSearch]: [any, boolean, string]) =>
                    authorized && qsSearch.includes('$search')
                ),
                map(([, , qsSearch]: [any, boolean, string]) =>
                    qsSearch
                ),
                switchMap((qsSearch: string) =>
                    this.serverListDomainService.search(qsSearch)
                        .pipe(
                            map((servers: BckpServerSearchResult[]) =>
                                fromRootSearchStoreActions.RootSearchResultsBckpServersLoaded({ result: servers })
                            )
                        )
                )
            )
    );

    bckpPoliciesResultSearchRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromRootSearchStoreActions.RootSearchRequested),
                withLatestFrom(
                    this.store.select(fromAuthStore.getApiAuthorization, { application: BckpConstants.Application, role: null }),
                    this.store.select(getBckpPoliciesSearchQueryString)
                ),
                filter(([, authorized, qsSearch]: [any, boolean, string]) =>
                    authorized && qsSearch.includes('$search')
                ),
                map(([, , qsSearch]: [any, boolean, string]) =>
                    qsSearch
                ),
                switchMap((qsSearch: string) =>
                    this.policyListDomainService.search(qsSearch)
                        .pipe(
                            map((policies: BckpPolicySearchResult[]) =>
                                fromRootSearchStoreActions.RootSearchResultsBckpPoliciesLoaded({ result: policies })
                            )
                        )
                )
            )
    );

    compInstancesResultSearchRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromRootSearchStoreActions.RootSearchRequested),
                withLatestFrom(
                    this.store.select(fromAuthStore.getApiAuthorization, { application: CompConstants.Application, role: null }),
                    this.store.select(getCompInstancesSearchQueryString)
                ),
                filter(([, authorized, qsSearch]: [any, boolean, string]) =>
                    authorized && qsSearch.includes('$search')
                ),
                map(([, , qsSearch]: [any, boolean, string]) =>
                    qsSearch
                ),
                switchMap((qsSearch: string) =>
                    this.instanceListDomainService.search(qsSearch)
                        .pipe(
                            map((instances: CompInstanceSearchResult[]) =>
                                fromRootSearchStoreActions.RootSearchResultsCompInstancesLoaded({ result: instances })
                            )
                        )
                )
            )
    );

    backAuthEntitiesResultSearchRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromRootSearchStoreActions.RootSearchRequested),
                withLatestFrom(
                    this.store.select(fromAuthStore.getBackAuthorization),
                    this.store.select(getBackAuthEntitiesSearchQueryString)
                ),
                filter(([, authorized, qsSearch]: [any, boolean, string]) =>
                    authorized && qsSearch.includes('$search')
                ),
                map(([, , qsSearch]: [any, boolean, string]) =>
                    qsSearch
                ),
                switchMap((qsSearch: string) =>
                    this.entitiesListService.searchEntities(qsSearch)
                        .pipe(
                            map((entities: BackAuthEntitySearchResult[]) =>
                                fromRootSearchStoreActions.RootSearchResultsBackAuthEntitiesLoaded({ result: entities })
                            )
                        )
                )
            )
    );

    backAuthUsersResultSearchRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromRootSearchStoreActions.RootSearchRequested),
                withLatestFrom(
                    this.store.select(fromAuthStore.getBackAuthorization),
                    this.store.select(getBackAuthUsersSearchQueryString)
                ),
                filter(([, authorized, qsSearch]: [any, boolean, string]) =>
                    authorized && qsSearch.includes('$search')
                ),
                map(([, , qsSearch]: [any, boolean, string]) =>
                    qsSearch
                ),
                switchMap((qsSearch: string) =>
                    this.usersListService.searchUsers(qsSearch)
                        .pipe(
                            map((users: BackAuthUserSearchResult[]) =>
                                fromRootSearchStoreActions.RootSearchResultsBackAuthUsersLoaded({ result: users })
                            )
                        )
                )
            )
    );
}
