import { createAction, props } from '@ngrx/store';
import { RequestUrgencyCounterByDay } from '@apps/its2/domain/models';

export const Its2DashReportingIncidentsSummariesRequested = createAction(
    '[ITS2-DASH] Its2 Dash Reporting Incidents Summaries Requested'
);
export const Its2DashReportingIncidentsSummariesSucceeded = createAction(
    '[ITS2-DASH] Its2 Dash Reporting Incidents Summaries Succeeded',
    props<{ summaries: RequestUrgencyCounterByDay[] }>()
);
export const Its2DashReportingIncidentsSummariesFailed = createAction(
    '[ITS2-DASH] Its2 Dash Reporting Incidents Summaries Failed',
    props<{ err: any }>()
);

export const Its2DashReportingServicesSummariesRequested = createAction(
    '[ITS2-DASH] Its2 Dash Reporting Services Summaries Requested'
);
export const Its2DashReportingServicesSummariesSucceeded = createAction(
    '[ITS2-DASH] Its2 Dash Reporting Services Summaries Succeeded',
    props<{ summaries: RequestUrgencyCounterByDay[] }>()
);
export const Its2DashReportingServicesSummariesFailed = createAction(
    '[ITS2-DASH] Its2 Dash Reporting Services Summaries Failed',
    props<{ err: any }>()
);
