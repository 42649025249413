import { Injector } from '@angular/core';

import { ODataSearch } from 'src/app/_common/api/qs';
import { AppConstants } from '../../app.constants';
import { DatesService } from 'src/app/_common/services';
import { RangeKey } from 'src/app/_common/enum';

export class ODataBckpServersSearch extends ODataSearch
{
    public datesService: DatesService;

    constructor(searchValue: string)
    {
        super(searchValue, 0);

        const injector = Injector.create({ providers: [{ provide: DatesService, deps: [] }] });
        this.datesService = injector.get(DatesService);
        const range = this.datesService.preRangeDates(RangeKey.CURRENT7DAYS);

        this.pushValue('$count', true);

        this.pushValue('since', this.datesService.DateToQueryString(range.since));
        this.pushValue('until', this.datesService.DateToQueryString(range.until));

        // Options de tri
        this.pushValue('$orderby', 'expiration:asc');

        if (this.searchValue !== null)
        {
            this.searchValue = `${searchValue}*`;
            this.pushValue('$search', this.searchValue);
        } else
        {
            const idx = this.values.findIndex((item: { key: string, value: any }) => item.key === '$search');

            this.values = this.values.splice(idx, 1);
        }
    }
}
