import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { Its2FeatureKey } from '@apps/its2/its2.constants';
import { Its2Reducers } from '@apps/its2/store/its2.reducer';

import { BckpOptionsEffects } from '@apps/bckp/store/common/options/options.effects';
import { BckpFeatureKey } from '@apps/bckp/bckp.constants';
import { BckpReducers } from '@apps/bckp/store/bckp.reducer';

import { Its2RequestsEffects } from './requests/requests.effects';
import { Its2ActionsEffects } from './actions/actions.effects';
import { Its2SelectedEffects } from './selected/selected.effects';
import { Its2DocumentsEffects } from './documents/documents.effects';
import { Its2ValuesEffects } from './values/values.effects';
import { Its2MiscEffects } from './misc/misc.effects';
import { Its2CatalogsEffects } from './catalogs/catalogs.effects';
import { Its2BreakdownsEffects } from './dashboard/breakdown/breakdown.effects';
import { Its2ReportingsEffects } from './dashboard/reporting/reporting.effects';
import { Its2SLAsEffects } from './dashboard/sla/sla.effects';
import { Its2sharedsEffects } from './dashboard/shared/shared.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forFeature([
            Its2RequestsEffects,
            Its2ActionsEffects,
            Its2SelectedEffects,
            Its2DocumentsEffects,
            Its2ValuesEffects,
            Its2MiscEffects,
            Its2CatalogsEffects,
            BckpOptionsEffects,
            Its2BreakdownsEffects,
            Its2ReportingsEffects,
            Its2SLAsEffects,
            Its2sharedsEffects
        ]),
        StoreModule.forFeature(
            Its2FeatureKey,
            Its2Reducers
        ),
        StoreModule.forFeature(
            BckpFeatureKey,
            BckpReducers
        )
    ]
})
export class Its2StoreModule
{ }
