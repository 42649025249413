import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { AppConstants } from '@apps/app.constants';
import { KeyPair } from '@apps/ostk/domain/models';
import { KeyPairApi, SecurityGroupApi } from '@apps/ostk/api/models';
import { CreateSecurityRuleViewModel, SecurityRule } from '@apps/ostk/domain/models/security/security-rule';
import { CreateSecurityGroupViewModel } from '@apps/ostk/domain/models/security/security-group';

@Injectable({
    providedIn: 'root'
})
export class SecurityMiscApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlOstk;
        this.segmentApi = '/ostk';
    }

    getSecurityGroups (): Observable<SecurityGroupApi[]>
    {
        return this.get<SecurityGroupApi[]>('/securitygroups');
    }

    getKeyPairs (): Observable<KeyPairApi[]>
    {
        return this.get<KeyPairApi[]>('/keypairs');
    }

    getDetails (securityGroupId: string): Observable<SecurityGroupApi>
    {
        return this.get<SecurityGroupApi>(`/securitygroups/${securityGroupId}`);
    }

    createSecurityGroup (securityGroup: CreateSecurityGroupViewModel): Observable<SecurityGroupApi>
    {
        return this.post<SecurityGroupApi>('/securitygroups', securityGroup);
    }

    createKeyPair (keyPair: KeyPair): Observable<KeyPairApi>
    {
        return this.post<KeyPairApi>('/keypairs', { name: keyPair.name, publicKey: keyPair.publicKey });
    }

    createSecurityRule (securityRule: CreateSecurityRuleViewModel): Observable<SecurityRule>
    {
        return this.post<SecurityRule>(`/securitygroups/${securityRule.securityGroupId}/rules`, securityRule);
    }

    deleteKeyPair (keyPair: KeyPair): Observable<void>
    {
        return this.delete(`/keypairs/${keyPair.name}`);
    }

    deleteSecurityRule (securityGroupId: string, ruleId: string): Observable<string>
    {
        return this.delete(`/securitygroups/${securityGroupId}/rules/${ruleId}`)
            .pipe(
                map(() => ruleId)
            );
    }

    deleteSecurityGroup (securityGroupId: string): Observable<string>
    {
        return this.delete(`/securitygroups/${securityGroupId}`)
            .pipe(
                map(() => securityGroupId)
            );
    }
}
