import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import * as fromImagesSnapActions from '@apps/ostk/store/images/snap/snap.actions';
import { NotifType } from '@common/enum';
import { NotificationService, NotfGravity } from '@common/services';

@Injectable()
export class OstkImagesSnapEffects
{
    constructor
    (
        private actions: Actions,
        private notificationService: NotificationService
    )
    { }

    ostkImagesSnapDeleteSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromImagesSnapActions.OstkImagesSnapDeleteSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.IMAGE.SNAPSHOT.DELETE.NOTIF.TITLE',
                                'OSTK.IMAGE.SNAPSHOT.DELETE.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );
}
