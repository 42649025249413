import { Action, combineReducers } from '@ngrx/store';

import { IAuroraState } from 'src/app/_apps/aurora.state';
import * as fromNetworksNetwReducer from './netw/netw.reducer';
import * as fromNetworksFipsReducer from './fips/fips.reducer';

export * from './netw/netw.reducer';
export * from './fips/fips.reducer';

export const OstkNetworksFeatureKey = 'networks';

export interface IState extends IAuroraState
{
    [OstkNetworksFeatureKey]: IOstkNetworksState;
}

export function OstkNetworksReducers (state: IOstkNetworksState | undefined, action: Action)
{
    return combineReducers({
        [fromNetworksNetwReducer.OstkNetworksNetwFeatureKey]: fromNetworksNetwReducer.OstkNetworksNetwReducer,
        [fromNetworksFipsReducer.OstkNetwFipsFeatureKey]: fromNetworksFipsReducer.OstkNetwFipsReducer
    })(state, action);
}

export interface IOstkNetworksState
{
    [fromNetworksNetwReducer.OstkNetworksNetwFeatureKey]: fromNetworksNetwReducer.IOstkNetworksNetwState;
    [fromNetworksFipsReducer.OstkNetwFipsFeatureKey]: fromNetworksFipsReducer.IOstkNetworksFipsState;
}

export const OstkNetworksInitialState: IOstkNetworksState = {
    [fromNetworksNetwReducer.OstkNetworksNetwFeatureKey]: null,
    [fromNetworksFipsReducer.OstkNetwFipsFeatureKey]: fromNetworksFipsReducer.OstkNetworksFipsInitialState
};
