import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { concatAll, filter, map, toArray } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { DatesService } from '@common/services';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { ResultApi } from '@common/api/result-api';

import { CopyMiscApiService } from '../api/copy-misc-api.service';
import { CopyEvolutionApi } from '../api/models/copy-evolution-api';
import { CopyEvolution, DomainCopyEvolution, GroupedPeriod, Sample } from './models';

@Injectable({
    providedIn: 'root'
})
export class CopyMiscDomainService extends BaseMiscDomainService
{
    constructor (
        private copyMiscApiService: CopyMiscApiService
    )
    {
        super();
    }

    getCopiesEvolutionPerMSDP (qs: string, since: DateTime, until: DateTime): Observable<CopyEvolution[]>
    {
        return this.copyMiscApiService.getCopiesEvolutionPerMSDP(qs)
            .pipe(
                map((obj: ResultApi<CopyEvolutionApi>): CopyEvolutionApi[] =>
                    obj.value
                ),
                concatAll(),
                filter((c: CopyEvolutionApi) =>
                    !c.residence.includes(BckpConstants.RemoteMaster)
                ),
                map((copyEvolutionApi: CopyEvolutionApi, index: number) =>
                    DomainCopyEvolution.mapperFromApi(copyEvolutionApi, index, since, until)
                ),
                toArray(),
                map(fillSampleGaps)
            );
    }
}

export const fillSampleGaps = ((copies: CopyEvolution[]) =>
{
    const groupedPeriodSet = new Map<string, GroupedPeriod>();

    copies.map((copyEvolution: CopyEvolution) =>
    {
        copyEvolution.groupedSamples.map((s: Sample) =>
        {
            groupedPeriodSet.set(s.groupedPeriod.toISO(), new GroupedPeriod(s.groupedPeriod));
        });
    });

    copies.forEach((copyEvolution: CopyEvolution) =>
    {
        const myGroupedPeriods = copyEvolution.groupedSamples.map((sample: Sample) =>
            sample.groupedPeriodISO
        );

        groupedPeriodSet.forEach((value: GroupedPeriod, key: string) =>
        {
            if (!myGroupedPeriods.includes(key))
            {
                copyEvolution.groupedSamples.push({
                    ...new Sample(),
                    groupedPeriod: DatesService.UtcStringToDateTimeHourStatic(key),
                    groupedPeriodISO: value.groupedPeriodISO,
                    groupedPeriodMillis: value.groupedPeriodMillis,
                    groupedValue: null
                });
            }
        });
        copyEvolution.groupedSamples.sort((gsa, gsb) =>
            gsa.groupedPeriodMillis - gsb.groupedPeriodMillis
        );
    });

    return copies;
});
