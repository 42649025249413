import { createAction, props } from '@ngrx/store';

export const Its2DashSLAIncidentsRequested = createAction(
    '[ITS2-DASH] Its2 Dash SLA Incidents Requested'
);
export const Its2DashSLAIncidentsSucceeded = createAction(
    '[ITS2-DASH] Its2 Dash SLA Incidents Succeeded',
    props<{ sla: number }>()
);
export const Its2DashSLAIncidentsFailed = createAction(
    '[ITS2-DASH] Its2 Dash SLA Incidents Failed',
    props<{ err: any }>()
);

export const Its2DashSLAServicesRequested = createAction(
    '[ITS2-DASH] Its2 Dash SLA Services Requested'
);
export const Its2DashSLAServicesSucceeded = createAction(
    '[ITS2-DASH] Its2 Dash SLA Services Succeeded',
    props<{ sla: number }>()
);
export const Its2DashSLAServicesFailed = createAction(
    '[ITS2-DASH] Its2 Dash SLA Services Failed',
    props<{ err: any }>()
);
