import { DateTime } from 'luxon';

export class TotalCapacity
{
    public since: DateTime;
    public until: DateTime;
    public validUntil?: DateTime;
    public capacity: number;

    constructor ()
    {
        this.validUntil = null;
    }
}
