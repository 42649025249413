import { Injectable } from '@angular/core';

import { of, Observable } from 'rxjs';
import chroma from 'chroma-js';

export type Skin = 'light' | 'dark' | 'prune';

export const enum ColorGamme
{
    Basic = 'basic',
    Message = 'message'
}

export const enum ColorLevel
{
    Normal = 'normal',
    Light = 'light'
}

export const enum ColorName
{
    Primary = 'primary',
    Secondary = 'secondary',
    Black = 'black',
    White = 'white',
    Grey1 = 'grey1',
    Grey2 = 'grey2',
    Success = 'success',
    Danger = 'danger',
    Warning1 = 'warning1',
    Warning2 = 'warning2',
    Blue = 'blue',
    Info = 'info',
    Grey = 'grey',
    Failed = 'failed',
    Partial = 'partial'
}

const basicName: ColorName[] = [
    ColorName.Primary,
    ColorName.Secondary,
    ColorName.Black,
    ColorName.White,
    ColorName.Grey1,
    ColorName.Grey2
];
const messageName: ColorName[] = [
    ColorName.Success,
    ColorName.Danger,
    ColorName.Warning1,
    ColorName.Warning2,
    ColorName.Info,
    ColorName.Grey,
    ColorName.Blue
];

export class JsonStyle
{
    entry: string;
    children?: JsonStyle[];
    value?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ColorsService
{
    public static setSkinColors (jsonColor: any): Observable<boolean>
    {
        const descendants: string[] = [];
        for (const key in jsonColor)
        {
            ColorsService.recurseAndAdd(jsonColor, key, descendants);
        }

        this.ckEditorStyling();

        return of(true);
    }

    public static getBasicColor (level: ColorLevel, name: ColorName)
    {
        if (!basicName.includes(name))
        {
            throw new RangeError();
        }

        return this.getColorValue(ColorGamme.Basic, level, name);
    }

    public static getMessageColor (level: ColorLevel, name: ColorName)
    {
        if (!messageName.includes(name))
        {
            throw new RangeError();
        }

        return this.getColorValue(ColorGamme.Message, level, name);
    }

    public static getBrightenGradientColors (level: ColorLevel, name: ColorName, nb: number): string[]
    {
        const clr: string = ColorsService.getBasicColor(level, name);
        const colors: string[] = [clr];

        for (let index = 1; index < nb; index++)
        {
            colors.push(chroma(clr).brighten(index / 2).hex());
        }

        return colors;
    }

    public static getDarkenGradientColors (level: ColorLevel, name: ColorName, nb: number): string[]
    {
        const color: string = ColorsService.getBasicColor(level, name);
        const clr = chroma(color);
        const colors: string[] = [color];

        for (let index = 1; index < nb; index++)
        {
            colors.push(clr.darken(index / 2).hex());
        }

        return colors;
    }

    public static getBrightenGradientColorsFromString (color: string, nb: number): string[]
    {
        return this.getGradientColorsFromString(color, nb, (c, n): chroma.Color => c.brighten(n));
    }

    public static getDarkenGradientColorsFromString (color: string, nb: number): string[]
    {
        return this.getGradientColorsFromString(color, nb, (c, n): chroma.Color => c.darken(n));
    }

    public static getBrightenColorFromString (color: string, x: number): string
    {
        return chroma(color).brighten(x).hex();
    }

    public static getDarkenColorFromString (color: string, x: number): string
    {
        return chroma(color).darken(x).hex();
    }

    public static getContrastColor (color: string, light: string, dark: string): string
    {
        return chroma(color).luminance() < 0.5 ? light : dark;
    }

    public static ScaleColors (length: number,): string[]
    {
        return chroma.scale(['#0057ff', '#51d46e', '#fe2020', '#9c4aad']).domain([0, length - 1]).colors(length);
    }

    private static getGradientColorsFromString (color: string, nb: number, func: (c: chroma.Color, n: number) => chroma.Color): string[]
    {
        const clr = chroma(color);
        const colors: string[] = [color];

        for (let index = 1; index < nb; index++)
        {
            colors.push(func(clr, index / 2).hex());
        }

        return colors;
    }

    // private static getGradientColorFromString (color: string, x: number, func: (c: chroma.Color, x: number) => chroma.Color): string
    // {
    //     const clr = chroma(color);

    //     return func(clr, x).hex();
    // }

    private static recurseAndAdd (jsonColor: any, entry: string, descendants: string[])
    {
        descendants.push(entry.trim());
        if (typeof jsonColor[entry] === 'object')
        {
            for (const key in jsonColor[entry])
            {
                this.recurseAndAdd(jsonColor[entry], key, descendants);
            }
        }
        else if (typeof jsonColor[entry] === 'string')
        {
            const property: string = `--${descendants.join('-')}`;
            let value = jsonColor[entry].trim();

            if (value)
            {
                if (value === 'transparent' || value === 'opacity')
                {
                    document.documentElement.style.setProperty(property, value);
                }
                else if (value.startsWith('#'))
                {
                    document.documentElement.style.setProperty(property, value);

                    value = chroma(value).rgb().join(',');
                    document.documentElement.style.setProperty(`${property}-rgb`, value);
                }
                else
                {
                    document.documentElement.style.setProperty(property, `var(--color-base-${value})`);
                    document.documentElement.style.setProperty(`${property}-rgb`, `var(--color-base-${value}-rgb)`);
                }
            }
        }

        descendants.pop();
    }

    private static getColorValue (gamme: ColorGamme, level: ColorLevel, name: ColorName, rgb: boolean = false): string
    {
        let colorStyle: string;

        if (name === ColorName.Black || name === ColorName.White)
        {
            colorStyle = `--color-base-${gamme}-${name}`;
        }
        else if (name === ColorName.Grey1 || name === ColorName.Grey2)
        {
            let greyLevel: string;

            switch (level)
            {
                case ColorLevel.Normal:

                    greyLevel = name === ColorName.Grey1 ? 'dark' : 'medium';
                    break;
                case ColorLevel.Light:

                    greyLevel = name === ColorName.Grey1 ? 'light' : 'snow';
                    break;
            }

            colorStyle = `--color-base-basic-grey-${greyLevel}`;
        }
        else
        {
            colorStyle = `--color-base-${gamme}-${name}-${level}`;
        }

        if (rgb)
        {
            colorStyle += '-rgb';
        }

        return getComputedStyle(document.documentElement)
            .getPropertyValue(colorStyle);
    }

    private static ckEditorStyling (): void
    {
        // Style de ckEditor
        // cf https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/ui/theme-customization.html#styles-processing-and-bundling
        document.documentElement.style.setProperty('--ck-custom-border', 'transparent');
        document.documentElement.style.setProperty('--ck-color-focus-border', 'transparent');
        document.documentElement.style.setProperty('--ck-custom-border', '4px');

    }
}
