import { Component, Input, HostListener } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { IAuroraState } from 'src/app/_apps/aurora.state';

@Component({
    selector: 'aa-popin',
    templateUrl: './popin.component.html',
    styleUrls: [
        './popin.component.scss'
    ],
    animations: [
        trigger('showHide', [
            state('show', style({
                transform: 'translateX(-100%)'
            })),
            state('hide', style({
                transform: 'translateX(100%)'
            })),
            transition('show => hide', [
                animate('1s cubic-bezier(0.35, 0, 0.25, 1)')
            ]),
            transition('hide => show', [
                animate('1s cubic-bezier(0.35, 0, 0.25, 1)')
            ])
        ])
    ]
})
export class PopinComponent
{
    @Input() width: number;
    @Input() isShown: boolean = false;
    @Input() onClose: () => {};

    // * [DLu] Ne pas supprimer ces injections, c'est bizarre mais comme cela
    // * Il faut aussi les injecter dans les composants popin-xxx
    constructor(
        public store: Store<IAuroraState>,
        public router: Router
    )
    {
    }

    public closePopin (): void
    {
        this.onClose();
    }

    public calcRight ()
    {
        return (-1 * this.width);
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown (event: KeyboardEvent)
    {
        if (event.key === 'Escape' && this.isShown)
        {
            this.onClose();
        }
    }
}
