import { CatalogEntry } from '@apps/its2/models';

import { StepBase } from './step-base';

export class Step1 extends StepBase
{
    title: string;
    urgency: number;
    catalogEntry: CatalogEntry;

    constructor (
        currentLang: string,
        step1?: Step1
    )
    {
        super(currentLang);
        // this.subTitle = this.translateService.instant('ITSM.CREATESERVICEREQUEST.STEPS.STEP1.TAB');

        if (step1)
        {
            Object.assign(this, step1);
        }
    }

    // toSummary ()
    // {
    //     const serviceLine: string = this.translateService.instant('ITSM.CREATECASEPOPIN.CATEGORY');
    //     return `
    //         <div class="summary-field label">${serviceLine}</div>
    //             <div class="summary-field value">
    //                 ${this.catalogEntry.path }
    //             </div>
    //         </div>
    //     `;
    // }

    // toDescription ()
    // {
    //     const language: string = this.currentLang;
    //     const serviceLine: string = this.translateService.instant('ITSM.CREATECASEPOPIN.CATEGORY');

    //     return `
    //     <div style="color: #0057ff;font-weight: bold;">
    //         ${serviceLine}
    //     </div>
    //     <div style="display:flex">
    //         <div style="white-space:nowrap; color:#8c9faa;flex-basis: 100%;">${ this.catalogEntry.path[language]}</div>
    //     </div>
    //     `;
    // }
}
