import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { SnackbarService } from '@ui/misc/snackbar';
import { IAuroraState } from '@apps/aurora.state';
import { LanguageService, NotfGravity } from '@common/services';

import { ProjectMiscDomainService } from '../../domain';
import { Platform, Project } from '../../domain/models';
import * as fromProjectsActions from './projects.actions';
import { getSelectedPlatform } from '../ostk.reducer';

@Injectable()
export class OstkProjectsEffects
{
    constructor (
        private store: Store<IAuroraState>,
        private actions: Actions,
        private projectMiscDomainService: ProjectMiscDomainService,
        private translate: TranslateService,
        private snackbarService: SnackbarService,
        private languageService: LanguageService
    )
    { }

    ostkProjectsLoadingRequested: Observable<Action> = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromProjectsActions.OstkProjectsLoadingRequested),
                withLatestFrom(
                    this.store.pipe(select(getSelectedPlatform)),
                    (_, platform: Platform) =>
                        ({
                            platform
                        })
                ),
                //     platform
                // ),
                switchMap(({ platform }: { platform: Platform }) =>
                    this.projectMiscDomainService.getProjects(platform)
                        .pipe(
                            switchMap((projects: Project[]) =>
                                [
                                    fromProjectsActions.OstkProjectsLoadingSucceeded({ projects }),
                                    // fromProjectsActions.OstkProjectSelected({ project: projects[0] })
                                ]
                            ),
                            catchError((error: any) =>
                                of(fromProjectsActions.OstkProjectsLoadingFailed(error))
                            )
                        )
                )
            )
    );

    ostkProjectsLoadingFailed = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromProjectsActions.OstkProjectsLoadingFailed),
                tap(() =>
                {
                    const tradKeys = [
                        '',
                        'OSTK.PROJECTS.LOADING.NOTF.FAILED'
                    ];

                    this.notifySnackbar(tradKeys, NotfGravity.danger);
                })
            ),
    { dispatch: false }
    );

    ostkPlatformUnknown = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromProjectsActions.OstkPlatformUnknown),
                tap(() =>
                {
                    const tradKeys = [
                        '',
                        'OSTK.PLATFORMS.LOADING.NOTF.UNKNOWN'
                    ];

                    this.notifySnackbar(tradKeys, NotfGravity.danger);
                })
            ),
    { dispatch: false }
    );

    ostkProjectUnknown = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromProjectsActions.OstkProjectUnknown),
                tap(() =>
                {
                    const tradKeys = [
                        '',
                        'OSTK.PROJECTS.LOADING.NOTF.UNKNOWN'
                    ];

                    this.notifySnackbar(tradKeys, NotfGravity.danger);
                })
            ),
    { dispatch: false }
    );

    private notifySnackbar (tradKeys: string[], gravity: NotfGravity)
    {
        this.languageService.loadLanguage(['ostk', 'ostk/project'])
            .pipe(
                take(1),
                tap(() =>
                {
                    const translations = this.translate.instant(tradKeys);

                    this.snackbarService.create(translations[tradKeys[1]], gravity);

                })
            )
            .subscribe();
    }
}
