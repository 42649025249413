/* eslint-disable no-underscore-dangle */
import { Injector } from '@angular/core';
import { ControlValueAccessor, AbstractControl } from '@angular/forms';

import { ErrorMsgService } from '../common/error-msg.service';

export abstract class ControlValueAccessorBase<T> implements ControlValueAccessor
{
    public changed: ((value: T) => void)[] = new Array<(value: T) => void>();
    public touched: (() => void)[] = new Array<() => void>();
    public errorMsgService: ErrorMsgService;
    protected control: AbstractControl;
    private innerValue: T;

    constructor (private injector: Injector)
    {
        this.errorMsgService = this.injector.get<ErrorMsgService>(ErrorMsgService);
    }

    get ctrlValue (): T
    {
        return this.control.value;
    }

    set ctrlValue (value: T)
    {
        this.control.setValue(value);
        this.innerValue = value;
    }

    writeValue (value: T): void
    {
        this.ctrlValue = value;
    }

    registerOnChange (fn: (value: T) => void): void
    {
        this.changed.push(fn);
    }

    registerOnTouched (fn: () => void): void
    {
        this.touched.push(fn);
    }

    touch (): void
    {
        this.touched.forEach((f: () => void): void => f());
    }

    change (): void
    {
        this.changed.forEach((f: (value: T) => void): void => f(this.innerValue));
    }
}
