import { HasMajorProps } from '@apps/ostk/domain/models/common';

import { KeyPairApi } from '../../../api/models/security/key-pair-api';

export class KeyPair implements HasMajorProps
{
    id: string;
    fingerprint?: string;
    name: string;
    publicKey: string;

    public static mapperFromApi(source: KeyPairApi): KeyPair
    {
        const target = new KeyPair();
        Object.assign(target, source);

        target.id = source.name;

        return target;
    }
}
