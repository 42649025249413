import { Profile } from '@apps/auth/models';

/**
 * Classe d'Impersonation
 */
export class Impersonation
{
    public entityId: string;
    public entityShortId: string;
    public entityErpId: string;
    public username: string;
    public email: string;
    public language: string;
    public userId: string;
    public entityName: string;
    public entityDocFolder: string;
    public entityErpShortId: string;

    /**
 *
 * @param profile Profile
 */
    constructor (profile?: Profile)
    {
        if (profile)
        {
            this.email = profile.email;
            this.entityId = profile.entityId;
            this.entityShortId = profile.entityShortId;
            this.entityErpId = profile.entityErpId;
            this.language = profile.language;
            this.userId = profile.id;
            this.username = profile.userName;
            this.entityName = profile.entityName;
            this.entityDocFolder = profile.entityDocFolder;
            this.entityErpShortId = profile.entityErpShortId;
        }
    }
}
