import { Injectable } from '@angular/core';

import { Observable, of, zip, GroupedObservable } from 'rxjs';
import { map, groupBy, concatAll, mergeMap, toArray } from 'rxjs/operators';

import { BaseListDomainService } from '@common/domain/base-list-domain.service';
import { DatesService } from '@common/services/dates.service';
import { LanguageService } from '@common/services';
import { BckpServerSearchResult } from '@common/models/search-result';
import { ResultApi } from '@common/api/result-api';

import { ServerListApiService } from '../api/server-list-api.service';
import { Image, Server } from './models';
import { ImageApi } from '../api/models';

@Injectable({
    providedIn: 'root'
})
export class ServerListDomainService extends BaseListDomainService
{
    constructor (
        private serverListApiService: ServerListApiService,
        private datesService: DatesService,
        private languageService: LanguageService
    )
    {
        super();
    }

    getImages (qs: string): Observable<Image[]>
    {
        this.getLatestImages(qs);

        return this.serverListApiService.getImages(qs)
            .pipe(
                map((obj: ResultApi<ImageApi>): ImageApi[] =>
                    obj.value
                ),
                concatAll(),
                map((imageApi: ImageApi) =>
                    Image.mapperFromApi(imageApi, this.datesService, this.languageService.language)
                ),
                toArray()
            );
    }

    getLatestImages (qs: string): Observable<Server[]>
    {
        return this.serverListApiService.getLatestImages(qs)
            .pipe(
                map((obj: ResultApi<ImageApi>): ImageApi[] =>
                    obj.value
                ),
                concatAll(),
                map((imageApi: ImageApi) =>
                    Image.mapperFromApi(imageApi, this.datesService, this.languageService.language)
                ),
                groupBy(
                    img => img.serverShort
                ),
                mergeMap((group: GroupedObservable<string, Image>) =>
                    zip(
                        of(group.key),
                        group.pipe(
                            toArray(),
                            map((images: Image[]) => this.sortImagesByBackupTime(images, -1)),
                            // map((images: Image[]) =>
                            // {
                            //     const idxFull = images.findIndex((val: Image) => val.scheduleType === 'FULL');

                            //     if (idxFull === -1 && idxFull === images.length - 1)
                            //     {
                            //         return images;
                            //     }

                            //     return images.splice(0, idxFull - 1);
                            // }),
                            map((images: Image[]) =>
                            {
                                const server: Server = new Server();

                                Object.assign(server, images[0]);
                                if (images.length > 1)
                                {
                                    server.images = images;
                                }

                                return server;
                            })
                        )
                    )
                ),
                map((value: [string, Server]) => value[1]),
                toArray(),
                map((servers: Server[]) => this.sortServerArrayByBackupTime(servers, 1))
            );
    }
    search (qs: string): Observable<BckpServerSearchResult[]>
    {
        return this.serverListApiService.getLatestImages(qs)
            .pipe(
                map((obj: ResultApi<ImageApi>): ImageApi[] =>
                    obj.value
                ),
                map((servers: ImageApi[]) =>
                    servers
                        .map(this.searchMapperFromApi, this)
                        .slice(0, 7)
                )
            );
    }

    private searchMapperFromApi (imageApi: ImageApi): BckpServerSearchResult
    {
        return new BckpServerSearchResult(
            Image.mapperFromApi(imageApi, this.datesService, this.languageService.language) as Server
        );
    }

    private sortServerArrayByBackupTime (servers: Server[], direction: 1 | -1): Server[]
    {
        return servers.sort((srva, srvb) => direction * srva.backupString.localeCompare(srvb.backupString));
    }

    private sortImagesByBackupTime (images: Image[], direction: 1 | -1): Image[]
    {
        return images.sort((imga, imgb) => direction * imga.backupString.localeCompare(imgb.backupString));
    }

    private sortImagesByMasterBackupTime (images: Image[], direction: 1 | -1): Image[]
    {
        return images.sort((imga, imgb) =>
            imga.master.localeCompare(imgb.master) || direction * imga.backupString.localeCompare(imgb.backupString));
    }
}
