import { RequestsCatalogCounterApi } from '@apps/its2/api/models';
import { LocalizedText } from '@common/models';

export class RequestsCatalogCounter
{
    catalogId: number;
    catalog: LocalizedText;
    count: number;
}

export class DomainRequestsCatalogCounter
{
    public static mapperFromApi (source: RequestsCatalogCounterApi): RequestsCatalogCounter
    {
        const target = new RequestsCatalogCounter();

        target.catalogId = source.catalogId;
        target.catalog = new LocalizedText(source.catalogEN, source.catalogFR);
        target.count = source.count;

        return target;
    }
}

