import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { switchMap, map, catchError, withLatestFrom, filter, concatAll, toArray, tap, take, mergeMap, takeUntil } from 'rxjs/operators';
import { of, Observable, Subject, iif, timer, throwError } from 'rxjs';

import { IAuroraState } from '@apps/aurora.state';
import { NotificationService, NotfGravity, NotifType } from '@common/services';
import { multiFilter } from '@common/operators/multi-filter';
import { InMemoryFilter, Predicate } from '@common/filters/in-memory-filter';
import { CreateInstance, CreateInstanceViewModel, ManageSecurityGroupsViewModel } from '@apps/ostk/models/instance';
import { OstkFailure } from '@common/models';
import { AppConstants } from '@apps/app.constants';

import * as fromOstkInstancesActions from './instances.actions';
import { InstanceMiscDomainService } from '../../domain';
import { ImageSnapshot, Instance, InstanceTimelineGroup } from '../../domain/models';
import { getCreateInstance } from '../ostk.reducer';
import { getAllInstances, getInstancesIsDataValid, getInstanceFieldsFilter } from '../ostk-instances.reducer';
import { InstanceStatus } from '@apps/ostk/instances.constants';

@Injectable()
export class OstkInstancesEffects
{
    private destroyator: Subject<unknown>;

    constructor (
        private actions: Actions,
        private store: Store<IAuroraState>,
        private instanceMiscDomainService: InstanceMiscDomainService,
        private notificationService: NotificationService
    )
    {
    }

    ostkInstancesListRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstancesListRequested),
                    withLatestFrom(
                        this.store.select(getInstancesIsDataValid)
                    ),
                    filter(([, valid]) => !valid),
                    switchMap(() =>
                        this.instanceMiscDomainService.getAllInstances()
                            .pipe(
                                switchMap((instances: Instance[]) =>
                                    [
                                        fromOstkInstancesActions.OstkInstancesListSucceeded({ instances }),
                                        fromOstkInstancesActions.OstkInstancesApplyFilter({ if4f: null })
                                    ]
                                ),
                                catchError((failure: OstkFailure) =>
                                    of(fromOstkInstancesActions.OstkInstancesListFailed({ failure }))
                                )
                            )
                    )
                )
    );

    ostkInstanceDetailsRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceDetailsRequested),
                    switchMap(({ instanceId }: { instanceId: string; }) =>
                        this.instanceMiscDomainService.getDetails(instanceId)
                            .pipe(
                                map((instance: Instance) =>
                                    fromOstkInstancesActions.OstkInstanceDetailsSucceeded({ selectedInstance: instance })
                                ),
                                catchError((failure: OstkFailure) =>
                                    of(fromOstkInstancesActions.OstkInstanceDetailsFailed({ failure }))
                                )
                            )
                    )
                )
    );

    ostkInstanceHistoryRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceHistoryRequested),
                    switchMap(({ instanceId }: { instanceId: string; }) =>
                        this.instanceMiscDomainService.getHistory(instanceId)
                            .pipe(
                                map((instanceTimelineGroups: InstanceTimelineGroup[]) =>
                                    fromOstkInstancesActions.OstkInstanceHistorySucceeded({ instanceTimelineGroups })
                                ),
                                catchError((failure: OstkFailure) =>
                                    of(fromOstkInstancesActions.OstkInstanceHistoryFailed({ failure }))
                                )
                            )
                    )
                )
    );

    ostkInstanceDetailsFailed: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceDetailsFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.INSTANCES.ACTIONS.START.NOTIF.TITLE',
                                'OSTK.INSTANCES.ACTIONS.START.NOTIF.FAILURE'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkInstancesCreationRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstancesCreationRequested),
                    withLatestFrom(this.store.select(getCreateInstance)),
                    filter(([, createInstance]: [any, CreateInstance]) =>
                        createInstance !== null
                    ),
                    take(1),
                    map(([, createInstance]: [any, CreateInstance]) =>
                        CreateInstance.toInstanceCreateViewModel(createInstance)
                    ),
                    switchMap((civm: CreateInstanceViewModel) =>
                        this.instanceMiscDomainService.createInstance(civm)
                            .pipe(
                                switchMap((instance: Instance | null) =>
                                    iif(
                                        () => instance !== null,
                                        of(true)
                                            .pipe(
                                                map(() =>
                                                    fromOstkInstancesActions.OstkInstancesCreationAccepted({ instance })
                                                )
                                            ),
                                        of(false)
                                            .pipe(
                                                map(() =>
                                                    fromOstkInstancesActions.OstkInstancesCreationFailed({ failure: null })
                                                )
                                            )
                                    )
                                )
                            )
                    )
                )
    );
    ostkInstancesCreationAccepted: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstancesCreationAccepted),
                    tap(()=>
                        this.notificationService.notify(
                            [
                                '',
                                'OSTK.INSTANCES.CREATE.NOTIF.ACCEPTED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    ),
                    tap(() =>
                        this.destroyator = new Subject()
                    ),
                    switchMap(({ instance }: { instance: Instance; }) =>
                        [
                            fromOstkInstancesActions.OstkInstancesApplyFilter({ if4f: null }),
                            fromOstkInstancesActions.OstkInstancesPollingStatusRequested({ instance })
                        ]
                    )
                )
    );

    ostkInstancesPollingStatusRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstancesPollingStatusRequested),
                    switchMap(({ instance }: { instance: Instance }) =>
                        timer(7 * AppConstants.PollingStatusInterval, 7 * AppConstants.PollingStatusInterval)
                            .pipe(
                                switchMap(() =>
                                    this.instanceMiscDomainService.getAllInstances()
                                        .pipe(
                                            tap((instances: Instance[]) =>
                                            {
                                                const newInst = instances.find((inst: Instance) => inst.name === instance.name);

                                                if (newInst?.status === InstanceStatus.ACTIVE)
                                                {
                                                    this.store.dispatch(fromOstkInstancesActions.OstkInstancesCreationSucceeded({ instance: newInst }));
                                                    this.store.dispatch(fromOstkInstancesActions.OstkInstancesApplyFilter({ if4f: null }));
                                                    this.destroyator.next(null);
                                                    this.destroyator.complete();
                                                }
                                            }),
                                            catchError((err) =>
                                            {
                                                this.destroyator.next(null);
                                                this.destroyator.complete();

                                                return of(fromOstkInstancesActions.OstkInstancesCreationFailed({ failure: err }));
                                            })
                                        )
                                ),
                                takeUntil(this.destroyator)
                            )
                    )
                ),
        { dispatch: false }
    );

    ostkInstancesCreationSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstancesCreationSucceeded),
                    tap(()=>
                        this.notificationService.notify(
                            [
                                '',
                                'OSTK.INSTANCES.CREATE.NOTIF.SUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkInstancesApplyFilter = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstancesApplyFilter),
                    withLatestFrom(
                        this.store.select(getAllInstances),
                        this.store.select(getInstanceFieldsFilter),
                        (_, instances, instanceFields4Filter) => ({
                            instances,
                            instanceFields4Filter
                        })
                    ),
                    filter(({ instanceFields4Filter }) =>
                        !!instanceFields4Filter
                    ),
                    switchMap(({ instances, instanceFields4Filter }) =>
                    {
                        const predicates: Predicate<Instance>[] = new InMemoryFilter(instanceFields4Filter.fields4Filter)
                            .predicates;

                        return of(instances)
                            .pipe(
                                concatAll(),
                                multiFilter(predicates),
                                toArray(),
                                map((filteredInstances: Instance[]) =>
                                    fromOstkInstancesActions.OstkInstancesFilterApplied({ instances: filteredInstances })
                                )
                            );
                    })
                )
    );

    ostkInstanceStartRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceStartRequested),
                    switchMap(({ instance }: { instance: Instance; }) =>
                        this.instanceMiscDomainService.startInstance(instance)
                            .pipe(
                                map((inst: Instance) =>
                                {
                                    this.notificationService.notify(
                                        [
                                            'OSTK.INSTANCES.ACTIONS.START.NOTIF.TITLE',
                                            'OSTK.INSTANCES.ACTIONS.START.NOTIF.SUCCESS'
                                        ],
                                        NotfGravity.success,
                                        NotifType.SNACKBAR,
                                        { which: instance.name }
                                    );

                                    return fromOstkInstancesActions.OstkInstanceStartSucceeded({ instance: inst });
                                }),
                                catchError((failure: OstkFailure) =>
                                {
                                    this.notificationService.notify(
                                        [
                                            'OSTK.INSTANCES.ACTIONS.START.NOTIF.TITLE',
                                            'OSTK.INSTANCES.ACTIONS.START.NOTIF.FAILURE'
                                        ],
                                        NotfGravity.danger,
                                        NotifType.SNACKBAR,
                                        { which: instance.name }
                                    );

                                    return of(fromOstkInstancesActions.OstkInstanceStartFailed({ failure }));
                                })
                            )
                    )
                )
    );

    ostkInstanceStartSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceStartSucceeded),
                    map(() =>
                        fromOstkInstancesActions.OstkInstancesApplyFilter(null)
                    )
                )
    );

    ostkInstancePauseRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstancePauseRequested),
                    switchMap(({ instance }: { instance: Instance; }) =>
                        this.instanceMiscDomainService.pauseInstance(instance)
                            .pipe(
                                map((inst: Instance) =>
                                {
                                    this.notificationService.notify(
                                        [
                                            'OSTK.INSTANCES.ACTIONS.PAUSE.NOTIF.TITLE',
                                            'OSTK.INSTANCES.ACTIONS.PAUSE.NOTIF.SUCCESS'
                                        ],
                                        NotfGravity.success,
                                        NotifType.SNACKBAR,
                                        { which: inst.name }
                                    );

                                    return fromOstkInstancesActions.OstkInstancePauseSucceeded({ instance: inst });
                                }),
                                catchError((failure: OstkFailure) =>
                                {
                                    this.notificationService.notify(
                                        [
                                            'OSTK.INSTANCES.ACTIONS.PAUSE.NOTIF.TITLE',
                                            'OSTK.INSTANCES.ACTIONS.PAUSE.NOTIF.FAILURE'
                                        ],
                                        NotfGravity.danger,
                                        NotifType.SNACKBAR,
                                        { which: instance.name }
                                    );

                                    return of(fromOstkInstancesActions.OstkInstancePauseFailed({ failure }));
                                })
                            )
                    )
                )
    );

    ostkInstancePauseSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstancePauseSucceeded),
                    map(() =>
                        fromOstkInstancesActions.OstkInstancesApplyFilter(null)
                    )
                )
    );

    ostkInstanceStopRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceStopRequested),
                    switchMap(({ instance }: { instance: Instance; }) =>
                        this.instanceMiscDomainService.stopInstance(instance)
                            .pipe(
                                map((inst: Instance) =>
                                {
                                    this.notificationService.notify(
                                        [
                                            'OSTK.INSTANCES.ACTIONS.STOP.NOTIF.TITLE',
                                            'OSTK.INSTANCES.ACTIONS.STOP.NOTIF.SUCCESS'
                                        ],
                                        NotfGravity.success,
                                        NotifType.SNACKBAR,
                                        { which: inst.name }
                                    );

                                    return fromOstkInstancesActions.OstkInstanceStopSucceeded({ instance: inst });
                                }),
                                catchError((failure: OstkFailure) =>
                                {
                                    this.notificationService.notify(
                                        [
                                            'OSTK.INSTANCES.ACTIONS.STOP.NOTIF.TITLE',
                                            'OSTK.INSTANCES.ACTIONS.STOP.NOTIF.FAILURE'
                                        ],
                                        NotfGravity.danger,
                                        NotifType.SNACKBAR,
                                        { which: instance.name }
                                    );

                                    return of(fromOstkInstancesActions.OstkInstanceStopFailed({ failure }));
                                })
                            )
                    )
                )
    );

    ostkInstanceStopSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceStopSucceeded),
                    map(() =>
                        fromOstkInstancesActions.OstkInstancesApplyFilter(null)
                    )
                )
    );

    ostkInstanceSnapshotRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceSnapshotRequested),
                    switchMap(({ instance, name }: { instance: Instance, name: string; }) =>
                        this.instanceMiscDomainService.takeSnapshot(instance, name)
                            .pipe(
                                map(() =>
                                {
                                    this.notificationService.notify(
                                        [
                                            'OSTK.INSTANCES.SNAPSHOT.NOTIF.TITLE',
                                            'OSTK.INSTANCES.SNAPSHOT.NOTIF.SUCCESS'
                                        ],
                                        NotfGravity.success,
                                        NotifType.SNACKBAR
                                    );

                                    return fromOstkInstancesActions.OstkInstanceSnapshotSucceeded();
                                }),
                                catchError((failure: OstkFailure) =>
                                {
                                    this.notificationService.notify(
                                        [
                                            'OSTK.INSTANCES.SNAPSHOT.NOTIF.TITLE',
                                            'OSTK.INSTANCES.SNAPSHOT.NOTIF.FAILURE'
                                        ],
                                        NotfGravity.danger,
                                        NotifType.SNACKBAR
                                    );

                                    return of(fromOstkInstancesActions.OstkInstanceStopFailed({ failure }));
                                })
                            )
                    )
                )
    );

    ostkInstancesManageSecurityGroups = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstancesManageSecurityGroupsRequested),
                    mergeMap(({ id, sgrpIds }: { id: string, sgrpIds: ManageSecurityGroupsViewModel; }) =>
                        this.instanceMiscDomainService.manageSecurityGroups(id, sgrpIds)
                            .pipe(
                                map((instance: Instance) =>
                                    fromOstkInstancesActions.OstkInstancesManageSecurityGroupsSucceeded({ instance })
                                ),
                                catchError((failure: OstkFailure) =>
                                    of(fromOstkInstancesActions.OstkInstancesManageSecurityGroupsFailed({ failure }))
                                )
                            )
                    )
                )
    );

    ostkInstanceDeleteRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceDeleteRequested),
                    switchMap(({ instance }) =>
                        this.instanceMiscDomainService.deleteInstance(instance)
                            .pipe(
                                map(() =>
                                    fromOstkInstancesActions.OstkInstanceDeleteSucceeded({ instanceId: instance.id })
                                ),
                                catchError(error =>
                                    of(fromOstkInstancesActions.OstkInstanceDeleteFailed({ error }))
                                )
                            )
                    )
                )
    );

    ostkInstanceDeleteSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceDeleteSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                '',
                                'OSTK.INSTANCES.DELETE.NOTIF.SUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkInstanceDeleteFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceDeleteFailed),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                '',
                                'OSTK.INSTANCES.DELETE.NOTIF.FAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        );
                    })
                ),
        { dispatch: false }
    );

    ostkInstanceTakeSnapshotRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceTakeSnapshotRequested),
                    switchMap(({ instance, name }: { instance: Instance, name: string }) =>
                        this.instanceMiscDomainService.takeSnapshot(instance, name)
                            .pipe(
                                map((snapshot: ImageSnapshot) =>
                                    fromOstkInstancesActions.OstkInstanceTakeSnapshotSucceeded({ snapshot: snapshot })
                                ),
                                catchError((failure: OstkFailure) =>
                                    of(fromOstkInstancesActions.OstkInstanceTakeSnapshotFailed({ failure }))
                                )
                            )
                    )
                )
    );

    ostkInstanceTakeSnapshotSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceTakeSnapshotSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                '',
                                'OSTK.INSTANCES.SNAPSHOT.NOTIF.SUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkInstanceTakeSnapshotFailed: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceTakeSnapshotFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                '',
                                'OSTK.INSTANCES.SNAPSHOT.NOTIF.FAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkInstanceRenameRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceRenameRequested),
                    switchMap(({ instance, newName }) =>
                        this.instanceMiscDomainService.renameInstance(instance, newName)
                            .pipe(
                                map(() =>
                                    fromOstkInstancesActions.OstkInstanceRenameSucceeded({ instance: instance, newName: newName })
                                ),
                                catchError(error =>
                                    of(fromOstkInstancesActions.OstkInstanceRenameFailed({ error }))
                                )
                            )
                    )
                )
    );

    ostkInstanceRenameSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceRenameSucceeded),
                    map(({ instance, newName }) =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.INSTANCES.RENAME.NOTIF.TITLE',
                                'OSTK.INSTANCES.RENAME.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );

                        return fromOstkInstancesActions.OstkInstanceChanged(
                            {
                                instance: {
                                    id: instance.id,
                                    changes: { name: newName }
                                }
                            }
                        );
                    })
                )
    );

    ostkInstanceRenameFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkInstancesActions.OstkInstanceRenameFailed),
                    tap(({ error }) =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.INSTANCES.RENAME.NOTIF.TITLE',
                                'OSTK.INSTANCES.RENAME.NOTIF.MSGFAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        );
                        if (error)
                        {
                            throwError(error);
                        }
                    })
                ),
        { dispatch: false }
    );
}
