import { EntityState, createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { Policy, PolicyDetails } from '../../domain/models';
import * as fromPoliciesActions from './policies.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { ODataBckpPolicies } from './odata-bckp-policies';
import { PoliciesFilter } from '../../models/policies-filter';
import * as fromRootStore from '@apps/root/store';

export const BckpPoliciesFeatureKey = 'policies';

type IPoliciesState = EntityState<Policy>;
type IPoliciesDetailsState = EntityState<PolicyDetails>;
export const policiesAdapter: EntityAdapter<Policy> = createEntityAdapter<Policy>({
    selectId: (policy: Policy) => policy.storeId
});
export const policiesDetailsAdapter: EntityAdapter<PolicyDetails> = createEntityAdapter<PolicyDetails>({
    selectId: (policyDetails: PolicyDetails) => policyDetails.storeId
});
const policiesDetailsInititalState = policiesDetailsAdapter.getInitialState({});

const policiesInitialState: IPoliciesState = policiesAdapter.getInitialState({});
export interface IBckpPoliciesState
{
    collection: IPoliciesState;
    isLoading: boolean;
    isOups: boolean;
    isEmpty: boolean;
    oData: ODataBckpPolicies;
    selectedPolicy: PolicyDetails;
    detailedPolicies: IPoliciesDetailsState;
    qsWord: string;
    isLoaded: boolean;
}

const initialState: IBckpPoliciesState = {
    isLoading: true,
    isOups: false,
    isEmpty: false,
    collection: policiesInitialState,
    oData: new ODataBckpPolicies(0, new PoliciesFilter()),
    selectedPolicy: null,
    detailedPolicies: policiesDetailsInititalState,
    qsWord: '',
    isLoaded: false
};

export const BckpPoliciesReducer = createReducer(
    initialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => initialState
    ),
    on(
        fromPoliciesActions.BckpPoliciesODataInitialized,
        (state, { filter }) => ({
            ...state,
            oData: new ODataBckpPolicies(0, filter)
        })
    ),
    on(
        fromPoliciesActions.BckpPoliciesODataLoaded,
        (state, { policies }) => ({
            ...state,
            isLoading: false,
            isLoaded: policies && policies.length !== 0,
            isEmpty: policies && policies.length === 0,
            collection: policiesAdapter.addMany(policies, state.collection)
        })
    ),
    on(
        fromPoliciesActions.BckpPoliciesODataFailed,
        (state) => ({
            ...state,
            isLoading: false,
            isLoaded: false,
            isOups: true,
            collection: null
        })
    ),
    on(
        fromPoliciesActions.BckpPoliciesDetailsLoaded,
        (state, { policyDetails }) =>
        {
            return {
                ...state,
                selectedPolicy: policyDetails,
                detailedPolicies: policiesDetailsAdapter.addOne(policyDetails, state.detailedPolicies)
            };
        }
    ),
    on(
        fromPoliciesActions.BckpPoliciesDetailsFailed,
        (state) => ({
            ...state,
            selectedPolicy: null
        })
    ),
    on(
        fromPoliciesActions.BckpPoliciesDetailsSelected,
        (state, { policyDetails }) =>
        {
            return {
                ...state,
                selectedPolicy: policyDetails,
            };
        }
    ),
    on(
        fromPoliciesActions.BckpPoliciesCleared,
        (state) => ({
            ...state,
            collection: policiesAdapter.removeAll(state.collection)
        })
    ),
    on(
        fromPoliciesActions.BckpPoliciesQsWordSet,
        (state, { qsWord }) => ({
            ...state,
            qsWord: qsWord.toLowerCase()
        })
    ),
    on(
        fromPoliciesActions.BckpPoliciesTagAttachSucceeded,
        fromPoliciesActions.BckpPoliciesTagDetachSucceeded,
        (state: IBckpPoliciesState, { updatedPolicy }: { updatedPolicy: Update<Policy>; }) =>
        {
            return {
                ...state,
                collection: policiesAdapter.updateOne(updatedPolicy, state.collection)
            };
        }
    )
);
