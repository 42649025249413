import { on, createReducer } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DateTime } from 'luxon';

import { SelectOption } from '@ui/components/form/common';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import * as fromRootStore from '@apps/root/store';
import { CompareUpperString2 } from '@common/services/utils.service';

import * as fromOptionsActions from './options.actions';
import { BckpConstants } from '../../../bckp.constants';
export const BckpOptionsFeatureKey = 'options';

type IBckpSelectOptionState = EntityState<SelectOption>;
export const mastersAdapter: EntityAdapter<SelectOption> = createEntityAdapter<SelectOption>({
    selectId: (so: SelectOption) =>
        so.value.toString(),
    sortComparer: CompareUpperString2<SelectOption>((so: SelectOption) => so.label)
});
export const serversAdapter: EntityAdapter<SelectOption> = createEntityAdapter<SelectOption>({
    selectId: (so: SelectOption) =>
        so.value.toString(),
    sortComparer: CompareUpperString2<SelectOption>((so: SelectOption) => so.label)
});
export const serverShortsAdapter: EntityAdapter<SelectOption> = createEntityAdapter<SelectOption>({
    selectId: (so: SelectOption) =>
        so.value.toString(),
    sortComparer: CompareUpperString2<SelectOption>((so: SelectOption) => so.label)
});
export const policiesAdapter: EntityAdapter<SelectOption> = createEntityAdapter<SelectOption>({
    selectId: (so: SelectOption) =>
        so.value.toString(),
    sortComparer: CompareUpperString2<SelectOption>((so: SelectOption) => so.label)
});
export const policyTypesAdapter: EntityAdapter<SelectOption> = createEntityAdapter<SelectOption>({
    selectId: (so: SelectOption) =>
        so.value.toString(),
    sortComparer: CompareUpperString2<SelectOption>((so: SelectOption) => so.label)
});
export const copiesServersAdapter: EntityAdapter<SelectOption> = createEntityAdapter<SelectOption>({
    selectId: (so: SelectOption) =>
        so.value.toString(),
    sortComparer: CompareUpperString2<SelectOption>((so: SelectOption) => so.label)
});
export const copiesPoliciesAdapter: EntityAdapter<SelectOption> = createEntityAdapter<SelectOption>({
    selectId: (so: SelectOption) =>
        so.value.toString(),
    sortComparer: CompareUpperString2<SelectOption>((so: SelectOption) => so.label)
});
export const copiesResidencesAdapter: EntityAdapter<SelectOption> = createEntityAdapter<SelectOption>({
    selectId: (so: SelectOption) =>
        so.value.toString(),
    sortComparer: CompareUpperString2<SelectOption>((so: SelectOption) => so.label)
});
export const copiesSLPAdapter: EntityAdapter<SelectOption> = createEntityAdapter<SelectOption>({
    selectId: (so: SelectOption) =>
        so.value.toString(),
    sortComparer: CompareUpperString2<SelectOption>((so: SelectOption) => so.label)
});
class Options
{
    collection: IBckpSelectOptionState;
    public expirationDate: DateTime;
}

export interface IBckpOptionsState
{
    masters: Options;
    servers: Options;
    serverShorts: Options;
    policies: Options;
    policyTypes: Options;
    copiesServers: Options;
    copiesPolicies: Options;
    copiesResidences: Options;
    copiesSLP: Options;
}

const initialState: IBckpOptionsState = {
    masters: {
        collection: mastersAdapter.getInitialState({}),
        expirationDate: null
    },
    servers: {
        collection: serversAdapter.getInitialState({}),
        expirationDate: null
    },
    serverShorts: {
        collection: serverShortsAdapter.getInitialState({}),
        expirationDate: null
    },
    policies: {
        collection: policiesAdapter.getInitialState({}),
        expirationDate: null
    },
    policyTypes: {
        collection: policyTypesAdapter.getInitialState({}),
        expirationDate: null
    },
    copiesServers: {
        collection: copiesServersAdapter.getInitialState({}),
        expirationDate: null
    },
    copiesPolicies: {
        collection: copiesPoliciesAdapter.getInitialState({}),
        expirationDate: null
    },
    copiesResidences: {
        collection: copiesResidencesAdapter.getInitialState({}),
        expirationDate: null
    },
    copiesSLP: {
        collection: copiesSLPAdapter.getInitialState({}),
        expirationDate: null
    }
};

export const BckpOptionsReducer = createReducer(
    initialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => initialState
    ),
    on(
        fromOptionsActions.BckpLoadMastersSucceeded,
        (state: IBckpOptionsState, { masters }) =>
            ({
                ...state,
                masters:
                {
                    collection: mastersAdapter.setAll(masters, state.masters.collection),
                    expirationDate: DateTime.utc().plus({ minutes: BckpConstants.DataLifeTime })
                }
            })
    ),
    on(
        fromOptionsActions.BckpLoadServersSucceeded,
        (state: IBckpOptionsState, { servers }) =>
            ({
                ...state,
                servers:
                {
                    collection: serversAdapter.setAll(servers, state.servers.collection),
                    expirationDate: DateTime.utc().plus({ minutes: BckpConstants.DataLifeTime })
                }
            })
    ),
    on(
        fromOptionsActions.BckpLoadServerShortsSucceeded,
        (state: IBckpOptionsState, { serverShorts }) =>
            ({
                ...state,
                serverShorts:
                {
                    collection: serverShortsAdapter.setAll(serverShorts, state.serverShorts.collection),
                    expirationDate: DateTime.utc().plus({ minutes: BckpConstants.DataLifeTime })
                }
            })
    ),
    on(
        fromOptionsActions.BckpLoadPoliciesSucceeded,
        (state: IBckpOptionsState, { policies }) =>
            ({
                ...state,
                policies:
                {
                    collection: policiesAdapter.setAll(policies, state.policies.collection),
                    expirationDate: DateTime.utc().plus({ minutes: BckpConstants.DataLifeTime })
                }
            })
    ),
    on(
        fromOptionsActions.BckpLoadPolicyTypesSucceeded,
        (state: IBckpOptionsState, { policyTypes }) =>
            ({
                ...state,
                policyTypes:
                {
                    collection: policyTypesAdapter.setAll(policyTypes, state.policyTypes.collection),
                    expirationDate: DateTime.utc().plus({ minutes: BckpConstants.DataLifeTime })
                }
            })
    ),
    on(
        fromOptionsActions.BckpLoadCopiesServersSucceeded,
        (state: IBckpOptionsState, { copiesServers }) =>
            ({
                ...state,
                copiesServers:
                {
                    collection: copiesServersAdapter.setAll(copiesServers, state.copiesServers.collection),
                    expirationDate: DateTime.utc().plus({ minutes: BckpConstants.DataLifeTime })
                }
            })
    ),
    on(
        fromOptionsActions.BckpLoadCopiesPoliciesSucceeded,
        (state: IBckpOptionsState, { copiesPolicies }) =>
            ({
                ...state,
                copiesPolicies:
                {
                    collection: copiesPoliciesAdapter.setAll(copiesPolicies, state.copiesPolicies.collection),
                    expirationDate: DateTime.utc().plus({ minutes: BckpConstants.DataLifeTime })
                }
            })
    ),
    on(
        fromOptionsActions.BckpLoadCopiesResidencesSucceeded,
        (state: IBckpOptionsState, { copiesResidences }) =>
            ({
                ...state,
                copiesResidences:
                {
                    collection: copiesResidencesAdapter.setAll(copiesResidences, state.copiesResidences.collection),
                    expirationDate: DateTime.utc().plus({ minutes: BckpConstants.DataLifeTime })
                }
            })
    ),
    on(
        fromOptionsActions.BckpLoadCopiesSLPSucceeded,
        (state: IBckpOptionsState, { copiesSLP }) =>
            ({
                ...state,
                copiesSLP:
                {
                    collection: copiesSLPAdapter.setAll(copiesSLP, state.copiesSLP.collection),
                    expirationDate: DateTime.utc().plus({ minutes: BckpConstants.DataLifeTime })
                }
            })
    )
);
