<div class="modal-container">
    <div class="modal-title">
        {{ title }}
    </div>
    <div *ngIf="msg" class="modal-msg" [innerHtml]="msg"></div>
    <div class="modal-msg-ok">{{ msgOk }}</div>
    <div class="modal-msg-no">{{ msgNo }}</div>
    <div class="modal-footer">
        <aa-btn-action-transparent
            [btnText]="btnOk"
            (click)="close(true)"
            [btnWidth]="'100%'"
        >
        </aa-btn-action-transparent
        >&nbsp;
        <aa-btn-action-secondary
            [btnText]="btnNo"
            (click)="close(false)"
            [btnWidth]="'100%'"
        >
        </aa-btn-action-secondary>
    </div>
</div>
