import { BackConstants } from '@apps/back/back.constants';
import { TypePopin } from '@apps/app.constants';
import { User } from '@apps/auth/domain/models/back/user';
import { SearchResult } from './search-result';

export class BackAuthUserSearchResult extends SearchResult<User>
{
    constructor (user: User)
    {
        super('back-user', user.id);

        this.result = user;
        this.icon = 'fal fa-user';

        this.statusKey = user.statusKey;
        this.statusClass = user.statusClass;

        this.link = {
            baseUrlFragment: user.external
                ? `/${BackConstants.BaseUrl}/${BackConstants.Users}/${BackConstants.External}`
                : `/${BackConstants.BaseUrl}/${BackConstants.Users}/${BackConstants.Internal}`,
            outlet: TypePopin.DETAILS,
            params: [this.id]
        };
    }
}
