import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { NotfFeatureKey, NotfReducers } from './notf.reducer';
import { NotfAlertEffects } from './alert/alert.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forFeature([
            NotfAlertEffects
        ]),
        StoreModule.forFeature(
            NotfFeatureKey,
            NotfReducers
        )
    ]
})
export class NotfStoreModule { }
