
import { JobsState, JobsStatus, JobsContract } from '../bckp.constants';
import { RangeKey } from '@common/enum';
import { RangeFilter } from '@common/filters/range-filter';

export class JobsFilter extends RangeFilter
{
    public jobStatus: number[];
    public state: number[];
    public master: string;
    public serverShort: string;
    public policyName: string;
    public policyType: string;
    public contract: number[];

    constructor ()
    {
        super();

        this.initFilter();
    }

    public static mapperFromObject (source: any): JobsFilter
    {
        const target = new JobsFilter();

        Object.assign(target, source);

        return target;
    }

    public initFilter ()
    {
        const range = this.datesService.preRangeDates(RangeKey.CURRENT7DAYS);

        this.rangeSince = range.since;
        this.rangeUntil = range.until;
        this.jobStatus = [JobsStatus.Partial, JobsStatus.Failed];
        this.state = [JobsState.Done];
        this.contract = [JobsContract.Regulated];
    }
}
