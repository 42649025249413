import { Pipe, PipeTransform } from '@angular/core';

import { PercentsService } from 'src/app/_common/services';

@Pipe({
    name: 'percent2'
})
export class Percent2Pipe implements PipeTransform
{
    constructor(private percentsService: PercentsService)
    { }

    transform (value: number): any
    {
        if (value === null || value === undefined)
        {
            return '';
        }

        return this.percentsService.percentage(value, 2);
    }
}
