import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, concatAll, toArray } from 'rxjs/operators';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';

import { User, DomainUser } from './models/back';
import { EntitiesMiscApiService } from '../api/entities-misc-api.service';

@Injectable({
    providedIn: 'root'
})
export class EntitiesMiscDomainService extends BaseMiscDomainService
{
    constructor (
        private entitiesMiscApiService: EntitiesMiscApiService
    )
    {
        super();
    }

    getReptUsers (entityId: string): Observable<User[]>
    {
        return this.entitiesMiscApiService.getReptUsers(entityId)
            .pipe(
                concatAll(),
                map(DomainUser.mapperFromApi),
                toArray()
            );
    }
}
