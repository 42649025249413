import { Injectable } from '@angular/core';

declare const window: {
    [key: string]: any;
    prototype: Window;
    new(): Window;
};

@Injectable({
    providedIn: 'root'
})
export class RocketChatInjectorService
{
    init (url: string, urlFile: string): void
    {
        (() =>
        {
            try
            {
                const d = document;
                const g = d.createElement('script');
                const s = d.getElementsByTagName('script')[0];

                window.RocketChat = function (c)
                {
                    window.RocketChat._.push(c);
                };
                window.RocketChat._ = [];
                window.RocketChat.url = url;

                g.async = true;
                g.src = urlFile;
                s.parentNode.insertBefore(g, s);
            }
            catch (_)
            {
            }
        })();
    }
}
