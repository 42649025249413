import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const cdeb = () =>
    (source: Observable<any>) =>
        source
            .pipe(
                // tslint:disable-next-line: no-console
                tap(console.debug)
            );
