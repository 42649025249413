export type OptionValue = string | number | boolean;

export abstract class SelectOptionBase
{
    constructor (public label: string, public selected: boolean = false)
    {
    }
}

export class SelectOption extends SelectOptionBase
{
    constructor (public value: OptionValue, label: string, selected: boolean = false)
    {
        super(label, selected);
    }
}

export class SelectOption2<T> extends SelectOptionBase
{
    constructor (public value: T, label: string, selected: boolean = false)
    {
        super(label, selected);
    }
}

export class SelectOptionNumber extends SelectOptionBase
{
    constructor (public value: number, label: string, selected: boolean = false)
    {
        super(label, selected);
    }
}
