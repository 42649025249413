import { Component, Input } from '@angular/core';

@Component({
    selector: 'aa-validation',
    templateUrl: './validation.component.html',
    styleUrls: [
        './validation.component.scss'
    ]
})
export class ValidationComponent
{
    @Input() messages: Array<string>;
    @Input() dirty: boolean;

    get message ()
    {
        if (this.dirty && this.messages && this.messages.length > 0)
        {
            return this.messages[0];
        }

        return null;
    }
}
