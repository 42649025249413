import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TyposService
{
    public upperFirst (word: string): string
    {
        return TyposService.UpperFirst(word);
    }

    public static UpperFirst (word: string): string
    {
        const firstLetter = word.substr(0, 1).toUpperCase();
        const wordRemainder = word.substr(1);

        return `${firstLetter}${wordRemainder}`;
    }
}
