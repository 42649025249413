import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GaugeComponent } from './gauge.component';
import { GaugeSlaComponent } from './gauge-sla/gauge-sla.component';

@NgModule({
    declarations: [
        GaugeComponent,
        GaugeSlaComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        GaugeComponent,
        GaugeSlaComponent
    ]
})
export class GaugeModule
{ }
