import { Bytes } from '@common/enum';
import { BytesService } from '@common/services';
import { FlavorApi } from '../../../api/models';
import { HasMajorProps } from '../common';

export class Flavor implements HasMajorProps
{
    disk: number;
    id: string;
    name: string;
    isPublic: boolean;
    disabled: boolean;
    ephemeral: number;
    vram: number;
    rxtxFactor: number;
    swap: string;
    vcpu: number;
    cpus: string;

    public static mapperFromApi (source: FlavorApi): Flavor
    {
        const target = new Flavor();

        Object.assign(target, source);

        target.vcpu = source.vcpus;
        target.vram = BytesService.staticConvert(source.ram, Bytes.MIBI, Bytes.GIBI);

        target.cpus = `${source.vcpus} CPU${source.vcpus > 1 ? 's' : ''}`;

        return target;
    }
}
