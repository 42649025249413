import { Configuration } from '../_common/models/configuration';

export const config: Configuration = {
    stage: '',
    apiUrlAuth: '',
    apiUrlItsm: '',
    apiUrlIts2: '',
    apiUrlObjs: '',
    apiUrlHist: '',
    apiUrlSecu: '',
    apiUrlPrtg: '',
    apiUrlCmdb: '',
    apiUrlOstk: '',
    apiUrlDafa: '',
    apiUrlRept: '',
    apiUrlSurv: '',
    urlSatisfactionSurv: '',
    trackingUrl: '',
    trackingSiteId: '',
    rocketChatUrl: '',
    rocketChatScriptUrl: '',
    sentryDsn: ''
};
