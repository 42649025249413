import { Component, Optional, Inject, Injector, forwardRef } from '@angular/core';
import
{
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    NG_ASYNC_VALIDATORS,
    UntypedFormControl,
} from '@angular/forms';

import { InputBase } from '../../../base/input-base';

@Component({
    selector: 'aa-input-email2',
    templateUrl: './input-email2.component.html',
    styleUrls: [
        '../input.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputEmail2Component),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputEmail2Component),
            multi: true,
        }
    ]
})
export class InputEmail2Component extends InputBase<string> // implements OnInit, AfterViewInit, OnDestroy
{
    // RFC 2822 compliant regex
    // * https://regexr.com/4lntt
    private static emailRegexp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
    private static identifier: number = 0;

    constructor(
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
            injector: Injector
    )
    {
        super(injector);

        this.fieldId = `input-email-${InputEmail2Component.identifier++}-id`;
    }

    // ngOnInit (): void
    // {
    // }

    // ngAfterViewInit (): void
    // {
    // }

    // ngOnDestroy ()
    // {
    // }

    // * https://netbasal.com/adding-integrated-validation-to-custom-form-controls-in-angular-dc55e49639ae
    validate ({ value }: UntypedFormControl)
    {
        if (value)
        {
            return value.match(InputEmail2Component.emailRegexp) ? null : { 'emailAddress': true };
        }
    }
}
