import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { Bucket } from '@apps/objs/domain/models';

export const ObjsBucketBuckFeatureKey = 'buckets';

type IBucketBuckState = EntityState<Bucket>;
export const bucketsAdapter: EntityAdapter<Bucket> = createEntityAdapter({
    selectId: (buckets: Bucket) => buckets.bucketName
});
const bucketsInitialState: IBucketBuckState = bucketsAdapter.getInitialState({});

export interface IObjsBucketBuckState {
    buckets: IBucketBuckState;
    isLoading: boolean;
    isLoaded: boolean;
    isEmpty: boolean;
    isOups: boolean;
    pathSegments: string[];
    storageUrl: string;
    selectedBucket: Bucket;
}

export const ObjsBucketBuckInitialState: IObjsBucketBuckState = {
    buckets: bucketsInitialState,
    isLoading: true,
    isLoaded: false,
    isEmpty: false,
    isOups: false,
    pathSegments: [],
    storageUrl: null,
    selectedBucket: null
};

