import { createAction, props } from '@ngrx/store';

import { SelectOption } from 'src/app/_ui/components/form/common';

// #region Masters
export const BckpLoadMastersRequested = createAction(
    '[BCKP-MASTER] Bckp Load Masters Requested'
);
export const BckpLoadMastersSucceeded = createAction(
    '[BCKP-MASTER] Bckp Load Masters Succeeded',
    props<{ masters: SelectOption[] }>()
);
export const BckpLoadMastersFailed = createAction(
    '[BCKP-MASTER] Bckp Load Masters Failed'
);
// #endregion

// #region Servers
export const BckpLoadServersRequested = createAction(
    '[BCKP-SERVER] Bckp Load Servers Requested'
);

export const BckpLoadServersSucceeded = createAction(
    '[BCKP-SERVER] Bckp Load Servers Succeeded',
    props<{ servers: SelectOption[] }>()
);
export const BckpLoadServersFailed = createAction(
    '[BCKP-SERVER] Bckp Load Servers Failed'
);

export const BckpLoadServerShortsRequested = createAction(
    '[BCKP-SERVER] Bckp Load ServerShorts Requested'
);
export const BckpLoadServerShortsSucceeded = createAction(
    '[BCKP-SERVER] Bckp Load ServerShorts Succeeded',
    props<{ serverShorts: SelectOption[] }>()
);
export const BckpLoadServerShortsFailed = createAction(
    '[BCKP-SERVER] Bckp Load ServerShorts Failed'
);
// #endregion

// #region Policies
export const BckpLoadPoliciesRequested = createAction(
    '[BCKP-POLICY] Bckp Load Policies Requested'
);
export const BckpLoadPoliciesSucceeded = createAction(
    '[BCKP-POLICY] Bckp Load Policies Succeeded',
    props<{ policies: SelectOption[] }>()
);
export const BckpLoadPoliciesFailed = createAction(
    '[BCKP-POLICY] Bckp Load Policies Failed'
);
// #endregion

// #region Policy Type
export const BckpLoadPolicyTypesRequested = createAction(
    '[BCKP-POLICY] Bckp Load Policy Types Requested'
);
export const BckpLoadPolicyTypesSucceeded = createAction(
    '[BCKP-POLICY] Bckp Load Policy Types Succeeded',
    props<{ policyTypes: SelectOption[] }>()
);
export const BckpLoadPolicyTypesFailed = createAction(
    '[BCKP-POLICY] Bckp Load Policy Types Failed'
);
// #endregion

//#region CopiesServers
export const BckpLoadCopiesServersRequested = createAction(
    '[BCKP-COPIES] Bckp Load Copies Servers Requested'
);
export const BckpLoadCopiesServersSucceeded = createAction(
    '[BCKP-COPIES] Bckp Load Copies Servers Succeeded',
    props<{ copiesServers: SelectOption[] }>()
);
export const BckpLoadCopiesServersFailed = createAction(
    '[BCKP-COPIES] Bckp Load Copies Servers Failed'
);
//#endregion

//#region CopiesPolicyNames
export const BckpLoadCopiesPoliciesRequested = createAction(
    '[BCKP-COPIES] Bckp Load Copies Policies Requested'
);
export const BckpLoadCopiesPoliciesSucceeded = createAction(
    '[BCKP-COPIES] Bckp Load Copies Policies Succeeded',
    props<{ copiesPolicies: SelectOption[] }>()
);
export const BckpLoadCopiesPoliciesFailed = createAction(
    '[BCKP-COPIES] Bckp Load Copies Policies Failed'
);
//#endregion

//#region CopiesResidences
export const BckpLoadCopiesResidencesRequested = createAction(
    '[BCKP-COPIES] Bckp Load Copies Residences Requested'
);
export const BckpLoadCopiesResidencesSucceeded = createAction(
    '[BCKP-COPIES] Bckp Load Copies Residences Succeeded',
    props<{ copiesResidences: SelectOption[] }>()
);
export const BckpLoadCopiesResidencesFailed = createAction(
    '[BCKP-COPIES] Bckp Load Copies Residences Failed'
);
//#endregion

//#region CopiesSLP
export const BckpLoadCopiesSLPRequested = createAction(
    '[BCKP-COPIES] Bckp Load Copies SLP Requested'
);
export const BckpLoadCopiesSLPSucceeded = createAction(
    '[BCKP-COPIES] Bckp Load Copies SLP Succeeded',
    props<{ copiesSLP: SelectOption[] }>()
);
export const BckpLoadCopiesSLPFailed = createAction(
    '[BCKP-COPIES] Bckp Load Copies SLP Failed'
);
//#endregion


