import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'aa-renew-password-link-sent',
    templateUrl: './renew-password-link-sent.component.html',
    styleUrls: ['./renew-password-link-sent.component.scss']
})
export class RenewPasswordLinkSentComponent implements OnInit
{
    constructor() { }

    ngOnInit ()
    {
    }
}
