import { TypePopin } from '@apps/app.constants';
import { OpenPopinOriginator } from '@common/enum/open-popin-originator.enum';

export const DashFeatureKey = 'dash';

export class DashConstants
{
    public static BaseUrl: string = 'dashboard';
    public static Main: string = 'main';
    //#region  Projects
    public static MainRoute = (): string[] =>
        [
            DashConstants.BaseUrl,
            DashConstants.Main
        ];
    public static MainPath = `/${DashConstants.MainRoute().join('/')}`;
    public static DefaultUrl: string = DashConstants.MainPath;
    public static CreateCaseRoute = (openPopinOriginator: OpenPopinOriginator) =>
        [
            ...DashConstants.MainRoute(),
            { outlets: { [TypePopin.ACTION]: [ 'createCase', openPopinOriginator ] } }
        ];
    public static CreateServiceRoute = (openPopinOriginator: OpenPopinOriginator) =>
        [
            ...DashConstants.MainRoute(),
            { outlets: { [TypePopin.ACTION]: [ 'createService', openPopinOriginator ] } }
        ];
    //#endregion
}
