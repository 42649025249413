import { Injectable } from '@angular/core';

import * as d3 from 'd3';

import { CatalogEntry } from '@apps/its2/models';
import { CatalogEntryApi } from '@apps/its2/models/catalog-entry';
import { Languages } from '@common/enum';
import { TreeviewNode } from '@ui/components/form/controls/treeview';

@Injectable({
    providedIn: 'root'
})
export class TreeviewService
{
    public flatToTreeInternal (flatList: CatalogEntry[], language: 'Fr' | 'En' = 'Fr'): d3.HierarchyNode<unknown>
    {
        return TreeviewService.flatToTree(flatList, language);
    }

    public static flatToTree (flatList: CatalogEntry[], language: 'Fr' | 'En'): d3.HierarchyNode<any>
    {
        const stratify: d3.StratifyOperator<unknown> = d3.stratify();
        const list1: CatalogEntry[] = TreeviewService.setParentId(flatList);
        const list2: TreeviewNode<CatalogEntry>[] = TreeviewService.toTreeview(list1, language);

        const tree = stratify(list2)
            .each((n: any): void =>
            {
                n.label = n.data.label;
                if (n.children === undefined)
                {
                    const ancestors = n.ancestors()
                        .reverse();

                    ancestors.shift();
                    n.data.entry.path[Languages.frFr] = ancestors
                        .map((node: any): any =>
                            node.data.entry.titleFr
                        )
                        .join('/');
                    n.data.entry.path[Languages.enUs] = ancestors
                        .map((node: any): any =>
                            node.data.entry.titleEn
                        )
                        .join('/');
                }
            })
            .sort((a: any, b: any): number =>
                d3.ascending(a.label, b.label)
            );

        return tree;
    }

    public static toTreeview (list: CatalogEntry[], language: 'Fr' | 'En'): TreeviewNode<CatalogEntry>[]
    {
        const treeview: TreeviewNode<CatalogEntry>[] = list.map((ce: CatalogEntry): TreeviewNode<CatalogEntry> =>
        {
            const label = language === 'Fr' ? ce.titleFr : ce.titleEn;
            const node = new TreeviewNode<CatalogEntry>(ce.catalogId.toString(), ce.parentId, label, ce);

            return node;
        });

        return treeview;
    }

    public static setParentId (paramList: CatalogEntry[]): CatalogEntry[]
    {

        const list: CatalogEntryApi[] = [...paramList];
        const minLevel = Math.min(...list.map((ce: CatalogEntry): number => ce.level));
        const minLeft = Math.min(...list.map((ce: CatalogEntry): number => ce.left));
        const maxRight = Math.max(...list.map((ce: CatalogEntry): number => ce.right));
        const root = {
            'catalogId': minLeft - 1,
            'catalogGuid': null,
            'left': minLeft,
            'right': maxRight,
            'level': minLevel - 1,
            'titleFr': null,
            'titleEn': null,
            'descriptionFr': null,
            'descriptionEn': null
        } as CatalogEntry;

        list.unshift(root);

        const enrichedList: CatalogEntry[] = list
            .map((ce: CatalogEntry): CatalogEntry =>
            {
                // const ce: CatalogEntry = Object.assign(new CatalogEntry, cea);
                const level = ce.level - 1;
                const cid = ce.left;
                const parent = list
                    .filter((xyz: CatalogEntry): boolean =>
                        xyz.level === level
                    )
                    .find((xyz: CatalogEntry): boolean =>
                        xyz.left <= cid && cid <= xyz.right
                    );

                ce.parentId = parent ? parent.catalogId.toString() : '';

                return ce;
            })
            .filter((ce: CatalogEntry): boolean =>
                !(ce.parentId === '' && ce.level >= minLevel)
            );

        return enrichedList;
    }
}
