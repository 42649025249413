import { StatusesMetadata } from '@common/models';
import { RequestUrgencyCounterByDay } from '@apps/its2/domain/models';

export const Its2DashReportingFeatureKey = 'reporting';

export interface IIts2DashReportingCountersState extends StatusesMetadata
{
    collection: RequestUrgencyCounterByDay[]
}
export const Its2DashReportingCountersInitialState: IIts2DashReportingCountersState = {
    collection: [],
    ...StatusesMetadata.init()
};


export interface IIts2DashReportingState
{
    incidents: IIts2DashReportingCountersState
    services: IIts2DashReportingCountersState
}

export const Its2DashReportingInitialState: IIts2DashReportingState =
{
    incidents: Its2DashReportingCountersInitialState,
    services: Its2DashReportingCountersInitialState
};

