import { Request } from '@apps/its2/domain/models';
import { TypePopin } from '@apps/app.constants';
import { Its2Constants, RequestStatusIdEnum } from '@apps/its2/its2.constants';

import { SearchResult } from './search-result';

export class Its2RequestSearchResult extends SearchResult<Request>
{
    constructor (request: Request)
    {
        super('its2-request', request.rfcNumber);

        this.result = request;
        this.icon = 'fal fa-concierge-bell';

        this.statusText = request.statusEn.toUpperCase();
        this.statusClass = request.statusId === RequestStatusIdEnum.CLOSED ? 'warning' : 'success';

        this.link = {
            baseUrlFragment: `/${Its2Constants.BaseUrl}/${Its2Constants.Requests}`,
            outlet: TypePopin.DETAILS,
            params: [this.id]
        };
    }
}
