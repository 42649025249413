export enum LevelRole
{
    NOROLE = 0,
    READER = 1,
    USER = 2,
    ADMIN = 3,
    MANAGER = 4
}

class AppliRoleBase
{
    public static RoleSteps: string[] = ['NoRole', 'Reader', 'User', 'Admin', 'Manager'];

    public static RoleByLevel = (level: number): string =>
    {
        return AppliRoleBase.RoleSteps[level];
    };
    public static LevelByRole = (role: string): number =>
    {
        return (<any>LevelRole)[role.toUpperCase()];
    };
}

export class AppliRole extends AppliRoleBase
{
    constructor (public application: string, public role: number)
    {
        super();
    }
}
