import { Field4Filter } from './field-4filter';

export class BaseFields4Filter<T>
{
    public fields4Filter: Map<string, Field4Filter<T, any>>;

    constructor ()
    {
        this.fields4Filter = new Map<string, Field4Filter<T, any>>();
    }

    protected addField (f4f: Field4Filter<T, any>): void
    {
        if (this.fields4Filter.has(f4f.prop))
        {
            this.fields4Filter.delete(f4f.prop);
        }

        this.fields4Filter.set(f4f.prop, f4f);
    }
}
