import { Step1 } from './step1';
import { Step2 } from './step2';
import { Step3 } from './step3';
import { Step4 } from './step4';

export class CreateService
{
    step1: Step1;
    step2: Step2;
    step3: Step3;
    step4: Step4;
    // get description ()
    // {
    //     return `
    //         ${this.step2.toDescription()}
    //         ${this.step3.message}
    //         ${this.step4.toDescription()}
    //     `;
    // }

    constructor (currentLang: string, createService?: CreateService)
    {
        if (createService)
        {
            this.step1 = new Step1(currentLang, createService.step1);
            this.step2 = new Step2(currentLang, createService.step2);
            this.step3 = new Step3(currentLang, createService.step3);
            this.step4 = new Step4(currentLang, createService.step4);
        }
        else
        {
            this.step1 = new Step1(currentLang);
            this.step2 = new Step2(currentLang);
            this.step3 = new Step3(currentLang);
            this.step4 = new Step4(currentLang);
        }
    }
}
