import { CopyApi } from '@apps/bckp/api/models';
import { Fragment } from './fragment';

export class Copy
{
    residence: string;
    copyNumber: number;
    copyType: number;
    copyState: number;
    fragment?: Fragment[];
    size: number = 0;

    public static mapperFromApi (source: CopyApi): Copy
    {
        const target = new Copy();

        Object.assign(target, source);

        if ( source.fragment )
        {
            target.fragment = source.fragment.map(Fragment.mapperFromApi);
            target.size = target.fragment.reduce<number>((prev:number, curr: Fragment) => prev + curr.fragmentSize, 0);
        }

        return target;
    }
}
