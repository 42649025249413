import { Injectable } from '@angular/core';
import { ValidationError } from '@apps/app.constants';

@Injectable({
    providedIn: 'root'
})
export class IpAddressService
{
    private static ipv4Regexp = /^(?:(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(?:2[0-4]\d|25[0-5]|[01]?\d\d?)$/;
    private static ipv4CidrRegexp = /^(?:(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\/(?:(?:\d)|(?:[1-2]\d)|(?:3[0-2]))$/;
    private static ipv6Regexp = /^([0-9A-F]{4})(:([0-9A-F]{4})){7}$/i;
    // private static ipv4BlockCidrRegexp = /^.*\/(?:(?:\d)|(?:[1-2]\d)|(?:3[0-2]))$/i;

    public validateIpv4Address (value: string): ValidationError
    {
        if (!value)
        {
            return null;
        }

        return value.match(IpAddressService.ipv4Regexp) ? null : { ipv4Address: true };
    }

    public validateIpv4CidrAddress (value: string): ValidationError
    {
        if (!value)
        {
            return null;
        }

        return value.match(IpAddressService.ipv4CidrRegexp) ? null : { ipv4CidrAddress: true };
    }

    public validateIpv6Address (value: string): ValidationError
    {
        if (!value)
        {
            return null;
        }

        const fullValue = this.buildFullIpv6Address(value);

        return fullValue.match(IpAddressService.ipv6Regexp) ? null : { ipv6Address: true };
    }

    public validateIpv6CidrAddress (value: string): ValidationError
    {
        if (!value)
        {
            return null;
        }

        if (!this.validateIpv6CidrPart(value))
        {
            return { ipv6CidrAddress: true };
        }

        const fullValue = this.buildFullIpv6Address(value.split('/')[0]);

        return fullValue.match(IpAddressService.ipv6Regexp) ? null : { ipv6CidrAddress: true };
    }

    public validateIpv6CidrPart (value: string): boolean
    {
        const parts = value.split('/');

        if (parts.length !== 2)
        {
            return false;
        }

        const block = parts[1];
        if (!/^\d{1,3}$/.test(block))
        {
            return false;
        }

        const cidr = parseInt(block, 10);
        const values1 = [...Array(64)].map((_, y) => y);
        const values2 = [...Array(17)].map((_, y) => 64 + 4 * y);
        const values = [...values1, ...values2];

        return values.includes(cidr);
    }

    public buildFullIpv6Address (value: string): string
    {
        // Recherche du dual colon
        let exploded: string[] = value.split('::');
        let nonZeroBlocsLength: number = 0;

        exploded = exploded.map((part: string) =>
        {
            let subExploded: string[] = part.split(':');

            nonZeroBlocsLength += subExploded.length;
            subExploded = subExploded.map((bloc: string) =>
                bloc.padStart(4, '0')
            );

            return subExploded.join(':');
        });
        if (exploded.length === 2)
        {
            const zeroPart = ':0000'.repeat(8 - nonZeroBlocsLength);

            return `${exploded[0]}${zeroPart}:${exploded[1]}`;
        }

        return exploded[0];
    }
}
