import { Answer } from './answer';
import { StepBase } from './step-base';

export class Step2 extends StepBase
{
    answers: Answer[] = [];

    constructor (currentLang: string, step2?: Step2)
    {
        super(currentLang);
        // this.subTitle = this.translateService.instant('ITSM.CREATESERVICEREQUEST.STEPS.STEP2.TAB');

        if (step2)
        {
            Object.assign(this, step2);
        }
    }

    public toSummary (): string
    {
        if (this.answers.length === 0)
        {
            return '';
        }

        const summary = this.answers
            .map((answer: Answer) =>
                answer.toSummary(this.currentLang)
            )
            .join('');

        return `
            <div class="summary-part-title">
            ${summary}
            </div>
        `;
    }

    toDescription ()
    {
        if (this.answers.length === 0)
        {
            return '';
        }

        const description = this.answers
            .map((curr: Answer) =>
                curr.toDescription(this.currentLang)
            )
            .join('');

        return `
            ${this.subTitleDescription}
            <div>
                <ul>
                ${description}
                </ul>
            </div>
        `;
    }
}
