import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import { SecurityMiscDomainService } from '@apps/ostk/domain';
import * as fromOstkSecuritySgrpActions from './sgrp.actions';
import { NotfGravity, NotificationService, NotifType } from '@common/services';
import { SecurityGroup } from '@apps/ostk/domain/models';

@Injectable()
export class OstkSecurityGroupsEffects
{
    constructor(
        private actions: Actions,
        private securityMiscDomainService: SecurityMiscDomainService,
        private notificationService: NotificationService
    )
    { }

    ostkSecuritySgrpListRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpListRequested),
                    switchMap(() =>
                        this.securityMiscDomainService.getSecurityGroups()
                            .pipe(
                                map(securityGroups =>
                                    fromOstkSecuritySgrpActions.OstkSecuritySgrpListSucceeded({ securityGroups: securityGroups })
                                ),
                                catchError((failure: any) =>
                                    of(fromOstkSecuritySgrpActions.OstkSecuritySgrpListFailed({failure}))
                                )
                            )
                    )
                )
    );

    ostkSecuritySgrpDetailsRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpDetailsRequested),
                    switchMap(({ securityGroupId }) =>
                        this.securityMiscDomainService.getDetails(securityGroupId)
                            .pipe(
                                map((securityGroup: SecurityGroup) =>
                                    fromOstkSecuritySgrpActions.OstkSecuritySgrpDetailsSucceeded({ securityGroup: securityGroup })
                                )
                            )
                    )
                )
    );

    ostkSecurityRuleDeleteRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleDeleteRequested),
                    switchMap(({ securityRule }) =>
                        this.securityMiscDomainService.deleteSecurityRule(securityRule)
                            .pipe(
                                map(securityRuleId =>
                                    fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleDeleteSucceeded({ securityRuleId: securityRuleId })
                                ),
                                catchError((failure: any) =>
                                    of(fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleDeleteFailed({ failure: failure }))
                                )
                            )
                    )
                )
    );

    ostkSecuritySgrpRuleDeleteSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleDeleteSucceeded),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.SECURITY.RULE.DELETE.NOTIF.TITLE',
                                'OSTK.SECURITY.RULE.DELETE.NOTIF.MSGSUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );
                    })
                ),
        { dispatch: false }
    );

    ostkSecuritySgrpRuleDeleteFailed: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleDeleteFailed),
                    tap(() =>
                    {

                        this.notificationService.notify(
                            [
                                'OSTK.SECURITY.RULE.DELETE.NOTIF.TITLE',
                                'OSTK.SECURITY.RULE.DELETE.NOTIF.MSGFAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        );
                    })
                ),
        { dispatch: false }
    );

    ostkSecurityRuleCreateRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleCreateRequested),
                    switchMap(({ securityRule }) =>
                        this.securityMiscDomainService.createSecurityRule(securityRule)
                            .pipe(
                                map((newSecurityRule) =>
                                    fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleCreateSucceeded({ securityRule: newSecurityRule })
                                ),
                                catchError((failure: any) =>
                                    of(fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleCreateFailed({ failure: failure }))
                                )
                            )
                    )
                )
    );

    ostkSecuritySgrpRuleCreateSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleCreateSucceeded),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.SECURITY.RULE.CREATE.NOTIF.TITLE',
                                'OSTK.SECURITY.RULE.CREATE.NOTIF.MSGSUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );
                    })
                ),
        { dispatch: false }
    );

    ostkSecuritySgrpRuleCreateFailed: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpRuleCreateFailed),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.SECURITY.RULE.CREATE.NOTIF.TITLE',
                                'OSTK.SECURITY.RULE.CREATE.NOTIF.MSGFAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        );
                    })
                ),
        { dispatch: false }
    );

    ostkSecuritySgrpCreateRequested: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpCreateRequested),
                    switchMap(({ securityGroup }) =>
                        this.securityMiscDomainService.createSecurityGroup(securityGroup)
                            .pipe(
                                map((newSecurityGroup) =>
                                    fromOstkSecuritySgrpActions.OstkSecuritySgrpCreateSucceeded({ securityGroup: newSecurityGroup })
                                ),
                                catchError((failure: any) =>
                                    of(fromOstkSecuritySgrpActions.OstkSecuritySgrpCreateFailed({ failure: failure }))
                                )
                            )
                    )
                )
    );

    ostkSecuritySgrpCreateSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpCreateSucceeded),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.SECURITY.SECURITYGROUP.CREATE.NOTIF.TITLE',
                                'OSTK.SECURITY.SECURITYGROUP.CREATE.NOTIF.MSGSUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );
                    })
                ),
        { dispatch: false }
    );

    ostkSecuritySgrpCreateFailed: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpCreateFailed),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.SECURITY.SECURITYGROUP.CREATE.NOTIF.TITLE',
                                'OSTK.SECURITY.SECURITYGROUP.CREATE.NOTIF.MSGFAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        );
                    })
                ),
        { dispatch: false }
    );
    
    ostkSecuritySgrpDeleteRequested: Observable<Action> = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpDeleteRequested),
                switchMap(({ securityGroup }) =>
                    this.securityMiscDomainService.deleteSecurityGroup(securityGroup)
                        .pipe(
                            map(securityGroupId =>
                                fromOstkSecuritySgrpActions.OstkSecuritySgrpDeleteSucceeded({ securityGroupId: securityGroupId })
                            ),
                            catchError((failure: any) =>
                                of(fromOstkSecuritySgrpActions.OstkSecuritySgrpDeleteFailed({ failure: failure }))
                            )
                        )
                )
            )
    );

    ostkSecuritySgrpDeleteSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpDeleteSucceeded),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.SECURITY.SECURITYGROUP.DELETE.NOTIF.TITLE',
                                'OSTK.SECURITY.SECURITYGROUP.DELETE.NOTIF.MSGSUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );
                    })
                ),
        { dispatch: false }
    );

    ostkSecuritySgrpDeleteFailed: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkSecuritySgrpActions.OstkSecuritySgrpDeleteFailed),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.SECURITY.SECURITYGROUP.DELETE.NOTIF.TITLE',
                                'OSTK.SECURITY.SECURITYGROUP.DELETE.NOTIF.MSGFAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        );
                    })
                ),
        { dispatch: false }
    );
}
