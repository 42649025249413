import { Injectable } from '@angular/core';

declare var window: {
    [key: string]: any;
    prototype: Window;
    new(): Window;
};

@Injectable({
    providedIn: 'root'
})
export class MatomoTrackerService
{
    constructor()
    {
        try
        {
            if (typeof window._paq === 'undefined')
            {
                // tslint:disable-next-line: no-console
                console.warn('Matomo has not yet been initialized! (Did you forget to inject it?)');
            }
        } catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    trackPageView (url: string, documentTitle: string): void
    {
        try
        {
            window._paq.push(['enableHeartBeatTimer']);
            window._paq.push(['setPagePerformanceTiming', 0]);

            window._paq.push(['setCustomUrl', url]);

            if (documentTitle)
            {
                window._paq.push(['setDocumentTitle', documentTitle]);
            }

            window._paq.push(['trackPageView']);
        } catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    trackEvent (category: string, action: string, name?: string, value?: number): void
    {
        try
        {
            const args: any[] = [category, action];
            if (!!name)
            {
                args.push(name);
            }
            if (typeof value === 'number')
            {
                args.push(value);
            }
            window._paq.push(['trackEvent', ...args]);
        } catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    trackLink (url: string, linkType: string): void
    {
        const u = url.replace(/([\w/-[(]]*)(\(popinOutlet:)([\w]*)([/)].*)/i, '$1$3');

        try
        {
            window._paq.push(['trackLink', u, linkType]);
        } catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    setUserId (userId: string): void
    {
        try
        {
            window._paq.push(['setUserId', userId]);
        } catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    setCustomDimension (customDimensionId: number, customDimensionValue: string): void
    {
        try
        {
            window._paq.push(['setCustomDimension', customDimensionId, customDimensionValue]);
        } catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    resetUserId (): void
    {
        try
        {
            window._paq.push(['resetUserId']);
        } catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }

    appendToTrackingUrl (appendToUrl: string): void
    {
        try
        {
            window._paq.push(['appendToTrackingUrl', appendToUrl]);
        } catch (e)
        {
            if (!(e instanceof ReferenceError))
            {
                throw e;
            }
        }
    }
}
