import {
    ISecuDashBreakdownState,
    SecuDashBreakdownFeatureKey,
    SecuDashBreakdownInitialState
} from './breakdown/breakdown.state';
import { ISecuDashTicketingState, SecuDashTicketingFeatureKey, SecuDashboardTicketingInitialState } from './ticketing/ticketing.state';
import { ISecuDashReportingState, SecuDashReportingFeatureKey, SecuDashReportingInitialState } from './reporting/reporting.state';

export const SecuDashboardFeatureKey = 'dashboard';

export interface ISecuDashboardState
{
    [SecuDashTicketingFeatureKey]: ISecuDashTicketingState;
    [SecuDashBreakdownFeatureKey]: ISecuDashBreakdownState;
    [SecuDashReportingFeatureKey]: ISecuDashReportingState;
}

export const SecuDashboardInitialState: ISecuDashboardState = {
    [SecuDashTicketingFeatureKey]: SecuDashboardTicketingInitialState,
    [SecuDashBreakdownFeatureKey]: SecuDashBreakdownInitialState,
    [SecuDashReportingFeatureKey]: SecuDashReportingInitialState
};
