import { Languages } from '@common/enum';

export class LocalizedText
{
    [Languages.enUs]: string;
    [Languages.frFr]: string;

    constructor (enUs: string, frFr:string)
    {
        this[Languages.enUs] = enUs;
        this[Languages.frFr] = frFr;
    }
}
