import { ActivityByServerApi } from '@apps/bckp/api/models/activity';
import { BckpHeatConstants } from '@apps/bckp/store/servers/heat/bckp-heat.constants';
import { Assetify9, IDomainized } from '@common/domain';

import { ActivityByDateDomain, ActivityByDate } from './activity-by-date';

export class ActivityByServer extends Assetify9 implements IDomainized
{
    public activityId: string;
    public details: ActivityByDate[];
    public isDomainized: boolean;
    public hasIssues: boolean;
    public successRates: {[key: string]: number};
    public assetName = (): string => this.server;

    constructor (public server: string, public master: string = '')
    {
        super((): string => this.server);

        this.details = [];
        this.activityId = `${this.server}-${this.master}`;
    }
}

export class ActivityByServerDomain extends ActivityByServer
{
    public static mapperFromApi (source: ActivityByServerApi): ActivityByServer
    {
        const target = new ActivityByServer(source.serverShort, source.master);

        target.details = source.details
            .map((detail: ActivityByDate): ActivityByDate=>
                ({
                    ...new ActivityByDate(source.serverShort, source.master),
                    ...detail
                } as ActivityByDate)
            );
        target.isDomainized = false;

        return target;
    }

    public static mapperToView (source: ActivityByServer): ActivityByServer
    {
        const target = new ActivityByServer(source.server, source.master);

        target.tagIds = source.tagIds;
        target.tagFullNames = source.tagFullNames;
        target.isDomainized = true;
        target.details = source.details.map(ActivityByDateDomain.mapperToView);
        const summary: ActivityByDate = ActivityByServerDomain.computeSummary(source.server, source.master, target.details);
        target.details.push(summary);

        // Préparation des colonnes de tri
        target.successRates = {};
        target.details.forEach((detail: ActivityByDate): number =>
            target.successRates[detail.dateExec] = detail.successRate
        );
        const totalDetails = target.details.find((abd: ActivityByDate): boolean =>
            abd.isServerTotal
        );
        target.hasIssues = (totalDetails.nbJobs !== totalDetails.nbFullyJobs);

        return target;
    }

    public static computeSummary (server: string, master: string, details: ActivityByDate[]): ActivityByDate
    {
        const activityByDate = new ActivityByDate(server, master);
        const lastIdx = details.length - 1;

        details.reduce((prev: ActivityByDate, curr: ActivityByDate, idx: number): ActivityByDate =>
        {
            prev.nbFailedJobs += curr.nbFailedJobs;
            prev.nbPartiallyJobs += curr.nbPartiallyJobs;
            prev.nbFullyJobs += curr.nbFullyJobs;
            prev.nbJobs += curr.nbJobs;
            if (idx === 0)
            {
                prev.since = curr.dateJobs;
            }

            if (idx === lastIdx)
            {
                prev.until = curr.dateJobs;
            }

            return prev;
        }, activityByDate);

        activityByDate.dateExec = BckpHeatConstants.TotalSorterKey;
        activityByDate.isServerTotal = true;
        activityByDate.successRate = BckpHeatConstants.computeSuccessRate(activityByDate.nbJobs, activityByDate.nbFullyJobs);

        return activityByDate;
    }
}
