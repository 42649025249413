import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseMiscApiService } from 'src/app/_common/api/base-misc-api.service';
import { ProjectApi, ReferenceApi } from '../models/billing';
import { AppConstants } from 'src/app/_apps/app.constants';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BillingMiscApiService extends BaseMiscApiService
{

    constructor (
        httpClient: HttpClient
    )
    {
        super(httpClient);

        this.segmentRoot = AppConstants.Configuration.apiUrlOstk;
        this.segmentApi = '/ostk/billing';
    }

    getReferences (): Observable<ReferenceApi[]>
    {
        if (AppConstants.IsDataLocal('ostk'))
        {
            return this.http.get<any[]>('assets/data/ostk/prices.json');
        }

        return this.get<ReferenceApi[]>('/references');
    }

    getProject (): Observable<ProjectApi>
    {
        if (AppConstants.IsDataLocal('ostk'))
        {
            return this.http.get<ProjectApi[]>('assets/data/ostk/projects.json')
                .pipe(
                    map(projects =>
                    {
                        return projects[0];
                    })
                );
        }

        return this.get<ProjectApi>('/project');
    }
}
