import { props, createAction } from '@ngrx/store';

import {
    Its2RequestSearchResult,
    BckpJobSearchResult,
    BckpServerSearchResult,
    BackAuthEntitySearchResult,
    BackAuthUserSearchResult,
    BckpPolicySearchResult,
    CompInstanceSearchResult
} from 'src/app/_common/models/search-result';

export const RootSearchInitialized = createAction(
    '[ROOT-SEARCH] Root Search Initialized',
    props<{ searchValue: string }>()
);

export const RootSearchEnterKey = createAction(
    '[ROOT-SEARCH] Root Search Enter Key',
    props<{ searchValue: string }>()
);

export const RootSearchRequested = createAction(
    '[ROOT-SEARCH] Root Search Requested'
);

export const RootSearchCanceled = createAction(
    '[ROOT-SEARCH] Root Search Canceled'
);


export const RootSearchResultsIts2RequestsLoaded = createAction(
    '[ROOT-SEARCH] Root Search Results Its2 Requests Loaded',
    props<{ result: Its2RequestSearchResult[] }>()
);

export const RootSearchResultsBckpJobsLoaded = createAction(
    '[ROOT-SEARCH] Root Search Results Bckp Jobs Loaded',
    props<{ result: BckpJobSearchResult[] }>()
);

export const RootSearchResultsBckpServersLoaded = createAction(
    '[ROOT-SEARCH] Root Search Results Bckp Servers Loaded',
    props<{ result: BckpServerSearchResult[] }>()
);

export const RootSearchResultsBckpPoliciesLoaded = createAction(
    '[ROOT-SEARCH] Root Search Results Bckp Policies Loaded',
    props<{ result: BckpPolicySearchResult[] }>()
);

export const RootSearchResultsCompInstancesLoaded = createAction(
    '[ROOT-SEARCH] Root Search Results Comp Instances Loaded',
    props<{ result: CompInstanceSearchResult[] }>()
);

export const RootSearchResultsBackAuthEntitiesLoaded = createAction(
    '[ROOT-SEARCH] Root Search Results Back Auth Entities Loaded',
    props<{ result: BackAuthEntitySearchResult[] }>()
);

export const RootSearchResultsBackAuthUsersLoaded = createAction(
    '[ROOT-SEARCH] Root Search Results Back Auth Users Loaded',
    props<{ result: BackAuthUserSearchResult[] }>()
);

export const RootSearchResultsClearRequested = createAction(
    '[ROOT-SEARCH] Root Search Results Requested'
);

export const RootSearchResultsCleared = createAction(
    '[ROOT-SEARCH] Root Search Results Cleared'
);
