export class PolicyForFilter
{
    name: string;
    flag: number;
    constructor(name: string, flag: number)
    {
        this.name = name;
        this.flag = flag;
    }
}
