import { ODataSearch } from 'src/app/_common/api/qs';
import { AppConstants } from '../../app.constants';

export class ODataCompInstancesSearch extends ODataSearch
{
    constructor(searchValue: string)
    {
        super(searchValue, 0);

        // Options de tri
        // this.pushValue('$orderby', 'expiration:asc');

        if (this.searchValue !== null)
        {
            this.searchValue = `${searchValue}*`;
            this.pushValue('$search', this.searchValue);
        } else
        {
            const idx = this.values.findIndex((item: { key: string, value: any }) => item.key === '$search');

            this.values = this.values.splice(idx, 1);
        }
    }
}
