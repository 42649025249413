import { Component, Input } from '@angular/core';

import { ControlStatus } from '../../common/control-status';

@Component({
    selector: 'aa-field-msg-error',
    templateUrl: './field-msg-error.component.html',
    styleUrls: [
        './field-msg-error.component.scss'
    ],
})
export class FieldMsgErrorComponent
{
    @Input() status: ControlStatus;
}
