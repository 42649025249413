import { createAction, props } from '@ngrx/store';
import { Step1, Step2 } from '../../models/instance';

export const OstkInstancesCreateStep1Exited = createAction(
    '[OSTK-MISC] Ostk Instances Create Step1 Exited',
    props<{ step1: Step1 }>()
);

export const OstkInstancesCreateStep2Exited = createAction(
    '[OSTK-MISC] Ostk Instances Create Step2 Exited',
    props<{ step2: Step2 }>()
);

export const OstkInstancesCreateReset = createAction(
    '[OSTK-MISC] Ostk Instances Create Reset'
);
