import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { UsersMiscDomainService } from '@apps/auth/domain/users-misc-domain.service';
import { ShortProfile } from '@apps/auth/domain/models';

import * as fromAuthUsersActions from './users.actions';

@Injectable()
export class AuthUsersEffects
{
    constructor (
        private actions: Actions,
        private usersMiscDomainService: UsersMiscDomainService,
    )
    {
    }

    authUsersRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthUsersActions.AuthUsersRequested),
                    concatMap(({ userName }: {userName: string}) =>
                        this.usersMiscDomainService.shortProfile(userName)
                            .pipe(
                                map((user: ShortProfile) =>
                                    fromAuthUsersActions.AuthUsersSucceeded({ user })
                                ),
                                catchError(() =>
                                    of(fromAuthUsersActions.AuthUsersFailed())
                                )
                            )
                    )
                )
    );
}
