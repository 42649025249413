import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare const window: {
    [key: string]: any;
    prototype: Window;
    new(): Window;
};

@Injectable({
    providedIn: 'root'
})
export class MatomoInjectorService
{
    private fileName: string = 'm';

    constructor (@Inject(PLATFORM_ID) private platformId)
    {
        if (isPlatformBrowser(this.platformId))
        {
            window._paq = window._paq || [];
        }
        else
        {
            console.warn('MatomoInjector can\'t be used on server platform');
        }
    }

    init (url: string, id: string)
    {
        if (isPlatformBrowser(this.platformId))
        {
            (() =>
            {
                try
                {
                    const urlFile = `${url}${this.fileName}`;
                    const d = document;
                    const g = d.createElement('script');
                    const s = d.getElementsByTagName('script')[0];

                    window._paq.push(['setTrackerUrl', `${urlFile}.php`]);
                    window._paq.push(['setSiteId', id]);
                    g.type = 'text/javascript';
                    g.async = true;
                    g.defer = true;
                    g.src = `${urlFile}.js`;
                    s.parentNode.insertBefore(g, s);
                }
                catch (_)
                {
                }
            })();
        }
    }
}
