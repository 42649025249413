import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { concatAll, map, tap, toArray } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { arrayMapper } from '@common/operators';
import { CatalogEntry, DomainCatalogEntry } from '@apps/its2/models';
import { CatalogEntryQuestion, DomainCalalogEntryQuestion } from '@apps/its2/domain/models';
import { CatalogEntryQuestionApi } from '@apps/its2/api/models';
import { Its2EndpointGuid } from '@apps/bckp/bckp.constants';
import { IAuroraState } from '@apps/aurora.state';
import { LanguageService } from '@common/services';
import { BckpLoadPoliciesRequested, BckpLoadServersRequested } from '@apps/bckp/store/common/options/options.actions';

import { CatalogMiscApiService } from '../api';

@Injectable({
    providedIn: 'root'
})
export class CatalogMiscDomainService extends BaseMiscDomainService
{

    constructor (
        private store: Store<IAuroraState>,
        private catalogMiscApiService: CatalogMiscApiService,
        private languageService: LanguageService,
    )
    {
        super();
    }

    getCatalogsIncident (): Observable<CatalogEntry[]>
    {
        return this.catalogMiscApiService.getCatalogsIncident()
            .pipe(
                arrayMapper(DomainCatalogEntry.mapperFromApi)
            );
    }

    getCatalogsService (): Observable<CatalogEntry[]>
    {
        return this.catalogMiscApiService.getCatalogsService()
            .pipe(
                arrayMapper(DomainCatalogEntry.mapperFromApi)
            );
    }

    getQuestions (catalogGuid: string): Observable<CatalogEntryQuestion[]>
    {
        return this.catalogMiscApiService.getQuestions(catalogGuid)
            .pipe(
                concatAll(),
                map((item: CatalogEntryQuestionApi) =>
                    DomainCalalogEntryQuestion.mapperFromApi(item, this.languageService.getShortItsmLanguage().toUpperCase())
                ),
                tap((item: CatalogEntryQuestion) =>
                {
                    if (item.hasEndpoint)
                    {
                        switch (item.endpointGuid)
                        {
                            case Its2EndpointGuid.Server:
                                this.store.dispatch(BckpLoadServersRequested());

                                break;

                            case Its2EndpointGuid.Policy:
                                this.store.dispatch(BckpLoadPoliciesRequested());

                                break;
                        }
                    }
                }),
                toArray()
            );
    }

    /**
     *
     * @returns {boolean} Suivant que l'utilisateur a droit ou non à ce catalogue
     */
    isSecuCatalogAuthorized (): Observable<boolean>
    {
        return this.catalogMiscApiService.isSecuCatalogAuthorized();
    }
}
