import { Injectable, Injector, Type } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { PopinModalRef } from './popin-modal.ref';
import { PopinModalComponent } from './popin-modal/popin-modal.component';
import { PopinModalConfig } from './popin-modal.config';
import { PopinComponentBase } from './popin-component-base';

@Injectable({
    providedIn: 'root'
})
export class PopinModalService
{
    private static defaultConfig = {
        width: 52,
        hasBackdrop: true
    } as PopinModalConfig;
    public overlayRef: OverlayRef = null;
    private config: PopinModalConfig = new PopinModalConfig();

    constructor(private overlay: Overlay, private parentInjector: Injector)
    {
    }

    create (content: Type<PopinComponentBase>, config?: PopinModalConfig, data?: any): PopinModalRef
    {
        Object.assign(this.config, PopinModalService.defaultConfig);
        if (config !== undefined)
        {
            Object.assign(this.config, config);
        }

        this.overlayRef = this.overlay.create(this.getOverlayConfig());
        const modalRef = new PopinModalRef(this.overlayRef, content, config, data);
        const injector = this.createInjector(modalRef, this.parentInjector);

        this.overlayRef.attach(new ComponentPortal(PopinModalComponent, null, injector));

        return modalRef;
    }

    createInjector (modalRef: PopinModalRef, injector: Injector)
    {
        const tokens = new WeakMap([[PopinModalRef, modalRef]]);

        return new PortalInjector(injector, tokens);
    }

    private getOverlayConfig (): OverlayConfig
    {
        return new OverlayConfig({
            width: `${this.config.width}vw`,
            height: '100vh',
            minWidth: `${this.config.width}vw`,
            minHeight: '100vh',
            hasBackdrop: this.config.hasBackdrop,
            backdropClass: 'popin-modal-backdrop',
            positionStrategy: this.getOverlayPosition(),
            scrollStrategy: this.overlay.scrollStrategies.reposition()
        });
    }

    private getOverlayPosition (): PositionStrategy
    {
        return this.overlay
            .position()
            .global()
            .right('0')
            .centerVertically();
    }
}
