import { Component, Host, Input, OnInit } from '@angular/core';

import { SelectOption9 } from '@ui/components/form9/common';

import { InputCheckbox9Component } from '../input-checkbox9.component';

@Component({
    selector: 'aa-checkbox9',
    templateUrl: './checkbox9.component.html'
})
export class Checkbox9Component implements OnInit
{
    @Input() name: string;
    @Input() option: SelectOption9;
    public isChecked: boolean = false;
    public optionId: string;

    constructor (@Host() private inputCheckbox9: InputCheckbox9Component)
    {
    }

    ngOnInit (): void
    {
        this.optionId = `id-${this.option.label}-${this.option.value}`;
        this.isChecked = this.inputCheckbox9.contains(this.option.value);
    }

    ngAfterContentInit (): void
    {
        this.isChecked = this.inputCheckbox9.contains(this.option.value);
    }

    toggleCheck (): void
    {
        this.inputCheckbox9.addOrRemove(this.option.value);
        this.isChecked = this.inputCheckbox9.contains(this.option.value);
    }
}
