import { Injector } from '@angular/core';

import { DateTime } from 'luxon';

import { DatesService } from '@common/services';
import { RangeKey } from '@common/enum';
import { AppConstants } from '@apps/app.constants';

export class ServersFilter
{
    public datesService: DatesService;
    private _since: string;
    get since ()
    {
        return this._since;
    }
    private _until: string;
    get until ()
    {
        return this._until;
    }
    private _rangeSince: DateTime;
    get rangeSince (): DateTime
    {
        return this._rangeSince;
    }
    set rangeSince (value: DateTime)
    {
        this._rangeSince = value;
        this._since = this.datesService.DateToQueryString(this._rangeSince);
    }
    private _rangeUntil: DateTime;
    get rangeUntil (): DateTime
    {
        return this._rangeUntil;
    }
    set rangeUntil (value: DateTime)
    {
        this._rangeUntil = value;
        this._until = this.datesService.DateToQueryString(this._rangeUntil);
    }
    public master: string;
    public serverShort: string;
    public policyName: string;
    public policyType: string;
    public scheduleType: number[];

    constructor ()
    {
        const injector = Injector.create({ providers: [{ provide: DatesService, deps: [] }] });
        this.datesService = injector.get(DatesService);

        const range = this.datesService.preRangeDates(RangeKey.CURRENT7DAYS);

        this.rangeSince = range.since;
        this.rangeUntil = range.until;
    }

    public static mapperFromObject (source: any): ServersFilter
    {
        const target = new ServersFilter();

        Object.assign(target, source);

        return target;
    }
}
