<div
    class="popin"
    [@showHide]="isShown ? 'show' : 'hide'"
    [style.width.vw]="width"
    [style.right.vw]="calcRight()"
>
    <div class="popin-title">
        <div class="h1 popin-text">
            <ng-content select="span.title"></ng-content>
        </div>
        <div class="h4 popin-close" (click)="closePopin()">
            <aa-action-circle class="blueprune1 duotone" [faIcon]="'fal fa-times'"></aa-action-circle>
        </div>
    </div>
    <ng-content></ng-content>
</div>
