import { Component, OnInit, forwardRef, Injector, Input, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputBase } from '@ui/components/form/base/input-base';

import { OptionValue, SelectOption } from '../../../common';

@Component({
    selector: 'aa-radio-group3',
    templateUrl: './radio-group3.component.html',
    styleUrls: [
        '../../input/input.component.scss',
        './radio-group3.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioGroup3Component),
            multi: true
        }
    ]
})
export class RadioGroup3Component extends InputBase<OptionValue> implements OnInit
{
    @Input() name: string;
    @Input() options: SelectOption[];
    @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
    @Input() toTranslate: boolean = false;
    @Input() lineSize: number = 0;
    @Input() marginTop: number = 20;
    @Output() public clickedRadio: EventEmitter<string | number | boolean> = new EventEmitter();
    public lines: number[];

    constructor (
        injector: Injector
    )
    {
        super(injector);
        // if (this.required === 'required')
        // {
        //     this.required = 'unchecked';
        // }
    }

    ngOnInit ()
    {
        if (this.lineSize !== 0)
        {
            const nbLines = Math.ceil(this.options.length / this.lineSize);

            this.lines = Array.from(Array(nbLines));
        }
    }

    toggle (v: OptionValue)
    {
        this.value = v;
        this.clickedRadio.emit(v);
    }

    // validate ({ value }: FormControl)
    // {
    //     if (value)
    //     {
    //         console.debug({ value });
    //         return this.required && value ? { 'unchecked': true } : null;
    //     }
    // }
}
