import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';

import { AlertApi } from './models';
import { AppConstants } from '../../app.constants';

@Injectable({
    providedIn: 'root'
})
export class AlertMiscApiService extends BaseMiscApiService
{
    constructor (protected http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlAuth;
        this.segmentApi = '/auth/alerts';
    }

    getAll (): Observable<AlertApi[]>
    {
        return this.get<AlertApi[]>('/');
    }
}
