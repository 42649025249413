import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { filter, tap, takeUntil } from 'rxjs/operators';

import * as fromAuthStore from 'src/app/_apps/auth/store';
import { IAuroraState } from 'src/app/_apps/aurora.state';
import { Profile } from 'src/app/_apps/auth/models/profile';

@Component({
    selector: 'aa-profile-step4',
    templateUrl: './profile-step4.component.html',
    styleUrls: [
        '../profile.component.scss',
        './profile-step4.component.scss'
    ]
})
export class ProfileStep4Component implements OnInit, OnDestroy
{
    private destroyator = new Subject();
    public profile: Profile;
    public form: UntypedFormGroup;
    public working: boolean;
    public statistics: boolean;
    public social: boolean;
    public marketing: boolean;
    public maintenance: boolean;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private store: Store<IAuroraState>)
    {
        this.store
            .pipe(
                select(fromAuthStore.getProfile),
                filter((p: Profile) => p !== null),
                tap((p: Profile) =>
                {
                    this.profile = p;
                    this.working = !!p.workingCookies;
                    this.statistics = !!p.statisticsCookies;
                    this.social = !!p.socialNetworksCookies;
                    this.marketing = !!p.marketingNewsletter;
                    this.maintenance = !!p.maintenanceNotification;

                    this.createForm();
                }),
                takeUntil(this.destroyator)
            )
            .subscribe();
    }

    ngOnInit ()
    {
    }

    ngOnDestroy (): void
    {
        this.destroyator.next(null);
        this.destroyator.complete();
    }

    onSubmit = () =>
    {
        const profile: Profile = {
            workingCookies: this.form.get('workingCookies').value,
            statisticsCookies: this.form.get('statisticsCookies').value,
            socialNetworksCookies: this.form.get('socialNetworksCookies').value,
            marketingNewsletter: this.form.get('marketingNewsletter').value,
            maintenanceNotification: this.form.get('maintenanceNotification').value
        };
        this.store.dispatch(fromAuthStore.AuthProfileStoreActions.AuthProfileStep4Changed({ profile }));

    }

    // onContinue = () =>
    // {
    //     this.store.dispatch(fromAuthStore.AuthProfileStoreActions.AuthProfileStep4Continued());
    // }

    onCancel = () =>
    {
        this.store.dispatch(fromAuthStore.AuthProfileStoreActions.AuthProfileStepCanceled());
    }

    private createForm ()
    {
        this.form = this.formBuilder.group({
            workingCookies: this.working,
            statisticsCookies: this.statistics,
            socialNetworksCookies: this.social,
            marketingNewsletter: this.marketing,
            maintenanceNotification: this.maintenance
        });
    }
}
