import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter, Update } from '@ngrx/entity';
import { DateTime } from 'luxon';

import { ImageSnapshot, Instance, InstanceTimelineGroup } from '../../domain/models';
import * as fromInstancesActions from './instances.actions';
import * as fromOstkProjectsActions from '../projects/projects.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { InstanceFields4Filter } from '../../models/filters/instance-fields-4filter';
import { SelectOption2 } from '@ui/components/form/common/select-option2';
import { SelectOption } from '@ui/components/form/common';
import { OstkConstants } from '../../ostk.constants';
import { UtilsService } from '@common/services';

export const OstkInstFeatureKey = 'instances';
type IOstkInstCollectionState = EntityState<Instance>;
export const OstkInstAdapter: EntityAdapter<Instance> = createEntityAdapter({
    selectId: (instance: Instance) => instance.id,
    sortComparer: (insta: Instance, instb: Instance) => insta.name.localeCompare(instb.name)
});
const instInitialState: IOstkInstCollectionState = OstkInstAdapter.getInitialState({});
export interface IOstkInstState
{
    instances: IOstkInstCollectionState;
    filteredInstances: Instance[];
    filter: InstanceFields4Filter;
    isEmpty: boolean;
    isLoading: boolean;
    isLoaded: boolean;
    isOups: boolean;
    selectedInstance: Instance;
    instanceTimelineGroups: InstanceTimelineGroup[];
    operatingSystems: SelectOption2[];
    vcpu: SelectOption[];
    ram: SelectOption[];
    expirationDate?: DateTime;
    qsWord: string;
    switchFilter: boolean;
}

export const OstkInstInitialState: IOstkInstState = {
    instances: instInitialState,
    filteredInstances: [],
    filter: new InstanceFields4Filter(),
    isEmpty: false,
    isLoading: true,
    isLoaded: false,
    isOups: false,
    selectedInstance: null,
    instanceTimelineGroups: [],
    operatingSystems: [],
    vcpu: [],
    ram: [],
    expirationDate: null,
    qsWord: '',
    switchFilter: true
};

export const OstkInstancesReducer = createReducer(
    OstkInstInitialState,
    on(
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        fromOstkProjectsActions.OstkProjectSelected,
        () => OstkInstInitialState
    ),
    on(
        fromInstancesActions.OstkInstancesListRequested,
        (state) => ({
            ...state,
            isLoading: !(state.expirationDate !== null && DateTime.utc() < state.expirationDate)
        })
    ),
    on(
        fromInstancesActions.OstkInstancesListSucceeded,
        (state, { instances }: { instances: Instance[]; }) => ({
            ...state,
            isLoading: false,
            isLoaded: instances && instances.length !== 0,
            isEmpty: instances && instances.length === 0,
            isOups: false,
            instances: OstkInstAdapter.addMany(instances, state.instances),
            expirationDate: DateTime.utc().plus({ minutes: OstkConstants.DataLifeTime })
        })
    ),
    on(
        fromInstancesActions.OstkInstancesListFailed,
        (state) => ({
            ...state,
            isLoaded: false,
            isLoading: false,
            isEmpty: false,
            isOups: true,
            instances: OstkInstAdapter.removeAll(state.instances)
        })
    ),
    on(
        fromInstancesActions.OstkInstancesCreationAccepted,
        (state, { instance }: { instance: Instance }) => ({
            ...state,
            instances: OstkInstAdapter.addOne(instance, state.instances)
        })
    ),
    on(
        fromInstancesActions.OstkInstancesCreationSucceeded,
        (state, { instance }: { instance: Instance; }) =>
            ({
                ...state,
                instances: OstkInstAdapter.addOne(instance, OstkInstAdapter.removeOne(UtilsService.hash(instance.name), state.instances))
            })
    ),
    on(
        fromInstancesActions.OstkInstanceDetailsSucceeded,
        (state, { selectedInstance }) => ({
            ...state,
            selectedInstance: selectedInstance
        })
    ),
    on(
        fromInstancesActions.OstkInstanceHistorySucceeded,
        (state, { instanceTimelineGroups }) => ({
            ...state,
            instanceTimelineGroups: instanceTimelineGroups
        })
    ),
    on(
        fromInstancesActions.OstkInstancesApplyFilter,
        (state, { if4f }) => ({
            ...state,
            isLoading: true,
            isEmpty: false,
            isOups: false,
            filter: new InstanceFields4Filter(if4f)
        })
    ),
    on(
        fromInstancesActions.OstkInstancesFilterApplied,
        (state, { instances }) => ({
            ...state,
            isEmpty: instances && instances.length === 0,
            isLoading: false,
            filteredInstances: instances
        })
    ),
    on(
        fromInstancesActions.OstkInstancesOperatingSystemValues,
        (state, { os }) => ({
            ...state,
            operatingSystems: os
        })
    ),
    on(
        fromInstancesActions.OstkInstancesVcpusValues,
        (state, { vcpus: vcpu }) => ({
            ...state,
            vcpu: vcpu
        })
    ),
    on(
        fromInstancesActions.OstkInstancesRamValues,
        (state, { ram }) => ({
            ...state,
            ram: ram
        })
    ),
    on(
        fromInstancesActions.OstkInstanceStartSucceeded,
        fromInstancesActions.OstkInstancePauseSucceeded,
        fromInstancesActions.OstkInstanceStopSucceeded,
        (state, { instance }: { instance: Instance; }) => ({
            ...state,
            instances: OstkInstAdapter.upsertOne(instance, state.instances)
        })
    ),
    on(
        fromInstancesActions.OstkInstancesBySecurityGroupSucceeded,
        (state, { instancesMap }) => ({
            ...state,
            instancesBySecurityGroup: instancesMap
        })
    ),
    on(
        fromInstancesActions.OstkInstancesExpireDate,
        (state) => ({
            ...state,
            expirationDate: null,
            //isLoaded: false
        })
    ),
    on(
        fromInstancesActions.OstkInstancesManageSecurityGroupsSucceeded,
        (state, { instance }: { instance: Instance; }) =>
        {
            const update: Update<Instance> = {
                id: instance.id,
                changes: {
                    securityGroups: instance.securityGroups
                }
            };

            return {
                ...state,
                instances: OstkInstAdapter.updateOne(update, state.instances)
            };
        }
    ),
    on(
        fromInstancesActions.OstkInstancesQsWordSet,
        (state, { qsWord }) =>
            ({
                ...state,
                qsWord: qsWord.toLowerCase()
            })
    ),
    on(
        fromInstancesActions.OstkInstancesSwitchFilterSet,
        (state, { qsf } : {qsf: boolean}) =>
            ({
                ...state,
                switchFilter: qsf
            })
    ),
    on(
        fromInstancesActions.OstkInstanceTakeSnapshotSucceeded,
        (state, { snapshot } : { snapshot: ImageSnapshot }) =>
        {
            const selectedInstance : Instance = state.selectedInstance;
            selectedInstance.snapshots = selectedInstance.snapshots ?? [];
            selectedInstance.snapshots.push(snapshot);

            const instance: Update<Instance> = {
                id: selectedInstance.id,
                changes: {
                    snapshots: selectedInstance.snapshots
                }
            };

            return ({
                ...state,
                instances: OstkInstAdapter.updateOne(instance, state.instances),
                selectedInstance: selectedInstance
            });
        }
    ),
    on(
        fromInstancesActions.OstkInstanceDeleteSucceeded,
        (state, { instanceId }) =>
            ({
                ...state,
                instances: OstkInstAdapter.removeOne(instanceId, state.instances),
                filteredInstances: state.filteredInstances.filter(i => i.id !== instanceId)
            })
    ),
    on(
        fromInstancesActions.OstkInstanceChanged,
        (state, { instance }: { instance: Update<Instance> }) =>
        {
            const instances: IOstkInstCollectionState = OstkInstAdapter.updateOne(instance, state.instances);

            const filteredInstances = state.filteredInstances.map(inst =>
                inst.id === instance.id ? instances.entities[instance.id] : inst
            );

            return {
                ...state,
                instances: OstkInstAdapter.updateOne(instance, state.instances),
                filteredInstances: filteredInstances
            };
        }
    )
);
