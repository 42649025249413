import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap, take, map } from 'rxjs/operators';

import { IAuroraState } from '@apps/aurora.state';
import * as fromAuthStore from '@apps/auth/store';
import { getCounterTwoFactorTries } from '@apps/auth/store/auth.reducer';

@Component({
    selector: 'aa-two-factor-login',
    templateUrl: './two-factor-login.component.html',
    styleUrls: [
        './two-factor-login.component.scss'
    ]
})
export class TwoFactorLoginComponent implements OnInit
{
    public remainingTries: Observable<number>;
    public readonly CodeField = 'code';
    formGroup: UntypedFormGroup;
    idToken: string;

    constructor (
        private route: ActivatedRoute,
        private store: Store<IAuroraState>,
        private formBuilder: UntypedFormBuilder
    )
    {
        this.route.paramMap
            .pipe(
                take(1),
                tap((params: ParamMap): void =>
                {
                    this.idToken = params.get('idtoken');
                })
            )
            .subscribe();
    }

    ngOnInit ()
    {
        this.formGroup = this.formBuilder.group({
            [this.CodeField]: ['', Validators.required]
        });

        this.remainingTries = this.store.select(getCounterTwoFactorTries);
    }

    onSubmit = () =>
    {
        if (this.formGroup.invalid)
        {
            for (const control in this.formGroup.controls)
            {
                this.formGroup.controls[control].markAsDirty();
            }

            return;
        }

        this.store.dispatch(fromAuthStore.AuthLoginStoreActions.AuthTwoFactorLoginRequested({
            idToken: this.idToken,
            code: this.formGroup.get(this.CodeField).value
        }));
    };
}
