import { createAction, props } from '@ngrx/store';

import { SelectOption } from '@ui/components/form/common';
import { SelectOption2 } from '@ui/components/form/common/select-option2';
import { OstkFailure } from '@common/models';
import { ManageSecurityGroupsViewModel } from '@apps/ostk/models/instance';

import { ImageSnapshot, Instance } from '../../domain/models';
import { InstanceTimelineGroup, SimpleInstance } from '../../domain/models/instance';
import { InstanceFields4Filter } from '../../models/filters/instance-fields-4filter';
import { Update } from '@ngrx/entity';

export const OstkInstancesListRequested = createAction(
    '[OSTK-INST] Ostk Instances List Requested'
);
export const OstkInstancesListSucceeded = createAction(
    '[OSTK-INST] Ostk Instances List Loaded',
    props<{ instances: Instance[]; }>()
);
export const OstkInstancesListFailed = createAction(
    '[OSTK-INST] Ostk Instances List Failed',
    props<{ failure: OstkFailure; }>()
);

export const OstkInstanceDetailsRequested = createAction(
    '[OSTK-INST] Ostk Instance Details Requested',
    props<{ instanceId: string; }>()
);
export const OstkInstanceDetailsSucceeded = createAction(
    '[OSTK-INST] Ostk Instance Details Succeeded',
    props<{ selectedInstance: Instance; }>()
);
export const OstkInstanceDetailsFailed = createAction(
    '[OSTK-INST] Ostk Instance Details Failed',
    props<{ failure: OstkFailure; }>()
);

export const OstkInstanceRefreshRequested = createAction(
    '[OSTK-INST] Ostk Instance Refresh Requested',
    props<{ id: string; }>()
);
export const OstkInstanceRefreshSucceeded = createAction(
    '[OSTK-INST] Ostk Instance Refresh Succeeded',
    props<{ instance: Instance; }>()
);
export const OstkInstanceRefreshFailed = createAction(
    '[OSTK-INST] Ostk Instance Refresh Failed',
    props<{ failure: OstkFailure; }>()
);

export const OstkInstanceHistoryRequested = createAction(
    '[OSTK-INST] Ostk Instance History Requested',
    props<{ instanceId: string; }>()
);
export const OstkInstanceHistorySucceeded = createAction(
    '[OSTK-INST] Ostk Instance History Succeeded',
    props<{ instanceTimelineGroups: InstanceTimelineGroup[]; }>()
);
export const OstkInstanceHistoryFailed = createAction(
    '[OSTK-INST] Ostk Instance History Failed',
    props<{ failure: OstkFailure; }>()
);

export const OstkInstancesCreationRequested = createAction(
    '[OSTK-INST] Ostk Instances Creation Requested'
);
export const OstkInstancesCreationAccepted = createAction(
    '[OSTK-INST] Ostk Instances Creation Accepted',
    props<{ instance: Instance; }>()
);
export const OstkInstancesCreationSucceeded = createAction(
    '[OSTK-INST] Ostk Instances Creation Succeeded',
    props<{ instance: Instance; }>()
);
export const OstkInstancesCreationFailed = createAction(
    '[OSTK-INST] Ostk Instances Creation Failed',
    props<{ failure: OstkFailure; }>()
);
export const OstkInstancesPollingStatusRequested = createAction(
    '[OSTK-VOLU] Ostk Instances Polling Status Requested',
    props<{ instance: Instance; }>()
);

export const OstkInstancesSwitchFilterSet = createAction(
    '[OSTK-INST] Ostk Instances Switch Filter Set',
    props<{ qsf: boolean; }>()
);

export const OstkInstancesApplyFilter = createAction(
    '[OSTK-INST] Ostk Instances Apply Filter',
    props<{ if4f?: InstanceFields4Filter; }>()
);
export const OstkInstancesFilterApplied = createAction(
    '[OSTK-INST] Ostk Instances Filter Applied',
    props<{ instances: Instance[]; }>()
);

export const OstkInstancesOperatingSystemValues = createAction(
    '[OSTK-INST] Ostk Instances Operating System Values',
    props<{ os: SelectOption2[]; }>()
);
export const OstkInstancesVcpusValues = createAction(
    '[OSTK-INST] Ostk Instances Vcpus Values',
    props<{ vcpus: SelectOption[]; }>()
);
export const OstkInstancesRamValues = createAction(
    '[OSTK-INST] Ostk Instances Ram Values',
    props<{ ram: SelectOption[]; }>()
);
export const OstkInstanceAddOneRequested = createAction(
    '[OSTK-INST] Ostk Instance Add One Requested',
    props<{ instance: Instance }>()
);
export const OstkInstanceAddOneSucceeded = createAction(
    '[OSTK-INST] Ostk Instance Add One Succeeded',
    props<{ instanceId: string }>()
);
export const OstkInstanceAddOneFailed = createAction(
    '[OSTK-INST] Ostk Instance Add One Failed',
    props<{ error?: any }>()
);
export const OstkInstanceDeleteRequested = createAction(
    '[OSTK-INST] Ostk Instance Delete Requested',
    props<{ instance: Instance }>()
);
export const OstkInstanceDeleteSucceeded = createAction(
    '[OSTK-INST] Ostk Instance Delete Succeeded',
    props<{ instanceId: string }>()
);
export const OstkInstanceDeleteFailed = createAction(
    '[OSTK-INST] Ostk Instance Delete Failed',
    props<{ error?: any }>()
);
export const OstkInstanceStartRequested = createAction(
    '[OSTK-MISC] Ostk Instance Start Requested',
    props<{ instance: Instance; }>()
);
export const OstkInstanceStartSucceeded = createAction(
    '[OSTK-MISC] Ostk Instance Start Succeeded',
    props<{ instance: Instance; }>()
);
export const OstkInstanceStartFailed = createAction(
    '[OSTK-MISC] Ostk Instance Start Failed',
    props<{ failure: OstkFailure; }>()
);

export const OstkInstancePauseRequested = createAction(
    '[OSTK-MISC] Ostk Instance Pause Requested',
    props<{ instance: Instance; }>()
);
export const OstkInstancePauseSucceeded = createAction(
    '[OSTK-MISC] Ostk Instance Pause Succeeded',
    props<{ instance: Instance; }>()
);
export const OstkInstancePauseFailed = createAction(
    '[OSTK-MISC] Ostk Instance Pause Failed',
    props<{ failure: OstkFailure; }>()
);

export const OstkInstanceStopRequested = createAction(
    '[OSTK-MISC] Ostk Instance Stop Requested',
    props<{ instance: Instance; }>()
);
export const OstkInstanceStopSucceeded = createAction(
    '[OSTK-MISC] Ostk Instance Stop Succeeded',
    props<{ instance: Instance; }>()
);
export const OstkInstanceStopFailed = createAction(
    '[OSTK-MISC] Ostk Instance Stop Failed',
    props<{ failure: OstkFailure; }>()
);

export const OstkInstanceSnapshotRequested = createAction(
    '[OSTK-INST] Ostk Instance Snapshot Requested',
    props<{ instance: Instance, name: string; }>()
);
export const OstkInstanceSnapshotSucceeded = createAction(
    '[OSTK-INST] Ostk Instance Snapshot Succeeded'
);
export const OstkInstanceSnapshotFailed = createAction(
    '[OSTK-INST] Ostk Instance Snapshot Failed',
    props<{ failure?: OstkFailure; }>()
);
export const OstkInstanceTakeSnapshotRequested = createAction(
    '[OSTK-INST] Ostk Instance Take Snapshot Requested',
    props<{ instance: Instance, name: string; }>()
);
export const OstkInstanceTakeSnapshotSucceeded = createAction(
    '[OSTK-INST] Ostk Instance Take Snapshot Succeeded',
    props<{ snapshot: ImageSnapshot }>()
);
export const OstkInstanceTakeSnapshotFailed = createAction(
    '[OSTK-INST] Ostk Instance Take Snapshot Failed',
    props<{ failure?: OstkFailure; }>()
);
export const OstkInstancesBySecurityGroupRequested = createAction(
    '[OSTK-INST] Ostk Instances By SecurityGroup Requested'
);

export const OstkInstancesBySecurityGroupSucceeded = createAction(
    '[OSTK-INST] Ostk Instances By Security Group Succeeded',
    props<{ instancesMap: Map<string, SimpleInstance[]>; }>()
);

export const OstkInstancesExpireDate = createAction(
    '[OSTK-INST] Ostk Instances Expire Date'
);

export const OstkInstancesManageSecurityGroupsRequested = createAction(
    '[OSTK-INST] Ostk Instances Manage Security Groups Requested',
    props<{ id: string, sgrpIds: ManageSecurityGroupsViewModel; }>()
);

export const OstkInstancesManageSecurityGroupsSucceeded = createAction(
    '[OSTK-INST] Ostk Instances Manage Security Groups Succeeded',
    props<{ instance: Instance; }>()
);

export const OstkInstancesManageSecurityGroupsFailed = createAction(
    '[OSTK-INST] Ostk Instances Manage Security Groups Failed',
    props<{ failure?: OstkFailure; }>()
);

export const OstkInstancesQsWordSet = createAction(
    '[OSTK-VOLU] Ostk Instances QsWord Set',
    props<{ qsWord: string }>()
);

export const OstkInstanceRenameRequested = createAction(
    '[OSTK-INST] Ostk Instance Rename Requested',
    props<{ instance: Instance, newName: string }>()
);
export const OstkInstanceRenameSucceeded = createAction(
    '[OSTK-INST] Ostk Instance Rename Succeeded',
    props<{ instance: Instance, newName: string }>()
);
export const OstkInstanceRenameFailed = createAction(
    '[OSTK-INST] Ostk Instance Rename Failed',
    props<{ error?: any }>()
);

export const OstkInstanceChanged = createAction(
    '[OSTK-INST] Ostk Instance Changed',
    props<{ instance: Update<Instance> }>()
);
