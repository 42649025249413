import { Component, OnInit, forwardRef, Injector, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';

import { OptionValue9 } from '@ui/components/form9/common';
import { RbCbBase } from '@ui/components/form9/common/rb-cb-base';

import { inputRadio9RequiredValidator } from './input-radio9.validators';

@Component({
    selector: 'aa-input-radio9',
    templateUrl: './input-radio9.component.html',
    styleUrls: [
        '../input9.component.scss',
        './input-radio9.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): typeof InputRadio9Component => InputRadio9Component),
            multi: true
        }
    ]
})
export class InputRadio9Component extends RbCbBase<OptionValue9> implements OnInit
{
    @Output() public clickedRadio: EventEmitter<OptionValue9> = new EventEmitter();
    private static identifier: number = 0;

    constructor (
        injector: Injector,
        controlContainer: ControlContainer
    )
    {
        super(injector, controlContainer);

        this.fieldId = `idRadioGroup9-${InputRadio9Component.identifier++}`;
    }

    ngOnInit (): void
    {
        this.initRbCb();
        this.initControl([inputRadio9RequiredValidator((this.customMsg))]);
    }

    toggle (value: OptionValue9): void
    {
        this.ctrlValue = value;
        this.clickedRadio.emit(value);
    }
}
