import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import * as fromRootImpersonateActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { Its2Constants } from '@apps/its2/its2.constants';
import { ResultDomain } from '@common/domain/result-domain';
import { PotoRequestsFilter, RequestsFilter } from '@apps/its2/services/requests-filter';

import * as fromIts2RequestsActions from './requests.actions';
import { Request, RequestCounters } from '../../domain/models';

export const Its2RequestsFeatureKey = 'requests';

type IRequestsState = EntityState<Request>;
export const requestsAdapter: EntityAdapter<Request> = createEntityAdapter<Request>({
    selectId: (request: Request): string =>
        request.rfcNumber
});
const requestsInitialState: IRequestsState = requestsAdapter.getInitialState({
});

export interface IIts2RequestsState
{
    requests: IRequestsState;
    favorites: string[];
    read: string[];
    counters: RequestCounters;
    requestsFilter: PotoRequestsFilter;
    isLoading: boolean;
    isLoaded: boolean;
    isEmpty: boolean;
    isOups: boolean;
    expirationDate?: DateTime;
    qsWord: string;
    selected: Request;
    // myTickets: boolean;
}

export const Its2RequestsState: IIts2RequestsState = {
    requests: requestsInitialState,
    favorites: [],
    read: [],
    counters: new RequestCounters(),
    requestsFilter: new RequestsFilter(),
    isLoading: false,
    isLoaded: false,
    isEmpty: false,
    isOups: false,
    expirationDate: null,
    qsWord: '',
    selected: null,
    // myTickets: true
};

export const Its2RequestsReducer = createReducer(
    Its2RequestsState,
    on(
        fromRootImpersonateActions.RootImpersonateSet,
        fromRootImpersonateActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => Its2RequestsState
    ),
    on(
        fromIts2RequestsActions.Its2RequestsODataFilterRequested,
        (state: IIts2RequestsState) => ({
            ...state,
            isLoading: true,
            isLoaded: false,
            isEmpty: false,
            isOups: false
        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsODataFilterSucceeded,
        (state: IIts2RequestsState, { result }: { result: ResultDomain<Request>; }) => ({
            ...state,
            isLoading: false,
            isLoaded: result.value.length > 0,
            isEmpty: result.value.length === 0,
            isOups: false,
            requests: requestsAdapter.setAll(result.value, state.requests),
            expirationDate: DateTime.utc().plus({ minutes: Its2Constants.DataLifeTime })
        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsODataFilterFailed,
        (state: IIts2RequestsState) => ({
            ...state,
            isLoading: false,
            isLoaded: false,
            isEmpty: false,
            isOups: true,
            requests: requestsAdapter.removeAll(state.requests),
            expirationDate: null
        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsODataSkipRequested,
        (state: IIts2RequestsState) => ({
            ...state,
            isLoading: false,
            isLoaded: true,
            isEmpty: false,
            isOups: false

        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsODataSkipSucceeded,
        (state: IIts2RequestsState, { result }: { result: ResultDomain<Request>; }) => ({
            ...state,
            isLoading: false,
            isLoaded: true,
            isEmpty: false,
            isOups: false,
            // isEmpty: result.value.length === 0,
            requests: requestsAdapter.addMany(result.value, state.requests)
        })
    ),
    // on(
    //     fromIts2RequestsActions.Its2RequestsODataSkipFailed,
    //     (state: IIts2RequestsState) => ({
    //         ...state,
    //         isLoading: false,
    //         isOups: true,
    //         requests: requestsAdapter.removeAll(state.requests),
    //         expirationDate: null
    //     })
    // ),
    on(
        fromIts2RequestsActions.Its2RequestsCountersSucceeded,
        (state: IIts2RequestsState, { requestCounters }: { requestCounters: RequestCounters; }) => ({
            ...state,
            counters: requestCounters
        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsFilterChanged,
        (state: IIts2RequestsState, { requestsFilter }: { requestsFilter: RequestsFilter; }) => ({
            ...state,
            requestsFilter
        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsSelectedSucceeded,
        (state: IIts2RequestsState, { selected }: { selected: Request }) => ({
            ...state,
            selected: selected
        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsSelectedUnset,
        (state: IIts2RequestsState) => ({
            ...state,
            selected: null
        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsUpdateMany,
        (state: IIts2RequestsState, { requests }: { requests: Update<Request>[]; }) => ({
            ...state,
            requests: requestsAdapter.updateMany(requests, state.requests)
        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsUpdateOne,
        (state: IIts2RequestsState, { request }: { request: Update<Request>; }) => ({
            ...state,
            requests: requestsAdapter.updateOne(request, state.requests)
        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsFavoritesStatusUpdate,
        (state: IIts2RequestsState, { rfcNumbers }: { rfcNumbers: string[]; }) =>
        {
            const updates: Update<Request>[] = rfcNumbers.map((rfcNumber: string) => ({
                id: rfcNumber,
                changes: {
                    isFavorite: true
                }
            } as Update<Request>));

            return {
                ...state,
                requests: requestsAdapter.updateMany(updates, state.requests),
                favorites: rfcNumbers
            };
        }
    ),
    on(
        fromIts2RequestsActions.Its2RequestsReadStatusUpdate,
        (state: IIts2RequestsState, { rfcNumbers }: { rfcNumbers: string[]; }) =>
        {
            const updates: Update<Request>[] = rfcNumbers.map((rfcNumber: string) => ({
                id: rfcNumber,
                changes: {
                    isRead: true
                }
            } as Update<Request>));

            return {
                ...state,
                requests: requestsAdapter.updateMany(updates, state.requests),
                read: rfcNumbers
            };
        }
    ),
    on(
        fromIts2RequestsActions.Its2RequestsCreateCaseSucceeded,
        (state: IIts2RequestsState, { request }: { request: Request }) => ({
            ...state,
            requests: requestsAdapter.addOne(request, state.requests)
        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsCreateServiceSucceeded,
        (state: IIts2RequestsState, { request }: { request: Request }) => ({
            ...state,
            requests: requestsAdapter.addOne(request, state.requests)
        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsQsWordSet,
        (state, { qsWord }) => ({
            ...state,
            qsWord: qsWord.toLowerCase()
        })
    ),
    on(
        fromIts2RequestsActions.Its2RequestsMyTicketsChanged,
        (state: IIts2RequestsState, { myTickets }: { myTickets: boolean }) =>
        {
            const currentRequestsFilter: RequestsFilter = RequestsFilter.convertFromPoto(state.requestsFilter);
            const requestsFilterToUpdate: RequestsFilter = RequestsFilter.convertFromPoto(state.requestsFilter);
            if (myTickets)
            {
                // On vient de la page SECU
                // - recipientIsMe a été forcé à null
                // - onlySecuRequests a été valorisé à true
                if (!currentRequestsFilter.recipientIsMe && currentRequestsFilter.onlySecuRequests)
                {
                    // On ne change rien
                    requestsFilterToUpdate.recipientIsMe = null;
                    requestsFilterToUpdate.onlySecuRequests = true;
                }
                else
                {
                    requestsFilterToUpdate.recipientIsMe = myTickets;
                    requestsFilterToUpdate.onlySecuRequests = false;

                }
            }
            else
            {
                requestsFilterToUpdate.recipientIsMe = myTickets;
                requestsFilterToUpdate.onlySecuRequests = false;

            }

            return {
                ...state,
                requestsFilter: requestsFilterToUpdate
            };
        }
    )
);
