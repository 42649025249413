import { Pipe, PipeTransform } from '@angular/core';

import { AppConstants } from '@apps/app.constants';
import { ColorsService, UtilsService } from '@common/services';

@Pipe({
    name: 'highlightSearch'
})
export class HighlightSearchPipe implements PipeTransform
{
    transform (value: any, qsWord: string): string
    {
        if (!value || UtilsService.isNullOrEmpty(qsWord))
        {
            return value;
        }

        const regexp: RegExp = new RegExp(this.escapeRegex(qsWord), 'gi');
        const val: string = value.toString();
        const match = val.match(regexp);
        if (!match)
        {
            return value;
        }

        return val.replace(regexp, `<span class='${AppConstants.QuickSearchClass}'>$&</span>`);
    }

    /**
     * Sanitize qs from regex special token
     */
    private escapeRegex (str: string): string
    {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
}

@Pipe({
    name: 'tagHighlightSearch'
})
export class TagHighlightSearchPipe implements PipeTransform
{
    transform (value: any, qsWord: string, bgColor: string, fgColor: string): string
    {
        if (!value || UtilsService.isNullOrEmpty(qsWord))
        {
            return value;
        }

        const regexp: RegExp = new RegExp(qsWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi');
        const val: string = value.toString();
        const match = val.match(regexp);
        if (!match)
        {
            return value;
        }

        const bgColor1: string = ColorsService.getDarkenColorFromString(bgColor, 0.75);
        const fgColor1: string = ColorsService.getBrightenColorFromString(fgColor, 1);

        return val.replace(regexp, `<span style="background-color:${bgColor1};color:${fgColor1}">$&</span>`);
    }
}
