import { createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';

import { getOstkState, IOstkState } from '@apps/ostk/store/ostk.reducer';
import { Instance } from '@apps/ostk/domain/models';
import { Comparer, filterQuickSearch } from '@common/services/utils.service';

import * as fromInstancesReducer from './instances/instances.reducer';
import { OstkConstants } from '../ostk.constants';

//#region Instances
export const getOstkInstancesState = createSelector(
    getOstkState,
    (state: IOstkState) =>
        state[fromInstancesReducer.OstkInstFeatureKey]
);
const getCollectionState = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.instances
);
export const {
    selectAll: getAllInstances,
} = fromInstancesReducer.OstkInstAdapter.getSelectors(getCollectionState);
export const getInstancesLoadingStatus = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.isLoading
);
export const getInstancesLoadedStatus = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.isLoaded
);
export const getInstancesEmptyStatus = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.isEmpty
);
export const getInstancesOupsStatus = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.isOups
);
export const getInstancesIsDataValid = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);
export const getInstanceFieldsFilter = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.filter
);
const getFilteredInstances = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.filteredInstances
);

const getSwitchFilterInstances = createSelector(
    getOstkInstancesState,
    getFilteredInstances,
    (state: fromInstancesReducer.IOstkInstState, instances: Instance[]) =>
        instances.filter((instance: Instance) =>
            instance.isRunning === state.switchFilter
        )
);

const getQuickSearchedInstances = createSelector(
    getOstkInstancesState,
    getSwitchFilterInstances,
    (state: fromInstancesReducer.IOstkInstState, instances: Instance[]) =>
        filterQuickSearch(
            instances,
            state.qsWord,
            OstkConstants.instancesQsFields
        )
);
export const getPipelinedInstances = createSelector(
    getQuickSearchedInstances,
    (instances: Instance[], params: { comparer: Comparer<Instance> }) =>
        [...instances].sort(params.comparer)
);
export const countRunningInstances = createSelector(
    getAllInstances,
    (instances) =>
        instances
            .filter((inst: Instance) => inst.isRunning)
            .length
);
export const countNonRunningInstances = createSelector(
    getAllInstances,
    (instances) =>
        instances
            .filter((inst: Instance) => !inst.isRunning)
            .length
);
export const getOperatingSystems = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.operatingSystems
);
export const getInstancesAllVcpues = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.vcpu
);
export const getInstancesAllRam = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.ram
);
// #endregion

//#region Instance details
export const getSelectedInstance = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.selectedInstance
);
export const getInstanceHistory = createSelector(
    getOstkInstancesState,
    (state: fromInstancesReducer.IOstkInstState) =>
        state.instanceTimelineGroups
);
// #endregion
