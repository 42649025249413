import { AppConstants } from '@apps/app.constants';

export class OData2
{
    private _nbItems: number;
    get nbItems ()
    {
        return this._nbItems;
    }
    set nbItems (value)
    {
        this._nbItems = value;
    }
    private _currentPage: number;
    private _top: number;
    get top ()
    {
        return this._top;
    }
    set top (value)
    {
        this._top = value;
    }
    private skip: number;
    private excludedFieldToExport: string[] = ['$count', '$top', '$orderBy'];
    get isEndReached ()
    {
        return (this._currentPage * this.scrollSize) >= this._nbItems;
    }
    public qs: string;
    protected values: Map<string, any> = null;
    public qsExport: string;
    protected exportValues: Map<string, any> = null;

    constructor (private scrollSize: number, nbItems?: number)
    {
        this._nbItems = nbItems ? nbItems : -1;

        this.values = new Map<string, any>();
        this.exportValues = new Map<string, any>();

        this.resetIncr();
    }

    public incr (): void
    {
        this._currentPage++;
        this.skip += this.scrollSize;

        this.qs = this.getQueryString();
    }

    public resetIncr (): void
    {
        this._currentPage = 1;
        this._top = this.scrollSize;
        this.skip = 0;

        this.qs = this.getQueryString();
    }

    protected getQueryString ()
    {
        if (this._top === -1)
        {
            return null;
        }

        let qs: string = '?';
        const queries: string[] = [];

        if (this.skip !== 0)
        {
            queries.push(`$skip=${this.skip}`);
        }

        if (this._top !== 0)
        {
            queries.push(`$top=${this._top}`);
        }

        if (this.values.size !== 0)
        {
            this.values
                .forEach((value: string, key: any ) =>
                {
                    queries.push(`${key}=${value}`);
                });
        }
        qs += queries.join('&');

        return qs;
    }

    protected getExportQueryString (): string
    {
        const queries: string[] = [];

        if (this.exportValues.size !== 0)
        {
            this.exportValues
                .forEach((value: string, key: any ) =>
                {
                    queries.push(`${key}=${value}`);
                });
        }
        const qs: string = queries.join('&');

        return qs !== '' ? `?${qs}` : '';
    }

    protected setKeyValue (key: string, value: any)
    {
        this.values.set(key, value);
        if (this.exportValues !== null && !this.excludedFieldToExport.includes(key))
        {
            this.exportValues.set(key, value);
        }
    }

    protected deleteKey (key: string): void
    {
        this.values.delete(key);
        this.exportValues.delete(key);
    }
}

export class ODataSearch2 extends OData2
{
    public searchValue: string;
    get qsSearch ()
    {
        return this.getQueryString();
    }

    constructor (searchValue: string, incrSize: number = AppConstants.QSSearchTop)
    {
        super(incrSize);

        this.searchValue = searchValue;
    }
}
