
export class Contact
{
    email: string = '';
    firstName: string = '';
    lastName: string = '';
    title: any;
    phoneNumber: any;
    mobile: any;

    constructor (contact?: any)
    {
        if (contact)
        {
            Object.assign(this, contact);
        }
    }

    public toSummary (): string
    {
        return `<li>${[this.firstName, this.lastName, this.email].join(' ')}</li> `;
    }

    public toDescription ()
    {
        return `
            <div style="display:flex;">
                <div style="white-space:nowrap;flex-basis: 50%">${this.firstName} ${this.lastName}</div>
                <div style="white-space:nowrap; color:#8c9faa;flex-basis: 50%;">${this.email}</div>
            </div>
        `;
    }
}
