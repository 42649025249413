import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SnapshotApi } from '../../api/models';
import { Snapshot } from '../models';
import { SnapshotListApiService } from '../../api';

@Injectable({
    providedIn: 'root'
})
export class SnapshotListDomainService
{

    constructor (
        private snapshotListApiService: SnapshotListApiService
    )
    { }

    getSnapshots (): Observable<Snapshot[]>
    {
        return this.snapshotListApiService.getSnapshots()
            .pipe(
                map((result: SnapshotApi[]) =>
                    result.map<Snapshot>(Snapshot.mapperFromApi)
                )
            );
    }
}
