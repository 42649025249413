import { FilterFieldType } from '@common/models';

import { OData2 } from './odata2';

export class ODataFilter2<T> extends OData2
{
    protected static count: boolean = true;
    protected _field4Filter: Map<string, FilterFieldType>;
    get field4Filter ()
    {
        return this._field4Filter;
    }

    private _filter: T;
    get filter ()
    {
        return this._filter;
    }
    set filter (value)
    {
        this._filter = value;
    }
    private _booleanOperator: string = 'is';

    constructor (incrSize: number, nbItems?: number, booleanOperator: string = 'is')
    {
        super(incrSize, nbItems);
        this._filter = {} as T;

        this._booleanOperator = booleanOperator;
    }

    public processQueryString (): void
    {
        this.processFilter();

        this.qs = this.getQueryString();
        this.qsExport = this.getExportQueryString();
    }

    protected processFilter ()
    {
        const qsFilterArray = [];

        let qsFilterField: string;
        let filterQueyString = '';

        this._field4Filter.forEach((value: FilterFieldType, key: string) =>
        {
            if (this.filter[key] !== undefined && this.filter[key] !== '')
            {
                switch (value.type)
                {
                    case 'int':
                        qsFilterField = this.processByInt(value.name, this.filter[key]);

                        break;

                    case 'string':
                        qsFilterField = this.processByString(value.name, this.filter[key], this.field4Filter.get(value.name).operator);

                        break;

                    case 'boolean':
                        qsFilterField = this.processByBoolean(value.name, this.filter[key], this._booleanOperator);

                        break;

                    case 'switch':
                        qsFilterField = this.processBySwitch(value.name, this.filter[key], this._booleanOperator);

                        break;

                    case 'date':
                        qsFilterField = this.processByDate(value.name, this.filter[key], value.operator);

                        break;
                }

                if (qsFilterField !== null)
                {
                    qsFilterArray.push(qsFilterField);
                }
            }
        });

        filterQueyString += qsFilterArray.join(' and ');
        if (filterQueyString !== '')
        {
            this.setKeyValue('$filter', filterQueyString);
        }
        else
        {
            this.deleteKey('$filter');
        }
    }

    private processByString (qsField: string, value: string | string[], operator: string = 'eq'): string
    {
        if (Array.isArray(value))
        {
            if (value.length === 0)
            {
                return null;
            }

            if (value.length > 1)
            {
                const val = value.map(v => `'${v}'`).join(',');

                return `${qsField} in (${val})`;
            }

            return `${qsField} ${operator} '${value[0]}'`;
        }

        return null;
    }

    private processByInt (qsField: string, value: number[]): string
    {
        if (value.length === 0)
        {
            return null;
        }

        if (value.length > 1)
        {
            const val = value.join(',');

            return `${qsField} in (${val})`;
        }

        return `${qsField} eq ${value[0]}`;
    }

    private processByBoolean (qsField: string, value: boolean[], operator: string): string
    {
        if (value.length === 0)
        {
            return null;
        }

        if (value.length > 1)
        {
            return '';
        }

        return `${qsField} ${operator} ${value[0]}`;
    }

    private processBySwitch (qsField: string, value: null | boolean, operator: string): string
    {
        if (value === null)
        {
            return null;
        }

        return `${qsField} ${operator} ${value}`;
    }

    private processByDate (qsField: string, value: string, operator: string): string
    {
        if (!value)
        {
            return null;
        }

        return `${qsField} ${operator} ${value}`;
    }
}
