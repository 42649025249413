import { createAction, props } from '@ngrx/store';

import { Quota } from '../../domain/models';

export const OstkQuotasListRequested = createAction(
    '[OSTK-QUOTAS] Ostk Quotas List Requested'
);

export const OstkQuotasListSucceeded = createAction(
    '[OSTK-QUOTAS] Ostk Quotas List Loaded',
    props<{ quotas: Quota[] }>()
);

export const OstkQuotasListFailed = createAction(
    '[OSTK-QUOTAS] Ostk Quotas List Failed',
    props<{ err?: any }>()
);

export const OstkQuotasListReset = createAction(
    '[OSTK-QUOTAS] Ostk Quotas List Reset'
);
