import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { PersonalDataService } from '@common/services/personal-data.service';

import { AlertMiscApiService } from '../api/alert-misc-api.service';
import { AlertApi } from '../api/models';
import { Alert } from './models/alert';

@Injectable({
    providedIn: 'root'
})
export class AlertMiscDomainService extends BaseMiscDomainService
{
    constructor (
        private alertMiscApiService: AlertMiscApiService,
        private personalDataService: PersonalDataService
    )
    {
        super();
    }

    getAll (): Observable<Alert[]>
    {
        const readAlerts: string[] = this.personalDataService.getReadAlerts();

        return this.alertMiscApiService.getAll()
            .pipe(
                map((alerts: AlertApi[]): Alert[] =>
                    alerts
                        ? alerts
                            .filter((alert: AlertApi): boolean =>
                                !readAlerts.includes(alert.id)
                            )
                            .map((item: AlertApi): Alert =>
                                Alert.mapperFromApi(item)
                            )
                        : null
                )
            );
    }
}
