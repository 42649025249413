import { DateTime } from 'luxon';

export class DateRange
{
    constructor (public since?: DateTime, public until?: DateTime, public format: string = 'dd/MM/yyyy HH:mm:ss')
    {
    }

    toString (): string
    {
        return `${this.since.toFormat(this.format)} - ${this.until.toFormat(this.format)}`;
    }
}
