import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppConstants } from '@apps/app.constants';
import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { FloatingIpApi } from '../models/network';

@Injectable({
    providedIn: 'root'
})
export class FloatingIpMiscApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlOstk;
        this.segmentApi = '/ostk/floatingips';
    }

    public getFloatingIps (): Observable<FloatingIpApi[]>
    {
        return this.get<FloatingIpApi[]>();
    }

    public map (id: string, serverId: string): Observable<FloatingIpApi>
    {
        return this.post(`/${id}/map`, { serverId });
    }

    public unmap (id: string): Observable<FloatingIpApi>
    {
        return this.post(`/${id}/unmap`, {});
    }

    public deleteFloatingIp (id: string): Observable<string>
    {
        return this.delete(`/${id}`, null);
    }

    public allocate (poolId: string): Observable<FloatingIpApi>
    {
        return this.post(`/${poolId}/allocate`, {});
    }
}
