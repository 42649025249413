import { CopiesEvolFilter } from '@apps/bckp/models';
import { OData } from '@common/api/qs';

export class ODataBckpCopiesEvol extends OData
{
    private _filter: CopiesEvolFilter;
    private field4Filter: string[] = [
        'server',
        'policyName',
        'residence',
        'slpName'
    ];
    get filter (): CopiesEvolFilter
    {
        return this._filter;
    }

    constructor (incSize: number, filter?: CopiesEvolFilter)
    {
        super(incSize);

        if (filter !== undefined)
        {
            this.processFilter(filter);
        }

        this.qs = this.getQueryString();
    }

    private processFilter (filter: CopiesEvolFilter)
    {
        this._filter = new CopiesEvolFilter();
        Object.assign(this._filter, filter);

        this.pushValue('since', filter.since);
        this.pushValue('until', filter.until);

        this.field4Filter.forEach((f: string) =>
        {
            if (filter[f])
            {
                this.pushValue('terms', `${f}:${filter[f]}`);
            }
        });
    }

}
