import { Component, Input } from '@angular/core';

@Component({
    selector: 'aa-validation2',
    templateUrl: './validation2.component.html',
    styleUrls: [
        './validation2.component.scss'
    ]
})
export class Validation2Component
{
    @Input() messages: Array<string>;
    @Input() dirty: boolean;
    get showMessage ()
    {
        return this.dirty && this.messages && this.messages.length > 0;
    }
    get message ()
    {
        if (this.showMessage)
        {
            return this.messages[0];
        }

        return null;
    }
}
