import { Observable } from 'rxjs';
import { concatAll, map, toArray } from 'rxjs/operators';

export const arrayMapper = <T, U>(fct: (x: T) => U) =>
    (source: Observable<any>) =>
        source
            .pipe(
                concatAll(),
                map(fct),
                toArray()
            );
