import { RequestDocumentApi } from '@apps/its2/api/models/request-document-api';
import { FilesService } from '@common/services';

export class RequestDocument
{
    creationDate: Date;
    documentId: string;
    documentNumber: number;
    documentName: string;
    physicalName: string;
    extension: string;
    mediaType: string;
    size: number;
    icon: string;
}

export class DomainRequestDocument extends RequestDocument
{
    public static mapperFromApi (source: RequestDocumentApi): RequestDocument
    {
        const target = new RequestDocument();

        Object.assign(target, source);

        target.icon = FilesService.getFileIconFromName(target.documentName);

        return target;
    }
}
