import { OptionValue, SelectOption } from './select-option';

export class SelectOption2 extends SelectOption
{
    public imgPath: string;

    constructor(value: OptionValue, label: string, selected: boolean = false, imgPath: string = '')
    {
        super(value, label, selected);

        this.imgPath = imgPath;
    }
}
