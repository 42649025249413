import { props, createAction } from '@ngrx/store';

import { DateTime } from 'luxon';
import { Skin } from 'src/app/_common/services';

export const RootGetDeployedVersion = createAction(
    '[ROOT-MISC] Root Get Deployed Version'
);

export const RootSetDeployedVersion = createAction(
    '[ROOT-MISC] Root Set Deployed Version',
    props<{ hash: string }>()
);

export const RootResetDeployedVersion = createAction(
    '[ROOT-MISC] Root Reset Deployed Version'
);

export const RootShowHideUserMenu = createAction(
    '[ROOT-MISC] Root Show Hide User Menu',
    props<{ status: boolean }>()
);

export const RootUserMenuItemClicked = createAction(
    '[ROOT-MISC] UserMenuItem clicked',
    props<{ menuItemId: string }>()
);

export const RootPopupMenuItemClicked = createAction(
    '[ROOT-MISC] Popup Menu Item Clicked'
);
export const RootMiscSkinSwitched = createAction(
    '[ROOT-MISC] Root Misc Skin Switched',
    props<{ skin: Skin }>()
);

export const RootDirtyPopinFormSet = createAction(
    '[ROOT-MISC] Root Dirty Popin Form Set',
    props<{ dirty: boolean }>()
);

export const RootDirtyUploadFileSet = createAction(
    '[ROOT-MISC] Root Dirty Upload File Set',
    props<{ dirty: boolean }>()
);

export const RootDirtyExtraDataSet = createAction(
    '[ROOT-MISC] Root Dirty Extra Data Set',
    props<{ dirty: boolean }>()
);

export const RootOverPopinSet = createAction(
    '[ROOT-MISC] Root Over Popin Set',
    props<{ over: boolean }>()
);

export const RootFilterStatusSet = createAction(
    '[ROOT-MISC] Root Filter Status Set',
    props<{ active: boolean }>()
);

// #region Wizard
export const RootWizardNextStepButtonClicked = createAction(
    '[ROOT-MISC] Root Wizard Next Step Button Clicked',
    props<{ stepIdx: number }>()
);

export const RootWizardStepState = createAction(
    '[ROOT-MISC] Root Wizard Step State',
    props<{ stepIdx: number, valid: boolean }>()
);

export const RootWizardStepActivated = createAction(
    '[ROOT-MISC] Root Wizard Step Activated',
    props<{ stepIdx: number }>()
);

export const RootWizardStepEnabled = createAction(
    '[ROOT-MISC] Root Wizard Step Enabled',
    props<{ stepIdx: number, deactivated: boolean }>()
);
// #endregion

// #region Wizard2
export const RootWizard2NextStepButtonClicked = createAction(
    '[ROOT-MISC] Root Wizard2 Next Step Button Clicked'
);

export const RootWizard2NextButtonClicked = createAction(
    '[ROOT-MISC] Root Wizard2 Next Button Clicked'
);
/**
 * Gère le statut visuel de l'onglet du wizard
 *
 * @param {number} stepIdx Index de l'onglet
 * @param {boolean} valide Statut 'valide' ou non de l'onglet en cours
 */
export const RootWizard2StepState = createAction(
    '[ROOT-MISC] Root Wizard2 Step State',
    props<{ stepIdx: number, valid: boolean }>()
);
export const RootWizard2StepEnabled = createAction(
    '[ROOT-MISC] Root Wizard2 Step Enabled',
    props<{ stepIdx: number, deactivated: boolean }>()
);
export const RootWizard2StepExitRequested = createAction(
    '[ROOT-MISC] Root Wizard2 Step Exit Requested',
    props<{ oldStepIdx: number, newStepIdx: number }>()
);
export const RootWizard2StepExitAccepted = createAction(
    '[ROOT-MISC] Root Wizard2 Step Exit Accepted',
    props<{ oldStepIdx: number, newStepIdx: number }>()
);
export const RootWizard2StepExitRefused = createAction(
    '[ROOT-MISC] Root Wizard2 Step Exit Refused',
    props<{ oldStepIdx: number, newStepIdx: number }>()
);
// #endregion

export const RootRangeViewerSinceUntilSet = createAction(
    '[ROOT-MISC] Root Range Viewer Since Until Set',
    props<{ since: DateTime, until: DateTime }>()
);

export const RootRangeViewerSinceUntilReset = createAction(
    '[ROOT-MISC] Root Range Viewer Since Until Reset'
);

export const RootToggleShowChips = createAction(
    '[ROOT-MISC] Root Toggle Show Chips',
    props<{ chipsState: boolean }>()
);

export const RootMiscWarningsRequested = createAction(
    '[ROOT-MISC] Root Misc Warnings Requested'
);

export const RootMiscWarningSet = createAction(
    '[ROOT-MISC] Root Misc Warning Set',
    props<{ id: string, nb: number }>()
);

export const RootMiscLastCustomerUrlSet = createAction(
    '[ROOT-MISC] Root Misc Last Customer Url Set',
    props<{ url: string }>()
);


export const RootMiscLastAdminUrlSet = createAction(
    '[ROOT-MISC] Root Misc Last Admin Url Set',
    props<{ url: string }>()
);
