import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { concatAll, filter, map, toArray } from 'rxjs/operators';

import { NetworkMiscApiService } from '../../api';
import { Network, CreateNetwork } from '../models';
import { SelectOption } from '@ui/components/form/common';

@Injectable({
    providedIn: 'root'
})
export class NetworkMiscDomainService
{
    constructor (
        private networkMiscApiService: NetworkMiscApiService
    )
    {
    }

    public getNetworks (): Observable<Network[]>
    {
        return this.networkMiscApiService.getNetworks()
            .pipe(
                concatAll(),
                filter((netw: Network) => netw.routerExternal !== true),
                map(Network.mapperFromApi),
                toArray()
            );
    }

    public createNetwork (network: CreateNetwork): Observable<Network>
    {
        return this.networkMiscApiService.createNetwork(network)
            .pipe(
                map(Network.mapperFromApi)
            );
    }

    public getNetwork (id: string): Observable<Network>
    {
        return this.networkMiscApiService.getNetwork(id)
            .pipe(
                map(Network.mapperFromApi)
            );
    }

    public deleteNetwork (id: string): Observable<string>
    {
        return this.networkMiscApiService.deleteNetwork(id)
            .pipe(
                map(() => id)
            );
    }

    public deleteSubnet (id: string, subnetId: string): Observable<string>
    {
        return this.networkMiscApiService.deleteSubnet(id, subnetId)
            .pipe(
                map(() => subnetId)
            );
    }

    public getPools (): Observable<SelectOption[]>
    {

        return this.networkMiscApiService.getNetworks()
            .pipe(
                concatAll(),
                filter((netw: Network) => netw.routerExternal),
                map((netw) => new SelectOption(netw.id, netw.name)),
                toArray()
            );
    }
}
