import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { CmdbAssetMiscApiService } from '../api/cmdb-asset-misc-api.service';
import {
    Application,
    CmdbAssetDetails,
    Entity,
    OperatingSystem,
    PhysicalServer,
    VirtualMachine
} from '../models/cmdb-asset-details';
import { CmdbAssetDetailsApi } from '../api/models';
import { CmdbAssetCategoryGuid } from '../cmdb.constants';

@Injectable({
    providedIn: 'root'
})
export class CmdbAssetMiscDomainService extends BaseMiscDomainService
{
    constructor (
        private cmdbAssetMiscApiService: CmdbAssetMiscApiService
    )
    {
        super();
    }

    getAssetDetails (categoryGuid: string, assetId: string): Observable<CmdbAssetDetails>
    {
        return this.cmdbAssetMiscApiService.getOne(assetId)
            .pipe(
                map((assetDetailsApi: CmdbAssetDetailsApi) =>
                {
                    let result: CmdbAssetDetails;

                    switch (categoryGuid)
                    {
                        case CmdbAssetCategoryGuid.VIRTUAL_MACHINE:
                            result = VirtualMachine.mapperFromApi(assetDetailsApi);
                            break;
                        case CmdbAssetCategoryGuid.PHYSICAL_SERVER:
                            result = PhysicalServer.mapperFromApi(assetDetailsApi);
                            break;
                        case CmdbAssetCategoryGuid.APPLICATION:
                            result = Application.mapperFromApi(assetDetailsApi);
                            break;
                        case CmdbAssetCategoryGuid.ENTITY:
                            result = Entity.mapperFromApi(assetDetailsApi);
                            break;
                        case CmdbAssetCategoryGuid.OPERATING_SYSTEM:
                            result = OperatingSystem.mapperFromApi(assetDetailsApi);
                            break;
                        default:
                            result = null;
                            break;
                    }

                    return result;

                })
            );
    }
}
