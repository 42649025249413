import { Component, Input, Host, OnInit } from '@angular/core';

import { SelectOption } from '../../../common';
import { RadioGroupComponent } from '../radio-group/radio-group.component';

@Component({
    selector: 'aa-radio',
    templateUrl: './radio.component.html'
})
export class RadioComponent implements OnInit
{
    @Input() name: string;
    @Input() option: SelectOption;
    @Input() toTranslate: boolean = false;
    public optionId: string;

    constructor(@Host() private radioGroup: RadioGroupComponent) { }

    ngOnInit ()
    {
        this.optionId = `id-${this.option.label}-${this.option.value}`;
    }

    toggle ()
    {
        this.radioGroup.toggle(this.option.value);
    }

    isChecked ()
    {
        return this.radioGroup.value === this.option.value;
    }
}
