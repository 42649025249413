import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { ResultApi } from 'src/app/_common/api/result-api';
import { PolicyApi } from './models';
import { AppConstants } from '../../app.constants';
import { BaseListApiService } from 'src/app/_common/api/base-list-api.service';

@Injectable({
    providedIn: 'root'
})
export class PolicyListApiService extends BaseListApiService<PolicyApi>
{
    private endPoint = '/latestpolicies';

    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlHist;
        this.resourceUrl = '/hist/netbackup';
    }

    getLatestPolicies (qs: string): Observable<ResultApi<PolicyApi>>
    {
        if (AppConstants.IsDataLocal('bckp'))
        {
            return this.http.get<ResultApi<PolicyApi>>('assets/data/bckp/policies.json');
        }

        return this.getAll(`${this.resourceUrl}${this.endPoint}${qs}`);
    }
}
