import { SiteApi } from '../../auth/api/models/back/site-api';

export class Site
{
    id: string;
    active: boolean;
    createdAt: string;
    modifiedAt: string;
    name: string;
    address: string;
    zipCode: string;
    city: string;
    country: string;
    entityId?: string;
    isFavorite?: boolean;
    fullAddress: string;

    public static mapperFromApi (source: SiteApi): Site
    {
        const target = new Site();
        Object.assign(target, source);

        return target;
    }
}
