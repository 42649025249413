import { Image } from '@apps/ostk/domain/models/image';

import { BlockDeviceMapping } from '../../models/common';

export class ImageSnapshot extends Image
{
    urlId: string;
    instanceId: string;
    size: number;

    public static mapperFromImage (source: Image): ImageSnapshot
    {
        const target = new ImageSnapshot();

        Object.assign(target, source);

        target.instanceId = source.customProperties['instance_id'];
        target.size = target.blockDeviceMapping.reduce((sum: number, bdm: BlockDeviceMapping) => sum + bdm.volumeSize, 0);

        return target;
    }
}
