import { Injectable } from '@angular/core';

import { switchMap, map, catchError } from 'rxjs/operators';
import { of, withLatestFrom } from 'rxjs';
import { DateTime } from 'luxon';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as fromSLAActions from './sla.actions';
import { RequestMiscDomainService } from '@apps/its2/domain';
import { Its2DashSharedSelectors } from '../..';
import { IAuroraState } from '@apps/aurora.state';
import { Its2DashboardFilter } from '@apps/its2/services';

@Injectable({
    providedIn: 'root'
})
export class Its2SLAsEffects
{
    constructor (
        private actions: Actions,
        private requestMiscDomainService: RequestMiscDomainService,
        private store: Store<IAuroraState>
    )
    {}

    its2DashSLACatalogIncidentsCountersRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromSLAActions.Its2DashSLAIncidentsRequested),
                    withLatestFrom(
                        this.store.select(Its2DashSharedSelectors.getDashSinceUntil),
                        (_, [since, until]) => ([
                            since,
                            until
                        ])
                    ),
                    switchMap(([since, until]: [DateTime, DateTime]) =>
                        this.requestMiscDomainService.getSLAIncidentCounters(
                            Its2DashboardFilter.mapToQueryString(since, until).qs
                        )
                            .pipe(
                                map((sla: number) =>
                                    fromSLAActions.Its2DashSLAIncidentsSucceeded({ sla })
                                ),
                                catchError((err) =>
                                    of(fromSLAActions.Its2DashSLAIncidentsFailed({ err }))
                                )
                            )
                    )
                )
    );

    its2DashSLACatalogServicesCountersRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromSLAActions.Its2DashSLAServicesRequested),
                    withLatestFrom(
                        this.store.select(Its2DashSharedSelectors.getDashSinceUntil),
                        (_, [since, until]) => ([
                            since,
                            until
                        ])
                    ),
                    switchMap(([since, until]: [DateTime, DateTime]) =>
                        this.requestMiscDomainService.getSLAServiceCounters(
                            Its2DashboardFilter.mapToQueryString(since, until).qs
                        )
                            .pipe(
                                map((sla: number) =>
                                    fromSLAActions.Its2DashSLAServicesSucceeded({ sla })
                                ),
                                catchError((err) =>
                                    of(fromSLAActions.Its2DashSLAServicesFailed({ err }))
                                )
                            )
                    )
                )
    );
}