import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CmdbFeatureKey, CmdbReducers } from './cmdb.reducer';
import { TagsEffects } from './tags/tags.effects';
import { CmdbAssetsEffects } from './assets/assets.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forFeature([
            TagsEffects,
            CmdbAssetsEffects
        ]),
        StoreModule.forFeature(
            CmdbFeatureKey,
            CmdbReducers
        )
    ]
})
export class CmdbStoreModule
{
}
