import { createAction, props } from '@ngrx/store';

import { Image } from '@apps/ostk/domain/models';
import { ImageFields4Filter } from '@apps/ostk/models/filters/image-fields-4filter';
import { SelectOption } from '@ui/components/form/common';
import { SelectOption2 } from '@ui/components/form/common/select-option2';
import { UploadImageViewModel } from '@apps/ostk/models/image/upload-image-view-model';

export const OstkImagesListRequested = createAction(
    '[OSTK-IMGE] Ostk Images List Requested'
);
export const OstkImagesListSucceeded = createAction(
    '[OSTK-IMGE] Ostk Images List Loaded',
    props<{ images: Image[] }>()
);
export const OstkImagesListFailed = createAction(
    '[OSTK-IMGE] Ostk Images List Failed',
    props<{ error: any }>()
);

export const OstkImageDetailsRequested = createAction(
    '[OSTK-IMGE] Ostk Image Details Requested',
    props<{ imageId: string }>()
);
export const OstkImageDetailsLoaded = createAction(
    '[OSTK-IMGE] Ostk Image Details Loaded',
    props<{ detailedImage: Image }>()
);
export const OstkImageDetailsFailed = createAction(
    '[OSTK-IMGE] Ostk Image Details Failed'
);
export const OstkImageUploadRequested = createAction(
    '[OSTK-IMGE] Ostk Image Upload Requested',
    props<{ image: UploadImageViewModel }>()
);
export const OstkImageUploadSucceeded = createAction(
    '[OSTK-IMGE] Ostk Image Upload Succeeded'
);
export const OstkImageUploadFailed = createAction(
    '[OSTK-IMGE] Ostk Image Upload Failed',
    props<{ error: any }>()
);
// export const OstkImagesListPublicFilterTriggered = createAction(
//     '[OSTK-IMGE] Ostk Images List Filter Triggered',
//     props<{ isPublic: boolean }>()
// );
export const OstkImagesApplyFilter = createAction(
    '[OSTK-IMGE] Ostk Images Apply Filter',
    props<{ if4f?: ImageFields4Filter }>()
);
export const OstkImagesFilterApplied = createAction(
    '[OSTK-IMGE] Ostk Images Filter Applied',
    props<{ images: Image[] }>()
);
export const OstkImagesOperatingSystemsLoaded = createAction(
    '[OSTK-IMGE] Ostk Images OperatingSystems Loaded',
    props<{ operatingSystems: SelectOption2[] }>()
);
export const OstkImagesOperatingSystemsFailed = createAction(
    '[OSTK-IMGE] Ostk Images OperatingSystems Failed',
    props<{ error: any }>()
);
export const OstkImagesStatusesLoaded = createAction(
    '[OSTK-IMGE] Ostk Images Statuses Loaded',
    props<{ statuses: SelectOption[] }>()
);
export const OstkImagesStatusesFailed = createAction(
    '[OSTK-IMGE] Ostk Images Statuses Failed',
    props<{ error: any }>()
);
export const OstkImagesImgeQsWordSet = createAction(
    '[OSTK-IMGE] OSTK Images Imge QsWord Set',
    props<{ qsWord: string }>()
);
export const OstkImagesDeleteRequested = createAction(
    '[OSTK-IMGE] Ostk Images Delete Requested',
    props<{ image: Image }>()
);
export const OstkImagesDeleteSucceeded = createAction(
    '[OSTK-IMGE] Ostk Images Delete Succeeded',
    props<{ imageId: string }>()
);
export const OstkImagesDeleteFailed = createAction(
    '[OSTK-IMGE] Ostk Images Delete Failed',
    props<{ error?: any }>()
);
