import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { DateTime } from 'luxon';

import { Image } from '@apps/ostk/domain/models';
import * as fromImagesImgeActions from './imge.actions';
import * as fromOstkProjectsActions from '../../projects/projects.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { ImageFields4Filter } from '../../../models/filters/image-fields-4filter';
import { SelectOption2 } from '@ui/components/form/common/select-option2';
import { SelectOption } from '@ui/components/form/common';
import { OstkConstants } from '../../../ostk.constants';

export const OstkImagesImgeFeatureKey = 'imge';
type IImagesImgeState = EntityState<Image>;
export const imagesAdapter: EntityAdapter<Image> = createEntityAdapter({
    selectId: (image: Image) => image.id,
    sortComparer: (imgea: Image, imgeb: Image) => imgea.name.localeCompare(imgeb.name)
});
const imageInitialState: IImagesImgeState = imagesAdapter.getInitialState({});
export interface IOstkImagesImgeState
{
    images: IImagesImgeState;
    filteredImages: Image[];
    filter: ImageFields4Filter;
    isEmpty: boolean;
    isLoading: boolean;
    isOups: boolean;
    isLoaded: boolean;
    detailedImage: Image;
    operatingSystems: SelectOption2[];
    statuses: SelectOption[];
    expirationDate?: DateTime;
    qsWord: string;
}

const initialState: IOstkImagesImgeState = {
    images: imageInitialState,
    isEmpty: false,
    isLoading: true,
    isOups: false,
    isLoaded: false,
    detailedImage: null,
    filter: new ImageFields4Filter(),
    operatingSystems: [],
    statuses: [],
    filteredImages: [],
    expirationDate: null,
    qsWord: ''
};

export const OstkImagesImgeReducer = createReducer(
    initialState,
    on(
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        fromOstkProjectsActions.OstkProjectSelected,
        () => initialState
    ),
    on(
        fromImagesImgeActions.OstkImagesListRequested,
        (state) =>
            ({
                ...state,
                isLoading: !(state.expirationDate !== null && DateTime.utc() < state.expirationDate)
            })
    ),
    on(
        fromImagesImgeActions.OstkImagesListSucceeded,
        (state, { images }: { images: Image[] }) =>
        {
            return {
                ...state,
                isEmpty: images && images.length === 0,
                isLoading: false,
                isLoaded: true,
                images: imagesAdapter.setAll(images.filter(i => i.baseImageRef === null), state.images),
                expirationDate: DateTime.utc().plus({ minutes: OstkConstants.DataLifeTime })
            };
        }
    ),
    on(
        fromImagesImgeActions.OstkImagesListFailed,
        (state: IOstkImagesImgeState) => ({
            ...state,
            isOups: true,
            isLoading: false,
            isLoaded: false,
            images: imagesAdapter.removeAll(state.images)
        })
    ),
    on(
        fromImagesImgeActions.OstkImageDetailsLoaded,
        (state, { detailedImage }) => ({
            ...state,
            detailedImage: detailedImage
        })
    ),
    on(
        fromImagesImgeActions.OstkImagesApplyFilter,
        (state, { if4f }) =>
        {
            if (if4f !== null)
            {
                return {
                    ...state,
                    isLoading: true,
                    isEmpty: false,
                    isOups: false,
                    filter: state.filter = new ImageFields4Filter(if4f)
                };
            }

            return state;
        }
    ),
    on(
        fromImagesImgeActions.OstkImagesFilterApplied,
        (state, { images }) =>
            ({
                ...state,
                isEmpty: images && images.length === 0,
                isLoading: false,
                filteredImages: images
            })
    ),
    on(
        fromImagesImgeActions.OstkImagesOperatingSystemsLoaded,
        (state, { operatingSystems }) =>
            ({
                ...state,
                operatingSystems: operatingSystems
            })
    ),
    on(
        fromImagesImgeActions.OstkImagesStatusesLoaded,
        (state, { statuses }) =>
            ({
                ...state,
                statuses: statuses
            })
    ),
    on(
        fromImagesImgeActions.OstkImagesImgeQsWordSet,
        (state, { qsWord }) =>
            ({
                ...state,
                qsWord: qsWord.toLowerCase()
            })
    ),
    on(
        fromImagesImgeActions.OstkImagesDeleteSucceeded,
        (state, { imageId }) =>
            ({
                ...state,
                images: imagesAdapter.removeOne(imageId, state.images),
                filteredImages: state.filteredImages.filter(v => v.id !== imageId)
            })
    )
);
