import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseListApiService } from '@common/api/base-list-api.service';
import { ResultApi } from '@common/api/result-api';

import { AppConstants } from '../../app.constants';
import { InstanceApi } from './models/instance-api';
import { SimplifiedInstance } from '@apps/comp/domain/models/instance';

@Injectable({
    providedIn: 'root'
})
export class InstanceListApiService extends BaseListApiService<InstanceApi>
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlHist;
        this.resourceUrl = '/hist/vmware';
    }

    getInstances (qs: string): Observable<ResultApi<InstanceApi>>
    {
        if (AppConstants.IsDataLocal('cmdb'))
        {
            return this.http.get<ResultApi<InstanceApi>>('assets/data/cmdb/machines.json');
        }

        return this.getAll(`${this.resourceUrl}/machines${qs}`);
    }

    getInstancesAlone (qs: string): Observable<ResultApi<InstanceApi>>
    {
        if (AppConstants.IsDataLocal('cmdb'))
        {
            return this.http.get<ResultApi<InstanceApi>>('assets/data/cmdb/machines.json');
        }

        return this.getAll(`${this.resourceUrl}/machinesAlone${qs}`);
    }

    getSimplifiedInstances (qs: string): Observable<ResultApi<SimplifiedInstance>>
    {
        if (AppConstants.IsDataLocal('cmdb'))
        {
            return this.http.get<ResultApi<InstanceApi>>('assets/data/cmdb/machines.json');
        }

        return this.getAll(`${this.resourceUrl}/machinesAlone${qs}`);
    }
}
