import { createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';

import { Comparer, filterQuickSearch } from '@common/services/utils.service';

import { getCmdbState, ICmdbState } from './cmdb.reducer';
import * as fromAssetsReducer from './assets/assets.reducer';
import { CmdbAsset } from '../models';
import { CmdbConstants } from '../cmdb.constants';


// #region Assets
export const getCmdbAssetsState = createSelector(
    getCmdbState,
    (state: ICmdbState) =>
        state[fromAssetsReducer.CmdbAssetsFeatureKey]
);
export const getCmdbAssetsFilter = createSelector(
    getCmdbAssetsState,
    (state: fromAssetsReducer.ICmdbAssetsState) => state.assetsFilter
);
export const getCmdbAssetsIsDataValid = createSelector(
    getCmdbAssetsState,
    (state: fromAssetsReducer.ICmdbAssetsState) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);
const getCmdbAssetsCollectionState = createSelector(
    getCmdbAssetsState,
    (state: fromAssetsReducer.ICmdbAssetsState) =>
        state.assets
);
export const {
    selectAll: getAllAssets
} = fromAssetsReducer.assetsAdapter.getSelectors(getCmdbAssetsCollectionState);
export const getCmdbAssetsLoadingStatus = createSelector(
    getCmdbAssetsState,
    (state) =>
        state.isLoading
);
export const getCmdbAssetsLoadedStatus = createSelector(
    getCmdbAssetsState,
    (state) =>
        state.isLoaded
);
export const getCmdbAssetsOupsStatus = createSelector(
    getCmdbAssetsState,
    (state) =>
        state.isOups
);
export const getCmdbAssetsEmptyStatus = createSelector(
    getCmdbAssetsState,
    (state) =>
        state.isEmpty
);
const getQuickSearchedAssets = (lang: string) => createSelector(
    getCmdbAssetsState,
    getAllAssets,
    (state: fromAssetsReducer.ICmdbAssetsState, assets: CmdbAsset[]) =>
        filterQuickSearch(
            assets,
            state.qsWord,
            CmdbConstants.assetsQSFields,
            (item: CmdbAsset) => item.category[lang].toLowerCase().includes(state.qsWord)
        )
);
export const getPipelinedAssets = (lang: string) => createSelector(
    getQuickSearchedAssets(lang),
    (assets: CmdbAsset[], params: { comparer: Comparer<CmdbAsset> }) =>
        [...assets].sort(params.comparer)
);
// #endregion

// #region Asset Details
export const getDetailedAsset = createSelector(
    getCmdbAssetsState,
    (state) =>
        state.assetDetails
);
// #endregion

// #region Asset Ids
export const getAssetPopinPreviousIds = createSelector(
    getCmdbAssetsState,
    (state) =>
    {
        const l = state.assetPopinIds.length;

        return l > 0 ? state.assetPopinIds[l - 1] : null;
    }
);
// #endregion
