import { Injector, Input, Directive } from '@angular/core';

import { Observable, of, combineLatest } from 'rxjs';
import { map, filter, distinctUntilChanged, share } from 'rxjs/operators';

import { ValueAccessorBase } from './value-accessor-base';
import { AsyncValidatorArray, ValidatorArray, ValidationResult, validate } from '../common/validate';

export enum fieldStatus
{
    disabled = 0,
    pristine = 1,
    invalid = 2,
    valid = 3
}

@Directive()
export abstract class ElementBase<T> extends ValueAccessorBase<T>
{
    private updates: string[] = ['change', 'blur', 'submit'];
    @Input() public name: string;
    @Input() public label: string;
    @Input() public required: string;
    @Input() public notEnabled: boolean = false;

    // * https://angular.io/api/forms/AbstractControl#updateOn
    // * 'change' | 'blur' | 'submit' Default value: 'change'
    @Input()
    set updateWhen (value: string)
    {
        if (value && this.updates.includes(value))
        {
            this.updateOn = { updateOn: value };
        }
    }
    public updateOn: any = { updateOn: 'blur' };
    public fieldId: string;
    protected validators: ValidatorArray;
    protected asyncValidators: AsyncValidatorArray;

    constructor (
        injector: Injector
    )
    {
        super(injector);
    }

    // public get pending$ (): Observable<boolean>
    // {
    //     console.log(this.control.pending);
    //     return of(this.control.pending).pipe(share());
    // }

    public get disabled$ (): Observable<boolean>
    {
        return of(this.control.disabled).pipe(share());
    }

    public get pristine$ (): Observable<boolean>
    {
        return of(this.control.pristine).pipe(share());
    }

    public get dirty$ (): Observable<boolean>
    {
        return of(this.control.dirty).pipe(share());
    }

    public get valid$ (): Observable<boolean>
    {
        return this.invalid$
            .pipe(
                map((x) => !x)
            );
    }

    public get invalid$ (): Observable<boolean>
    {
        return combineLatest([this.validateInnerModel(), this.getErrorsFromOuterModel()])
            .pipe(
                map(v =>
                {
                    const errors = Object.assign(v[0] || {}, v[1] || {});

                    return Object.keys(errors || {}).length > 0;
                })
            );
    }

    public get dirtyStatus$ (): Observable<boolean>
    {

        // return new Observable(() => () => this.control.dirty);
        // if (!this.disabled)
        // {
        return this.dirty$;
        // }

        // return of(false).pipe(share());
    }

    public get validStatus$ (): Observable<boolean>
    {
        // if (!this.disabled)
        // {
        return combineLatest([this.dirty$, this.valid$])
            .pipe(
                // tap((v) =>
                // {
                //     if (this.name === 'firstName')
                //     {
                //         console.log({ f: this.name, dirty: v[0], valid: v[1], validStatus: v[0] && v[1] });
                //     }
                // }),
                map((v: [boolean, boolean]) => v[0] && v[1])
            );
        // }

        // return of(false).pipe(share());
    }

    public get invalidStatus$ (): Observable<boolean>
    {
        // if (!this.disabled)
        // {
        return combineLatest([this.dirty$, this.invalid$])
            .pipe(
                map((v: [boolean, boolean]) => v[0] && v[1])
            );
        // }

        // return of(false).pipe(share());
    }

    public get failures$ (): Observable<Array<string>>
    {
        return combineLatest([this.validateInnerModel(), this.getErrorsFromOuterModel()])
            .pipe(
                // take(1),
                map(v =>
                {
                    const errors = Object.assign(v[0] || {}, v[1] || {});

                    return Object.keys(errors || {}).map(k => this.errorMsgService.getMessage(errors, k));
                })
            );
    }

    public firstError$ (): Observable<string>
    {
        // return this.failures$
        //     .pipe(
        //         filter((XY: string[]) => XY.length > 0),
        //         map((XY: string[]) => XY[0]),
        //         distinctUntilChanged((prev, curr) => prev === curr),
        //         tap((errorMsg: string) => this.formErrorService.show(this.controlElement, errorMsg)),
        //         // tap(console.log),
        //         map(() => '')
        //     );
        return combineLatest([this.invalidStatus$, this.failures$])
            .pipe(
                filter((infa: [boolean, string[]]) => infa[0] && infa[1].length > 0),
                map((infa) => infa[1][0]),
                distinctUntilChanged(),
                // tap((errorMsg: string) => this.formErrorService.open(errorMsg)),
                map(() => '')
            );
    }

    public noMoreError$ (): Observable<string>
    {
        return this.validStatus$
            .pipe(
                filter((valid: boolean) => valid),
                distinctUntilChanged(),
                // tap(() => this.formErrorService.close()),
                map(() => '')
            );
    }

    private validateInnerModel (): Observable<ValidationResult>
    {
        return validate(this.validators, this.asyncValidators)(this.control);
    }

    private getErrorsFromOuterModel (): Observable<ValidationResult>
    {
        let x: any = null;

        if (!(this.control == null || this.control.errors == null))
        {
            x = this.control.errors;
        }

        return of(x).pipe(share());
    }
}
