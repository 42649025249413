<!-- <div class="loader"></div> -->
<div class="container" #container>
    <!-- <svg:svg
        style="margin: auto; background: transparent; display: block; shape-rendering: auto;"
        preserveAspectRatio="xMaxYMax meet"
        [attr.width]="width"
        [attr.height]="height"
        viewBox="0 0 201 10"
    >
        <svg:g transform="translate(0)">
            <svg:rect
                fill="#ededed"
                [attr.width]="ww1"
                height="2.5px"
                x="0"
                y="5"
                rx="1"
            ></svg:rect>
            <svg:rect [attr.fill]="'#0057ff'" height="2.5px" x="0" y="5" rx="1">
                <svg:animate
                    id="first"
                    attributeName="x"
                    [attr.values]="x1"
                    [attr.dur]="d"
                    [attr.begin]="begin1"
                ></svg:animate>
                <svg:animate
                    attributeName="width"
                    [attr.values]="width1"
                    [attr.dur]="d"
                    [attr.begin]="begin1"
                ></svg:animate>
            </svg:rect>
            <svg:rect [attr.fill]="'#0057ff'" height="2.5px" x="0" y="5" rx="1">
                <svg:animate
                    id="second"
                    attributeName="x"
                    [attr.values]="x1"
                    [attr.begin]="begin2"
                    [attr.dur]="d"
                ></svg:animate>
                <svg:animate
                    attributeName="width"
                    [attr.values]="width1"
                    [attr.begin]="begin2"
                    [attr.dur]="d"
                ></svg:animate>
            </svg:rect>
        </svg:g>
    </svg:svg> -->
    <svg
        preserveAspectRatio="xMaxYMax meet"
        style="margin: auto; background: transparent; display: block; shape-rendering: auto;"
        viewBox="0 0 201 10"
        [attr.width]="width"
        [attr.height]="height"
    >
        <g transform="translate(0)">
            <rect
                fill="#ededed"
                height="2.5px"
                rx="1"
                x="0"
                y="5"
                width="200px"
            ></rect>
            <rect height="2.5px" rx="1" x="0" y="5" fill="#0057ff">
                <animate
                    attributeName="x"
                    id="first"
                    values="0;0;0;0;0;0;0;0;0;5;10;15;20;25;30;35;40;45;50;55;60;65;70;75;80;85;90;95;100;105;110;115;120;125;130;135;140;145;150;155;160;165;170;175;180;185;190;195;200"
                    dur="2s"
                    begin="0s;second.end - 0.32653061224489793s"
                ></animate>
                <animate
                    attributeName="width"
                    values="0px;5px;10px;15px;20px;25px;30px;35px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;35px;30px;25px;20px;15px;10px;5px;0px"
                    dur="2s"
                    begin="0s;second.end - 0.32653061224489793s"
                ></animate>
            </rect>
            <rect height="2.5px" rx="1" x="0" y="5" fill="#0057ff">
                <animate
                    attributeName="x"
                    id="second"
                    values="0;0;0;0;0;0;0;0;0;5;10;15;20;25;30;35;40;45;50;55;60;65;70;75;80;85;90;95;100;105;110;115;120;125;130;135;140;145;150;155;160;165;170;175;180;185;190;195;200"
                    begin="first.end - 0.32653061224489793s"
                    dur="2s"
                ></animate>
                <animate
                    attributeName="width"
                    values="0px;5px;10px;15px;20px;25px;30px;35px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;40px;35px;30px;25px;20px;15px;10px;5px;0px"
                    begin="first.end - 0.32653061224489793s"
                    dur="2s"
                ></animate>
            </rect>
        </g>
    </svg>
</div>
