import { createReducer, on } from '@ngrx/store';

import * as fromAccountActions from './account.actions';
import * as fromAuthLoginActions from '../login/login.actions';
import { Profile } from '../../models/profile';

export const AuthAccountFeatureKey = 'account';

export interface IAuthAccountState
{
    profile: Profile;
}

const initialState: IAuthAccountState = {
    profile: null
};

export const AuthAccountReducer = createReducer(
    initialState,
    on(
        fromAuthLoginActions.AuthLogout,
        () => (initialState)
    ),
    on(
        fromAccountActions.AuthAccountCreateStep1Enter,
        (state) => ({
            ...state
        })
    ),
    on(
        fromAccountActions.AuthAccountCreateStep2Complete,
        (state, { profile }) => ({
            ...state,
            profile: profile
        })
    ),
    on(
        fromAccountActions.AuthAccountCreateStep3Enter,
        (state, { profile }) => ({
            ...state,
            profile: profile
        })
    )
);
