import { RangeKey } from '@common/enum';
import { RangeFilter } from '@common/filters/range-filter';
import { DatesService } from '@common/services';

export class CopiesCurrFilter extends RangeFilter
{

    server: string;
    policyName: string;
    residence: string;
    slpName: string;

    constructor ()
    {
        super();

        this.initFilter();
    }

    public static mapperFromObject (source: any): CopiesCurrFilter
    {
        const target = new CopiesCurrFilter();

        Object.assign(target, source);

        return target;
    }

    private initFilter ()
    {
        const { since, until } = DatesService.preRangeDatesStatic(RangeKey.CURRENT7DAYS);

        this.rangeSince = since;
        this.rangeUntil = until;
    }
}
