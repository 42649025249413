import { createReducer, on } from '@ngrx/store';

import { DateTime } from 'luxon';

import * as fromRootImpeActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { AppConstants } from '@apps/app.constants';

import * as fromRootMiscActions from './misc.actions';

export const RootMiscFeatureKey = 'misc';
interface INbWarnings
{
    [key: string]: number;
}
export interface IRootMiscState
{
    deployedVersion: string;
    popinFormDirtyStatus: boolean;
    uploadFileDirtyStatus: boolean;
    extraDataDirtyStatus: boolean;
    popinOverStatus: boolean;
    filterStatus: boolean;
    since: DateTime;
    until: DateTime;
    chipsStatus: boolean;
    warnings: INbWarnings;
    lastAdminUrl: string;
    lastCustomerUrl: string;
}

const initialState: IRootMiscState = {
    deployedVersion: null,
    popinFormDirtyStatus: false,
    uploadFileDirtyStatus: false,
    extraDataDirtyStatus: false,
    popinOverStatus: false,
    filterStatus: false,
    since: null,
    until: null,
    chipsStatus: false,
    warnings: {},
    lastAdminUrl: AppConstants.DefaultAdminUrl,
    lastCustomerUrl: AppConstants.DefaultCustomerUrl
};

export const RootMiscReducer = createReducer(
    initialState,
    on(
        fromRootImpeActions.RootImpersonateSet,
        fromRootImpeActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => initialState
    ),
    on(fromRootMiscActions.RootSetDeployedVersion,
        (state, { hash }: { hash: string; }) => ({
            ...state,
            deployedVersion: hash
        })
    ),
    on(fromRootMiscActions.RootResetDeployedVersion,
        (state) => ({
            ...state,
            deployedVersion: null
        })
    ),
    on(fromRootMiscActions.RootDirtyPopinFormSet,
        (state, { dirty }) => ({
            ...state,
            popinFormDirtyStatus: dirty
        })
    ),
    on(fromRootMiscActions.RootDirtyUploadFileSet,
        (state, { dirty }) => ({
            ...state,
            uploadFileDirtyStatus: dirty
        })
    ),
    on(fromRootMiscActions.RootDirtyExtraDataSet,
        (state, { dirty }) => ({
            ...state,
            extraDataDirtyStatus: dirty
        })
    ),
    on(fromRootMiscActions.RootOverPopinSet,
        (state, { over }) => ({
            ...state,
            popinOverStatus: over
        })
    ),
    on(fromRootMiscActions.RootFilterStatusSet,
        (state, { active }) => ({
            ...state,
            filterStatus: active
        })
    ),
    on(fromRootMiscActions.RootRangeViewerSinceUntilSet,
        (state, { since, until }) => ({
            ...state,
            since: since,
            until: until
        })
    ),
    on(fromRootMiscActions.RootRangeViewerSinceUntilReset,
        (state) => ({
            ...state,
            since: null,
            until: null
        })
    ),
    on(fromRootMiscActions.RootToggleShowChips,
        (state, { chipsState }) => ({
            ...state,
            chipsStatus: chipsState
        })
    ),
    on(
        fromRootMiscActions.RootMiscWarningSet,
        (state, { id, nb }: {id:string, nb: number}) =>
        {
            const warnings = state.warnings;

            warnings[id.toLowerCase()] = nb;

            return {
                ...state,
                warnings
            };
        }
    ),
    on(fromRootMiscActions.RootMiscLastCustomerUrlSet,
        (state, { url }: {url: string}) => ({
            ...state,
            lastCustomerUrl: url
        })
    ),
    on(fromRootMiscActions.RootMiscLastAdminUrlSet,
        (state, { url }: {url: string}) => ({
            ...state,
            lastAdminUrl: url
        })
    )
);
