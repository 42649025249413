export enum UrgencyId
{
    High = 1,
    Standard,
    Low
}

export enum UrgencyTradKey
{
    High = 'HIGH',
    Standard = 'STANDARD',
    Low = 'LOW'
}
