import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { NumbersService } from 'src/app/_common/services';

@Pipe({
    name: 'convertBToKB'
})
export class ConvertBToKBPipe implements PipeTransform
{
    constructor(
        private translate: TranslateService,
        private numbersService: NumbersService
    ) { }

    transform (bytesValue: number, showUnit: boolean = false): string
    {
        if (bytesValue === undefined || bytesValue === null)
        {
            return '';
        }

        const kiloBytesValue: string = this.numbersService.toFixed(bytesValue / 1024, 2);

        return showUnit ? `${kiloBytesValue} ${this.translate.instant('NUMBERS.UNITS.DECIMAL.KIBI')}` : kiloBytesValue;
    }
}
