import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, filter, withLatestFrom, concatAll, toArray, tap, debounceTime, exhaustMap, takeUntil } from 'rxjs/operators';
import { of, Observable, throwError, Subject, timer } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';


import { AppConstants } from '@apps/app.constants';
import { NotificationService, NotfGravity, NotifType } from '@common/services';
import { IAuroraState } from '@apps/aurora.state';
import { InMemoryFilter, Predicate } from '@common/filters/in-memory-filter';
import { multiFilter } from '@common/operators/multi-filter';

import * as fromOstkVolumesActions from './volu.actions';
import { VolumeListDomainService } from '../../../domain';
import { getAllVolumesVolu, getVolumesVoluFieldsFilter, getVolumesVoluIsDataValid } from '../../ostk-volumes.reducer';
import { Attachment, Instance, Volume } from '../../../domain/models';
import { VolumeMiscDomainService } from '../../../domain/volume/volume-misc-domain.service';

@Injectable()
export class OstkVolumesVoluEffects
{
    private destroyator: Subject<unknown>;

    constructor (
        private actions: Actions,
        private store: Store<IAuroraState>,
        private volumeListDomainService: VolumeListDomainService,
        private notificationService: NotificationService,
        private volumeMiscDomainService: VolumeMiscDomainService
    )
    { }

    ostkVolumesRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromOstkVolumesActions.OstkVolumesVoluListRequested),
                withLatestFrom(this.store.select(getVolumesVoluIsDataValid)),
                filter(([, valid]) => !valid),
                switchMap(() =>
                    this.volumeListDomainService.getVolumes()
                        .pipe(
                            switchMap((volumes: Volume[]) =>
                                [
                                    fromOstkVolumesActions.OstkVolumesVoluListSucceeded({ volumes }),
                                    fromOstkVolumesActions.OstkVolumesApplyFilter({ vf4f: null })
                                ]
                            ),
                            catchError(err =>
                                of(fromOstkVolumesActions.OstkVolumesVoluListFailed({ err }))
                            )
                        )
                )
            )
    );

    ostkVolumeCreateRequested: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromOstkVolumesActions.OstkVolumesVoluCreationRequested),
                switchMap(({ createVolume }) =>
                    this.volumeMiscDomainService.createVolume(createVolume).pipe(
                        map((volume: Volume) =>
                            fromOstkVolumesActions.OstkVolumesVoluCreationSucceeded({ volume, oldStatus: volume.status })
                        ),
                        catchError((err) =>
                            of(fromOstkVolumesActions.OstkVolumesVoluCreationFailed(err))
                        )
                    )
                )
            )
    );

    ostkVolumesVoluCreationSucceeded: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluCreationSucceeded),
                    tap(()=>
                        this.notificationService.notify(
                            [
                                '',
                                'OSTK.VOLUME.CREATE.NOTIF.SUCCEEDED'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    ),
                    tap(() =>
                        this.destroyator = new Subject()
                    ),
                    map(({ volume, oldStatus }: { volume: Volume; oldStatus: string; }) =>
                        fromOstkVolumesActions.OstkVolumesVoluPollingStatusRequested({
                            volumeId: volume.id,
                            oldStatus
                        })
                    )
                )
    );

    ostkVolumesVoluCreationFailed: Observable<Action> = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluCreationFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                '',
                                'OSTK.VOLUME.CREATE.NOTIF.FAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkVolumesVoluPollingStatusRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluPollingStatusRequested),
                    switchMap(({ volumeId, oldStatus }: { volumeId: string; oldStatus: string; }) =>
                        timer(0, AppConstants.PollingStatusInterval)
                            .pipe(
                                exhaustMap(() =>
                                    this.volumeMiscDomainService.getVolume(volumeId)
                                        .pipe(
                                            tap((volume: Volume) =>
                                            {
                                                if (oldStatus !== volume.status)
                                                {
                                                    const update: Update<Volume> = {
                                                        id: volume.id,
                                                        changes:volume
                                                    };

                                                    this.store.dispatch(fromOstkVolumesActions.OstkVolumesVoluChanged({ volume: update }));

                                                    this.destroyator.next(null);
                                                    this.destroyator.complete();
                                                }
                                            }),
                                            catchError((err) =>
                                            {
                                                this.destroyator.next(null);
                                                this.destroyator.complete();
                                                return of(fromOstkVolumesActions.OstkVolumesVoluCreationFailed(err));
                                            })
                                        )
                                ),
                                takeUntil(this.destroyator)
                            )
                    )
                ),
        { dispatch: false }
    );

    ostkVolumesApplyFilter = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesApplyFilter),
                    withLatestFrom(
                        this.store.select(getAllVolumesVolu),
                        this.store.select(getVolumesVoluFieldsFilter),
                        (_, volumes, vf4f) =>
                            ({
                                volumes,
                                vf4f
                            })
                    ),
                    filter(({ vf4f }) => !!vf4f),
                    switchMap(({ volumes, vf4f }) =>
                    {
                        const predicates: Predicate<Volume>[] = new InMemoryFilter(vf4f.fields4Filter)
                            .predicates;

                        return of(volumes)
                            .pipe(
                                concatAll(),
                                multiFilter(predicates),
                                toArray(),
                                map((filteredVolumes: Volume[]) =>
                                    fromOstkVolumesActions.OstkVolumesFilterApplied({ volumes: filteredVolumes })
                                )
                            );
                    })
                )
    );

    ostkVolumesVoluAttachRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluAttachRequested),
                    switchMap(({ volume, serverId }) =>
                        this.volumeMiscDomainService.attachVolume(volume, serverId)
                            .pipe(
                                filter((attached: boolean) =>
                                    attached === true
                                ),
                                map(() =>
                                    fromOstkVolumesActions.OstkVolumesVoluAttachSucceeded({ volume: volume })
                                )
                            )
                    )
                )
    );

    ostkVolumesVoluAttachSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluAttachSucceeded),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.VOLUME.ATTACH.NOTIF.TITLE',
                                'OSTK.VOLUME.ATTACH.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );
                    }),
                    debounceTime(1000),
                    map(({ volume }) =>
                        fromOstkVolumesActions.OstkVolumesVoluRefreshOneRequested({ volumeId: volume.id })
                    )
                )
    );

    ostkVolumesVoluDetachRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluDetachRequested),
                    switchMap(({ volume }) =>
                    {
                        const instance: Instance = volume.attachments.find((att: Attachment) => att.instance).instance;

                        return this.volumeMiscDomainService.detachVolume(
                            volume,
                            instance.id
                        )
                            .pipe(
                                map(() =>
                                    fromOstkVolumesActions.OstkVolumesVoluDetachSucceeded({ volume: volume })
                                )
                            );
                    }
                    )
                )
    );

    ostkVolumesVoluDetachSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluDetachSucceeded),
                    tap(() =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.VOLUME.DETACH.NOTIF.TITLE',
                                'OSTK.VOLUME.DETACH.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );
                    }),
                    debounceTime(1000),
                    map(({ volume }) =>
                        fromOstkVolumesActions.OstkVolumesVoluRefreshOneRequested({ volumeId: volume.id })
                    )
                )
    );

    ostkVolumesVoluExtendRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluExtendRequested),
                    switchMap(({ volume, newSize }) =>
                        this.volumeMiscDomainService.extendVolume(volume, newSize)
                            .pipe(
                                map(() =>
                                    fromOstkVolumesActions.OstkVolumesVoluExtendSucceeded({ volume: volume, newSize: newSize })
                                ),
                                catchError(error =>
                                    of(fromOstkVolumesActions.OstkVolumesVoluExtendFailed({ error }))
                                )
                            )
                    )
                )
    );

    ostkVolumesVoluExtendSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluExtendSucceeded),
                    map(({ volume, newSize }) =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.VOLUME.EXTEND.NOTIF.TITLE',
                                'OSTK.VOLUME.EXTEND.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );

                        return fromOstkVolumesActions.OstkVolumesVoluChanged(
                            {
                                volume: {
                                    id: volume.id,
                                    changes: {
                                        ...volume,
                                        size: newSize
                                    }
                                }
                            }
                        );
                    })
                )
    );

    ostkVolumesVoluExtendFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluExtendFailed),
                    tap(({ error }) =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.VOLUME.EXTEND.NOTIF.TITLE',
                                'OSTK.VOLUME.EXTEND.NOTIF.MSGFAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        );
                        if (error)
                        {
                            throwError(error);
                        }
                    })
                ),
        { dispatch: false }
    );

    ostkVolumesVoluDeleteRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluDeleteRequested),
                    switchMap(({ volume }) =>
                        this.volumeMiscDomainService.deleteVolume(volume)
                            .pipe(
                                map(() =>
                                    fromOstkVolumesActions.OstkVolumesVoluDeleteSucceeded({ volumeId: volume.id })
                                ),
                                catchError(error =>
                                    of(fromOstkVolumesActions.OstkVolumesVoluDeleteFailed({ error }))
                                )
                            )
                    )
                )
    );

    ostkVolumesVoluDeleteSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluDeleteSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'OSTK.VOLUME.DELETE.NOTIF.TITLE',
                                'OSTK.VOLUME.DELETE.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    ostkVolumesVoluDeleteFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluDeleteFailed),
                    tap(({ error }) =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.VOLUME.DELETE.NOTIF.TITLE',
                                'OSTK.VOLUME.DELETE.NOTIF.MSGFAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        );
                        if (error)
                        {
                            throwError(error);
                        }
                    })
                ),
        { dispatch: false }
    );

    ostkVolumesVoluRefreshOneRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluRefreshOneRequested),
                    switchMap(({ volumeId }) =>
                        this.volumeMiscDomainService.getVolume(volumeId)
                            .pipe(
                                map(volume =>
                                    fromOstkVolumesActions.OstkVolumesVoluChanged(
                                        {
                                            volume: {
                                                id: volume.id,
                                                changes: { ...volume }
                                            }
                                        }
                                    )
                                )
                            )
                    )
                )
    );

    ostkSelectVolumeRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkSelectVolumeRequested),
                    switchMap(props =>
                        this.store.select(getAllVolumesVolu)
                            .pipe(
                                map(volumes => !volumes ? null : volumes.find(v => v.id === props.volumeId)),
                                filter(volume => volume !== null),
                                map(volume =>
                                    fromOstkVolumesActions.OstkVolumeSelected({ volume: volume })
                                )
                            )
                    )
                )
    );

    ostkVolumesVoluRenameRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluRenameRequested),
                    switchMap(({ volume, newName }) =>
                        this.volumeMiscDomainService.renameVolume(volume, newName)
                            .pipe(
                                map(() =>
                                    fromOstkVolumesActions.OstkVolumesVoluRenameSucceeded({ volume: volume, newName: newName })
                                ),
                                catchError(error =>
                                    of(fromOstkVolumesActions.OstkVolumesVoluRenameFailed({ error }))
                                )
                            )
                    )
                )
    );

    ostkVolumesVoluRenameSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluRenameSucceeded),
                    map(({ volume, newName }) =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.VOLUME.RENAME.NOTIF.TITLE',
                                'OSTK.VOLUME.RENAME.NOTIF.MSGSUCCESS'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        );

                        return fromOstkVolumesActions.OstkVolumesVoluChanged(
                            {
                                volume: {
                                    id: volume.id,
                                    changes: { name: newName }
                                }
                            }
                        );
                    })
                )
    );

    ostkVolumesVoluRenameFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromOstkVolumesActions.OstkVolumesVoluRenameFailed),
                    tap(({ error }) =>
                    {
                        this.notificationService.notify(
                            [
                                'OSTK.VOLUME.RENAME.NOTIF.TITLE',
                                'OSTK.VOLUME.RENAME.NOTIF.MSGFAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        );
                        if (error)
                        {
                            throwError(error);
                        }
                    })
                ),
        { dispatch: false }
    );
}
