import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { DateTime } from 'luxon';

import * as fromRootStore from '@apps/root/store';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { Comparer } from '@common/services/utils.service';

import { Tag, DetailedTag, EnrichedTag } from '../../models';
import * as fromTagsActions from './tags.actions';
import { CmdbConstants } from '../../cmdb.constants';

export const CmdbTagsFeatureKey = 'tags';
type ITagsState = EntityState<EnrichedTag>;

export const tagComparer: Comparer<EnrichedTag> = (elta: EnrichedTag, eltb: EnrichedTag) =>
    elta.categoryName.toLowerCase().localeCompare(eltb.categoryName.toLowerCase())
    || elta.tagName.toLowerCase().localeCompare(eltb.tagName.toLowerCase());
export const tagsAdapter: EntityAdapter<EnrichedTag> = createEntityAdapter({
    selectId: (tag: EnrichedTag) => tag.tagId,
    sortComparer: tagComparer
});
const tagInitialState: ITagsState = tagsAdapter.getInitialState({});

export interface ICmdbTagsState
{
    tags: ITagsState;
    isEmpty: boolean;
    isLoading: boolean;
    isLoaded: boolean;
    isOups: boolean;
    detailedTag: DetailedTag;
    expirationDate: DateTime;
    qsWord: string;
}

const initialState: ICmdbTagsState = {
    tags: tagInitialState,
    isEmpty: false,
    isLoading: false,
    isLoaded: false,
    isOups: false,
    detailedTag: null,
    expirationDate: null,
    qsWord: ''
};

export const CmdbTagsReducer = createReducer(
    initialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => initialState
    ),
    on(
        fromTagsActions.CmdbTagsListSucceeded,
        (state, { tags }) =>
            ({
                ...state,
                isEmpty: tags && tags.length === 0,
                isLoading: false,
                isLoaded: true,
                isOups: false,
                tags: tagsAdapter.setAll(tags, tagsAdapter.removeAll(state.tags)),
                expirationDate: DateTime.utc().plus({ minutes: CmdbConstants.DataLifeTime })
            })
    ),
    on(
        fromTagsActions.CmdbTagsListFailed,
        (state) =>
            ({
                ...state,
                isLoading: false,
                isLoaded: false,
                isEmpty: false,
                isOups: true,
                tags: tagsAdapter.removeAll(state.tags)
            })
    ),
    on(
        fromTagsActions.CmdbTagDetailedSucceeded,
        (state, { detailedTag }) =>
            ({
                ...state,
                detailedTag: detailedTag
            })
    ),
    on(
        fromTagsActions.CmdbTagCreateSucceeded,
        (state, { tag }: { tag: EnrichedTag; }) =>
            ({
                ...state,
                tags: tagsAdapter.addOne(tag, state.tags)
            })
    ),
    on(
        fromTagsActions.CmdbTagDeleteSucceeded,
        (state, { tagId }) => ({
            ...state,
            tags: tagsAdapter.removeOne(tagId, state.tags)
        })
    ),
    on(
        fromTagsActions.CmdbTagUpdateSucceeded,
        (state, { tag }: { tag: Update<Tag>; }) =>
            ({
                ...state,
                tags: tagsAdapter.updateOne(tag, state.tags),
                detailedTag: {
                    ...state.detailedTag,
                    ...tag.changes
                }
            })
    ), on(
        fromTagsActions.CmdbTagsQsWordSet,
        (state, { qsWord }) =>
            ({
                ...state,
                qsWord: qsWord.toLowerCase()
            })

    )
);
