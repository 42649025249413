import { Injectable } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { BaseListDomainService } from '@common/domain/base-list-domain.service';
import { DatesService } from '@common/services';
import { setDifference, setIntersection } from '@common/services/utils.service';
import { arrayMapper } from '@common/operators';

import { CapacityListApiService } from '../api/capacity-list-api.service';
import { Capacity, VolumetricVariation } from './models';
import { CapacityApi } from '../api/models';
import { BckpConstants } from '../bckp.constants';
@Injectable({
    providedIn: 'root'
})
export class CapacityListDomainService extends BaseListDomainService
{
    constructor (
        private capacityListApiService: CapacityListApiService,
        private datesService: DatesService
    )
    {
        super();
    }

    getCapacities (since: DateTime, until: DateTime): Observable<Capacity[]>
    {
        const range: string = this.datesService.RawUtcDateRange(since, until);

        return this.capacityListApiService.getCapacities(range)
            .pipe(
                map((obj: any): CapacityApi[] => obj.value),
                arrayMapper(Capacity.mapperFromApi)
            );
    }

    getTotalCapacity (since: DateTime, until: DateTime): Observable<number>
    {
        const range: string = this.datesService.RawUtcDateRange(since, until);

        return this.capacityListApiService.getCapacities(range)
            .pipe(
                map((obj: any): CapacityApi[] =>
                    obj.value
                ),
                map((result: Capacity[]) =>
                    result.reduce((prev: number, curr: Capacity) =>
                        prev + curr.kbytesSent, 0)
                )
            );
    }

    getCountServers (since: DateTime, until: DateTime): Observable<number>
    {
        const range: string = this.datesService.RawUtcDateRange(since, until);

        return this.capacityListApiService.getCapacities(range)
            .pipe(
                map((obj: any): CapacityApi[] =>
                    obj.value
                ),
                map((result: Capacity[]) =>
                    result.length
                )
            );
    }

    getVolumetricVariation (since: DateTime, until: DateTime): Observable<VolumetricVariation[]>
    {
        const { prevSince, prevUntil } = this.datesService.getPreviousRange(since, until);

        return combineLatest([this.getCapacities(prevSince, prevUntil), this.getCapacities(since, until)])
            .pipe(
                map((capacities: Capacity[][]) =>
                {
                    const volumetrics: VolumetricVariation[] = [];

                    const prevCapacities = capacities[0];
                    const currCapacities = capacities[1];
                    const prevCapas: Map<string, Capacity> = new Map<string, Capacity>();
                    const currCapas: Map<string, Capacity> = new Map<string, Capacity>();

                    const prevServers: Set<string> = new Set([...prevCapacities.map((capa: Capacity) => capa.serverShort)]);
                    const currServers: Set<string> = new Set([...currCapacities.map((capa: Capacity) => capa.serverShort)]);
                    const oldServers: Set<string> = setDifference<string>(prevServers, currServers);
                    const newServers: Set<string> = setDifference<string>(currServers, prevServers);
                    const allServers: Set<string> = setIntersection<string>(prevServers, currServers);

                    prevCapacities.forEach((capa: Capacity) =>
                    {
                        prevCapas.set(capa.serverShort, capa);
                    });

                    currCapacities.forEach((capa: Capacity) =>
                    {
                        currCapas.set(capa.serverShort, capa);
                    });

                    oldServers.forEach((server: string) =>
                    {
                        volumetrics.push(
                            new VolumetricVariation(server, prevCapas.get(server).kbytesSent, undefined)
                        );
                    });

                    newServers.forEach((server: string) =>
                    {
                        volumetrics.push(
                            new VolumetricVariation(server, undefined, currCapas.get(server).kbytesSent)
                        );
                    });

                    allServers.forEach((server: string) =>
                    {
                        volumetrics.push(
                            new VolumetricVariation(server, prevCapas.get(server).kbytesSent, currCapas.get(server).kbytesSent)
                        );
                    });

                    // Calcul des volumétries totales
                    const totalVolumetry: [number, number] = volumetrics
                        .reduce<[number, number]>((previous: [number, number], volVar: VolumetricVariation) =>
                        [
                            volVar.prevVolumetry ? previous[0] + volVar.prevVolumetry : previous[0],
                            volVar.currVolumetry ? previous[1] + volVar.currVolumetry : previous[1]
                        ],
                    [0, 0]
                    );

                    volumetrics.push(
                        new VolumetricVariation(BckpConstants.TotalColumnName, totalVolumetry[0], totalVolumetry[1])
                    );

                    return volumetrics;
                })
            );
    }
}
