import { FilesService, UtilsService } from '../services';

export enum OperationStatus
    {
    failed = -1,
    unavailable = 0,
    successful = 1
}

export class CustomFile extends File
{
    public ext: string;
    public icon: string;
    public id: string;
    public validateOverwriting: boolean = false;
    public uploadedStatus: OperationStatus = 0;

    static addProperties (file: CustomFile): void
    {
        file.icon = FilesService.getFileIconFromName(file.name);
        file.id = UtilsService.hash(file.name);
    }

    public static mapperToStoreCustomFile (file: CustomFile): StoreCustomFile
    {
        return {
            id: file.id,
            name: file.name,
            icon: file.icon,
            validateOverwriting: file.validateOverwriting
        } as StoreCustomFile;
    }
}

export type StoreCustomFile = Pick<CustomFile, 'id' | 'name' | 'icon' | 'validateOverwriting'>;
