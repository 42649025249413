import { DateTime } from 'luxon';

import { DatesService, UtilsService } from '@common/services';
import { EnumIpType } from '@apps/ostk/ostk.constants';

import { InstanceApi } from '../../../api/models';
import { Metadata, Addresses, Address, IPV4Address, CreateInstanceViewModel } from '../../../models/instance';
import { VolumeApi } from '../../../api/models';
import { Flavor } from './flavor';
import { SecurityGroup } from '../security';
import { Volume } from '../volumes';
import { InstancesConstants, InstanceStatus } from '../../../instances.constants';
import { HasMajorProps } from '../common';
import { ImageSnapshot } from '../image-snapshot';
import { DomainFault, Fault } from './fault';

export class Instance implements HasMajorProps
{
    accessIPv4?: string;
    accessIPv6?: string;
    addresses?: Addresses;
    attachedVolumes?: string[] | null;
    configDrive?: string;
    createdAt?: DateTime;
    flavor?: Flavor;
    hostId?: string;
    id: string;
    idFlavor?: string;
    idImage?: string;
    keyName?: string;
    metadata?: Metadata;
    name: string;
    osDcfDiskConfig?: string;
    osExtAzAvailabilityZone?: string;
    osExtStsPowerState?: number;
    osExtStsVmState?: string;
    osSrvUsgLaunchedAt?: string;
    progress?: number;
    securityGroups?: SecurityGroup[] | null;
    status?: string;
    isRunning?: boolean;
    tenantId?: string;
    updatedAt?: DateTime;
    userId?: string;
    volumes?: Volume[] | null;
    snapshots?: ImageSnapshot[] | null;
    osIconLocation?: string;
    osLabel?: string;
    statusLabelKey?: string;
    statusClass?: string;
    faIconStatus?: string;
    vram?: number;
    vcpu?: number;
    diskTotalSizeGb?: number;
    ipAdresses?: {
        [key: string]: IPV4Address
    };
    fault?: Fault;
    addressesLabel: string = '';

    updateStatus (instanceApi: InstanceApi)
    {
        DomainInstance.buildStatus(instanceApi.status, this);
    }

    // get networksCount (): number
    // {
    //     if (this.ipAdresses)
    //     {
    //         return Object.keys(this.ipAdresses).length;
    //     }

    //     return 0;
    // }
}

export class DomainInstance extends Instance
{
    public static mapperFromApi (source: InstanceApi, volumes?: Volume[], mapFlavor?: Map<string, Flavor>): Instance
    {
        const target = new Instance();
        const os = source.metadata?.['os-icon'];

        Object.assign(target, source);

        DomainInstance.buildStatus(source.status, target);

        target.osLabel = UtilsService.getOsLabel(os, 'unknown');
        target.osIconLocation = UtilsService.getFullOsPicture(os);

        target.createdAt = DatesService.UtcStringToDateTimeHourStatic(source.createdAt);
        target.updatedAt = DatesService.UtcStringToDateTimeHourStatic(source.updatedAt);

        if (volumes)
        {
            if (!source.volumes)
            {
                target.volumes = [...volumes];
            }
            else
            {
                target.volumes = source.volumes.length > 0
                    ? source.volumes.map((v: VolumeApi) => Volume.mapperFromApi(v))
                    : null;
            }
        }

        target.diskTotalSizeGb = target.volumes
            ? target.volumes.reduce((acc: number, curr: Volume) => acc + curr.size, 0)
            : null;

        if (mapFlavor)
        {
            const flavor: Flavor = mapFlavor.get(source.idFlavor);

            target.vram = flavor ? flavor.vram : 0;
            target.vcpu = flavor ? flavor.vcpu : 0;

            if (!target.diskTotalSizeGb)
            {
                target.diskTotalSizeGb = flavor ? flavor.disk : 0;
            }
        }

        if (source.addresses && Object.keys(source.addresses).length > 0)
        {
            target.ipAdresses = {};
            for (const key in source.addresses)
            {
                target.ipAdresses[key] = {} as IPV4Address;
                const ipFixed = source.addresses[key].find((addr: Address) => addr.ipType === EnumIpType.FIXED);
                if (ipFixed)
                {
                    target.ipAdresses[key].ipV4Fixed = ipFixed.addr;
                }
                const ipFloating = source.addresses[key].find((addr: Address) => addr.ipType === EnumIpType.FLOATING);
                if (ipFloating)
                {
                    target.ipAdresses[key].ipV4Floating = ipFloating.addr;
                }
            }

            const netwNames: string[] = [];
            Object.keys(source.addresses)
                .forEach((network: string) =>
                {
                    const fixed = source.addresses[network].find(a => a.ipType === EnumIpType.FIXED);
                    const floating = source.addresses[network].find(a => a.ipType === EnumIpType.FLOATING);

                    netwNames.push(network);

                    const addressesIP = [fixed.addr];
                    if (floating)
                    {
                        addressesIP.push(floating.addr);
                    }

                    netwNames.push(`(${addressesIP.join(' / ')})`);
                });
            target.addressesLabel = netwNames.join(' ');
        }

        try
        {
            target.fault = DomainFault.mapperFromApi(source.fault);
        }
        catch (err)
        {
            console.debug({ source, err });
        }

        return target;
    }

    public static MapperFromViewModel (source: CreateInstanceViewModel): Instance
    {

        const os = source.osIcon;
        const target = {
            isRunning: false,
            name:source.name,
            osLabel: UtilsService.getOsLabel(os, 'unknown'),
            osIconLocation: UtilsService.getFullOsPicture(os),
            id: UtilsService.hash(source.name),
            createdAt: DateTime.utc(),
            vcpu: 1,
            vram: 1,
            diskTotalSizeGb: 10,
            addresses: null,
        } as Instance;
        DomainInstance.buildStatus('CREATION', target);

        // target.osLabel = UtilsService.getOsLabel(os, 'unknown');
        // target.osIconLocation = UtilsService.getFullOsPicture(os);

        // target.id = UtilsService.hash(source.name);
        // target.createdAt = DateTime.utc();
        // target.vcpu = 1;
        // target.vram = 1;
        // target.diskTotalSizeGb = 10;
        // target.addresses = null;

        return target;
    }

    public static buildStatus (sourceStatus: string, target: Instance)
    {
        target.status = sourceStatus.toUpperCase();
        target.isRunning = target.status === InstanceStatus.ACTIVE;

        const tempKlass: string = target.status ? InstancesConstants.getStatusClass(target.status) : null;
        target.statusClass = tempKlass;
        target.faIconStatus = `fa-${tempKlass}`;
        target.statusLabelKey = target.status ? InstancesConstants.getStatusLabelKey(target.status) : null;
    }
}
