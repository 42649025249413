import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, switchMap, withLatestFrom, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromOstkActions from './quotas.actions';
import { IAuroraState } from 'src/app/_apps/aurora.state';
import { getQuotasIsDataValid } from '../ostk.reducer';
import { Quota } from '../../domain/models';
import { QuotaMiscDomainService } from '../../domain';

@Injectable()
export class OstkQuotasEffects
{
    constructor(
        private actions: Actions,
        private store: Store<IAuroraState>,
        private quotaMiscDomainService: QuotaMiscDomainService
    ) { }

    ostkQuotaRequested = createEffect(() =>
        this.actions.pipe(
            ofType(fromOstkActions.OstkQuotasListRequested),
            withLatestFrom(this.store.select(getQuotasIsDataValid)),
            filter(([_, valid]) => !valid),
            switchMap(() =>
                this.quotaMiscDomainService.getQuotas()
                    .pipe(
                        map((quotas: Quota[]) =>
                            fromOstkActions.OstkQuotasListSucceeded({ quotas })
                        ),
                        catchError((err) =>
                            of(fromOstkActions.OstkQuotasListFailed(err))
                        )
                    )
            )
        )
    );
}
