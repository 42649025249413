import { Action, combineReducers } from '@ngrx/store';

import { IAuroraState } from 'src/app/_apps/aurora.state';
import * as fromVolumesVoluReducer from './volu/volu.reducer';
import * as fromVolumesSnapReducer from './snap/snap.reducer';

export * from './volu/volu.reducer';
export * from './snap/snap.reducer';

export const OstkVolumesFeatureKey = 'volumes';

export interface IState extends IAuroraState
{
    [OstkVolumesFeatureKey]: IOstkVolumesState;
}

export function OstkVolumesReducers (state: IOstkVolumesState | undefined, action: Action)
{
    return combineReducers({
        [fromVolumesVoluReducer.OstkVolumesVoluFeatureKey]: fromVolumesVoluReducer.OstkVolumesVoluReducer,
        [fromVolumesSnapReducer.OstkVolumesSnapFeatureKey]: fromVolumesSnapReducer.OstkVolumesSnapReducer
    })(state, action);
}

export interface IOstkVolumesState
{
    [fromVolumesVoluReducer.OstkVolumesVoluFeatureKey]: fromVolumesVoluReducer.IOstkVolumesVoluState;
    [fromVolumesSnapReducer.OstkVolumesSnapFeatureKey]: fromVolumesSnapReducer.IOstkVolumesSnapState;
}
