import { OverlayRef } from '@angular/cdk/overlay';

import { Failure } from '@common/models';
import { NotfGravity } from '@common/services';

export class SnackbarRef
{
    private intervalId: any;

    constructor (
        public readonly overlayRef: OverlayRef,
        public failure: Failure,
        public gravity?: NotfGravity
    )
    {
        this.intervalId = setTimeout((): void => this.destroy(), 5000);
    }

    private destroy (): void
    {
        clearTimeout(this.intervalId);
        this.overlayRef.dispose();
    }
}
