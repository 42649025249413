import { AbstractControl, AsyncValidatorFn, Validator, Validators, ValidatorFn, AsyncValidator, } from '@angular/forms';

import { of } from 'rxjs';

export interface ValidationResult { [validator: string]: string | boolean; }
export type AsyncValidatorArray = Array<AsyncValidator | AsyncValidatorFn>;
export type ValidatorArray = Array<Validator | ValidatorFn>;

const normalizeValidator =
    (validator: Validator | ValidatorFn): ValidatorFn | AsyncValidatorFn =>
    {
        const func = (validator as Validator).validate.bind(validator);
        if (typeof func === 'function')
        {
            return (c: AbstractControl) => func(c);
        }
        else
        {
            return <ValidatorFn | AsyncValidatorFn>validator;
        }
    };

export const composeValidators =
    (validators: ValidatorArray): AsyncValidatorFn | ValidatorFn =>
    {
        if (validators == null || validators.length === 0)
        {
            return null;
        }

        return Validators.compose(validators.map(normalizeValidator));
    };

export const validate =
    (validators: ValidatorArray, asyncValidators: AsyncValidatorArray) =>
    {
        return (control: AbstractControl) =>
        {
            const synchronousValid = () => composeValidators(validators)(control);

            if (asyncValidators)
            {
                const asyncValidator = composeValidators(asyncValidators);

                return asyncValidator(control).map(v =>
                {
                    const secondary = synchronousValid();

                    if (secondary || v)
                    { // compose async and sync validator results
                        return Object.assign({}, secondary, v);
                    }
                });
            }

            if (validators)
            {
                return of(synchronousValid());
            }

            return of(null);
        };
    };

