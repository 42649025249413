import { Injector } from '@angular/core';

import { OData } from '@common/api/qs/odata';
import { DatesService } from '@common/services';

import { ServersFilter } from '../../../models';

export class ODataBckpServersHeat extends OData
{
    private _filter: ServersFilter;
    private field4Filter: string[] = [
        'master',
        'serverShort',
        'policyName',
        'policyType'
    ];
    get filter ()
    {
        return this._filter;
    }
    get since ()
    {
        return this._filter.since;
    }
    get until ()
    {
        return this._filter.until;
    }
    public datesService: DatesService;
    public qsExport: string;
    public qsSinceUntil: string;

    constructor (incrSize: number, filter?: ServersFilter, nbItems?: number)
    {
        super(incrSize, nbItems);

        const injector = Injector.create({ providers: [{ provide: DatesService, deps: [] }] });
        this.datesService = injector.get(DatesService);

        this.exportValues = [];

        if (filter !== undefined)
        {
            this.processFilter(filter);
        }
        this.top = 0;


        this.qs = this.getQueryString();
        this.qsExport = this.getExportQueryString();
    }

    public init ()
    {
        this.processFilter(new ServersFilter());
    }

    private processFilter (filter: ServersFilter)
    {
        this._filter = new ServersFilter();
        Object.assign(this._filter, filter);

        this.pushValue('since', filter.since);
        this.pushValue('until', filter.until);
        this.pushValue('tags', true, true);


        this.qsSinceUntil = `?since=${filter.since}&until=${filter.until}`;

        this.field4Filter.forEach((f: string) =>
        {
            if (filter[f])
            {
                this.pushValue('terms', `${f}:${filter[f]}`, false);
            }
        });
    }

    private getExportQueryString (): string
    {
        const queries: string[] = [];
        let qs: string = '?';

        this.exportValues
            .forEach((kv: { key: string, value: any }) =>
            {
                queries.push(`${kv.key}=${kv.value}`);
            });
        qs += queries.join('&');

        return qs;
    }
}
