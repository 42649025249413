import { filterTagAdapter } from '@apps/root/store/filter-tag/filter-tag.state';
import { getRootFilterTagState } from '@apps/root/store/root.reducer';

export const {
    selectAll: getFilterTags,
} = filterTagAdapter.getSelectors(getRootFilterTagState);

// export const getFilterTags = createSelector(
//     getStoreFilterTags,
//     (storeFilterTags: StoreFilterTag[]): FilterTag[] =>
//     {
//         console.debug({ storeFilterTags });
//         console.debug({ mapped: storeFilterTags.map(StaticFilterTag.mapperFromStoreFilterTag) });
//         return storeFilterTags.map(StaticFilterTag.mapperFromStoreFilterTag);
//     }
// );
