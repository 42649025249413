import { Injectable } from '@angular/core';

import { DateTime } from 'luxon';

import { NavbarItem } from '../models/navbar-item';
import { NavbarItems } from 'src/app/_layouts/navbar/navbar-item.constants';
import { AppConstants } from 'src/app/_apps/app.constants';
import { CompareUpperString2 } from '@common/services/utils.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationsService
{
    private static excludedIds: string[] = ['AUTH', 'DASH', 'ITSM'];
    private publicFilter: (ni: NavbarItem) => boolean = (ni: NavbarItem): boolean =>
        ni.isPublic;
    private adminFilter: (ni: NavbarItem) => boolean = (ni: NavbarItem): boolean =>
        ni.isAdmin;
    private dashboardFilter: (ni: NavbarItem) => boolean = (ni: NavbarItem): boolean =>
        ni.onDashboard && ni.enabled;

    public publicNavbarItems (forIcons: boolean = false): NavbarItem[]
    {
        if (forIcons)
        {
            return this.buildNavbar(this.publicFilter)
                .filter((ni: NavbarItem): boolean =>
                    !ApplicationsService.excludedIds.includes(ni.id)
                )
                .sort(CompareUpperString2<NavbarItem>((ni: NavbarItem): string =>
                    ni.id
                ));
        }

        return this.buildNavbar(this.publicFilter);
    }

    public adminNavbarItems (simplified: boolean = false): NavbarItem[]
    {
        if (simplified)
        {
            return this.buildNavbar(this.adminFilter)
                .filter((ni: NavbarItem): boolean =>
                    ni.id === 'BACK.DASH'
                );
        }

        return this.buildNavbar(this.adminFilter);
    }

    public dashboardNavbarItems = (): NavbarItem[] =>
        this.buildNavbar(this.dashboardFilter);

    public habilitationNavbarItems (): NavbarItem[]
    {
        return this.buildHabilitationsNavbar();
    }

    private buildNavbar (f: (ni: NavbarItem) => boolean): NavbarItem[]
    {
        const items = NavbarItems
            .filter(f)
            .filter((ni: NavbarItem): boolean =>
            {
                if (AppConstants.IsProductionStage)
                {
                    return ni.production < DateTime.utc();
                }

                if (AppConstants.IsStagingStage)
                {
                    return ni.staging < DateTime.utc();
                }

                return true;
            });
        items
            .sort((a: NavbarItem, b: NavbarItem): number =>
                a.order - b.order
            );

        return items;
    }

    private buildHabilitationsNavbar (): NavbarItem[]
    {
        const items = NavbarItems
            .filter((ni: NavbarItem): boolean =>
                ni.isPublic && ni.habilitation
            )
            .map((ni: NavbarItem): NavbarItem =>
            {
                ni.order = ni.isDeployed ? 100 : ni.soon ? 50 : ni.order;

                return ni;
            });
        items
            .sort((a: NavbarItem, b: NavbarItem): number =>
                b.order - a.order
            );

        return items;
    }
}
