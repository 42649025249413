import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import * as fromRootImpersonateActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import { Its2Constants } from '@apps/its2/its2.constants';
import { RequestDocument } from '@apps/its2/domain/models';

import * as fromIts2DocumentsActions from './documents.actions';

export const Its2DocumentsFeatureKey = 'documents';

type IDocumentsState = EntityState<RequestDocument>;
export const documentsAdapter: EntityAdapter<RequestDocument> = createEntityAdapter<RequestDocument>({
    selectId: (requestDocument: RequestDocument) => requestDocument.documentId
});
const documentsInitialState: IDocumentsState = documentsAdapter.getInitialState({
});

export interface IIts2DocumentsState
{
    documents: IDocumentsState;
    isLoading: boolean;
    isLoaded: boolean;
    isEmpty: boolean;
    isOups: boolean;
    expirationDate?: DateTime;
}
export const Its2DocumentsState: IIts2DocumentsState = {
    documents: documentsInitialState,
    isLoading: false,
    isLoaded: false,
    isEmpty: false,
    isOups: false,
    expirationDate: null
};

export const Its2DocumentsReducer = createReducer(
    Its2DocumentsState,
    on(
        fromRootImpersonateActions.RootImpersonateSet,
        fromRootImpersonateActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => Its2DocumentsState
    ),
    on(
        fromIts2DocumentsActions.Its2RequestsDocumentsRequested,
        (state: IIts2DocumentsState) => ({
            ...state,
            isLoading: true
        })
    ),
    on(
        fromIts2DocumentsActions.Its2RequestsDocumentsSucceeded,
        (state: IIts2DocumentsState, { requestDocuments }: { requestDocuments: RequestDocument[]; }) => ({
            ...state,
            isLoading: false,
            isLoaded: true,
            isEmpty: requestDocuments.length === 0,
            documents: documentsAdapter.setAll(requestDocuments, state.documents),
            expirationDate: DateTime.utc().plus({ minutes: Its2Constants.DataLifeTime })
        })
    )
);
