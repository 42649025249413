import { BaseFields4Filter } from '@common/filters/base-fields-4filter';

import { Volume } from '../../domain/models';

export class VolumeFields4Filter extends BaseFields4Filter<Volume>
{
    constructor (filtre?: VolumeFields4Filter)
    {
        super();
    }
}
