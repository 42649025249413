<form id="auth-form" [formGroup]="formGroup">
    <div class="auth-title">{{ 'AUTH.TWOFACTORAUTHENTICATION.TITLE' | translate | uppercase }}</div>
    <div class="auth-sub-title">
        <div>{{ 'AUTH.VERIFYCODE.LABEL' | translate }}</div>
        <div *ngIf="(remainingTries | async) as remainingTries">
            <span class="twofactor-nbtries" *ngIf="remainingTries < 3">
                {{ 'AUTH.TWOFACTORAUTHENTICATION.ERRORS.RETRY' | translate: { remainingTries: remainingTries} }}
            </span>
        </div>
    </div>
    <div class="twofactor-digit-code">
        <aa-input-digit-code name="{{ CodeField }}" label="{{ CodeField }}" required formControlName="{{ CodeField }}"
            [updateWhen]="'blur'">
        </aa-input-digit-code>
    </div>
    <div class="twofactor-buttons">
        <aa-btn-action-secondary [btnText]="'AUTH.FORM.BUTTON' | translate" [cta]="onSubmit" [btnWidth]="'100%'">
        </aa-btn-action-secondary>
    </div>
</form>
