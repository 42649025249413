import { createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';

import { Comparer, filterQuickSearch } from '@common/services/utils.service';
import * as fromTagsReducer from './tags/tags.reducer';
import { getCmdbState, ICmdbState } from './cmdb.reducer';
import { EnrichedTag, Tag, NoneTag, AnyTag, FilterTag, StaticFilterTag } from '../models';
import { CmdbConstants } from '../cmdb.constants';
import { tagComparer } from './tags/tags.reducer';

// #Region tags
const getCmdbTagsState = createSelector(
    getCmdbState,
    (state: ICmdbState) =>
        state[fromTagsReducer.CmdbTagsFeatureKey]
);
const getCollectionState = createSelector(
    getCmdbTagsState,
    (state) => state.tags
);
export const {
    selectAll: getAllTags,
} = fromTagsReducer.tagsAdapter.getSelectors(getCollectionState);

const getQuickSearchedTags = createSelector(
    getCmdbTagsState,
    getAllTags,
    (state: fromTagsReducer.ICmdbTagsState, tags: EnrichedTag[]) =>
        filterQuickSearch(tags, state.qsWord, CmdbConstants.tagsQSFields)
);
export const getPipelinedTags = createSelector(
    getQuickSearchedTags,
    (tags: EnrichedTag[], params: { comparer: Comparer<EnrichedTag>; }) =>
        tags.sort(params.comparer)
);
export const getTagsLoadingStatus = createSelector(
    getCmdbTagsState,
    (state) => state.isLoading
);
export const getTagsLoadedStatus = createSelector(
    getCmdbTagsState,
    (state) => state.isLoaded
);
export const getTagsOupsStatus = createSelector(
    getCmdbTagsState,
    (state) => state.isOups
);
export const getTagsEmptyStatus = createSelector(
    getCmdbTagsState,
    (state) => state.isEmpty
);
export const getTagsIsDataValid = createSelector(
    getCmdbTagsState,
    (state) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);
// #endregion

// #region DetailedTag
export const getDetailedTag = createSelector(
    getCmdbTagsState,
    (state) =>
        state.detailedTag
);
export const getTagById = createSelector(
    getAllTags,
    (tags: Tag[], props: { tagId: string; }) =>
        tags.find((tag: Tag) =>
            tag.tagId === props.tagId
        )
);
export const getTagsByIds = createSelector(
    getAllTags,
    (tags: Tag[], props: { tagIds: string[]; }) =>
        tags.filter((tag: Tag) =>
            props.tagIds ? props.tagIds.includes(tag.tagId) : false
        )
);

export const getTagsByNotIds = createSelector(
    getAllTags,
    (tags: Tag[], props: { tagIds: string[]; }) =>
        tags.filter((tag: Tag) => props.tagIds ? !props.tagIds.includes(tag.tagId) : false)
);

export const getAvailableFilterTags = (filterTagIds: string[]) => createSelector(
    getAllTags,
    (tags: Tag[]): FilterTag[] =>
    {
        const allTags = tags
            .sort(tagComparer)
            .map(StaticFilterTag.mapperFromTag);

        return [new NoneTag(), new AnyTag(), ...allTags]
            .filter((tag: FilterTag): boolean =>
                filterTagIds.length !== 0 ? !filterTagIds.includes(tag.tagId) : false);
    }
);
// #endregion
