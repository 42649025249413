import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GaugeBarComponent } from './gauge-bar.component';

@NgModule({
    declarations: [
        GaugeBarComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        GaugeBarComponent
    ]
})
export class GaugeBarModule
{ }
