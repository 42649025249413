import { HttpEvent } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import { Entity, User } from '@apps/auth/domain/models/back';
import { AppliRole, PartialUser } from '@apps/back/models';
import { MediaTypes } from '@common/services';
import { DetailedUser } from '@apps/back/store/auth/users/users.common';
import { CreateUserViewModel } from '@apps/back/models/create-user-view-model';

export const BackAuthUserSelectedSet = createAction(
    '[BACK-AUTH-ENTITY] Back Auth Users Selected Set',
    props<{ user: DetailedUser }>()
);
export const BackAuthUserSelectedReset = createAction(
    '[BACK-AUTH-USER] Back Auth Users Selected Reset',
);

// #region users updates
export const BackAuthUsersDetailsUpdateRequested = createAction(
    '[BACK-AUTH-USER] Back Auth Users Details Update Requested'
);
export const BackAuthUsersIdentityUpdateRequested = createAction(
    '[BACK-AUTH-USER] Back Auth Users Identity Update Requested',
    props<{ user: User }>()
);
export const BackAuthUsersIdentityUpdateSucceeded = createAction(
    '[BACK-AUTH-USER] Back Auth Users Identity Update Succeeded',
    props<{ user: User }>()
);
export const BackAuthUsersIdentityUpdateFailed = createAction(
    '[BACK-AUTH-USER] Back Auth Users Identity Update Failed'
);

export const BackAuthUsersHabsUpdateRequested = createAction(
    '[BACK-AUTH-USER] Back Auth Users Habs Update Requested',
    props<{ userId: string, habs: AppliRole[] }>()
);
export const BackAuthUsersHabsUpdateSucceeded = createAction(
    '[BACK-AUTH-USER] Back Auth Users Habs Update Succeeded',
    props<{ user: User }>()
);
export const BackAuthUsersHabsUpdateFailed = createAction(
    '[BACK-AUTH-USER] Back Auth Users Habs Update Failed'
);
export const BackAuthUsersAddHabilitationRequested = createAction(
    '[BACK-AUTH-USER] Back Auth Users Add Habilitation Requested',
    props<{ external: boolean, userId: string, appRole: AppliRole }>()
);
export const BackAuthUsersRemoveHabilitationRequested = createAction(
    '[BACK-AUTH-USER] Back Auth Users Remove Habilitation Requested',
    props<{ external: boolean, userId: string, appRole: AppliRole }>()
);
export const BackAuthUsersAddHabilitationSucceeded = createAction(
    '[BACK-AUTH-USER] Back Auth Users Add Habilitation Succeeded',
    props<{ appRole: AppliRole }>()
);
export const BackAuthUsersRemoveHabilitationSucceeded = createAction(
    '[BACK-AUTH-USER] Back Auth Users Remove Habilitation Succeeded',
    props<{ appRole: AppliRole }>()
);
export const BackAuthUsersAddHabilitationFailed = createAction(
    '[BACK-AUTH-USER] Back Auth Users Add Habilitation Failed',
    props<{ appRole: AppliRole }>()
);
export const BackAuthUsersRemoveHabilitationFailed = createAction(
    '[BACK-AUTH-USER] Back Auth Users Remove Habilitation Failed',
    props<{ appRole: AppliRole }>()
);

export const BackAuthUsersExportRequested = createAction(
    '[BACK-AUTH-USER] Back Auth Users Export Requested',
    props<{ qs: string, format: MediaTypes }>()
);

export const BackAuthUsersExportSucceeded = createAction(
    '[BACK-AUTH-USER] Back Auth Users Export Succeeded',
    props<{ response: HttpEvent<Blob> }>()
);

export const BackAuthUsersExportFailed = createAction(
    '[BACK-AUTH-USER] Back Auth Users Export Failed',
    props<{ failure: any }>()
);
// #endregion

export const BackAuthUsersAddUserRequested = createAction(
    '[BACK-AUTH-USER] Back Auth Users Add User Requested',
    props<{ user: CreateUserViewModel; }>()
);
export const BackAuthUsersAddUserSucceeded = createAction(
    '[BACK-AUTH-USER] Back Auth Users Add User Succeeded',
    props<{ user: User }>()
);
export const BackAuthUsersAddUserFailed = createAction(
    '[BACK-AUTH-USER] Back Auth Users Add User Failed'
);
export const BackAuthUsersDeactivateUserRequested = createAction(
    '[BACK-AUTH-USER] Back Auth Users Deactivate User Requested',
    props<{ user: User; }>()
);
export const BackAuthUsersActivateUserRequested = createAction(
    '[BACK-AUTH-USER] Back Auth Users Activate User Requested',
    props<{ user: User; }>()
);