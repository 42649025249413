import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseListApiService } from 'src/app/_common/api/base-list-api.service';
import { EntityApi } from './models/back/entity-api';
import { ResultApi } from 'src/app/_common/api/result-api';
import { AppConstants } from '../../app.constants';

@Injectable({
    providedIn: 'root'
})
export class EntitiesListApiService extends BaseListApiService<EntityApi>
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlAuth;
        this.resourceUrl = '/auth/entities';
    }

    getEntities (qs: string): Observable<ResultApi<EntityApi>>
    {
        if (AppConstants.IsDataLocal('back') && qs.includes('external=true'))
        {
            return this.http.get<ResultApi<EntityApi>>('assets/data/back/entities-external.json');
        }

        return this.getAll(`${this.resourceUrl}${qs}`);
    }

    searchEntities (qs: string)
    {
        return this.getAll(`${this.resourceUrl}/search${qs}`);
    }
}
