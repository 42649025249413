import { DateTime } from 'luxon';

import { IGroupByPeriod } from '@apps/bckp/domain/models';
import { DatesService } from '@common/services/dates.service';
import { UtilsService } from '@common/services/utils.service';

export const enum GroupByPeriodEnum
{
    HALFH = 'half hour',
    HOUR = 'hour',
    HALFD = 'half day',
    DAY = 'day'
}


const roundDateTime = (dt: DateTime, gbte: GroupByPeriodEnum): DateTime=>
{
    switch (gbte)
    {
        case GroupByPeriodEnum.HALFH:
            if (dt.minute < 30)
            {
                return dt.set({ minute: 0, second:0 });
            }
            else
            {
                return dt.set({ minute: 30, second:0 });
            }

        case GroupByPeriodEnum.HOUR:
            return dt.set({ minute: 0, second:0 });

        case GroupByPeriodEnum.HALFD:
            if (dt.hour < 12)
            {
                return dt.set({ hour: 0, minute: 0, second:0 });
            }
            else
            {
                return dt.set({ hour: 12, minute: 0, second:0 });
            }

        case GroupByPeriodEnum.DAY:
            return dt.set({ hour: 0, minute: 0, second:0 });
    }
};

export const groupByPeriod = <T extends IGroupByPeriod> (source: T[], gbpe: GroupByPeriodEnum): T[] =>
{
    source.forEach((item: T) =>
    {
        item.groupedPeriod = roundDateTime(item.originalPeriod, gbpe);
        item.groupedPeriodISO = item.groupedPeriod.toISO();
        item.groupedPeriodMillis = item.groupedPeriod.toMillis();
    });

    return Array.from(UtilsService.groupByString<T>(source, (item: T) => DatesService.DateToQueryStringStatic(item.groupedPeriod)))
        .map((grouping: [string, T[]]) =>
        {
            const init: T = {
                groupedPeriod: DatesService.UtcStringToDateTimeHourStatic(grouping[0]),
                groupedPeriodISO: DatesService.UtcStringToDateTimeHourStatic(grouping[0]).toISO(),
                groupedPeriodMillis: DatesService.UtcStringToDateTimeHourStatic(grouping[0]).toMillis(),
                groupedValue: {}
            } as T;
            const temp: T[] = grouping[1];
            // Tableau des propriétés à cumuler par exemple ["numImages", "bytesTotal"]
            const keys = Object.keys(temp[0].originalValues);
            keys.forEach((k: string) =>
            {
                init.groupedValue[k] = 0;
            });
            const obj = temp.reduce((previous: T, currentItem: T) =>
            {
                keys.forEach((k: string) =>
                {
                    previous.groupedValue[k] += currentItem.originalValues[k];
                });

                return previous;
            }, init);

            return obj;
        });
};
