import { DateTime } from 'luxon';

import { RequestUrgencyCounterByDayApi, RequestUrgencyCounterApi } from '@apps/its2/api/models';

export class RequestUrgencyCounterByDay
{
    constructor (
        public dateUtc: DateTime,
        public urgencies: RequestUrgencyCounter[],
        public totalCreated: number,
        public totalClosed: number
    )
    {}
}

export class DomainEvolutionSummary
{
    public static mapperFromApi (source: RequestUrgencyCounterByDayApi): RequestUrgencyCounterByDay
    {
        const target = new RequestUrgencyCounterByDay(
            DateTime.fromISO(source.dateUtc),
            source.urgencies?.map<RequestUrgencyCounter>(DomainRequestUrgencyCounter.mapperFromApi),
            source.totalCreated,
            source.totalClosed
        );

        return target;
    }
}

class RequestUrgencyCounter
{
    public urgencyId: number;
    public countCreated: number;
    public countClosed: number;
}

class DomainRequestUrgencyCounter
{
    public static mapperFromApi (source: RequestUrgencyCounterApi): RequestUrgencyCounter
    {
        const target = new RequestUrgencyCounter();

        Object.assign(target, source);

        return target;
    }
}