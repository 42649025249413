import { Component } from '@angular/core';

import { IconCircleComponent } from '../icon-circle.component';

@Component({
    selector: 'aa-action-circle',
    templateUrl: '../icon-circle.component.html',
    styleUrls: [
        './action-circle.component.scss',
        '../icon-circle.component.scss'
    ]
})
export class ActionCircleComponent extends IconCircleComponent
{
    constructor ()
    {
        super();
    }
}
