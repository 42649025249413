import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// Controls - Input
import { InputEmailComponent } from './controls/input/input-email/input-email.component';
import { InputTextComponent } from './controls/input/input-text/input-text.component';
import { InputText2Component } from './controls/input/input-text2/input-text2.component';
import { InputPasswordComponent } from './controls/input/input-password/input-password.component';
import { InputPassword2Component } from './controls/input/input-password2/input-password2.component';
import { InputNewPswdComponent } from './controls/input/input-new-pswd/input-new-pswd.component';
import { InputNumberComponent } from './controls/input/input-number/input-number.component';
import { InputTokenCodeComponent } from './controls/input/input-token-code/input-token-code.component';
import { InputPhoneComponent } from './controls/input/input-phone/input-phone.component';
import { ValidationComponent } from './controls/input/validation/validation.component';
import { Validation2Component } from './controls/input/validation2/validation2.component';
import { InputComboboxComponent } from './controls/input/input-combobox/input-combobox.component';
import { InputDateComponent } from './controls/input/input-date/input-date.component';
import { InputDate2Component } from './controls/input/input-date2/input-date2.component';
// Controls - radio et chekbox
// Contols - Textarea / editor
// Misc
import { InputUploadComponent } from './controls/input/input-upload/input-upload.component';
import { InputSwitchComponent } from './controls/input/input-switch/input-switch.component';
import { SwitchableInputTextComponent } from './controls/input/switchable-input-text/switchable-input-text.component';
import { CheckboxGroupComponent } from './controls/checkbox/checkbox-group/checkbox-group.component';
import { CheckboxComponent } from './controls/checkbox/checkbox/checkbox.component';
import { RadioGroupComponent } from './controls/radiobutton/radio-group/radio-group.component';
import { RadioComponent } from './controls/radiobutton/radio/radio.component';
import { CreatePswdFormComponent } from './complex/create-pswd-form/create-pswd-form.component';
import { InputWithPrefixComponent } from './controls/input/input-with-prefix/input-with-prefix.component';
import { InputServersComponent } from './controls/input/input-entities/input-servers/input-servers.component';
import { InputPolicyTypesComponent } from './controls/input/input-entities/input-policy-types/input-policy-types.component';
import { InputPoliciesComponent } from './controls/input/input-entities/input-policies/input-policies.component';
import { InputMastersComponent } from './controls/input/input-entities/input-masters/input-masters.component';
import { RangePickerModule } from './complex/range-picker/range-picker.module';
import { RangePickerComponent } from './complex/range-picker/range-picker.component';
import { InputServerShortsComponent } from './controls/input/input-entities/input-server-shorts/input-server-shorts.component';
import { Checkbox2Component } from './controls/checkbox2/checkbox2/checkbox2.component';
import { CheckboxGroup2Component } from './controls/checkbox2/checkbox-group2/checkbox-group2.component';
import { TreeviewComponent } from './controls/treeview/treeview.component';
import { Treeview2Component } from './controls/treeview2/treeview2.component';
import { InputTextareaComponent } from './controls/input/input-textarea/input-textarea.component';
import { ValidationManualComponent } from './controls/input/validation-manual/validation-manual.component';
import { InputNumber2Component } from './controls/input/input-number2/input-number2.component';
import { InputCombobox2Component } from './controls/input/input-combobox2/input-combobox2.component';
import { InputHidden2Component } from './controls/input/input-hidden2/input-hidden2.component';
import { RadioGroup2Component } from './controls/radiobutton2/radio-group2/radio-group2.component';
import { Radio2Component } from './controls/radiobutton2/radio2/radio2.component';
import { RadioGroup3Component } from './controls/radiobutton3/radio-group3/radio-group3.component';
import { Radio3Component } from './controls/radiobutton3/radio3/radio3.component';
import { InputEmail2Component } from './controls/input/input-email2/input-email2.component';
import { ColorPaletteComponent } from './controls/color-palette/color-palette.component';
import { InputIpv4CidrComponent, InputIpv4Component, InputIpv6CidrComponent, InputIpv6Component } from './controls/input/input-ip';
import { InputColor2Component } from './controls/input/input-color2/input-color2.component';
import { PeriodSelectorComponent } from './controls/period-selector/period-selector.component';
import { InputPhone2Component } from './controls/input/input-phone2/input-phone2.component';
import { InputEditorComponent } from './controls/input/input-editor/input-editor.component';
import { InputTreeview2Component } from './controls/input/input-treeview2/input-treeview2.component';
import { PopupTreeviewComponent } from './controls/input/input-treeview2/popup-treeview/popup-treeview.component';
import { InputIts2ListComponent } from './controls/input/input-entities/input-its2-list/input-its2-list.component';
import { InputCopiesServersComponent } from './controls/input/input-entities/input-copies-servers/input-copies-servers.component';
import { InputCopiesPoliciesComponent } from './controls/input/input-entities/input-copies-policies/input-copies-policies.component';
import { InputCopiesResidencesComponent } from './controls/input/input-entities/input-copies-residences/input-copies-residences.component';
import { InputCopiesSlpComponent } from './controls/input/input-entities/input-copies-slp/input-copies-slp.component';
import { InputCopyText2Component } from './controls/input/input-copy-text2/input-copy-text2.component';
import { InputRangeComponent } from './controls/input/input-range/input-range.component';
import { PopupRangeModule } from './controls/input/input-range/popup-range/popup-range.module';
import { InputNewPswd2Component } from './controls/input/input-new-pswd2/input-new-pswd2.component';
import { InputDigitCodeComponent } from './controls/input/input-digit-code/input-digit-code.component';

@NgModule({
    declarations: [
        InputEmailComponent,
        InputEmail2Component,
        InputPhoneComponent,
        InputPhone2Component,
        InputTextComponent,
        InputText2Component,
        SwitchableInputTextComponent,
        InputPasswordComponent,
        InputPassword2Component,
        InputNewPswdComponent,
        InputNewPswd2Component,
        InputNumberComponent,
        InputNumber2Component,
        InputTokenCodeComponent,
        InputComboboxComponent,
        InputDateComponent,
        InputDate2Component,
        ValidationComponent,
        Validation2Component,
        ValidationManualComponent,
        InputUploadComponent,
        InputSwitchComponent,
        CheckboxGroupComponent,
        CheckboxComponent,
        RadioGroupComponent,
        RadioComponent,
        RadioGroup2Component,
        Radio2Component,
        RadioGroup3Component,
        Radio3Component,
        CreatePswdFormComponent,
        InputWithPrefixComponent,
        InputServersComponent,
        InputServerShortsComponent,
        InputPolicyTypesComponent,
        InputPoliciesComponent,
        InputMastersComponent,
        Checkbox2Component,
        CheckboxGroup2Component,
        TreeviewComponent,
        Treeview2Component,
        InputTextareaComponent,
        InputCombobox2Component,
        InputHidden2Component,
        ColorPaletteComponent,
        InputIpv4Component,
        InputIpv4CidrComponent,
        InputIpv6Component,
        InputIpv6CidrComponent,
        InputColor2Component,
        PeriodSelectorComponent,
        InputEditorComponent,
        InputTreeview2Component,
        PopupTreeviewComponent,
        InputIts2ListComponent,
        InputCopiesServersComponent,
        InputCopiesPoliciesComponent,
        InputCopiesResidencesComponent,
        InputCopiesSlpComponent,
        InputCopyText2Component,
        InputRangeComponent,
        InputDigitCodeComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        NgSelectModule,
        RangePickerModule,
        PopupRangeModule,
        TranslateModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        NgSelectModule,
        InputEmailComponent,
        InputEmail2Component,
        InputPhoneComponent,
        InputPhone2Component,
        InputTextComponent,
        InputText2Component,
        SwitchableInputTextComponent,
        InputPasswordComponent,
        InputPassword2Component,
        InputNewPswdComponent,
        InputNewPswd2Component,
        InputNumberComponent,
        InputNumber2Component,
        InputTokenCodeComponent,
        InputComboboxComponent,
        InputDateComponent,
        InputDate2Component,
        ValidationComponent,
        Validation2Component,
        ValidationManualComponent,
        InputUploadComponent,
        InputSwitchComponent,
        CheckboxGroupComponent,
        CheckboxComponent,
        RadioGroupComponent,
        RadioComponent,
        RadioGroup2Component,
        Radio2Component,
        RadioGroup3Component,
        Radio3Component,
        CreatePswdFormComponent,
        InputWithPrefixComponent,
        InputServersComponent,
        InputServerShortsComponent,
        InputPolicyTypesComponent,
        InputPoliciesComponent,
        InputMastersComponent,
        RangePickerComponent,
        Checkbox2Component,
        CheckboxGroup2Component,
        TreeviewComponent,
        Treeview2Component,
        InputTextareaComponent,
        InputCombobox2Component,
        InputHidden2Component,
        ColorPaletteComponent,
        InputIpv4Component,
        InputIpv4CidrComponent,
        InputIpv6Component,
        InputIpv6CidrComponent,
        InputColor2Component,
        PeriodSelectorComponent,
        InputEditorComponent,
        InputTreeview2Component,
        InputIts2ListComponent,
        InputCopiesServersComponent,
        InputCopiesPoliciesComponent,
        InputCopiesResidencesComponent,
        InputCopiesSlpComponent,
        InputCopyText2Component,
        InputRangeComponent,
        InputDigitCodeComponent
    ],
    providers: [
        TranslatePipe
    ]
})
export class UiFormModule
{
}
