import { combineReducers, Action, createFeatureSelector } from '@ngrx/store';


import { IAuroraState } from '../../aurora.state';
import * as fromTagsReducer from './tags/tags.reducer';
import * as fromAssetsReducer from './assets/assets.reducer';

export const CmdbFeatureKey = 'cmdb';

export interface IState extends IAuroraState
{
    [CmdbFeatureKey]: ICmdbState;
}

export interface ICmdbState
{
    [fromTagsReducer.CmdbTagsFeatureKey]: fromTagsReducer.ICmdbTagsState;
    [fromAssetsReducer.CmdbAssetsFeatureKey]: fromAssetsReducer.ICmdbAssetsState;
}

export function CmdbReducers (state: ICmdbState, action: Action)
{
    return combineReducers({
        [fromTagsReducer.CmdbTagsFeatureKey]: fromTagsReducer.CmdbTagsReducer,
        [fromAssetsReducer.CmdbAssetsFeatureKey]: fromAssetsReducer.CmdbAssetsReducer,
    })(state, action);
}

export const getCmdbState = createFeatureSelector<ICmdbState>(
    CmdbFeatureKey
);
