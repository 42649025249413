import { DateTime } from 'luxon';

import { ImageCopyApi } from '@apps/bckp/api/models';
import { Copy } from './copy';
import { DatesService } from '@common/services';

export class ImageCopy
{
    documentId: string;
    collectTime: DateTime;
    policyName: string;
    scheduleType: number;
    scheduleTypeEnum: string;
    clientType?: number;
    policyType?: string;
    server: string;
    master: string;
    backupId: string;
    backupTime: DateTime;
    slpName: string;
    originMasterServer?: string;
    originMasterServerId?: string;
    copy: Copy[];
    numOtherCopies: number;
    index: number;
    size: number = 0;

    public static mapperFromApi (source: ImageCopyApi, index: number): ImageCopy
    {
        const target = new ImageCopy();

        Object.assign(target, source);

        target.copy = source.copy.map(Copy.mapperFromApi);
        target.size = target.copy.reduce<number>((prev:number, curr: Copy) => prev + curr.size, 0);
        target.backupTime = DatesService.UtcStringToDateTimeHourStatic(source.backupTime);
        target.collectTime = DatesService.UtcStringToDateTimeHourStatic(source.collectTime);
        target.index = index;

        return target;
    }
}
