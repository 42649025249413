import { Component } from '@angular/core';

import { BtnActionComponent } from '../btn-action/btn-action.component';

@Component({
    selector: 'aa-btn-action-transparent',
    templateUrl: '../btn-action/btn-action.component.html',
    styleUrls: [
        '../btn-action/btn-action.component.scss',
        './btn-action-transparent.component.scss'
    ]
})
export class BtnActionTransparentComponent extends BtnActionComponent
{
    constructor ()
    {
        super();
    }
}
