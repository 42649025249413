import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { tap, map, switchMap, catchError, concatMap } from 'rxjs/operators';

import { AuthConstants } from '@apps/auth/auth.constants';
import { AccountCreationService } from '@apps/auth/api/account-creation.service';
import { Profile } from '@apps/auth/models/profile';
import * as fromAccountActions from './account.actions';

@Injectable()
export class AuthAccountEffects
{
    constructor (
        private actions: Actions,
        private router: Router,
        private accountCreationService: AccountCreationService
    )
    {
    }

    authAccountCreateStep1Enter = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAccountActions.AuthAccountCreateStep1Enter),
                    tap(() =>
                        this.router.navigate(AuthConstants.AccountCreateStepRoute(1))
                    )
                ),
        { dispatch: false }
    );

    authAccountCreateStep1Complete = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAccountActions.AuthAccountCreateStep1Complete),
                switchMap((action) =>
                    this.accountCreationService.emailConfirmSendLink(action.email)
                        .pipe(
                            map(() =>
                                fromAccountActions.AuthConfirmEmailLinkSent()
                            ),
                            catchError((error) =>
                            {
                                // if (error.status === 409)
                                // {
                                //     return of(fromAuthLoginActions.AuthLogin({ email: action.email }));
                                // }

                                return of(fromAccountActions.AuthConfirmEmailProblemArisen({ error }));
                            })
                        )
                )
            )
    );

    authConfirmEmailLinkSent = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAccountActions.AuthConfirmEmailLinkSent),
                    tap(() =>
                        this.router.navigate(AuthConstants.AccountCreateLinkSentRoute())
                    )
                ),
        { dispatch: false }
    );

    authConfirmEmailProblemArisen = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAccountActions.AuthConfirmEmailProblemArisen),
                    tap(() =>
                        this.router.navigate(AuthConstants.AccountCreateProblemArisenRoute())
                    )
                ),
        { dispatch: false }
    );

    authAccountCreateStep2Enter = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAccountActions.AuthAccountCreateStep2Enter),
                    tap(() =>
                        this.router.navigate(AuthConstants.AccountCreateStepRoute(2))
                    )
                ),
        { dispatch: false }
    );

    authAccountCreateStep2Complete = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAccountActions.AuthAccountCreateStep2Complete),
                concatMap((action) =>
                    this.accountCreationService.self(action.profile)
                        .pipe(
                            map((profile: Profile) =>
                                fromAccountActions.AuthAccountCreateStep3Enter({ profile })
                            ),
                            catchError((error) =>
                            {
                                // if (error.status === 409)
                                // {
                                //     return of(fromAccountActions.AuthAccountCreateStep3Enter({ email: action.email }));
                                // }

                                return of(fromAccountActions.AuthConfirmEmailProblemArisen({ error }));
                            }
                            )
                        )
                )
            )
    );

    authAccountCreateStep3Enter = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAccountActions.AuthAccountCreateStep3Enter),
                    tap(() =>
                        this.router.navigate(AuthConstants.AccountCreateStepRoute(3))
                    )
                ),
        { dispatch: false }
    );

    authAccountCreateStep3Complete = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAccountActions.AuthAccountCreateStep3Complete),
                map((_) =>
                    fromAccountActions.AuthAccountCreateStep4Enter()
                )
            )
    );

    authAccountCreateStep4Enter = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAccountActions.AuthAccountCreateStep4Enter),
                    tap(() =>
                        this.router.navigate(AuthConstants.AccountCreateStepRoute(4))
                    )
                ),
        { dispatch: false }
    );

    authAccountCreateStep4Complete = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAccountActions.AuthAccountCreateStep4Complete),
                    // tap(() =>
                    //     this.router.navigate(AuthConstants.AccountCreateStepRoute(1))
                    // )
                ),
        { dispatch: false }
    );
}
