import { Injector } from '@angular/core';

import { DateTime } from 'luxon';

import { DatesService } from '@common/services';

export class RangeFilter
{
    private _datesService: DatesService;
    get datesService (): DatesService
    {
        return this._datesService;
    }
    private _since: string;
    get since ()
    {
        return this._since;
    }
    private _until: string;
    get until ()
    {
        return this._until;
    }
    private _rangeSince: DateTime;
    get rangeSince (): DateTime
    {
        return this._rangeSince;
    }
    set rangeSince (value: DateTime)
    {
        this._rangeSince = value;
        this._since = this.datesService.DateToQueryString(this._rangeSince);
    }
    private _rangeUntil: DateTime;
    get rangeUntil (): DateTime
    {
        return this._rangeUntil;
    }
    set rangeUntil (value: DateTime)
    {
        this._rangeUntil = value;
        this._until = this.datesService.DateToQueryString(this._rangeUntil);
    }

    constructor ()
    {
        const injectorDates = Injector.create({ providers: [{ provide: DatesService, deps: [] }] });

        this._datesService = injectorDates.get(DatesService);
    }
}
