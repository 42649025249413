import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { BaseListDomainService } from '@common/domain/base-list-domain.service';
import { DatesService } from '@common/services/dates.service';
import { ResultApi } from '@common/api/result-api';

import { SuccessRateListApiService } from '../api/successrate-list-api.service';
import { SuccessRate, Status, StatusPerDay, JobPerPolicy, StatusPerPolicyType } from './models';
import { SuccessRateApi } from '../api/models';

@Injectable({
    providedIn: 'root'
})
export class SuccessRateListDomainService extends BaseListDomainService
{
    constructor (
        private successRateListApiService: SuccessRateListApiService,
        private datesService: DatesService
    )
    {
        super();
    }

    getSuccessRates (since: DateTime, until: DateTime): Observable<SuccessRate[]>
    {
        const range: string = this.datesService.RawUtcDateRange(since, until);

        return this.successRateListApiService.getSuccessRates(range)
            .pipe(
                map((obj: ResultApi<SuccessRateApi>): SuccessRateApi[] =>
                    obj.value
                ),
                map((result: SuccessRateApi[]) =>
                    result.map<SuccessRate>(SuccessRate.mapperFromApi)
                )
            );
    }

    getLastSession (since: DateTime, until: DateTime): Observable<Status[]>
    {
        const init: Status[] = [0, 1, 2].map((idx: number) => new Status(idx, 0, idx));

        return this.getSuccessRates(since, until)
            .pipe(
                map((successRates: SuccessRate[]) =>
                    successRates.reduce((prev: Status[], curr: SuccessRate) =>
                    {
                        for (let idx = 0; idx < 3; idx++)
                        {
                            prev[idx].counter += curr.groupByCodeStatuses[idx].counter;
                        }

                        return prev;

                    }, init))
            );
    }

    getStatusPerRange (since: DateTime, until: DateTime): Observable<StatusPerDay[]>
    {
        return this.getSuccessRates(since, until);
    }

    getStatusPerPolicyType (since: DateTime, until: DateTime): Observable<StatusPerPolicyType[]>
    {
        return this.getSuccessRates(since, until)
            .pipe(
                map((successRates: SuccessRate[]) =>
                {
                    const xyz: Map<string, StatusPerPolicyType> = new Map<string, StatusPerPolicyType>();
                    const alpha: JobPerPolicy[] = [];

                    successRates.forEach((sr: SuccessRate) => sr.jobPerPolicy.forEach((jpp) => alpha.push(jpp)));
                    alpha.forEach((jpp: JobPerPolicy) =>
                    {
                        let sppt: StatusPerPolicyType;

                        if (xyz.has(jpp.policyType))
                        {
                            sppt = xyz.get(jpp.policyType);
                            jpp.groupByCodeStatuses.forEach((s: Status) =>
                            {
                                sppt.total += s.counter;
                                sppt.statuses[s.codeStatus].counter += s.counter;
                            });
                        }
                        else
                        {
                            sppt = new StatusPerPolicyType(
                                jpp.policyType,
                                [...jpp.groupByCodeStatuses],
                                jpp.groupByCodeStatuses.reduce((prev: number, curr: Status) => prev + curr.counter, 0)
                            );

                            xyz.set(jpp.policyType, sppt);
                        }
                    });
                    for (const [, v] of xyz)
                    {
                        v.successRate = v.calcSuccessRate();
                    }

                    return Array.from(xyz.values());
                })
            );
    }
}
