import { RequestsUrgencyCounter, StatusesMetadata } from '@common/models';

export const SecuDashTicketingFeatureKey = 'ticketing';

export interface ISecuDashTicketingIncidentsState extends StatusesMetadata
{
    counters: RequestsUrgencyCounter[]
}
export const SecuDashTicketingIncidentsInitialState: ISecuDashTicketingIncidentsState = {
    counters: [],
    ...StatusesMetadata.init()
};

export interface ISecuDashTicketingServicesState extends StatusesMetadata
{
    counters: RequestsUrgencyCounter[]
}
export const SecuDashTicketingServicesInitialState: ISecuDashTicketingServicesState = {
    counters: [],
    ...StatusesMetadata.init()
};

export interface ISecuDashTicketingState
{
    incidents: ISecuDashTicketingIncidentsState,
    services: ISecuDashTicketingServicesState
}

export const SecuDashboardTicketingInitialState: ISecuDashTicketingState = {
    incidents: SecuDashTicketingIncidentsInitialState,
    services: SecuDashTicketingServicesInitialState
};
