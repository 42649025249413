import { CompareLowerString2, UtilsService, CompareNumber2 } from '@common/services/utils.service';
import { SecurityGroupApi } from '../../../api/models';
import { SecurityRule } from './security-rule';

export class SecurityGroup
{
    description: string;
    id: string;
    name: string;
    rules: SecurityRule[];
    tenantId: string;
    protocols: string;

    get nbRules ()
    {
        return this.rules.length;
    }

    public static mapperFromApi (source: SecurityGroupApi): SecurityGroup
    {
        const target = new SecurityGroup();
        Object.assign(target, source);

        target.rules = source.rules.map(SecurityRule.mapperFromApi);

        const sortedRules = source.rules
            .filter((item: SecurityRule) =>
                item.protocol
            )
            .sort(CompareLowerString2<SecurityRule>((item: SecurityRule) => item.protocol));
        const groupedRules = UtilsService.groupByString(sortedRules, (sr: SecurityRule) => sr.protocol);
        target.protocols = Array.from(groupedRules)
            .map((value: [string, SecurityRule[]]) =>
            {
                const ports: string = value[1]
                    .filter((item: SecurityRule) =>
                        item.portRangeMin
                    )
                    .sort(CompareNumber2<SecurityRule>((item: SecurityRule) => item.portRangeMin))
                    .map(SecurityRule.toString)
                    .join(', ');

                return `${value[0]}/${ports}`;
            })
            .join(' - ');

        return target;
    }
}

export class CreateSecurityGroupViewModel
{
    name: string;
    description: string;
}
