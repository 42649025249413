import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseMiscApiService } from 'src/app/_common/api/base-misc-api.service';
import { AppConstants } from '../../app.constants';
import { ImageApi } from './models';
import { UploadImageViewModel } from '../models/image/upload-image-view-model';

@Injectable({
    providedIn: 'root'
})
export class ImageMiscApiService extends BaseMiscApiService
{
    constructor ( http: HttpClient )
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlOstk;
        this.segmentApi = '/ostk/images';
    }

    getImageById (imageId: string): Observable<ImageApi>
    {
        return this.get<ImageApi>(`/${imageId}`);
    }

    uploadImage (image: UploadImageViewModel): Observable<boolean>
    {
        return this.post('/create', image)
            .pipe(
                map(() => true)
            );
    }

    deleteImage (id: string): Observable<string>
    {
        return this.delete(`/${id}`)
            .pipe(
                map(() => id)
            );
    }
}
