import { Injectable } from '@angular/core';
import { HttpEvent } from '@angular/common/http';

import { Observable, of, combineLatest } from 'rxjs';
import { map, defaultIfEmpty, first, filter, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { LanguageService, MediaTypes } from '@common/services';
import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { RequestMiscApiService } from '@apps/its2/api';
import { AppConstants } from '@apps/app.constants';
import { RequestActionDetailsApi, RequestApi, RequestCountersApi, RequestsSummaryCounterApi } from '@apps/its2/api/models';
import { DomainRequest, RequestCounters, Request, RequestActionDetails, RequestDocument, RequestsCatalogCounter, DomainRequestsCatalogCounter, RequestUrgencyCounterByDay, DomainEvolutionSummary } from '@apps/its2/domain/models';
import { RequestsSummaryCounter } from '@common/models';
import { arrayMapper, cdeb } from '@common/operators';
import { UploadFileState } from '@common/domain/models/upload-file-state';
import { Answer } from '@apps/its2/models/create-service';
import { CatalogEntry, RequestValidationParameters } from '@apps/its2/models';
import { Its2Constants, RequestTypeEnum } from '@apps/its2/its2.constants';
import { BckpFeatureKey } from '@apps/bckp/bckp.constants';
import { IAuroraState } from '@apps/aurora.state';
import { getAllActions, getIts2CatalogIncidentsGuidByAppli, getIts2CatalogServicesGuidByAppli } from '@apps/its2/store/its2.reducer';
import { DashFeatureKey } from '@apps/dash/dash.constants';

import { DomainRequestActionDetails } from './models/request-action';
import { DomainRequestDocument } from './models/request-document';
import { CreateRequestViewModel } from '../models/create-request-view-model';
import { DateTime } from 'luxon';
import { Its2DashboardFilter } from '../services/its2-dashboard-filter';

@Injectable({
    providedIn: 'root'
})
export class RequestMiscDomainService extends BaseMiscDomainService
{
    constructor (
        private store: Store<IAuroraState>,
        private requestMiscApiService: RequestMiscApiService,
        private languageService: LanguageService
    )
    {
        super();
    }

    getWarnings (): Observable<number>
    {
        return this.requestMiscApiService.getWarnings();
    }

    getCounters (): Observable<RequestCounters>
    {
        if (AppConstants.IsDataLocal('itsm'))
        {
            return of(new RequestCounters(1, 95));
        }

        return this.requestMiscApiService.getCounters()
            .pipe(
                defaultIfEmpty(new RequestCountersApi()),
                map<RequestCountersApi, RequestCounters>((result: RequestCountersApi) =>
                    new RequestCounters(result.userTickets, result.teamTickets)
                ),
                first()
            );
    }

    getDetails (rfcNumber: string): Observable<Request>
    {
        return this.requestMiscApiService.getDetails(rfcNumber)
            .pipe(
                map((requestApi: RequestApi) =>
                    DomainRequest.mapperFromApi(requestApi, this.languageService.language)
                )
            );
    }

    getActions (rfcNumber: string): Observable<RequestActionDetails[]>
    {
        return this.requestMiscApiService.getActions(rfcNumber)
            .pipe(
                arrayMapper(DomainRequestActionDetails.mapperFromApi)
            );
    }

    getActionDetails (rfcNumber: string, requestActionDetails: RequestActionDetails): Observable<RequestActionDetails>
    {
        // return this.store.select(getAllActions)
        //     .pipe(
        //         map(actions =>
        //         {
        //             const actionDetails = actions.find(a => a.actionId === requestActionDetails.actionId);
        //             DomainRequestActionDetails.setDisplayText(actionDetails, this.languageService.language === 'fr-FR' ? actionDetails.labelFR : actionDetails.labelEN);

        //             return actionDetails;
        //         })
        //     );
        return this.requestMiscApiService.getActionDetails(rfcNumber, requestActionDetails.actionId)
            .pipe(
                map((radApi: RequestActionDetailsApi) =>
                    DomainRequestActionDetails.mapperFromDetailsApi(
                        radApi,
                        this.languageService.language === 'fr-FR' ? radApi.labelFR : radApi.labelEN
                    )
                )
            );
    }

    createAction (rfcNumber: string, description: string)
    {
        return this.requestMiscApiService.createAction(rfcNumber, description);
    }

    getDocuments (rfcNumber: string): Observable<RequestDocument[]>
    {
        return this.requestMiscApiService.getDocuments(rfcNumber)
            .pipe(
                arrayMapper(DomainRequestDocument.mapperFromApi)
            );
    }

    getDocument (rfcNumber: string, physicalName: string): Observable<HttpEvent<Blob>>
    {
        return this.requestMiscApiService.getDocument(rfcNumber, physicalName);
    }

    export (qs: string, format: MediaTypes): Observable<HttpEvent<Blob>>
    {
        return this.requestMiscApiService.export(qs, format);
    }

    createRequest (createCase: CreateRequestViewModel): Observable<Request>
    {
        return this.requestMiscApiService.createRequest(createCase)
            .pipe(
                map(r => DomainRequest.mapperFromApi(r, this.languageService.language))
            );
    }

    postQuestion (rfcNumber: string, answer: Answer): Observable<unknown>
    {
        return this.requestMiscApiService.postQuestion(rfcNumber, answer.question.id, answer.response );
    }

    uploadDocument (rfcNumber: string, document: File): Observable<UploadFileState>
    {
        return this.requestMiscApiService.uploadDocument(rfcNumber, document);
    }

    validate (rfcNumber: string, rvp: RequestValidationParameters)
    {
        return this.requestMiscApiService.validate(rfcNumber, rvp);
    }

    getBckpSummary (): Observable<RequestsSummaryCounter>
    {
        const incidentsMetaGuid = Its2Constants.CatalogEntryMetaGuid[BckpFeatureKey][RequestTypeEnum.INCIDENTS];
        const servicesMetaGuid = Its2Constants.CatalogEntryMetaGuid[BckpFeatureKey][RequestTypeEnum.SERVICES];

        return combineLatest([
            this.store.select(getIts2CatalogIncidentsGuidByAppli, incidentsMetaGuid),
            this.store.select(getIts2CatalogServicesGuidByAppli, servicesMetaGuid)
        ])
            .pipe(
                filter(([ceIncidents, ceServices]: [CatalogEntry, CatalogEntry]) =>
                    ceIncidents !== null && ceServices !== null
                ),
                map(([ceIncidents, ceServices]: [CatalogEntry, CatalogEntry]) =>
                    [ceIncidents.catalogGuid, ceServices.catalogGuid]
                ),
                switchMap(([incidentsGuid, servicesGuid]: [string, string]) =>
                    combineLatest([
                        this.requestMiscApiService.getSummary(incidentsGuid),
                        this.requestMiscApiService.getSummary(servicesGuid)
                    ])
                        .pipe(
                            map(([incidentsSummaryApi, servicesSummaryApi]: [RequestsSummaryCounterApi, RequestsSummaryCounterApi]) =>
                                [
                                    RequestsSummaryCounter.mapperFromApi(incidentsSummaryApi),
                                    RequestsSummaryCounter.mapperFromApi(servicesSummaryApi)
                                ]
                            ),
                            map(([incidentsSummary, servicesSummary]: [RequestsSummaryCounter, RequestsSummaryCounter]) =>
                                new RequestsSummaryCounter(incidentsSummary, servicesSummary)
                            )
                        )
                )
            );
    }

    getSLAIncidentCounters (qs: string): Observable<number>
    {
        return this.requestMiscApiService.getSLAIncidentCounters(qs);
    }

    getSLAServiceCounters (qs: string): Observable<number>
    {
        return this.requestMiscApiService.getSLAServiceCounters(qs);
    }

    getSummaryCounters (type: RequestTypeEnum, qs: string): Observable<RequestsCatalogCounter[]>
    {
        return this.requestMiscApiService.getSummaryCounters(type, qs)
            .pipe(
                arrayMapper(DomainRequestsCatalogCounter.mapperFromApi)
            );
    }

    getEvolutionCounters (type: RequestTypeEnum, since: DateTime, until: DateTime): Observable<RequestUrgencyCounterByDay[]>
    {
        const qs: string = Its2DashboardFilter.mapToQueryString(since, until).qs;
        return this.requestMiscApiService.getEvolutionCounters(type, qs)
            .pipe(
                arrayMapper(DomainEvolutionSummary.mapperFromApi),
                map((collection: RequestUrgencyCounterByDay[]) =>
                    this.sortEvolutionSummary(this.completeEvolutionSummary(collection, since, until))
                )
            );
    }

    public getDashSummary (): Observable<RequestsSummaryCounter>
    {
        const incidentsGuid = Its2Constants.CatalogEntryGuid[DashFeatureKey][RequestTypeEnum.INCIDENTS];
        const servicesGuid = Its2Constants.CatalogEntryGuid[DashFeatureKey][RequestTypeEnum.SERVICES];
        const openOnly: boolean = true;

        return combineLatest([
            this.requestMiscApiService.getSummary(incidentsGuid, openOnly),
            this.requestMiscApiService.getSummary(servicesGuid, openOnly)
        ])
            .pipe(
                map(([incidentsSummaryApi, servicesSummaryApi]: [RequestsSummaryCounterApi, RequestsSummaryCounterApi]) =>
                    new RequestsSummaryCounter(
                        RequestsSummaryCounter.mapperFromApi(incidentsSummaryApi),
                        RequestsSummaryCounter.mapperFromApi(servicesSummaryApi)
                    )
                )
            );
    }

    private completeEvolutionSummary (evolutionSummary: RequestUrgencyCounterByDay[], since: DateTime, until: DateTime)
    {
        if (!evolutionSummary || evolutionSummary.length === 0)
        {
            return evolutionSummary;
        }

        const fullEvolution: RequestUrgencyCounterByDay[] = [];
        for (let currentDate: DateTime = since; currentDate <= until; currentDate = currentDate.plus({ days: 1 }))
        {
            const counterByDay: RequestUrgencyCounterByDay = evolutionSummary.find((es: RequestUrgencyCounterByDay): boolean =>
                es.dateUtc.toISODate() === currentDate.toISODate()
            );

            fullEvolution.push(counterByDay ?? { dateUtc: currentDate, totalCreated: 0, totalClosed: 0 } as RequestUrgencyCounterByDay);
        }

        return fullEvolution;
    }

    private sortEvolutionSummary = (collection: RequestUrgencyCounterByDay[]): RequestUrgencyCounterByDay[] =>
        collection.sort((resa: RequestUrgencyCounterByDay, resb: RequestUrgencyCounterByDay): 1 | 0 | -1 =>
        {
            if (resa.dateUtc < resb.dateUtc)
            {
                return -1;
            }
            else if (resa.dateUtc > resb.dateUtc)
            {
                return 1;
            }

            return 0;
        });
}
