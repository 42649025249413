import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';

import { CatalogMiscDomainService } from '@apps/its2/domain';
import * as fromIts2CatalogsActions from '@apps/its2/store/catalogs/catalogs.actions';
import { CatalogEntry } from '@apps/its2/models';
import { CatalogEntryQuestion } from '@apps/its2/domain/models';
import { getIts2CatalogsIncidentIsDataValid, getIts2CatalogsServiceIsDataValid } from '@apps/its2/store/its2.reducer';
import { IAuroraState } from '@apps/aurora.state';

@Injectable()
export class Its2CatalogsEffects
{

    constructor (
        private store: Store<IAuroraState>,
        private actions: Actions,
        private catalogMiscDomainService: CatalogMiscDomainService
    )
    {
    }

    its2RequestsIncidentCatalogsRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2CatalogsActions.Its2CatalogsIncidentRequested),
                withLatestFrom(
                    this.store.select(getIts2CatalogsIncidentIsDataValid),
                    (_, isDataValid) => ({
                        isDataValid
                    })
                ),
                filter(({ isDataValid }: { isDataValid: boolean; }) =>
                    !isDataValid
                ),
                switchMap(() =>
                    this.catalogMiscDomainService.getCatalogsIncident()
                        .pipe(
                            map((catalogEntries: CatalogEntry[]) =>
                                fromIts2CatalogsActions.Its2CatalogsIncidentSucceeded({ catalogEntries })
                            ),
                            catchError((error: Error) =>
                                of(fromIts2CatalogsActions.Its2CatalogsIncidentFailed({ error: error }))
                            )
                        )
                )
            )
    );

    its2RequestsServiceCatalogsRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2CatalogsActions.Its2CatalogsServiceRequested),
                withLatestFrom(
                    this.store.select(getIts2CatalogsServiceIsDataValid),
                    (_, isDataValid) => ({
                        isDataValid
                    })
                ),
                filter(({ isDataValid }: { isDataValid: boolean; }) =>
                    !isDataValid
                ),
                switchMap(() =>
                    this.catalogMiscDomainService.getCatalogsService()
                        .pipe(
                            map((catalogEntries: CatalogEntry[]) =>
                                fromIts2CatalogsActions.Its2CatalogsServiceSucceeded({ catalogEntries })
                            ),
                            catchError((error: Error) =>
                                of(fromIts2CatalogsActions.Its2CatalogsServiceFailed({ error: error }))
                            )
                        )
                )
            )
    );

    its2CatalogsServiceQuestionsRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2CatalogsActions.Its2CatalogsServiceQuestionsRequested),
                switchMap((action: any) =>
                    this.catalogMiscDomainService.getQuestions(action.catalogGuid)
                        .pipe(
                            map((questions: CatalogEntryQuestion[]) =>
                                fromIts2CatalogsActions.Its2CatalogsServiceQuestionsSucceeded({ catalogGuid: action.catalogGuid, questions })
                            ),
                            catchError((error: Error) =>
                                of(fromIts2CatalogsActions.Its2CatalogsServiceQuestionsFailed({ error }))
                            )
                        )
                )
            )
    );
}
