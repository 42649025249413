import { createAction, props } from '@ngrx/store';
import { DateTime } from 'luxon';

import { CountServers, LastSession, StatusPerRange, TotalCapacity, Variations } from '../../../dashboard/models';
import { RequestsSummaryCounter } from '@common/models';

export const LoadBackupVboDashboardStatusPerRangeRequested = createAction(
    '[BCKP-DASH] Load Backup Vbo Dashboard Status Per Range Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupVboDashboardStatusPerRangeSucceeded = createAction(
    '[BCKP-DASH] Load Backup Vbo Dashboard Status Per Range Succeeded',
    props<{ statusPerRange: StatusPerRange }>()
);

export const LoadBackupVboDashboardLastSessionRequested = createAction(
    '[BCKP-DASH] Load Backup Vbo Dashboard Last Session Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupVboDashboardCountServersSucceeded = createAction(
    '[BCKP-DASH] Load Backup Vbo Dashboard Count Servers Succeeded',
    props<{ countServers: CountServers }>()
);

export const LoadBackupVboDashboardLastSessionSucceeded = createAction(
    '[BCKP-DASH] Load Backup Vbo Dashboard Last Session Succeeded',
    props<{ lastSession: LastSession }>()
);

export const LoadBackupVboDashboardLastSessionFailed = createAction(
    '[BCKP-DASH] Load Backup Vbo Dashboard Last Session Failed'
);

export const LoadBackupVboDashboardTotalCapacityRequested = createAction(
    '[BCKP-DASH] Load Backup Vbo Dashboard Total Capacity Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupVboDashboardTotalCapacitySucceeded = createAction(
    '[BCKP-DASH] Load Backup Vbo Dashboard Total Capacity Succeeded',
    props<{ totalCapacity: TotalCapacity }>()
);

export const LoadBackupVboDashboardTicketingRequested = createAction(
    '[BCKP-DASH] Load Backup Vbo Dashboard Ticketing Requested'
);

export const LoadBackupVboDashboardTicketingSucceeded = createAction(
    '[BCKP-DASH] Load Backup Vbo Dashboard Ticketing Succeeded',
    props<{ ticketing: RequestsSummaryCounter }>()
);

export const LoadBackupVboDashboardVariationsRequested = createAction(
    '[BCKP-DASH] Load Backup Vbo Dashboard Variations Requested',
    props<{ since: DateTime, until: DateTime }>()
);

export const LoadBackupVboDashboardVariationsSucceeded = createAction(
    '[BCKP-DASH] Load Backup Vbo Dashboard Variations Succeeded',
    props<{ variations: Variations }>()
);