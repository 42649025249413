import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { FloatingIpApi } from '@apps/ostk/api/models';
import { FloatingIpStatus } from '@apps/ostk/ostk.constants';
import { arrayMapper } from '@common/operators';

import { FloatingIpMiscApiService } from '../../api';
import { FloatingIp } from '../models';

@Injectable({
    providedIn: 'root'
})
export class FloatingIpMiscDomainService
{
    constructor (private floatingipMiscApiService: FloatingIpMiscApiService)
    {
    }

    public getFloatingIps (): Observable<FloatingIp[]>
    {
        return this.floatingipMiscApiService.getFloatingIps()
            .pipe(
                arrayMapper(FloatingIp.mapperFromApi)
            );
    }

    public map (id: string, serverId: string): Observable<FloatingIp>
    {
        return this.floatingipMiscApiService.map(id, serverId)
            .pipe(
                tap((fip: FloatingIpApi) =>
                    fip.status = FloatingIpStatus.ACTIVE
                ),
                map(FloatingIp.mapperFromApi)
            );
    }

    public unmap (id: string): Observable<FloatingIp>
    {
        return this.floatingipMiscApiService.unmap(id)
            .pipe(
                tap((fip: FloatingIpApi) =>
                    fip.status = FloatingIpStatus.DOWN
                ),
                map(FloatingIp.mapperFromApi)
            );
    }

    public deleteFloatingIp (id: string): Observable<string>
    {
        return this.floatingipMiscApiService.deleteFloatingIp(id);
    }

    public allocate (poolId: string): Observable<FloatingIp>
    {
        return this.floatingipMiscApiService.allocate(poolId)
            .pipe(
                map(floatingIp => floatingIp ?? { status: FloatingIpStatus.DOWN, floatingIpAddress: '' }),
                map(FloatingIp.mapperFromApi)
            );
    }
}
