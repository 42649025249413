import { createFeatureSelector } from '@ngrx/store';

import { IAuroraState } from '@apps/aurora.state';
import * as fromRequestsReducer from './requests/requests.reducer';
import * as fromActionsReducer from './actions/actions.reducer';
import * as fromSelectedReducer from './selected/selected.reducer';
import * as fromDocumentsReducer from './documents/documents.reducer';
import * as fromValuesReducer from './values/values.reducer';
import * as fromMiscReducer from './misc/misc.reducer';
import * as fromCatalogsReducer from './catalogs/catalogs.reducer';
import { IIts2DashboardState, Its2DashboardFeatureKey } from './dashboard/dashboard.state';
import { Its2FeatureKey } from '../its2.constants';

export interface IState extends IAuroraState
{
    readonly [Its2FeatureKey]: IIts2State;
}

export interface IIts2State
{
    readonly [fromRequestsReducer.Its2RequestsFeatureKey]: fromRequestsReducer.IIts2RequestsState;
    readonly [fromActionsReducer.Its2ActionsFeatureKey]: fromActionsReducer.IIts2ActionsState;
    readonly [fromSelectedReducer.Its2SelectedFeatureKey]: fromSelectedReducer.IIts2SelectedState;
    readonly [fromDocumentsReducer.Its2DocumentsFeatureKey]: fromDocumentsReducer.IIts2DocumentsState;
    readonly [fromValuesReducer.Its2ValuesFeatureKey]: fromValuesReducer.IIts2ValuesState;
    readonly [fromMiscReducer.Its2MiscFeatureKey]: fromMiscReducer.IIts2MiscState;
    readonly [fromCatalogsReducer.Its2CatalogsFeatureKey]: fromCatalogsReducer.IIts2CatalogsState;
    readonly [Its2DashboardFeatureKey]: IIts2DashboardState;
}

export const getIts2State = createFeatureSelector<IIts2State>(
    Its2FeatureKey
);
