import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ValidationResult } from './types';

export const CustomErrorKey: string = 'customMsg';

@Injectable({
    providedIn: 'root'
})
export class ErrorMsgService
{
    constructor (private translate: TranslateService)
    { }

    public getStandardMessage = (validationResult: ValidationResult, key: string): string =>
        this.translate.instant(`FORMS.MESSAGE.${key.toUpperCase()}`, validationResult[key]);

    public getCustomMessage = (validationResult: ValidationResult): string =>
    {
        const key: string = validationResult[CustomErrorKey].toString().toUpperCase();

        return this.translate.instant(key, validationResult[CustomErrorKey][key]);
    };
}
