import { createAction, props } from '@ngrx/store';

import { Project, Reference } from '../../domain/models/billing';

export const OstkBillingRequested = createAction(
    '[OSTK-BILLING] Ostk Billing Requested'
);

export const OstkBillingSucceeded = createAction(
    '[OSTK-BILLING] Ostk Billing Succeeded',
    props<{ references: Reference[], project: Project }>()
);

export const OstkBillingFailed = createAction(
    '[OSTK-BILLING] Ostk Billing Failed',
    props<{ err?: any }>()
);
