import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { filter, tap, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';

import { IAuroraState } from '@apps/aurora.state';
import * as fromAuthStore from '@apps/auth/store';
import { Profile } from '@apps/auth/models/profile';

@Component({
    selector: 'aa-profile-step1',
    templateUrl: './profile-step1.component.html',
    styleUrls: [
        '../profile.component.scss',
        './profile-step1.component.scss'
    ]
})
export class ProfileStep1Component implements OnInit, OnDestroy
{
    private destroyator = new Subject();
    public form: UntypedFormGroup;
    public profile: Profile;

    constructor (
        private formBuilder: UntypedFormBuilder,
        private store: Store<IAuroraState>
    )
    {
    }

    ngOnInit ()
    {
        this.store
            .pipe(
                select(fromAuthStore.getProfile),
                filter((p: Profile) => p !== null),
                tap((p: Profile) =>
                {
                    this.profile = p;
                    this.createForm();
                }),
                takeUntil(this.destroyator)
            )
            .subscribe();
    }

    ngOnDestroy (): void
    {
        this.destroyator.next(null);
        this.destroyator.complete();
    }

    onSubmit = () =>
    {
        if (this.form.invalid)
        {
            for (const control in this.form.controls)
            {
                this.form.controls[control].markAsDirty();
            }

            return;
        }

        const profile: Profile = {
            firstName: this.form.get('firstName').value,
            lastName: this.form.get('lastName').value,
            title: this.form.get('title').value
        };
        this.store.dispatch(fromAuthStore.AuthProfileStoreActions.AuthProfileStep1Changed({ profile }));
    };

    // onContinue = () =>
    // {
    //     this.store.dispatch(fromAuthStore.AuthProfileStoreActions.AuthProfileStep1Continued());
    // }

    onCancel = () =>
    {
        this.store.dispatch(fromAuthStore.AuthProfileStoreActions.AuthProfileStepCanceled());
    };

    private createForm ()
    {
        this.form = this.formBuilder.group({
            firstName: [this.profile.firstName, Validators.required],
            lastName: [this.profile.lastName, Validators.required],
            title: [this.profile.title]
        });
    }
}
