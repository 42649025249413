import { Info } from 'luxon';

import { SelectOption } from '@ui/components/form/common';
import { TypePopin } from '@apps/app.constants';

import { Report } from './domain/models';
import { SelectOption9 } from '@ui/components/form9/common';

export enum Periodicity
    {
    HOURLY = 0,
    DAILY = 1,
    WEEKLY = 2,
    MONTHLY = 3
}
export enum IntervalType
    {
    PREDEF = 0,
    CUSTOM = 1
}

export enum TemplateVersion
    {
    V1 = 1,
    V2 = 2
}

export class ReptConstants
{
    private static periodicities: string[] = [];
    // Durée de vie en minutes des éléments dans le store
    public static DataLifeTime: number = 7 * 24 * 60;
    public static Application: string = 'ReptApi';
    public static BaseApi: string = 'rept';
    public static Rept = 'report';
    public static Reports = 'reports';
    public static DefaultReptUrl: string = `/${ReptConstants.Rept}/${ReptConstants.Reports}`;
    public static Details = 'details';
    public static Edit = 'edit';
    public static Create = 'create';
    public static NumbersFormat: SelectOption[] = [
        {
            label: 'fr-FR',
            value: 'fr-FR',
            selected: false
        },
        {
            label: 'fr-BE',
            value: 'fr-BE',
            selected: false
        },
        {
            label: 'en-US',
            value: 'en-US',
            selected: false
        },
        {
            label: 'en-GB',
            value: 'en-GB',
            selected: false
        },
    ];
    public static MaxSelectedHours = 6;
    public static HoursSelectOption: SelectOption9[] = Array.from(Array(24))
        .map((_: any, i: number): SelectOption9 =>
            new SelectOption9(i, `${i.toString()}h`)
        );
    public static WeekdaysSelectOption: SelectOption9[] = Array.from(Array(7))
        .map((_: any, i: number): SelectOption9 =>
            new SelectOption9(((i + 1) % 7), 'DAYSOFWEEK.' + Info.weekdays('short', { locale: 'en-US' })[i].toUpperCase())
        );
    public static MonthdaysSelectOption: SelectOption9[] = Array.from(Array(28))
        .map((_: any, i: number): SelectOption9 =>
            new SelectOption9(i + 1, (i + 1).toString())
        );

    public static ReptEditReportRoute = (reportId: string) => [
        ReptConstants.Rept,
        ReptConstants.Reports,
        { outlets: { [TypePopin.ACTION]: [reportId, ReptConstants.Edit] } }
    ];
    public static ReptDetailsReportRoute = (reportId: string) => [
        ReptConstants.Rept,
        ReptConstants.Reports,
        { outlets: { [TypePopin.DETAILS]: [reportId] } }
    ];
    public static reportsQSFields: Array<keyof Report> = [ 'name', 'templateName',
        'detailedPeriodicity', 'applicationName' ];
    public static GetPeriodicities (p: number): string
    {
        if (ReptConstants.periodicities.length === 0)
        {
            Object.keys(Periodicity)
                .map((key: string) =>
                    ({ id: parseInt(Periodicity[key], 10), name: key })
                )
                .filter((result: { id: number, name: string }) =>
                    !isNaN(result.id)
                )
                .forEach((result: { id: number, name: string }) =>
                    ReptConstants.periodicities[result.id] = result.name.toUpperCase()
                );
        }

        return ReptConstants.periodicities[p];
    }

    // public static GetIntervalTypes (p: number): string
    // {
    //     if (ReptConstants.intervalTypes.length === 0)
    //     {
    //         Object.keys(Periodicity)
    //             .map(key =>
    //                 ({ id: parseInt(Periodicity[key], 10), name: key })
    //             )
    //             .filter((result: { id: number, name: string }) =>
    //                 !isNaN(result.id)
    //             )
    //             .forEach((result: { id: number, name: string }) =>
    //                 ReptConstants.periodicities[result.id] = result.name.toUpperCase()
    //             );
    //     }

    //     return ReptConstants.periodicities[p];
    // }

    public static getCssStatus = (status: boolean) =>
    {
        return status ? 'active' : 'inactive';
    };

    public static getCssContractual = (contractual: boolean) =>
    {
        return `contractual-${contractual ? 'yes' : 'no'}`;
    };

    public static getCssVisibility = (visibility: boolean) =>
    {
        return `visibility-${visibility ? 'yes' : 'no'}`;
    };
}
