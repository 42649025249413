import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { StoreCustomFile } from '@common/models/custom-file';
import { Summary } from '@apps/its2/models/create-service/summary';
import { Step1, Step2, Step3, Step4 } from '@apps/its2/models/create-service';
import { FieldOptions } from '@common/models';
import { RequestValidationParameters } from '@apps/its2/models';

export const Its2RequestsServiceCreateStep1Entered = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Requests Service Create Step1 Entered',
    props<{ currentLang: string }>()
);
export const Its2RequestsServiceCreateStep1Exited = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Requests Service Create Step1 Exited',
    props<{ step1: Step1 }>()
);

export const Its2RequestsServiceCreateStep2Entered = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Requests Service Create Step2 Entered'
);
export const Its2RequestsServiceCreateStep2Exited = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Requests Service Create Step2 Exited',
    props<{ step2: Step2 }>()
);

export const Its2RequestsServiceCreateStep3Entered = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Requests Service Create Step3 Entered'
);
export const Its2RequestsServiceCreateStep3Exited = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Requests Service Create Step3 Exited',
    props<{ step3: Step3 }>()
);

export const Its2RequestsServiceCreateStep4Entered = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Requests Service Create Step4 Entered'
);
export const Its2RequestsServiceCreateStep4Exited = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Requests Service Create Step4 Exited',
    props<{ step4: Step4 }>()
);

export const Its2RequestsServiceCreateStep5Entered = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Requests Service Create Step5 Entered'
);
export const Its2RequestsServiceCreateStep5Exited = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Requests Service Create Step5 Exited',
    props<{ step5: Summary }>()
);

export const Its2RequestsServiceCreateSetSkipped = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Requests Service Create Set Skipped',
    props<{idx: number, isSkipped: boolean}>()
);
export const Its2RequestsServiceCreatePopinCloseRequested = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Requests Service Create Popin Close Requested'
);

export const Its2RequestsReplyRequested = createAction(
    '[ITS2-REQUEST] Its2 Requests Reply Requested',
    props<{ rfcNumber: string, description: string }>()
);

export const Its2RequestsReplySucceeded = createAction(
    '[ITS2-REQUEST] Its2 Requests Reply Executed',
    props<{ rfcNumber: string }>()
);

export const Its2RequestsReplyFailed = createAction(
    '[ITS2-REQUEST] Its2 Requests Reply Failed',
    props<{ rfcNumber: string }>()
);

export const Its2RequestsFileAdded = createAction(
    '[ITS2-REQUEST-DETAILS] Its2 Requests File Added',
    props<{ file: StoreCustomFile }>()
);
export const Its2RequestsFileRemoved = createAction(
    '[ITS2-REQUEST-DETAILS] Its2 Requests File Removed',
    props<{ id: string }>()
);
export const Its2RequestsFileClearRequested = createAction(
    '[ITS2-REQUEST-DETAILS] Its2 Requests File Clear Requested'
);

export const Its2RequestsFileStatusChanged = createAction(
    '[ITS2-REQUEST-DETAILS] Its2 Requests File Status Changed',
    props<{ file: Update<StoreCustomFile> }>()
);

export const Its2CreateServiceLoadOptionsRequested = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Create Service Load Options Requested',
    props<{ endPoint: string, fieldName: string }>()
);

export const Its2CreateServiceLoadOptionsSucceeded = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Create Service Load Options Succeeded',
    props<{ fieldOptions: FieldOptions }>()
);

export const Its2CreateServiceLoadOptionsFailed = createAction(
    '[ITS2-CREATE-SERVICE] Its2 Create Service Load Options Failed'
);

export const Its2RequestsValidateRequested = createAction(
    '[ITS2-REQUEST-DETAILS] Its2 Requests Validate Requested',
    props<{ rfcNumber: string; oldStatusId: number; rvp: RequestValidationParameters; }>()
);

export const Its2RequestsValidateSucceeded = createAction(
    '[ITS2-REQUEST-DETAILS] Its2 Requests Validate Succeeded',
    props<{ rfcNumber: string; oldStatusId: number; }>()
);

export const Its2RequestsValidateFailed = createAction(
    '[ITS2-REQUEST-DETAILS] Its2 Requests Validate Failed'
);

export const Its2RequestsPollingStatusRequested = createAction(
    '[ITS2-REQUEST-DETAILS] Its2 Requests Polling Status Requested',
    props<{ rfcNumber: string; oldStatusId: number; }>()
);

export const Its2RequestsPollingStatusSucceeded = createAction(
    '[ITS2-REQUEST-DETAILS] Its2 Requests Polling Status Succeeded',
    props<{ rfcNumber: string; }>()
);

export const Its2RequestsPollingStatusFailed = createAction(
    '[ITS2-REQUEST-DETAILS] Its2 Requests Polling Status Failed',
    props<{ rfcNumber: string; }>()
);
