import { DateTime } from 'luxon';

import { DatesService } from '@common/services';

export class PotoAssetsFilter
{
    protected _isEverProtect?: boolean;
    protected _rangeSince: DateTime;
    protected _rangeUntil: DateTime;
}
/**
 * Cette classe gère l'état du filtre utilisé par la queryString vers le backend
 */
export class AssetsFilter extends PotoAssetsFilter
{
    get isEverProtect ()
    {
        return this._isEverProtect;
    }

    set isEverProtect (value: boolean | null)
    {
        this._isEverProtect = value;
    }
    protected _since: string;
    get since ()
    {
        return this._since;
    }
    protected _until: string;
    get until ()
    {
        return this._until;
    }
    get rangeSince (): DateTime
    {
        return this._rangeSince;
    }
    set rangeSince (value: DateTime)
    {
        this._rangeSince = value;
        this._since = DatesService.DateToQueryStringStatic(this._rangeSince);
    }
    get rangeUntil (): DateTime
    {
        return this._rangeUntil;
    }
    set rangeUntil (value: DateTime)
    {
        this._rangeUntil = value;
        this._until = DatesService.DateToQueryStringStatic(this._rangeUntil);
    }

    constructor ()
    {
        super();

        this.initFilter();
    }

    private initFilter ()
    {
        this.isEverProtect = true;
    }
}