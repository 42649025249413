import { LocalizedText } from '@common/models';
import { CmdbRelatedAssetApi } from '../api/models';
import { CmdbConstants } from '../cmdb.constants';

export class CmdbRelatedAsset
{
    public assetId: string;
    public myAntemetAGuid: string;
    public statusId: number;
    public statusFR: string;
    public statusEN: string;
    public name: string;
    public categoryId: string;
    public categoryFR: string;
    public categoryEN: string;
    public blocking?: boolean;
    public category: LocalizedText;
    public status: LocalizedText;
    public statusClass: string;
    public os: string;

    public static mapperFromApi (source: CmdbRelatedAssetApi): CmdbRelatedAsset
    {
        const target = new CmdbRelatedAsset();

        Object.assign(target, source);

        target.category = new LocalizedText(
            source.categoryEN,
            source.categoryFR
        );
        target.status = new LocalizedText(
            source.statusEN,
            source.statusFR
        );
        target.statusClass = CmdbConstants.getCssActiveStatus(source.statusEN);

        return target;
    }
}