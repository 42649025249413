import { Action, combineReducers } from '@ngrx/store';

import { IAuroraState } from '@apps/aurora.state';
import * as fromImagesImgeReducer from './imge/imge.reducer';
import * as fromImagesSnapReducer from './snap/snap.reducer';

export * from './imge/imge.reducer';
export * from './snap/snap.reducer';
export const OstkImagesFeatureKey = 'images';

export interface IState extends IAuroraState
{
    [OstkImagesFeatureKey]: IOstkImagesState;
}

export function OstkImagesReducers (state: IOstkImagesState | undefined, action: Action)
{
    return combineReducers({
        [fromImagesImgeReducer.OstkImagesImgeFeatureKey]: fromImagesImgeReducer.OstkImagesImgeReducer,
        [fromImagesSnapReducer.OstkImagesSnapFeatureKey]: fromImagesSnapReducer.OstkImagesSnapReducer
    })(state, action);
}

export interface IOstkImagesState
{
    [fromImagesImgeReducer.OstkImagesImgeFeatureKey]: fromImagesImgeReducer.IOstkImagesImgeState;
    [fromImagesSnapReducer.OstkImagesSnapFeatureKey]: fromImagesSnapReducer.IOstkImagesSnapState;
}
