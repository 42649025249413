import { PlatformApi } from '../../../api/models';

export class Platform
{
    platformId: string;
    platformName: string;
    platformType: string;
    platformUri: string;
    platformDomain: string;
    platformSegment: string;

    public static mapperFromApi (source: PlatformApi): Platform
    {
        const target = new Platform();

        target.platformId = source.id;
        target.platformName = source.name;
        target.platformType = source.type;
        target.platformUri = source.uri;
        target.platformDomain = source.domain;
        target.platformSegment = source.name;

        return target;
    }
}
