import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TrackerEventDirective } from './directives/tracker.directive';
import { LayoutModule } from '@layouts/layout.module';
import { UIModule } from '@ui/ui.module';
import { PipesModule } from '../_pipes/pipes.module';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { ChartingModule } from '../_charting/charting.module';
import { ClickOutsideRowDetailsDirective } from './directives/click-outside-row-details.directive';

export function HttpLoaderFactory (http: HttpClient)
{
    return new TranslateHttpLoader(http);
}

export function createTranslateLoader (http: HttpClient)
{
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        TrackerEventDirective,
        AutofocusDirective,
        ClickOutsideDirective,
        ClickOutsideRowDetailsDirective,
        CopyClipboardDirective
    ],
    imports: [
        CommonModule,
        LayoutModule,
        UIModule,
        PipesModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        // TranslateModule.forRoot({
        //     loader: {
        //         provide: TranslateLoader,
        //         useFactory: HttpLoaderFactory,
        //         deps: [
        //             HttpClient
        //         ]
        //     }
        // }),
    ],
    exports: [
        TrackerEventDirective,
        AutofocusDirective,
        CommonModule,
        LayoutModule,
        UIModule,
        PipesModule,
        ChartingModule,
        TranslateModule,
        TrackerEventDirective,
        ClickOutsideDirective,
        ClickOutsideRowDetailsDirective,
        CopyClipboardDirective,
        TranslatePipe
    ]
})
export class SharedModule
{
}
