import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, catchError, tap, map } from 'rxjs/operators';
import { of } from 'rxjs';

import { CmdbAssetListDomainService } from '@apps/cmdb/domain';
import { ResultDomain } from '@common/domain/result-domain';
import { NotfGravity, NotificationService, NotifType } from '@common/services';
import { CmdbAssetMiscDomainService } from '@apps/cmdb/domain';

import * as fromCmdbAssetsActions from './assets.actions';
import { CmdbAsset, CmdbAssetDetails } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class CmdbAssetsEffects
{
    constructor (
        private actions: Actions,
        private cmdbAssetListDomainService: CmdbAssetListDomainService,
        private cmdbAssetMiscDomainService: CmdbAssetMiscDomainService,
        private notificationService: NotificationService
    )
    {}

    cmdbAssetsODataFilterRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromCmdbAssetsActions.CmdbAssetsODataFilterRequested),
                    switchMap((params: any) =>
                        this.cmdbAssetListDomainService.getAssets(params.queryString)
                            .pipe(
                                map((result: ResultDomain<CmdbAsset>) =>
                                    fromCmdbAssetsActions.CmdbAssetsODataFilterSucceeded({ result })
                                ),
                                catchError((error: Error) =>
                                    of(fromCmdbAssetsActions.CmdbAssetsODataFilterFailed({ error }))
                                )
                            )
                    )
                )
    );

    cmdbAssetsODataFilterFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromCmdbAssetsActions.CmdbAssetsODataFilterFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'CMDB.ASSETS.LIST.ODATA.FILTER.TITLE',
                                'CMDB.ASSETS.LIST.ODATA.FILTER.FAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    cmdbAssetsODataSkipRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromCmdbAssetsActions.CmdbAssetsODataSkipRequested),
                    switchMap((params: any) =>
                        this.cmdbAssetListDomainService.getAssets(params.queryString)
                            .pipe(
                                map((result: ResultDomain<CmdbAsset>) =>
                                    fromCmdbAssetsActions.CmdbAssetsODataSkipSucceeded({ result })
                                ),
                                catchError((error: Error) =>
                                    of(fromCmdbAssetsActions.CmdbAssetsODataSkipFailed({ error }))
                                )
                            )
                    )
                )
    );

    cmdbAssetsODataSkipFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromCmdbAssetsActions.CmdbAssetsODataSkipFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                'CMDB.ASSETS.LIST.ODATA.SKIP.TITLE',
                                'CMDB.ASSETS.LIST.ODATA.SKIP.FAILED'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    cmdbAssetDetailedRequested = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromCmdbAssetsActions.CmdbAssetDetailedRequested),
                    switchMap((params) =>
                        this.cmdbAssetMiscDomainService.getAssetDetails(params.categoryGuid, params.assetId).pipe(
                            switchMap((assetDetails: CmdbAssetDetails) => [
                                fromCmdbAssetsActions.CmdbAssetDetailedSucceeded({ assetDetails })
                            ]),
                            catchError(() =>
                                of(fromCmdbAssetsActions.CmdbAssetDetailedFailed())
                            )
                        )
                    )
                )
    );
}
