import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'telto'
})
export class TeltoPipe implements PipeTransform
{
    transform (value: string): string
    {
        return `tel:${value}`;
    }
}
