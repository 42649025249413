import { Component, OnInit, OnDestroy } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { tap, filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { IAuroraState } from '../_apps/aurora.state';
import { getWelcomeInfos, getWelcomeInfos2 } from '../_apps/auth/store';

@Component({
    selector: 'aa-auth',
    templateUrl: './auth.component.html',
    styleUrls: [
        './auth.component.scss'
    ]
})
export class AuthComponent implements OnInit, OnDestroy
{
    private destroyator = new Subject();
    public welcomeMsg: Observable<string>;

    constructor(
        private store: Store<IAuroraState>,
        private translate: TranslateService
    ) { }

    ngOnInit ()
    {
        this.welcomeMsg = this.translate.get('AUTH.HOME.WELCOME1');

        this.store
            .pipe(
                select(getWelcomeInfos),
                filter((infos: any) => infos !== null),
                tap((infos: { email: string, firstName: string, lastName: string }) =>
                {
                    this.buildWelcomeMessage(infos.firstName, infos.lastName);
                }),
                takeUntil(this.destroyator)
            )
            .subscribe();

        this.store
            .pipe(
                select(getWelcomeInfos2),
                filter((infos: any) => infos !== null),
                tap((infos: { email: string, firstName: string, lastName: string }) =>
                {
                    this.buildWelcomeMessage(infos.firstName, infos.lastName);
                }),
                takeUntil(this.destroyator)
            )
            .subscribe();
    }

    ngOnDestroy (): void
    {
        this.destroyator.next(null);
        this.destroyator.complete();
    }

    private buildWelcomeMessage (firstName: string, lastName: string)
    {
        if (firstName || lastName)
        {
            this.welcomeMsg = this.translate.get('AUTH.HOME.WELCOME', { firstLastName: `${firstName} ${lastName}` });
        }
    }
}
