import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { NotfGravity } from '@common/services';
import { Failure } from '@common/models';

import { SnackbarComponent } from './snackbar.component';
import { SnackbarRef } from './snackbar.ref';


@Injectable({
    providedIn: 'root'
})
export class SnackbarService
{
    public overlayRef: OverlayRef = null;
    private injector: Injector;

    constructor (
        private overlay: Overlay
    )
    {
    }

    ngOnDestroy (): void
    {
        if (this.injector)
        {
            this.overlayRef.dispose();
        }
    }

    create (failure: Failure, gravity?: NotfGravity): SnackbarRef
    {
        this.overlayRef = this.overlay.create(this.getOverlayConfig());
        const snackbarRef = new SnackbarRef(this.overlayRef, failure, gravity);
        this.injector = Injector.create({
            providers: [
                {
                    provide: SnackbarRef,
                    useFactory: (): SnackbarRef =>
                        snackbarRef
                }
            ]
        });
        this.overlayRef.attach(new ComponentPortal(SnackbarComponent, null, this.injector));

        return snackbarRef;
    }

    private getOverlayConfig (): OverlayConfig
    {
        return new OverlayConfig({
            // width: '344px',
            // height: '48px',
            // minWidth: '344px',
            // minHeight: '48px',
            hasBackdrop: false,
            positionStrategy: this.getOverlayPosition()
        });
    }

    private getOverlayPosition (): PositionStrategy
    {
        return this.overlay
            .position()
            .global()
            .bottom('25px')
            .centerHorizontally();
    }
}
