import { TemplateRef } from '@angular/core';

export class TreeModel
{
    private _templates: TemplateRef<any>[] = [];
    set templates (value: TemplateRef<any>[])
    {
        this._templates = [null, ...value];
    }
    get templates (): TemplateRef<any>[]
    {
        return this._templates;
    }

    constructor (
        public cid: string,
        public lid: string,
        public cnid: string,
        public items: any[] = null,
        public className: any = null,
        public showTitle: boolean = true,
        public treeTitle: string = '',
        public treeNodeId: string = '',
        public iconExpand: string = 'fa-plus-square',
        public iconCollapse: string = 'fa-minus-square',
        public iconLeaf: string = 'fa-square-o'
    )
    { }
}
