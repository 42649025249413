import { HttpEvent } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { DateTime } from 'luxon';

import { ResultDomain } from '@common/domain/result-domain';
import { MediaTypes } from '@common/services';
import { RequestsFilter } from '@apps/its2/services/requests-filter';
import { CreateRequestViewModel } from '@apps/its2/models/create-request-view-model';
import { Request, RequestCounters } from '@apps/its2/domain/models';
import { CreateService } from '@apps/its2/models/create-service';

// #region Requests
export const Its2RequestsODataFilterRequested = createAction(
    '[ITS2-REQUESTS] Its2 Requests OData Filter Requested',
    props<{ queryString: string; }>()
);
export const Its2RequestsODataFilterSucceeded = createAction(
    '[ITS2-REQUESTS] Its2 Requests OData Filter Succeeded',
    props<{ result: ResultDomain<Request>; }>()
);
export const Its2RequestsODataFilterFailed = createAction(
    '[ITS2-REQUESTS] Its2 Requests OData Filter Failed',
    props<{ error: any; }>()
);
export const Its2RequestsODataSkipRequested = createAction(
    '[ITS2-REQUESTS] Its2 Requests OData Skip Requested',
    props<{ queryString: string; }>()
);
export const Its2RequestsODataSkipSucceeded = createAction(
    '[ITS2-REQUESTS] Its2 Requests OData Skip Succeeded',
    props<{ result: ResultDomain<Request>; }>()
);
export const Its2RequestsODataSkipFailed = createAction(
    '[ITS2-REQUESTS] Its2 Requests OData Skip Failed',
    props<{ error: any; }>()
);

export const Its2RequestsCountersRequested = createAction(
    '[ITS2-REQUESTS] Its2 Requests Counters Requested'
);

export const Its2RequestsCountersSucceeded = createAction(
    '[ITS2-REQUESTS] Its2 Requests Counters Succeeded',
    props<{ requestCounters: RequestCounters; }>()
);

export const Its2RequestsCountersFailed = createAction(
    '[ITS2-REQUESTS] Its2 Requests Counters Failed',
    props<{ error: any; }>()
);

export const Its2RequestsCreateCaseRequested = createAction(
    '[ITS2-REQUESTS] Its2 Requests Create Case Requested',
    props<{ createCase: CreateRequestViewModel; }>()
);
export const Its2RequestsCreateCaseSucceeded = createAction(
    '[ITS2-REQUESTS] Its2 Requests Create Case Succeeded',
    props<{ request: Request; }>()
);
export const Its2RequestsCreateCaseFailed = createAction(
    '[ITS2-REQUESTS] Its2 Requests Create Case Failed',
    props<{ error: Error; }>()
);

export const Its2RequestsCreateServiceRequested = createAction(
    '[ITS2-REQUESTS] Its2 Requests Create Service Requested',
    props<{ createService: CreateService; }>()
);
export const Its2RequestsCreateServiceCreated = createAction(
    '[ITS2-REQUESTS] Its2 Requests Create Service Created',
    props<{ request: Request; createService: CreateService; }>()
);
export const Its2RequestsCreateServiceSucceeded = createAction(
    '[ITS2-REQUESTS] Its2 Requests Create Service Succeeded',
    props<{ request: Request; }>()
);
// export const Its2RequestsCreateServiceCompleted = createAction(
//     '[ITS2-REQUESTS] Its2 Requests Create Service Completed',
//     props<{ request: Request; }>()
// );
export const Its2RequestsCreateServiceFailed = createAction(
    '[ITS2-REQUESTS] Its2 Requests Create Service Failed',
    props<{ error: Error; }>()
);
export const Its2RequestsQsWordSet = createAction(
    '[ITS2-REQUESTS] Its2 Requests QsWord Set',
    props<{ qsWord: string }>()
);
// #endregion

export const Its2RequestsUpdateMany = createAction(
    '[ITS2-REQUESTS] Its2 Requests Update Many',
    props<{ requests: Update<Request>[]; }>()
);

export const Its2RequestsUpdateOne = createAction(
    '[ITS2-REQUESTS] Its2 Requests Update One',
    props<{ request: Update<Request>; }>()
);

// #region Selected/Actions/Documents
export const Its2RequestsSelectedRequested = createAction(
    '[ITS2-REQUEST] Its2 Requests Selected Requested',
    props<{ rfcNumber: string; }>()
);

export const Its2RequestsSelectedSucceeded = createAction(
    '[ITS2-REQUESTS] Its2 Requests Selected Succeeded',
    props<{ selected: Request; }>()
);

export const Its2RequestsSelectedFailed = createAction(
    '[ITS2-REQUESTS] Its2 Requests Selected Failed',
    props<{ error: any; }>()
);
export const Its2RequestsSelectedUnset = createAction(
    '[ITS2-REQUEST] Its2 Requests Selected Unset'
);
// #endregion

// // #region Filter
// export const Its2RequestsQueryStringChanged = createAction(
//     '[ITS2-REQUESTS] Its2 Requests QueryString Changed',
//     props<{ queryString: string; }>()
// );
export const Its2RequestsFilterChanged = createAction(
    '[ITS2-REQUESTS] Its2 Requests Filter Changed',
    props<{ requestsFilter: RequestsFilter; }>()
);
export const Its2RequestsMyTicketsChanged = createAction(
    '[ITS2-REQUESTS] Its2 Requests My Tickets Changed',
    props<{ myTickets: boolean; }>()
);
// // #endregion

// #region Statuses
export const Its2RequestsStatusesRequested = createAction(
    '[ITS2-REQUESTS] Its2 Requests Statuses Requested',
);
export const Its2RequestsStatusesFailed = createAction(
    '[ITS2-REQUESTS] Its2 Requests Statuses Failed',
    props<{ error: any; }>()
);
export const Its2RequestsFavoritesStatusUpdate = createAction(
    '[ITS2-REQUESTS] Its2 Requests Favorites Status Update',
    props<{ rfcNumbers: string[]; }>()
);

export const Its2RequestsReadStatusUpdate = createAction(
    '[ITS2-REQUESTS] Its2 Requests Read Status Update',
    props<{ rfcNumbers: string[]; }>()
);
// #endregion

// #region File Upload
export const Its2RequestsFilesUploadRequested = createAction(
    '[ITS2-REQUEST] Its2 Requests Files Upload Requested',
    props<{ rfcNumber: string }>()
);
export const Its2RequestsFilesUploadExecuted = createAction(
    '[ITS2-REQUEST] Its2 Requests Files Upload Executed',
    props<{ result: boolean }>()
);
// #endregion

// #region Export
export const Its2RequestsExportRequested = createAction(
    '[ITS2-REQUESTS] Its2 Requests Export Requested',
    props<{ qs: string, format: MediaTypes }>()
);

export const Its2RequestsExportSucceeded = createAction(
    '[ITS2-REQUESTS] Its2 Requests Export Succeeded',
    props<{ response: HttpEvent<Blob> }>()
);

export const Its2RequestsExportFailed = createAction(
    '[ITS2-REQUESTS] Its2 Requests Export Failed',
    props<{ failure: any }>()
);
// #endregion
