import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromRootImpersonateActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import {
    Its2RequestSearchResult,
    BckpJobSearchResult, BckpServerSearchResult, BckpPolicySearchResult,
    CompInstanceSearchResult,
    BackAuthEntitySearchResult, BackAuthUserSearchResult
} from '@common/models/search-result';
import { CompareDateTime2 } from '@common/services/utils.service';

import {
    ODataBckpJobsSearch, ODataBckpServersSearch, ODataBckpPoliciesSearch,
    ODataCompInstancesSearch,
    ODataBackAuthEntitiesSearch, ODataBackAuthUsersSearch, ODataIts2RequestsSearch
} from '../../models';
import * as fromRootSearchActions from './search.actions';

export const RootSearchFeatureKey = 'search';

// Result search collection
// #region ITSM Request
type ISearchResultIts2RequestsState = EntityState<Its2RequestSearchResult>;
export const searchResultIts2RequestsAdapter: EntityAdapter<Its2RequestSearchResult> = createEntityAdapter<Its2RequestSearchResult>({
    selectId: (sr: Its2RequestSearchResult) => sr.searchId,
    sortComparer: CompareDateTime2<Its2RequestSearchResult>((sr: Its2RequestSearchResult) => sr.result.submitDateUtc)
});
export const searchResultIts2RequestsInitialState: ISearchResultIts2RequestsState = searchResultIts2RequestsAdapter.getInitialState();
// #endregion

// #region BCKP Jobs
type ISearchResultBckpJobsState = EntityState<BckpJobSearchResult>;
export const searchResultBckpJobsAdapter: EntityAdapter<BckpJobSearchResult> = createEntityAdapter<BckpJobSearchResult>({
    selectId: (sr: BckpJobSearchResult) => sr.searchId
});
export const searchResultBckpJobsInitialState: ISearchResultBckpJobsState = searchResultBckpJobsAdapter.getInitialState();
// #endregion

// #region BCKP Servers
type ISearchResultBckpServersState = EntityState<BckpServerSearchResult>;
export const searchResultBckpServersAdapter: EntityAdapter<BckpServerSearchResult> = createEntityAdapter<BckpServerSearchResult>({
    selectId: (sr: BckpServerSearchResult) => sr.searchId
});
export const searchResultBckpServersInitialState: ISearchResultBckpServersState = searchResultBckpServersAdapter.getInitialState();
// #endregion

// #region BCKP Policies
type ISearchResultBckpPoliciesState = EntityState<BckpPolicySearchResult>;
export const searchResultBckpPoliciesAdapter: EntityAdapter<BckpPolicySearchResult> = createEntityAdapter<BckpPolicySearchResult>({
    selectId: (sr: BckpPolicySearchResult) => sr.searchId
});
export const searchResultBckpPoliciesInitialState: ISearchResultBckpPoliciesState = searchResultBckpPoliciesAdapter.getInitialState();
// #endregion

// #region COMP Instances
type ISearchResultCompInstancesState = EntityState<CompInstanceSearchResult>;
export const searchResultCompInstancesAdapter: EntityAdapter<CompInstanceSearchResult> = createEntityAdapter<CompInstanceSearchResult>({
    selectId: (sr: CompInstanceSearchResult) => sr.searchId
});
export const searchResultCompInstancesInitialState: ISearchResultCompInstancesState = searchResultCompInstancesAdapter.getInitialState();
// #endregion

// #region BACK AUTH Entities
type ISearchResultBackAuthEntitiesState = EntityState<BackAuthEntitySearchResult>;
export const searchResultBackAuthEntitiesAdapter: EntityAdapter<BackAuthEntitySearchResult> = createEntityAdapter<BackAuthEntitySearchResult>({
    selectId: (sr: BackAuthEntitySearchResult) => sr.searchId
});
export const searchResultBackAuthEntitiesInitialState: ISearchResultBackAuthEntitiesState = searchResultBackAuthEntitiesAdapter.getInitialState();
// #endregion

// #region BACK AUTH Users
type ISearchResultBackAuthUsersState = EntityState<BackAuthUserSearchResult>;
export const searchResultBackAuthUsersAdapter: EntityAdapter<BackAuthUserSearchResult> = createEntityAdapter<BackAuthUserSearchResult>({
    selectId: (sr: BackAuthUserSearchResult) => sr.searchId
});
export const searchResultBackAuthUsersInitialState: ISearchResultBackAuthUsersState = searchResultBackAuthUsersAdapter.getInitialState();
// #endregion

export interface IRootSearchState
{
    searchValue: string;

    oDataIts2RequestsSearch: ODataIts2RequestsSearch;
    isLoadingIts2Requests: boolean;
    searchResultIts2Requests: ISearchResultIts2RequestsState;

    oDataBckpJobsSearch: ODataBckpJobsSearch;
    isLoadingBckpJobs: boolean;
    searchResultBckpJobs: ISearchResultBckpJobsState;

    oDataBckpServersSearch: ODataBckpServersSearch;
    isLoadingBckpServers: boolean;
    searchResultBckpServers: ISearchResultBckpServersState;

    oDataBckpPoliciesSearch: ODataBckpPoliciesSearch;
    isLoadingBckpPolicies: boolean;
    searchResultBckpPolicies: ISearchResultBckpPoliciesState;

    oDataCompInstancesSearch: ODataCompInstancesSearch;
    isLoadingCompInstances: boolean;
    searchResultCompInstances: ISearchResultCompInstancesState;

    oDataBackAuthEntitiesSearch: ODataBackAuthEntitiesSearch;
    isLoadingBackAuthEntities: boolean;
    searchResultBackAuthEntities: ISearchResultBackAuthEntitiesState;

    oDataBackAuthUsersSearch: ODataBackAuthUsersSearch;
    isLoadingBackAuthUsers: boolean;
    searchResultBackAuthUsers: ISearchResultBackAuthUsersState;
}

const initialState: IRootSearchState = {
    searchValue: '',

    oDataIts2RequestsSearch: new ODataIts2RequestsSearch(null),
    isLoadingIts2Requests: false,
    searchResultIts2Requests: searchResultIts2RequestsInitialState,

    oDataBckpJobsSearch: new ODataBckpJobsSearch(null),
    isLoadingBckpJobs: false,
    searchResultBckpJobs: searchResultBckpJobsInitialState,

    oDataBckpServersSearch: new ODataBckpServersSearch(null),
    isLoadingBckpServers: false,
    searchResultBckpServers: searchResultBckpServersInitialState,

    oDataBckpPoliciesSearch: new ODataBckpPoliciesSearch(null),
    isLoadingBckpPolicies: false,
    searchResultBckpPolicies: searchResultBckpPoliciesInitialState,


    oDataCompInstancesSearch: new ODataCompInstancesSearch(null),
    isLoadingCompInstances: false,
    searchResultCompInstances: searchResultCompInstancesInitialState,


    oDataBackAuthEntitiesSearch: new ODataBackAuthEntitiesSearch(null),
    isLoadingBackAuthEntities: false,
    searchResultBackAuthEntities: searchResultBackAuthEntitiesInitialState,

    oDataBackAuthUsersSearch: new ODataBackAuthUsersSearch(null),
    isLoadingBackAuthUsers: false,
    searchResultBackAuthUsers: searchResultBackAuthUsersInitialState
};

export const RootSearchReducer = createReducer(
    initialState,
    on(
        fromRootImpersonateActions.RootImpersonateSet,
        fromRootImpersonateActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => initialState
    ),
    on(
        fromRootSearchActions.RootSearchInitialized,
        (state, { searchValue }) => ({
            ...state,
            searchValue: searchValue,

            oDataIts2RequestsSearch: new ODataIts2RequestsSearch(searchValue),
            isLoadingIts2Requests: true,
            searchResultIts2Requests: searchResultIts2RequestsAdapter.removeAll(state.searchResultIts2Requests),

            oDataBckpJobsSearch: new ODataBckpJobsSearch(searchValue),
            isLoadingBckpJobs: true,
            searchResultBckpJobs: searchResultBckpJobsAdapter.removeAll(state.searchResultBckpJobs),

            oDataBckpServersSearch: new ODataBckpServersSearch(searchValue),
            isLoadingBckpServers: true,
            searchResultBckpServers: searchResultBckpServersAdapter.removeAll(state.searchResultBckpServers),

            oDataBckpPoliciesSearch: new ODataBckpPoliciesSearch(searchValue),
            isLoadingBckpPolicies: true,
            searchResultBckpPolicies: searchResultBckpPoliciesAdapter.removeAll(state.searchResultBckpPolicies),


            oDataCompInstancesSearch: new ODataCompInstancesSearch(searchValue),
            isLoadingCompInstances: true,
            searchResultCompInstances: searchResultCompInstancesAdapter.removeAll(state.searchResultCompInstances),


            oDataBackAuthEntitiesSearch: new ODataBackAuthEntitiesSearch(searchValue),
            isLoadingBackAuthEntities: true,
            searchResultBackAuthEntities: searchResultBackAuthEntitiesAdapter.removeAll(state.searchResultBackAuthEntities),

            oDataBackAuthUsersSearch: new ODataBackAuthUsersSearch(searchValue),
            isLoadingBackAuthUsers: true,
            searchResultBackAuthUsers: searchResultBackAuthUsersAdapter.removeAll(state.searchResultBackAuthUsers),
        })
    ),
    on(
        fromRootSearchActions.RootSearchResultsIts2RequestsLoaded,
        (state, { result }) => ({
            ...state,
            isLoadingIts2Requests: false,
            searchResultIts2Requests: searchResultIts2RequestsAdapter.addMany(result, state.searchResultIts2Requests)
        })
    ),
    on(
        fromRootSearchActions.RootSearchResultsBckpJobsLoaded,
        (state, { result }) => ({
            ...state,
            isLoadingBckpJobs: false,
            searchResultBckpJobs: searchResultBckpJobsAdapter.addMany(result, state.searchResultBckpJobs)
        })
    ),
    on(
        fromRootSearchActions.RootSearchResultsBckpServersLoaded,
        (state, { result }) => ({
            ...state,
            isLoadingBckpServers: false,
            searchResultBckpServers: searchResultBckpServersAdapter.addMany(result, state.searchResultBckpServers)
        })
    ),
    on(
        fromRootSearchActions.RootSearchResultsBckpPoliciesLoaded,
        (state, { result }) => ({
            ...state,
            isLoadingBckpPolicies: false,
            searchResultBckpPolicies: searchResultBckpPoliciesAdapter.addMany(result, state.searchResultBckpPolicies)
        })
    ),
    on(
        fromRootSearchActions.RootSearchResultsCompInstancesLoaded,
        (state, { result }) => ({
            ...state,
            isLoadingCompInstances: false,
            searchResultCompInstances: searchResultCompInstancesAdapter.addMany(result, state.searchResultCompInstances)
        })
    ),
    on(
        fromRootSearchActions.RootSearchResultsBackAuthEntitiesLoaded,
        (state, { result }) => ({
            ...state,
            isLoadingBackAuthEntities: false,
            searchResultBackAuthEntities: searchResultBackAuthEntitiesAdapter.addMany(result, state.searchResultBackAuthEntities)
        })
    ),
    on(
        fromRootSearchActions.RootSearchResultsBackAuthUsersLoaded,
        (state, { result }) => ({
            ...state,
            isLoadingBackAuthUsers: false,
            searchResultBackAuthUsers: searchResultBackAuthUsersAdapter.addMany(result, state.searchResultBackAuthUsers)
        })
    ),
    on(
        fromRootSearchActions.RootSearchResultsCleared,
        () => ({
            ...initialState
        })
    )
);
