import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Donut2Component } from './donut2.component';

@NgModule({
    declarations: [
        Donut2Component
    ],
    imports: [
        CommonModule
    ],
    exports: [
        Donut2Component
    ]
})
export class Donut2Module
{ }
