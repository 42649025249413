import { ActivityByServer } from '@apps/bckp/domain/models';

export class BckpHeatConstants
{
    public static ServerSorter = (item: ActivityByServer): string => item.server;
    public static ListDivId = 'bckp-heat-list';
    public static InitSorterKey = 'server';
    public static TotalColumnHeader = 'Total';
    public static TotalSorterKey = 'total';
    public static NoSuccessRate: number = -1;
    public static computeSuccessRate = (nbJobs: number, nbFullyJobs: number): number =>
        nbJobs !== 0 ? nbFullyJobs / nbJobs : BckpHeatConstants.NoSuccessRate;
}
