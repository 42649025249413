import { User } from '@apps/auth/domain/models/back';
import { BackConstants } from '@apps/back/back.constants';

import { EntityApi } from '../../../api/models/back/entity-api';

export class ApplicationDefaultRole
{
    application: string;
    defaultRole: string;

    constructor (application: string, defaultRole: string)
    {
        this.application = application;
        this.defaultRole = defaultRole;
    }
}

export class AppliRoleIcon
{
    constructor (public appli: string, public role: string, public icon: string)
    {
    }
}

export class Entity
{
    public active: boolean;
    public id: string;
    public name: string;
    public entityShortId: string;
    public entityErpId: string;
    public external: boolean;
    public applications: ApplicationDefaultRole[];
    public appliRoleIcons: AppliRoleIcon[];
    public createdAt: string;
    public modifiedAt: string;
    public nbUsers?: number;
    public lastUserLoginUtc: string;
    public statusKey: string;
    public statusClass: string;
    public withExploit: boolean;
    public exploitKey: string;
    public exploitClass: string;
    public cisoId: string;
    public cisoContact: User;
    public dpoId: string;
    public dpoContact: User;
    public hasRight: Map<string, boolean> = new Map();
    public entityDocFolder: string;
    public extractedApplicationNames: string;
    public entityErpShortId: string;
    public isVirtual: boolean = false;

    public static mapperFromApi (source: EntityApi): Entity
    {
        const target = new Entity();

        Object.assign(target, source);

        target.statusKey = BackConstants.getStatusKey(target.active);
        target.statusClass = BackConstants.getCssStatus(target.active);

        target.exploitKey = 'STATUSES.WITH_EXPLOIT';
        target.exploitClass = BackConstants.getCssExploit(source?.withExploit);

        target.applications?.map(
            (app: ApplicationDefaultRole) =>
            {
                target.hasRight.set(app.application, true);
            }
        );

        target.extractedApplicationNames = target.applications?.map(a => a.application.substring(0, 4)).join(' ');

        return target;
    }
}
