import { DateTime } from 'luxon';

import { DatesService, UtilsService } from '@common/services';

import { ImageApi } from '../../../api/models';
import { OstkConstants } from '../../../ostk.constants';
import { CustomProperties } from '../common/custom-properties';

export class Image
{
    architecture: string;
    baseImageRef: string;
    bdmV2: string;
    blockDeviceMapping: [];
    checksum: string;
    containerFormat: string;
    createdAt: DateTime;
    customProperties: CustomProperties;
    description: string;
    diskFormat: string;
    file: string;
    hwDiskBus: string;
    hwVifModel: string;
    id: string;
    imageLocation: string;
    imageType: string;
    instanceId: string;
    minDisk: 0;
    minRam: 0;
    name: string;
    owner: string;
    protected: false;
    rootDeviceName: string;
    schema: string;
    self: string;
    size: number;
    status: string;
    tags: [];
    updatedAt: string;
    virtualSize: string;
    visibility: string;
    statusLabelKey: string;
    statusClass: string;
    osIconLocation: string;
    osLabel: string;
    osLabelFirstUpperCase: string;
    isPublic: boolean;
    isKeyPairRequired: boolean;

    public static mapperFromApi (source: ImageApi): Image
    {
        const target = new Image();

        Object.assign(target, source);

        target.statusLabelKey = source.status ? source.status.toUpperCase() : null;
        target.statusClass = source.status ? OstkConstants.getCssImageStatus(source.status) : null;
        target.createdAt = source.createdAt ? DatesService.UtcStringToDateTimeHourStatic(source.createdAt) : null;
        target.osLabel = UtilsService.getOsLabel(source.customProperties['os-icon'] || source.customProperties['os_icon'], 'default');
        target.osIconLocation = UtilsService.getFullOsPicture(target.osLabel);
        target.osLabelFirstUpperCase = UtilsService.toFirstUpperCase(target.osLabel);
        target.isKeyPairRequired = UtilsService.isKeyPairRequired(target.osLabel);
        target.isPublic = source.visibility && source.visibility.toLowerCase() === 'public';

        target.customProperties = CustomProperties.mapperFromApi(source.customProperties);

        return target;
    }
}
