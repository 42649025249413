import { createAction, props } from '@ngrx/store';
import { Platform, Project } from '../../domain/models';

export const OstkPlatformsLoadingSucceeded = createAction(
    '[OSTK-PLATFORMS] Ostk Platforms Loading Succeeded',
    props<{ platforms: Platform[] }>()
);
export const OstkPlatformsLoadingFailed = createAction(
    '[OSTK-PLATFORMS] Ostk Platforms Loading Failed',
    props<{ error: any }>()
);
export const OstkPlatformSelected = createAction(
    '[OSTK-PLATFORMS] Ostk Platform Selected',
    props<{ platform: Platform }>()
);
export const OstkPlatformUnknown = createAction(
    '[OSTK-PLATFORMS] Ostk Platform Unknown',
    props<{ platformSegment: string }>()
);

export const OstkProjectsLoadingRequested = createAction(
    '[OSTK-PROJECTS] Ostk Projects Loading Requested',
    props<{ platform: Platform }>()
);
export const OstkProjectsLoadingSucceeded = createAction(
    '[OSTK-PROJECTS] Ostk Projects Loading Succeeded',
    props<{ projects: Project[] }>()
);
export const OstkProjectsLoadingFailed = createAction(
    '[OSTK-PROJECTS] Ostk Projects Loading Failed',
    props<{ error: any }>()
);
export const OstkProjectSelected = createAction(
    '[OSTK-PROJECTS] Ostk Project Selected',
    props<{ project: Project }>()
);
export const OstkProjectUnknown = createAction(
    '[OSTK-PLATFORMS] Ostk Project Unknown',
    props<{ projectSegment: string }>()
);
