import { animate, state, style, transition, trigger, AnimationTriggerMetadata, } from '@angular/animations';

export const popin_notifications_animations: Array<AnimationTriggerMetadata> = [
    trigger('showHide', [
        state('show', style({
            right: '0'
        })),
        state('hide', style({
            right: '-26vw'
        })),
        transition('show => hide', [
            animate('0.5s')
        ]),
        transition('hide => show', [
            animate('0.5s')
        ])
    ])
];
