import { Injector } from '@angular/core';

import { OData } from '@common/api/qs/odata';
import { DatesService } from '@common/services';

import { JobsFilter } from '../../models';
import { JobsState, JobsStatus } from '../../bckp.constants';

export class ODataBckpJobs extends OData
{
    private static selectedFields = [
        'documentId',
        'serverShort',
        'policyName',
        'policyType',
        'state',
        'master',
        'started',
        'ended',
        'elapsed',
        'isRegulated',
        'server',
        'state',
        'status',
        'jobId',
        'parentJobId',
        'statusDesc'
    ];
    private static fields = ODataBckpJobs.selectedFields.join(',');
    private _filter: JobsFilter;
    private field4Filter: string[] = [
        'master',
        'serverShort',
        'policyName',
        'policyType'
    ];
    get filter ()
    {
        return this._filter;
    }
    get since ()
    {
        return this._filter.since;
    }
    get until ()
    {
        return this._filter.until;
    }
    public datesService: DatesService;
    public qsExport: string;
    public qsSinceUntil: string;

    constructor (incrSize: number, filter: JobsFilter, nbItems?: number)
    {
        super(incrSize, nbItems);

        const injector = Injector.create({ providers: [{ provide: DatesService, deps: [] }] });
        this.datesService = injector.get(DatesService);

        this.exportValues = [{ key: 'tags', value: 'true' }];

        if (filter !== undefined)
        {
            this.processFilter(filter);
        }

        // Options de tri
        this.values.push({ key: '$orderby', value: 'started:desc' });
        // this.values.push({ key: '$orderby', value: 'tries.statusCount:desc' });

        // Sélection des champs
        this.values.push({ key: '$select', value: ODataBckpJobs.fields });

        this.qs = this.getQueryString();

        this.qsExport = this.getExportQueryString();
    }

    public init ()
    {
        this.processFilter(new JobsFilter());
    }

    private processFilter (filter: JobsFilter)
    {
        this._filter = new JobsFilter();
        Object.assign(this._filter, filter);

        this.pushValue('since', filter.since);
        this.pushValue('until', filter.until);

        this.qsSinceUntil = `?since=${filter.since}&until=${filter.until}`;


        this.field4Filter.forEach((f: string) =>
        {
            if (filter[f])
            {
                this.pushValue('terms', `${f}:${filter[f]}`);
            }
        });
        this.qsState(filter.state);
        this.qsContract(filter.contract);
        if (!filter.state.includes(JobsState.Running))
        {
            this.qsStatus(filter.jobStatus);
        }
    }

    private getExportQueryString (): string
    {
        const queries: string[] = [];
        let qs: string = '?';

        this.exportValues
            .forEach((kv: { key: string, value: any }) =>
            {
                queries.push(`${kv.key}=${kv.value}`);
            });
        qs += queries.join('&');

        return qs;
    }

    private qsContract (options: number[]): void
    {
        if (options.length === 1)
        {
            this.pushValue('terms', `isRegulated:${options[0] === 1}`);
        }
    }

    private qsState (options: number[]): void
    {
        let v: string;

        if (options.length === 1)
        {
            if (options[0] === JobsState.Done)
            {
                // done only
                v = 'state:Done';
            }
            else if (options[0] === JobsState.Running)
            {
                // running only
                v = 'status:!Done';
            }

            this.pushValue('terms', v);
        }
    }

    private qsStatus (options: number[]): void
    {
        let v: string;

        if (options.length === 0)
        {
            return;
        }

        if (options.length === 1)
        {
            switch (options[0])
            {
                case JobsStatus.Success: // success only
                    v = 'status:0';
                    break;
                case JobsStatus.Partial: // partial only
                    v = 'status:1';
                    break;
                case JobsStatus.Failed: // failed only
                    v = 'status:!0,!1';
                    break;
            }

            this.pushValue('terms', v);
        }
        else if (options.length === 2)
        {
            if (options.includes(JobsStatus.Success) && options.includes(JobsStatus.Partial))
            {
                v = 'status:0,1';
            }

            if (options.includes(JobsStatus.Success) && options.includes(JobsStatus.Failed))
            {
                v = 'status:!1';
            }

            if (options.includes(JobsStatus.Partial) && options.includes(JobsStatus.Failed))
            {
                v = 'status:!0';
            }

            this.pushValue('terms', v);
        }
    }
}
