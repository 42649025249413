import { DateTime } from 'luxon';

import { ActivityByDateApi } from '@apps/bckp/api/models/activity';
import { BckpHeatConstants } from '@apps/bckp/store/servers/heat/bckp-heat.constants';

export class ActivityByDate extends ActivityByDateApi
{
    public dateJobs: DateTime;
    public since: DateTime;
    public until: DateTime;
    /* Totalisation par ligen/server si true */
    public isServerTotal: boolean = false;
    /* Totalisation par colonne si true */
    public isGrandTotal: boolean = false;
    public successRate: number;

    constructor (public server: string, public master: string)
    {
        super();

        this.nbJobs = 0;
        this.nbFullyJobs = 0;
        this.nbPartiallyJobs = 0;
        this.nbFailedJobs = 0;
    }
}

export class ActivityByDateDomain
{
    public static mapperToView (source: ActivityByDate): ActivityByDate
    {
        const target = { ...source };

        target.dateJobs = DateTime.fromFormat(source.dateExec, 'yyyy-MM-dd', { zone: 'utc' });
        target.since = target.until = target.dateJobs;
        target.successRate = BckpHeatConstants.computeSuccessRate(target.nbJobs, target.nbFullyJobs);

        return target;
    }
}
