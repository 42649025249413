import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { RequestsUrgencyCounter } from '@common/models';
import { Its2Constants, RequestTypeEnum } from '@apps/its2/its2.constants';
import { SecuFeatureKey } from '@apps/secu/store/secu.state';
import { RequestSecuApiService } from '@apps/its2/api';
import { arrayMapper } from '@common/operators';
import { RangeKey } from '@common/enum';
import { DatesService } from '@common/services';

import { DomainSimplifiedRequest, SimplifiedRequest, DomainRequestsEvolutionSummary, RequestsEvolutionSummary, RequestsUseCaseCounter, DomainRequestsUseCaseCounter } from './models';
import { DateRange } from '@ui/components/form/complex/range-picker/utils';
import { RequestsSummaryCounterApi, RequestsUrgencyCounterApi } from '@apps/its2/api/models';

@Injectable({
    providedIn: 'root'
})
export class RequestSecuDomainService extends BaseMiscDomainService
{
    constructor (
        private requestSecuApiService: RequestSecuApiService
    )
    {
        super();
    }

    getIncidentsCounters (): Observable<RequestsUrgencyCounter[]>
    {
        const incidentsMetaGuid = Its2Constants.CatalogEntryMetaGuid[SecuFeatureKey][RequestTypeEnum.INCIDENTS];

        return this.requestSecuApiService.getCounters(incidentsMetaGuid)
            .pipe(
                map((obj: RequestsSummaryCounterApi): RequestsUrgencyCounterApi[] =>
                    obj.incidents
                ),
                arrayMapper(RequestsUrgencyCounter.mapperFromApi)
            );
    }

    getServicesCounters (): Observable<RequestsUrgencyCounter[]>
    {
        const servicesMetaGuid = Its2Constants.CatalogEntryMetaGuid[SecuFeatureKey][RequestTypeEnum.SERVICES];

        return this.requestSecuApiService.getCounters(servicesMetaGuid)
            .pipe(
                map((obj: RequestsSummaryCounterApi): RequestsUrgencyCounterApi[] =>
                    obj.requests
                ),
                arrayMapper(RequestsUrgencyCounter.mapperFromApi)
            );
    }

    getSummaryCounters (): Observable<RequestsUseCaseCounter[]>
    {
        return this.requestSecuApiService.getSummaryCounters()
            .pipe(
                arrayMapper(DomainRequestsUseCaseCounter.mapperFromApi)
            );
    }

    getRequests (): Observable<SimplifiedRequest[]>
    {
        return this.requestSecuApiService.getRequests()
            .pipe(
                arrayMapper(DomainSimplifiedRequest.mapperFromApi)
            );
    }

    getEvolutionSummary (): Observable<RequestsEvolutionSummary[]>
    {
        return this.requestSecuApiService.getEvolutionSummary()
            .pipe(
                arrayMapper(DomainRequestsEvolutionSummary.mapperFromApi),
                map((collection: RequestsEvolutionSummary[]): RequestsEvolutionSummary[] =>
                {
                    const range: DateRange = DatesService.preRangeDatesStatic(RangeKey.LAST30DAYS);
                    // const range: DateRange = new DateRange(DateTime.utc(2022, 7, 1), DateTime.utc(2022, 7, 31));
                    let currDate: DateTime = range.since;

                    while (currDate <= range.until)
                    {
                        const currDateIso = currDate.toISODate();
                        const filtered = collection.filter((item: RequestsEvolutionSummary): boolean =>
                            item.dateUtc.toISODate() === currDateIso
                        );
                        if (filtered.length === 0)
                        {
                            collection.push(new RequestsEvolutionSummary(currDate, 1, 0, 0));
                            collection.push(new RequestsEvolutionSummary(currDate, 2, 0, 0));
                            collection.push(new RequestsEvolutionSummary(currDate, 3, 0, 0));
                        }

                        currDate = currDate.plus({ days: 1 });
                    }

                    return this.sortRequestsEvolutionSummary(collection);
                })
            );
    }

    private sortRequestsEvolutionSummary = (collection: RequestsEvolutionSummary[]): RequestsEvolutionSummary[] =>
        collection.sort((resa: RequestsEvolutionSummary, resb: RequestsEvolutionSummary): 1 | 0 | -1 =>
        {
            if (resa.dateUtc < resb.dateUtc)
            {
                return -1;
            }
            else if (resa.dateUtc > resb.dateUtc)
            {
                return 1;
            }

            if (resa.urgencyId < resb.urgencyId)
            {
                return -1;
            }
            else if (resa.urgencyId > resb.urgencyId)
            {
                return 1;
            }

            return 0;
        });
}
