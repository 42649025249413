import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { DateTime } from 'luxon';

import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';

import * as fromOstkVolumesVoluActions from './volu.actions';
import * as fromOstkProjectsActions from '../../projects/projects.actions';
import { OstkConstants } from '../../../ostk.constants';
import { Volume } from '../../../domain/models';
import { VolumeFields4Filter } from '../../../models/volumes/volume-fields-4filter';


export const OstkVolumesVoluFeatureKey = 'volu';

type IVolumesVoluState = EntityState<Volume>;
export const volumesVoluAdapter: EntityAdapter<Volume> = createEntityAdapter({
    selectId: (volume: Volume) => volume.id,
    sortComparer: (volua: Volume, volub: Volume) =>
        volua.name.toLowerCase().localeCompare(volub.name.toLowerCase())
});
const volumesVoluInitialState: IVolumesVoluState = volumesVoluAdapter.getInitialState({});
export interface IOstkVolumesVoluState
{
    volumes: IVolumesVoluState;
    filteredVolumes: Volume[];
    filter: VolumeFields4Filter;
    isEmpty: boolean;
    isLoading: boolean;
    isOups: boolean;
    isLoaded: boolean;
    expirationDate?: DateTime;
    selectedVolume: Volume;
    qsWord: string;
    switchFilter: boolean;
}

const initialState: IOstkVolumesVoluState = {
    volumes: volumesVoluInitialState,
    filteredVolumes: [],
    filter: new VolumeFields4Filter(),
    isEmpty: false,
    isLoading: true,
    isOups: false,
    isLoaded: false,
    expirationDate: null,
    selectedVolume: null,
    qsWord: '',
    switchFilter: true
};

export const OstkVolumesVoluReducer = createReducer(
    initialState,
    on(
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        fromOstkProjectsActions.OstkProjectSelected,
        () => initialState
    ),
    on(
        fromOstkVolumesVoluActions.OstkVolumesVoluListRequested,
        (state) =>
            ({
                ...state,
                isLoading: !(state.expirationDate !== null && DateTime.utc() < state.expirationDate)
            }),
    ),
    on(
        fromOstkVolumesVoluActions.OstkVolumesVoluListSucceeded,
        (state, { volumes }) =>
            ({
                ...state,
                isEmpty: volumes && volumes.length === 0,
                isLoading: false,
                isOups: false,
                isLoaded: true,
                volumes: volumesVoluAdapter.setAll(volumes, state.volumes),
                expirationDate: DateTime.utc().plus({ minutes: OstkConstants.DataLifeTime })
            }),
    ),
    on(
        fromOstkVolumesVoluActions.OstkVolumesVoluListFailed,
        (state) =>
            ({
                ...state,
                isLoading: false,
                isEmpty: false,
                isOups: true,
                isLoaded: false,
                volumes: volumesVoluAdapter.removeAll(state.volumes),
                expirationDate: null
            }),
    ),
    on(
        fromOstkVolumesVoluActions.OstkVolumesApplyFilter,
        (state, { vf4f }) =>
            ({
                ...state,
                isLoading: true,
                isEmpty: false,
                isOups: false,
                filter: new VolumeFields4Filter(vf4f)
            })
    ),
    on(
        fromOstkVolumesVoluActions.OstkVolumesFilterApplied,
        (state, { volumes }) =>
            ({
                ...state,
                isEmpty: volumes && volumes.length === 0,
                isLoading: false,
                filteredVolumes: volumes
            })
    ),
    on(
        fromOstkVolumesVoluActions.OstkVolumesVoluCreationSucceeded,
        (state, { volume }) =>
        {
            const filteredVolumes: Volume[] = state.filteredVolumes;
            filteredVolumes.push(volume);

            return {
                ...state,
                volumes: volumesVoluAdapter.addOne(volume, state.volumes),
                filteredVolumes: filteredVolumes
            };
        }
    ),
    on(
        fromOstkVolumesVoluActions.OstkVolumeSelected,
        (state, { volume }) =>
            ({
                ...state,
                selectedVolume: volume
            })
    ),
    on(
        fromOstkVolumesVoluActions.OstkVolumesVoluDeleteSucceeded,
        (state, { volumeId }) =>
            ({
                ...state,
                volumes: volumesVoluAdapter.removeOne(volumeId, state.volumes),
                filteredVolumes: state.filteredVolumes.filter(v => v.id !== volumeId)
            })
    ),
    on(
        fromOstkVolumesVoluActions.OstkVolumesVoluChanged,
        (state, { volume }: { volume: Update<Volume> }) =>
        {
            const volumes: IVolumesVoluState = volumesVoluAdapter.updateOne(volume, state.volumes);

            const filteredVolumes = state.filteredVolumes.map(v =>
                v.id === volume.id ? volumes.entities[volume.id] : v
            );

            return {
                ...state,
                volumes: volumesVoluAdapter.updateOne(volume, state.volumes),
                filteredVolumes: filteredVolumes
            };
        }
    ),
    on(
        fromOstkVolumesVoluActions.OstkVolumesVoluQsWordSet,
        (state, { qsWord }) =>
            ({
                ...state,
                qsWord: qsWord.toLowerCase()
            })
    ),
    on(
        fromOstkVolumesVoluActions.OstkVolumesVoluSwitchFilterSet,
        (state, { qsf } : {qsf: boolean}) =>
            ({
                ...state,
                switchFilter: qsf
            })
    )
);
