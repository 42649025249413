import { Component, Input, Host } from '@angular/core';

import { SelectOption9 } from '@ui/components/form9/common';

import { InputRadio9Component } from '../input-radio9.component';

@Component({
    selector: 'aa-radio9',
    templateUrl: './radio9.component.html'
})
export class Radio9Component
{
    @Input() name: string;
    @Input() option: SelectOption9;

    constructor (@Host() private inputRadio9: InputRadio9Component)
    { }

    toggle (): void
    {
        this.inputRadio9.toggle(this.option.value);
    }

    isInitChecked (): boolean
    {
        return this.inputRadio9.ctrlValue === this.option.value;
    }
}
