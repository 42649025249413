<div id="auth-wrapper">
        <div id="auth-left">
            <div id="auth-content">
                <div id="loginBrand">
                    <img
                        id="auth-logo"
                        src="assets/images/logoBrandBleu.png"
                        width="262px"
                        height="60px"
                        alt="Logo AntemetA"
                    />
                </div>
                <div id="all-auth-forms">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <div id="auth-right">
            <div id="auth-image">
            </div>
            <div id="auth-intro">
                <div class="intro-title">{{ welcomeMsg | async }}</div>
                <div class="intro-content">{{ 'AUTH.HOME.SLOGAN' | translate }}</div>
            </div>
        </div>
    </div>
