import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap, map, catchError, delay } from 'rxjs/operators';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { MatomoTrackerService } from '@common/services/matomo-tracker.service';
import { ImpersonateService } from '@apps/root/services/impersonate.service';

import { TokenService } from './token.service';
import { Profile } from '../models/profile';
import { LoginResult } from '../models/loginResult';
import { ProfileApi } from './models/profile-api';
import { PasswordResetData } from './models/reset-pswd';
import { ChangePassword } from './models';
import { AppConstants } from '../../app.constants';
import { UtilsService } from '@common/services';

@Injectable()
export class AuthenticationService extends BaseMiscApiService
{
    constructor (
        http: HttpClient,
        private tokenService: TokenService,
        private impersonateService: ImpersonateService,
        private matomoTrackerService: MatomoTrackerService
    )
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlAuth;
        this.segmentApi = '/auth/accounts';
    }

    login (email: string, password: string): Observable<LoginResult>
    {
        return this.post<LoginResult>('/login', { email: email, password: password })
            .pipe(
                tap((loginResult) =>
                {
                    this.tokenService.saveToken(loginResult.tokens);
                }),
                catchError((err) =>
                {
                    throw err;
                })
            );
    }

    twoFactorLogin (idToken: string, code: string): Observable<LoginResult>
    {
        return this.post<LoginResult>('/login/verify', { idToken, code })
            .pipe(
                tap((loginResult) =>
                {
                    this.tokenService.saveToken(loginResult.tokens);
                }),
                catchError((err) =>
                {
                    throw err;
                })
            );
    }

    profile (): Observable<Profile>
    {
        if (AppConstants.IsDataLocal('auth'))
        {
            return this.http.get('assets/data/auth/profile.json')
                .pipe(
                    map((obj: any): ProfileApi =>
                        obj.profile
                    ),
                    map(Profile.mapperFromApi)
                );
        }

        return this.get<LoginResult>('/profile')
            .pipe(
                map((obj: any): ProfileApi =>
                    obj.profile
                ),
                map(Profile.mapperFromApi)
            );
    }

    isProfileComplete (): Observable<boolean>
    {
        return this.get<boolean>('/complete');
    }

    updateProfile (profile: Profile): Observable<Profile>
    {
        return this.put<ProfileApi>('/profile', ProfileApi.mapperToApi(profile))
            .pipe(
                map(Profile.mapperFromApi)
            );
    }

    logout (): Observable<boolean>
    {
        if (!UtilsService.isNullOrEmpty(AppConstants.Configuration.trackingUrl) && this.matomoTrackerService)
        {
            this.matomoTrackerService.resetUserId();
            this.matomoTrackerService.appendToTrackingUrl('');
        }

        this.impersonateService.unsetImpersonation();

        return this.tokenService.logout();
    }

    // TODO: Quel type de donnée renvoyer
    resetPasswordSendLink (email: string): Observable<boolean>
    {
        return this.post('/pswd/reset/sendlink', { email: email })
            .pipe(
                map(() => true)
            );
    }

    resetPasswordVerifyToken (token: string): Observable<boolean>
    {
        return this.post('/pswd/reset/verifytoken', { token: token })
            .pipe(
                map(() => true)
            );
    }

    resetPassword (resetPasswordData: PasswordResetData): Observable<boolean>
    {
        return this.post<boolean>('/pswd/reset', resetPasswordData);
    }

    changePassword (changePassword: ChangePassword): Observable<Profile>
    {
        // return this.post<any>('/pswd/change', changePassword);

        return this.post<LoginResult>('/pswd/change', changePassword)
            .pipe(
                tap((loginResult) =>
                {
                    this.tokenService.saveToken(loginResult.tokens);
                }),
                map((obj: any): ProfileApi =>
                    obj.profile
                ),
                map(Profile.mapperFromApi),
                catchError((err) =>
                {
                    throw err;
                })
            );
    }

    isEmailTaken (email: string): Observable<boolean>
    {
        return of(true).pipe(delay(5000));
    }
}
