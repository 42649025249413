<div id="auth-loading" *ngIf="isLogging">
    <div class="logo">
        <img
            src="assets/images/logoBrandBleu.png"
            width="262px"
            height="60px"
            alt="Logo AntemetA"
        />
    </div>
    <div class="signal">
        <div class="message">{{ waitingMessage | async }}</div>
        <div class="loader">
            <aa-infinite-loader></aa-infinite-loader>
        </div>
    </div>
    <div class="image"></div>
</div>
