import { AppConstants } from '@apps/app.constants';

export class OData
{
    private _nbItems: number;
    get nbItems ()
    {
        return this._nbItems;
    }
    set nbItems (value)
    {
        this._nbItems = value;
    }
    private _currentScroll: number;
    private _scrollSize: number;
    private _top: number;
    get top ()
    {
        return this._top;
    }
    set top (value)
    {
        this._top = value;
    }
    private _skip: number;
    public isEndReached: boolean = false;
    public qs: string;
    protected values: { key: string, value: any }[] = [];
    protected exportValues: { key: string, value: any }[] = null;

    constructor (incrSize: number, nbItems?: number)
    {
        this._scrollSize = incrSize;
        this.nbItems = nbItems !== null ? nbItems : -1;

        this.resetIncr();
    }

    public incr (): void
    {
        this._currentScroll++;
        this._skip += this._scrollSize;

        this.qs = this.getQueryString();

        this.isEndReached = (this._currentScroll * this._scrollSize) >= this._nbItems;
    }

    public resetIncr (): void
    {
        this._currentScroll = 0;
        this._top = this._scrollSize;
        this._skip = 0;

        this.qs = this.getQueryString();
    }

    protected getQueryString ()
    {
        if (this._top === -1)
        {
            return null;
        }

        let qs: string = '?';
        const queries: string[] = [];

        if (this._skip !== 0)
        {
            queries.push(`$skip=${this._skip}`);
        }

        if (this._top !== 0)
        {
            queries.push(`$top=${this._top}`);
        }

        if (this.values.length === 0)
        {
            return '';
        }

        this.values
            .forEach((kv: { key: string, value: any }) =>
            {
                queries.push(`${kv.key}=${kv.value}`);
            });
        qs += queries.join('&');

        return qs;
    }

    protected pushValue (key: string, value: any, toExport: boolean = true)
    {
        this.values.push({ key: key, value: value });
        if (this.exportValues !== null && toExport)
        {
            this.exportValues.push({ key: key, value: value });
        }
    }
}

export class ODataSearch extends OData
{
    public searchValue: string;
    get qsSearch ()
    {
        return this.getQueryString();
    }

    constructor (searchValue: string, incrSize: number = AppConstants.QSSearchTop)
    {
        super(incrSize);

        this.searchValue = searchValue;
    }
}
