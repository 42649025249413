import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseListDomainService } from '@common/domain/base-list-domain.service';
import { ResultDomain } from '@common/domain/result-domain';
import { ResultApi } from '@common/api/result-api';
import { CmdbAssetListApiService } from '../api/cmdb-asset-list-api.service';
import { CmdbAssetApi } from '../api/models';
import { CmdbAsset } from '../models';

@Injectable({
    providedIn: 'root'
})
export class CmdbAssetListDomainService extends BaseListDomainService
{
    constructor (
        private cmdbAssetListApiService: CmdbAssetListApiService
    )
    {
        super();
    }

    getAssets (qs: string): Observable<ResultDomain<CmdbAsset>>
    {
        return this.cmdbAssetListApiService.getAssets(qs)
            .pipe(
                map((resultApi: ResultApi<CmdbAssetApi>) =>
                    ({
                        count: resultApi['@odata.count'],
                        value: resultApi.value
                            .map<CmdbAsset>(CmdbAsset.mapperFromApi)
                    }) as ResultDomain<CmdbAsset>
                )
            );
    }

}
