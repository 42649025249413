import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs';

import { Its2DashboardActions } from '../..';

@Injectable({
    providedIn: 'root'
})
export class Its2sharedsEffects
{
    constructor (
        private actions: Actions
    )
    {}

    filterApplied = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(Its2DashboardActions.Its2DashRangeSet),
                    switchMap(() => [
                        Its2DashboardActions.Its2DashSLAIncidentsRequested(),
                        Its2DashboardActions.Its2DashSLAServicesRequested(),
                        Its2DashboardActions.Its2DashReportingIncidentsSummariesRequested(),
                        Its2DashboardActions.Its2DashReportingServicesSummariesRequested(),
                        Its2DashboardActions.Its2DashBreakdownIncidentsCountersRequested(),
                        Its2DashboardActions.Its2DashBreakdownServicesCountersRequested()
                    ])
                )
    );

}