import { IIts2DashBreakdownState, Its2DashBreakdownFeatureKey } from './breakdown/breakdown.state';
import { IIts2DashReportingState, Its2DashReportingFeatureKey } from './reporting/reporting.state';
import { IIts2DashSharedState, Its2DashSharedFeatureKey } from './shared/shared.state';
import { IIts2DashSLAState, Its2DashSLAFeatureKey } from './sla/sla.state';

export const Its2DashboardFeatureKey = 'dashboard';

export interface IIts2DashboardState
{
    [Its2DashSLAFeatureKey]: IIts2DashSLAState;
    [Its2DashBreakdownFeatureKey]: IIts2DashBreakdownState;
    [Its2DashReportingFeatureKey]: IIts2DashReportingState;
    [Its2DashSharedFeatureKey]: IIts2DashSharedState
}
