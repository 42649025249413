import { DateTime } from 'luxon';

export class NavbarItem
{
    order: number;
    enabled: boolean;
    icon: string;
    id: string;
    production: DateTime;
    staging: DateTime;
    nuovo: boolean;
    soon: boolean;
    isDeployed: boolean;
    link: string;
    activeWhen: string;
    habilitation?: boolean = false;
    api: string = '';
    text?: string;
    active: boolean;
    trackerCategory?: string;
    trackerAction?: string;
    trackerLabel?: string;
    isPublic?: boolean;
    isAdmin?: boolean;
    onDashboard: boolean = false;

    public static mapperFromObject (source: any): NavbarItem
    {
        const ni = new NavbarItem();

        Object.assign(ni, source);

        ni.trackerCategory = 'MENU';
        ni.trackerAction = 'Click';

        ni.isAdmin = ni.link.includes('admin');
        ni.isPublic = !ni.link.includes('admin');

        return ni;
    }
}

export class UserNavbarItem extends NavbarItem
{
    isAuthorized: boolean;
}
