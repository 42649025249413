import { createReducer, on } from '@ngrx/store';

import { StatusesMetadata } from '@common/models';
import { RequestUrgencyCounterByDay } from '@apps/its2/domain/models';
import * as fromRootImpersonateActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';

import * as fromReportingActions from './reporting.actions';
import { IIts2DashReportingState, Its2DashReportingInitialState } from './reporting.state';

export const Its2DashboardReportingReducer = createReducer(
    Its2DashReportingInitialState,
    on(
        fromRootImpersonateActions.RootImpersonateSet,
        fromRootImpersonateActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => Its2DashReportingInitialState
    ),
    on(
        fromReportingActions.Its2DashReportingIncidentsSummariesRequested,
        (state: IIts2DashReportingState) =>
            ({
                ...state,
                incidents: {
                    collection: [],
                    ...StatusesMetadata.requested()
                }
            })
    ),
    on(
        fromReportingActions.Its2DashReportingIncidentsSummariesSucceeded,
        (state: IIts2DashReportingState, { summaries }: {summaries: RequestUrgencyCounterByDay[]}) => ({
            ...state,
            incidents: {
                collection: summaries,
                ...StatusesMetadata.succeeded(summaries.length !== 0, summaries.length === 0)
            }
        })
    ),
    on(
        fromReportingActions.Its2DashReportingIncidentsSummariesFailed,
        (state: IIts2DashReportingState) =>
            ({
                ...state,
                incidents: {
                    collection: [],
                    ...StatusesMetadata.failed()
                }
            })
    ),
    on(
        fromReportingActions.Its2DashReportingServicesSummariesRequested,
        (state: IIts2DashReportingState) =>
            ({
                ...state,
                services: {
                    collection: [],
                    ...StatusesMetadata.requested()
                }
            })
    ),
    on(
        fromReportingActions.Its2DashReportingServicesSummariesSucceeded,
        (state: IIts2DashReportingState, { summaries }: {summaries: RequestUrgencyCounterByDay[]}) => ({
            ...state,
            services: {
                collection: summaries,
                ...StatusesMetadata.succeeded(summaries.length !== 0, summaries.length === 0)
            }
        })
    ),
    on(
        fromReportingActions.Its2DashReportingServicesSummariesFailed,
        (state: IIts2DashReportingState) =>
            ({
                ...state,
                services: {
                    collection: [],
                    ...StatusesMetadata.failed()
                }
            })
    ),
);