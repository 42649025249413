import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslatePipe, TranslateModule } from '@ngx-translate/core';

import { ExportActionCircle2Component } from '@ui/misc/export-action-circle2/export-action-circle2.component';

import { PopinComponent } from './popin/popin.component';
import { PopinV2Component } from './popin-v2/popin-v2.component';
import { RouterModule } from '@angular/router';
import { TabGroupComponent } from './tabs/tab-group.component';
import { TabComponent } from './tabs/tab/tab.component';
import { TabContentDirective } from './tabs/tab/tab-content.directive';
import { TabTitleDirective } from './tabs/tab/tab-title.directive';
import { LabelQtyComponent } from './label-qty/label-qty.component';
import { SwitchableLabelQtyComponent } from './switchable-label-qty/switchable-label-qty.component';
import { SpinningLoaderComponent } from './spinning-loader/spinning-loader.component';
import { SpinningLoaderAltComponent } from './spinning-loader-alt/spinning-loader-alt.component';
import { InfiniteLoaderComponent } from './infinite-loader/infinite-loader.component';
import { ModalComponent } from './modal/modal.component';
import { ConfirmModalComponent } from './modal/confirm-modal/confirm-modal.component';
import { UiBtnModule } from '../components/buttons/ui.btn.module';
import { WizardComponent } from './wizard/wizard.component';
import { Wizard2Component } from './wizard/wizard2.component';
import { StepComponent } from './wizard/step/step.component';
import { StepContentDirective } from './wizard/step/step-content.directive';
import { StepTitleDirective } from './wizard/step/step-title.directive';
import { GuardModalComponent } from './guard-modal/guard-modal/guard-modal.component';
import { PopinModalComponent } from './popin-modal/popin-modal/popin-modal.component';
import { PopupMenuComponent } from './popup-menu/popup-menu.component';
import { PopupMenuItemComponent } from './popup-menu/popup-menu-item/popup-menu-item.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { AvatarIconComponent } from './avatar-icon/avatar-icon.component';
import { TabGroupNoSlideComponent } from './tabs/tab-group-no-slide.component';
import { CircleComponent } from './spinning-loader-alt/circle/circle.component';
import { LackOfDataComponent } from './lack-of-data/lack-of-data.component';
import { OupsComponent } from './oups/oups.component';
import { TinyOupsComponent } from './tiny-oups/tiny-oups.component';
import { DashCardComponent } from './dash-card/dash-card.component';
import { ProtectedAreaComponent } from './protected-area/protected-area.component';
import { RangeSelectorComponent } from './range-selector/range-selector.component';
import { SliderComponent } from './slider/slider.component';
import { DashTabGroupComponent } from './dash-tabs/dash-tab-group.component';
import { DashTabComponent } from './dash-tabs/dash-tab/dash-tab.component';
import { DashTabContentDirective } from './dash-tabs/dash-tab/dash-tab-content.directive';
import { DashTabTitleDirective } from './dash-tabs/dash-tab/dash-tab-title.directive';
import { AlertComponent } from './alert/alert.component';
import { RangeViewerComponent } from './range-viewer/range-viewer.component';
import { PopinV3Component } from './popin-v3/popin-v3.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { IconCircleComponent, ActionCircleComponent } from './icon-circle';
import { DetailsItemComponent } from './details-item/details-item.component';
import { HeadbandComponent } from './headband/headband.component';
import { QuotaComponent } from './quota/quota.component';
import { ProjectViewerComponent } from './project-viewer/project-viewer.component';
import { FilterActionCircleComponent } from './filter-action-circle/filter-action-circle.component';
import { FilterActionCircle2Component } from './filter-action-circle2/filter-action-circle2.component';
import { FilterSubTitleComponent } from './filter-sub-title/filter-sub-title.component';
import { SwitchThemeComponent } from './switch-theme/switch-theme.component';
import { OsPictureComponent } from './os-picture/os-picture.component';
import { ColoredPopupMenuItemComponent } from './popup-menu/colored/colored-popup-menu-item/colored-popup-menu-item.component';
import { ColoredPopupMenuComponent } from './popup-menu/colored/colored-popup-menu.component';
import { PopinSubtitleComponent } from './popin-subtitle/popin-subtitle.component';
import { TimelineComponent } from './timeline/timeline.component';
import { TimelineGroupComponent } from './timeline/timeline-group/timeline-group.component';
import { TimelineItemComponent } from './timeline/timeline-item/timeline-item.component';
import { PipesModule } from 'src/app/_pipes/pipes.module';
import { PopinContentHeaderComponent } from './popin-content-header/popin-content-header.component';
import { ColumnHeaderGroupComponent } from './column-headers/column-header-group.component';
import { ColumnHeaderItemComponent } from './column-headers/column-header-item/column-header-item.component';
import { QuickSearchWordComponent } from './quick-search-word/quick-search-word.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiFormModule } from '../components/form/ui.form.module';
import { ChipsSwitchComponent } from './chips/chips-switch/chips-switch.component';
import { WarningBadgeComponent } from './warning-badge/warning-badge.component';
import { DeleteItemComponent } from './delete-item/delete-item.component';
import { RoutletsComponent } from './routlets/routlets.component';
import { RoutletComponent } from './routlets/routlet/routlet.component';
import { PagingViewerComponent } from './paging-viewer/paging-viewer.component';
import { PopinTitleComponent } from './popin-title/popin-title.component';
import { DetailsItemOverflowComponent } from './details-item-overflow/details-item-overflow.component';
import { RatingComponent } from './rating/rating.component';
import { IconAppliComponent } from './icon-appli/icon-appli.component';
import { UserCardComponent } from './user-card/user-card.component';
import { PopupTooltipBaseComponent } from './popup-tooltip/base/popup-tooltip-base.component';
import { DashCardPopupTooltipComponent } from './dash-card-popup-tooltip/dash-card-popup-tooltip.component';
import { DashAppliContainerComponent } from './dash-appli-container/dash-appli-container.component';
import { QuickSwitchComponent } from './quick-switch/quick-switch.component';
import { Rating2Component } from './rating2/rating2.component';
import { UserIconComponent } from './user-icon/user-icon.component';
import { ScrollComponent } from './scroll/scroll.component';
import { HorizScrollComponent } from './scroll/horiz-scroll/horiz-scroll.component';
import { VertiScrollComponent } from './scroll/verti-scroll/verti-scroll.component';
import { DetailedUserComponent } from './detailed-user/detailed-user.component';
import { GridContainerComponent } from './grid-container/grid-container.component';
import { GridSimplePanelComponent } from './grid-container/grid-simple-panel/grid-simple-panel.component';
import { GridDoublePanelComponent } from './grid-container/grid-double-panel/grid-double-panel.component';
import { GridTriplePanelComponent } from './grid-container/grid-triple-panel/grid-triple-panel.component';
import { GridPanelContentComponent } from './grid-container/grid-panel-content/grid-panel-content.component';
import { GridPanelComponent } from './grid-container/grid-panel/grid-panel.component';
import { DetailedUserByProfileComponent } from './detailed-user-by-profile/detailed-user-by-profile.component';
import { DetailedGenericUserComponent } from './detailed-generic-user/detailed-generic-user.component';
import { DashExplicitAppliContainerComponent } from './dash-explicit-appli-container/dash-explicit-appli-container.component';
import { EntityIconAppliComponent } from '@ui/misc/entity-icon-appli/entity-icon-appli.component';

@NgModule({
    declarations: [
        TabGroupComponent,
        TabGroupNoSlideComponent,
        TabComponent,
        TabContentDirective,
        TabTitleDirective,
        WizardComponent,
        Wizard2Component,
        StepComponent,
        StepContentDirective,
        StepTitleDirective,
        PopinComponent,
        PopinTitleComponent,
        PopinV2Component,
        PopinV3Component,
        LabelQtyComponent,
        SwitchableLabelQtyComponent,
        SpinningLoaderComponent,
        SpinningLoaderAltComponent,
        CircleComponent,
        InfiniteLoaderComponent,
        ModalComponent,
        ConfirmModalComponent,
        GuardModalComponent,
        PopinModalComponent,
        PopupMenuComponent,
        PopupMenuItemComponent,
        ColoredPopupMenuComponent,
        ColoredPopupMenuItemComponent,
        SnackbarComponent,
        AvatarIconComponent,
        LackOfDataComponent,
        OupsComponent,
        TinyOupsComponent,
        DashCardComponent,
        ProtectedAreaComponent,
        RangeSelectorComponent,
        SliderComponent,
        DashTabGroupComponent,
        DashTabComponent,
        DashTabContentDirective,
        DashTabTitleDirective,
        AlertComponent,
        RangeViewerComponent,
        RangeSliderComponent,
        IconCircleComponent,
        ActionCircleComponent,
        DetailsItemComponent,
        HeadbandComponent,
        QuotaComponent,
        ProjectViewerComponent,
        FilterActionCircleComponent,
        FilterActionCircle2Component,
        ExportActionCircle2Component,
        FilterSubTitleComponent,
        SwitchThemeComponent,
        OsPictureComponent,
        PopinSubtitleComponent,
        TimelineComponent,
        TimelineGroupComponent,
        TimelineItemComponent,
        PopinContentHeaderComponent,
        ColumnHeaderGroupComponent,
        ColumnHeaderItemComponent,
        QuickSearchWordComponent,
        ChipsSwitchComponent,
        WarningBadgeComponent,
        DeleteItemComponent,
        RoutletsComponent,
        RoutletComponent,
        PagingViewerComponent,
        DetailsItemOverflowComponent,
        RatingComponent,
        IconAppliComponent,
        EntityIconAppliComponent,
        UserCardComponent,
        PopupTooltipBaseComponent,
        DashCardPopupTooltipComponent,
        DashAppliContainerComponent,
        QuickSwitchComponent,
        Rating2Component,
        UserIconComponent,
        ScrollComponent,
        HorizScrollComponent,
        VertiScrollComponent,
        DetailedUserComponent,
        DetailedUserByProfileComponent,
        GridContainerComponent,
        GridSimplePanelComponent,
        GridDoublePanelComponent,
        GridTriplePanelComponent,
        GridPanelContentComponent,
        GridPanelComponent,
        DetailedGenericUserComponent,
        DashExplicitAppliContainerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UiBtnModule,
        UiFormModule,
        PipesModule,
        TranslateModule
    ],
    exports: [
        TabGroupComponent,
        TabGroupNoSlideComponent,
        TabComponent,
        TabContentDirective,
        TabTitleDirective,
        WizardComponent,
        Wizard2Component,
        StepComponent,
        StepContentDirective,
        StepTitleDirective,
        PopinComponent,
        PopinTitleComponent,
        PopinV2Component,
        PopinV3Component,
        LabelQtyComponent,
        SwitchableLabelQtyComponent,
        SpinningLoaderComponent,
        SpinningLoaderAltComponent,
        InfiniteLoaderComponent,
        ModalComponent,
        ConfirmModalComponent,
        GuardModalComponent,
        PopinModalComponent,
        PopupMenuComponent,
        ColoredPopupMenuComponent,
        AvatarIconComponent,
        LackOfDataComponent,
        OupsComponent,
        TinyOupsComponent,
        DashCardComponent,
        ProtectedAreaComponent,
        RangeSelectorComponent,
        SliderComponent,
        DashTabGroupComponent,
        DashTabComponent,
        DashTabContentDirective,
        DashTabTitleDirective,
        AlertComponent,
        RangeViewerComponent,
        RangeSliderComponent,
        IconCircleComponent,
        ActionCircleComponent,
        DetailsItemComponent,
        HeadbandComponent,
        QuotaComponent,
        ProjectViewerComponent,
        FilterActionCircleComponent,
        FilterActionCircle2Component,
        ExportActionCircle2Component,
        FilterSubTitleComponent,
        SwitchThemeComponent,
        OsPictureComponent,
        PopinSubtitleComponent,
        TimelineComponent,
        TimelineGroupComponent,
        TimelineItemComponent,
        PopinContentHeaderComponent,
        ColumnHeaderGroupComponent,
        ColumnHeaderItemComponent,
        QuickSearchWordComponent,
        ChipsSwitchComponent,
        // ChipsComponent,
        // ChipsOverflowComponent,
        // ChipsTransparentComponent,
        WarningBadgeComponent,
        DeleteItemComponent,
        RoutletsComponent,
        PagingViewerComponent,
        DetailsItemOverflowComponent,
        RatingComponent,
        IconAppliComponent,
        EntityIconAppliComponent,
        UserCardComponent,
        DashCardPopupTooltipComponent,
        DashAppliContainerComponent,
        QuickSwitchComponent,
        Rating2Component,
        UserIconComponent,
        DetailedUserComponent,
        DetailedUserByProfileComponent,
        GridContainerComponent,
        GridSimplePanelComponent,
        GridDoublePanelComponent,
        GridTriplePanelComponent,
        GridPanelContentComponent,
        DashExplicitAppliContainerComponent
    ],
    providers: [
        TranslatePipe
    ]
})
export class UiMiscModule
{
}
