import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { TokenService } from '../api/token.service';
import { AppConstants } from '../../app.constants';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenCanActivateGuard implements CanActivate
{
    constructor (
        private router: Router,
        private tokenService: TokenService
    )
    { }

    canActivate (): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this.process();
    }

    process (): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const accessToken: string = this.tokenService.getAccessToken();

        if (accessToken !== null)
        {
            if (this.tokenService.isAccessTokenValid())
            {
                return this.router.navigate([AppConstants.DefaultRedirectUrl]);
            }
            else
            {
                return this.tokenService.getNewToken()
                    .pipe(
                        switchMap(() =>
                            this.router.navigate([AppConstants.DefaultRedirectUrl])
                        ),
                        catchError(() =>
                            of(true)
                        )
                    );
            }
        }
        else
        {
            return true;
        }
    }
}
