import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, toArray } from 'rxjs/operators';
import { ProjectMiscApiService } from '../api';

import { ProjectApi } from '../api/models';
import { Platform, Project } from './models';

@Injectable({
    providedIn: 'root'
})
export class ProjectMiscDomainService
{
    constructor (
        private projectMiscApiService: ProjectMiscApiService
    )
    { }

    getPlatforms (): Observable<Platform[]>
    {
        return this.projectMiscApiService.getPlatforms()
            .pipe(
                map(Platform.mapperFromApi),
                toArray()
            );
    }

    getProjects (platform: Platform): Observable<Project[]>
    {
        return this.projectMiscApiService.getProjects(platform.platformId)
            .pipe(
                map((projectApi: ProjectApi) =>
                    ({
                        projectId: projectApi.projectId,
                        projectName: projectApi.projectName,
                        platformId: platform.platformId,
                        platformName: platform.platformName,
                        platformType: platform.platformType,
                        platformUri: platform.platformUri,
                        platformDomain: platform.platformDomain,
                        projectDescription: projectApi.projectDescription,
                        platformSegment: platform.platformSegment,
                        projectSegment: projectApi.projectName
                    } as Project)
                ),
                toArray()
            );
    }
}
