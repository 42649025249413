import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsChartModule } from '../../../components/components-chart.module';
import { TimelineChartComponent } from './timeline-chart.component';



@NgModule({
    declarations: [
        TimelineChartComponent
    ],
    imports: [
        CommonModule,
        ComponentsChartModule
    ],
    exports: [
        TimelineChartComponent
    ]
})
export class TimelineChartModule
{ }
