import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { FieldMsgErrorComponent } from './controls/field-msg-error/field-msg-error.component';
import { FormMsgErrorComponent } from './controls/form-msg-error/form-msg-error.component';
import { CheckMark9Component } from './controls/check-mark9/check-mark9.component';
import { InputHidden9Component } from './controls/input/input-hidden9/input-hidden9.component';
import { InputText9Component } from './controls/input/input-text9/input-text9.component';
import { InputRadio9Component } from './controls/input/input-radio9/input-radio9.component';
import { Radio9Component } from './controls/input/input-radio9/radio9/radio9.component';
import { InputCheckbox9Component } from './controls/input/input-checkbox9/input-checkbox9.component';
import { Checkbox9Component } from './controls/input/input-checkbox9/checkbox9/checkbox9.component';
import { InputDropdown9Component } from './controls/input/input-dropdown9/input-dropdown9.component';
import { PopupDropdownComponent } from './controls/input/input-dropdown9/popup-dropdown/popup-dropdown.component';
import { ArrowComboComponent } from './common/arrow-combo/arrow-combo.component';
import { ItemPopupDropdownComponent } from './controls/input/input-dropdown9/popup-dropdown/item-popup-dropdown/item-popup-dropdown.component';
import { InputDigits9Component } from './controls/input/input-digits9/input-digits9.component';
import { InputSensorids9Component } from './controls/input/input-sensorids9/input-sensorids9.component';

@NgModule({
    declarations: [
        FieldMsgErrorComponent,
        FormMsgErrorComponent,
        CheckMark9Component,
        InputHidden9Component,
        InputText9Component,
        InputRadio9Component,
        Radio9Component,
        InputCheckbox9Component,
        Checkbox9Component,
        InputDropdown9Component,
        PopupDropdownComponent,
        ArrowComboComponent,
        ItemPopupDropdownComponent,
        InputDigits9Component,
        InputSensorids9Component
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ScrollingModule,
    ],
    exports: [
        InputHidden9Component,
        InputText9Component,
        InputRadio9Component,
        InputCheckbox9Component,
        InputDropdown9Component,
        InputDigits9Component,
        InputSensorids9Component
    ],
    providers: [
        TranslatePipe
    ]
})
export class UiForm9Module
{
}
