import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseMiscApiService } from 'src/app/_common/api/base-misc-api.service';
import { Profile } from '../models/profile';
import { AppConstants } from '../../app.constants';

@Injectable({
    providedIn: 'root'
})
export class AccountCreationService extends BaseMiscApiService
{
    constructor(http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlAuth;
        this.segmentApi = '/auth/accounts';
    }

    emailConfirmSendLink (email: string): Observable<boolean>
    {
        return this.post('/email/confirm', { email: email })
            .pipe(
                map(() => true),
                catchError((err) =>
                {
                    throw err;
                })
            );
    }

    emailConfirmVerifyToken (token: string): Observable<boolean>
    {
        return this.post('/email/verify', { token: token })
            .pipe(
                map(() => true),
                catchError((err) =>
                {
                    throw err;
                })
            );
    }

    self (profile: Profile): Observable<Profile>
    {
        return this.post<Profile>(
            '/self', profile
        );
    }
}
