import { createAction, props } from '@ngrx/store';

import { Alert } from '../../domain/models';

export const NotfAlertLoadRequested = createAction(
    '[NOTF-ALERT] Load Alert Requested'
);

export const NotfAlertLoadSucceeded = createAction(
    '[NOTF-ALERT] Load Alert Succeeded',
    props<{ alerts: Alert[] }>()
);
