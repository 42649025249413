import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppConstants } from '@apps/app.constants';
import { BaseMiscApiService } from '@common/api/base-misc-api.service';

import { SnapshotApi } from './models';

@Injectable({
    providedIn: 'root'
})
export class SnapshotListApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlOstk;
        this.segmentApi = '/ostk/snapshots';
    }

    getSnapshots (): Observable<SnapshotApi[]>
    {
        if (AppConstants.IsDataLocal('ostk'))
        {
            return this.http.get<SnapshotApi[]>('assets/data/ostk/volumes-snapshots.json');
        }

        return this.get<SnapshotApi[]>();
    }
}
