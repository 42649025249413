import { OData } from 'src/app/_common/api/qs';
import { PoliciesFilter } from '../../models/policies-filter';
import { PolicyStatus } from '../../bckp.constants';

export class ODataBckpPolicies extends OData
{
    private _filter: PoliciesFilter;
    private field4Filter: string[] = [
        'master'
    ];
    get filter ()
    {
        return this._filter;
    }
    public qsExport: string;

    constructor (incrSize: number, filter: PoliciesFilter)
    {
        super(incrSize);

        if (filter !== undefined)
        {
            this.processFilter(filter);
        }

        this.qs = this.getQueryString();
        this.qsExport = this.getQueryString();
    }

    public init ()
    {
        this.processFilter(new PoliciesFilter());
    }

    private processFilter (filter: PoliciesFilter)
    {
        this._filter = new PoliciesFilter();
        Object.assign(this._filter, filter);

        this.pushValue('$orderby', 'policyName');

        this.pushValue('since', filter.since, true);
        this.pushValue('until', filter.until, true);

        this.field4Filter.forEach((f: string) =>
        {
            if (filter[f])
            {
                this.pushValue('terms', `${f}:${filter[f]}`);
            }
        });

        if (filter.policyStatus)
        {
            this.qsActiveStatus(filter.policyStatus);
        }
    }

    private qsActiveStatus (options: number[]): void
    {
        let v: string;
        if (options.length > 0 && options.length < 2)
        {
            if (options.indexOf(PolicyStatus.Active) !== -1)
            {
                // Append "true"
                v = 'active:true';
            }
            if (options.indexOf(PolicyStatus.Inactive) !== -1)
            {
                // Append "false"
                v = 'active:false';
            }
            this.pushValue('terms', v);
        }
    }

}
