import { TypePopin } from '@apps/app.constants';

export interface LinkRouter
{
    //  this.router.navigate([route, { outlets: { TypePopin.DETAILS: [ this.entity.id] } }]);
    baseUrlFragment: string;
    outlet: TypePopin;
    extraUrlFragment?: string | number;
    params: any[];
}

export abstract class SearchResult<T>
{
    public result: T;
    public searchId: string;
    public category: string;
    public id?: string;
    public name?: string;
    public statusClass: string;
    public statusText: string = null;
    public statusKey: string = null;
    public icon: string;
    public link: LinkRouter;

    constructor (category: string, id: string)
    {
        this.category = category;
        this.id = id;
        this.searchId = `${this.category}-${this.id}`;
    }
}
