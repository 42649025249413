import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, concatAll, first, toArray } from 'rxjs/operators';

import { BaseListDomainService } from '@common/domain/base-list-domain.service';
import { BckpPolicySearchResult } from '@common/models/search-result';
import { ResultApi } from '@common/api/result-api';

import { PolicyListApiService } from '../api/policy-list-api.service';
import { Policy, PolicyDetails } from './models';
import { PolicyApi } from '../api/models';
import { PolicyDetailsApi } from '../api/models/policy-api';
import { PolicyDetailsApiService } from '../api/policy-details-api.service';

@Injectable({
    providedIn: 'root'
})
export class PolicyListDomainService extends BaseListDomainService
{

    constructor (
        private policyListApiService: PolicyListApiService,
        private policyDetailsApiService: PolicyDetailsApiService,
    )
    {
        super();
    }

    getLatestPolicies (qs: string): Observable<Policy[]>
    {
        return this.policyListApiService.getLatestPolicies(qs)
            .pipe(
                map((obj: ResultApi<PolicyApi>): PolicyApi[] =>
                    obj.value),
                concatAll(),
                map((policyApi: PolicyApi) =>
                    Policy.mapperFromApi(policyApi)
                ),
                toArray()
            );
    }

    search (qs: string): Observable<BckpPolicySearchResult[]>
    {
        return this.policyListApiService.getLatestPolicies(qs)
            .pipe(
                map((obj: ResultApi<PolicyApi>): PolicyApi[] =>
                    obj.value
                ),
                map((policies: PolicyApi[]) =>
                    policies
                        .map(this.searchMapperFromApi, this)
                        .slice(0, 7)
                )
            );
    }

    private searchMapperFromApi (policyApi: PolicyApi): BckpPolicySearchResult
    {
        return new BckpPolicySearchResult(Policy.mapperFromApi(policyApi));
    }

    getPolicyDetails (documentId: string): Observable<PolicyDetails>
    {
        return this.policyDetailsApiService.getPolicyDetails(documentId)
            .pipe(
                map((obj: ResultApi<PolicyDetailsApi>): PolicyDetailsApi[] =>
                    obj.value
                ),
                map((result: PolicyDetailsApi[]) =>
                    result.map<PolicyDetails>((policyDetail: PolicyDetailsApi) =>
                        PolicyDetails.mapperFromApi(policyDetail)
                    )
                ),
                first(),
                map((Policies: PolicyDetails[]) => Policies[0])
            );
    }
}
