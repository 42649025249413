import { createReducer, on } from '@ngrx/store';

import * as fromAuthLoginActions from './login.actions';
import { AppConstants } from 'src/app/_apps/app.constants';

export const AuthLoginFeatureKey = 'login';
export interface IAuthLoginState
{
    loggedIn: boolean;
    returnUrl: string;
    email: string;
    firstName: string;
    lastName: string;
    counterTwoFactorTries: number;
}

const initialState: IAuthLoginState = {
    loggedIn: false,
    returnUrl: '',
    email: null,
    firstName: null,
    lastName: null,
    counterTwoFactorTries: 3
};

export const AuthLoginReducer = createReducer(
    initialState,
    on(
        fromAuthLoginActions.AuthLoginEntered,
        (state, { email, firstName, lastName }) => ({
            ...state,
            email: email,
            firstName: firstName,
            lastName: lastName
        })
    ),
    on(
        fromAuthLoginActions.AuthLoggedIn,
        (state) => ({
            ...state,
            counterTwoFactorTries: initialState.counterTwoFactorTries
        })
    ),
    on(
        fromAuthLoginActions.AuthReturnUrlSaved,
        (state, { returnUrl }) =>
        {
            let urlDeRetour: string;

            if (!returnUrl || returnUrl === '/')
            {
                urlDeRetour = AppConstants.DefaultRedirectUrl;
            }
            else
            {
                urlDeRetour = returnUrl;
            }

            return {
                ...state,
                returnUrl: urlDeRetour
            };
        }
    ),
    on(
        fromAuthLoginActions.AuthLogout,
        (state) =>
        {
            const returnUrl = state.returnUrl ? state.returnUrl : AppConstants.DefaultRedirectUrl;

            return {
                ...initialState,
                returnUrl: returnUrl
            };
        }
    ),
    on(
        fromAuthLoginActions.AuthTwoFactorRequested,
        (state) => ({
            ...state,
            counterTwoFactorTries:  initialState.counterTwoFactorTries
        })
    ),
    on(
        fromAuthLoginActions.AuthTwoFactorLoginFailed,
        (state) => ({
            ...state,
            counterTwoFactorTries: state.counterTwoFactorTries - 1
        })
    )
);
