import { ApplicationRole } from '../../models/application-role';
import { Profile } from '../../models/profile';

export class ProfileApi
{
    firstName?: string;
    lastName?: string;
    firstLastName?: string;
    roles?: ApplicationRole[];
    subscriptions?: string[];
    id?: string;
    identiconDataUrl?: string;
    userName?: string;
    normalizedUserName?: string;
    email?: string;
    normalizedEmail?: string;
    emailConfirmed?: boolean;
    phoneNumber?: string;
    phoneNumberConfirmed?: boolean;
    mobile?: string;
    twoFactorEnabled?: boolean;
    entityId?: string;
    entityName?: string;
    title?: string;
    marketingNewsletter?: boolean;
    maintenanceNotification?: boolean;
    language?: string;
    skin?: string;
    isEntityAdmin?: boolean;
    workingCookies?: boolean;
    statisticsCookies?: boolean;
    socialNetworksCookies?: boolean;
    nextProfileCompletionUtc?: string;
    entityShortId?: string;

    public static mapperToApi (source: Profile): ProfileApi
    {
        const target = new ProfileApi();

        Object.assign(target, source);

        target.nextProfileCompletionUtc = source.nextProfileCompletionUtc
            ? source.nextProfileCompletionUtc.toISO()
            : null;

        return target;
    }
}
