import { createFeatureSelector } from '@ngrx/store';

import { IAuroraState } from '@apps/aurora.state';
import { IObjsCredentialState, ObjsCredentialFeatureKey, objsCredentialInitialState } from './credential/credential.state';
import { IObjsStorageState, ObjsStorageFeatureKey, ObjsStorageInitialState } from './storage/storage.state';
import { IObjsBucketState, ObjsBucketFeatureKey, ObjsBucketInitialState } from './bucket/bucket.state';

import { IObjsMiscState, ObjsMiscFeatureKey, ObjsMiscInitialState } from './misc/misc.state';

export const ObjsFeatureKey = 'objs';

export interface IState extends IAuroraState
{
    [ObjsFeatureKey]: IObjsState;
}

export interface IObjsState
{
    [ObjsCredentialFeatureKey]: IObjsCredentialState;
    [ObjsStorageFeatureKey]: IObjsStorageState;
    [ObjsBucketFeatureKey]: IObjsBucketState;
    [ObjsMiscFeatureKey]: IObjsMiscState;
}

export const ObjsInitialState: IObjsState = {
    [ObjsCredentialFeatureKey]: objsCredentialInitialState,
    [ObjsStorageFeatureKey]: ObjsStorageInitialState,
    [ObjsBucketFeatureKey]: ObjsBucketInitialState,
    [ObjsMiscFeatureKey]: ObjsMiscInitialState
};

export const getObjsState = createFeatureSelector<IObjsState>(
    ObjsFeatureKey
);
