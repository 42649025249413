import { Component, OnInit, forwardRef, Optional, Inject, Injector, Input, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { ElementBase, OptionValue, SelectOption } from '../../../common';

@Component({
    selector: 'aa-radio-group2',
    templateUrl: './radio-group2.component.html',
    styleUrls: [
        './radio-group2.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioGroup2Component),
            multi: true
        }
    ]
})
export class RadioGroup2Component extends ElementBase<OptionValue> implements OnInit
{
    @Input() name: string;
    @Input() options: SelectOption[];
    @Input() orientation: string = 'horizontal';
    @Input() toTranslate: boolean = false;
    @Input() lineSize: number = 0;
    @Input() marginTop: number = 20;
    @Output() public clickedRadio: EventEmitter<string | number | boolean> = new EventEmitter();

    public lines: number[];

    constructor (
        injector: Injector,
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>
    )
    {
        super(injector);
    }

    ngOnInit ()
    {
        if (this.lineSize !== 0)
        {
            const nbLines = Math.ceil(this.options.length / this.lineSize);

            this.lines = Array.from(Array(nbLines));
        }
    }

    toggle (v: OptionValue)
    {
        this.value = v;
        this.clickedRadio.emit(v);
    }
}
