import { createSelector } from '@ngrx/store';

import * as fromStorageState from './storage.state';
import { getObjsState, IObjsState } from '../objs.state';

const getObjsStorageState = createSelector(
    getObjsState,
    (state: IObjsState) =>
        state[fromStorageState.ObjsStorageFeatureKey]
);
const getUserConsoState = createSelector(
    getObjsStorageState,
    (state) => state.userConso
);
export const {
    selectAll: getUserConsos,
} = fromStorageState.userConsoAdapter.getSelectors(getUserConsoState);

const getTeamConsoState = createSelector(
    getObjsStorageState,
    (state) => state.teamConso
);
export const {
    selectAll: getTeamConsos,
} = fromStorageState.teamConsoAdapter.getSelectors(getTeamConsoState);

export const getLoadingStatus = createSelector(
    getObjsStorageState,
    (state) => state.isLoading
);
export const getLoadedStatus = createSelector(
    getObjsStorageState,
    (state) => state.isLoaded
);
export const getOupsStatus = createSelector(
    getObjsStorageState,
    (state) => state.isOups
);
export const getEmptyStatus = createSelector(
    getObjsStorageState,
    (state) => state.isEmpty
);
export const getAllObjsPlatforms = createSelector(
    getObjsStorageState,
    (state) => state.platforms
);
export const getHeaderPlatform = createSelector(
    getObjsStorageState,
    (state) => state.platformId
);
