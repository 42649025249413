import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { ValidationResult } from './validate';

@Injectable({
    providedIn: 'root'
})
export class ErrorMsgService
{
    constructor (private translate: TranslateService)
    { }

    public getMessage (validator: ValidationResult, key: string): string
    {
        let trad: string;

        this.translate.get(`FORMS.MESSAGE.${key.toUpperCase()}`, validator[key])
            .pipe(
                take(1)
            )
            .subscribe(
                (data: string) => trad = data
            );

        return trad;
    }
}
