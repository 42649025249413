import { NetworkApi } from '../../../api/models/network';
import { OstkConstants } from '../../../ostk.constants';
import { Subnet } from './subnet';

export class Network
{
    adminStateUp?: boolean;
    id: string;
    mtu: number;
    name: string;
    providerNetworkType?: string;
    providerPhysicalNetwork: string;
    providerSegmentationId?: number;
    routerExternal?: boolean;
    shared?: boolean;
    status: string;
    subnetIds: string[];
    subnets: Subnet[];
    tenantId: string;
    subnetsClass: string;
    statusClass: string;
    statusKey: string;

    public static mapperFromApi (source: NetworkApi): Network
    {
        const target = new Network();
        Object.assign(target, source);

        target.subnetsClass = OstkConstants.getSubnetsClass(source.subnets && source.subnets.length > 0);
        target.statusClass = OstkConstants.getNetworkStatusClass(source.adminStateUp);
        target.statusKey = OstkConstants.getNetworkStatusKey(source.adminStateUp);
        target.subnets = source.subnets.map(Subnet.mapperFromApi);

        return target;
    }
}
