import { Component, Optional, Inject, Injector, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, UntypedFormControl } from '@angular/forms';

import { InputBase } from '../../../base/input-base';

@Component({
    selector: 'aa-input-token-code',
    templateUrl: './input-token-code.component.html',
    styleUrls: [
        '../input.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputTokenCodeComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: InputTokenCodeComponent,
            multi: true,
        }
    ]
})
export class InputTokenCodeComponent extends InputBase<string>
{
    private static validateRegexp = /^\d{6}$/;
    private static identifier: number = 0;

    constructor(
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
        injector: Injector
    )
    {
        super(injector);

        this.fieldId = `input-token-code-${InputTokenCodeComponent.identifier++}-id`;
    }

    // * https://netbasal.com/adding-integrated-validation-to-custom-form-controls-in-angular-dc55e49639ae
    validate ({ value }: UntypedFormControl)
    {
        if (value)
        {
            return value.trim().match(InputTokenCodeComponent.validateRegexp) ? null : { 'inputTokenCode': true };
        }
    }
}
