<button
    type="button"
    [disabled]="disabled ? 'disabled' : undefined"
    class="aa-btn"
    [class.pressed]="pressed"
    [ngClass]="getClassNames()"
    (click)="onClick()"
    [style.width]="btnWidth"
>
    {{ btnText }}
</button>
