import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import { IAuroraState } from '@apps/aurora.state';
import { Md5Service } from '@common/services';

import * as fromMiscReducer from './misc/misc.reducer';
import * as fromSearchReducer from './search/search.reducer';
import * as fromImpeReducer from './impersonate/impe.reducer';
import * as fromQuickSearchReducer from './quick-search/quick-search.reducer';
import { QuickSearchValue } from '@apps/root/models';
import { IFilterTagState, IRootFilterTagState, RootFilterTagFeatureKey } from '@apps/root/store/filter-tag/filter-tag.state';
import { RootFilterTagReducer } from '@apps/root/store/filter-tag/filter-tag.reducer';

export const RootFeatureKey = 'root';
// console.log all actions
// export function debug (reducer: ActionReducer<IRootState>): ActionReducer<IRootState>
// {
//     return function (state, action)
//     {
//         return reducer(state, action);
//     };
// }

// export const metaReducers: MetaReducer<IRootState>[] = AppConstants.IsDebugStage ? [debug] : [];

export interface IRootState
{
    [fromMiscReducer.RootMiscFeatureKey]: fromMiscReducer.IRootMiscState;
    [fromSearchReducer.RootSearchFeatureKey]: fromSearchReducer.IRootSearchState;
    [fromImpeReducer.RootImpeFeatureKey]: fromImpeReducer.IRootImpeState;
    [fromQuickSearchReducer.RootQuickSearchFeatureKey]: fromQuickSearchReducer.IRootQuickSearchState;
    [RootFilterTagFeatureKey]: IRootFilterTagState;
}

export interface IState extends IAuroraState
{
    [RootFeatureKey]: IRootState;
}

export function RootReducers (state: IRootState | undefined, action: Action)
{
    return combineReducers({
        [fromMiscReducer.RootMiscFeatureKey]: fromMiscReducer.RootMiscReducer,
        [fromSearchReducer.RootSearchFeatureKey]: fromSearchReducer.RootSearchReducer,
        [fromImpeReducer.RootImpeFeatureKey]: fromImpeReducer.RootImpeReducer,
        [fromQuickSearchReducer.RootQuickSearchFeatureKey]: fromQuickSearchReducer.RootQuickSearchReducer,
        [RootFilterTagFeatureKey]: RootFilterTagReducer
    })(state, action);
}

const getRootState = createFeatureSelector<IRootState>(
    RootFeatureKey
);

const getRootMiscState = createSelector(
    getRootState,
    (state: IRootState) =>
        state[fromMiscReducer.RootMiscFeatureKey]
);

export const getRootVersionDeployed = createSelector(
    getRootMiscState,
    (state: fromMiscReducer.IRootMiscState) =>
        state.deployedVersion
);

export const getPopinFormDirtyStatus = createSelector(
    getRootMiscState,
    (state: fromMiscReducer.IRootMiscState) =>
        state.popinFormDirtyStatus
);

export const getUploadFileDirtyStatus = createSelector(
    getRootMiscState,
    (state: fromMiscReducer.IRootMiscState) =>
        state.uploadFileDirtyStatus
);

export const getPopinFormWithUploadFileDirtyStatus = createSelector(
    getPopinFormDirtyStatus,
    getUploadFileDirtyStatus,
    (popinFormDirtyStatus: boolean, uploadFileDirtyStatus: boolean) =>
        popinFormDirtyStatus || uploadFileDirtyStatus
);

export const getExtraDataDirtyStatus = createSelector(
    getRootMiscState,
    (state: fromMiscReducer.IRootMiscState) =>
        state.extraDataDirtyStatus
);

export const getPopinFormWithExtraDataDirtyStatus = createSelector(
    getPopinFormDirtyStatus,
    getExtraDataDirtyStatus,
    (popinFormDirtyStatus: boolean, extraDataDirtyStatus: boolean) =>
        popinFormDirtyStatus || extraDataDirtyStatus
);

export const getPopinOverStatus = createSelector(
    getRootMiscState,
    (state: fromMiscReducer.IRootMiscState) =>
        state.popinOverStatus
);

export const getFilterStatus = createSelector(
    getRootMiscState,
    (state: fromMiscReducer.IRootMiscState) =>
        state.filterStatus
);

export const getRootRangeViewerSinceUntil = createSelector(
    getRootMiscState,
    (state: fromMiscReducer.IRootMiscState) =>
        [state.since, state.until]
);

// #region Global Search
const getRootSearchState = createSelector(
    getRootState,
    (state: IRootState) =>
        state[fromSearchReducer.RootSearchFeatureKey]
);
export const getSearchValue = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.searchValue
);

export const getSearchResultsIts2RequestsState = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.searchResultIts2Requests
);
export const {
    selectAll: getIts2RequestsSearchResults,
    selectTotal: getNbIts2RequestsSearchResults,
} = fromSearchReducer.searchResultIts2RequestsAdapter.getSelectors(getSearchResultsIts2RequestsState);

export const getSearchResultsBckpJobsState = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.searchResultBckpJobs
);
export const {
    selectAll: getBckpJobsSearchResults,
    selectTotal: getNbBckpJobsSearchResults,
} = fromSearchReducer.searchResultBckpJobsAdapter.getSelectors(getSearchResultsBckpJobsState);

export const getSearchResultsBckpServersState = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.searchResultBckpServers
);
export const {
    selectAll: getBckpServersSearchResults,
    selectTotal: getNbBckpServersSearchResults,
} = fromSearchReducer.searchResultBckpServersAdapter.getSelectors(getSearchResultsBckpServersState);

export const getSearchResultsBckpPoliciesState = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.searchResultBckpPolicies
);
export const {
    selectAll: getBckpPoliciesSearchResults,
    selectTotal: getNbBckpPoliciesSearchResults,
} = fromSearchReducer.searchResultBckpPoliciesAdapter.getSelectors(getSearchResultsBckpPoliciesState);

export const getSearchResultsCompInstancesState = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.searchResultCompInstances
);
export const {
    selectAll: getCompInstancesSearchResults,
    selectTotal: getNbCompInstancesSearchResults,
} = fromSearchReducer.searchResultCompInstancesAdapter.getSelectors(getSearchResultsCompInstancesState);

export const getSearchResultsBackAuthEntitiesState = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.searchResultBackAuthEntities
);
export const {
    selectAll: getBackAuthEntitiesSearchResults,
    selectTotal: getNbBackAuthEntitiesSearchResults,
} = fromSearchReducer.searchResultBackAuthEntitiesAdapter.getSelectors(getSearchResultsBackAuthEntitiesState);
export const getSearchResultsBackAuthUsersState = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.searchResultBackAuthUsers
);
export const {
    selectAll: getBackAuthUsersSearchResults,
    selectTotal: getNbBackAuthUsersSearchResults,
} = fromSearchReducer.searchResultBackAuthUsersAdapter.getSelectors(getSearchResultsBackAuthUsersState);
// #endregion


// #region QueryString de recherche
export const getIts2RequestsSearchQueryString = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.oDataIts2RequestsSearch.qsSearch
);
export const getBckpJobsSearchQueryString = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.oDataBckpJobsSearch.qsSearch
);
export const getBckpServersSearchQueryString = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.oDataBckpServersSearch.qsSearch
);
export const getBckpPoliciesSearchQueryString = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.oDataBckpPoliciesSearch.qsSearch
);
export const getCompInstancesSearchQueryString = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.oDataCompInstancesSearch.qsSearch
);
export const getBackAuthEntitiesSearchQueryString = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.oDataBackAuthEntitiesSearch.qsSearch
);
export const getBackAuthUsersSearchQueryString = createSelector(
    getRootSearchState,
    (state: fromSearchReducer.IRootSearchState) =>
        state.oDataBackAuthUsersSearch.qsSearch
);
// #endregion

// #region Impersonate
const getRootImpersonateState = createSelector(
    getRootState,
    (state: IRootState) =>
        state[fromImpeReducer.RootImpeFeatureKey]
);
export const getRootImpersonation = createSelector(
    getRootImpersonateState,
    (state) =>
        state.impersonation
);
// #endregion

export const getChipsStatus = createSelector(
    getRootMiscState,
    (state: fromMiscReducer.IRootMiscState) =>
        state.chipsStatus
);

//#region QuickSearch
const getRootQuickSearchesState = createSelector(
    getRootState,
    (state: IRootState) =>
        state[fromQuickSearchReducer.RootQuickSearchFeatureKey].quickSearches
);
const {
    selectAll: getAllQuickSearch,
} = fromQuickSearchReducer.quickSearchesAdapter.getSelectors(getRootQuickSearchesState);
export const getQuickSearchValue = createSelector(
    getAllQuickSearch,
    (allQuickSearches: QuickSearchValue[], props: { key: string }) =>
    {
        const key = Md5Service.HexMd5(props.key.toUpperCase());
        const qsv = allQuickSearches.find((qsv: QuickSearchValue) =>
            qsv.key === key
        );

        return qsv !== undefined ? qsv.searchValue : null;
    }
);
//#endregion

//#region Warnings
export const getWarningsValue = (id: string) => createSelector(
    getRootMiscState,
    (state: fromMiscReducer.IRootMiscState): number =>
    {
        id = id.toLowerCase();
        const ret = state.warnings && state.warnings[id] ? state.warnings[id] : 0;

        return ret;
    }
);
//#endregion

export const getLastAdminUrl = createSelector(getRootMiscState,
    (state: fromMiscReducer.IRootMiscState) =>
        state.lastAdminUrl
);

export const getLastCustomerUrl = createSelector(getRootMiscState,
    (state: fromMiscReducer.IRootMiscState) =>
        state.lastCustomerUrl
);

// #region FilterTag
export const getRootFilterTagState = createSelector(
    getRootState,
    (state: IRootState): IFilterTagState =>
        state[RootFilterTagFeatureKey].filterTags
);
//#endregion
