import { Component, OnInit, Input } from '@angular/core';

import { Store } from '@ngrx/store';

import { Message } from 'src/app/_apps/notf/models';
import { IAuroraState } from 'src/app/_apps/aurora.state';
import * as fromNotfStoreActions from 'src/app/_apps/notf/store/';
import { popin_notif_item_animations } from './popin-notif-item.animations';

@Component({
    selector: 'aa-popin-notif-item',
    templateUrl: './popin-notif-item.component.html',
    styleUrls: [
        './popin-notif-item.component.scss'
    ],
    animations: popin_notif_item_animations
})
export class PopinNotifItemComponent implements OnInit
{
    isShown: boolean = true;
    @Input() message: Message;

    constructor(private store: Store<IAuroraState>) { }

    ngOnInit ()
    {
    }

    deleteMessage (): void
    {
        this.isShown = false;
    }

    showHideDone (): void
    {
        if (!this.isShown)
        {
            this.store.dispatch(fromNotfStoreActions.NotfWarnStoreActions.DeleteMessage({ id: this.message.id }));
        }
    }
}
