import { DateTime } from 'luxon';

export class CountServers
{
    public since: DateTime;
    public until: DateTime;
    public validUntil?: DateTime;
    public count: number;

    constructor ()
    {
        this.validUntil = null;
    }
}
