import { IAuroraState } from '@apps/aurora.state';
import * as fromBucketBuckState from './buck/buck.state';
import * as fromBucketObjeState from './obje/obje.state';
export const ObjsBucketFeatureKey = 'bucket';

export interface IState extends IAuroraState
{
    [ObjsBucketFeatureKey]: IObjsBucketState;
}

export interface IObjsBucketState
{
    [fromBucketBuckState.ObjsBucketBuckFeatureKey]: fromBucketBuckState.IObjsBucketBuckState;
    [fromBucketObjeState.ObjsBucketObjeFeatureKey]: fromBucketObjeState.IObjsBucketObjeState;
}

export const ObjsBucketInitialState: IObjsBucketState = {
    [fromBucketBuckState.ObjsBucketBuckFeatureKey]: fromBucketBuckState.ObjsBucketBuckInitialState,
    [fromBucketObjeState.ObjsBucketObjeFeatureKey]: fromBucketObjeState.ObjsBucketObjeInitialState
};