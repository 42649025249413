import { HttpClient, HttpHeaders, HttpEvent, HttpEventType, HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { Result204 } from '@common/api/models/result204';

import { iif, Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { UploadFileState } from '../domain/models/upload-file-state';

export abstract class BaseMiscApiService
{
    protected segmentRoot: string;
    protected segmentApi: string;

    constructor (
        protected http: HttpClient
    )
    {
    }

    protected get<T> (resource: string = '', headers?: HttpHeaders): Observable<T>
    {
        const urlFull = `${this.segmentRoot}${this.segmentApi}${resource}`;

        if (headers)
        {
            return this.http.get<T>(urlFull, { headers: headers });
        }

        return this.http.get<T>(urlFull);
    }

    protected getNoContent<T> (resource: string = '', defaultValue: T): Observable<Result204<T>>
    {
        const urlFull = `${this.segmentRoot}${this.segmentApi}${resource}`;

        return this.http.get<T>(urlFull, { observe: 'response' })
            .pipe(
                switchMap((response: HttpResponse<any>): Observable<Result204<T>> =>
                    iif(
                        (): boolean => response.status === 204,
                        of(true)
                            .pipe(
                                map((): Result204<T> =>
                                    new Result204<T>(defaultValue, true)
                                )
                            ),
                        of(false)
                            .pipe(
                                map((): Result204<T> =>
                                    new Result204<T>(response.body, false)
                                )
                            )
                    )
                )
            );
    }

    protected getFromBlob (resource: string = '', mediaType: string = ''): Observable<any>
    {
        const urlFull = `${this.segmentRoot}${this.segmentApi}${resource}`;

        const options: any = {
            responseType: 'blob' as 'json',
            reportProgress: true,
            observe: 'response'
        };

        if (mediaType)
        {
            options.headers = new HttpHeaders({
                'Content-Type': mediaType,
                'Accept': mediaType
            });
        }

        return this.http.get(urlFull, options);
    }

    protected post<T> (resource: string, payload: unknown, headers?: HttpHeaders): Observable<T>
    {
        const urlFull = `${this.segmentRoot}${this.segmentApi}${resource}`;

        if (headers !== null)
        {
            return this.http.post<T>(urlFull, payload, { headers: headers });
        }

        return this.http.post<T>(urlFull, payload);
    }

    protected put<T> (resource: string, payload: unknown, headers?: HttpHeaders): Observable<T>
    {
        const urlFull = `${this.segmentRoot}${this.segmentApi}${resource}`;

        if (headers !== null)
        {
            return this.http.put<T>(urlFull, payload, { headers: headers });
        }

        return this.http.put<T>(urlFull, payload);
    }

    protected patch<T> (resource: string, payload: unknown): Observable<T>
    {
        const urlFull = `${this.segmentRoot}${this.segmentApi}${resource}`;

        return this.http.patch<T>(urlFull, payload);
    }

    protected delete (resource: string, payload?: any): Observable<any>
    {
        const urlFull = `${this.segmentRoot}${this.segmentApi}${resource}`;

        if (payload)
        {
            return this.http.delete(urlFull, payload);
        }

        return this.http.delete(urlFull);
    }

    protected upload (resource: string, upload: File): Observable<UploadFileState>
    {
        const urlFull = `${this.segmentRoot}${this.segmentApi}${resource}`;
        const formData: FormData = new FormData();

        formData.append('file', upload, upload.name);
        const req = new HttpRequest('POST', urlFull, formData, {
            reportProgress: true
        });

        return this.http.request(req)
            .pipe(
                map((event: HttpEvent<any>) => this.getEventMessage(event, upload)),
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    protected download (resource: string, mediaType: string, body: any = { 'url': '' }): Observable<any>
    {
        const urlFull = `${this.segmentRoot}${this.segmentApi}${resource}`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': mediaType
        });

        return this.http.post<Blob>(
            urlFull,
            body,
            {
                headers: headers,
                responseType: 'blob' as 'json',
                reportProgress: true,
                observe: 'response'
            });
    }

    // private showProgress (message: string): void
    // {
    // }

    /** Return distinct message for sent, upload progress, & response events */
    private getEventMessage (event: HttpEvent<any>, file: File): UploadFileState
    {
        let percentDone: number;

        switch (event.type)
        {
            case HttpEventType.Sent:
                return { type: event.type, size: file.size, progress: 0, percent: 0 };

            case HttpEventType.UploadProgress:
                percentDone = 50 + Math.round(100 * event.loaded / event.total) / 2;

                return { type: event.type, size: file.size, progress: event.loaded, percent: percentDone };

            case HttpEventType.Response:
                return { type: event.type, size: file.size, progress: file.size, percent: 100 };

            default:
                return { type: event.type, size: file.size, progress: file.size, percent: 100 };
        }
    }

    private handleError (error: HttpErrorResponse)
    {
        if (error.error instanceof ErrorEvent)
        {
            // A client-side or network error occurred. Handle it accordingly.
            // tslint:disable-next-line: no-console
            console.error('An error occurred:', error.error.message);
        }
        else
        {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            // tslint:disable-next-line: no-console
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }

        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }
}
