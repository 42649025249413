import { RequestUrgencyCounterByDay } from '@apps/its2/domain/models';
import { FlagsMetadata } from '@common/models';
import { createSelector } from '@ngrx/store';

import { getDashboardState } from '../dashboard-selectors';
import { IIts2DashboardState } from '../dashboard.state';
import { IIts2DashReportingCountersState, IIts2DashReportingState, Its2DashReportingFeatureKey } from './reporting.state';

const getReportingState = createSelector(
    getDashboardState,
    (state: IIts2DashboardState) => state[Its2DashReportingFeatureKey]
);

const getReportingIncidents = createSelector(
    getReportingState,
    (rState: IIts2DashReportingState): IIts2DashReportingCountersState =>
        rState.incidents
);
export const getIncidentsSummaries = createSelector(
    getReportingIncidents,
    (iState: IIts2DashReportingCountersState): RequestUrgencyCounterByDay[] =>
        iState.collection
);
export const getIts2DashEvolutionIncidentsFlags = createSelector(
    getReportingIncidents,
    (iState: IIts2DashReportingCountersState): FlagsMetadata =>
        new FlagsMetadata(iState.isLoading, iState.isLoaded, iState.isEmpty, iState.isOups)
);

const getReportingServices = createSelector(
    getReportingState,
    (rState: IIts2DashReportingState): IIts2DashReportingCountersState =>
        rState.services
);
export const getServicesSummaries = createSelector(
    getReportingServices,
    (sState: IIts2DashReportingCountersState): RequestUrgencyCounterByDay[] =>
        sState.collection
);
export const getIts2DashEvolutionServicesFlags = createSelector(
    getReportingServices,
    (sState: IIts2DashReportingCountersState): FlagsMetadata =>
        new FlagsMetadata(sState.isLoading, sState.isLoaded, sState.isEmpty, sState.isOups)
);