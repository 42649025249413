import { Injectable } from '@angular/core';
import { HttpEvent } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, catchError, tap, withLatestFrom } from 'rxjs/operators';

import { RequestMiscDomainService } from '@apps/its2/domain';
import { IAuroraState } from '@apps/aurora.state';
import { RequestDocument } from '@apps/its2/domain/models';
import { FilesService } from '@common/services';

import * as fromIts2ActionssActions from './documents.actions';
import * as fromAuthStore from '@apps/auth/store';

@Injectable()
export class Its2DocumentsEffects
{
    constructor (
        private actions: Actions,
        private store: Store<IAuroraState>,
        private requestMiscDomainService: RequestMiscDomainService,
        private filesService: FilesService
    )
    { }

    its2RequestsDocumentsRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2ActionssActions.Its2RequestsDocumentsRequested),
                switchMap(({ rfcNumber: rfcNumber }: { rfcNumber: string; }) =>
                    this.requestMiscDomainService.getDocuments(rfcNumber)
                        .pipe(
                            map((requestDocuments: RequestDocument[]) =>
                                fromIts2ActionssActions.Its2RequestsDocumentsSucceeded({ requestDocuments })
                            ),
                            catchError((error: Error) =>
                                of(fromIts2ActionssActions.Its2RequestsDocumentsFailed({ error }))
                            )
                        )
                )
            )
    );

    its2RequestsDownloadDocumentRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2ActionssActions.Its2RequestsDownloadDocumentRequested),
                switchMap(({ rfcNumber: rfcNumber, physicalName: physicalName, documentName: documentName }: { rfcNumber: string; physicalName: string, documentName: string }) =>
                    this.requestMiscDomainService.getDocument(rfcNumber, physicalName)
                        .pipe(
                            map((document: HttpEvent<Blob>) =>
                                fromIts2ActionssActions.Its2RequestsDownloadDocumentSucceeded({ document, documentName })
                            ),
                            catchError((error: Error) =>
                                of(fromIts2ActionssActions.Its2RequestsDownloadDocumentFailed({ error }))
                            )
                        )
                )
            )
    );

    its2RequestsDownloadDocumentSucceeded = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromIts2ActionssActions.Its2RequestsDownloadDocumentSucceeded),
                withLatestFrom(this.store.select(fromAuthStore.getProfile)),
                tap(([{ document, documentName }, profile]) =>
                {
                    this.filesService.saveFileFromApi(document, profile, documentName);
                })
            ),
    { dispatch: false }
    );
}
