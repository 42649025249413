import { SelectOption } from 'src/app/_ui/components/form/common';

export enum InstanceStatus
{
    ACTIVE = 'ACTIVE',
    PAUSED = 'PAUSED',
    SHUTOFF = 'SHUTOFF',
    ERROR = 'ERROR',
    CREATION = 'CREATION',
    BUILD = 'BUILD'
}

export enum InstCreationSource
{
    IMAGE = 'image',
    VOLUME = 'volume',
    SNAPSHOT = 'snapshot'
}

export class InstancesConstants
{
    public static InstCreationSources: InstCreationSource[] = [
        InstCreationSource.IMAGE,
        InstCreationSource.VOLUME,
        InstCreationSource.SNAPSHOT
    ];
    public static getStatusLabelKey (status: string): string
    {
        return `OSTK.INSTANCES.STATUS.${status.toUpperCase()}`;
    }
    public static getStatusClass (status: string): string
    {
        let klass = '';

        switch (status)
        {
            case InstanceStatus.ACTIVE:
                klass = 'play';
                break;
            case InstanceStatus.PAUSED:
                klass = 'pause';
                break;
            case InstanceStatus.SHUTOFF:
                klass = 'stop';
                break;
            case InstanceStatus.ERROR:
                klass = 'exclamation-triangle';
                break;
            case InstanceStatus.CREATION:
            case InstanceStatus.BUILD:
                klass = 'spinner fa-spin';
                break;
            default:
                break;
        }

        return klass;
    }

    public static getInstanceSourcesOptions (): SelectOption[]
    {
        return InstancesConstants.InstCreationSources
            .map((s: InstCreationSource) =>
                new SelectOption(s, `OSTK.INSTANCES.CREATE.STEP1.SOURCE.${s.toUpperCase()}`)
            );
    }
}
