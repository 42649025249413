import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppConstants } from '@apps/app.constants';
import { BaseMiscApiService } from '@common/api/base-misc-api.service';

import { NetworkApi } from '../models/network';
import { CreateNetwork } from '@apps/ostk/domain/models/network';

@Injectable({
    providedIn: 'root'
})
export class NetworkMiscApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlOstk;
        this.segmentApi = '/ostk/networks';
    }

    public getNetworks (): Observable<NetworkApi[]>
    {
        return this.get<NetworkApi[]>();
    }

    public createNetwork (network: CreateNetwork): Observable<NetworkApi>
    {
        return this.post<NetworkApi>('', network);
    }

    public getNetwork (id: string): Observable<NetworkApi>
    {
        return this.get<NetworkApi>(`/${id}`);
    }

    public deleteNetwork (id: string): Observable<string>
    {
        return this.delete(`/${id}`);
    }

    public deleteSubnet (id: string, subnetId: string): Observable<string>
    {
        return this.delete(`/${id}/subnet/${subnetId}`);
    }
}
