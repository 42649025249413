import { Injectable } from '@angular/core';

import { DateTime } from 'luxon';
import { Store } from '@ngrx/store';

import * as fromRootStore from 'src/app/_apps/root/store';
import { IAuroraState } from 'src/app/_apps/aurora.state';

@Injectable({
    providedIn: 'root'
})
export class RangeService
{
    constructor (private store: Store<IAuroraState>)
    {
    }

    public setRange (since: DateTime, until: DateTime)
    {
        this.store.dispatch(fromRootStore.RootMiscStoreActions.RootRangeViewerSinceUntilSet({ since, until }));
    }

    public resetRange ()
    {
        this.store.dispatch(fromRootStore.RootMiscStoreActions.RootRangeViewerSinceUntilReset());
    }
}
