import { DateRange, LocalizedText } from '@common/models';
import { DatesService } from '@common/services/dates.service';

import { AlertApi } from '../../api/models';

export class Alert
{
    title: LocalizedText;
    text: LocalizedText;
    rangeActivation: DateRange;

    constructor (public id: string, public active: boolean)
    {
    }

    public static mapperFromApi (source: AlertApi): Alert
    {
        const target = new Alert(source.id, source.active);

        const since = DatesService.UtcStringToDateTimeHourStatic(source.rangeActivation.since);
        const until = DatesService.UtcStringToDateTimeHourStatic(source.rangeActivation.until);
        target.rangeActivation = new DateRange(since, until);

        target.title = new LocalizedText(source.title.enUsText, source.title.frFrText);
        target.text = new LocalizedText(source.text.enUsText, source.text.frFrText);

        return target;
    }
}
