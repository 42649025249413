import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { OstkFeatureKey, OstkReducers } from './ostk.reducer';
import { OstkInstancesEffects } from './instances/instances.effects';
import { OstkQuotasEffects } from './quotas/quotas.effects';
import { OstkMiscEffects } from './misc/misc.effects';
import { OstkImagesEffects } from './images/imge/imge.effects';
import { OstkVolumesVoluEffects } from './volumes/volu/volu.effects';
import { OstkVolumesSnapEffects } from './volumes/snap/snap.effects';
import { OstkProjectsEffects } from './projects/projects.effects';
import { OstkSecurityGroupsEffects } from './security/sgrp/sgrp.effects';
import { OstkKeyPairsEffects } from './security/keyp/keyp.effects';
import { OstkNetworksNetwEffects } from './networks/netw/netw.effects';
import { OstkFlavorsEffects } from './flavors/flavors.effects';
import { OstkNetworksFipsEffects } from './networks/fips/fips.effects';
import { OstkBillingEffects } from './billing/billing.effects';
import { OstkImagesSnapEffects } from './images/snap/snap.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forFeature([
            OstkInstancesEffects,
            OstkMiscEffects,
            OstkImagesEffects,
            OstkImagesSnapEffects,
            OstkQuotasEffects,
            OstkVolumesVoluEffects,
            OstkVolumesSnapEffects,
            OstkProjectsEffects,
            OstkSecurityGroupsEffects,
            OstkKeyPairsEffects,
            OstkNetworksNetwEffects,
            OstkFlavorsEffects,
            OstkNetworksFipsEffects,
            OstkBillingEffects
        ]),
        StoreModule.forFeature(
            OstkFeatureKey,
            OstkReducers
        )
    ]
})
export class OstkStoreModule
{ }
