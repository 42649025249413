import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { SelectOption } from '@ui/components/form/common';
import { RequestValuesApiService } from '../api/request-values-api.service';

@Injectable({
    providedIn: 'root'
})
export class RequestValuesDomainService extends BaseMiscDomainService
{
    constructor (
        private requestValuesApiService: RequestValuesApiService
    )
    {
        super();
    }

    getOptions (propertyName: string): Observable<SelectOption[]>
    {
        return this.requestValuesApiService.getValues(propertyName)
            .pipe(
                map((keyValues: { key: string, value: string }[]) =>
                    keyValues.map((kv: { key: string, value: string; }) =>
                        new SelectOption(kv.value, kv.key)
                    )
                )
            );
    }
}
