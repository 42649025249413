import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppConstants } from '@apps/app.constants';
import { Its2Constants } from '@apps/its2/its2.constants';
import { CatalogEntryApi } from '@apps/its2/models';
import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { CatalogEntryQuestionApi } from '@apps/its2/api/models';

@Injectable({
    providedIn: 'root'
})
export class CatalogMiscApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlIts2;
        this.segmentApi = `/${Its2Constants.BaseApi}/catalogs`;
    }

    getCatalogsIncident (): Observable<CatalogEntryApi>
    {
        return this.get('/incidents');
    }

    getCatalogsService (): Observable<CatalogEntryApi>
    {
        return this.get('/services');
    }

    getQuestions (catalogGuid: string): Observable<CatalogEntryQuestionApi[]>
    {
        return this.get(`/${catalogGuid}/questions`);
    }

    /**
     *
     * @returns {boolean} Suivant que l'utilisateur a droit ou non à ce catalogue
     */
    isSecuCatalogAuthorized (): Observable<boolean>
    {
        return this.get('/secu/authorized');
    }
}
