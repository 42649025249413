import { createAction, props } from '@ngrx/store';

import { ShortProfile } from '@apps/auth/domain/models';

export const AuthUsersRequested = createAction(
    '[AUTH-USERS] Auth Users Requested',
    props<{ userName: string }>()
);

export const AuthUsersSucceeded = createAction(
    '[AUTH-USERS] Auth Users Succeeded',
    props<{ user: ShortProfile }>()
);

export const AuthUsersFailed = createAction(
    '[AUTH-USERS] Auth Users Failed'
);
