import { TranslateService } from '@ngx-translate/core';

import { DatesService, UtilsService } from '@common/services';
import { Assetify } from '@common/domain/assetify';

import { InstanceApi } from '../../api/models/instance-api';
import { CompConstants } from '../../comp.constants';
import { Network } from '../../models/network';
import { DatastoreObject, StoragePerDatastoreObject, DiskObject, HardwareDiskObject, AvailabilityObject } from '@apps/comp/models';

export type SimplifiedInstance = Pick<Instance, 'os' | 'powerState' | 'billable' | 'anonymized'>;
export type InstancesTotal = Pick<Instance, 'wattsAverage' | 'numberCpu' | 'memorySizeMb' | 'allocatedDiskGb'>;

export class Instance extends Assetify
{
    public documentId: string;
    public tagIds: string[];
    public tagFullNames: string;
    public cluster: string;
    public path: string;
    public host: string;
    public name: string;
    public nameShort: string;
    public vcenter: string;
    public datacenter: string;
    public class: string;
    public cpuUsageAverage?: number;
    public diskUsageAverageKb?: number;
    public folder: string;
    public memorySizeMb?: number;
    public memoryUsedMb?: number;
    public memUsageAverage?: number;
    public netUsageAverageMb?: number;
    public numberCpu?: number;
    public os: string;
    public powerState: string;
    wattsAverage: number;
    public srm?: boolean;
    public toolsStatus: string;
    public toolsVersion: string;
    public totalSnapshotCount?: number;
    public totalSnapshotSizeGb?: number;
    public uptimeSeconds?: number;
    public disks: DiskObject[];
    public hardwareDisks: HardwareDiskObject[];
    public statusClass: string;
    public statusLabel: string;
    public osIconLocation: string;
    public uptime: string;
    public net: Network[];
    public datacenterLabel: string;
    public osLabel: string;
    public replicationTier: string;
    public allocatedDiskGb: number;
    public billable: boolean;
    public billableLabel: string;
    public anonymized: boolean;
    public uuid: string;
    public datastores: DatastoreObject[];
    public storagePerDatastore: StoragePerDatastoreObject[];
    public availabilityInfo: AvailabilityObject;
    public assetName = (): string => this.nameShort;

    public static mapperFromApi (
        source: InstanceApi,
        datesService: DatesService,
        translateService: TranslateService,
        language: string): Instance
    {
        const target = new Instance();
        Object.assign(target, source);

        let allocatedDiksGb = 0;
        const replications: string[] = [];
        const tiers: string[] = [];
        const splittedDatacenter: string[] = target.datacenter.split(' - ');

        target.datacenterLabel = splittedDatacenter.length > 1 ? splittedDatacenter[1] : target.datacenter;

        target.documentId = `${target.name}:${target.vcenter}`;
        target.statusClass = CompConstants.getCssStatus(target.powerState);
        target.statusLabel = CompConstants.getStatusLabel(target.powerState, translateService);
        target.osLabel = UtilsService.getOsLabel(target.os, 'default');
        target.osIconLocation = UtilsService.getFullOsPicture(target.osLabel);

        target.uptime = datesService.NumberToDuration(target.uptimeSeconds, language);

        if (target.hardwareDisks)
        {
            target.hardwareDisks.forEach(hd =>
            {
                const infos = hd.datastoreName.split('_');

                hd.replication = infos[3] ? infos[3] : '';
                hd.tier = infos[2];

                if (hd.replication && !replications.includes(hd.replication))
                {
                    replications.push(hd.replication);
                }

                if (hd.tier && !tiers.includes(hd.tier))
                {
                    tiers.push(hd.tier);
                }

                if (hd.capacityGb)
                {
                    allocatedDiksGb += hd.capacityGb;
                }
            });
        }

        target.replicationTier = CompConstants.getReplicationTier(replications, tiers);
        target.allocatedDiskGb = allocatedDiksGb;
        target.billableLabel = CompConstants.getBillableLabel(target.billable, translateService);

        return target;
    }
}
