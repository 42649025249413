import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { on, createReducer } from '@ngrx/store';

import * as fromRootImpersonateActions from '@apps/root/store/impersonate/impe.actions';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';

import * as fromSelectedActions from './selected.actions';
import { SelectedRequest } from '../../domain/models/request';

export const Its2SelectedFeatureKey = 'selected';

export type IIts2SelectedState = EntityState<SelectedRequest>;

export const selectedAdapter: EntityAdapter<SelectedRequest> = createEntityAdapter<SelectedRequest>({
    selectId: (rq: SelectedRequest) => rq.rfcNumber
});

const selectedInitialState: IIts2SelectedState = selectedAdapter.getInitialState({
});

export const Its2SelectedReducer = createReducer(
    selectedInitialState,
    on(
        fromRootImpersonateActions.RootImpersonateSet,
        fromRootImpersonateActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        () => selectedInitialState
    ),
    on(
        fromSelectedActions.Its2RequestsSelectedAddMany,
        (state, { requests }: { requests: SelectedRequest[]; }) =>
            selectedAdapter.addMany(requests, state)
    ),
    on(
        fromSelectedActions.Its2RequestsSelectedAddOne,
        (state, { request }: { request: SelectedRequest; }) =>
            selectedAdapter.addOne(request, state)
    ),
    on(
        fromSelectedActions.Its2RequestsSelectedRemoveMany,
        (state, { requests }: { requests: SelectedRequest[]; }) =>
            selectedAdapter.removeMany(requests.map((rq) => rq.rfcNumber), state)
    ),
    on(
        fromSelectedActions.Its2RequestsSelectedRemoveOne,
        (state, { request }: { request: SelectedRequest; }) =>
            selectedAdapter.removeOne(request.rfcNumber, state)
    ),
    on(
        fromSelectedActions.Its2RequestsSelectedCleared,
        (state) =>
            selectedAdapter.removeAll(state)
    )
);
