import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartComponent } from './chart.component';
import { AxisComponent } from './axis/axis.component';
import { ListeningZoneComponent } from './listening-zone/listening-zone.component';
import { TitleComponent } from './title/title.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { LineComponent } from './line/line.component';
import { CirclesComponent } from './circles/circles.component';
import { LegendComponent } from './legend/legend.component';
import { GridHorComponent } from './grid/grid-hor/grid-hor.component';
import { GridVerComponent } from './grid/grid-ver/grid-ver.component';
import { ColumnsComponent } from './columns/columns.component';
import { ColumnGroupComponent } from './columns/column-group/column-group.component';
import { ColumnBarComponent } from './columns/column-bar/column-bar.component';
import { BarBadgeComponent } from './bar-badge/bar-badge.component';

@NgModule({
    declarations: [
        ChartComponent,
        AxisComponent,
        ListeningZoneComponent,
        TitleComponent,
        TooltipComponent,
        LineComponent,
        CirclesComponent,
        LegendComponent,
        GridHorComponent,
        GridVerComponent,
        ColumnsComponent,
        ColumnGroupComponent,
        ColumnBarComponent,
        BarBadgeComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ChartComponent,
        AxisComponent,
        ListeningZoneComponent,
        TitleComponent,
        TooltipComponent,
        LineComponent,
        LegendComponent,
        GridHorComponent,
        GridVerComponent,
        BarBadgeComponent
    ]
})
export class ComponentsChartModule
{ }
