import { AppConstants } from '@apps/app.constants';
import { CatalogEntryQuestionApi } from '@apps/its2/api/models';
import { MetaField } from '@common/domain/models/forms/meta-field';
import { Languages } from '@common/enum';
import { LocalizedText } from '@common/models';
import { SelectOption2 } from '@ui/components/form/common/select-option';

// Type de quetion en provenance d'EZV
export type QuestionType = 'text' | 'list' | 'memo' | 'date' | 'radio' | 'checkbox';
// Type de question en interne
export const enum EnumTypeQuestion {
    TEXT = 'text',
    LIST = 'list',
    EDITOR = 'editor',
    DATE = 'date',
    RADIO = 'radio',
    CHECKBOX = 'checkbox'
}

export class QuestionListItem
{
    '@index': string;
    'EN': string;
    'FR': string;
}

export class QuestionDefinition
{
    list: {
        '@data-type': string;
        '@display-item-label': string;
        '@selected-type': string;
        'string': QuestionListItem[];
    };
    radio: {
        '@data-type': string;
        '@display-item-label': string;
        '@selected-type': string;
        'string': QuestionListItem[];
    };
}
export class CatalogEntryQuestion extends MetaField
{
    questionGuid: string;
    endpointGuid: string;
    hasEndpoint: boolean;
    definition: QuestionDefinition;
    options: SelectOption2<string>[];
}

export class DomainCalalogEntryQuestion
{
    public static mapperFromApi (source: CatalogEntryQuestionApi, language: string): CatalogEntryQuestion
    {
        const target = new CatalogEntryQuestion();

        target.id = source.questionId;

        target.type = DomainCalalogEntryQuestion.mapDataType(source.type as QuestionType);
        target.questionGuid = source.questionGuid.toUpperCase();
        target.name = target.questionGuid;
        target.endpointGuid = source.endpointGuid.toUpperCase();
        target.hasEndpoint = target.endpointGuid !== AppConstants.NullGuid;
        target.label = {
            [Languages.frFr]: source.questionFr,
            [Languages.enUs]: source.questionEn,
        } as LocalizedText;
        target.percentWidth = 100;
        target.required = source.mandatory;
        target.definition = source.definition;
        if (target.type === EnumTypeQuestion.RADIO)
        {
            target.options = target.definition.radio.string
                .map((value: QuestionListItem) =>
                    new SelectOption2(value['@index'], value[language])
                );
        }

        return target;
    }

    private static mapDataType (srcType: string): EnumTypeQuestion
    {
        switch (srcType)
        {
            case 'text':
                return EnumTypeQuestion.TEXT;

            case 'list':
                return EnumTypeQuestion.LIST;

            case 'memo':
                return EnumTypeQuestion.EDITOR;

            case 'date':
                return EnumTypeQuestion.DATE;

            case 'radio':
                return EnumTypeQuestion.RADIO;
        }
    }
}
