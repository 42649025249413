import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { Configuration } from '@common/models/configuration';
import * as AppGlobals from '@apps/globals';

import { AppModule } from './app/app.module';

const req = new XMLHttpRequest();
req.onreadystatechange = function ()
{
    if (this.readyState === XMLHttpRequest.DONE)
    {
        if (req.status === 200)
        {
            const config: Configuration = JSON.parse(req.responseText);
            Object.assign(AppGlobals.config, config);
            if (config.stage.toLowerCase() === 'production')
            {
                enableProdMode();
            }

            platformBrowserDynamic()
                .bootstrapModule(AppModule)
                // tslint:disable-next-line: no-console
                .catch(err => console.log(err));
        }
        else
        {
            console.log('Status de la réponse: %d (%s)', req.status, req.statusText);
        }
    }
};
req.open('GET', 'assets/configuration.json', false);
req.send(null);
