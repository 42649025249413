import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'aa-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: [
        './page-not-found.component.scss'
    ]
})
export class PageNotFoundComponent
{
    constructor (private location: Location)
    { }

    click ()
    {
        this.location.back();
    }
}
