import { Status } from './status';
import { JobPerPolicyApi } from '../../api/models/job-per-policy-api';
import { StatusApi } from '../../api/models/status-api';
import { StatusPerPolicyType } from './status-per-policy-type';

export class JobPerPolicy extends StatusPerPolicyType
{
    public counter: number;
    public groupByCodeStatuses: Status[];

    public static mapperFromApi (source: JobPerPolicyApi): JobPerPolicy
    {
        const target = new JobPerPolicy(null, null, null);

        Object.assign(target, source);

        target.statuses = source.statuses.map((statusApi: StatusApi) =>
            Status.mapperFromApi(statusApi)
        );

        return target;
    }

    public groupByCodeStatus (): void
    {
        const init: Status[] = [0, 1, 2].map((idx: number) => new Status(idx, 0, idx));

        this.groupByCodeStatuses = this.statuses.reduce(
            (prev: Status[], curr: Status) =>
            {
                prev[curr.codeStatus].counter += curr.counter;

                return prev;
            },
            init
        );
    }
}
