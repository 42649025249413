import { createAction, props } from '@ngrx/store';

import { RequestDocument } from '@apps/its2/domain/models';
import { HttpEvent } from '@angular/common/http';

export const Its2RequestsDocumentsRequested = createAction(
    '[ITS2-REQUEST] Its2 Requests Documents Requested',
    props<{ rfcNumber: string; }>()
);

export const Its2RequestsDocumentsSucceeded = createAction(
    '[ITS2-REQUEST] Its2 Requests Documents Succeeded',
    props<{ requestDocuments: RequestDocument[]; }>()
);

export const Its2RequestsDocumentsFailed = createAction(
    '[ITS2-REQUEST] Its2 Requests Documents Failed',
    props<{ error: any; }>()
);

export const Its2RequestsDownloadDocumentRequested = createAction(
    '[ITS2-REQUEST] Its2 Requests Download Document Requested',
    props<{ rfcNumber: string, physicalName: string, documentName: string }>()
);

export const Its2RequestsDownloadDocumentSucceeded = createAction(
    '[ITS2-REQUEST] Its2 Requests Download Document Succeeded',
    props<{ document: HttpEvent<Blob>, documentName: string }>()
);

export const Its2RequestsDownloadDocumentFailed = createAction(
    '[ITS2-REQUEST] Its2 Requests Download Document Failed',
    props<{ error: any }>()
);
