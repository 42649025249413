import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, flatMap, take } from 'rxjs/operators';

import { IAuroraState } from '@apps/aurora.state';
import { Project } from '@apps/ostk/domain/models';
import { OstkConstants } from '@apps/ostk/ostk.constants';
import * as fromOstkStore from '@apps/ostk/store';

@Injectable()
export class OstkInterceptor implements HttpInterceptor
{
    constructor (
        private store: Store<IAuroraState>
    )
    {
    }

    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        if (!req.url.includes(OstkConstants.BaseApi))
        {
            return next.handle(req);
        }

        if (req.url.endsWith('platforms'))
        {
            return next.handle(req);
        }

        if (req.url.endsWith('projects'))
        {
            return next.handle(req);
        }

        return this.store
            .pipe(
                select(fromOstkStore.getSelectedProject),
                filter((project: Project) =>
                    project !== null
                ),
                take(1),
                flatMap((project: Project) =>
                {
                    const platformId = project.platformId;
                    const projectName = project.projectName;

                    return next.handle(
                        req.clone({
                            url: req.url.replace('/ostk/', `/ostk/${platformId}/${projectName}/`)
                        })
                    );
                })
            );
    }
}
