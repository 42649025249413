import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { Subject, throwError } from 'rxjs';
import { tap, catchError, first, takeUntil, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from '@apps/auth/api/authentication.service';
import { IAuroraState } from '@apps/aurora.state';
import * as fromAuthStore from '@apps/auth/store';
import { LoginResult } from '@apps/auth/models/loginResult';

@Component({
    selector: 'aa-login',
    templateUrl: './login.component.html',
    styleUrls: [
        './login.component.scss'
    ]
})
export class LoginComponent implements OnInit, OnDestroy
{
    private destroyator = new Subject();
    email: string = '';
    link: string = `http://localhost:4200/auth/login;email=${this.email}`;
    form: UntypedFormGroup;
    msgError: string;
    public fields = [
        {
            'name': 'loginEmail',
            'type': 'email',
            'label': 'Saisissez votre email',
            'value': '',
            'required': true,
            'validators': []
        }, {
            'name': 'loginPassword',
            'type': 'password',
            'label': 'Saisissez votre mot de passe',
            'value': '',
            'required': true,
            'validators': []
        }
    ];
    private controls = {};
    public keyUp = new Subject<string>();

    constructor (
        private store: Store<IAuroraState>,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        private route: ActivatedRoute
    )
    {
        this.createForm();
    }

    ngOnInit ()
    {
        this.route.paramMap
            .pipe(
                take(1),
                tap((params) =>
                {
                    const email = params.get('email');

                    if (email !== null)
                    {
                        this.email = email;

                        // ! TODO Supprimer ce hardcoded host/port
                        // this.link = `http://localhost:4200/auth/login;email=${this.email}`;
                    }
                    // else
                    // {
                    //     this.link = 'http://localhost:4200/auth/login;email=<your email address>';
                    // }

                    this.fields[0].value = this.email;


                    this.store.dispatch(fromAuthStore.AuthLoginStoreActions.AuthLoginEntered({
                        email: this.email,
                        firstName: params.get('firstName'),
                        lastName: params.get('lastName')
                    }));
                })
            )
            .subscribe();
        this.translate.get(['AUTH.FORM.EMAIL.LABEL', 'AUTH.FORM.PASSWORD.LABEL'])
            .pipe(
                first(),
                tap((results: string[]) =>
                {
                    this.fields[0].label = results['AUTH.FORM.EMAIL.LABEL'];
                    this.fields[1].label = results['AUTH.FORM.PASSWORD.LABEL'];
                    this.createForm();
                }),
                takeUntil(this.destroyator)
            )
            .subscribe();
        this.form.get('loginEmail').valueChanges
            .pipe(
                tap(() =>
                {
                    this.email = this.form.get('loginEmail').value;
                    this.link = `http://localhost:4200/auth/login;email=${this.email}`;
                }),
                takeUntil(this.destroyator)
            )
            .subscribe();
    }

    // ngOnChanges ()
    // {
    //     this.rebuildForm();
    // }

    ngOnDestroy (): void
    {
        this.store.dispatch(fromAuthStore.AuthLoginStoreActions.AuthLogging({ isLogging: false }));
        this.destroyator.next(null);
        this.destroyator.complete();
    }

    createForm ()
    {
        this.fields.forEach((f) =>
        {
            if (f.required)
            {
                f.validators.push(Validators.required);
            }

            this.controls[f.name] = new UntypedFormControl(f.value, f.validators);
        });
        this.form = new UntypedFormGroup(this.controls);
    }

    // rebuildForm ()
    // {
    //     this.form.reset({
    //         loginEmail: '',
    //         loginPassword: ''
    //     });
    // }

    onSubmit = () =>
    {
        if (this.form.invalid)
        {
            for (const control in this.form.controls)
            {
                this.form.controls[control].markAsDirty();
            }

            return;
        }

        this.store.dispatch(fromAuthStore.AuthLoginStoreActions.AuthLogging({ isLogging: true }));

        this.authenticationService.login(this.form.get('loginEmail').value, this.form.get('loginPassword').value)
            .pipe(
                first(),
                tap((loginResult: LoginResult) =>
                {
                    this.msgError = '';

                    if (!loginResult.twoFactorRequired)
                    {
                        this.store.dispatch(fromAuthStore.AuthLoginStoreActions.AuthLoggedIn());
                    }
                    else
                    {
                        this.store.dispatch(fromAuthStore.AuthLoginStoreActions.AuthTwoFactorRequested(
                            {
                                idToken: loginResult.tokens.idToken
                            })
                        );
                    }
                }),
                catchError((err) =>
                {
                    this.store.dispatch(fromAuthStore.AuthLoginStoreActions.AuthLogging({ isLogging: false }));

                    if (err.status === 401)
                    {
                        this.msgError = this.translate.instant('AUTH.FORM.ERROR.BADIDENTIFIERS');
                    }
                    else
                    {
                        this.msgError = this.translate.instant('AUTH.FORM.ERROR.GLOBAL');
                    }

                    return throwError(err);
                }),
                takeUntil(this.destroyator)
            )
            .subscribe();
    };

    required (fieldName: string)
    {
        return (
            this.form.get(fieldName).hasError('required') &&
            this.form.get(fieldName).touched
        );
    }

    forgottenPassword ()
    {
        this.store.dispatch(fromAuthStore.AuthLoginStoreActions.AuthPasswordForgotten());
    }

    createAccount ()
    {
        this.store.dispatch(fromAuthStore.AuthAccountStoreActions.AuthAccountCreateStep1Enter());
    }
}
