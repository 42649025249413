import { DateTime } from 'luxon';

import { PolicyApi } from '../../api/models';
import { BckpConstants } from '../../bckp.constants';
import { DatesService, UtilsService } from 'src/app/_common/services';
import { CompareLowerString2 } from '@common/services/utils.service';
import { Assetify } from '@common/domain/assetify';

export class Policy extends Assetify
{
    documentId: string;
    storeId: string;
    policyName: string;
    policyType: string;
    clientType: string;
    master: string;
    active: boolean;
    statusClass: string;
    collectTime: DateTime;
    servers: DetailedServer[];
    serversShortList: string;
    metaStatusKey: string;
    public assetName = (): string => this.policyName;

    public static mapperFromApi (source: PolicyApi): Policy
    {
        const target = new Policy();

        Object.assign(target, source);

        target.storeId = UtilsService.hash(source.documentId);
        target.statusClass = BckpConstants.getCssActiveStatus(source.active);
        target.metaStatusKey = `BACKUP.POLICIES.FILTER.METASTATUSES.${source.active ? 'ACTIVE' : 'INACTIVE'}`;

        target.collectTime = DatesService.UtcStringToDateTimeHourStatic(source.collectTime);
        target.servers.sort(CompareLowerString2<DetailedServer>((server: DetailedServer) => server.serverShort));
        target.serversShortList = target
            .servers
            // .slice(0, BckpConstants.MAX_SERVER_PER_POLICY)
            .map(s => s.serverShort)
            .join(', ');

        return target;
    }
}

export class PolicyDetails extends Policy
{
    includes: string[];
    excludes: string[];
    schedules: SchedulesDetails[];

    public static mapperFromApi (source: PolicyApi): PolicyDetails
    {
        const target = new PolicyDetails();

        Object.assign(target, Policy.mapperFromApi(source));

        target.storeId = UtilsService.hash(source.documentId);
        target.statusClass = BckpConstants.getCssActiveStatus(source.active);
        target.metaStatusKey = `BACKUP.POLICIES.FILTER.METASTATUSES.${source.active ? 'ACTIVE' : 'INACTIVE'}`;

        target.collectTime = DatesService.UtcStringToDateTimeHourStatic(source.collectTime);

        return target;
    }
}

export interface DetailedServer {
    server: string;
    serverShort: string;
    system: string;
    extInfo: string;
    priority: number;
}

export interface SchedulesDetails {
    name: string;
    type: string;
    typeInteger: number;
    residences: string[];
    retention: number;
}
