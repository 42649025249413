import { Predicate } from './in-memory-filter';

export abstract class Field4Filter<T, U>
{
    public prop: string;
    public value: U;

    abstract process (): Predicate<T>;
}

export class NumberField4Filter<T> extends Field4Filter<T, number>
{

    constructor(prop: string, value: number)
    {
        super();

        this.prop = prop;
        this.value = value;
    }

    process (): Predicate<T>
    {
        return ((v: T) => v[this.prop] === this.value) as Predicate<T>;
    }
}

export class NumberArrayField4Filter<T> extends Field4Filter<T, number[]>
{

    constructor(prop: string, value: number[])
    {
        super();

        this.prop = prop;
        this.value = value;
    }

    process (): Predicate<T>
    {
        return ((v: T) => this.value.includes(v[this.prop])) as Predicate<T>;
    }
}

export class BooleanField4Filter<T> extends Field4Filter<T, boolean>
{
    constructor(prop: string, value: boolean)
    {
        super();

        this.prop = prop;
        this.value = value;
    }

    process (): Predicate<T>
    {
        return ((v: T) => v[this.prop] === this.value) as Predicate<T>;
    }
}

export class StringField4Filter<T> extends Field4Filter<T, string>
{

    constructor(prop: string, value: string)
    {
        super();

        this.prop = prop;
        this.value = value;
    }

    process (): Predicate<T>
    {
        return ((v: T) => v[this.prop] === this.value) as Predicate<T>;
    }
}

export class StringArrayField4Filter<T> extends Field4Filter<T, string[]>
{

    constructor(prop: string, value: string[])
    {
        super();

        this.prop = prop;
        this.value = value;
    }

    process (): Predicate<T>
    {
        return ((v: T) => this.value.includes(v[this.prop])) as Predicate<T>;
    }
}

