import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { FilterTag } from '@apps/cmdb/models';

export const RootFilterTagFeatureKey = 'tags';
export type IFilterTagState = EntityState<FilterTag>;
export const filterTagAdapter: EntityAdapter<FilterTag> = createEntityAdapter({
    selectId: (filterTag: FilterTag): string =>
        filterTag.tagId
});

export interface IRootFilterTagState
{
    filterTags: IFilterTagState;
}

export const filterTagInitialState: IRootFilterTagState = {

    filterTags: filterTagAdapter.getInitialState({})
};
