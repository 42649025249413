import { createSelector } from '@ngrx/store';

import { FlagsMetadata } from '@common/models';
import { RequestsCatalogCounter } from '@apps/its2/domain/models';
import { getDashboardState } from '../dashboard-selectors';
import { IIts2DashboardState } from '../dashboard.state';
import { IIts2DashBreakdownCountersState, IIts2DashBreakdownState, Its2DashBreakdownFeatureKey } from './breakdown.state';

const getBreakdownState = createSelector(
    getDashboardState,
    (state: IIts2DashboardState) => state[Its2DashBreakdownFeatureKey]
);

const getBreakdownIncidents = createSelector(
    getBreakdownState,
    (bState: IIts2DashBreakdownState) => bState.incidents
);
export const getIncidentsCounters = createSelector(
    getBreakdownIncidents,
    (iState: IIts2DashBreakdownCountersState): RequestsCatalogCounter[] => iState.collection
);
export const getIts2DashReportingIncidentsFlags = createSelector(
    getBreakdownIncidents,
    (iState: IIts2DashBreakdownCountersState): FlagsMetadata =>
        new FlagsMetadata(iState.isLoading, iState.isLoaded, iState.isEmpty, iState.isOups)
);

const getBreakdownServices = createSelector(
    getBreakdownState,
    (bState: IIts2DashBreakdownState) => bState.services
);
export const getIts2DashReportingServicesFlags = createSelector(
    getBreakdownServices,
    (sState: IIts2DashBreakdownCountersState): FlagsMetadata =>
        new FlagsMetadata(sState.isLoading, sState.isLoaded, sState.isEmpty, sState.isOups)
);
export const getServicesCounters = createSelector(
    getBreakdownServices,
    (sState: IIts2DashBreakdownCountersState): RequestsCatalogCounter[] => sState.collection
);