import { RequestsCatalogCounter } from '@apps/its2/domain/models';
import { StatusesMetadata } from '@common/models';

export const Its2DashBreakdownFeatureKey = 'breakdown';

export interface IIts2DashBreakdownCountersState extends StatusesMetadata
{
    collection: RequestsCatalogCounter[]
}
export const Its2DashBreakdownCountersInitialState: IIts2DashBreakdownCountersState = {
    collection: [],
    ...StatusesMetadata.init()
};


export interface IIts2DashBreakdownState
{
    incidents: IIts2DashBreakdownCountersState;
    services: IIts2DashBreakdownCountersState;
}

export const Its2DashBreakdownInitialState: IIts2DashBreakdownState =
{
    incidents: Its2DashBreakdownCountersInitialState,
    services: Its2DashBreakdownCountersInitialState
};

