import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface IRootRouterState
{
    router: fromRouter.RouterReducerState<any>;
}

export const selectRouter = createFeatureSelector<
    IRootRouterState,
    fromRouter.RouterReducerState<any>
>('router');

export const {
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data
    selectUrl, // select the current url
} = fromRouter.getSelectors(selectRouter);

export const selectRouteId = selectRouteParam('id');
export const selectStatus = selectQueryParam('status');

export const getRouteInfos = createSelector(
    selectUrl,
    selectRouteData,
    (url, data: any): { url: string, data: any } =>
        ({
            url,
            data
        })
);
