import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import * as fromCmdbStoreTagsActions from '@apps/cmdb/store/tags/tags.actions';
import { IAuroraState } from '@apps/aurora.state';

import { Tokens } from '../models/tokens';
import { AuthConstants } from '../auth.constants';
import { AppConstants } from '../../app.constants';

@Injectable({
    providedIn: 'root'
})
export class TokenService extends BaseMiscApiService
{
    private helper: JwtHelperService;

    constructor (
        http: HttpClient,
        private store: Store<IAuroraState>,
        private router: Router
    )
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlAuth;
        this.segmentApi = '/auth';
        this.helper = new JwtHelperService();
    }

    saveToken (tokenResponse: Tokens): void
    {
        localStorage.setItem(AuthConstants.TokenKey, JSON.stringify(tokenResponse));
    }

    getAccessToken (): string
    {
        return this.getToken('accessToken');
    }

    getRefreshToken (): string
    {
        return this.getToken('refreshToken');
    }

    getNewToken (): Observable<string>
    {
        const rt = this.getRefreshToken();

        if (rt === null)
        {
            return of(null);
        }

        return this.post<Tokens>('/accounts/refresh/', { refreshToken: rt })
            .pipe(
                map((tr: Tokens) =>
                {
                    this.saveToken(tr);

                    return tr.accessToken;
                }),
                catchError(() =>
                {
                    this.router.navigate([AppConstants.LoginUrl]);

                    return of(null);
                })
            );
    }

    isAccessTokenValid (): boolean
    {
        const token: string = this.getAccessToken();

        return token !== null ? !this.helper.isTokenExpired(token) : false;
    }

    logout (): Observable<boolean>
    {
        localStorage.removeItem(AuthConstants.TokenKey);

        return of(true);
        // if (this.getAccessToken() !== null)
        // {
        //     const observ = this.post<boolean>('/accounts/logout', {});

        //     observ.subscribe(() =>
        //         localStorage.removeItem(AuthConstants.TokenKey)
        //     );

        //     return observ;
        // } else
        // {
        //     localStorage.removeItem(AuthConstants.TokenKey);

        //     return of(true);
        // }
    }

    private getToken (key: string): string
    {
        const str = localStorage.getItem(AuthConstants.TokenKey);
        const obj = JSON.parse(str);
        let token: string = null;

        if (obj !== null)
        {
            token = obj[key];
        }
        else
        {
            this.store.dispatch(fromCmdbStoreTagsActions.CmdbTagsListStopRequested());
        }

        return token;
    }
}
