import { SearchResult } from './search-result';
import { Policy } from 'src/app/_apps/bckp/domain/models';
import { BckpConstants } from 'src/app/_apps/bckp/bckp.constants';
import { OpenPopinOriginator } from '../../enum/open-popin-originator.enum';
import { TypePopin } from '@apps/app.constants';

export class BckpPolicySearchResult extends SearchResult<Policy>
{
    constructor (policy: Policy)
    {
        super('bckp-policy', policy.documentId);

        this.result = policy;
        this.icon = 'fal fa-server';

        this.statusText = policy.active ? 'active' : 'inactive';
        this.statusClass = policy.active ? 'active' : 'inactive';

        this.link = {
            baseUrlFragment: `/${BckpConstants.Bckp}/${BckpConstants.Policies}`,
            outlet: TypePopin.DETAILS,
            extraUrlFragment: OpenPopinOriginator.ROOT_SEARCH,
            params: [policy.documentId]
        };
    }
}
