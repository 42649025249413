import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, take } from 'rxjs/operators';

import { IAuroraState } from 'src/app/_apps/aurora.state';
import { AuthLoginStoreActions } from 'src/app/_apps/auth/store';

@Component({
    selector: 'aa-forgotten-password',
    templateUrl: './forgotten-password.component.html',
    styleUrls: [
        './forgotten-password.component.scss'
    ]
})
export class ForgottenPasswordComponent implements OnInit
{
    form: UntypedFormGroup;
    error: boolean;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private store: Store<IAuroraState>
    )
    {
        this.route.paramMap
            .pipe(
                take(1),
                tap((params: ParamMap) =>
                {
                    this.error = params.get('result') === 'false';
                })
            )
            .subscribe();
    }

    ngOnInit ()
    {
        this.createForm();
    }

    createForm ()
    {
        this.form = this.formBuilder.group({
            email: ['', Validators.required],
            lang: 'en-us'
        });
    }

    onSubmit = () =>
    {
        if (this.form.invalid)
        {
            for (const control in this.form.controls)
            {
                this.form.controls[control].markAsDirty();
            }

            return;
        }

        this.store.dispatch(AuthLoginStoreActions.AuthPasswordResetSendingLink({ email: this.form.get('email').value }));
    }
}
