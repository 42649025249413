import { Component, Injector, forwardRef } from '@angular/core';
import {
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl
} from '@angular/forms';

import { InputBase } from '../../../base/input-base';

@Component({
    selector: 'aa-input-phone',
    templateUrl: './input-phone.component.html',
    styleUrls: [
        '../input.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputPhoneComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputPhoneComponent),
            multi: true,
        }
    ]
})
export class InputPhoneComponent extends InputBase<string>
{
    // * https://regexr.com/4lnuf
    private static phoneRegexp = /^[-+ .\d]*$/;
    private static identifier: number = 0;

    constructor(
        injector: Injector
    )
    {
        super(injector);

        this.fieldId = `input-phone-${InputPhoneComponent.identifier++}-id`;
    }

    validate ({ value }: UntypedFormControl)
    {
        if (value)
        {
            return value.match(InputPhoneComponent.phoneRegexp) ? null : { 'phoneNumber': true };
        }
    }
}
