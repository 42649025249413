import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseListDomainService } from '@common/domain/base-list-domain.service';
import { ResultDomain } from '@common/domain/result-domain';
import { ResultApi } from '@common/api/result-api';
import { LanguageService } from '@common/services';
import { Its2RequestSearchResult, SearchResult } from '@common/models/search-result';

import { RequestListcApiService } from '../api';
import { DomainRequest, Request } from './models';
import { RequestApi } from '../api/models';

@Injectable({
    providedIn: 'root'
})
export class RequestListDomainService extends BaseListDomainService
{
    constructor (
        private requestListApiService: RequestListcApiService,
        private languageService: LanguageService
    )
    {
        super();
    }

    getRequests (qs: string): Observable<ResultDomain<Request>>
    {
        return this.requestListApiService.getRequests(qs)
            .pipe(
                map((resultApi: ResultApi<RequestApi>) =>
                    ({
                        count: resultApi['@odata.count'],
                        value: resultApi.value
                            .map<Request>((requestApi: RequestApi) =>
                            DomainRequest.mapperFromApi(requestApi, this.languageService.language)
                        )
                    }) as ResultDomain<Request>
                )
            );
    }

    search (qs: string): Observable<SearchResult<Request>[]>
    {
        return this.requestListApiService.getRequests(qs)
            .pipe(
                map((obj: ResultApi<RequestApi>): RequestApi[] =>
                    obj.value
                ),
                map((requests: RequestApi[]) =>
                    requests
                        .map(this.searchMapperFromApi, this)
                        .slice(0, 7)
                )
            );
    }

    private searchMapperFromApi (requestApi: RequestApi): Its2RequestSearchResult
    {
        return new Its2RequestSearchResult(DomainRequest.mapperFromApi(requestApi, this.languageService.language));
    }
}
