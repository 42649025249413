import { Pipe, PipeTransform } from '@angular/core';

import { Bytes } from 'src/app/_common/enum';
import { BytesService } from 'src/app/_common/services';

@Pipe({
    name: 'formatToBestUnit'
})
export class FormatToBestUnitPipe implements PipeTransform
{
    constructor (
        private bytesService: BytesService
    )
    {
    }

    /**
     *
     * @param value La valeur par défaut en bytes à représenter
     * @param args
     */
    transform (value: number, sourceUnit: Bytes = Bytes.BYTE, decPart: number = 2): string
    {
        if (value === undefined || value === null)
        {
            return '';
        }

        return this.bytesService.formatToBestUnit(value, sourceUnit, decPart);
    }
}

@Pipe({
    name: 'convertAndFormatUnit'
})
export class ConvertAndFormatUnitPipe implements PipeTransform
{
    constructor (
        private bytesService: BytesService
    )
    {
    }

    /**
     *
     * @param value La valeur par défaut en bytes à représenter
     * @param args
     */
    transform (value: number, sourceUnit: Bytes = Bytes.BYTE, targetUnit: Bytes = Bytes.GIBI, decPart: number = 2): string
    {
        if (value === undefined || value === null)
        {
            return '';
        }

        return this.bytesService.convertAndFormat(value, sourceUnit, targetUnit, decPart);
    }
}
