import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';

import * as fromJobsActions from './jobs.actions';
import { Job, JobDetails } from '../../domain/models';
import { ODataBckpJobs } from './odata-bckp-jobs';
import { BckpConstants } from '../../bckp.constants';
import { JobsFilter } from '../../models';
import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import * as fromRootStore from '@apps/root/store';

export const BckpJobsFeatureKey = 'jobs';

export type IJobsState = EntityState<Job>;

export const jobsAdapter: EntityAdapter<Job> = createEntityAdapter<Job>({
    selectId: (job: Job) => job.storeId
});

export const jobsInitialState: IJobsState = jobsAdapter.getInitialState({
});

type IJobsDetailsState = EntityState<JobDetails>;

export const jobsDetailsAdapter: EntityAdapter<JobDetails> = createEntityAdapter<JobDetails>({
    selectId: (jobDetails: JobDetails) => jobDetails.storeId
});

const jobDetailsInitialState: IJobsDetailsState = jobsDetailsAdapter.getInitialState({
});
export interface IBckpJobsState
{
    detailedJobs: IJobsDetailsState;
    selectedJob?: JobDetails;
    oData: ODataBckpJobs;
    collection: IJobsState;
    qsWord: string;
    isLoading: boolean;
    isLoaded: boolean;
    isEmpty: boolean;
    isOups: boolean;
}

const initialState: IBckpJobsState = {
    isLoading: true,
    detailedJobs: jobDetailsInitialState,
    selectedJob: null,
    oData: new ODataBckpJobs(BckpConstants.QSJobsTop, new JobsFilter()),
    collection: jobsInitialState,
    qsWord: '',
    isLoaded: false,
    isEmpty: false,
    isOups: false
};

export const BckpJobsReducer = createReducer(
    initialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        (): IBckpJobsState => initialState
    ),
    on(
        fromJobsActions.BckpJobsODataInitialized,
        (state, { scrollSize, filter }) => ({
            ...state,
            oData: new ODataBckpJobs(scrollSize, filter)
        })
    ),
    on(
        fromJobsActions.BckpJobsODataLoaded,
        (state, { dataLoaded }) =>
        {
            const o = state.oData;

            o.nbItems = dataLoaded.count;

            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                isEmpty: o.nbItems === 0,
                oData: o,
                collection: jobsAdapter.addMany(dataLoaded.value, state.collection)
            };
        }
    ),
    on(
        fromJobsActions.BckpJobsODataFailed,
        (state) => ({
            ...state,
            isLoading: false,
            isLoaded: false,
            isOups: true,
            collection: jobsInitialState
        })
    ),
    on(
        fromJobsActions.BckpJobsODataIncremented,
        (state) =>
        {
            const o = state.oData;

            o.incr();

            return {
                ...state,
                oData: o
            };
        }
    ),
    on(
        fromJobsActions.BckpJobsFilterApplied,
        (_, { filter }) => ({
            ...initialState,
            oData: new ODataBckpJobs(BckpConstants.QSJobsTop, filter)
        })
    ),
    on(
        fromJobsActions.BckpJobsCleared,
        (state) => ({
            ...state,
            collection: jobsAdapter.removeAll(state.collection)
        })
    ),
    on(
        fromJobsActions.BckpJobsDetailsSucceeded,
        (state, { jobDetails }) =>
        {
            return {
                ...state,
                selectedJob: jobDetails,
                detailedJobs: jobsDetailsAdapter.addOne(jobDetails, state.detailedJobs)
            };
        }
    ),
    on(
        fromJobsActions.BckpJobsDetailsSelected,
        (state, { jobDetails }) =>
        {
            return {
                ...state,
                selectedJob: jobDetails,
            };
        }
    ),
    on(
        fromJobsActions.BckpJobsQsWordSet,
        (state, { qsWord }) => ({
            ...state,
            qsWord: qsWord.toLowerCase()
        })
    ),
    on(
        fromJobsActions.BckpJobsTagAttachSucceeded,
        fromJobsActions.BckpJobsTagDetachSucceeded,
        (state: IBckpJobsState, { updatedJob }: { updatedJob: Update<Job>; }) =>
        {
            return {
                ...state,
                collection: jobsAdapter.updateOne(updatedJob, state.collection)
            };
        }
    )
);
