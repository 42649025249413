import { NgModule } from '@angular/core';

import { StackedColumnModule } from './stacked-column.module';

@NgModule({
    exports: [
        StackedColumnModule
    ]
})
export class MixedChartModule
{ }
