import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { map, switchMap, concatMap, tap, catchError, filter, withLatestFrom, debounceTime } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { NotificationService, NotfGravity, NotifType } from '@common/services';
import { IAuroraState } from '@apps/aurora.state';
import { AppConstants } from '@apps/app.constants';
import * as fromRootMiscActions from '@apps/root/store/misc/misc.actions';
import { NotfWarnStoreActions } from '@apps/notf/store';
import { Message } from '@apps/notf/models/message';
import { AuthenticationService } from '@apps/auth/api/authentication.service';
import { TokenService } from '@apps/auth/api/token.service';
import { Profile } from '@apps/auth/models/profile';
import { AuthConstants } from '@apps/auth/auth.constants';
import { CatalogMiscDomainService } from '@apps/its2/domain';

import { getAuthProfile, getReturnUrl } from '../auth.reducer';
import * as fromAuthProfileActions from './profile.actions';

@Injectable()
export class AuthProfileEffects
{
    constructor (
        private store: Store<IAuroraState>,
        private actions: Actions,
        private authenticationService: AuthenticationService,
        private catalogMiscDomainService: CatalogMiscDomainService,
        private tokenService: TokenService,
        private router: Router,
        private translate: TranslateService,
        private notificationService: NotificationService
    )
    {
    }

    authLoginProfileLoading = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthLoginProfileLoading),
                switchMap(() =>
                    this.authenticationService.profile()
                        .pipe(
                            map((profile) =>
                                fromAuthProfileActions.AuthLoginProfileLoaded({ profile })
                            )
                        )
                )
            )
    );

    authLoginProfileLoaded = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthLoginProfileLoaded),
                switchMap((params: { profile: Profile }) =>
                    this.authenticationService.isProfileComplete()
                        .pipe(
                            switchMap((result: boolean) =>
                                [
                                    fromAuthProfileActions.AuthProfileLanguageSet({ language: params.profile.language }),
                                    fromAuthProfileActions.AuthProfileStatus({ completed: result })
                                ]
                            )
                        )
                )
            )
    );

    authProfileComplete = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthProfileActions.AuthProfileStatus),
                    filter((params: { completed: boolean }) => params.completed),
                    switchMap(() =>
                        this.store
                            .pipe(
                                select(getReturnUrl),
                                tap((returnUrl: string) =>
                                    this.router.navigateByUrl(returnUrl)
                                )
                            )
                    )
                ),
        { dispatch: false }
    );

    authProfileUncomplete = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileStatus),
                filter((params: { completed: boolean; }) =>
                    !params.completed
                ),
                withLatestFrom(
                    this.store.pipe(
                        select(getAuthProfile)
                    ),
                    this.store.pipe(
                        select(getReturnUrl)
                    ),
                    (o1, o2, o3) =>
                        ({ completed: o1.completed, profile: o2, returnUrl: o3 })
                ),

                map(({ profile, returnUrl }: { completed: boolean, profile: Profile, returnUrl: string; }) =>
                    ({ profile, returnUrl })
                ),
                tap(({ profile, returnUrl }: { profile: Profile, returnUrl: string }) =>
                {
                    if (profile.nextProfileCompletionUtc >= DateTime.utc())
                    {
                        this.router.navigateByUrl(returnUrl);
                    }
                    else
                    {
                        this.router.navigate(AuthConstants.ProfileStepRoute(1), { skipLocationChange: true });
                    }
                }),
                map(({ profile }: { profile: Profile, returnUrl: string }) =>
                    fromAuthProfileActions.AuthProfileLoaded({ profile: profile })
                )
            )
    );

    authProfileStep1Changed = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileStep1Changed),
                switchMap((action: any) =>
                    this.authenticationService.updateProfile(action.profile)
                        .pipe(
                            tap(() =>
                                this.router.navigate(AuthConstants.ProfileStepRoute(2), { skipLocationChange: true })
                            ),
                            map(() =>
                                fromAuthProfileActions.AuthProfileStepSucceeded()
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileStepFailed())
                            )
                        )
                )
            )
    );

    // authProfileStep1Continued = createEffect(() =>
    //     this.actions
    //         .pipe(
    //             ofType(fromAuthProfileActions.AuthProfileStep1Continued),
    //             tap(() =>
    //                 this.router.navigate(AuthConstants.ProfileStepRoute(2), { skipLocationChange: true })
    //             )
    //         ),
    //     { dispatch: false }
    // );

    authProfileStep2Changed = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileStep2Changed),
                switchMap((action: any) =>
                    this.authenticationService.updateProfile(action.profile)
                        .pipe(
                            tap(() =>
                                this.router.navigate(AuthConstants.ProfileStepRoute(4), { skipLocationChange: true })
                            ),
                            map(() =>
                                fromAuthProfileActions.AuthProfileStepSucceeded()
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileStepFailed())
                            )
                        )
                )
            )
    );

    // authProfileStep2Continued = createEffect(() =>
    //     this.actions
    //         .pipe(
    //             ofType(fromAuthProfileActions.AuthProfileStep2Continued),
    //             tap(() =>
    //                 this.router.navigate(AuthConstants.ProfileStepRoute(4), { skipLocationChange: true })
    //             )
    //         ),
    //     { dispatch: false }
    // );

    // authProfileStep3Changed = createEffect(() =>
    //     this.actions
    //         .pipe(
    //             ofType(fromAuthProfileActions.AuthProfileStep3Changed),
    //             switchMap((action: any) =>
    //                 this.authenticationService.changePassword(action.oldPswd, action.newPswd, action.verifyPswd)
    //                     .pipe(
    //                         tap(() =>
    //                             this.router.navigate(AuthConstants.ProfileStepRoute(4), { skipLocationChange: true })
    //                         ),
    //                         map(() =>
    //                             fromAuthProfileActions.AuthProfileStepSucceeded()
    //                         ),
    //                         catchError(() =>
    //                             of(fromAuthProfileActions.AuthProfileStepFailed())
    //                         )
    //                     )
    //             )
    //         )
    // );

    // authProfileStep3Continued = createEffect(() =>
    //     this.actions
    //         .pipe(
    //             ofType(fromAuthProfileActions.AuthProfileStep3Continued),
    //             tap(() =>
    //                 this.router.navigate(AuthConstants.ProfileStepRoute(4), { skipLocationChange: true })
    //             )
    //         ),
    //     { dispatch: false }
    // );

    authProfileStep4Changed = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileStep4Changed),
                map((action: any) =>
                {
                    return {
                        profile: {
                            ...action.profile,
                            nextProfileCompletionUtc: DateTime.utc().plus({ month: 1 })
                        } as Profile
                    };
                }),
                switchMap((params: { profile: Profile }) =>
                    this.authenticationService.updateProfile(params.profile)
                        .pipe(
                            tap(() =>
                                this.router.navigate([AppConstants.DefaultRedirectUrl], { skipLocationChange: true })
                            ),
                            map(() =>
                                fromAuthProfileActions.AuthProfileStepSucceeded()
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileStepFailed())
                            ))
                )
            )
    );

    // authProfileStep4Continued = createEffect(() =>
    //     this.actions
    //         .pipe(
    //             ofType(fromAuthProfileActions.AuthProfileStep4Continued),
    //             map(() =>
    //             {
    //                 return {
    //                     profile: {
    //                         nextProfileCompletionUtc: DateTime.utc().plus({ month: 1 })
    //                     } as Profile
    //                 };
    //             }),
    //             switchMap((params: { profile: Profile }) =>
    //                 this.authenticationService.updateProfile(params.profile)
    //                     .pipe(
    //                         tap(() =>
    //                             // this.router.navigate([params.returnUrl])
    //                             this.router.navigate([AppConstants.DefaultRedirectUrl])
    //                         )
    //                     )
    //             )
    //         ),
    //     { dispatch: false }
    // );

    authProfileStepSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthProfileActions.AuthProfileStepSucceeded),
                    tap(() =>
                    {
                        this.notificationService.notify([
                            '',
                            'AUTH.HOME.PROFILE.UPDATE.SUCCEEDED'
                        ],
                        NotfGravity.success,
                        NotifType.SNACKBAR
                        );
                    })
                ),
        { dispatch: false }
    );

    authProfileStepFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthProfileActions.AuthProfileStepFailed),
                    tap(() =>
                    {
                        this.notificationService.notify([
                            '',
                            'AUTH.HOME.PROFILE.UPDATE.FAILED'
                        ],
                        NotfGravity.danger,
                        NotifType.SNACKBAR
                        );
                    })
                ),
        { dispatch: false }
    );

    authProfileUpdateCanceled = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthProfileActions.AuthProfileStepCanceled),
                    map(() =>
                    {
                        return {
                            profile: {
                                nextProfileCompletionUtc: DateTime.utc().plus({ month: 1 })
                            } as Profile
                        };
                    }),
                    switchMap((params: { profile: Profile }) =>
                        this.authenticationService.updateProfile(params.profile)
                            .pipe(
                                tap(() =>
                                    this.router.navigate([AppConstants.DefaultRedirectUrl])
                                )
                            )
                    )
                ),
        { dispatch: false }
    );

    authProfileInformationsChanged = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileInformationsChanged),
                concatMap((action: any) =>
                    this.authenticationService.updateProfile(action.profile)
                        .pipe(
                            map(() =>
                                fromAuthProfileActions.AuthProfileUpdateSucceeded()
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileUpdateFailed())
                            )
                        )
                )
            )
    );

    authProfileUpdateSucceeded = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileUpdateSucceeded),
                debounceTime(400),
                switchMap(() =>
                    this.tokenService.getNewToken()
                        .pipe(
                            switchMap(() =>
                            {
                                const translations = this.translate.instant([
                                    'PRIV.PROFILE.NOTF.GENERIC.TITLE',
                                    'PRIV.PROFILE.NOTF.GENERIC.MSGSUCCESS'
                                ]);

                                const notif = new Message(
                                    translations['PRIV.PROFILE.NOTF.GENERIC.TITLE'],
                                    translations['PRIV.PROFILE.NOTF.GENERIC.MSGSUCCESS'],
                                    NotfGravity.success
                                );

                                return [
                                    NotfWarnStoreActions.AddMessage({ message: notif }),
                                    fromAuthProfileActions.AuthProfileUpdated({ status: true })
                                ];
                            })
                        )
                )
            )
    );

    authProfileUpdateFailed = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileUpdateFailed),
                switchMap(() =>
                {
                    const translations = this.translate.instant([
                        'PRIV.PROFILE.NOTF.GENERIC.TITLE',
                        'PRIV.PROFILE.NOTF.GENERIC.MSGFAILED']);
                    const notif = new Message(
                        translations['PRIV.PROFILE.NOTF.GENERIC.TITLE'],
                        translations['PRIV.PROFILE.NOTF.GENERIC.MSGFAILED'],
                        NotfGravity.danger
                    );

                    return [
                        NotfWarnStoreActions.AddMessage({ message: notif }),
                        fromAuthProfileActions.AuthProfileUpdated({ status: false })
                    ];
                })
            )
    );

    authProfileSecurityTwofactorChanged = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileSecurityTwofactorChanged),
                concatMap((action: any) =>
                    this.authenticationService.updateProfile(action.profile)
                        .pipe(
                            map(() =>
                                fromAuthProfileActions.AuthProfileUpdateSucceeded()
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileUpdateFailed())
                            )
                        )
                )
            )
    );

    authProfileNewsletterMarketingChanged = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileNewsletterMarketingChanged),
                concatMap((action: any) =>
                    this.authenticationService.updateProfile(action.profile)
                        .pipe(
                            map(() =>
                                fromAuthProfileActions.AuthProfileUpdateSucceeded()
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileUpdateFailed())
                            )
                        )
                )
            )
    );

    authProfileNewsletterNotificationChanged = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileNewsletterNotificationChanged),
                concatMap((action: any) =>
                    this.authenticationService.updateProfile(action.profile)
                        .pipe(
                            map(() =>
                                fromAuthProfileActions.AuthProfileUpdateSucceeded()
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileUpdateFailed())
                            )
                        )
                )
            )
    );

    authProfileWorkingCookiesChanged = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileWorkingCookiesChanged),
                concatMap((action: any) =>
                    this.authenticationService.updateProfile(action.profile)
                        .pipe(
                            map(() =>
                                fromAuthProfileActions.AuthProfileUpdateSucceeded()
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileUpdateFailed())
                            )
                        )
                )
            )
    );

    authProfileStatisticsCookiesChanged = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileStatisticsCookiesChanged),
                concatMap((action: any) =>
                    this.authenticationService.updateProfile(action.profile)
                        .pipe(
                            map(() =>
                                fromAuthProfileActions.AuthProfileUpdateSucceeded()
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileUpdateFailed())
                            )
                        )
                )
            )
    );

    authProfileSocialNetworksCookiesChanged = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileSocialNetworksCookiesChanged),
                concatMap((action: any) =>
                    this.authenticationService.updateProfile(action.profile)
                        .pipe(
                            map(() =>
                                fromAuthProfileActions.AuthProfileUpdateSucceeded()
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileUpdateFailed())
                            )
                        )
                )
            )
    );

    authProfileLanguageChanged = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileLanguageChanged),
                concatMap((action: any) =>
                    this.authenticationService.updateProfile(action.profile)
                        .pipe(
                            switchMap((profile: Profile) =>
                                [
                                    fromAuthProfileActions.AuthProfileLanguageSet({ language: profile.language }),
                                    fromAuthProfileActions.AuthProfileUpdateSucceeded(),
                                    fromAuthProfileActions.AuthProfileLoaded({ profile: profile })
                                ]
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileUpdateFailed())
                            )

                        )
                )
            )
    );

    authProfileSkinChanged = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileSkinChanged),
                concatMap((action: any) =>
                    this.authenticationService.updateProfile(action.profile)
                        .pipe(
                            switchMap((profile: Profile) =>
                                [
                                    fromRootMiscActions.RootMiscSkinSwitched({
                                        skin: Profile.fromThemeToSkin(profile.skin)
                                    }),
                                    fromAuthProfileActions.AuthProfileUpdateSucceeded()
                                ]
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileUpdateFailed())
                            )
                        )
                )
            )
    );

    authProfileChangePasswordRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileChangePasswordRequested),
                switchMap((params: any) =>
                    this.authenticationService.changePassword(params.changePassword)
                        .pipe(
                            map(() =>
                                fromAuthProfileActions.AuthProfileChangePasswordSucceeded()
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileChangePasswordFailed())
                            )
                        )
                )
            )
    );

    authProfileChangePasswordSucceeded = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthProfileActions.AuthProfileChangePasswordSucceeded),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                '',
                                'PRIV.PROFILE.SECURITY.UPDATEPASSWORD.MODAL.UPDATEOK'
                            ],
                            NotfGravity.success,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    authProfileChangePasswordFailed = createEffect(
        () =>
            this.actions
                .pipe(
                    ofType(fromAuthProfileActions.AuthProfileChangePasswordFailed),
                    tap(() =>
                        this.notificationService.notify(
                            [
                                '',
                                'PRIV.PROFILE.SECURITY.UPDATEPASSWORD.MODAL.UPDATEKO'
                            ],
                            NotfGravity.danger,
                            NotifType.SNACKBAR
                        )
                    )
                ),
        { dispatch: false }
    );

    authProfileIsCatalogSecuAuthorizedRequested = createEffect(() =>
        this.actions
            .pipe(
                ofType(fromAuthProfileActions.AuthProfileIsCatalogSecuAuthorizedRequested),
                switchMap(() =>
                    this.catalogMiscDomainService.isSecuCatalogAuthorized()
                        .pipe(
                            map((isCatalogSecuAuthorized: boolean) =>
                                fromAuthProfileActions.AuthProfileIsCatalogSecuAuthorizedSucceeded({ isCatalogSecuAuthorized })
                            ),
                            catchError(() =>
                                of(fromAuthProfileActions.AuthProfileIsCatalogSecuAuthorizedFailed())
                            )
                        )
                )
            )
    );
}
