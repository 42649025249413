import { SecurityRuleApi } from '../../../api/models/security';

export class SecurityRule
{
    direction: string;
    ethertype: string;
    id: string;
    portRangeMax?: number;
    portRangeMin?: number;
    protocol: string;
    remoteGroupId: string;
    remoteIpPrefix: string;
    securityGroupId: string;
    tenantId: string;

    directionClass: string;

    public static mapperFromApi (source: SecurityRuleApi): SecurityRule
    {
        const target = new SecurityRule();

        Object.assign(target, source);

        // target.directionClass = OstkConstants.getDirectionClass(target.direction);

        return target;
    }

    public static toString (sr: SecurityRule): string
    {
        let strRule: string = '';
        if (sr.portRangeMin)
        {
            if (sr.portRangeMax)
            {
                if (sr.portRangeMax !== sr.portRangeMin)
                {
                    strRule = `${sr.portRangeMin}-${sr.portRangeMax}`;
                }
                else
                {
                    strRule = `${sr.portRangeMin}`;
                }
            }
            else
            {
                strRule = `${sr.portRangeMin}`;
            }
        }

        return strRule;
    }
}

export class CreateSecurityRuleViewModel
{
    securityGroupId: string;
    direction: string;
    portRangeMin: number | null;
    portRangeMax: number | null;
    ethertype: string;
    protocol: string;
    remoteGroupId: string;
    remoteIpPrefix: string;
}
