import { Pipe, PipeTransform } from '@angular/core';

import { NumbersService } from '@common/services';

@Pipe({
    name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform
{
    constructor (private numbersService: NumbersService)
    { }

    transform (value: number, decPart: number = 0): string
    {
        if (value === undefined || value === null)
        {
            return '';
        }

        return this.numbersService.toFixed(value, decPart);
    }
}
