import { Injector } from '@angular/core';
import { ControlValueAccessor, NgControl, AbstractControl } from '@angular/forms';

import { ErrorMsgService } from '../common/error-msg.service';

export abstract class ValueAccessorBase<T> implements ControlValueAccessor
{
    private innerValue: T;
    private _control: AbstractControl;
    public changed = new Array<(value: T) => void>();
    public touched = new Array<() => void>();
    public errorMsgService: ErrorMsgService;

    constructor (private _injector: Injector)
    {
        this.errorMsgService = this._injector.get<ErrorMsgService>(ErrorMsgService);
    }

    /**
     * The control (NgModel or FormControl) that exists on our custom component.
     * Lazy loaded.
     * @readonly
     * @protected
     * @type {NgControl}
     * @memberof ValueAccessorBase
     */
    public get control (): AbstractControl
    {
        if (this._control != null)
        {
            return this._control;
        }

        this._control = this._injector.get(NgControl, null).control;

        return this._control;
    }

    get value (): T
    {
        return this.innerValue;
    }

    set value (value: T)
    {
        if (this.innerValue !== value)
        {
            this.innerValue = value;
            this.changed.forEach(f => f(value));
        }
    }

    writeValue (value: T)
    {
        this.innerValue = value;
    }

    registerOnChange (fn: (value: T) => void)
    {
        this.changed.push(fn);
    }

    registerOnTouched (fn: () => void)
    {
        this.touched.push(fn);
    }

    touch ()
    {
        this.touched.forEach(f => f());
    }

    change ()
    {
        this.changed.forEach(f => f(this.innerValue));
    }
}
