import { Tag } from '@apps/cmdb/models';

export class Assetify9
{
    public tagIds: string[];
    public tagFullNames: string;

    constructor (public assetName: () => string)
    {
    }

    public static BuildFullTagNames (tagIds: string[], tags: Tag[]): string
    {
        return tags
            .filter((tag: Tag): boolean =>
                tagIds.includes(tag.tagId)
            )
            .map((tag: Tag): string =>
                tag.fullTagName
            )
            .join('|');
    }
}
