<form id="auth-form" [formGroup]="form" (keyup.enter)="onSubmit()">
    <div class="auth-title">{{ 'AUTH.FORM.TITLE' | translate }}</div>
    <div class="auth-sub-title">
        {{ 'AUTH.FORM.LABEL' | translate }}
    </div>
    <ng-container *ngFor="let f of fields">
        <ng-container [ngSwitch]="f.type">
            <aa-input-email2
                aaAutofocus
                *ngSwitchCase="'email'"
                [required]="f.required ? 'required' : undefined"
                name="{{ f.name }}"
                label="{{ f.label }}"
                formControlName="{{ f.name }}"
            >
            </aa-input-email2>
            <aa-input-password2
                *ngSwitchCase="'password'"
                [required]="f.required ? 'required' : undefined"
                updateWhen="change"
                name="{{ f.name }}"
                label="{{ f.label }}"
                formControlName="{{ f.name }}"
            >
            </aa-input-password2>
        </ng-container>
    </ng-container>
    <span class="small-text-link fg-pswd" (click)="forgottenPassword()">
        {{ 'AUTH.FORM.FORGET' | translate }}
    </span>
    <div>
        <aa-btn-action-secondary
            [btnText]="'AUTH.FORM.BUTTON' | translate"
            [cta]="onSubmit"
            [btnWidth]="'100%'"
        >
        </aa-btn-action-secondary>
    </div>
    <!-- <span class="medium-text create-account" *ngIf="!email"
        >Vous souhaitez un compte ?
        <span (click)="createAccount()" class="medium-text-link">
            Créez-le !
        </span>
    </span>
    <div class="medium-text" *ngIf="email">
        Votre compte a déjà été créé, vous pouvez-vous connecter avec cette
        adresse mail.
    </div>
    <div class="medium-text">
        <span class="bulb">
            <span class="fa-stack fa-1x">
                <i class="fas fa-circle fa-stack-2x" aria-hidden="true"></i>
                <i class="fas fa-stack-1x fa-lightbulb" aria-hidden="true"></i>
            </span>
        </span>
        Vos mots de passe sont identiques entre Portal et AurorA.
    </div>
    <div class="medium-text">
        <span class="bulb">
            <span class="fa-stack fa-1x">
                <i class="fas fa-circle fa-stack-2x" aria-hidden="true"></i>
                <i class="fas fa-stack-1x fa-lightbulb" aria-hidden="true"></i>
            </span>
        </span>
        Pour une connexion plus rapide, vous pouvez copier ce lien et le placer
        dans vos signets : <span class="url-text">{{ link }}</span>
        <span class="copy" title="Copy link" [aaCopyClipboard]="link">
            <span class="fa-stack fa-1x">
                <i class="fas fa-circle fa-stack-2x" aria-hidden="true"></i>
                <i class="fas fa-stack-1x fa-copy" aria-hidden="true"></i>
            </span>
        </span>
    </div> -->
</form>
<span *ngIf="msgError !== ''" class="auth-error-message">{{ msgError }}</span>
<aa-auth-loading></aa-auth-loading>
