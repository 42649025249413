<form id="auth-form" [formGroup]="form" (keyup.enter)="onSubmit()" *ngIf="profile">
    <div class="auth-title">{{ 'AUTH.PROFILE.ONBOARDING.STEP4.TITLE' | translate }}</div>
    <div class="auth-sub-title">
        {{ 'AUTH.PROFILE.ONBOARDING.STEP4.SUBTITLE' | translate }}
    </div>
    <aa-input-switch
        name="workingCookies"
        [label]="'PRIV.PROFILE.COOKIES.USAGE' | translate"
        formControlName="workingCookies"
        checked="{{working}}"
    ></aa-input-switch
    ><aa-input-switch
        name="statisticsCookies"
        [label]="'PRIV.PROFILE.COOKIES.STATISTIC' | translate"
        formControlName="statisticsCookies"
        checked="{{statistics}}"
    ></aa-input-switch
    ><aa-input-switch
        name="socialNetworksCookies"
        [label]="'PRIV.PROFILE.COOKIES.SOCIAL_NETWORKS' | translate"
        formControlName="socialNetworksCookies"
        checked="{{social}}"
    ></aa-input-switch>
    <aa-input-switch
        name="marketingNewsletter"
        [label]="'PRIV.PROFILE.NEWSLETTER.COMMERCIAL' | translate"
        formControlName="marketingNewsletter"
        checked="{{marketing}}"
    ></aa-input-switch>
    <aa-input-switch
        name="maintenanceNotification"
        [label]="'PRIV.PROFILE.NEWSLETTER.MAINTENANCE' | translate"
        formControlName="maintenanceNotification"
        checked="{{maintenance}}"
    ></aa-input-switch>
    <div class="buttons">
        <aa-btn-action-secondary
            [btnText]="'BUTTON.CANCEL' | translate"
            [cta]="onCancel"
            [btnWidth]="'100%'"
        >
        </aa-btn-action-secondary>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <aa-btn-action-secondary
            [btnText]="'BUTTON.CONTINUE' | translate"
            [cta]="onSubmit"
            [btnWidth]="'100%'"
        >
        </aa-btn-action-secondary>
    </div>
</form>
