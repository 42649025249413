import { Component, OnInit, OnDestroy } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

import { AuthLoginStoreActions } from 'src/app/_apps/auth/store';

@Component({
    selector: 'aa-auth-loading',
    templateUrl: './auth-loading.component.html',
    styleUrls: [
        './auth-loading.component.scss'
    ]
})
export class AuthLoadingComponent implements OnInit, OnDestroy
{
    private destroyator = new Subject();
    public isLogging: boolean = false;
    public waitingMessage: Observable<string>;

    constructor(
        private actions: Actions,
        translate: TranslateService
    )
    {
        this.waitingMessage = translate.get('AUTH.LOADING.MESSAGE');
    }

    ngOnInit ()
    {
        this.actions
            .pipe(
                ofType(AuthLoginStoreActions.AuthLogging),
                tap((x: {isLogging: boolean}) =>
                    this.isLogging = x.isLogging
                ),
                takeUntil(this.destroyator)
            )
            .subscribe();
    }

    ngOnDestroy ()
    {
        this.destroyator.next(null);
        this.destroyator.complete();
    }
}
