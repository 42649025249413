export class SummaryCounter<T>
{
    public static sortSummaryCounter<T> (sc: SummaryCounter<T>[]): SummaryCounter<T>[]
    {
        return sc.sort((sca: SummaryCounter<T>, scb:SummaryCounter<T>) => scb.value - sca.value);
    }

    constructor (public key: T, public value: number)
    {
    }
}
